import React, { useState } from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AddressForm from '../../users/AddressForm';

import { authActions } from '../../../../store/ducks/auth.duck';

const Address = (props) => {
  const {
    // user,
    addresses,
    address,
    setAddresses,
    onChangeAddressValue,
    addressType,
    countries,
    errors,
    setErrors,
    isBilling,
    setIsBilling,
    order,
    intl,
  } = props;
  const [openAddressModal, setOpenAddressModal] = useState(false);

  const onChangeAddressValueTest = (value, fieldName) => {
    onChangeAddressValue(value, fieldName);
  };

  const onSaveAndClose = () => {
    const updateAddresses = (type) => {
      setAddresses({
        ...addresses,
        [type.toLowerCase()]: { ...address, type: type === 'Billing' ? 2 : 1 },
      });
    };
  
    const processErrors = (errorType) => {
      const keysError = Object.keys(errors?.[errorType]).filter(key => errors?.[errorType][key]);
      const excludedFields = ['company_name', 'tax_number', 'tax_number_valid', 'ext'];
      const filteredKeysError = keysError.filter(key => !excludedFields.includes(key));
  
      const keysWithNullOrEmpty = Object.keys(address).filter(
        key => address[key] === null || address[key] === undefined || address[key] === ''
      );
      const filteredKeysWithNullOrEmpty = keysWithNullOrEmpty.filter(key => !excludedFields.includes(key));
  
      if (filteredKeysError.length > 0 || filteredKeysWithNullOrEmpty.length > 0) {
        let errorsArr = [...filteredKeysError, ...filteredKeysWithNullOrEmpty.filter(key => !filteredKeysError.includes(key))];
  
        toast.error(
          intl.formatMessage({ id: 'ERROR.FIELDS_ARE_REQUIRED' }) +
            errorsArr.map(word => word.charAt(0).toUpperCase() + word.slice(1).replace(/_/g, ' ')).join(', '),
          {
            position: 'bottom-right',
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          }
        );
      }
    };
  
    if (addressType !== 'Billing') {
      updateAddresses('Shipping');
      processErrors('shipping');
    } else {
      updateAddresses('Billing');
      processErrors('billing');
    }
  
    setOpenAddressModal(!openAddressModal);
  };
  

  return (
    <div key={order.id + 'okasdbaf'}>
      <Row>
        <Col as={'h6'} className={'font-weight-bold'}>
          <FormattedMessage
            id={
              addressType == 'Shipping'
                ? 'CHECKOUT.SHIPPING_ADDRESS'
                : 'CHECKOUT.BILLING_ADDRESS'
            }
          />
        </Col>
      </Row>
      <Row className={'mt-2 mb-3'}>
        <Col as={'ul'} className={'list-unstyled address-list'}>
          {/*{addresses.length > 0 && addresses.filter(address => address.type.name == addressType)*/}
          {/*  .map((address) => {*/}
          {/*  onClick={(e) => {*/}
          {/*  setIsActive(address?.id);*/}
          {/*}}*/}
          {/*    return (*/}
          {(addressType == 'Shipping' ||
            (addressType == 'Billing' && isBilling)) &&
            address && (
              <li
                key={address?.id + addressType}
                className={'address-list-address active p-4'}
              >
                <Container>
                  <Row>
                    <Col>
                      <h6 className={'font-weight-bold'}>
                        {address?.company_name
                          ? address?.company_name
                          : (address?.first_name ?? '') +
                            ' ' +
                            (address?.last_name ?? '')}
                      </h6>
                      {address.address && (
                        <span>
                          {address?.address} {address?.house_number},{' '}
                          <em>
                            {address?.city} ({address?.country?.name})
                          </em>
                        </span>
                      )}
                    </Col>
                    <Col className={'text-right'}>
                      <a href={'#'} onClick={() => setOpenAddressModal(true)}>
                        <FormattedMessage id={'GENERAL.MODIFY'} />
                      </a>
                      {addressType == 'Billing' && (
                        <>
                          {' '}
                          /{' '}
                          <a
                            href={'#'}
                            onClick={() => {
                              setIsBilling(false);
                            }}
                          >
                            <FormattedMessage id={'Delete'} />
                          </a>
                        </>
                      )}
                    </Col>
                  </Row>
                </Container>
              </li>
            )}
          {/*  );*/}
          {/*})}*/}

          {!isBilling && addressType == 'Billing' && (
            <li className={'add-address-link text-center mt-4'}>
              <FontAwesomeIcon icon={solid('plus')} className={'pr-1'} />
              <a
                href={'#'}
                onClick={() => {
                  setIsBilling(true);
                  setOpenAddressModal(true);
                }}
              >
                <FormattedMessage id={'Enter a different billing address'} />
              </a>
            </li>
          )}
        </Col>
      </Row>

      {address && (
        <Modal
          show={openAddressModal}
          onHide={() => setOpenAddressModal(!openAddressModal)}
          size="lg"
          centered
        >
          <Modal.Body>
            <IconButton
              aria-label="close"
              onClick={() => {
                return setOpenAddressModal(false);
              }}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>

            <div className="draw draw__content" id="drawBox">
              <AddressForm
                isBilling={isBilling}
                setIsBilling={setIsBilling}
                // updateTax={updateTax}
                changeAddress={address}
                onChangeAddressValue={onChangeAddressValueTest}
                countries={countries}
                type={addressType}
                errors={errors}
                setErrors={setErrors}
                order={order}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={onSaveAndClose}>
              <FormattedMessage id={'Save and close'} />
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

const mapStateToProps = ({ auth: { user }, users: { detailedUser } }) => ({
  user: detailedUser.data,
  userId: user?.id,
  checkAuth: authActions.checkAuth,
});

const mapDispatchToProps = {
  checkAuth: authActions.checkAuth,
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Address));
