import { createAction } from 'redux-actions';

export const getVats = createAction('GET_VATS_REQUEST');
export const getVatsSuccess = createAction('GET_VATS_SUCCESS');
export const getVatsError = createAction('GET_VATS_ERROR');
export const getVat = createAction('GET_VAT_REQUEST');
export const getVatSuccess = createAction('GET_VAT_SUCCESS');
export const getVatError = createAction('GET_VAT_ERROR');
export const deleteVat = createAction('DELETE_VAT_REQUEST');
export const deleteVatSuccess = createAction('DELETE_VAT_SUCCESS');
export const deleteVatError = createAction('DELETE_VAT_ERROR');
export const updateVat = createAction('UPDATE_VAT_REQUEST');
export const updateVatSuccess = createAction('UPDATE_VAT_SUCCESS');
export const updateVatError = createAction('UPDATE_VAT_ERROR');
