export const DEFAULT_DATE_FORMAT = 'DD-MM-YYYY';
export const COUNTRY_LIST = [
  { id: 1, value: 'netherlands', title: 'Netherlands' },
  { id: 2, value: 'germany', title: 'Germany' },
  { id: 3, value: 'belgium', title: 'Belgium' },
  { id: 4, value: 'austria', title: 'Austria' },
  { id: 5, value: 'switzerland', title: 'Switzerland' },
  { id: 6, value: 'denmark', title: 'Denmark' },
  { id: 7, value: 'sweden', title: 'Sweden' },
  { id: 9, value: 'finland', title: 'Finland' },
  { id: 14, value: 'france', title: 'France' },
  { id: 15, value: 'greece', title: 'Greece' },
  { id: 16, value: 'hungary', title: 'Hungary' },
  { id: 17, value: 'ireland', title: 'Ireland' },
  { id: 18, value: 'italy', title: 'Italy' },
  { id: 19, value: 'croatia', title: 'Croatia' },
  { id: 20, value: 'latvia', title: 'Latvia' },
  { id: 22, value: 'luxembourg', title: 'Luxembourg' },
  { id: 24, value: 'poland', title: 'Poland' },
  { id: 25, value: 'portugal', title: 'Portugal' },
  { id: 27, value: 'slovenia', title: 'Slovenia' },
  { id: 28, value: 'slovakia', title: 'Slovakia' },
  { id: 29, value: 'spain', title: 'Spain' },
  { id: 30, value: 'czech_republic', title: 'Czech Republic' },
  { id: 32, value: 'scotland', title: 'Scotland' },
  { id: 33, value: 'united_kingdom', title: 'United Kingdom' },
];

export const PRICE_RULE_ACTIONS = [
  { id: 1, title: 'add' },
  { id: 2, title: 'subtract' },
  { id: 3, title: 'replace' },
];

export const PRICE_RULE_ELEMENTS = [
  { id: 1, title: 'Hourly rate' },
  { id: 2, title: 'Price' },
];

export const PRICE_RULE_TYPES = [
  { id: 1, title: 'Product weight' },
  { id: 2, title: 'Product price' },
  { id: 3, title: 'Product surface' },
  { id: 4, title: 'Total price' },
  { id: 5, title: 'Total weight' },
  { id: 6, title: 'Total surface' },
  { id: 7, title: 'Payment Type' },
  { id: 8, title: 'Material price' },
];

export const PAYMENT_TYPES = [
  { id: 4, title: 'iDeal' },
  { id: 5, title: 'Paypal' },
  { id: 6, title: 'Bancontact' },
  { id: 7, title: 'Op rekening' },
];

export const MATERIAL_CATEGORIES = [
  { id: 1, title: 'KUNSTSTOF', value: 'plastic' },
  { id: 2, title: 'RUBBER', value: 'rubber' },
  { id: 3, title: 'OVERIG', value: 'other' },
];

export const INITIAL_ADDRESS = {
  city: '',
  country: { id: null, name: null, code: null },
  ext: '',
  house_number: null,
  postal_code: '',
  address: '',
  last_name: '',
  first_name: '',
  company_name: '',
  tax_number_valid: null,
};

export const REQUIRED_MATERIALS_CSV_FIELDS = [
  'name',
  'plate_measurement',
  'number',
  'thickness',
  'color',
  'cutting_speed',
  'price',
  'hourly_rate',
  'destiny',
];

export const DOMAIN_LOCALES = [
  { country: 'Nederland', domain: 'localhost', defaultLocale: 'nl' },
  { country: 'Denemarken', domain: 'app.cutwise.dk', defaultLocale: 'en' },
  { country: 'Duitsland', domain: 'app.cutwise.de', defaultLocale: 'de' },
  { country: 'Belgie', domain: 'app.cutwise.be', defaultLocale: 'nl' },
  { country: 'Nederland', domain: 'app.cutwise.nl', defaultLocale: 'nl' },
  { country: 'Nederland', domain: 'cutwise.bmtec.nl', defaultLocale: 'nl' },
  {
    country: 'Duitsland',
    domain: 'cutwise.bmtec-laserschneiden.de',
    defaultLocale: 'de',
  },
  {
    country: 'Denemarken',
    domain: 'cutwise.bmtec-lasercutting.com',
    defaultLocale: 'en',
  },
];
