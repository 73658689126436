import React, { useState, useEffect, useRef } from 'react';
import {
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tabs,
  Tab,
  Box,
} from '@mui/material';
import { FormattedMessage, injectIntl } from 'react-intl';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

import { API_URL, API_URLS } from '../../../common/constants/api/apiConstants';
import {
  toAbsoluteUrl,
  mergeObjInArray,
} from '../../../../_metronic/utils/utils';
import initApi from '../../../crud/apiConfig/apiConfig';
import ConfiguratorPage from '../configurator/ConfiguratorPage';

import PDFDownloader from './PDFDownloader';
import CommonSection from './CommonSection';
import HowItWorks from './HowItWorks';

const HPLMaterialLandingPage = (props) => {
  const { intl } = props;
  const isMounted = useRef(false);

  const [index, setIndex] = useState(null);
  const [topPanel2, setTopPanel2] = useState(0);

  const [materialData, setMaterialData] = useState([]);

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    isMounted.current = true;
    const updateWindowSize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    const scrollToTop = () => {
      window.scrollTo(0, 0);
    };
    scrollToTop();
    window.addEventListener('beforeunload', scrollToTop);

    window.addEventListener('resize', updateWindowSize);
    setTopPanel2(
      document.getElementById('panel2a-header').getBoundingClientRect().top -
        150
    );

    return () => {
      isMounted.current = false;
      window.removeEventListener('resize', updateWindowSize);
      window.removeEventListener('beforeunload', scrollToTop);
    };
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      getMaterialData('HPL / Trespa');
    }
  }, [isMounted]);

  useEffect(() => {
    setTopPanel2(
      document.getElementById('panel2a-header').getBoundingClientRect().top -
        150
    );
  }, [materialData, windowSize]);

  const [tabValue, setTabValue] = useState(0);

  const TabPanel = (props) => {
    const { children, value, index } = props;

    return (
      <div
        role="tabPanel"
        hidden={value !== index}
        id={`tabPanel-${index}`}
        style={{ width: '100%' }}
      >
        {value === index && (
          <Box p={3}>
            <Typography component="div">{children}</Typography>
          </Box>
        )}
      </div>
    );
  };

  const handleTabChange = (event, value) => {
    event.preventDefault();

    setTabValue(value);
  };

  const getMaterialData = async (material_name) => {
    let { data } = await initApi().get(API_URL + API_URLS.GET_MATERIALS_DATA);

    const filteredMaterialData = data.filter(
      (item) => item.name === material_name
    );

    setMaterialData(mergeObjInArray(filteredMaterialData));
  };

  return (
    <Grid container justifyContent="center" alignItems="center">
      {/* <Grid item container xs={12} className="hb-section"></Grid> */}

      <Grid item xs={12} container className="bp-main-section">
        <Grid item xs={12} container justifyContent="center">
          <Typography variant="h1">
            <FormattedMessage id="HPL.TITLE" />
          </Typography>

          <Typography variant="p">
            <FormattedMessage id="HPL.DESC" />
          </Typography>
        </Grid>
        <Grid item xs={12} container justifyContent="center">
          <ConfiguratorPage isMainPage={true} />
        </Grid>
      </Grid>

      <HowItWorks materialType="HPL" />

      <Grid item xs={12} container justifyContent="center" className="tab-list">
        <Grid item sm={10} xs={12} sx={{ border: 1, borderColor: 'divider' }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab
              label={intl.formatMessage({ id: 'Materiaal informatie' })}
              index={0}
            />
            <Tab
              label={intl.formatMessage({ id: 'Technische informatie' })}
              index={1}
            />
            <Tab label={intl.formatMessage({ id: 'Datasheets' })} index={2} />
          </Tabs>
        </Grid>

        <TabPanel value={tabValue} index={0}>
          <table
            className="material-info-table table table-striped w-100"
            style={{ marginBottom: 0, overflowX: 'auto' }}
          >
            <thead>
              <tr>
                <th colSpan="3" style={{ fontSize: '18px' }}>
                  <FormattedMessage id="Productinformatie" />
                </th>
                <th colSpan="4" style={{ fontSize: '18px' }}>
                  <FormattedMessage id="Bewerkingen" />
                </th>
              </tr>

              <tr>
                <th scope="col" className={'w-15'}>
                  <FormattedMessage id="Plaat diktes" />
                </th>
                <th scope="col" className={'w-15'}>
                  <FormattedMessage id="Plaat afmetingen" />
                </th>
                <th scope="col" className={'w-15'}>
                  <FormattedMessage id="Kleuren" />
                </th>
                <th scope="col" className={'w-15 col-with-icon'}>
                  <span>
                    <FormattedMessage id="Te laseren diktes" />
                    <i className="cw-materials-icon icon-laser"></i>
                  </span>
                </th>
                <th scope="col" className={'w-15 col-with-icon'}>
                  <span>
                    <FormattedMessage id="Te frezen diktes" />
                    <i className="cw-materials-icon icon-milling"></i>
                  </span>
                </th>
                <th scope="col" className={'w-15 col-with-icon'}>
                  <span>
                    <FormattedMessage id="Zettingen mogelijk" />
                    <i className="cw-materials-icon icon-bending"></i>
                  </span>
                </th>
                <th scope="col" className={'w-15'}>
                  <FormattedMessage id="Verlijmbaar" />
                </th>
              </tr>
            </thead>
            <tbody>
              {materialData.map(
                ({
                  thickness,
                  plate_measurement,
                  color,
                  color_de,
                  color_en,
                  has_to_be_milled,
                  is_bendable,
                }) => {
                  return (
                    <tr key={thickness + color}>
                      <td>{thickness} mm</td>
                      <td>{plate_measurement} mm</td>
                      <td className="td-flex">
                        {intl?.locale === 'nl' &&
                          color.map((item, idx) => (
                            <span key={idx + item}>{item}</span>
                          ))}

                        {intl?.locale === 'de' &&
                          color_de.map((item, idx) => (
                            <span key={idx + item}>{item}</span>
                          ))}

                        {intl?.locale === 'en' &&
                          color_en.map((item, idx) => (
                            <span key={idx + item}>{item}</span>
                          ))}
                      </td>
                      <td className="td-flex">
                        {has_to_be_milled?.map((item, idx) =>
                          item == false ? (
                            <span key={idx + color}>
                              <CheckIcon htmlColor="#7ed321" />
                            </span>
                          ) : (
                            <span key={idx + color}>
                              <CloseIcon htmlColor="#ff0000" />
                            </span>
                          )
                        )}
                      </td>
                      <td className="td-flex">
                        {has_to_be_milled?.map((item, idx) =>
                          item == false ? (
                            <span key={idx + color}>
                              <CloseIcon htmlColor="#ff0000" />
                            </span>
                          ) : (
                            <span key={idx + color}>
                              <CheckIcon htmlColor="#7ed321" />
                            </span>
                          )
                        )}
                      </td>
                      <td className="td-flex" align="center">
                        {color.map((idx, item) =>
                          is_bendable == false ? (
                            <span key={idx + item}>
                              <CloseIcon htmlColor="#ff0000" />
                            </span>
                          ) : (
                            <span key={idx + item}>
                              <CheckIcon htmlColor="#7ed321" />
                            </span>
                          )
                        )}
                      </td>
                      <td className="td-flex" align="center">
                        {color.map((idx, item) => (
                          <span key={idx + item}>
                            <CheckIcon htmlColor="#7ed321" />
                          </span>
                        ))}
                      </td>
                    </tr>
                  );
                }
              )}
            </tbody>
          </table>

          <table
            className="material-info-table table table-striped w-100"
            style={{ marginBottom: 0, overflowX: 'auto' }}
          >
            <thead>
              <tr>
                <th scope="col" className={'w-50'} style={{ fontSize: '18px' }}>
                  <FormattedMessage id="Toepassingen" />
                </th>
                <th scope="col" className={'w-50'} style={{ fontSize: '18px' }}>
                  <FormattedMessage id="Verdere eigenschappen" />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <ul>
                    <li>
                      <CheckIcon
                        htmlColor="#7ed321"
                        style={{ marginRight: '8px' }}
                      />
                      <FormattedMessage id="HPL.CHECK.LIST_1.ITEM_1" />
                    </li>
                    <li>
                      <CheckIcon
                        htmlColor="#7ed321"
                        style={{ marginRight: '8px' }}
                      />
                      <FormattedMessage id="HPL.CHECK.LIST_1.ITEM_2" />
                    </li>
                    <li>
                      <CheckIcon
                        htmlColor="#7ed321"
                        style={{ marginRight: '8px' }}
                      />
                      <FormattedMessage id="HPL.CHECK.LIST_1.ITEM_3" />
                    </li>
                    <li>
                      <CheckIcon
                        htmlColor="#7ed321"
                        style={{ marginRight: '8px' }}
                      />
                      <FormattedMessage id="HPL.CHECK.LIST_1.ITEM_4" />
                    </li>
                    <li>
                      <CheckIcon
                        htmlColor="#7ed321"
                        style={{ marginRight: '8px' }}
                      />
                      <FormattedMessage id="HPL.CHECK.LIST_1.ITEM_5" />
                    </li>
                    <li>
                      <CheckIcon
                        htmlColor="#7ed321"
                        style={{ marginRight: '8px' }}
                      />
                      <FormattedMessage id="HPL.CHECK.LIST_1.ITEM_6" />
                    </li>
                    <li>
                      <CheckIcon
                        htmlColor="#7ed321"
                        style={{ marginRight: '8px' }}
                      />
                      <FormattedMessage id="HPL.CHECK.LIST_1.ITEM_7" />
                    </li>
                    <li>
                      <CheckIcon
                        htmlColor="#7ed321"
                        style={{ marginRight: '8px' }}
                      />
                      <FormattedMessage id="HPL.CHECK.LIST_1.ITEM_8" />
                    </li>
                    <li>
                      <CheckIcon
                        htmlColor="#7ed321"
                        style={{ marginRight: '8px' }}
                      />
                      <FormattedMessage id="HPL.CHECK.LIST_1.ITEM_9" />
                    </li>
                    <li>
                      <CheckIcon
                        htmlColor="#7ed321"
                        style={{ marginRight: '8px' }}
                      />
                      <FormattedMessage id="HPL.CHECK.LIST_1.ITEM_10" />
                    </li>
                    <li>
                      <CheckIcon
                        htmlColor="#7ed321"
                        style={{ marginRight: '8px' }}
                      />
                      <FormattedMessage id="HPL.CHECK.LIST_1.ITEM_11" />
                    </li>
                    <li>
                      <CheckIcon
                        htmlColor="#7ed321"
                        style={{ marginRight: '8px' }}
                      />
                      <FormattedMessage id="HPL.CHECK.LIST_1.ITEM_12" />
                    </li>
                    <li>
                      <CheckIcon
                        htmlColor="#7ed321"
                        style={{ marginRight: '8px' }}
                      />
                      <FormattedMessage id="HPL.CHECK.LIST_1.ITEM_13" />
                    </li>
                    <li>
                      <CheckIcon
                        htmlColor="#7ed321"
                        style={{ marginRight: '8px' }}
                      />
                      <FormattedMessage id="HPL.CHECK.LIST_1.ITEM_14" />
                    </li>
                    <li>
                      <CheckIcon
                        htmlColor="#7ed321"
                        style={{ marginRight: '8px' }}
                      />
                      <FormattedMessage id="HPL.CHECK.LIST_1.ITEM_15" />
                    </li>
                    <li>
                      <CheckIcon
                        htmlColor="#7ed321"
                        style={{ marginRight: '8px' }}
                      />
                      <FormattedMessage id="HPL.CHECK.LIST_1.ITEM_16" />
                    </li>
                    <li>
                      <CheckIcon
                        htmlColor="#7ed321"
                        style={{ marginRight: '8px' }}
                      />
                      <FormattedMessage id="HPL.CHECK.LIST_1.ITEM_17" />
                    </li>
                    <li>
                      <CheckIcon
                        htmlColor="#7ed321"
                        style={{ marginRight: '8px' }}
                      />
                      <FormattedMessage id="HPL.CHECK.LIST_1.ITEM_18" />
                    </li>
                  </ul>
                </td>

                <td>
                  <ul>
                    <li>
                      <CheckIcon
                        htmlColor="#7ed321"
                        style={{ marginRight: '8px' }}
                      />
                      <FormattedMessage id="HPL.CHECK.LIST_2.ITEM_1" />
                    </li>
                    <li>
                      <CheckIcon
                        htmlColor="#7ed321"
                        style={{ marginRight: '8px' }}
                      />
                      <FormattedMessage id="HPL.CHECK.LIST_2.ITEM_2" />
                    </li>
                    <li>
                      <CheckIcon
                        htmlColor="#7ed321"
                        style={{ marginRight: '8px' }}
                      />
                      <FormattedMessage id="HPL.CHECK.LIST_2.ITEM_3" />
                    </li>
                    <li>
                      <CheckIcon
                        htmlColor="#7ed321"
                        style={{ marginRight: '8px' }}
                      />
                      <FormattedMessage id="HPL.CHECK.LIST_2.ITEM_4" />
                    </li>
                    <li>
                      <CheckIcon
                        htmlColor="#7ed321"
                        style={{ marginRight: '8px' }}
                      />
                      <FormattedMessage id="HPL.CHECK.LIST_2.ITEM_5" />
                    </li>
                    <li>
                      <CheckIcon
                        htmlColor="#7ed321"
                        style={{ marginRight: '8px' }}
                      />
                      <FormattedMessage id="HPL.CHECK.LIST_2.ITEM_6" />
                    </li>
                    <li>
                      <CheckIcon
                        htmlColor="#7ed321"
                        style={{ marginRight: '8px' }}
                      />
                      <FormattedMessage id="HPL.CHECK.LIST_2.ITEM_7" />
                    </li>
                    <li>
                      <CheckIcon
                        htmlColor="#7ed321"
                        style={{ marginRight: '8px' }}
                      />
                      <FormattedMessage id="HPL.CHECK.LIST_2.ITEM_8" />
                    </li>
                    <li>
                      <CheckIcon
                        htmlColor="#7ed321"
                        style={{ marginRight: '8px' }}
                      />
                      <FormattedMessage id="HPL.CHECK.LIST_2.ITEM_9" />
                    </li>
                    <li>
                      <CheckIcon
                        htmlColor="#7ed321"
                        style={{ marginRight: '8px' }}
                      />
                      <FormattedMessage id="HPL.CHECK.LIST_2.ITEM_10" />
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <table
            className="material-info-table table table-striped w-100"
            style={{
              border: '1px solid rgba(0, 0, 0, 0.12)',
              overflowX: 'auto',
            }}
          >
            <thead>
              <tr>
                <th
                  colSpan={5}
                  scope="col"
                  className={'w-100'}
                  style={{ fontSize: '18px' }}
                >
                  <FormattedMessage id="Algemeen" />
                </th>
              </tr>
              <tr scope="col" className={'w-100'}>
                <th scope="col" className={'w-20'}>
                  <FormattedMessage id="Testmethode" />
                </th>
                <th scope="col" className={'w-20'}>
                  <FormattedMessage id="Standaard" />
                </th>
                <th scope="col" className={'w-20'}>
                  <FormattedMessage id="Eigenschap/Kenmerk" />
                </th>
                <th scope="col" className={'w-20'}>
                  <FormattedMessage id="Eenheid " />
                </th>
                <th scope="col" className={'w-20'}>
                  <FormattedMessage id="Prestaties" />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <FormattedMessage id="Buigmodulus" />
                </td>
                <td>EN ISO 178</td>
                <td>Stress</td>
                <td>MPa</td>
                <td>9000</td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="Buigsterkte" />
                </td>
                <td>EN ISO 178</td>
                <td>Stress</td>
                <td>MPa</td>
                <td>80</td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="Methode voor het bepalen van de dichtheid" />
                </td>
                <td>ISO 1183-1</td>
                <td>Dichtheid</td>
                <td>kg/m&#179;</td>
                <td>1350</td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="Bepaling van de thermische weerstand" />
                </td>
                <td>EN 12664:200</td>
                <td>Thermische geleidbaarheid</td>
                <td>W/m K</td>
                <td>0.25</td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="Standaard testmethode voor de coëfficiënt van lineaire uitzetting" />
                </td>
                <td>ASTM D696 - 16</td>
                <td>Coëfficiënt van lineaire thermische uitzetting</td>
                <td>°C -1</td>
                <td>
                  <p>L ≈ 1.6 x 10 - 5</p>
                  <p>T ≈ 3.5 x 10 - 5</p>
                </td>
              </tr>
            </tbody>
          </table>
          <table
            className="material-info-table table table-striped w-100"
            style={{
              border: '1px solid rgba(0, 0, 0, 0.12)',
              overflowX: 'auto',
            }}
          >
            <thead>
              <tr>
                <th
                  colSpan={4}
                  scope="col"
                  className={'w-100'}
                  style={{ fontSize: '18px' }}
                >
                  <FormattedMessage id="Brandprestaties" />
                </th>
              </tr>
              <tr scope="col" className={'w-100'}>
                <th scope="col" className={'w-30'}>
                  <FormattedMessage id="Testmethode" />
                </th>
                <th scope="col" className={'w-20'}>
                  <FormattedMessage id="Standaard" />
                </th>
                <th colSpan={2} scope="col" className={'w-30'}>
                  <FormattedMessage id="Classificatie" />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <FormattedMessage id="Kleine vlam en stralingspaneel" />
                </td>
                <td>
                  <p>UNI 8457</p>
                  <p>UNI 9174</p>
                  <p>UNI 9177</p>
                </td>
                <td>Klasse 1</td>
                <td></td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="Spoorwegtoepassingen - Brandbeveiliging in railvoertuigen - Deel 2: Eisen voor brandgedrag van materialen en componenten" />
                </td>
                <td>EN 45545-2: 2013</td>
                <td>3 ≤ t ≤ 25 mm HL1 - HL2</td>
                <td></td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="Brandclassificatie van bouwproducten en bouwelementen - Deel 1: Classificatie met behulp van gegevens uit reactie op brandtests" />
                </td>
                <td>EN 13501-1</td>
                <td>
                  <p>t ≥ 2 mm</p>
                  <p>t ≥ 6 mm</p>
                  <p>t ≥ 12 mm</p>
                </td>
                <td>
                  <p>D-s2,d0</p>
                  <p>C-s2,d0</p>
                  <p>B-s1,d</p>
                </td>
              </tr>
            </tbody>
          </table>

          <table
            className="material-info-table table table-striped w-100"
            style={{
              border: '1px solid rgba(0, 0, 0, 0.12)',
              overflowX: 'auto',
            }}
          >
            <thead>
              <tr>
                <th
                  colSpan={6}
                  scope="col"
                  className={'w-100'}
                  style={{ fontSize: '18px' }}
                >
                  <FormattedMessage id="Algemeen" />
                </th>
              </tr>
              <tr scope="col" className={'w-100'}>
                <th scope="col" className={'w-20'}>
                  <FormattedMessage id="Testmethode" />
                </th>
                <th scope="col" className={'w-15'}>
                  <FormattedMessage id="Standaard" />
                </th>
                <th scope="col" className={'w-15'}>
                  <FormattedMessage id="Eigenschap/Kenmerk" />
                </th>
                <th scope="col" className={'w-15'}>
                  <FormattedMessage id="Eenheid " />
                </th>
                <th scope="col" className={'w-15'}>
                  <FormattedMessage id="Vereiste " />
                </th>
                <th scope="col" className={'w-15'}>
                  <FormattedMessage id="Prestaties" />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <FormattedMessage id="Thermische geleidbaarheid" />
                </td>
                <td>DIN 52 612</td>
                <td>-</td>
                <td>W/m °K</td>
                <td>0.25</td>
                <td>0.25</td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="Coëfficiënt van lineaire thermische uitzetting" />
                </td>
                <td>ASTM D 696</td>
                <td>-</td>
                <td>°C -1</td>
                <td>
                  <p>L = 1,6 x 10 -5 ca</p> <p>T = 3,5 x 10 -5 ca</p>
                </td>
                <td>
                  <p>L = 1,6 x 10 -5 ca</p> <p>T = 3,5 x 10 -5 ca</p>
                </td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="Treksterkte" />
                </td>
                <td>EN ISO 527-2</td>
                <td>Stress</td>
                <td>MPa</td>
                <td>
                  <p>L 100</p>
                  <p>T 70</p>
                </td>
                <td>
                  <p>L 100</p>
                  <p>T 70</p>
                </td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="Buigsterkte" />
                </td>
                <td>EN ISO 178</td>
                <td>Stress</td>
                <td>MPa</td>
                <td>
                  <p>L 100</p>
                  <p>T 90</p>
                </td>
                <td>
                  <p>L 140</p>
                  <p>T 100</p>
                </td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="Buigmodulus" />
                </td>
                <td>EN ISO 178</td>
                <td>Stress</td>
                <td>MPa</td>
                <td>
                  <p>L 10.000</p>
                  <p>T 9.000</p>
                </td>
                <td>
                  <p>L 14.000</p>
                  <p>T 10.000</p>
                </td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="Dichtheid" />
                </td>
                <td>ISO 1183-1</td>
                <td>Dichtheid</td>
                <td>kg/m&#179;</td>
                <td>1.35</td>
                <td>1.4</td>
              </tr>
            </tbody>
          </table>
          <table
            className="material-info-table table table-striped w-100"
            style={{
              border: '1px solid rgba(0, 0, 0, 0.12)',
              overflowX: 'auto',
            }}
          >
            <thead>
              <tr>
                <th
                  colSpan={3}
                  scope="col"
                  className={'w-100'}
                  style={{ fontSize: '18px' }}
                >
                  <FormattedMessage id="Brandprestaties" />
                </th>
              </tr>
              <tr scope="col" className={'w-100'}>
                <th scope="col" className={'w-30'}>
                  <FormattedMessage id="Testmethode" />
                </th>
                <th scope="col" className={'w-30'}>
                  <FormattedMessage id="Standaard" />
                </th>
                <th scope="col" className={'w-30'}>
                  <FormattedMessage id="Classificatie" />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <FormattedMessage id="Reactie op vuur" />
                </td>
                <td>EN 13501-1</td>
                <td>C,s2-d0</td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="Vlamverspreiding" />
                </td>
                <td>BS 476-7</td>
                <td>Klasse 2</td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="Brandschacht" />
                </td>
                <td>DIN 4102-1</td>
                <td>B2</td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="Epiradiator" />
                </td>
                <td>NF P 92-501</td>
                <td>M2</td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="Brandkengetal" />
                </td>
                <td>VKF</td>
                <td>5.3</td>
              </tr>
            </tbody>
          </table>
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <PDFDownloader
            title="Tolerance sheet thickness HPL - CutWise"
            pdfUrl="https://api.prod.cutwise.nl/assets/images/Tolerance sheet thickness HPL - CutWise.pdf"
            pdfFileName="toleranties"
          />

          <PDFDownloader
            title="Datasheet HPL CutWise"
            pdfUrl="https://api.prod.cutwise.nl/assets/images/Datasheet HPL CutWise.pdf"
            pdfFileName="technisch_datablad"
          />
        </TabPanel>
      </Grid>

      <Grid
        item
        container
        xs={12}
        sm={12}
        md={12}
        alignItems="center"
        justifyContent="center"
        className="materials-info-section"
      >
        <Grid item container xs={12} sm={10} alignItems="flex-start">
          <Grid item sm={6} xs={12}>
            <Grid item xs={12}>
              <Typography variant="h2">
                <FormattedMessage id="HPL.FREZEN" />
              </Typography>
              <Typography variant="body1">
                <FormattedMessage id="HPL.FREZEN.BODY" />
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h2">
                <FormattedMessage id="HPL.DESC.TITLE" />
              </Typography>
              <Typography variant="body1">
                <FormattedMessage id="HPL.DESC.BODY_1" />
              </Typography>
              <Typography variant="body1">
                <FormattedMessage id="HPL.DESC.BODY_2" />
              </Typography>
            </Grid>
          </Grid>

          <Grid item sm={6} xs={12} container justifyContent="center">
            <img
              src={toAbsoluteUrl('/clients-logos/materials-hb.svg')}
              width="100%"
              height="auto"
              style={{ padding: '20px', maxWidth: '474px' }}
            />
          </Grid>

          <Grid item xs={12} className="accordion-list">
            <Accordion
              TransitionProps={{ unmountOnExit: true }}
              expanded={index === 0}
              onChange={(event, expanded) => {
                event.preventDefault();

                setIndex(expanded ? 0 : null);
              }}
            >
              <AccordionSummary
                expandIcon={
                  index === 0 ? (
                    <RemoveCircleOutlineIcon fontSize="large" />
                  ) : (
                    <AddCircleOutlineIcon fontSize="large" />
                  )
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="body1">
                  <FormattedMessage id="HPL.ACCORDION_1.TITLE" />
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="body1"
                  style={{
                    marginBottom: '0',
                    fontSize: '20px',
                    fontWeight: 600,
                  }}
                >
                  <FormattedMessage id="HPL.ACCORDION_1.LIST.TITLE" />
                </Typography>

                <ul>
                  <li>
                    <FormattedMessage id="HPL.ACCORDION_1.LIST.LIST_1" />
                  </li>
                  <li>
                    <FormattedMessage id="HPL.ACCORDION_1.LIST.LIST_2" />
                  </li>
                  <li>
                    <FormattedMessage id="HPL.ACCORDION_1.LIST.LIST_3" />
                  </li>
                  <li>
                    <FormattedMessage id="HPL.ACCORDION_1.LIST.LIST_4" />
                  </li>
                  <li>
                    <FormattedMessage id="HPL.ACCORDION_1.LIST.LIST_5" />
                  </li>
                  <li>
                    <FormattedMessage id="HPL.ACCORDION_1.LIST.LIST_6" />
                  </li>
                </ul>

                {intl?.locale === 'nl' && (
                  <iframe
                    title="BMTEC - PS lasersnijden"
                    width="815"
                    height="580"
                    src="https://www.youtube.com/embed/cgBYh5lZSBQ"
                    style={{ border: 0 }}
                    loading="lazy"
                    allowFullScreen
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                )}
              </AccordionDetails>
            </Accordion>
            <Accordion
              TransitionProps={{ unmountOnExit: true }}
              expanded={index === 1}
              onChange={(event, expanded) => {
                event.preventDefault();

                setIndex(expanded ? 1 : null);
                window.scrollTo(0, topPanel2);
              }}
            >
              <AccordionSummary
                expandIcon={
                  index === 1 ? (
                    <RemoveCircleOutlineIcon fontSize="large" />
                  ) : (
                    <AddCircleOutlineIcon fontSize="large" />
                  )
                }
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography variant="body1">
                  <FormattedMessage id="HPL.ACCORDION_2.TITLE" />
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="body1"
                  style={{
                    marginBottom: '0',
                    fontSize: '20px',
                    fontWeight: 600,
                  }}
                >
                  <FormattedMessage id="HPL.ACCORDION_2.LIST.TITLE" />
                </Typography>

                <ul>
                  <li>
                    <span style={{ fontWeight: 600 }}>
                      <FormattedMessage id="HPL.ACCORDION_2.LIST.LIST_1" />
                    </span>
                    : &nbsp;
                    <FormattedMessage id="HPL.ACCORDION_2.LIST.LIST_1.DESC" />
                  </li>
                  <li>
                    <span style={{ fontWeight: 600 }}>
                      <FormattedMessage id="HPL.ACCORDION_2.LIST.LIST_2" />
                    </span>
                    : &nbsp;
                    <FormattedMessage id="HPL.ACCORDION_2.LIST.LIST_2.DESC" />
                  </li>
                  <li>
                    <span style={{ fontWeight: 600 }}>
                      <FormattedMessage id="HPL.ACCORDION_2.LIST.LIST_3" />
                    </span>
                    : &nbsp;
                    <FormattedMessage id="HPL.ACCORDION_2.LIST.LIST_3.DESC" />
                  </li>
                  <li>
                    <span style={{ fontWeight: 600 }}>
                      <FormattedMessage id="HPL.ACCORDION_2.LIST.LIST_4" />
                    </span>
                    : &nbsp;
                    <FormattedMessage id="HPL.ACCORDION_2.LIST.LIST_4.DESC" />
                  </li>
                  <li>
                    <span style={{ fontWeight: 600 }}>
                      <FormattedMessage id="HPL.ACCORDION_2.LIST.LIST_5" />
                    </span>
                    : &nbsp;
                    <FormattedMessage id="HPL.ACCORDION_2.LIST.LIST_5.DESC" />
                  </li>
                  <li>
                    <span style={{ fontWeight: 600 }}>
                      <FormattedMessage id="HPL.ACCORDION_2.LIST.LIST_6" />
                    </span>
                    : &nbsp;
                    <FormattedMessage id="HPL.ACCORDION_2.LIST.LIST_6.DESC" />
                  </li>
                  <li>
                    <span style={{ fontWeight: 600 }}>
                      <FormattedMessage id="HPL.ACCORDION_2.LIST.LIST_7" />
                    </span>
                    : &nbsp;
                    <FormattedMessage id="HPL.ACCORDION_2.LIST.LIST_7.DESC" />
                  </li>
                </ul>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </Grid>

      <CommonSection materialType="HPL" />
    </Grid>
  );
};

export default injectIntl(HPLMaterialLandingPage);
