export function makeLoadingObj(status, svgNames, loadedName, oldState) {

    if(loadedName) {
        return {
            loading: {
                status,
                uploadingSvgs: oldState.uploadingSvgs.filter(nameWpromis => nameWpromis.name !== loadedName),
            }
        }
    } else if(status && !svgNames && !loadedName) {
        return {
            loading: {
                status,
                uploadingSvgs: oldState.uploadingSvgs,
            }
        }
    }

    return {
        loading: {
            status,
            uploadingSvgs: svgNames ? svgNames : oldState.uploadingSvgs,
        }
    }
}

export async function uploadedSVGasync(uploadedSvgs, currentSvg, id) {

    let returnSVG;

    await new Promise.resolve(  
        returnSVG = uploadedSvgs.map((item) =>
            item.id === id ? currentSvg : item
        )
    );

    return returnSVG;
};