import React from 'react';
import { Route, Switch } from 'react-router-dom';

import FormPage from './FormPage';
import { ROUTES } from "../../../common/constants/routes/routes";

export default function AppSettings () {
  return (
    <Switch>
      <Route exact path={ROUTES.APP_SETTINGS} component={FormPage} />
    </Switch>
  );
}
