import { createAction } from 'redux-actions';

export const getHourlyRates = createAction('GET_HOURLY_RATES_REQUEST');
export const getHourlyRatesSuccess = createAction('GET_HOURLY_RATES_SUCCESS');
export const getHourlyRatesError = createAction('GET_HOURLY_RATES_ERROR');
export const getHourlyRate = createAction('GET_HOURLY_RATE_REQUEST');
export const getHourlyRateSuccess = createAction('GET_HOURLY_RATE_SUCCESS');
export const getHourlyRateError = createAction('GET_HOURLY_RATE_ERROR');
export const deleteHourlyRate = createAction('DELETE_HOURLY_RATE_REQUEST');
export const deleteHourlyRateSuccess = createAction('DELETE_HOURLY_RATE_SUCCESS');
export const deleteHourlyRateError = createAction('DELETE_HOURLY_RATE_ERROR');
export const updateHourlyRate = createAction('UPDATE_HOURLY_RATE_REQUEST');
export const updateHourlyRateSuccess = createAction('UPDATE_HOURLY_RATE_SUCCESS');
export const updateHourlyRateError = createAction('UPDATE_HOURLY_RATE_ERROR');
