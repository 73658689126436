import React, {useEffect, useState} from 'react';
import Papa from 'papaparse';
import Multiselect from 'multiselect-react-dropdown';
import initApi from '../../../../crud/apiConfig/apiConfig';
import {FormattedMessage, useIntl} from 'react-intl';
import {PortletBody} from '../../../../partials/content/Portlet';
import {Button, Col, Form, Row} from 'react-bootstrap';
import {API_URL, API_URLS} from '../../../../common/constants/api/apiConstants';
import {REQUIRED_MATERIALS_CSV_FIELDS} from '../../../../common/constants/constants';

export const Import = () => {

    const intl = useIntl();
    const [error, setError] = useState(undefined);
    const [success, setSuccess] = useState(undefined);
    const [progress, setProgress] = useState(false);
    // const [importType, setImportType] = useState('IMPORT');
    const [importFile, setImportFile] = useState(undefined);
    const [importOption, setImportOption] = useState(undefined);
    // const [validated, setValidated] = useState(false);
    const [disabled, setDisabled] = useState(true);

    useEffect(() => {
        if (/*importType &&*/ importFile ) {
            checkCSVFile(importFile);
        }
    }, [importOption, importFile /* importType */ ])

    const importOptions = [
        {
            title: intl.formatMessage({id: 'IMPORT.MATERIALS'}),
            type: "materials",
        },
    ];

    const checkCSVFile = async (csv_file) => {
        const reader = new FileReader();

        reader.onload = async ({target}) => {

            let checkCSV = await Papa.parse(target.result, {header: true});
            let parsedData = checkCSV?.data;
            let csvColumns = Object.keys(parsedData[0]);
            let columnsCheck = '';

            REQUIRED_MATERIALS_CSV_FIELDS.forEach(required => {
                if (!csvColumns.includes(required)) {
                    columnsCheck = required;
                }
                ;
            });

            if (columnsCheck.length) {
                setError({message: `Missing field '${columnsCheck}' in CSV File.`})
                setDisabled(true);
            } else {
                setDisabled(false)
            }

        };

        reader.readAsText(csv_file);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        const data = new FormData();
        data.append('csv_file', importFile);
        // data.append('type', importType);

        setProgress(intl.formatMessage({id: 'IMPORT.PROCESSING'}));

        let uploadUrl = API_URL + API_URLS.IMPORT_CSV_FILE(importOption);
        let uploadResult = await initApi().post(uploadUrl, data);

        setProgress(false);
        setSuccess(`Processed ${uploadResult.data.processed_items} Items`);
        setTimeout(function () {
            setSuccess(false);
        }, 5000);
    }

    return (
        <PortletBody>
            <div className="kt-section kt-margin-t-30">
                <div className="kt-section__body w-100">
                    <Form
                        noValidate
                        validated={false /*validated*/}
                        onSubmit={handleSubmit}
                    >
                        <Form.Group as={Row} className="mb-3">
                            <Col sm={{span: 4, offset: 2}}>
                                {progress &&
                                    <div className="alert alert-solid-warning alert-bold fade show kt-margin-t-20 kt-margin-b-40" role="alert">
                                        <div className="alert-icon">
                                            <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                                        </div>
                                        <div className="alert-text">{progress}</div>
                                    </div>
                                }

                                {error &&
                                    <div className="alert alert-solid-danger alert-bold fade show kt-margin-t-20 kt-margin-b-40" role="alert">
                                        <div className="alert-icon"><i className="fa fa-exclamation-triangle"></i>
                                        </div>
                                        <div className="alert-text">{error.message}</div>
                                    </div>
                                }

                                {success &&
                                    <div className="alert alert-solid-success alert-bold fade show kt-margin-t-20 kt-margin-b-40" role="alert">
                                        <div className="success-icon"><i className="fa fa-exclamation-triangle"></i>
                                        </div>
                                        <div className="success-text">{success}</div>
                                    </div>
                                }
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                            <Form.Label column sm="2">
                                <FormattedMessage id={'IMPORT.OPTION'}/>
                            </Form.Label>
                            <Col sm="4">
                                {importOptions && <Multiselect
                                    options={importOptions}
                                    selectedValues={[importOptions[0]]}
                                    singleSelect={true}
                                    onSelect={(selectedList) => {
                                        setImportOption(selectedList[0].type);
                                    }}
                                    placeholder={intl.formatMessage({id: 'IMPORT.SELECT'})}
                                    displayValue="title"
                                />}
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                            <Form.Label column sm="2">
                                <FormattedMessage id={'IMPORT.FILE'}/>
                            </Form.Label>
                            <Col sm="4">
                                <Form.Control
                                    type="file"
                                    accept=".csv"
                                    label={importFile?.name}
                                    onChange={(e) => {
                                        setImportFile(e.target.files[0]);
                                    }}
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                            <Form.Label column sm="2"></Form.Label>
                            <Col sm="4">
                                <Button disabled={disabled} variant="primary" type="submit">
                                    <FormattedMessage id={'IMPORT'}/>
                                </Button>
                            </Col>
                        </Form.Group>

                    </Form>
                </div>
            </div>
        </PortletBody>
    )

}
