import { handleActions } from "redux-actions";
import {
  getDeliveryDays,
  getDeliveryDaysSuccess,
  getDeliveryDaysError
} from "./actions";

const initialState = {
  isProcessing: false,
  data: [],
  error: "",
  pagination: {
    per_page: 10,
    current_page: 1,
    count: 0
  }
};

const handlers = {
  [getDeliveryDays]: (state) => ({
    ...state,
    isProcessing: true,
  }),
  [getDeliveryDaysSuccess]: (state, action) => ({
    ...state,
    isProcessing: false,
    data: action.payload,
    pagination: {
      ...state.pagination,
      count: (action.payload && action.payload.length) || 0
    }
  }),
  [getDeliveryDaysError]: (state, action) => ({
    ...state,
    isProcessing: false,
    error: action.payload
  })
};

export default handleActions(handlers, initialState);
