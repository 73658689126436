import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const MetaTags = ({ title, description, keywords, canonicalUrl }) => {
  return (
    <Helmet htmlAttributes={{ reverse: true }}>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
    </Helmet>
  );
};

export default MetaTags;

MetaTags.defaultProps = {
  title: '',
  description: '',
  keywords: '',
  canonicalUrl: '',
};

MetaTags.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  keywords: PropTypes.string,
  canonicalUrl: PropTypes.string,
};
