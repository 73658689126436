import { API_URLS } from '../common/constants/api/apiConstants';
import initApi from './apiConfig/apiConfig';
import axios from 'axios';

export async function convertDxfRequest(
  data,
  fileType = 'dxf',
  withAbort,
  request
) {
  if (withAbort) {
    const response = await axios(request).catch(() =>
      console.warn(`request abort status:${request.signal.aborted}`)
    );

    return response;
  }

  return initApi().post(API_URLS.CONVERT_DXF, data, {
    params: {
      type: fileType,
    },
  });
}

export async function convertDxfSimple(data, fileType = 'dxf', part_lib = false) {
  
  return initApi().post(API_URLS.CONVERT_DXF, data, {
  params: {
    type: fileType,
    part_lib
  },
  });
}

export function materialDataRequest() {
  return initApi().get(API_URLS.MATERIAL_DATA);
}

export function saveProducts(data, params = {}) {
  return initApi().post(API_URLS.SAVE_PRODUCTS, data, { params });
}

export function getQuoteSvg(id) {
  return initApi().get(API_URLS.GET_PRODUCTS_SVG(id));
}

export function getEditProject(id) {
  return initApi().get(API_URLS.GET_EDIT_PROJECT(id), {
    params: {
      include:
        'products.material,products.bendings,order,order.shipping_address.country,order.billing_address.country',
    },
  });
}

export function saveChangeProduct(id, data) {
  return initApi().put(API_URLS.GET_SAVE_CHANGE_PRODUCT(id), data);
}
