/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { FormattedMessage, injectIntl } from 'react-intl';
import { toast } from 'react-toastify';

import {
  toAbsoluteUrl,
  getToleranceTableValue,
} from '../../../../../_metronic';
import { ROUTES } from '../../../../common/constants/routes/routes';
import {
  API_URL,
  API_URLS,
} from '../../../../common/constants/api/apiConstants';
import initApi from '../../../../crud/apiConfig/apiConfig';
import { quotesActions } from '../../../../store/ducks/quotesReducer';
import { drawActions } from '../../../../store/ducks/draw.duck';
import '../../../../../_metronic/_assets/sass/pages/invoices/invoice-2.scss';
import '../../../../styles/print.css';

function DetailsPage(props) {
  const [showCalculation, setShowCalculation] = useState(0);

  const {
    data,
    isProcessing,
    admin,
    match: {
      params: { id },
    },
    history,
    quoteDetailedRequest,
    resetDraw,
    intl,
  } = props;

  const showSuccessToast = () => {
    toast.success(intl.formatMessage({ id: 'Quote saved succesfully' }), {
      position: 'bottom-right',
      autoClose: 2500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    });

    history.push({ state: { newQuote: false } });
  };

  const gtagID = {
    'cutwise.bmtec-laserschneiden.de': 'AW-10814647505/mE16CNOhvqsZENHZ6aQo',
    'cutwise.bmtec-lasercutting.com': 'AW-11391537181/77gCCPqQxKsZEJ2g9Lcq',
    'cutwise.bmtec.nl': 'AW-833061246/DY2yCOC3vqsZEP6Cno0D',
  };

  React.useEffect(() => {
    quoteDetailedRequest(id);
    resetDraw();
    if (history.location?.state?.newQuote) {
      showSuccessToast();
    }
  }, []);

  React.useEffect(() => {
    if (typeof window !== 'undefined' && typeof window.gtag === 'function') {
      if (data) {
        window.gtag('event', 'conversion', {
          send_to:
            gtagID[window.location.host] || 'AW-833061246/DY2yCOC3vqsZEP6Cno0D',
          value: data.project && data.project.price.toFixed(2),
          currency: 'EUR',
          email: data.user && data.user.email,
          email_invoices: data.user && data.user.email_invoices,
        });
      }
    }
  }, [data]);

  if (isProcessing) {
    return 'Loading...';
  }

  if (!data) {
    return null;
  }

  const {
    project,
    shipping_address,
    billing_address,
    shipping_type,
    reference,
  } = data;

  const navigateToCheckOut = async (event, item) => {
    event.stopPropagation();
    const response = await initApi().get(
      API_URL + API_URLS.COPY_ORDER_TO_QUOTE(item?.project?.id)
    );

    if (response?.data?.success) {
      if (item.is_quote_expired) {
        history.push(`/checkout/${response.data.project_id}?recalculate=true`);
      } else {
        history.push(
          `/checkout/${response.data.project_id}?old_project_id=${item?.project?.id}`
        );
      }
    }
  };

  const selectEditQuote = (event) => {
    history.push(`${ROUTES.CONFIGURATION}/${event.target.id}`);
  };

  const getColorName = (item) => {
    let color_name = item.color;
    if (intl.locale == 'en' && item.color_en != '') {
      color_name = item.color_en;
    }
    if (intl.locale == 'de' && item.color_de != '') {
      color_name = item.color_de;
    }
    return color_name;
  };

  let deliveryDay = new Date();
  if (project && project.created_at) {
    deliveryDay = new Date(project.created_at * 1000);
    let countAll = 0;
    for (let count = 0; countAll != project.delivery_days; count++) {
      deliveryDay.setDate(deliveryDay.getDate() + 1);
      if (deliveryDay.getDay() != 0 && deliveryDay.getDay() != 6) {
        countAll++;
      }
    }
  }
  let formattedDeliveryDate = format(deliveryDay, 'dd-MM-yyyy');

  return (
    <>
      <div className="kt-container  kt-grid__item kt-grid__item--fluid">
        <div className="kt-portlet section-to-print">
          <div className="kt-portlet__body kt-portlet__body--fit">
            <div className="kt-invoice-2">
              <div className="kt-invoice__head">
                <div className="kt-invoice__container">
                  <div className="kt-invoice__items">
                    <div className="kt-invoice__item">
                      <img
                        className="kt-invoice__logo"
                        alt="logo"
                        src={toAbsoluteUrl(intl.formatMessage({ id: 'LOGO' }))}
                      />
                      <strong>
                        <FormattedMessage id="QUOTE.QUOTE" /> {id}{' '}
                      </strong>
                      {reference ? reference : ''}
                      <br />
                      <br />
                      <strong>
                        <FormattedMessage id="ORDER.SHIPPING_ADDRESS" />
                      </strong>
                      {shipping_address && shipping_address.country && (
                        <>
                          <span>{shipping_address?.company_name}</span>
                          <span>
                            {shipping_address?.first_name}{' '}
                            {shipping_address?.last_name}
                          </span>
                          <span>
                            {shipping_address?.address}{' '}
                            {shipping_address?.house_number}{' '}
                            {shipping_address?.ext}
                          </span>
                          <span>
                            {shipping_address?.postal_code}{' '}
                            {shipping_address?.city}
                          </span>
                          <span>
                            <FormattedMessage
                              id={shipping_address?.country?.name}
                            />
                          </span>
                          {shipping_address.phone_number}
                          <span className={'google-email'}>
                            {data?.user?.email_invoices ?? data?.user?.email}
                          </span>
                        </>
                      )}
                      <br />
                      <br />
                      {billing_address && billing_address.country ? (
                        <>
                          <strong>
                            <FormattedMessage id="ORDER.BILLING_ADDRESS" />
                          </strong>
                          <span>
                            {billing_address?.first_name}{' '}
                            {billing_address?.last_name}
                          </span>
                          <span>
                            {billing_address?.address}{' '}
                            {billing_address?.house_number}{' '}
                            {billing_address?.ext}
                          </span>
                          <span>
                            {billing_address?.postal_code}{' '}
                            {billing_address?.city}
                          </span>
                          <span>
                            <FormattedMessage
                              id={billing_address?.country?.name}
                            />
                          </span>
                          <span>{billing_address.phone_number}</span>
                        </>
                      ) : (
                        ''
                      )}
                    </div>

                    <div className="kt-invoice__item text-right">
                      <span className="kt-invoice__desc">
                        <strong>{data?.location?.company_name}</strong>
                        <br />
                        {data?.location?.address} {data?.location?.house_number}{' '}
                        {data?.location?.ext}
                        <br />
                        {data?.location?.postal_code} {data?.location?.city}
                        <br />
                        {data?.location?.country?.name}
                        <br /> <br />
                        {data?.location?.email} <br />
                        {data?.location?.phone_number}
                        <br /> <br />
                        {data?.location?.coc_number}
                        <br />
                        {data?.location?.tax_number}
                        <br />
                        {data?.location?.iban_number}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="kt-invoice__body">
                <div className="kt-invoice__container">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>
                            <FormattedMessage id="QUOTE.QUOTE" />
                          </th>
                          <th>
                            <FormattedMessage id="QUOTE.PART" />
                          </th>
                          <th>
                            <FormattedMessage id="QUOTE.QTY" />
                          </th>
                          <th>
                            <FormattedMessage id="QUOTE.PRICE_PER_PIECE" />
                          </th>
                          <th>
                            <FormattedMessage id="QUOTE.PRICE" />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {project.products &&
                          project.products.map((item) => (
                            <>
                              <tr key={item.id}>
                                <td>
                                  {item.name}
                                  <br />
                                  <small>
                                    <FormattedMessage id="QUOTE.MATERIAL" />:{' '}
                                    {item.material.name}
                                  </small>
                                  <br />
                                  <small>
                                    <FormattedMessage id="QUOTE.THICKNESS" />:{' '}
                                    {parseFloat(item.material.thickness)}
                                    mm
                                  </small>
                                  <br />
                                  <small>
                                    <FormattedMessage id="QUOTE.COLOR" />:{' '}
                                    {getColorName(item.material)}
                                  </small>
                                  <br />
                                  <small>
                                    <FormattedMessage id="QUOTE.SIZE" />:{' '}
                                    {item.width.toFixed(1)} x{' '}
                                    {item.height.toFixed(1)}mm
                                  </small>
                                  <br />
                                  {item?.width >= item?.height && (
                                    <small className={'text-muted'}>
                                      <FormattedMessage
                                        id={'CONFIGURATOR.TOLERANCE'}
                                      />
                                      +/-&nbsp;
                                      {getToleranceTableValue(
                                        parseFloat(item?.width)
                                      )}{' '}
                                      mm
                                    </small>
                                  )}

                                  {item?.height > item?.width && (
                                    <small className={'text-muted'}>
                                      <FormattedMessage
                                        id={'CONFIGURATOR.TOLERANCE'}
                                      />
                                      +/-&nbsp;
                                      {getToleranceTableValue(
                                        parseFloat(item?.height)
                                      )}{' '}
                                      mm
                                    </small>
                                  )}
                                  <br />
                                  {item?.bendings?.length > 0 &&
                                    item?.bendings.map((bending, index) => {
                                      return (
                                        <small key={`bending-${index}`}>
                                          {bending?.direction ===
                                            'vertical' && (
                                            <FormattedMessage id="BENDING.VERTICAL" />
                                          )}
                                          {bending?.direction ===
                                            'horizontal' && (
                                            <FormattedMessage id="BENDING.HORIZONTAL" />
                                          )}
                                          : offset {bending?.offset} mm
                                          {' / '}
                                          {bending?.degrees}&#176;{' '}
                                          <FormattedMessage id="BENDING.DEGREES" />
                                          {' / '}
                                          {bending?.side === 'down' && (
                                            <FormattedMessage id="BENDING.DOWN" />
                                          )}
                                          {bending?.side === 'up' && (
                                            <FormattedMessage id="BENDING.UP" />
                                          )}
                                        </small>
                                      );
                                    })}
                                  <br />
                                  {item.foil_removal && (
                                    <>
                                      <small>
                                        <FormattedMessage id="ORDER.FOIL_REMOVAL" />
                                      </small>
                                      <br />
                                    </>
                                  )}
                                  <br />
                                  <a
                                    id={project.id}
                                    className="kt-font-sm"
                                    href="#"
                                    onClick={selectEditQuote}
                                  >
                                    <FormattedMessage id="QUOTE.EDIT_IN_CONFIGURATOR" />
                                  </a>
                                  <br />
                                  {admin && (
                                    <>
                                      <a
                                        className="kt-font-sm"
                                        href={`${API_URL}/api/product/${item.id}/download/dxf`}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        Download DXF
                                      </a>
                                      <br />
                                      <a
                                        className="kt-font-sm"
                                        href={`${API_URL}/api/product/${item.id}/download/dxf-original`}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        {item.name.indexOf('.stp') !== -1 ||
                                        item.name.indexOf('.step') !== -1 ||
                                        item.name.indexOf('.STP') !== -1 ||
                                        item.name.indexOf('.STEP') !== -1
                                          ? 'Download STP Original'
                                          : 'Download DXF Original'}
                                      </a>
                                    </>
                                  )}
                                  <br />
                                </td>
                                <td
                                  width={100}
                                  height={100}
                                  className="text-center"
                                >
                                  <img
                                    src={`${API_URL}/projects${item.svg}`}
                                    alt=""
                                    width={80}
                                    height={80}
                                  />
                                </td>
                                <td>{item.quantity}</td>
                                <td>
                                  € {(item.price / item.quantity).toFixed(2)}
                                </td>
                                <td
                                  className="kt-font-danger kt-font-lg"
                                  onClick={() => {
                                    setShowCalculation(
                                      showCalculation == item.id ? 0 : item.id
                                    );
                                  }}
                                >
                                  € {item.price.toFixed(2)}
                                </td>
                              </tr>
                              {showCalculation == item.id && admin && (
                                <>
                                  <tr key={item.id + 'calc'}>
                                    <td colSpan={5}>
                                      <small>
                                        {item.calculation
                                          .split('\r\n')
                                          .map((calcItem, index) => {
                                            return (
                                              <span
                                                style={{ display: 'block' }}
                                                key={index}
                                              >
                                                {calcItem}
                                              </span>
                                            );
                                          })}
                                      </small>
                                    </td>
                                  </tr>
                                </>
                              )}
                            </>
                          ))}

                        <tr>
                          <td>
                            <FormattedMessage id="QUOTE.STARTUP_COSTS" />
                          </td>
                          <td />
                          <td />
                          <td />
                          <td className="kt-font-danger kt-font-lg">
                            € {project.startup_costs.toFixed(2)}
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <FormattedMessage id="QUOTE.SHIPPING_COSTS" />{' '}
                            {shipping_type
                              ? '(' + shipping_type.toUpperCase() + ')'
                              : ''}
                          </td>
                          <td />
                          <td />
                          <td />
                          <td className="kt-font-danger kt-font-lg">
                            € {project.shipping_costs.toFixed(2)}
                          </td>
                        </tr>
                        {project.discount > 0 && (
                          <tr>
                            <td>
                              <FormattedMessage id="ORDER.DISCOUNT" />
                            </td>
                            <td />
                            <td />
                            <td />
                            <td className="kt-font-danger kt-font-lg">
                              € -{project.discount.toFixed(2)}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="kt-invoice__footer">
                <div className="kt-invoice__container">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>
                            <FormattedMessage id="QUOTE.DAYS_TO_DELIVERY" />
                          </th>
                          <th>
                            <FormattedMessage id="QUOTE.ESTIMATED_DELIVERY" />
                          </th>
                          <th className="text-right">
                            <FormattedMessage id="QUOTE.VAT" />
                          </th>
                          <th className="text-right">
                            <FormattedMessage id="QUOTE.TOTAL" />{' '}
                            <FormattedMessage id="QUOTE.EXCL" />{' '}
                            <FormattedMessage id="QUOTE.VAT" />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="kt-font-danger kt-font-xl kt-font-boldest">
                            {project.delivery_days == 3 &&
                            shipping_address.country?.id != 1
                              ? 5
                              : project.delivery_days}{' '}
                            <FormattedMessage id="QUOTE.DAYS" />
                          </td>
                          <td className="kt-font-danger kt-font-xl kt-font-boldest">
                            {project?.delivery_days
                              ? formattedDeliveryDate
                              : ''}
                          </td>
                          <td className="kt-font-danger kt-font-xl kt-font-boldest text-right">
                            € {project.vat.toFixed(2)}
                          </td>
                          <td className="kt-font-danger kt-font-xl kt-font-boldest text-right">
                            € {project.price.toFixed(2)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="kt-invoice__actions">
                <div className="kt-invoice__container">
                  <button
                    type="button"
                    className="btn btn-label-brand btn-bold"
                    onClick={window.print}
                  >
                    <FormattedMessage id="QUOTE.DOWNLOAD_QUOTE" />
                  </button>
                  <button
                    type="button"
                    className="btn btn-brand btn-bold"
                    onClick={(e) => {
                      navigateToCheckOut(e, data);
                    }}
                  >
                    <FormattedMessage id="QUOTE.PLACE_ORDER" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

DetailsPage.propTypes = {
  isProcessing: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  quoteDetailedRequest: PropTypes.func.isRequired,
  resetDraw: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

const mapStateToProps = ({
  auth: {
    user: { admin },
  },
  auth: { user },
  quotes: {
    selectedQuoteDetails: { data, isProcessing },
  },
}) => ({
  data,
  isProcessing,
  user,
  admin,
});

const mapDispatchToProps = {
  quoteDetailedRequest: (id) => quotesActions.getQuoteDetailedRequest(id),
  resetDraw: () => drawActions.resetDraw(),
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(DetailsPage)
);
