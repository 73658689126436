export const priceCalculator = ({
  area,
  length,
  quantity = 1,
  materialPrice,
  cuttingSpeed,
  rate =  202,
  minimalRate = 74,
  startingFrom = false,
}) => {

  // console.log({area, length, quantity, materialPrice, cuttingSpeed, rate, minimalRate, startingFrom});

  const toFixedArea = Math.round(area * 10000) / 10000;
  const toFixedLength = Math.round(length * 10000) / 10000;

  let basePricePerMM2 = Number(materialPrice) / 1000000;
  let basePricePerProduct = (toFixedArea * basePricePerMM2);

  let cuttingTimeInSeconds = toFixedLength / cuttingSpeed;

  let totalCuttingTimeInMinutes = (cuttingTimeInSeconds * quantity) / 60;
  let amountOfMinutes = Math.floor(totalCuttingTimeInMinutes / 5);

  if (amountOfMinutes > 11) {
    amountOfMinutes = 11;
  }

  let hourlyDiscount = amountOfMinutes * 7.5;

  if (totalCuttingTimeInMinutes > 60) {
    totalCuttingTimeInMinutes -= 60;
    let hoursLeftAfterOneHour = Math.floor(totalCuttingTimeInMinutes / 60);
    hourlyDiscount += hoursLeftAfterOneHour;
  }

  let finalRate = rate - hourlyDiscount;

  if (finalRate < minimalRate || startingFrom) {
    finalRate = minimalRate;
  }
  
  let cuttingTimeCost = (cuttingTimeInSeconds * finalRate) / 3600;

  let pricePerItem = Math.round((basePricePerProduct + cuttingTimeCost) * 100) / 100;
  let totalPrice = pricePerItem * quantity;

  return Number(totalPrice.toFixed(2));
};