import { createAction } from 'redux-actions';

export const getMaterials = createAction('GET_MATERIALS_REQUEST');
export const getMaterialsSuccess = createAction('GET_MATERIALS_SUCCESS');
export const getMaterialsError = createAction('GET_MATERIALS_ERROR');
export const getMaterial = createAction('GET_MATERIAL_REQUEST');
export const getMaterialSuccess = createAction('GET_MATERIAL_SUCCESS');
export const getMaterialError = createAction('GET_MATERIAL_ERROR');
export const deleteMaterial = createAction('DELETE_MATERIAL_REQUEST');
export const deleteMaterialSuccess = createAction('DELETE_MATERIAL_SUCCESS');
export const deleteMaterialError = createAction('DELETE_MATERIAL_ERROR');
export const updateMaterial = createAction('UPDATE_MATERIAL_REQUEST');
export const updateMaterialSuccess = createAction('UPDATE_MATERIAL_SUCCESS');
export const updateMaterialError = createAction('UPDATE_MATERIAL_ERROR');
