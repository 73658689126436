import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "./ducks/auth.duck";
import { metronic } from "../../_metronic";
import authSagas from "../sagas/auth/authSagas";
import drawSagas from "../sagas/draw/drawSagas";
import { drawReducer } from "./ducks/draw.duck";
import { checkoutReducer } from "./ducks/checkout.duck";
import checkoutSagas from "../sagas/checkout/checkoutSagas";
import ordersSagas from "../sagas/orders/ordersSagas";
import materialsSagas from "./materials/sagas";
import threadsSagas from "./threads/sagas";
import vatsSagas from "./vats/sagas";
import hourlyRatesSagas from "./hourlyRates/sagas";
import paymentTypesSagas from "./paymentTypes/sagas";
import priceRulesSagas from "./priceRules/sagas";
import shippingCostsSagas from "./shippingCosts/sagas";
import shippingSagas from "./shipping/sagas";
import messagesSagas from "./messages/saga";
import usersSagas from "./users/sagas";
import countriesSagas from "./countries/sagas";
import deliveryDaysSagas from "./deliveryDays/sagas";
import partlibrarySagas from "../sagas/partlibrary/partlibrarySagas";

import { ordersReducer } from "./ducks/ordersReducer";
import {folderReducer} from "./ducks/folderReducer";
import { qoutesReducer } from "./ducks/quotesReducer";
import materialsReducer from "./materials/reducer";
import threadsReducer from "./threads/reducer";
import vatsReducer from "./vats/reducer";
import priceRulesReducer from "./priceRules/reducer";
import hourlyRatesReducer from "./hourlyRates/reducer";
import paymentTypesReducer from "./paymentTypes/reducer";
import shippingCostsReducer from "./shippingCosts/reducer";
import shippingReducer from "./shipping/reducer";
import messagesReducer from "./messages/reducer";
import usersReducer from "./users/reducer";
import countriesReducer from "./countries/reducer";
import deliveryDaysReducer from "./deliveryDays/reducer";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  i18n: metronic.i18n.reducer,
  builder: metronic.builder.reducer,
  draw: drawReducer,
  checkout: checkoutReducer,
  orders: ordersReducer,
  quotes: qoutesReducer,
  folders: folderReducer,

  materials: materialsReducer,
  threads: threadsReducer,
  vats: vatsReducer,
  hourlyRates: hourlyRatesReducer,
  paymentTypes: paymentTypesReducer,
  priceRules: priceRulesReducer,
  shippingCosts: shippingCostsReducer,
  shipping: shippingReducer,
  messages: messagesReducer,
  users: usersReducer,
  countries: countriesReducer,
  deliveryDays: deliveryDaysReducer,
});

export function* rootSaga() {
  yield all([
    authSagas(),
    drawSagas(),
    checkoutSagas(),
    ordersSagas(),
    priceRulesSagas(),
    materialsSagas(),
    threadsSagas(),
    vatsSagas(),
    hourlyRatesSagas(),
    paymentTypesSagas(),
    shippingCostsSagas(),
    shippingSagas(),
    messagesSagas(),
    usersSagas(),
    countriesSagas(),
    deliveryDaysSagas(),
    partlibrarySagas(),
  ]);
}
