import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Orders from './orders/Orders';
import Quotes from './quotes/Quotes';
import Vats from './vats';
import Materials from './materials';
import Materials360 from './materials360';
import Bending from './bending';
import Shipping from './shipping';
import Threads from './threads';
import Users from './users';
import HourlyRates from './hourlyRates';
import PriceRules from './priceRules';
import Countries from './countries';
import Configurator from './configurator/Configurator';
import ConfiguratorNew from './configurator-new/ConfiguratorNew';
import Checkout from './checkout/Checkout';
import CheckStatus from './checkstatus/CheckStatus';
import Pages from './pages/Pages';
import Dashboard from './Dashboard';
import Settings from './settings';
import Uploads from './uploads';
import AppSettings from './appSettings';
import ImportExport from './importExport';
import AdminUpdates from './admin-updates';
import Exact from './Exact';
import Sketcher from './sketcher';
import MyPartLibrary from './mypartlibrary';
import PartLibrary from "./partLibrary";
import PartsInLib from './partsInLib';

import { LayoutSplashScreen } from '../../../_metronic';
import { ROUTES } from '../../common/constants/routes/routes';

// const GoogleMaterialPage = lazy(() =>
//   import("./google-material/GoogleMaterialPage")
// );
// const ReactBootstrapPage = lazy(() =>
//   import("./react-bootstrap/ReactBootstrapPage")
// );

export default function HomePage() {
  // Test

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to={ROUTES.CONFIGURATION} />
        }
        <Route path={ROUTES.LP_ADVERTISING} component={Configurator} />
        <Route path={ROUTES.LP_ADVERTISING_NL} component={Configurator} />
        <Route path={ROUTES.LP_ADVERTISING_DE} component={Configurator} />
        <Route path={ROUTES.LP_ADVERTISING_DK} component={Configurator} />
        <Route path={ROUTES.CONFIGURATION} component={Configurator} />
        <Route path={ROUTES.CONFIGURATIONNEW} component={ConfiguratorNew} />
        <Route path={ROUTES.CHECKOUT} component={Checkout} />
        <Route path={ROUTES.CHECK_STATUS} component={CheckStatus} />
        <Route path={ROUTES.ORDERS} component={Orders} />
        <Route path={ROUTES.QOUTES} component={Quotes} />
        <Route path={ROUTES.VATS} component={Vats} />
        <Route path={ROUTES.SHIPPING} component={Shipping} />
        <Route path={ROUTES.COUNTRIES} component={Countries} />
        <Route path={ROUTES.MATERIALS} component={Materials} />
        <Route path={ROUTES.MATERIALS_360} component={Materials360} />
        <Route path={ROUTES.BENDING} component={Bending} />
        <Route path={ROUTES.HOURLY_RATES} component={HourlyRates} />
        <Route path={ROUTES.PRICE_RULES} component={PriceRules} />
        <Route path={ROUTES.SETTINGS} component={Settings} />
        <Route path={ROUTES.APP_SETTINGS} component={AppSettings} />
        <Route path={ROUTES.THREADS} component={Threads} />
        <Route path={ROUTES.USERS} component={Users} />
        <Route path={ROUTES.ADMIN_UPDATES} component={AdminUpdates} />
        <Route path={ROUTES.UPLOADS} component={Uploads} />
        <Route path={ROUTES.PAGES} component={Pages} />
        <Route path={ROUTES.DASHBOARD} component={Dashboard} />
        <Route path={ROUTES.IMPORT_EXPORT} component={ImportExport} />
        <Route path={ROUTES.SKETCHER} component={Sketcher} />
        <Route path={ROUTES.MYPARTLIBRARY} component={MyPartLibrary} />
        <Route path={ROUTES.PARTLIBRARY} component={PartLibrary} />
        <Route path={ROUTES.PARTSINLIB} component={PartsInLib} />
        <Route path={ROUTES.EXACT} component={Exact} />
        <Redirect to={ROUTES.ERROR_V1} />
      </Switch>
    </Suspense>
  );
}
