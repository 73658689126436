import React, { useEffect, useRef, useState } from 'react';
import './index.scss';
import { isUndefined } from 'lodash';

export default function CheckBox({
  checked,
  indeterminate,
  onClick,
  ...other
}) {
  const cRef = useRef();
  const onClickPass = isUndefined(onClick);

  const [state, setState] = useState(
    onClickPass ? { checked: false } : { checked }
  );

  useEffect(() => {
    cRef.current.indeterminate = indeterminate;
  }, [indeterminate]);

  return (
    <input
      {...other}
      ref={cRef}
      type="checkbox"
      className={`redCheckBox${other.className ? ' ' + other.className : ''}`}
      checked={onClickPass ? state.checked : checked}
      onChange={(e) => {
        if (onClickPass) {
          setState((prevState) => ({
            ...prevState,
            checked: !prevState.checked,
          }));
        } else {
          onClick(e);
        }
      }}
    />
  );
}
