import React, { useEffect, useState, useRef } from 'react';
import { Grid, Typography } from '@material-ui/core';

import { API_URL, API_URLS } from '../../common/constants/api/apiConstants';
import initApi from '../../crud/apiConfig/apiConfig';

const Exact = () => {
  const isMounted = useRef(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      getExpiredDate();
    }
  }, [isMounted]);

  const getExpiredDate = async () => {
    let { data } = await initApi().get(API_URL + API_URLS.EXACT_EXPIRE_DATE);

    if (isMounted.current) {
      setData(data);
    }
  };

  const handleRefreshToken = async (value) => {
    let { data } = await initApi().post(API_URL + API_URLS.EXACT, {
      administration: value,
    });

    if (data) {
      window.open(data, '_blank');
    }
  };

  return (
    <Grid item sm={6} xs={12} container style={{ margin: '20px auto 0' }}>
      {data.length > 0 &&
        data.map((item) => {
          return (
            <>
              <Typography variant="h3" align="center">
                <b>{item?.administration.toUpperCase()}</b>: Exact token will be
                expired {item?.expireDate}
              </Typography>
              <button
                type={'button'}
                className={'btn btn-block btn-bold btn-outline-brand'}
                onClick={() => handleRefreshToken(item?.administration)}
                style={{ margin: '20px 0' }}
              >
                Submit for refresh token
              </button>
            </>
          );
        })}
    </Grid>
  );
};

export default Exact;
