export function removeCSSClass(ele, cls) {
  const reg = new RegExp('(\\s|^)' + cls + '(\\s|$)');
  ele.className = ele.className.replace(reg, ' ');
}

export function addCSSClass(ele, cls) {
  ele.classList.add(cls);
}

export const toAbsoluteUrl = (pathname) => process.env.PUBLIC_URL + pathname;

export function setupAxios(axios, store) {
  axios.interceptors.request.use(
    (config) => {
      const {
        auth: { authToken },
      } = store.getState();

      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }

      return config;
    },
    (err) => Promise.reject(err)
  );
}

/*  removeStorage: removes a key from localStorage and its sibling expiracy key
    params:
        key <string>     : localStorage key to remove
    returns:
        <boolean> : telling if operation succeeded
 */
export function removeStorage(key) {
  try {
    localStorage.setItem(key, '');
    localStorage.setItem(key + '_expiresIn', '');
  } catch (e) {
    console.log(
      'removeStorage: Error removing key [' +
        key +
        '] from localStorage: ' +
        JSON.stringify(e)
    );
    return false;
  }
  return true;
}

/*  getStorage: retrieves a key from localStorage previously set with setStorage().
    params:
        key <string> : localStorage key
    returns:
        <string> : value of localStorage key
        null : in case of expired key or failure
 */
export function getStorage(key) {
  const now = Date.now(); //epoch time, lets deal only with integer
  // set expiration for storage
  let expiresIn = localStorage.getItem(key + '_expiresIn');
  if (expiresIn === undefined || expiresIn === null) {
    expiresIn = 0;
  }

  expiresIn = Math.abs(expiresIn);
  if (expiresIn < now) {
    // Expired
    removeStorage(key);
    return null;
  } else {
    try {
      const value = localStorage.getItem(key);
      return value;
    } catch (e) {
      console.log(
        'getStorage: Error reading key [' +
          key +
          '] from localStorage: ' +
          JSON.stringify(e)
      );
      return null;
    }
  }
}
/*  setStorage: writes a key into localStorage setting a expire time
    params:
        key <string>     : localStorage key
        value <string>   : localStorage value
        expires <number> : number of seconds from now to expire the key
    returns:
        <boolean> : telling if operation succeeded
 */
export function setStorage(key, value, expires) {
  if (expires === undefined || expires === null) {
    expires = 24 * 60 * 60; // default: seconds for 1 day
  }

  const now = Date.now(); //millisecs since epoch time, lets deal only with integer
  const schedule = now + expires * 1000;
  try {
    localStorage.setItem(key, value);
    localStorage.setItem(key + '_expiresIn', schedule);
  } catch (e) {
    console.log(
      'setStorage: Error setting key [' +
        key +
        '] in localStorage: ' +
        JSON.stringify(e)
    );
    return false;
  }
  return true;
}

export function getToleranceTableValue(length) {
  let toleranceVal = 0;

  if (
    (length >= 0.5 && length < 3) ||
    (length >= 3 && length < 6) ||
    (length >= 6 && length < 30)
  ) {
    toleranceVal = 0.3;
  }

  if (length >= 30 && length < 120) {
    toleranceVal = 0.4;
  }

  if (length >= 120 && length < 400) {
    toleranceVal = 0.5;
  }

  if (length >= 400 && length < 1000) {
    toleranceVal = 0.8;
  }

  if (length >= 1000 && length < 2000) {
    toleranceVal = 1;
  }

  if (length >= 2000 && length < 4000) {
    toleranceVal = 1.2;
  }

  return toleranceVal;
}

export function mergeObjInArray(arrayOfObj) {
  /* eslint-disable no-unused-vars */
  var output = [];

  arrayOfObj.forEach(function(item) {
    var existing = output.filter(function(v, i) {
      return v.thickness == item.thickness;
    });    

    if (existing.length) {
      var existingIndex = output.indexOf(existing[0]);
      output[existingIndex].color = output[existingIndex]?.color?.concat(
        item.color
      );
      output[existingIndex].color_de = output[existingIndex]?.color_de?.concat(
        item.color_de
      );
      output[existingIndex].color_en = output[existingIndex]?.color_en?.concat(
        item.color_en
      );

      output[existingIndex].has_to_be_milled = output[
        existingIndex
      ]?.has_to_be_milled?.concat(item.has_to_be_milled);
    } else {
      if (typeof item.color == 'string') item.color = [item.color];
      if (typeof item.color_de == 'string') item.color_de = [item.color_de];
      if (typeof item.color_en == 'string') item.color_en = [item.color_en];
      item.has_to_be_milled = [item.has_to_be_milled];
      output.push(item);
    }
  });

  output.sort(function(a, b) {
    return a.thickness - b.thickness;
  });

  return output;
}

export function areObjectKeysDefined(obj) {
  return (
    Object.values(obj).includes(null) || Object.values(obj).includes(undefined)
  );
}

export function filterObjectsByStringKeys(arrayOfObjects, arrayOfStrings) {
  return arrayOfObjects.filter((obj) => {
    return Object.values(obj).some(
      (value) =>
        typeof value !== 'object' &&
        arrayOfStrings.some((str) => {
          return str?.toLowerCase().includes(obj.title.toLowerCase());
        })
    );
  });
}

export function arraysAreEqual(arr1, arr2) {
  if (arr1?.length !== arr2?.length) {
    return false;
  }

  for (let i = 0; i < arr1?.length; i++) {
    if (!objectsAreEqual(arr1[i], arr2[i])) {
      return false;
    }
  }

  return true;
}

export function objectsAreEqual(obj1, obj2) {
  if (
    (obj1 === null || obj1 === undefined) &&
    (obj2 === null || obj2 === undefined)
  ) {
    return true;
  }

  if (
    obj1 === null ||
    obj1 === undefined ||
    obj2 === null ||
    obj2 === undefined
  ) {
    return false;
  }

  const keys1 = Object.keys(obj1).filter((key) => key !== 'type');
  const keys2 = Object.keys(obj2).filter((key) => key !== 'type');

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    if (key === 'id') {
      continue;
    }

    const value1 = obj1[key];
    const value2 = obj2[key];

    if (
      (value1 === null || value1 === undefined) &&
      (value2 === null || value2 === undefined)
    ) {
      continue;
    }

    if (
      value1 === null ||
      value1 === undefined ||
      value2 === null ||
      value2 === undefined
    ) {
      return false;
    }

    if (typeof value1 === 'object' && typeof value2 === 'object') {
      if (!objectsAreEqual(value1, value2)) {
        return false;
      }
    } else {
      if (value1 !== value2) {
        return false;
      }
    }
  }

  return true;
}


export function areAllFieldsValid(source, excludedFields) {
  for (const key in source) {
    if (!excludedFields.includes(key) && (source[key] === null || source[key] === undefined)) {
      return false; 
    }
  }
  return true; 
}

export function createAddressWithType(source, type, excludedFields) {
  if (areAllFieldsValid(source, excludedFields)) {
    return {
      ...source,
      type,
    };
  }
  return null; 
}
