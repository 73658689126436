import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Dropzone from 'react-dropzone';
import { Button } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import ReactTooltip from 'react-tooltip';
import { BiInfoCircle } from 'react-icons/bi';
import { FormattedMessage, injectIntl } from 'react-intl';

import { isArray } from 'lodash';
import { compress, decompress } from 'lz-string';
import Draw from './Draw/Draw/Draw';
import ConfiguratorDropzone from './ConfiguratorDropzone';
import { drawActions } from '../../../store/ducks/draw.duck';
import { COMMON_PROP_TYPES } from '../../../common/constants/propTypes/propTypes';
import { MATERIAL_CATEGORIES } from '../../../common/constants/constants';
import {
  getTotalLengths,
  // calculateElementLength,
} from '../../../common/svg/sum';
import { INVALID_DXF_ELEMENTS } from '../../../common/constants/elements/elements';
import { API_URL, API_URLS } from '../../../common/constants/api/apiConstants';
import initApi from '../../../crud/apiConfig/apiConfig';

import PreviewModal from '../../home/configurator/PreviewModal';
import CustomToolTip from '../../../components/CustomTooltip';
import MetaTags from '../../../components/MetaTags';

import { getToleranceTableValue } from '../../../../_metronic/utils/utils';
import ToleranceTableModal from '../../home/configurator/ToleranceTableModal';
import Emitter from '../../../common/constants/EventEmitter/emmiter';
import LoadingTag from './loadingProgress';

class ConfiguratorPage extends React.Component {
  state = {
    error: '',
    pcSelected: false,
    openModalConfigurator: false,
    batchMaterial: undefined,
    batchMaterialType: undefined,
    batchCount: 0,
    batchSelected: [],
    projectId: undefined,
    materialsData: [],
  };

  constructor(props) {
    super(props);

    this.modalRef = React.createRef();
    this.iframeRef = React.createRef();
    this.toleranceTableModalRef = React.createRef();

    this.getProgressData = this.getProgressData.bind(this);
  }

  componentDidMount() {
    Emitter.on('LOADING_PROGRESS_UPDATE', this.getProgressData);

    const { match } = this.props;

    const projectId = match.params.projectId;

    if (projectId) {
      this.loadProject(projectId);
    }

    if (this.iframeRef.current) {
      const iframe = this.iframeRef.current;
      iframe.addEventListener('play', this.handlePlay);
      iframe.addEventListener('pause', this.handlePause);
    }

    // this.getMaterialsData();
  }

  componentWillUnmount() {
    if (this.iframeRef.current) {
      const iframe = this.iframeRef.current;
      iframe.removeEventListener('play', this.handlePlay);
      iframe.removeEventListener('pause', this.handlePause);
    }

    Emitter.off('LOADING_PROGRESS_UPDATE', this.getProgressData);
  }

  componentDidUpdate() {
    let {
      uploadedSvgs,
      selectedMaterial,
      materialData,
      addMaterial,
    } = this.props;

    for (let uSvg of uploadedSvgs) {
      if (!selectedMaterial[uSvg.id] && materialData.materials) {
        materialData.materials.forEach((item) => {
          if (this.getMaterialName(item) === uSvg.selectedTypeMaterial?.name) {
            addMaterial(
              {
                ...item,
                id: uSvg.id,
              },
              uSvg.id
            );
          }
        });
      }
    }
  }

  getMaterialsData = async () => {
    let { data } = await initApi().get(API_URL + API_URLS.GET_MATERIALS_DATA);

    this.setState({
      ...this.state,
      materialsData: data,
    });
  };

  getProgressData = ({ name, loadingObject }) => {
    const { loadingProgressUpdate } = this.props;

    loadingProgressUpdate(name, loadingObject);
  };

  handlePlay = () => {
    console.log('Video started playing.');
  };

  handlePause = () => {
    console.log('Video paused.');
  };

  addSelectMaterial = (event, overwriteSvg = undefined) => {
    const { materialData, addMaterial, uploadedSvgs } = this.props;

    const currentSvg = isArray(this.props.currentSvg)
      ? this.props.currentSvg.map((svg) => ({
          ...svg,
          dxf: decompress(svg.dxf),
          dxf_original: decompress(svg.dxf_original),
        }))
      : this.props.currentSvg;

    materialData.materials.forEach((item) => {
      if (
        this.getMaterialName(item) ===
        this.props.intl.formatMessage({ id: event.value })
      ) {
        let svgId = overwriteSvg ? overwriteSvg.id : currentSvg.id;
        addMaterial(
          {
            ...item,
            id: svgId,
          },
          svgId
        );
        this.setState({
          ...this.state,
          uploadedSvgs: uploadedSvgs?.map((uploadedSvg) => {
            if (uploadedSvg.id === svgId) {
              currentSvg.selectedMaterial = event.value;
              return {
                ...uploadedSvg,
                selectedTypeMaterial: undefined,
                selectedMaterial: event.value,
              };
            }
          }),
        });
      }
    });
  };

  setOpenModalConfigurator = (newOpenModal) => {
    this.setState({
      ...this.state,
      openModalConfigurator: newOpenModal,
    });
  };

  addTypeMaterial = (event, overwriteSvg = undefined) => {
    const { selectedMaterial, uploadedSvgs } = this.props;

    const currentSvg = isArray(this.props.currentSvg)
      ? this.props.currentSvg.map((svg) => ({
          ...svg,
          dxf: decompress(svg.dxf),
          dxf_original: decompress(svg.dxf_original),
        }))
      : this.props.currentSvg;

    const idSelectedType = event.id;
    const svgId = overwriteSvg?.id ?? currentSvg.id;

    this.setState({
      ...this.state,
      uploadedSvgs: uploadedSvgs?.map((uploadedSvg) => {
        if (uploadedSvg.id === svgId) {
          selectedMaterial[svgId].choices.map((choice) => {
            if (choice.id === idSelectedType) {
              uploadedSvg.selectedTypeMaterial = choice;
              currentSvg.selectedTypeMaterial = choice;
            }
          });

          return uploadedSvg;
        }
      }),
    });
  };

  async loadProject(projectId) {
    const { uploadedSvgs, getProject, deleteSvg } = this.props;

    for (let uploadedSvg of uploadedSvgs) {
      deleteSvg(uploadedSvg.id);
    }
    getProject(projectId);
  }

  getDataForSaving = () => {
    const { user } = this.props;
    const { uploadedSvgs /* isEditing */ } = this.props;

    const currentSvg = isArray(this.props.currentSvg)
      ? this.props.currentSvg.map((svg) => ({
          ...svg,
          dxf: decompress(svg.dxf),
          dxf_original: decompress(svg.dxf_original),
        }))
      : this.props.currentSvg;

    const dxfsLengths = getTotalLengths(currentSvg);

    const products = uploadedSvgs?.map((value) => {
      const {
        id,
        selectedTypeMaterial,
        quantity,
        area,
        engravingArea,
        insertedGears,
        bending,
      } = value;

      const lengthItem = dxfsLengths.map((item) =>
        item.id === String(id) ? item.sum : 0
      );

      let length = 0;

      lengthItem.forEach((item) => {
        length += item;
      });

      const material =
        (selectedTypeMaterial && selectedTypeMaterial.id) || null;

      const toleranceVal =
        parseFloat(value.viewBox.width) >= parseFloat(value.viewBox.height)
          ? getToleranceTableValue(parseFloat(value.viewBox.width))
          : getToleranceTableValue(parseFloat(value.viewBox.height));

      // const selectedMaterialData = this.state.materialsData.filter(
      //   (item) => item.id === selectedTypeMaterial.id
      // );

      // const svgPreview = value.preview;
      // const tempDiv = document.createElement('div');
      // tempDiv.innerHTML = svgPreview;

      // const calculatePathLengths = (svgElement) => {
      //   const paths = svgElement.querySelectorAll('path');
      //   let totalLength = 0;
      //   paths.forEach((path) => {
      //     const length = path.getTotalLength();
      //     totalLength += length;
      //   });
      //   return totalLength;
      // };

      // const pathLength = calculatePathLengths(tempDiv.querySelector('svg'));

      // console.log(this.priceCalculator({
      //   area          : currentSvg.viewBox.width * currentSvg.viewBox.height,
      //   length        : pathLength,
      //   quantity      : quantity,
      //   materialPrice : selectedMaterialData[0].price,
      //   cuttingSpeed  : selectedMaterialData[0].cutting_speed
      // }));

      return {
        name: value.name,
        dxf: value.dxf.replace('HEADER\n0', 'HEADER\n9\n$INSUNITS\n70\n4\n0'),
        dxf_original: value.dxf_original,
        svg: value.preview,
        length: length + ( isNaN(engravingArea) ? 0 : engravingArea ) / 1.2,
        width: value.viewBox.width,
        height: value.viewBox.height,
        threadings_quantity:
          value.threadings_quantity || insertedGears
            ? Object.keys(insertedGears)?.length
            : 0,
        area,
        material,
        quantity: Number(quantity),
        bendings:
          Object.keys(bending).length > 0 && !bending[0]
            ? [bending]
            : Array.isArray(bending)
            ? bending
            : undefined,
        tolerance: toleranceVal,
      };
    });

    const data = { products };
    data.isAnonymousUser = !user && !user?.id ? true : null;

    return data;
  };

  // priceCalculator = ({ area, length, quantity, materialPrice, cuttingSpeed }) => {
  //   const RATE = 624; // Default value for 14 days in acceptatie need dyunamic change in the future
  //   const MINIMAL_RATE = 520; // Default value for 14 need as well as previous line...

  //   const toFixedArea = Math.round(area * 10000) / 10000;
  //   const toFixedLength = Math.round(length * 10000) / 10000;

  //   let basePricePerMM2 = Number(materialPrice) / 1000000;
  //   let basePricePerProduct = toFixedArea * basePricePerMM2 / 2;

  //   let cuttingTimeInSeconds = toFixedLength / cuttingSpeed;

  //   let totalCuttingTimeInMinutes = (cuttingTimeInSeconds * quantity) / 60;
  //   let amountOfMinutes = Math.floor(totalCuttingTimeInMinutes / 5);

  //   if (amountOfMinutes > 11) {
  //     amountOfMinutes = 11;
  //   }

  //   let hourlyDiscount = amountOfMinutes * 7.5;

  //   if (totalCuttingTimeInMinutes > 60) {
  //     totalCuttingTimeInMinutes -= 60;
  //     let hoursLeftAfterOneHour = Math.floor(totalCuttingTimeInMinutes / 60);
  //     hourlyDiscount += hoursLeftAfterOneHour;
  //   }

  //   let finalRate = RATE - hourlyDiscount;

  //   if (finalRate < MINIMAL_RATE) {
  //     finalRate = MINIMAL_RATE;
  //   }

  //   let cuttingTimeCost = cuttingTimeInSeconds * finalRate / 3600;
  //   let pricePerItem = Math.round( ( basePricePerProduct + cuttingTimeCost ) * 100 ) / 100;
  //   let totalPrice = pricePerItem * quantity;

  //   return Number( totalPrice.toFixed(2) );
  // };

  toStr = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  validateDxfString = (dxfString) => {
    const invalidElemsFromString = dxfString
      .replace(/[\n\r\s]+/gm, ' ')
      .split(' ')
      .map((elem) =>
        INVALID_DXF_ELEMENTS.find((invalidItem) => elem === invalidItem)
      )
      .filter((elem) => elem)
      .filter((value, index, self) => self.indexOf(value) === index);

    if (invalidElemsFromString.length) {
      this.setState(() => ({
        ...this.state,
        error: `${
          invalidElemsFromString.length > 1
            ? invalidElemsFromString.join(', ')
            : invalidElemsFromString.join(' ')
        } not supported!`,
      }));
    } else {
      this.setState(() => ({
        ...this.state,
        error: '',
      }));
    }

    return !invalidElemsFromString.length;
  };

  importDxfFiles = (acceptedFiles) => {
    const loadingSvgs = Object.values(acceptedFiles).map((file) => ({
      name: file.name,
    }));

    this.props.loadingUpdate(false, loadingSvgs);


    acceptedFiles.map((acceptedFile, i) => {
      this.importDxf(acceptedFile, acceptedFiles.length === ++i);
    });
  };

  importDxf = async (dxf, lastOne) => {
    const { convertDxf, loading } = this.props;

    const dxfString = await this.toStr(dxf);

    this.setState({
      ...this.state,
      uploadedFile: dxf,
    });

    await convertDxf(compress(dxfString), dxf.name, loading, true, lastOne);
  };

  selectSvg = (id) => () => {
    const { selectCurrentSvg } = this.props;

    selectCurrentSvg(id, this.state);
  };

  deleteSvgElem = (id) => () => {
    const { deleteSvg, selectCurrentSvg, uploadedSvgs } = this.props;
    deleteSvg(id);

    if (uploadedSvgs.length > 0) {
      setTimeout(function() {
        selectCurrentSvg(uploadedSvgs[0]?.id);
      }, 200);
    }
  };

  previewElem = (material) => {
    if (!material?.id) {
      return;
    }
    const { id: materialId } = material;
    this.modalRef.current.open({ materialId });
  };

  previewToleranceTable = () => {
    this.toleranceTableModalRef.current.open();
  };

  downloadSvgElem = (value) => () => {
    const svg = value && value.preview;

    if (svg) {
      const element = document.createElement('a');
      const file = new Blob([svg], { type: 'text/plain' });
      element.href = URL.createObjectURL(file);
      element.download = value.name.replace('dxf', 'svg') || 'example.svg';
      document.body.appendChild(element);
      element.click();
    }
  };

  downloadDxfElem = (value) => () => {
    const dxf = value && value.dxf;

    if (dxf) {
      const element = document.createElement('a');
      const file = new Blob([dxf], { type: 'text/plain' });

      element.href = URL.createObjectURL(file);
      element.download = value.name || 'example.dxf';
      document.body.appendChild(element);
      element.click();
    }
  };

  submit = (e) => {
    const { history, saveProducts } = this.props;
    e.preventDefault();

    const data = this.getDataForSaving();

    saveProducts(data, history, {
      include:
        'products,products.material,products.bendings,order,order.shipping_address.country,order.billing_address.country',
    });
  };

  submitSaveChange = (e) => {
    e.preventDefault();

    const { history, saveChangeProduct, editProductId } = this.props;
    const data = this.getDataForSaving();

    saveChangeProduct(editProductId, data, history);

    localStorage.setItem('svgRect', null);
    localStorage.setItem('modalContentRect', null);
    localStorage.setItem('horizontalLineRect', null);
    localStorage.setItem('xAxisLineRect', null);
    localStorage.setItem('verticalLineRect', null);
    localStorage.setItem('yAxisLineRect', null);
  };

  getMaterialName = (item) => {
    const { intl } = this.props;
    let material_name = item.name;
    if (intl.locale == 'en') {
      material_name = item.name_en ? item.name_en : material_name;
    }
    if (intl.locale == 'de') {
      material_name = item.name_de ? item.name_de : material_name;
    }
    return material_name;
  };

  getColorName = (item) => {
    const { intl } = this.props;
    let color_name = item.color;
    if (intl.locale == 'en' && item.color_en != '') {
      color_name = item.color_en;
    }

    if (intl.locale == 'de' && item.color_de != '') {
      color_name = item.color_de;
    }

    return color_name;
  };

  addQuantity = (event) => {
    const { selectQuantity } = this.props;
    let quantity = event.target.value;

    const currentSvg = isArray(this.props.currentSvg)
      ? this.props.currentSvg.map((svg) => ({
          ...svg,
          dxf: decompress(svg.dxf),
          dxf_original: decompress(svg.dxf_original),
        }))
      : this.props.currentSvg;

    if (quantity < 0) {
      return false;
    }

    selectQuantity(quantity, currentSvg.id);
  };

  checkIfSubmitDisabled = () => {
    const { uploadedSvgs } = this.props;

    const disabled = !!uploadedSvgs.find((svg) => {
      if (!svg.selectedTypeMaterial) {
        return true;
      }

      if (
        svg.selectedTypeMaterial.id === null ||
        svg.selectedTypeMaterial === undefined
      ) {
        return true;
      }

      return !svg.quantity;
    });

    return disabled;
  };

  handleChangeBatchMaterial = (value) => {
    const { currentSvg, uploadedSvgs, materialData, addMaterial } = this.props;
    const { batchSelected } = this.state;

    const isDisabled = (options) => {
      return options.map((item) => {
        return item?.options?.find((option) => option?.key == value)
          ?.isDisabled;
      });
    };

    this.setState({
      ...this.state,
      batchMaterial: isDisabled(currentSvg?.options).some((el) => el === true)
        ? ''
        : value,
    });

    for (let [batchKey, batchValue] of Object.entries(batchSelected)) {
      uploadedSvgs.map((uploadedSvg) => {
        if (uploadedSvg.id == batchKey && batchValue == true) {
          materialData.materials.forEach((item) => {
            if (this.getMaterialName(item) === value) {
              addMaterial(
                {
                  ...item,
                  id: uploadedSvg.id,
                },
                uploadedSvg.id
              );
            }
          });
        }
      });
    }
  };

  handleChangeBatchMaterialType = (value) => {
    const { uploadedSvgs, materialData, addMaterial } = this.props;

    const { batchSelected, batchMaterial } = this.state;

    let timeout = 0;
    for (let [batchKey, batchValue] of Object.entries(batchSelected)) {
      this.setState({
        ...this.state,
        batchMaterialType: value,
        uploadedSvgs: uploadedSvgs?.map((uploadedSvg) => {
          if (uploadedSvg.id == batchKey && batchSelected[uploadedSvg.id]) {
            materialData.materials.forEach((item) => {
              if (batchValue) {
                if (this.getMaterialName(item) === batchMaterial) {
                  addMaterial(
                    {
                      ...item,
                      id: uploadedSvg.id,
                    },
                    uploadedSvg.id
                  );
                }
                item.choices.map((choice) => {
                  if (choice.id == value) {
                    uploadedSvg.selectedTypeMaterial = choice;
                  }
                });
              }
            });
          }
          return uploadedSvg;
        }),
      });

      setTimeout(() => {
        // selectCurrentSvg(batchKey, this.state);
      }, timeout);
      timeout += 100;
    }
  };

  batchSelectAll = (event) => {
    const { uploadedSvgs } = this.props;
    let batchSelected = this.state.batchSelected;
    for (let uploadedSvg of uploadedSvgs) {
      batchSelected[uploadedSvg.id] = event.target.checked;
    }
    let count = 0;
    for (let ov of Object.values(batchSelected)) {
      if (ov) {
        count++;
      }
    }

    this.setState({
      ...this.state,
      batchSelected: batchSelected,
      batchCount: count,
    });
  };

  batchSelectOne = (event, svgId) => {
    let batchSelected = this.state.batchSelected;
    batchSelected[svgId] = event.target.checked;
    let count = 0;
    for (let ov of Object.values(batchSelected)) {
      if (ov) {
        count++;
      }
    }

    let newState = {
      ...this.state,
      batchSelected: batchSelected,
      batchCount: count,
    };

    this.setState(newState);
  };

  render() {
    const {
      user,
      uploadedSvgs,
      materialData,
      selectedMaterial,
      isProcessing,
      isEditing,
      intl,
      loading,
      isMainPage,
    } = this.props;

    const currentSvg = isArray(this.props.currentSvg)
      ? this.props.currentSvg.map((svg) => ({
          ...svg,
          dxf: decompress(svg.dxf),
          dxf_original: decompress(svg.dxf_original),
        }))
      : this.props.currentSvg;

    const error = this.state.error || this.props.error;
    const submitDisabled = this.checkIfSubmitDisabled();
    let foundApplicable = false;

    const optionStyle = {
      menuPortal: (base) => ({
        ...base,
        zIndex: 9999,
      }),
      control: (base) => ({
        ...base,
        border: '1px solid #e2e5ec',
        borderRadius: '4px',
      }),
    };

    let materialOptions = [];

    materialOptions.push({
      key: 'empty',
      value: '',
      label: '...',
    });

    if (materialData.materials && materialData.materials.length) {
      MATERIAL_CATEGORIES.map((optGroup) => {
        let optList = {
          label: this.props.intl.formatMessage({ id: optGroup.title }),
          options: [],
        };
        materialData.materials.map((item) => {
          if (item.category == optGroup.id) {
            for (let choice of item.choices) {
              let measurements = choice.measurement.split('x');
              let width = measurements[0];
              let height = measurements[1];
              if (
                (currentSvg?.viewBox?.width <= width &&
                  currentSvg?.viewBox?.height <= height) ||
                (currentSvg?.viewBox?.width <= height &&
                  currentSvg?.viewBox?.height <= width)
              ) {
                foundApplicable = true;
              }
            }

            optList.options.push({
              key: item.name,
              label: (
                <div
                  style={{
                    textDecoration: !foundApplicable ? 'line-through' : 'unset',
                  }}
                >
                  {!foundApplicable && (
                    <CustomToolTip
                      id="applicable"
                      effect="solid"
                      position="top"
                      tooltipText={
                        <FormattedMessage
                          id={'CONFIGURATOR.IS_NOT_APPLICABLE'}
                        />
                      }
                    >
                      <button
                        data-tip
                        data-for="applicable"
                        style={{
                          border: 'none',
                          background: 'transparent',
                        }}
                      >
                        <BiInfoCircle
                          size={18}
                          cursor={'pointer'}
                          color={'#b6b6b6'}
                        />
                      </button>
                    </CustomToolTip>
                  )}

                  {this.getMaterialName(item)}
                </div>
              ),
              value: item.name,
              isDisabled: !foundApplicable ? true : false,
            });
          }
        });

        materialOptions.push(optList);
        currentSvg.options = materialOptions;
      });
    }

    return (
      <>
        <MetaTags
          title={intl.formatMessage({ id: 'CONFIGURATOR.META.TITLE' })}
          description={intl.formatMessage({ id: 'CONFIGURATOR.META.DESC' })}
          keywords={intl.formatMessage({ id: 'CONFIGURATOR.META.KEYWORDS' })}
        />

        <ReactTooltip />

        <div
          className={
            'configurator-wrapper ' +
            (isMainPage ? 'configurator-wrapper-bp-mode' : '')
          }
        >
          {user && !isMainPage && (
            <h3 className={'upload-title'}>
              <FormattedMessage id="UPLOAD" />
            </h3>
          )}
          <div
            className={
              'configurator-dropzone-container ' +
              (uploadedSvgs.length == 0 &&
              this.props.loading.uploadingSvgs.length === 0 &&
              !isProcessing
                ? 'dropzone-big'
                : 'dropzone-small ') +
              (!user ? ' guest-mode' : '') +
              (isMainPage ? ' basic-page-mode' : '')
            }
          >
            <ConfiguratorDropzone
              importDxfFiles={this.importDxfFiles}
              isMainPage={isMainPage}
            />
          </div>
          {this.props.loading.uploadingSvgs.length > 0 && (
            <div className="loading-wrapper">
              {this.props.loading.uploadingSvgs.map((nameWpromis, index) => (
                <LoadingTag
                  key={index}
                  promiseAbort={nameWpromis.promiseCancel}
                  loadingUpdate={this.props.loadingUpdate}
                  loading={this.props.loading}
                  loadingProgress={this.props.loadingProgress[nameWpromis.name]}
                >
                  {nameWpromis.name}
                </LoadingTag>
              ))}
            </div>
          )}

          <Draw
            openModal={this.state.openModalConfigurator}
            setOpenModal={this.setOpenModalConfigurator}
          />

          {uploadedSvgs.length > 0 && (
            <div className="svgs-list">
              <div className={'row mt-2 mb-3'}>
                <div className={'col-2'}>
                  <div className={'row'}>
                    <div className={'col-2'}>
                      <label className="checkbox checkbox-outline checkbox-success checkbox-batch">
                        <input type="checkbox" onChange={this.batchSelectAll} />
                      </label>
                    </div>
                    <div className={'col-10'}>
                      <strong>
                        {this.state.batchCount > 0
                          ? this.state.batchCount +
                            ' ' +
                            this.props.intl.formatMessage({
                              id: 'Geselecteerd',
                            })
                          : this.props.intl.formatMessage({
                              id: 'Multi select',
                            })}
                      </strong>
                    </div>
                  </div>
                </div>
                <div className={'col-2'}>
                  <select
                    key={'batch-select-material'}
                    className={'batch-select-material'}
                    value={this.state.batchMaterial}
                    onChange={(event) => {
                      this.handleChangeBatchMaterial(event.target.value);
                    }}
                  >
                    <option value={''}>
                      {this.props?.intl.formatMessage({ id: 'MATERIAL' })}
                    </option>
                    {this.props?.materialData.materials?.map((material) => {
                      return (
                        <option
                          key={
                            'opt-mater-' + material.name + '' + currentSvg.id
                          }
                          value={material.name}
                        >
                          {this.getMaterialName(material)}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className={'col-2'}>
                  {this.state.batchMaterial && (
                    <select
                      key={'batch-select-material-type'}
                      className={'batch-select-material'}
                      value={this.state.batchMaterialType}
                      onChange={(event) =>
                        this.handleChangeBatchMaterialType(event.target.value)
                      }
                    >
                      <option value={''}>
                        {this.props.intl.formatMessage({
                          id: 'THICKNESS_AND_COLOR',
                        })}
                      </option>
                      {this.props?.materialData.materials
                        ?.find(
                          (material) =>
                            material.name == this.state.batchMaterial
                        )
                        ?.choices?.map((choice) => {
                          return (
                            <option
                              key={'optmat' + choice.id + '' + currentSvg.id}
                              value={choice.id}
                            >
                              {choice.thickness}mm - {this.getColorName(choice)}
                            </option>
                          );
                        })}
                    </select>
                  )}
                </div>
              </div>
              {uploadedSvgs.map((value) => {
                const { id, name, quantity, preview } = value;

                // const labelId = `checkbox-list-secondary-label-${value}`;
                // const labelId = `checkbox-list-secondary-label-${value}`;
                const selected = id === currentSvg.id;
                const className = 'col-12 ' + (selected ? 'active' : '');

                let width = 0;
                let height = 0;

                const ifItemDisabled = (itemOptions) => {
                  return itemOptions?.some((obj) => {
                    return obj?.options?.some((item) =>
                      item?.key == selectedMaterial[value.id]?.name
                        ? item?.isDisabled
                        : false
                    );
                  });
                };

                if (
                  selectedMaterial[value.id] !== undefined &&
                  selectedMaterial[value.id].choices
                ) {
                  selectedMaterial[value.id].choices.map((item) => {
                    let measurements = item.measurement.split('x');
                    width = measurements[0];
                    height = measurements[1];

                    if (
                      (currentSvg?.viewBox?.width <= width &&
                        currentSvg?.viewBox?.height <= height) ||
                      (currentSvg?.viewBox?.width <= height &&
                        currentSvg?.viewBox?.height <= width)
                    ) {
                      item.isDisabled = false;
                    } else {
                      item.isDisabled = true;
                    }
                  });
                }

                return (
                  <div
                    className="row focus-row mb-2"
                    key={id}
                    onClick={this.selectSvg(id, currentSvg.id)}
                  >
                    <div className={className} key={`svg_${id}`}>
                      <div className="row">
                        <div className="col-2">
                          <div className={'row'}>
                            <div className={'col-2'}>
                              <div className="checkbox-inline">
                                <label className="checkbox checkbox-danger checkbox-batch">
                                  <input
                                    type="checkbox"
                                    onChange={(evt) =>
                                      this.batchSelectOne(evt, id)
                                    }
                                    checked={
                                      this.state.batchSelected[id] ?? false
                                    }
                                    key={'checkbox-batch-' + value.id}
                                  />
                                </label>
                              </div>
                            </div>
                            <div
                              className={'col-10'}
                              onClick={() =>
                                this.setOpenModalConfigurator(
                                  !this.state.openModalConfigurator
                                )
                              }
                            >
                              <div
                                className="svg-wrapper"
                                id={`configurator-draw-box:${id}`}
                                dangerouslySetInnerHTML={{ __html: preview }}
                              ></div>

                              {(value?.bending?.direction === 'vertical' ||
                                (value?.bending &&
                                  value?.bending[0]?.direction ===
                                    'vertical')) && (
                                <span className="bending-type-block">
                                  <FormattedMessage id={'BENDING.VERTICAL'} />
                                </span>
                              )}

                              {(value?.bending?.direction === 'horizontal' ||
                                (value?.bending &&
                                  value?.bending[0]?.direction ===
                                    'horizontal')) && (
                                <span className="bending-type-block">
                                  <FormattedMessage id={'BENDING.HORIZONTAL'} />
                                </span>
                              )}
                            </div>

                            <div
                              className={'col-10 offset-2'}
                              onClick={() => this.previewToleranceTable()}
                            >
                              {parseFloat(value.viewBox.width) >=
                                parseFloat(value.viewBox.height) && (
                                <span className="tolerance-text-block">
                                  <FormattedMessage
                                    id={'CONFIGURATOR.TOLERANCE'}
                                  />
                                  <b>
                                    +/-&nbsp;
                                    {getToleranceTableValue(
                                      parseFloat(value.viewBox.width)
                                    )}{' '}
                                    mm
                                  </b>
                                </span>
                              )}

                              {parseFloat(value.viewBox.width) <
                                parseFloat(value.viewBox.height) && (
                                <span className="tolerance-text-block">
                                  <FormattedMessage
                                    id={'CONFIGURATOR.TOLERANCE'}
                                  />
                                  <b>
                                    +/-&nbsp;
                                    {getToleranceTableValue(
                                      parseFloat(value.viewBox.height)
                                    )}{' '}
                                    mm
                                  </b>
                                </span>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="col-1">
                          <strong style={{ wordBreak: 'break-all' }}>
                            {name}
                          </strong>
                        </div>

                        <div className="col-1">
                          {value.viewBox.width.toFixed(1)} x{' '}
                          {value.viewBox.height.toFixed(1)} mm
                        </div>

                        <div className="col-2">
                          <Select
                            key={'material-select-' + value.id}
                            placeholder={this.props.intl.formatMessage({
                              id: 'MATERIAL',
                            })}
                            onChange={(event) => {
                              this.addSelectMaterial(event, value);
                            }}
                            value={
                              !selectedMaterial[value.id]?.name ||
                              ifItemDisabled(currentSvg.options)
                                ? null
                                : {
                                    key: selectedMaterial[value.id]?.name,
                                    label: this.getMaterialName(
                                      selectedMaterial[value.id]
                                    ),
                                    value: selectedMaterial[value.id]?.name,
                                  }
                            }
                            onClick={this.selectSvg(id, currentSvg.id)}
                            menuPortalTarget={document.body}
                            options={currentSvg.options}
                            isOptionDisabled={(option) => option.isDisabled}
                            styles={optionStyle}
                          />
                        </div>

                        <div className="col-2">
                          {selectedMaterial[value.id] ? (
                            <>
                              <Select
                                placeholder={this.props.intl.formatMessage({
                                  id: 'THICKNESS_AND_COLOR',
                                })}
                                onChange={(event) => {
                                  this.addTypeMaterial(event, value);
                                }}
                                key={'material-type-select-' + value.id}
                                onClick={this.selectSvg(id, currentSvg.id)}
                                options={selectedMaterial[value.id].choices}
                                menuPortalTarget={document.body}
                                value={value.selectedTypeMaterial ?? null}
                                styles={optionStyle}
                                getOptionValue={(option) => option.id}
                                getOptionLabel={(item) => (
                                  <div
                                    className={'row'}
                                    title={
                                      item.has_to_be_milled
                                        ? this.props.intl.formatMessage({
                                            id: 'PRODUCT_MILLED',
                                          })
                                        : ''
                                    }
                                  >
                                    {item.isDisabled ? (
                                      <>
                                        <span className={'col-md-2'}>
                                          <CustomToolTip
                                            id="disabled"
                                            effect="solid"
                                            position="top"
                                            tooltipText={
                                              <FormattedMessage
                                                id={
                                                  'CONFIGURATOR.THICKNESS_COLOR_IS_NOT_AVAILABLE'
                                                }
                                              />
                                            }
                                          >
                                            <button
                                              data-tip
                                              data-for="disabled"
                                              style={{
                                                border: 'none',
                                                background: 'transparent',
                                              }}
                                            >
                                              <BiInfoCircle
                                                size={18}
                                                cursor={'pointer'}
                                                color={'#b6b6b6'}
                                                style={{ margin: 0 }}
                                              />
                                            </button>
                                          </CustomToolTip>
                                        </span>
                                        <span
                                          className={'col-md-2'}
                                          dangerouslySetInnerHTML={{
                                            __html: item.icon,
                                          }}
                                        />
                                        <span
                                          className={'col-md-6'}
                                          style={{
                                            textDecoration: 'line-through',
                                          }}
                                        >
                                          {Number(item?.thickness)
                                            .toFixed(3)
                                            .replace('.000', '')
                                            .replace('00', '')
                                            .replace('.', ',')}{' '}
                                          mm &nbsp; {this.getColorName(item)}
                                        </span>
                                      </>
                                    ) : (
                                      <>
                                        <span
                                          className={'col-md-2'}
                                          style={{ marginLeft: 5 }}
                                          dangerouslySetInnerHTML={{
                                            __html: item.icon,
                                          }}
                                        />
                                        <span
                                          className={'col-md-8'}
                                          style={{ marginLeft: 5 }}
                                        >
                                          {Number(item?.thickness)
                                            .toFixed(3)
                                            .replace('.000', '')
                                            .replace('00', '')
                                            .replace('.', ',')}{' '}
                                          mm &nbsp;{this.getColorName(item)}
                                        </span>
                                      </>
                                    )}
                                  </div>
                                )}
                              />
                            </>
                          ) : (
                            <Select
                              disabled={true}
                              onClick={this.selectSvg(id, currentSvg.id)}
                            >
                              <option>
                                {this.props.intl.formatMessage({
                                  id: 'CONFIGURATOR.SELECT_THICKNESS_COLOR',
                                })}
                              </option>
                            </Select>
                          )}
                        </div>

                        <div className="col-1">
                          <input
                            min={1}
                            className="form-control"
                            placeholder={this.props.intl.formatMessage({
                              id: 'CONFIGURATOR.QUANTITY',
                            })}
                            type="number"
                            value={Math.abs(quantity) || ''}
                            onClick={this.selectSvg(id, currentSvg.id)}
                            onChange={this.addQuantity}
                            onKeyDown={(event) => {
                              if (
                                !(
                                  (event.key >= '0' &&
                                    event.key <= '9' &&
                                    event.key !== ' ') ||
                                  (event.key >= 'NumPad0' &&
                                    event.key <= 'NumPad9') ||
                                  event.key === 'Backspace'
                                )
                              ) {
                                return false;
                              }
                            }}
                          />
                        </div>

                        <div className={'col-2'}>
                          <button
                            className="btn btn-outline-primary btn-outline btn-md "
                            onClick={() =>
                              this.setOpenModalConfigurator(
                                !this.state.openModalConfigurator
                              )
                            }
                          >
                            <FormattedMessage id="APPLY_EDIT" />
                          </button>
                        </div>

                        <div className={'col-1'}>
                          <div className={'row'}>
                            <CustomToolTip
                              id="material-info"
                              effect="solid"
                              position="top"
                              tooltipText={
                                <FormattedMessage
                                  id={'CONFIGURATOR.PREVIEW_MATERIAL'}
                                />
                              }
                            >
                              <button
                                data-tip
                                data-for="material-info"
                                style={{
                                  border: 'none',
                                  background: 'transparent',
                                }}
                                className={`btn text-center btn-md`}
                                onClick={() =>
                                  this.previewElem(value.selectedTypeMaterial)
                                }
                              >
                                <i className="flaticon2-cube font-size-6" />
                              </button>
                            </CustomToolTip>
                            <button
                              className="btn btn-delete text-center btn-md"
                              onClick={this.deleteSvgElem(id)}
                            >
                              <i className="flaticon2-trash font-size-6" />
                            </button>
                          </div>
                        </div>
                      </div>
                      {error && (
                        <span className="badge badge-pill badge-info">
                          {error}
                        </span>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          {isEditing ? (
            <div className="proceed-button-container">
              <button
                disabled={submitDisabled}
                type="submit"
                onClick={this.submitSaveChange}
                className="proceed-button-container__item"
                title={
                  submitDisabled
                    ? 'Please select one detail and choose material and quantity in configurator'
                    : ''
                }
              >
                {isProcessing ? (
                  'Loading..'
                ) : (
                  <FormattedMessage id="CONFIGURATOR.SAVE_CHANGE_QUOTE" />
                )}
              </button>
            </div>
          ) : (
            !!uploadedSvgs.length &&
            !loading.status && (
              <div className="proceed-button-container">
                <Dropzone
                  accept=".dxf, .step, .stp"
                  onDrop={this.importDxfFiles}
                  minSize={0}
                  maxSize={2000000}
                >
                  {({ getRootProps, getInputProps }) => {
                    return (
                      <section
                        className="dropzone"
                        style={{ background: 'transparent' }}
                      >
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <div className="kt-portlet__body text-center">
                            <Button
                              variant={'secondary'}
                              style={{ margin: '1em 0', border: 'none' }}
                            >
                              <i
                                className={'flaticon2-plus icon-4x'}
                                style={{
                                  paddingRight: 0,
                                  fontSize: '2rem',
                                  color: '#db3c3c',
                                }}
                              />
                            </Button>
                          </div>
                        </div>
                      </section>
                    );
                  }}
                </Dropzone>

                <button
                  disabled={submitDisabled}
                  type="submit"
                  onClick={this.submit}
                  className="proceed-button-container__item"
                  title={
                    submitDisabled
                      ? 'Please choose material and quantity for every detail in configurator'
                      : ''
                  }
                >
                  {isProcessing ? (
                    'Loading..'
                  ) : (
                    <FormattedMessage id="CONFIGURATOR.PROCEED_TO_CHECKOUT" />
                  )}
                </button>
              </div>
            )
          )}
        </div>

        <PreviewModal ref={this.modalRef} />
        <ToleranceTableModal ref={this.toleranceTableModalRef} />
      </>
    );
  }
}

ConfiguratorPage.propTypes = {
  user: PropTypes.object,
  uploadedSvgs: PropTypes.arrayOf(COMMON_PROP_TYPES.CURRENT_SVG),
  currentSvg: COMMON_PROP_TYPES.CURRENT_SVG,
  isProcessing: PropTypes.bool.isRequired,
  materialData: PropTypes.object.isRequired,
  error: PropTypes.string,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  selectedMaterial: PropTypes.object.isRequired,
  selectMaterialType: PropTypes.func.isRequired,
  selectQuantity: PropTypes.func.isRequired,
  addMaterial: PropTypes.func.isRequired,
  // setDraw: PropTypes.func.isRequired,
  selectCurrentSvg: PropTypes.func.isRequired,
  selectBatchMaterial: PropTypes.func,
  deleteSvg: PropTypes.func.isRequired,
  convertDxf: PropTypes.func.isRequired,
  saveProducts: PropTypes.func.isRequired,
  getProduct: PropTypes.func.isRequired,
  loadingUpdate: PropTypes.func.isRequired,
  loadingProgressUpdate: PropTypes.func.isRequired,
  dataProducts: PropTypes.object,
  isEditing: PropTypes.bool,
  saveChangeProduct: PropTypes.func.isRequired,
  editProductId: PropTypes.string,
  isMainPage: PropTypes.bool,
};

const mapStateToProps = ({
  auth: { user },
  draw: {
    uploadedSvgs,
    currentSvg,
    error,
    editProductId,
    materialData,
    selectedMaterial,
    isProcessing,
    dxf,
    dxf_original,
    loading,
    loadingProgress,
  },
  quotes: {
    selectedQuoteDetails: { data },
  },
}) => ({
  user,
  uploadedSvgs,
  currentSvg,
  error,
  materialData,
  selectedMaterial,
  isProcessing,
  dataProducts: data,
  editProductId,
  dxf,
  dxf_original,
  loading,
  loadingProgress,
});

const mapDispatchToProps = {
  // setDraw: (svg, name) => drawActions.setDraw({ svg, name, }),
  getProject: (id) => drawActions.getEditProject(id),
  getProduct: (id) => drawActions.getEditProduct(id),
  selectCurrentSvg: (id, extraState) =>
    drawActions.selectCurrentSvg(id, extraState),
  deleteSvg: drawActions.deleteSvg,
  convertDxf: (data, name, loading, withAbort, lastOne) =>
    drawActions.convertDxfRequest(data, name, loading, withAbort, lastOne),
  addMaterial: (data, id, svgId) => drawActions.selectMaterial(data, id, svgId),
  selectMaterialType: (data, id, svgId) =>
    drawActions.selectMaterialType(data, id, svgId),
  selectBatchMaterial: (material) => drawActions.selectBatchMaterial(material),
  selectQuantity: (data, id) => drawActions.addQuantity(data, id),
  saveProducts: (data, history, params) =>
    drawActions.saveProductsRequest({ data, history, params }),
  saveChangeProduct: (id, data, history) =>
    drawActions.saveChangeProductRequest({ id, data, history }),
  loadingUpdate: (status, svgNames) =>
    drawActions.loadingUpdate(status, svgNames),
  loadingProgressUpdate: (name, loadingObject) =>
    drawActions.loadingProgressUpdate(name, loadingObject),
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(ConfiguratorPage))
);
