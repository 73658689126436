import PropTypes from 'prop-types';

export const INSERTED_GEAR = PropTypes.shape({
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
});

export const INSERTED_GALVANIZE_ITEM = PropTypes.shape({
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
});

export const INSERTED_GEARS = PropTypes.shape({
  [PropTypes.oneOf([PropTypes.number, PropTypes.string])]: INSERTED_GEAR,
});
export const INSERTED_GALVANIZE = PropTypes.shape({
  [PropTypes.oneOf([
    PropTypes.number,
    PropTypes.string,
  ])]: INSERTED_GALVANIZE_ITEM,
});

export const SELECTED_ELEM = PropTypes.shape({
  nodeName: PropTypes.any,
  index: PropTypes.any,
});

export const COMMON_PROP_TYPES = {
  CURRENT_SVG: PropTypes.shape({
    svg: PropTypes.string,
    insertedGalvanize: INSERTED_GALVANIZE,
    insertedGears: INSERTED_GEARS,
    selectedElem: SELECTED_ELEM,
    id: PropTypes.any,
  }),
};
