import React, { useState, useEffect } from 'react';
import MaterialTable from '@material-table/core';
import initApi from '../../../crud/apiConfig/apiConfig';
import { API_URLS, API_URL } from '../../../common/constants/api/apiConstants';
import { priceCalculator } from '../../../partials/content/priceCalculator';
import { injectIntl } from 'react-intl';

import { getFileSvg } from '../../../crud/partLibrary.crud';
import makerjs from "makerjs";


function MasterPage(props) {
  const { intl, history } = props;
  const [fileContents, setFileContents] = useState([]);

  useEffect(() => {

    const initialize = async () => {
      let { data } = await initApi().get(API_URLS.GET_ALL_FILES);

      fetchSVGs(data)
    }
    initialize();
  }, [])


  const fetchSVGs = async (fileList) => {
    const svgPromises = fileList.map(async (item, index) => {
      if (item.id) {
        const { data } = await getFileSvg(item.id);
        const fillColor =
          item?.material !== null ? '#c0c0c0' : 'rgb(243, 179, 179)';

        const parser = new DOMParser();
        const svgDoc = parser.parseFromString(data, 'image/svg+xml');
        const svgElement = svgDoc.querySelector('svg');
        const width = svgElement?.getAttribute('width');

        const height = svgElement?.getAttribute('height');
        const viewBox = svgElement?.getAttribute('viewBox').split(' ');
        const paths = svgDoc.querySelectorAll('path');
        const polyLines = svgDoc.querySelectorAll('polyline');
        const circles = svgDoc.querySelectorAll('circle');
        const modelsMaker = [];
        const pathsMaker = [];


        let totalLength = 0;
        let totalArea = viewBox && viewBox.length > 3 && (viewBox[2] - 10) * (viewBox[3] - 10);

        paths.forEach((path) => {
          totalLength += path.getTotalLength();
        });

        paths.forEach((elem, index) => {
          let pathData = elem.getAttribute('d');
          try {
            var model = makerjs.importer.fromSVGPathData(pathData);
            model = makerjs.model.mirror(model, false, true);

            if (model && model.paths) {
              Object.keys(model.paths).forEach((key) => {
                if (model.paths[key] && fillColor) {
                  model.paths[key].fill = model.paths[key].fill || fillColor;
                }
              });
            }

            if (model && model.models) {
              Object.keys(model.models).forEach((key) => {
                let shape = model.models && model.models[key];

                if (shape && shape.paths && fillColor) {
                  shape.paths.fill = shape.paths.fill || fillColor;
                }
              });
            }

            modelsMaker['path' + index] = model;
          } catch (e) {
            console.error(e);
          }
        });

        polyLines.forEach((polyline) => {
          totalLength += polyline.getTotalLength();
        });

        circles.forEach((circle) => {
          const radius = parseFloat(circle.getAttribute('r'));

          totalLength += 2 * Math.PI * radius;
        });

        circles.forEach((elem, index) => {
          let cx = parseFloat(elem.getAttribute('cx'));
          let cy = parseFloat(elem.getAttribute('cy'));
          let r = parseFloat(elem.getAttribute('r'));

          try {
            let circle = new makerjs.paths.Circle([cx, cy], r);
            circle.fill = '#F2F3F8';

            pathsMaker['path' + index] = circle;
          } catch (e) {
            console.error(e);
          }
        });

        let modelWrapper = {
          models: modelsMaker,
          paths: pathsMaker,
        };

        const svgString = makerjs.exporter.toSVG(modelWrapper, {
          useSvgPathOnly: true,
          layerOptions: { fill: true },
        });

        const doc = parser.parseFromString(svgString, 'image/svg+xml');
        const docSvgEl = doc.documentElement;

        if (docSvgEl.hasAttribute('viewBox')) {
          const viewBoxValues = docSvgEl.getAttribute('viewBox').split(' ');
          const newViewBox = `-2 -3 ${parseFloat(viewBoxValues[2]) + 4} ${parseFloat(viewBoxValues[3]) + 4}`;
          docSvgEl.setAttribute('viewBox', newViewBox);
        } else {
          console.error('The SVG element does not have a viewBox attribute.');
        }

        docSvgEl.setAttribute('width', '100')
        docSvgEl.setAttribute('height', '100')


        const pathsToFill = doc.querySelectorAll('path');

        pathsToFill.forEach((path) => {
          const d = path.getAttribute('d');
          if (d && (d.startsWith('M') || d.startsWith('m'))) {
            path.setAttribute('fill', fillColor);
          } else {
            console.error('Invalid path data:', d);
          }
        });


        const serializer = new XMLSerializer();
        const updatedSvg = serializer.serializeToString(doc);
        const material = item.material;
        const svgPath = item.svgPath;
        const dxfPath = item.dxfPath;


        return { updatedSvg, width, height, totalLength, totalArea, material, svgPath, dxfPath };
      }
    });

    const svgResults = await Promise.all(svgPromises);

    const getloweRate = async (days) => {
      const data = await initApi().get(
        API_URL + API_URLS.GET_HOURLY_RATES_DATA
      );

      return data.data.find(rate => rate.deliveryDays === days);
    }


    const price_i = (index, loweRate) => {

      if (svgResults[index]) {

        return priceCalculator({
          area: svgResults[index].totalArea,
          length: svgResults[index].totalLength,
          materialPrice: svgResults[index].material.price,
          cuttingSpeed: svgResults[index].material.cuttingSpeed,
          rate: Number(loweRate.rate),
          minimalRate: Number(loweRate.minimumPrice),
          startingFrom: true
        });
      }

      return 0;
    };

    const loweRate = await getloweRate(30)
    const updatedFileList = fileList.map((item, index) => ({
      ...item,
      material: item.material,
      svgContent: svgResults[index]?.updatedSvg,
      svgWidth: svgResults[index]?.width,
      svgHeight: svgResults[index]?.height,
      totalLength: svgResults[index]?.totalLength,
      totalArea: svgResults[index]?.totalArea,
      price: (item.material) ? price_i(index, loweRate) : 0,
    }))


    setFileContents(updatedFileList);

    return updatedFileList
  };





  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const columns = [

    {
      title: "Svg",
      field: 'name',
      render: (rowData) => <>
        <div className="file-component_img product-images" style={{ width: "unset", cursor: 'pointer', display: 'flex', justifyContent: 'center' }}>
          {`${process.env.REACT_APP_API_URL}/${rowData.svgPath}` ? (
            <div
              dangerouslySetInnerHTML={{ __html: rowData.svgContent }}
              style={{
                padding: '10px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100px',
                height: '100px',
                overflow: 'visible',
              }}
            />

          ) : (
            <p>Error loading SVG</p>
          )}
        </div>
      </>,
    },

    {
      title: "name",
      field: 'name',
      render: (rowData) => rowData.name,
    },

    {
      title: "Starting From",
      field: 'name',
      render: (rowData) => rowData.price ? '€' + rowData.price : <string style={{ color: "red" }}> Price unavailable</string>,
    },


    {
      title: "Material",
      field: 'Material',
      render: (rowData) => rowData.material ? rowData.material.name : "Not Specified",
    },

    {
      title: "Color",
      field: 'Color',
      render: (rowData) => rowData.material ? parseFloat(Number(rowData.material.thickness)) + ' ' + 'mm' + ' ' + (rowData.material[`color_${intl.locale}`] ? rowData.material[`color_${intl.locale}`] : rowData.material[`color_en`])
        : "Not Specified",
    },

    {
      title: "User",
      field: 'User',
      render: (rowData) => <div style={{ color: 'red', cursor: 'pointer' }} onClick={() => navigateToUser(rowData.user.id)} > {rowData.user.username || rowData.user.email} </ div>,
    },

  ];



  function navigateToUser(id) {
    history.push(`/users/details/${id}`);
  }

  const changePage = (page) => {
    setCurrentPage(page);
  };

  const changePerPage = (per_page) => {
    setPageSize(per_page);
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="kt-section">
            <div className="kt-section__content">
              <MaterialTable
                localization={{
                  toolbar: {
                    searchPlaceholder: intl.formatMessage({ id: 'search' }),
                  },
                  header: {
                    actions: intl.formatMessage({ id: 'QUOTES.ACTIONS' }),
                  },
                  body: {
                    emptyDataSourceMessage: intl.formatMessage({
                      id: 'no_records_to_display',
                    }),
                  },
                }}
                title="Parts in Library"
                // onRowClick={(evt, selectedRow) => navigateTo(selectedRow)}
                columns={columns}
                data={fileContents}
                page={currentPage}
                totalCount={0 /* total */}
                onPageChange={changePage}
                onChangeRowsPerPage={changePerPage}
                options={{
                  pageSize: pageSize,
                  actionsColumnIndex: -1,
                  search: true,
                  // padding: 'dense',
                  headerStyle: {
                    color: '#D80000',
                    fontSize: 13,
                  },
                  body: {
                    fontSize: 13,
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default injectIntl(MasterPage);
