import { createAction } from 'redux-actions';

export const getPaymentTypes = createAction('GET_PAYMENT_TYPES_REQUEST');
export const getPaymentTypesSuccess = createAction('GET_PAYMENT_TYPES_SUCCESS');
export const getPaymentTypesError = createAction('GET_PAYMENT_TYPES_ERROR');
export const getPaymentType = createAction('GET_PAYMENT_TYPE_REQUEST');
export const getPaymentTypeSuccess = createAction('GET_PAYMENT_TYPE_SUCCESS');
export const getPaymentTypeError = createAction('GET_PAYMENT_TYPE_ERROR');
//export const deletePaymentType = createAction('DELETE_MATERIAL_REQUEST');
//export const deletePaymentTypeSuccess = createAction('DELETE_MATERIAL_SUCCESS');
//export const deletePaymentTypeError = createAction('DELETE_MATERIAL_ERROR');
//export const updatePaymentType = createAction('UPDATE_MATERIAL_REQUEST');
//export const updatePaymentTypeSuccess = createAction('UPDATE_MATERIAL_SUCCESS');
//export const updatePaymentTypeError = createAction('UPDATE_MATERIAL_ERROR');