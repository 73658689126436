import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import Materials360Page from './Materials360Page';
import HDPEMaterialLandingPage from '../materialsLP/HDPEMaterialLandingPage';
import POMMaterialLandingPage from '../materialsLP/POMMaterialLandingPage';
import PMMAXTMaterialLandingPage from '../materialsLP/PMMAXTMaterialLandingPage';
import PMMAGSMaterialLandingPage from '../materialsLP/PMMAGSMaterialLandingPage';
import HMPE1000MaterialLandingPage from '../materialsLP/HMPE1000MaterialLandingPage';
import HMPE500MaterialLandingPage from '../materialsLP/HMPE500MaterialLandingPage';
import PCMaterialLandingPage from '../materialsLP/PCMaterialLandingPage';
import PETGMaterialLandingPage from '../materialsLP/PETGMaterialLandingPage';
import PTFEMaterialLandingPage from '../materialsLP/PTFEMaterialLandingPage';
import PETPMaterialLandingPage from '../materialsLP/PETPMaterialLandingPage';
import PSMaterialLandingPage from '../materialsLP/PSMaterialLandingPage';
import PAMaterialLandingPage from '../materialsLP/PAMaterialLandingPage';
import PPMaterialLandingPage from '../materialsLP/PPMaterialLandingPage';
import PIMaterialLandingPage from '../materialsLP/PIMaterialLandingPage';
import PEEKMaterialLandingPage from '../materialsLP/PEEKMaterialLandingPage';
import ABSMaterialLandingPage from '../materialsLP/ABSMaterialLandingPage';
import HPLMaterialLandingPage from '../materialsLP/HPLMaterialLandingPage';
import AlupanelMaterialLandingPage from '../materialsLP/AlupanelMaterialLandingPage';
import EPDMMaterialLandingPage from '../materialsLP/EPDMMaterialLandingPage';
import NBRMaterialLandingPage from '../materialsLP/NBRMaterialLandingPage';
import PURMaterialLandingPage from '../materialsLP/PURMaterialLandingPage';
import SBRMaterialLandingPage from '../materialsLP/SBRMaterialLandingPage';
import SiliconeMaterialLandingPage from '../materialsLP/SiliconeMaterialLandingPage';

import { ROUTES } from '../../../common/constants/routes/routes';

const Materials360 = () => {
  const currentHref = window.location.href.split('/');
  const infoHref = currentHref && currentHref[currentHref.length - 1];

  const componentsArray = [
    {
      id: 'hdpe',
      component: HDPEMaterialLandingPage,
    },
    { id: 'pom', component: POMMaterialLandingPage },
    { id: 'pmma-xt', component: PMMAXTMaterialLandingPage },
    { id: 'pmma-gs', component: PMMAGSMaterialLandingPage },
    {
      id: 'hmpe-1000',
      component: HMPE1000MaterialLandingPage,
    },
    {
      id: 'hmpe-500',
      component: HMPE500MaterialLandingPage,
    },
    { id: 'pc', component: PCMaterialLandingPage },
    { id: 'pet-g', component: PETGMaterialLandingPage },
    { id: 'ptfe', component: PTFEMaterialLandingPage },
    { id: 'petp', component: PETPMaterialLandingPage },
    { id: 'ps', component: PSMaterialLandingPage },
    { id: 'pa', component: PAMaterialLandingPage },
    { id: 'pp', component: PPMaterialLandingPage },
    { id: 'pi', component: PIMaterialLandingPage },
    { id: 'peek', component: PEEKMaterialLandingPage },
    { id: 'abs', component: ABSMaterialLandingPage },
    { id: 'hpl', component: HPLMaterialLandingPage },
    {
      id: 'dibond-alupanel',
      component: AlupanelMaterialLandingPage,
    },
    {
      id: 'epdm',
      component: EPDMMaterialLandingPage,
    },
    {
      id: 'nbr',
      component: NBRMaterialLandingPage,
    },
    {
      id: 'pur',
      component: PURMaterialLandingPage,
    },
    {
      id: 'sbr',
      component: SBRMaterialLandingPage,
    },
    {
      id: 'siliconen',
      component: SiliconeMaterialLandingPage,
    },
  ];

  const route = componentsArray.filter((item) => {
    return item.id === infoHref;
  });

  return (
    <Switch>
      <Route exact path={ROUTES.MATERIALS_360} component={Materials360Page} />

      {!route.length && (
        <Redirect
          exact={true}
          from={`${ROUTES.MATERIALS_360}/*`}
          to={ROUTES.MATERIALS_360}
          component={Materials360Page}
        />
      )}

      <Route
        exact
        path={`${ROUTES.MATERIALS_360}/${infoHref}`}
        component={route[0]?.component}
      />
    </Switch>
  );
};

const mapStateToProps = ({ auth: { user } }) => ({
  user,
});

export default injectIntl(connect(mapStateToProps)(Materials360));
