import { call, put, takeEvery } from "redux-saga/effects";

import {
  getCountryQuery,
  updateCountryQuery,
  getCountriesQuery,
  deleteCountryQuery
} from "./crud";

import {
  getCountries,
  getCountriesError,
  getCountriesSuccess,
  updateCountry,
  updateCountrySuccess,
  updateCountryError,
  deleteCountry,
  deleteCountrySuccess,
  deleteCountryError,
  getCountry,
  getCountryError,
  getCountrySuccess
} from "./actions";

function* fetchCountriesSaga({ payload }) {
  try {
    const res = yield call(getCountriesQuery, payload);

    yield put(getCountriesSuccess(res.data));
  } catch (err) {
    yield put(getCountriesError(err));
  }
}

function* fetchCountrySaga({ payload }) {
  try {
    const { successCallBack = () => {} } = payload;

    const res = yield call(getCountryQuery, payload);

    yield put(
      getCountrySuccess({
        ...res.data,
        created_at: res.data.created_at
      })
    );

    yield successCallBack(res.data.type);
  } catch (err) {
    yield put(getCountryError(err.response));
  }
}

function* updateCountrySaga({ payload }) {
  try {
    const { successCallBack = () => {}, id, data } = payload;

    const res = yield call(updateCountryQuery, { id, data });

    yield put(
      updateCountrySuccess({
        ...res.data,
        created_at: res.data.created_at
      })
    );

    yield successCallBack(res.data.type);
  } catch (err) {
    yield put(updateCountryError(err.response));
  }
}

function* deleteCountrySaga({ payload }) {
  try {
    const { successCallBack = () => {}, id } = payload;

    const res = yield call(deleteCountryQuery, id);

    yield put(
      deleteCountrySuccess({
        ...res.data,
        created_at: res.data.created_at
      })
    );

    yield successCallBack(res.data.type);
  } catch (err) {
    yield put(deleteCountryError(err.response));
  }
}

function* countriesSagas() {
  yield takeEvery(getCountries, fetchCountriesSaga);
  yield takeEvery(getCountry, fetchCountrySaga);
  yield takeEvery(updateCountry, updateCountrySaga);
  yield takeEvery(deleteCountry, deleteCountrySaga);
}

export default countriesSagas;
