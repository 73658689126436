import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { stateToHTML } from 'draft-js-export-html';
import { convertFromRaw } from 'draft-js';

export const usePreviewMaterial = () => {
  const intl = useIntl();
  const [detail, setDetail] = useState(null);
  const loadSirv = (components) => {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = 'https://scripts.sirv.com/sirvjs/v3/sirv.js';
      script.type = 'text/javascript';
      script.async = true;
      if (components) {
        script.dataset.components = components;
      }
      script.onload = resolve;
      document.body.appendChild(script);
    });
  };

  useEffect(() => {
    if (detail && detail.id) {
      if (typeof window.Sirv === 'undefined') {
        loadSirv().then(() => {
          window.Sirv.start();
        });
      } else {
        window.Sirv.stop();
        window.Sirv.start();
      }
    }
  }, [detail]);

  const handleMaterialDetails = (item) => {
    const materialDetails = {
      id: item.id,
      src:
        item.uncleanedProductFile || item.cleanedProductFile
          ? `https://cutwise.sirv.com/${item.uncleanedProductFile ||
              item.cleanedProductFile}`
          : '',
      description: item.text
        ? stateToHTML(convertFromRaw(JSON.parse(item.text)))
        : '',
      header: item.name,
    };

    if (intl.locale === 'de' && !!item.textDe) {
      materialDetails.description = stateToHTML(
        convertFromRaw(JSON.parse(item.textDe))
      );

      materialDetails.header = item.nameDe;
    }

    if (intl.locale === 'en' && !!item.textEn) {
      materialDetails.description = stateToHTML(
        convertFromRaw(JSON.parse(item.textEn))
      );

      materialDetails.header = item.nameEn;
    }
    setDetail(materialDetails);
  };

  return { detail, handleMaterialDetails };
};
