/* eslint-disable react/jsx-no-target-blank */
import React, { useState, Fragment } from 'react';
import { format } from 'date-fns';
import '../../../../../_metronic/_assets/sass/pages/invoices/invoice-2.scss';
import { connect } from 'react-redux';
import {
  toAbsoluteUrl,
  getToleranceTableValue,
} from '../../../../../_metronic';

import PropTypes from 'prop-types';
import { API_URL } from '../../../../common/constants/api/apiConstants';
import { ordersActions } from '../../../../store/ducks/ordersReducer';
import { FormattedMessage, injectIntl } from 'react-intl';
import { toast } from 'react-toastify';

import '../../../../styles/print.css';

function DetailsPage(props) {
  const [selectedType, setSelectedType] = useState('');
  const [showCalculation, setShowCalculation] = useState(0);
  const {
    data,
    isProcessing,
    match: {
      params: { id },
    },
    history,
    getDetailedOrder,
    getOrderTypes,
    intl,
  } = props;

  const showSuccessToast = () => {
    toast.success(intl.formatMessage({ id: 'Order saved succesfully' }), {
      position: 'bottom-right',
      autoClose: 2500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    });

    history.push({ state: { newOrder: false } });
  };

  const gtagID = {
    'cutwise.bmtec-laserschneiden.de': 'AW-10814647505/qnJwCIWAgLoDENHZ6aQo',
    'cutwise.bmtec-lasercutting.com': 'AW-11391537181/t6yICKv_ifEYEJ2g9Lcq',
    'cutwise.bmtec.nl': 'AW-833061246/TqN1CJTSl7ADEP6Cno0D',
  };

  React.useEffect(() => {
    if (id) {
      getDetailedOrder(id);
    }

    getOrderTypes();
    // resetDraw();
    if (history.location?.state?.newOrder) {
      showSuccessToast();
    }
  }, []);

  React.useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      typeof window.gtag === 'function' &&
      window.location.host.indexOf('cutwise.bmtec-laserschneiden.de') > -1
    ) {
      if (data) {
        window.gtag('event', 'conversion', {
          send_to:
            gtagID[window.location.host] || 'AW-833061246/TqN1CJTSl7ADEP6Cno0D',
          value: data.project && data.project.price.toFixed(2),
          currency: 'EUR',
          email: data.user && data.user.email,
          email_invoices: data.user && data.user.email_invoices,
        });
      }
    }
  }, [data]);

  if (isProcessing) {
    return 'Loading...';
  }

  if (!data) {
    return null;
  }

  const { project, shipping_address, billing_address, shipping_type } = data;

  // const selectEditQuote = (event) => {
  //   history.push(`${ROUTES.CONFIGURATION}/${event.target.id}`);
  // };

  let deliveryDay = new Date();
  if (project && project.created_at) {
    deliveryDay = new Date(project.created_at * 1000);
    let countAll = 0;
    let deliveryDays =
      project.delivery_days == 3 &&
      shipping_address.country?.id != 1 &&
      shipping_address.country?.id != 3
        ? 5
        : project.delivery_days;

    for (let count = 0; countAll != deliveryDays; count++) {
      deliveryDay.setDate(deliveryDay.getDate() + 1);
      if (deliveryDay.getDay() != 0 && deliveryDay.getDay() != 6) {
        countAll++;
      }
    }
  }

  let formattedDeliveryDate = format(deliveryDay, 'dd-MM-yyyy');

  const { types, admin, status } = props;

  const submitTypeForm = (e) => {
    const {
      saveOrderType,
      match: {
        params: { id },
      },
    } = props;

    e.preventDefault();

    saveOrderType(id, selectedType);
  };

  const onChangeType = ({ target: { value } }) => {
    setSelectedType(value);
  };

  const getSelectedTypeObject = (value) => {
    const { types } = props;

    return types && types.items.find((type) => type.value === value);
  };

  const getColorName = (item) => {
    let color_name = item.color;
    if (intl.locale == 'en' && item.color_en != '') {
      color_name = item.color_en;
    }
    if (intl.locale == 'de' && item.color_de != '') {
      color_name = item.color_de;
    }
    return color_name;
  };

  const submitPaymentTypeForm = () => {};
  const onChangePaymentType = () => {};

  const selectedTypeObj = getSelectedTypeObject(selectedType);

  return (
    <>
      <div className="kt-container  kt-grid__item kt-grid__item--fluid">
        <div className="kt-portlet section-to-print">
          <div className="kt-portlet__body kt-portlet__body--fit">
            <div className="kt-invoice-2">
              <div className="kt-invoice__head">
                <div className="kt-invoice__container">
                  <div className="kt-invoice__items">
                    <div className="kt-invoice__item">
                      <img
                        className="kt-invoice__logo"
                        alt="logo"
                        src={toAbsoluteUrl(intl.formatMessage({ id: 'LOGO' }))}
                      />
                      <strong>
                        <FormattedMessage id="ORDER.ORDER" /> {id.split('?')[0]}{' '}
                        {data.reference ? '(' + data.reference + ')' : ''}
                      </strong>
                      <br />
                      <strong>
                        <FormattedMessage id="ORDER.SHIPPING_ADDRESS" />
                      </strong>
                      <span>{shipping_address?.company_name}</span>
                      <span>
                        {shipping_address?.first_name}{' '}
                        {shipping_address?.last_name}
                      </span>
                      <span>
                        {shipping_address?.address}{' '}
                        {shipping_address?.house_number} {shipping_address?.ext}
                      </span>
                      <span>
                        {shipping_address?.postal_code} {shipping_address?.city}
                      </span>
                      <span>
                        <FormattedMessage
                          id={shipping_address?.country?.name}
                        />
                      </span>
                      <span className={'google-email'}>
                        {data?.user?.email_invoices ?? data?.user?.email}
                      </span>

                      {billing_address?.postal_code?.length > 0 ? (
                        <>
                          <strong>
                            <FormattedMessage id="ORDER.BILLING_ADDRESS" />
                          </strong>
                          <span>{billing_address?.company_name}</span>
                          <span>
                            {billing_address?.first_name}{' '}
                            {billing_address?.last_name}
                          </span>
                          <span>
                            {billing_address?.address}{' '}
                            {billing_address?.house_number}{' '}
                            {billing_address?.ext}
                          </span>
                          <span>
                            {billing_address?.postal_code}{' '}
                            {billing_address?.city}
                          </span>
                          <span>
                            <FormattedMessage
                              id={billing_address?.country?.name}
                            />
                          </span>
                        </>
                      ) : (
                        ''
                      )}
                      <h3>
                        <FormattedMessage id="ORDER.ORDER" /> {id.split('?')[0]}
                      </h3>
                    </div>

                    <div className="kt-invoice__item text-right">
                      <span className="kt-invoice__desc">
                        <strong>{data?.location?.company_name}</strong>
                        <br />
                        {data?.location?.address} {data?.location?.house_number}{' '}
                        {data?.location?.ext}
                        <br />
                        {data?.location?.postal_code} {data?.location?.city}
                        <br />
                        {data?.location?.country?.name}
                        <br /> <br />
                        {data?.location?.email} <br />
                        {data?.location?.phone_number}
                        <br /> <br />
                        {data?.location?.coc_number}
                        <br />
                        {data?.location?.tax_number}
                        <br />
                        {data?.location?.iban_number}
                      </span>
                    </div>
                  </div>

                  {!(types && types.error) && admin && (
                    <div className="kt-invoice__item">
                      <span className="kt-invoice__subtitle">Status</span>
                      <span className="kt-invoice__te.kt-invoice__containerxt">
                        <form onSubmit={submitTypeForm} className="d-flex">
                          {types.isProccessing ? (
                            'Loading statuses..'
                          ) : (
                            <select
                              onChange={onChangeType}
                              value={selectedTypeObj?.value}
                              className="kt-invoice__status form-control"
                            >
                              {types.items.map(({ label, value }) => (
                                <option key={value} value={value}>
                                  {label}
                                </option>
                              ))}
                            </select>
                          )}

                          <button
                            disabled={
                              types.isProccessing || status.isProcessing
                            }
                            type="submit"
                            className="btn btn-label-brand btn-bold pull-right"
                          >
                            {status.isProcessing
                              ? <FormattedMessage id="CHECKOUT.ORDER" /> + '...'
                              : 'Save status'}
                          </button>
                        </form>
                      </span>
                    </div>
                  )}

                  {admin && (
                    <div className="kt-invoice__item mt-10">
                      <span className="kt-invoice__subtitle">Betaalstatus</span>
                      <span className="kt-invoice__te.kt-invoice__containerxt">
                        <form
                          onSubmit={submitPaymentTypeForm}
                          className="d-flex"
                        >
                          <select
                            onChange={onChangePaymentType}
                            className="kt-invoice__status form-control"
                          >
                            <option key="pending" value="payed">
                              In afwachting{' '}
                            </option>
                            <option key="payed" value="payed">
                              Betaald{' '}
                            </option>
                          </select>

                          <button
                            disabled={
                              types.isProccessing || status.isProcessing
                            }
                            type="submit"
                            className="btn btn-label-brand btn-bold pull-right"
                          >
                            {status.isProcessing
                              ? <FormattedMessage id="CHECKOUT.ORDER" /> + '...'
                              : 'Save status'}
                          </button>
                        </form>
                      </span>
                    </div>
                  )}
                </div>
              </div>

              <div className="kt-invoice__body">
                <div className="kt-invoice__container">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>
                            <FormattedMessage id="ORDER.ORDER" />
                          </th>
                          <th>
                            <FormattedMessage id="ORDER.PART" />
                          </th>
                          <th>
                            <FormattedMessage id="ORDER.QTY" />
                          </th>
                          <th>
                            <FormattedMessage id="ORDER.PRICE_PER_PIECE" />
                          </th>
                          <th>
                            <FormattedMessage id="ORDER.PRICE" />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {project.products &&
                          project.products.map((item) => (
                            <Fragment key={item.id}>
                              <tr>
                                <td>
                                  {item.name}
                                  <br />
                                  <small>
                                    <FormattedMessage id="ORDER.MATERIAL" />:{' '}
                                    {item.material.name}
                                  </small>
                                  <br />
                                  <small>
                                    <FormattedMessage id="ORDER.THICKNESS" />:{' '}
                                    {parseFloat(item.material.thickness)}
                                    mm
                                  </small>
                                  <br />
                                  <small>
                                    <FormattedMessage id="ORDER.COLOR" />:{' '}
                                    {getColorName(item.material)}
                                  </small>
                                  <br />
                                  <small>
                                    <FormattedMessage id="ORDER.SIZE" />:{' '}
                                    {item.width.toFixed(1)} x{' '}
                                    {item.height.toFixed(1)}mm
                                  </small>
                                  <br />
                                  {item?.width >= item?.height && (
                                    <small className={'text-muted'}>
                                      <FormattedMessage
                                        id={'CONFIGURATOR.TOLERANCE'}
                                      />
                                      +/-&nbsp;
                                      {getToleranceTableValue(
                                        parseFloat(item?.width)
                                      )}{' '}
                                      mm
                                    </small>
                                  )}

                                  {item?.height > item?.width && (
                                    <small className={'text-muted'}>
                                      <FormattedMessage
                                        id={'CONFIGURATOR.TOLERANCE'}
                                      />
                                      +/-&nbsp;
                                      {getToleranceTableValue(
                                        parseFloat(item?.height)
                                      )}{' '}
                                      mm
                                    </small>
                                  )}
                                  <br />
                                  {item?.bendings?.length > 0 &&
                                    item?.bendings?.map((bending, index) => {
                                      return (
                                        <small key={`bending-${index}`}>
                                          {bending?.direction ===
                                            'vertical' && (
                                            <FormattedMessage id="BENDING.VERTICAL" />
                                          )}
                                          {bending?.direction ===
                                            'horizontal' && (
                                            <FormattedMessage id="BENDING.HORIZONTAL" />
                                          )}
                                          : offset {bending?.offset} mm
                                          {' / '}
                                          {bending?.degrees}&#176;{' '}
                                          <FormattedMessage id="BENDING.DEGREES" />
                                          {' / '}
                                          {bending?.side === 'down' && (
                                            <FormattedMessage id="BENDING.DOWN" />
                                          )}
                                          {bending?.side === 'up' && (
                                            <FormattedMessage id="BENDING.UP" />
                                          )}
                                        </small>
                                      );
                                    })}
                                  <br />
                                  {item.foil_removal && (
                                    <>
                                      <small>
                                        <FormattedMessage id="ORDER.FOIL_REMOVAL" />
                                      </small>
                                      <br />
                                    </>
                                  )}
                                  {admin && (
                                    <>
                                      <a
                                        className="kt-font-sm"
                                        href={`${API_URL}/api/product/${item.id}/download/dxf`}
                                        target={'_blank'}
                                      >
                                        Download DXF
                                      </a>
                                      <br />
                                      <a
                                        className="kt-font-sm"
                                        href={`${API_URL}/api/product/${item.id}/download/dxf-original`}
                                        target={'_blank'}
                                      >
                                        Download{' '}
                                        {item.name.indexOf('.stp') !== -1 ||
                                        item.name.indexOf('.step') !== -1 ||
                                        item.name.indexOf('.STP') !== -1 ||
                                        item.name.indexOf('.STEP') !== -1
                                          ? 'STEP'
                                          : 'DXF'}{' '}
                                        Original
                                      </a>
                                    </>
                                  )}
                                </td>
                                <td
                                  width={100}
                                  height={100}
                                  className="text-center"
                                >
                                  <img
                                    src={`${API_URL}/projects${item.svg}`}
                                    alt=""
                                    width={80}
                                    height={80}
                                  />
                                </td>
                                <td>{item.quantity}</td>
                                <td>
                                  € {(item.price / item.quantity).toFixed(2)}
                                </td>
                                <td
                                  className="kt-font-danger kt-font-lg"
                                  onClick={() => {
                                    setShowCalculation(
                                      showCalculation == item.id ? 0 : item.id
                                    );
                                  }}
                                >
                                  € {item.price.toFixed(2)}
                                </td>
                              </tr>
                              {showCalculation == item.id && admin && (
                                <>
                                  <tr key={item.id + 'calc'}>
                                    <td colSpan={5}>
                                      <small>
                                        {item.calculation
                                          .split('\r\n')
                                          .map((calcItem, index) => (
                                            <span
                                              style={{ display: 'block' }}
                                              key={index}
                                            >
                                              {calcItem}
                                            </span>
                                          ))}
                                      </small>
                                    </td>
                                  </tr>
                                </>
                              )}
                            </Fragment>
                          ))}

                        <tr>
                          <td>
                            <FormattedMessage id="ORDER.STARTUP_COSTS" />
                          </td>
                          <td />
                          <td />
                          <td />
                          <td className="kt-font-danger kt-font-lg">
                            € {project.startup_costs.toFixed(2)}
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <FormattedMessage id="ORDER.SHIPPING_COSTS" />{' '}
                            {shipping_type
                              ? '(' + shipping_type.toUpperCase() + ')'
                              : ''}
                          </td>
                          <td />
                          <td />
                          <td />
                          <td className="kt-font-danger kt-font-lg">
                            € {project.shipping_costs.toFixed(2)}
                          </td>
                        </tr>
                        {project.discount > 0 && (
                          <tr>
                            <td>
                              <FormattedMessage id="ORDER.DISCOUNT" />
                            </td>
                            <td />
                            <td />
                            <td />
                            <td className="kt-font-danger kt-font-lg">
                              € -{project.discount.toFixed(2)}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="kt-invoice__footer">
                <div className="kt-invoice__container">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>
                            <FormattedMessage id="ORDER.DAYS_TO_DELIVERY" />
                          </th>
                          <th>
                            <FormattedMessage id="ORDER.ESTIMATED_DELIVERY" />
                          </th>
                          <th className="text-right">
                            <FormattedMessage id="ORDER.VAT" />
                          </th>
                          <th className="text-right">
                            <FormattedMessage id="ORDER.TOTAL" />{' '}
                            <FormattedMessage id="ORDER.EXCL" />{' '}
                            <FormattedMessage id="ORDER.VAT" />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="kt-font-danger kt-font-xl kt-font-boldest">
                            {project.delivery_days}{' '}
                            <FormattedMessage id="ORDER.DAYS" />
                          </td>
                          <td className="kt-font-danger kt-font-xl kt-font-boldest">
                            {project?.created_at ? formattedDeliveryDate : ''}
                          </td>
                          <td className="kt-font-danger kt-font-xl kt-font-boldest text-right">
                            € {project.vat.toFixed(2)}
                          </td>
                          <td className="kt-font-danger kt-font-xl kt-font-boldest text-right">
                            € {project.price.toFixed(2)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="kt-invoice__actions">
                <div className="kt-invoice__container">
                  <button
                    type="button"
                    className="btn btn-label-brand btn-bold"
                    onClick={window.print}
                  >
                    <FormattedMessage id="ORDER.DOWNLOAD_ORDER" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

DetailsPage.propTypes = {
  isProcessing: PropTypes.bool.isRequired,
  data: PropTypes.object,
  getDetailedOrder: PropTypes.func.isRequired,
  // resetDraw: PropTypes.func.isRequired,
  status: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  getOrderTypes: PropTypes.func.isRequired,
};

const mapStateToProps = ({
  auth: {
    user: { admin },
  },
  orders: {
    detailedOrder: { status, data, isProcessing },
    orderTypes,
  },
}) => ({
  data,
  isProcessing,
  status,
  types: orderTypes,
  admin,
});

const mapDispatchToProps = {
  getDetailedOrder: (data, successCallBack) =>
    ordersActions.getOrderDetailedRequest({
      data,
      successCallBack,
    }),
  getOrderTypes: (cb) =>
    ordersActions.getOrderTypeRequest({ successCallBack: cb }),
  saveOrderType: (id, type) =>
    ordersActions.changeDetailedOrderRequest({
      id,
      type,
    }),
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(DetailsPage)
);
