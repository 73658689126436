import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { PortletBody } from '../../../../partials/content/Portlet';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import Multiselect from 'multiselect-react-dropdown';
import initApi from '../../../../crud/apiConfig/apiConfig';
import {
  API_URL,
  API_URLS,
} from '../../../../common/constants/api/apiConstants';

export const Export = () => {
  const intl = useIntl();
  const [progress, setProgress] = useState(false);
  const [exportOption, setExportOption] = useState(undefined);
  // const [validated, setValidated] = useState(false);
  const [download, setDownload] = useState(false);
  const [newest, setNewest] = useState(false);
  const [discountData, setDiscountData] = useState('');
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    getNewest();
  }, []);

  useEffect(() => {
    if (exportOption) {
      setDisabled(false);
    }
  }, [exportOption]);

  const importTypes = [
    {
      title: intl.formatMessage({ id: 'IMPORT.MATERIALS' }),
      type: 'materials',
    },
    {
      title: intl.formatMessage({ id: 'IMPORT.USERS' }),
      type: 'users',
    },
    {
      title: intl.formatMessage({ id: 'EXPORT.USERS_PRICE_SETTINGS_DISCOUNT' }),
      type: 'users-discount',
    },
  ];

  const getNewest = async () => {
    let newestUrl = API_URL + API_URLS.NEWEST_EXPORT_DATA;
    let newestResult = await initApi().get(newestUrl);
    if (newestResult) {
      newestResult.data.finishedAt = new Date(newestResult.data.finishedAt);
      setNewest(newestResult.data);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setProgress(intl.formatMessage({ id: 'EXPORT.GENERATING' }));

    let exportUrl = API_URL + API_URLS.EXPORT_CSV_FILE(exportOption);
    let exportResult = await initApi().post(exportUrl);
    getNewest();
    setProgress(false);
    setDownload(exportResult);
  };

  const downloadCsvFile = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (download.data.success) {
      let downloadUrl =
        API_URL + API_URLS.DOWNLOAD_CSV_FILE(download.data.run_id);
      let downloadFile = await initApi().get(downloadUrl);
      const url = window.URL.createObjectURL(
        new Blob([downloadFile.data], { type: 'text/csv;charset=utf-8;' })
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'materialen-export.csv');
      document.body.appendChild(link);
      link.click();
    }
  };

  const downloadNewestFile = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (newest) {
      let downloadUrl = API_URL + API_URLS.DOWNLOAD_CSV_FILE(newest.id);
      let downloadFile = await initApi().get(downloadUrl);
      const url = window.URL.createObjectURL(
        new Blob([downloadFile.data], { type: 'text/csv;charset=utf-8;' })
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'materialen-export.csv');
      document.body.appendChild(link);
      link.click();
    }
  };

  const downloadUsersDiscountFile = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    let fileUrl = API_URL + API_URLS.PRICE_SETTINGS_DATA_DISCOUNT;
    let response = await initApi().get(fileUrl);

    if (response && response.data && response.data.url) {
      const match = response.data.url.match(/\/excel_exports\/.+$/);
      const path = match ? match[0] : '';
      setDiscountData({ ...discountData, url: path });

      const cleanedUrl = `${API_URL}/${path}`.replace(/([^:]\/)\/+/g, '$1');

      const link = document.createElement('a');
      link.href = cleanedUrl;

      link.setAttribute('download', 'users-price-discount-settings.csv');
      document.body.appendChild(link);
      link.click();
    }
  };

  return (
    <PortletBody>
      <div className="kt-section kt-margin-t-30">
        <div className="kt-section__body w-100">
          <Form
            noValidate
            validated={false /*validated*/}
            onSubmit={handleSubmit}
          >
            {progress && (
              <Form.Group as={Row} className="mb-3">
                <Col sm={{ span: 4, offset: 2 }}>
                  <div
                    className="alert alert-solid-warning alert-bold fade show kt-margin-t-20 kt-margin-b-40"
                    role="alert"
                  >
                    <div className="alert-icon">
                      <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                    </div>
                    <div className="alert-text">{progress}</div>
                  </div>
                </Col>
              </Form.Group>
            )}

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="2">
                <FormattedMessage id={'IMPORT.TYPE'} />
              </Form.Label>
              <Col sm="4">
                <Multiselect
                  options={importTypes}
                  selectedValues={false}
                  singleSelect={true}
                  onSelect={(selectedList) => {
                    setExportOption(selectedList[0].type);

                    if (selectedList[0].type === 'users-discount') {
                      const currentTime = new Date().toLocaleTimeString();

                      setDiscountData({
                        ...discountData,
                        finishedAt: currentTime,
                      });
                    }
                  }}
                  avoidHighlightFirstOption={true}
                  placeholder={intl.formatMessage({ id: 'IMPORT.SELECT' })}
                  displayValue="title"
                />
              </Col>
            </Form.Group>

            {exportOption !== 'users-discount' && (
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="2"></Form.Label>
                <Col sm="1">
                  <Button disabled={disabled} variant="primary" type="submit">
                    <FormattedMessage id={'EXPORT'} />
                  </Button>
                </Col>
                <Col sm="1">
                  {download && (
                    <Button variant="success" onClick={downloadCsvFile}>
                      <FormattedMessage id={'DOWNLOAD'} />
                    </Button>
                  )}
                </Col>
              </Form.Group>
            )}
          </Form>
        </div>

        <br />

        {newest && (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th colSpan={4}>
                  <FormattedMessage id={'EXPORT.LAST'} />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <FormattedMessage id={'EXPORT.DATETIME'} />:{' '}
                  <b>{moment(newest.finishedAt).format('DD-MM-YYYY HH:mm')}</b>
                </td>
                <td>
                  <FormattedMessage id={'EXPORT.TYPE'} />:{' '}
                  <b>{newest.importOption}</b>
                </td>
                <td>
                  <FormattedMessage id={'EXPORT.TOTAL_ITEMS'} />:{' '}
                  <b>{newest.processed}</b>
                </td>
                <td>
                  <Button
                    onClick={downloadNewestFile}
                    variant="success"
                    size="sm"
                  >
                    Download
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table>
        )}

        {discountData && exportOption === 'users-discount' && (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th colSpan={4}>
                  <FormattedMessage id={'EXPORT.LAST'} />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <FormattedMessage id={'EXPORT.DATETIME'} />:{' '}
                  <b>
                    {moment(discountData.finishedAt, 'h:mm:ss A').format(
                      'DD-MM-YYYY HH:mm'
                    )}
                  </b>
                </td>
                <td>
                  <FormattedMessage id={'EXPORT.TYPE'} />:{' '}
                  <b>users with price discount settings</b>
                </td>
                <td>
                  <Button
                    onClick={downloadUsersDiscountFile}
                    variant="success"
                    size="sm"
                  >
                    Download
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table>
        )}
      </div>
    </PortletBody>
  );
};
