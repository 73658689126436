import { createAction } from 'redux-actions';

export const getCountries = createAction('GET_COUNTRIES_REQUEST');
export const getCountriesSuccess = createAction('GET_COUNTRIES_SUCCESS');
export const getCountriesError = createAction('GET_COUNTRIES_ERROR');
export const getCountry = createAction('GET_COUNTRY_REQUEST');
export const getCountrySuccess = createAction('GET_COUNTRY_SUCCESS');
export const getCountryError = createAction('GET_COUNTRY_ERROR');
export const deleteCountry = createAction('DELETE_COUNTRY_REQUEST');
export const deleteCountrySuccess = createAction('DELETE_COUNTRY_SUCCESS');
export const deleteCountryError = createAction('DELETE_COUNTRY_ERROR');
export const updateCountry = createAction('UPDATE_COUNTRY_REQUEST');
export const updateCountrySuccess = createAction('UPDATE_COUNTRY_SUCCESS');
export const updateCountryError = createAction('UPDATE_COUNTRY_ERROR');
