import React from 'react';
import { Grid, Typography, Link } from '@material-ui/core';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import CallIcon from '@mui/icons-material/Call';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';

import { FormattedMessage, injectIntl } from 'react-intl';

import { toAbsoluteUrl } from '../../../../_metronic/utils/utils';

const Footer = (props) => {
  const { intl } = props;

  const privacyStLinks = {
    en:
      'https://api.prod.cutwise.nl/assets/images/Privacy_Statement_BMTEC_BV_EN.pdf',
    nl:
      'https://api.prod.cutwise.nl/assets/images/Privacy_Statement_BMTEC_BV_NL.pdf',
    de:
      'https://api.prod.cutwise.nl/assets/images/Datenschutzerklarung_GmbH.pdf',
  };

  const termsAnsCondLinks = {
    en:
      'https://api.prod.cutwise.nl/assets/images/Terms_and_Conditions_BMTEC_BV_EN.pdf',
    nl:
      'https://api.prod.cutwise.nl/assets/images/Algemene_voorwaarden_BMTEC_NL.pdf',
    de:
      'https://api.prod.cutwise.nl/assets/images/Allgemeinen_GmbH.pdf',
  };

  return (
    <>
      <Grid
        item
        xs={12}
        container
        justifyContent="space-around"
        alignItems="center"
        className="bp-footer"
      >
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          container
          flexDirection="column"
          alignItems="center"
        >
          <Typography variant="p">
            <MailOutlineIcon />
            &nbsp;
            <a
              href={
                intl?.locale === 'de'
                  ? 'mailto:info@bmtec-laserschneiden.de'
                  : 'mailto:info@bmtec.nl'
              }
            >
              {intl?.locale === 'de'
                ? 'info@bmtec-laserschneiden.de'
                : 'info@bmtec.nl'}
            </a>
            <br />
            <CallIcon />
            &nbsp;
            <a
              href={
                intl?.locale === 'en'
                  ? 'tel:+31591785454'
                  : intl?.locale === 'de'
                  ? '+4959711653004 '
                  : 'tel:0591785454'
              }
            >
              {intl?.locale === 'en'
                ? '+31 (0)591 785 454'
                : intl?.locale === 'de'
                ? '+49 (0)5971 1653004'
                : '0591 78 54 54'}
            </a>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4} container flexDirection="column">
          <img
            src={toAbsoluteUrl('/clients-logos/bmtec-logo-web.svg')}
            alt="bmtec-logo"
            height="65px"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} container justifyContent="center">
          <Link href="https://www.facebook.com/bmtechniek" target="_blank">
            <FacebookIcon />
          </Link>

          <Link
            href={
              intl?.locale === 'de'
                ? 'https://www.instagram.com/bmtec_laserschneiden/ '
                : 'https://www.instagram.com/bmtecnl/'
            }
            target="_blank"
          >
            <InstagramIcon />
          </Link>

          <Link
            href={
              intl?.locale === 'de'
                ? 'https://www.linkedin.com/company/bmtec-laserschneiden/'
                : 'https://nl.linkedin.com/company/bmtec-nl'
            }
            target="_blank"
          >
            <LinkedInIcon />
          </Link>

          <Link
            href="https://www.youtube.com/c/BMTECStadskanaal"
            target="_blank"
          >
            <YouTubeIcon />
          </Link>
        </Grid>
      </Grid>

      <Grid
        item
        container
        xs={12}
        justifyContent="center"
        className="cookie-policy-section"
      >
        <Link href={privacyStLinks[intl?.locale]} target="_blank">
          <Typography variant="p">
            <FormattedMessage id="Privacy statement" />
          </Typography>
        </Link>

        <Link href={termsAnsCondLinks[intl?.locale]} target="_blank">
          <Typography variant="p">
            <FormattedMessage id="Algemene voorwaarden" />
          </Typography>
        </Link>
      </Grid>
    </>
  );
};

export default injectIntl(Footer);
