/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/pages/auth/AuthPage`, `src/pages/home/HomePage`).
 */

import React from 'react';
import { Switch, withRouter } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { useLastLocation } from 'react-router-last-location';
import { useIntl } from 'react-intl';
import HomePage from '../pages/home/HomePage';
import ErrorsPage from '../pages/errors/ErrorsPage';
import LogoutPage from '../pages/auth/Logout';
import { LayoutContextProvider } from '../../_metronic';
import Layout from '../../_metronic/layout/Layout';
import * as routerHelpers from '../router/RouterHelpers';
import AuthPage from '../pages/auth/AuthPage';
import RegisterPage from '../pages/auth/RegisterPage';
import { ROUTES } from '../common/constants/routes/routes';
import PrivateRoute from './PrivateRoute';

import MetaTags from '../components/MetaTags';
import HDPEMaterialPage from '../../app/pages/home/materialsLP/HDPEMaterialPage';
import POMMaterialPage from '../../app/pages/home/materialsLP/POMMaterialPage';
import PMMAXTMaterialPage from '../../app/pages/home/materialsLP/PMMAXTMaterialPage';
import PMMAGSMaterialPage from '../../app/pages/home/materialsLP/PMMAGSMaterialPage';
import HMPE1000MaterialPage from '../../app/pages/home/materialsLP/HMPE1000MaterialPage';
import HMPE500MaterialPage from '../../app/pages/home/materialsLP/HMPE500MaterialPage';
import PCMaterialPage from '../../app/pages/home/materialsLP/PCMaterialPage';
import PETGMaterialPage from '../../app/pages/home/materialsLP/PETGMaterialPage';
import PTFEMaterialPage from '../../app/pages/home/materialsLP/PTFEMaterialPage';
import PETPMaterialPage from '../../app/pages/home/materialsLP/PETPMaterialPage';
import PSMaterialPage from '../../app/pages/home/materialsLP/PSMaterialPage';
import PAMaterialPage from '../../app/pages/home/materialsLP/PAMaterialPage';
import PPMaterialPage from '../../app/pages/home/materialsLP/PPMaterialPage';
import PIMaterialPage from '../../app/pages/home/materialsLP/PIMaterialPage';
import PEEKMaterialPage from '../../app/pages/home/materialsLP/PEEKMaterialPage';
import ABSMaterialPage from '../../app/pages/home/materialsLP/ABSMaterialPage';
import HPLMaterialPage from '../../app/pages/home/materialsLP/HPLMaterialPage';
import AlupanelMaterialPage from '../../app/pages/home/materialsLP/AlupanelMaterialPage';
import EPDMMaterialPage from '../../app/pages/home/materialsLP/EPDMMaterialPage';
import NBRMaterialPage from '../../app/pages/home/materialsLP/NBRMaterialPage';
import PURMaterialPage from '../../app/pages/home/materialsLP/PURMaterialPage';
import SBRMaterialPage from '../../app/pages/home/materialsLP/SBRMaterialPage';
import SiliconeMaterialPage from '../../app/pages/home/materialsLP/SiliconeMaterialPage';

export const Routes = withRouter(({ history }) => {
  const lastLocation = useLastLocation();
  routerHelpers.saveLastLocation(lastLocation);

  const intl = useIntl();

  const { menuConfig, userLastLocation } = useSelector(
    ({ /* auth, urls, */ builder: { menuConfig } }) => {
      return {
        menuConfig,
        userLastLocation: routerHelpers.getLastLocation(),
      };
    },
    shallowEqual
  );

  return (
    /* Create `LayoutContext` from current `history` and `menuConfig`. */
    <LayoutContextProvider history={history} menuConfig={menuConfig}>
      <MetaTags
        title={intl.formatMessage({ id: 'META.TITLE' })}
        description={intl.formatMessage({ id: 'META.DESC' })}
        keywords={intl.formatMessage({ id: 'META.KEYWORDS' })}
      />

      <Switch>
        <PrivateRoute path={ROUTES.AUTH_LOGIN_LANG} component={AuthPage} />
        <PrivateRoute path={ROUTES.AUTH_LOGIN} component={AuthPage} />
        <PrivateRoute
          path={ROUTES.AUTH_REGISTRATION_LANG}
          component={RegisterPage}
        />
        <PrivateRoute
          path={ROUTES.AUTH_REGISTRATION}
          component={RegisterPage}
        />
        <PrivateRoute path={ROUTES.AUTH_FORGOT_PASS} component={AuthPage} />
        <PrivateRoute
          path={ROUTES.AUTH_RESET_PASS_WITH_PARAMS}
          component={AuthPage}
        />
        <PrivateRoute path={ROUTES.AUTH_RESET_PASS} component={AuthPage} />
        <PrivateRoute path={ROUTES.AUTH} component={AuthPage} />
        <PrivateRoute path={ROUTES.ERROR} component={ErrorsPage} />
        <PrivateRoute path={ROUTES.LOGOUT} component={LogoutPage} />

        {Object.values(ROUTES.HDPE_MATERIALS_LP).map((item) => {
          return (
            <PrivateRoute key={item} path={item} component={HDPEMaterialPage} />
          );
        })}

        {Object.values(ROUTES.POM_MATERIAL_LP).map((item) => {
          return (
            <PrivateRoute key={item} path={item} component={POMMaterialPage} />
          );
        })}

        {Object.values(ROUTES.PMMA_XT_MATERIAL_LP).map((item) => {
          return (
            <PrivateRoute key={item} path={item} component={PMMAXTMaterialPage} />
          );
        })}

        {Object.values(ROUTES.PMMA_GS_MATERIAL_LP).map((item) => {
          return (
            <PrivateRoute key={item} path={item} component={PMMAGSMaterialPage} />
          );
        })}

        {Object.values(ROUTES.HMPE_1000_MATERIAL_LP).map((item) => {
          return (
            <PrivateRoute
              key={item}
              path={item}
              component={HMPE1000MaterialPage}
            />
          );
        })}

        {Object.values(ROUTES.HMPE_500_MATERIAL_LP).map((item) => {
          return (
            <PrivateRoute
              key={item}
              path={item}
              component={HMPE500MaterialPage}
            />
          );
        })}

        {Object.values(ROUTES.PC_MATERIAL_LP).map((item) => {
          return (
            <PrivateRoute key={item} path={item} component={PCMaterialPage} />
          );
        })}

        {Object.values(ROUTES.PETG_MATERIAL_LP).map((item) => {
          return (
            <PrivateRoute key={item} path={item} component={PETGMaterialPage} />
          );
        })}

        {Object.values(ROUTES.PTFE_MATERIAL_LP).map((item) => {
          return (
            <PrivateRoute key={item} path={item} component={PTFEMaterialPage} />
          );
        })}

        {Object.values(ROUTES.PETP_MATERIAL_LP).map((item) => {
          return (
            <PrivateRoute key={item} path={item} component={PETPMaterialPage} />
          );
        })}

        {Object.values(ROUTES.PS_MATERIAL_LP).map((item) => {
          return (
            <PrivateRoute key={item} path={item} component={PSMaterialPage} />
          );
        })}

        {Object.values(ROUTES.PA_MATERIAL_LP).map((item) => {
          return (
            <PrivateRoute key={item} path={item} component={PAMaterialPage} />
          );
        })}

        {Object.values(ROUTES.PP_MATERIAL_LP).map((item) => {
          return (
            <PrivateRoute key={item} path={item} component={PPMaterialPage} />
          );
        })}

        {Object.values(ROUTES.PI_MATERIAL_LP).map((item) => {
          return (
            <PrivateRoute key={item} path={item} component={PIMaterialPage} />
          );
        })}

        {Object.values(ROUTES.PEEK_MATERIAL_LP).map((item) => {
          return (
            <PrivateRoute key={item} path={item} component={PEEKMaterialPage} />
          );
        })}

        {Object.values(ROUTES.ABS_MATERIAL_LP).map((item) => {
          return (
            <PrivateRoute key={item} path={item} component={ABSMaterialPage} />
          );
        })}

        {Object.values(ROUTES.HPL_MATERIAL_LP).map((item) => {
          return (
            <PrivateRoute key={item} path={item} component={HPLMaterialPage} />
          );
        })}

        {Object.values(ROUTES.DIBOND_ALUPANEL_MATERIAL_LP).map((item) => {
          return (
            <PrivateRoute
              key={item}
              path={item}
              component={AlupanelMaterialPage}
            />
          );
        })}

        {Object.values(ROUTES.EPDM_MATERIAL_LP).map((item) => {
          return (
            <PrivateRoute key={item} path={item} component={EPDMMaterialPage} />
          );
        })}

        {Object.values(ROUTES.NBR_MATERIAL_LP).map((item) => {
          return (
            <PrivateRoute key={item} path={item} component={NBRMaterialPage} />
          );
        })}

        {Object.values(ROUTES.PUR_MATERIAL_LP).map((item) => {
          return (
            <PrivateRoute key={item} path={item} component={PURMaterialPage} />
          );
        })}

        {Object.values(ROUTES.SBR_MATERIAL_LP).map((item) => {
          return (
            <PrivateRoute key={item} path={item} component={SBRMaterialPage} />
          );
        })}

        {Object.values(ROUTES.SILICONE_MATERIAL_LP).map((item) => {
          return (
            <PrivateRoute
              key={item}
              path={item}
              component={SiliconeMaterialPage}
            />
          );
        })}

        <PrivateRoute>
          <Layout>
            <HomePage userLastLocation={userLastLocation} />
          </Layout>
        </PrivateRoute>
      </Switch>
    </LayoutContextProvider>
  );
});
