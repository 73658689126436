export const ELEMENTS = {
    CIRCLE: 'circle',
    PATH: 'path',
    LINE: 'line',
    ELLIPSE: 'ellipse',
    GROUP: 'g',
};

export const INVALID_DXF_SPLITTER = /(3DSOLID)|(ACAD_PROXY_ENTITY)|(ARCALIGNEDTEXT)|(ATTRIB)|(BODY)|(HATCH)|(IMAGE)|(LEADER)|(MLINE)|(OLEFRAME)|(OLE2FRAME)|(RAY)|(REGION)|(RTEXT)|(SEQEND)|(SHAPE)|(TOLERANCE)|(TRACE)|(VIEWPORT)|(WIPEOUT)|(XLINE)/gm;

export const INVALID_DXF_ELEMENTS = [
    '3DSOLID',
    'ACAD_PROXY_ENTITY',
    'ARCALIGNEDTEXT',
    'ATTRIB', 'BODY',
    'HATCH', 'IMAGE',
    'LEADER', 'MLINE',
    'OLEFRAME', 'OLE2FRAME',
    'RAY', 'REGION',
    'RTEXT', 'SEQEND',
    'SHAPE', 'TOLERANCE',
    'TRACE', 'VIEWPORT',
    'WIPEOUT', 'XLINE'
];
