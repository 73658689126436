// import React from 'react';
class Leveler {

  levelPaths (paths) {
    console.log(paths)

    let polyGons = [];
    // let index = 0;

    for (let checkPath of paths) {
      let newPolygon = [];
      let elements = checkPath.d.split(/(?=[CLAQZ])/g);
      for (let element of elements) {
        if (element.includes(' ')) {

          let newObject = {
            type: 'M',
            id: checkPath.id
          }
          if (element.includes('A')) {
            newObject.type = 'A';
          }
          if (element.includes('Q')) {
            newObject.type = 'Q';
          }
          if (element.includes('L')) {
            newObject.type = 'L';
          }
          if (element.includes('C')) {
            newObject.type = 'C';
          }

          let elementParts = element.split(' ');

          newObject.x = parseFloat(elementParts[elementParts.length - 2]);
          newObject.y = parseFloat(elementParts[elementParts.length - 1]);

          newPolygon.push(newObject);
        }
      }
      console.log('NEWPOL', newPolygon);
      polyGons.push(newPolygon);
      index++;
    }

    let newPaths = [];
    for (let path of paths) {
      let elements = path.d.split(/(?=[CLAQZ])/g);
      path.level = 1;

      let elementParts = elements[0].split(' ');
      let x = parseFloat(elementParts[elementParts.length - 2]);
      let y = parseFloat(elementParts[elementParts.length - 1]);

      for (let polygon of polyGons) {
        if (polygon[0].id != path.id) {

          let pathIsInside = this.pointIsInPoly({
            x: x,
            y: y
          }, polygon);

          path.isInside = pathIsInside;

          newPaths.push({
            id: path.id,
            isInside: pathIsInside
          });
        }
      }
    }

    return newPaths;
  }

  pointIsInPoly (p, polygon) {
    var isInside = false;

    var minX = polygon[0].x;
    var maxX = polygon[0].x;
    var minY = polygon[0].y;
    var maxY = polygon[0].y;
    // if(polygon.type == "C") {
    //   minX = minX +
    // }

    for (var n = 1; n < polygon.length; n++) {
      var q = polygon[n];

      minX = Math.min(q.x, minX);
      maxX = Math.max(q.x, maxX);
      minY = Math.min(q.y, minY);
      maxY = Math.max(q.y, maxY);
    }

    console.log(minX, maxX, minY, maxY);

    if (p.x < minX || p.x > maxX || p.y < minY || p.y > maxY) {
      return false;
    }

    var i = 0;
    var j = polygon.length - 1;
    for (i, j; i < polygon.length; j = i++) {
      if ((polygon[i].y > p.y) != (polygon[j].y > p.y) &&
        p.x < (polygon[j].x - polygon[i].x) * (p.y - polygon[i].y) / (polygon[j].y - polygon[i].y) + polygon[i].x) {
        isInside = !isInside;
      }
    }

    return isInside;
  }

}

export default Leveler;
