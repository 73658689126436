import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useIntl } from 'react-intl';
import MasterPage from './master/MasterPage';
import DetailsPage from './details/DetailsPage';

import MetaTags from '../../../components/MetaTags';

export default function Orders() {
  const intl = useIntl();

  return (
    <>
      <MetaTags
        title={intl.formatMessage({ id: 'ORDER.META.TITLE' })}
        description={intl.formatMessage({ id: 'ORDER.META.DESC' })}
        keywords={intl.formatMessage({ id: 'ORDER.META.KEYWORDS' })}
      />

      <Switch>
        <Redirect exact from="/orders" to="/orders/master" />

        {/* Layout */}
        <Route path="/orders/master" component={MasterPage} />

        <Route path="/orders/details/:id" component={DetailsPage} />
      </Switch>
    </>
  );
}
