import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

const UserAddressForm = ({ onChange, fields , countries, type }) => {

    const intl = useIntl();

    return (
      <>
        <div className="row">
          <div className="col-md-12 mb-3">
            <label htmlFor="{'companyName' + type}"><FormattedMessage id={"CHECKOUT.COMPANY_NAME"} /></label>
            <input
              type="text"
              className="form-control"
              id={'companyName' + type}
              key={'companyName' + type}
              required={false}
              value={fields.company_name}
              onChange={onChange('company_name')}
            />
            <div className="invalid-feedback">
              Valid company name is required.
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mb-3">
            <label htmlFor="{'taxNumber' + type}"><FormattedMessage id={"CHECKOUT.TAX_NUMBER"} /></label>
            <input
              type="text"
              className="form-control"
              id={'taxNumber' + type}
              key={'taxNumber' + type}
              required={false}
              value={fields.tax_number}
              onChange={onChange('tax_number')}
            />
            <div className="invalid-feedback">
              Valid company name is required.
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 mb-3">
            <label htmlFor="firstName"><FormattedMessage id={"CHECKOUT.FIRST_NAME"} /></label>
            <input
              type="text"
              className="form-control"
              id={'firstName' + type}
              key={'firstName' + type}
              required
              value={fields.first_name}
              onChange={onChange('first_name')}
            />
            <div className="invalid-feedback">
              Valid first name is required.
            </div>
          </div>

          <div className="col-md-6 mb-3">
            <label htmlFor="first_name"><FormattedMessage id={"CHECKOUT.LAST_NAME"} /></label>
            <input
              type="text"
              className="form-control"
              id={'lastName' + type}
              key={'lastName' + type}
              required
              value={fields.last_name}
              onChange={onChange('last_name')}
            />
            <div className="invalid-feedback">
              Valid first name is required.
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-7 mb-3">
            <label htmlFor="address"><FormattedMessage id={"CHECKOUT.ADDRESS"} /></label>
            <input
              type="text"
              className="form-control"
              id={'address' + type}
              key={'address' + type}
              required
              value={fields.address}
              onChange={onChange('address')}
            />
            <div className="invalid-feedback">
              Please enter your shipping address.
            </div>
          </div>

          <div className="col-md-3 mb-3">
            <label htmlFor="house_number"><FormattedMessage id={"CHECKOUT.HOUSE_NUMBER"} /></label>
            <input
              type="text"
              className="form-control"
              id={'houseNumber' + type}
              key={'houseNumber' + type}
              required
              value={fields.house_number ? fields.house_number: ''}
              onChange={onChange('house_number')}
            />
            <div className="invalid-feedback">
              Please enter your house number.
            </div>
          </div>


          <div className="col-md-2 mb-3">
            <label htmlFor="ext"><FormattedMessage id={"CHECKOUT.EXT"} /></label>
            <input
              type="text"
              className="form-control"
              id={'ext' + type}
              key={'ext' + type}
              required={false}
              value={fields.ext}
              onChange={onChange('ext')}
            />
            <div className="invalid-feedback">
              Please enter your extension.
            </div>
          </div>
        </div>


        <div className="row">

          <div className="col-md-5 mb-3">
            <label htmlFor="zip"><FormattedMessage id={"CHECKOUT.POSTAL_CODE"} /></label>
            <input
              type="text"
              className="form-control"
              id={'postalCode' + type}
              key={'postalCode' + type}
              required
              value={fields.postal_code}
              onChange={onChange('postal_code')}
            />
            <div className="invalid-feedback">
              Postal code required.
            </div>
          </div>
          <div className="col-md-7 mb-3">
            <label htmlFor="city"><FormattedMessage id={"CHECKOUT.CITY"} /></label>
            <input
              type="text"
              className="form-control"
              id={'city' + type}
              key={'city' + type}
              required
              value={fields.city}
              onChange={onChange('city')}
            />
            <div className="invalid-feedback">
              Please enter your city.
            </div>
          </div>

        </div>

        <div className="row">
          <div className="col-md-12 mb-3">
            <label htmlFor="country"><FormattedMessage id={"CHECKOUT.COUNTRY"} /></label>
            <select
              className="custom-select d-block w-100"
              required
              id={'country' + type}
              key={'country' + type}
              value={fields.country?.id}
              onChange={onChange('country')}
            >
              {countries.map((item) =>
                <option key={'Option' + item.id + type }
                        value={item.id}>{intl.formatMessage({id:item.name})}</option>
              )}
            </select>
            <div className="invalid-feedback">
              Please select a valid country.
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 mb-3">
            <label htmlFor="{'phoneNumber' + type}"><FormattedMessage id={"CHECKOUT.PHONE_NUMBER"} /></label>
            <input
              type="text"
              className="form-control"
              id={'phoneNumber' + type}
              key={'phoneNumber' + type}
              required={false}
              value={fields.phone_number}
              onChange={onChange('phone_number')}
            />
            <div className="invalid-feedback">
              Valid phone number is required.
            </div>
          </div>
        </div>
      </>
    );
  }
;


export default UserAddressForm;
