import { call, put, takeEvery } from "redux-saga/effects";

import {
    getPaymentTypesQuery,
    getPaymentTypeQuery
} from "./crud";
import {
    getPaymentTypes,
    getPaymentTypesSuccess,
    getPaymentTypesError,
    getPaymentType,
    getPaymentTypeSuccess,
    getPaymentTypeError
} from "./actions";

function* fetchPaymentTypesSaga({ payload }) {
    try {
        const res = yield call(getPaymentTypesQuery, payload);

        yield put(getPaymentTypesSuccess(res.data));
    } catch (err) {
        yield put(getPaymentTypesError(err));
    }
}

function* fetchPaymentTypeSaga({ payload }) {
    try {
        const { successCallBack = () => {} } = payload;

        const res = yield call(getPaymentTypeQuery, payload);

        yield put(
            getPaymentTypeSuccess({
                ...res.data,
                created_at: res.data.created_at
            })
        );

        yield successCallBack(res.data.type);
    } catch (err) {
        yield put(getPaymentTypeError(err.response));
    }
}

function* paymentTypesSagas() {
    yield takeEvery(getPaymentTypes, fetchPaymentTypesSaga);
    yield takeEvery(getPaymentType, fetchPaymentTypeSaga);
}

export default paymentTypesSagas;
