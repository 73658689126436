import React, { useEffect, useState, useRef, forwardRef } from 'react';
import qs from 'query-string';
import {
  Tab,
  Tabs,
  TextField,
  InputAdornment,
  FormControl,
} from '@material-ui/core';
import { CiSearch, CiFilter, CiCalendar } from 'react-icons/ci';
import { All, Processing, Sent } from './Tabs';
import {
  Portlet,
  PortletHeader,
  PortletHeaderToolbar,
} from '../../../../partials/content/Portlet';
import { Row, Col, Button, Form, Tooltip, Overlay } from 'react-bootstrap';
import Select from 'react-select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Slider from '@material-ui/core/Slider';
import DatePicker /* { setDefaultLocale } */ from 'react-datepicker';
import { injectIntl, useIntl, FormattedMessage } from 'react-intl';
import {
  API_URL,
  API_URLS,
} from '../../../../common/constants/api/apiConstants';
import initApi from '../../../../crud/apiConfig/apiConfig';
import { ordersActions } from '../../../../store/ducks/ordersReducer';
import { useHistory, withRouter } from 'react-router-dom';
import './styles.css';
import { connect } from 'react-redux';
import { withLastLocation } from 'react-router-last-location';
import PropTypes from 'prop-types';

import 'react-datepicker/dist/react-datepicker.css';

const MasterPage = ({ admin }) => {
  const initialFilter = {
    start_date: new Date(),
    end_date: new Date(),
    outstanding: true,
    satisfied: true,
    price_range: [0, 10000],
    payment_types: [],
  };

  const intl = useIntl();
  const history = useHistory();
  const pagePath = history.location.pathname;
  const activeTab = JSON.parse(localStorage.getItem(`${pagePath}`));
  const [tab, setTab] = useState(activeTab ? activeTab.nextTab : 0);
  const [totalOrders, setTotalOrders] = useState(0);
  const [totalProcessingOrders, setTotalProcessingOrders] = useState(0);
  const [totalSentOrders, setTotalSentOrders] = useState(0);
  const [search, setSearch] = useState('');
  const [showFilter, setShowFilter] = useState(false);
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [filterActive, setFilterActive] = useState(false);
  const [filter, setFilter] = useState(initialFilter);
  const [loading, setLoading] = useState(false);
  const isMounted = useRef(false);

  const filterRef = useRef(null);

  const allQuery = {
    page: 1,
    search: search,
    filter: filterActive ? JSON.stringify(filter) : '',
  };

  const productionQuery = {
    page: 1,
    search: search,
    filter: filterActive ? JSON.stringify(filter) : '',
    type: 'production_finished',
  };

  const sendQuery = {
    page: 1,
    search: search,
    filter: filterActive ? JSON.stringify(filter) : '',
    type: 'send_to_customer',
  };

  let fetchAllOrders =
    API_URL +
    API_URLS.GET_ORDERS_DATA +
    `?include=project.products,shipping_address.country,billing_address.country,payment_status,user&${qs.stringify(
      allQuery
    )}`;
  let fetchProcessingOrders =
    API_URL +
    API_URLS.GET_ORDERS_DATA +
    `?include=project.products,shipping_address.country,billing_address.country,payment_status,user&${qs.stringify(
      productionQuery
    )}`;
  let fetchSendOrders =
    API_URL +
    API_URLS.GET_ORDERS_DATA +
    `?include=project.products,shipping_address.country,billing_address.country,payment_status,user&${qs.stringify(
      sendQuery
    )}`;

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      getOrderData();
      getPaymentTypes();
    }
  }, [isMounted]);

  useEffect(() => {
    if (isMounted.current) {
      getOrderData();
    }
  }, [filterActive, isMounted]);

  const applyFilterSettings = async () => {
    setFilterActive(true);
  };

  const resetFilterSettings = async () => {
    setFilter(initialFilter);
    setFilterActive(false);
  };

  const getPaymentTypes = async () => {
    setLoading(true);

    let apiRepsonse = await initApi().get(
      API_URL + API_URLS.GET_PAYMENT_TYPES_DATA
    );

    if (isMounted.current) {
      await new Promise(() => {
        return setPaymentTypes(apiRepsonse.data);
      }).then(() => setLoading(false));
    }
  };

  const getOrderData = async (/*refresh = false*/) => {
    setLoading(true);

    let [allOrders, sendOrders, processingOrders] = await Promise.all([
      initApi().get(fetchAllOrders),
      initApi().get(fetchSendOrders),
      initApi().get(fetchProcessingOrders),
    ]);

    if (isMounted.current) {
      await Promise.all([
        setTotalOrders(allOrders.data.meta.pagination.total),
        setTotalProcessingOrders(processingOrders.data.meta.pagination.total),
        setTotalSentOrders(sendOrders.data.meta.pagination.total),
      ]).then(() => setLoading(false));
    }
  };

  const updateFilterValue = (value, field) => {
    setFilterActive(false);
    setFilter({
      ...filter,
      [field]: value,
    });
  };

  const searchInput = (event) => {
    const searchQuery = event.target.value;
    if (searchQuery.length > 3) {
      setSearch(searchQuery);
    } else {
      setSearch('');
    }
  };

  const DateTimeButton = forwardRef(({ value, onClick }, ref) => (
    <Button variant={'theme-date-time'} onClick={onClick} ref={ref}>
      {' '}
      {value} <CiCalendar size={18} cursor={'pointer'} />
    </Button>
  ));

  const TabComponent = () => {
    if (isMounted.current && !loading) {
      if (tab === 0) {
        return (
          <All
            admin={admin}
            search={search}
            filter={filter}
            filterActive={filterActive}
            isMounted={isMounted}
          />
        );
      } else if (tab === 1) {
        return (
          <Processing
            admin={admin}
            search={search}
            filter={filter}
            filterActive={filterActive}
          />
        );
      }

      return (
        <Sent
          admin={admin}
          search={search}
          filter={filter}
          filterActive={filterActive}
        />
      );
    }

    return (
      <div
        style={{
          borderTop: '1px solid #ebedf2',
          marginTop: '10px',
          marginLeft: '10px',
          padding: '10px',
        }}
      >
        <p>Loading...</p>
      </div>
    );
  };

  return (
    <>
      <div id="kt_subheader" className="subheader py-2 py-lg-4">
        <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
          <div className="d-flex align-items-center flex-wrap mr-1">
            <div className="d-flex align-items-baseline mr-5">
              <h5 className="text-dark font-weight-bold my-2 mr-5">
                <FormattedMessage id={'MENU.ORDERS'} />
              </h5>
            </div>
          </div>
        </div>
      </div>

      <div className="kt-form kt-form--label-right">
        <Portlet className={'orders-portlet'}>
          <PortletHeader
            toolbar={
              <>
                <PortletHeaderToolbar className={'orders-toolbar'}>
                  <Tabs
                    component="div"
                    className="order-tabs"
                    TabIndicatorProps={{ style: { background: 'none' } }}
                    value={tab}
                    onChange={(_, nextTab) => {
                      setTab(nextTab);
                      localStorage.setItem(
                        `${pagePath}`,
                        JSON.stringify({ nextTab })
                      );
                    }}
                  >
                    <Tab
                      label={
                        intl.formatMessage({ id: 'ORDER.ALL' }) +
                        ` (${totalOrders})`
                      }
                    />
                    <Tab
                      label={
                        intl.formatMessage({ id: 'ORDER.PROCESSING' }) +
                        ` (${totalProcessingOrders})`
                      }
                    />
                    <Tab
                      label={
                        intl.formatMessage({ id: 'ORDER.SENT' }) +
                        ` (${totalSentOrders})`
                      }
                    />
                  </Tabs>
                </PortletHeaderToolbar>

                <PortletHeaderToolbar>
                  <FormControl>
                    <Button
                      variant={'light'}
                      ref={filterRef}
                      onClick={() => setShowFilter(!showFilter)}
                    >
                      <CiFilter
                        size={32}
                        cursor={'pointer'}
                        color={filterActive ? '#ff150f' : ''}
                      />
                    </Button>

                    <Overlay
                      target={filterRef.current}
                      show={showFilter}
                      placement={'bottom-end'}
                    >
                      <Tooltip id={'filter-tooltip'}>
                        <Form>
                          <Row className={'mb-3'}>
                            <Col xs={6}>
                              <DatePicker
                                selected={filter.start_date}
                                dateFormat={'dd-MM-yyyy'}
                                customInput={<DateTimeButton />}
                                onChange={(date) => {
                                  updateFilterValue(date, 'start_date');
                                }}
                                maxDate={new Date()}
                              />
                            </Col>
                            <Col xs={6}>
                              <DatePicker
                                selected={filter.end_date}
                                dateFormat={'dd-MM-yyyy'}
                                customInput={<DateTimeButton />}
                                onChange={(date) => {
                                  updateFilterValue(date, 'end_date');
                                }}
                                maxDate={new Date()}
                              />
                            </Col>
                          </Row>
                          <Row className={'mb-3'}>
                            <Col xs={6}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={filter.outstanding}
                                    onChange={(e) => {
                                      updateFilterValue(
                                        e.target.checked,
                                        'outstanding'
                                      );
                                    }}
                                    color={'default'}
                                  />
                                }
                                label={intl.formatMessage({
                                  id: 'ORDERS.OUTSTANDING',
                                })}
                              />
                            </Col>
                            <Col xs={6}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={filter.satisfied}
                                    onChange={(e) => {
                                      updateFilterValue(
                                        e.target.checked,
                                        'satisfied'
                                      );
                                    }}
                                    color={'default'}
                                  />
                                }
                                label={intl.formatMessage({
                                  id: 'ORDERS.SATISFIED',
                                })}
                              />
                            </Col>
                          </Row>
                          <Row className={'mb-3'}>
                            <Col md={{ span: 10, offset: 1 }}>
                              <Select
                                value={filter.payment_types}
                                isMulti
                                name={'paymentTypes'}
                                options={paymentTypes}
                                className={'basic-multi-select'}
                                classNamePrefix={'select'}
                                getOptionLabel={(item) => item.title}
                                onChange={(selectedValue) => {
                                  updateFilterValue(
                                    selectedValue,
                                    'payment_types'
                                  );
                                }}
                              />
                            </Col>
                          </Row>
                          <Row className={'mb-3'}>
                            <Col md={{ span: 10, offset: 1 }}>
                              <Slider
                                value={filter.price_range}
                                min={0}
                                step={10}
                                max={10000}
                                onChange={(event, value) => {
                                  updateFilterValue(value, 'price_range');
                                }}
                                valueLabelDisplay={'auto'}
                                valueLabelFormat={(value) => {
                                  if (value) {
                                    value = Number(value).toFixed(2);
                                  }
                                  return value
                                    ? '€ ' +
                                        value
                                          .toString()
                                          .replace('.', ',')
                                          .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
                                    : '€ 0';
                                }}
                                aria-labelledby={'range-slider'}
                              />
                            </Col>
                          </Row>
                          <Row className={'mb-3'}>
                            <Col md={{ span: 10, offset: 1 }}>
                              {!filterActive ? (
                                <button
                                  type={'button'}
                                  className={'btn btn-brand btn-bold btn-block'}
                                  onClick={(e) => {
                                    applyFilterSettings(e);
                                  }}
                                >
                                  <FormattedMessage id="GENERAL.APPLY_FILTER" />
                                </button>
                              ) : (
                                <button
                                  type={'button'}
                                  className={'btn btn-brand btn-bold btn-block'}
                                  onClick={(e) => {
                                    resetFilterSettings(e);
                                  }}
                                >
                                  <FormattedMessage id="GENERAL.RESET_FILTER" />
                                </button>
                              )}
                            </Col>
                          </Row>
                        </Form>
                      </Tooltip>
                    </Overlay>
                  </FormControl>

                  <FormControl>
                    <TextField
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <CiSearch size={20} />
                          </InputAdornment>
                        ),
                      }}
                      placeholder={
                        'Zoeken naar offerte, referentie, datum, etc.'
                      }
                      className={'orders-search-form'}
                      variant={'outlined'}
                      onChange={(e) => {
                        searchInput(e);
                      }}
                      size={'medium'}
                    />
                  </FormControl>
                </PortletHeaderToolbar>
              </>
            }
          />

          <TabComponent />
        </Portlet>
      </div>
    </>
  );
};

MasterPage.propTypes = {
  history: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired,
  getOrders: PropTypes.func.isRequired,
  deleteOrder: PropTypes.func.isRequired,
  fulfillDetailedOrder: PropTypes.func.isRequired,
  isProcessing: PropTypes.bool.isRequired,
};

const mapStateToProps = ({
  auth: {
    user: { admin },
  },
  orders: { data, pagination, isProcessing },
}) => ({
  data,
  pagination,
  isProcessing,
  admin,
});

const mapDispatchToProps = {
  getOrders: ordersActions.getOrdersRequest,
  deleteOrder: ordersActions.deleteOrderRequest,
  fulfillDetailedOrder: ordersActions.fulfillDetailedOrder,
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withLastLocation(withRouter(MasterPage)))
);
