import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Grid } from '@material-ui/core';
import Multiselect from 'multiselect-react-dropdown';

import { API_URL, API_URLS } from '../../../common/constants/api/apiConstants';
import initApi from '../../../crud/apiConfig/apiConfig';

const MaterialListViewComponent = ({ data, materialOptions, onChange }) => {
  const [selectedMaterial, setSelectedMaterial] = useState([]);
  const [percentage, setPercentage] = useState();

  const [materialsPayload, setMaterialsPayload] = useState(data);

  const calculateDiscount = (price, discount) => {
    const parsedOriginalValue = parseFloat(price);
    const parsedPercentage = parseFloat(discount);

    const calculatedValue =
      parsedOriginalValue - (parsedOriginalValue * parsedPercentage) / 100;

    return calculatedValue;
  };

  useEffect(() => {
    onChange(materialsPayload);
  }, [percentage]);

  useEffect(() => {
    setMaterialsPayload(data);
  }, [data]);

  return (
    data &&
    data.map((el, index) => {
      let materialItem = materialsPayload.find((item) => item.id === el.id);
      return (
        <Grid item xs={11} container alignItems="center" key={index}>
          <div className="col-md-6 mb-3">
            <label htmlFor="materialPrice">
              <FormattedMessage id={'Material'} />
            </label>
            <Multiselect
              options={materialOptions}
              selectedValues={false}
              singleSelect={true}
              avoidHighlightFirstOption={true}
              onSelect={(item) => {
                setSelectedMaterial([...selectedMaterial, item[0]]);

                materialItem.id = item[0].key;
                materialItem.material_id = item[0].key;
                materialItem.original_price = item[0].price;
              }}
              displayValue={'label'}
            />
          </div>
          <div className="col-md-2 mb-3">
            <label htmlFor="materialPrice">
              <FormattedMessage id={'Original Price'} />
            </label>
            <input
              type="text"
              className="form-control"
              id="materialPrice"
              key="materialPrice"
              value={materialItem?.original_price}
              disabled
            />
          </div>

          <div className="col-md-2 mb-3">
            <label htmlFor="percentage">
              <FormattedMessage id={'Discount (%)'} />
            </label>
            <input
              type="text"
              className="form-control"
              id="percentage"
              key="percentage"
              value={materialItem?.discount || ''}
              onChange={(e) => {
                setPercentage(e.target.value);

                materialItem.discount = e.target.value;
                materialItem.price = calculateDiscount(
                  materialItem.original_price,
                  materialItem.discount
                );
              }}
            />
          </div>

          <div className="col-md-2 mb-3">
            <label htmlFor="new-value">
              <FormattedMessage id={'New Price'} />{' '}
            </label>
            <input
              type="text"
              className="form-control"
              id="new-value"
              key="new-value"
              defaultValue={materialItem?.price || ''}
            />
          </div>
        </Grid>
      );
    })
  );
};

const MaterialsDiscountCmpnt = ({ data, onChange }) => {
  const isMounted = useRef(false);
  const [materialGroups, setMaterialGroups] = useState([]);

  const [materialPriceValues, setMaterialPriceValues] = useState([
    {
      id: '',
      material_id: '',
      original_price: '',
      discount: '',
      price: '',
    },
  ]);

  const getMaterialsData = async () => {
    let { data } = await initApi().get(API_URL + API_URLS.GET_MATERIALS_DATA);
    let materialOptions = [];

    if (data) {
      data.map((item) => {
        materialOptions.push({
          key: item.id,
          label: `${item.name} / ${item.color} / ${item.thickness} - ${item.price}`,
          price: item.price,
        });
      });
    }

    if (isMounted.current) {
      setMaterialGroups(materialOptions);
    }
  };

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      getMaterialsData();
    }
  }, [isMounted]);

  const addMaterial = () => {
    setMaterialPriceValues([
      ...materialPriceValues,
      {
        id: '',
        material_id: '',
        original_price: '',
        discount: '',
        price: '',
      },
    ]);
  };

  return (
    <>
      <Grid
        container
        item
        xs={12}
        justifyContent="space-between"
        alignItems="center"
        className="materials-discount-item"
      >
        <MaterialListViewComponent
          data={materialPriceValues}
          materialOptions={materialGroups}
          onChange={onChange}
        />

        <div className="button-plus" onClick={addMaterial}></div>
      </Grid>

      {data?.length
        ? data?.map((item) => {
            let materialItem = materialGroups.find(
              (material) => material.key === item?.material_id
            );

            return (
              <Grid
                container
                alignItems="center"
                xs={12}
                className="mb-3"
                key={item?.material_id}
              >
                <div className="col-md-5 mb-3">
                  <label htmlFor="materialName">
                    <FormattedMessage id={'Material'} />
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="materialName"
                    key="materialName"
                    value={materialItem?.label}
                    disabled
                  />
                </div>

                <div className="col-md-3 mb-3">
                  <label htmlFor="discount">
                    <FormattedMessage id={'Discount (%)'} />
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="discount"
                    key="discount"
                    value={item?.discount}
                    disabled
                  />
                </div>

                <div className="col-md-3 mb-3">
                  <label htmlFor="new-value">
                    <FormattedMessage id={'New Price'} />{' '}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="new-value"
                    key="new-value"
                    value={item?.price}
                    disabled
                  />
                </div>
              </Grid>
            );
          })
        : ''}
    </>
  );
};

const mapStateToProps = ({ users: { detailedUser } }) => ({
  data: detailedUser.data?.price_setting?.price_setting_materials,
});

export default connect(mapStateToProps)(MaterialsDiscountCmpnt);
