import React from 'react';
import { Route, Switch } from 'react-router-dom';
import CheckStatusPage from './CheckStatusPage';


export default function CheckStatus () {
  return (
    <Switch>
      <Route
        path="/check-status"
        component={CheckStatusPage}
      />
    </Switch>
  );
}
