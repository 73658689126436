import React from 'react';
import PropTypes from 'prop-types';
import { Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { authActions } from '../store/ducks/auth.duck';
import { ROUTES } from '../common/constants/routes/routes';
import { getStorage } from '../../_metronic';

class PrivateRoute extends React.Component {
  componentDidMount() {
    const { user, checkAuth, history } = this.props;
    const accessToken = getStorage('token');
    //console.log(JSON.parse(atob(accessToken.split(".")[1])))

    if (accessToken && !user) {
      const now = new Date();
      const tokenDetails = JSON.parse(atob(accessToken.split('.')[1]));
      const expiration = new Date(tokenDetails.exp * 1000);

      if (expiration < now) {
        // removeStorage('token');
        history.push(ROUTES.AUTH_LOGIN);
      } else {
        checkAuth(history);
        return;
      }
    }

    if (!accessToken && !this.checkIfOnLoginPages()) {
      history.push(ROUTES.AUTH_LOGIN);
    }
  }

  checkIfOnLoginPages = () => {
    let pathname = this.props.computedMatch.path;
    const { location } = this.props;
    const routesEntries = Object.values(ROUTES).slice(10, 38);

    return (
      location.pathname === ROUTES.AUTH ||
      location.pathname === ROUTES.AUTH_LOGIN ||
      pathname === ROUTES.AUTH_LOGIN_LANG ||
      pathname === ROUTES.AUTH_REGISTRATION_LANG ||
      location.pathname === ROUTES.AUTH_REGISTRATION ||
      location.pathname === ROUTES.AUTH_FORGOT_PASS ||
      location.pathname === ROUTES.AUTH_RESET_PASS ||
      location.pathname === ROUTES.AUTH_RESET_PASS_WITH_PARAMS ||
      pathname === ROUTES.AUTH_RESET_PASS_WITH_PARAMS ||
      location.pathname.includes('materials') ||
      routesEntries.find((route) => {
        if (typeof route === 'object' && route !== null) {
          return Object.values(route).find((item) => {
            return location.pathname === item || pathname === item;
          });
        } else {
          return location.pathname === route || pathname === route;
        }
      }) ||
      location.pathname.includes('checkout')
    );
  };

  render() {
    const { user, isPending } = this.props;
    if (!user) {
      if (!this.checkIfOnLoginPages()) {
        return null;
      }
    }

    return isPending ? <>Loading...</> : <Route {...this.props} />;
  }
}

PrivateRoute.propTypes = {
  user: PropTypes.object,
  isPending: PropTypes.bool.isRequired,
  checkAuth: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const withRouterRoute = withRouter(PrivateRoute);

const mapStateToProps = ({ i18n, auth: { user, isPending } }) => ({
  lang: i18n.lang,
  user,
  isPending,
});

const mapDispatchToprops = {
  checkAuth: authActions.checkAuth,
};

export default connect(mapStateToProps, mapDispatchToprops)(withRouterRoute);
