import React, { useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { TextField } from '@material-ui/core';
import clsx from 'clsx';
import { authActions } from '../../store/ducks/auth.duck';
import MetaTags from '../../components/MetaTags';

function Login(props) {
  const { intl, login, deleteError, errorMessage, history } = props;

  useEffect(() => {
    if (errorMessage) {
      deleteError();
    };
  }, [errorMessage])

  const onSubmit = (values, { setSubmitting }) => {
    const { email, password } = values;

    login(email, password, setSubmitting, history);
  };

  const validate = (values) => {
    const errors = {};

    if (!values.email) {
      // https://github.com/formatjs/react-intl/blob/master/docs/API.md#injection-api
      errors.email = intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD',
      });
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
    ) {
      errors.email = intl.formatMessage({
        id: 'AUTH.VALIDATION.INVALID_FIELD',
      });
    }

    if (!values.password) {
      errors.password = intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD',
      });
    }

    return errors;
  };

  return (
    <>
      <MetaTags
        title={intl.formatMessage({ id: 'AUTH.LOGIN.META.TITLE' })}
        description={intl.formatMessage({ id: 'AUTH.LOGIN.META.DESC' })}
        keywords={intl.formatMessage({ id: 'AUTH.LOGIN.META.KEYWORDS' })}
      />

      <h3 className={'login-left-center-title'}>
        <FormattedMessage id="AUTH.LOGIN.TITLE" />
      </h3>

      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        validate={validate}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form
            noValidate={true}
            autoComplete="off"
            className="kt-form"
            onSubmit={handleSubmit}
          >
            {errorMessage && (
              <div role="alert" className="alert alert-danger">
                <div className="alert-text">{errorMessage}</div>
              </div>
            )}

            <div className="form-group">
              <TextField
                variant="outlined"
                type="email"
                label="Email"
                margin="normal"
                className="kt-width-full"
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                helperText={touched.email && errors.email}
                error={Boolean(touched.email && errors.email)}
              />
            </div>

            <div className="form-group">
              <TextField
                variant="outlined"
                type="password"
                margin="normal"
                label="Password"
                className=""
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.password}
                helperText={touched.password && errors.password}
                error={Boolean(touched.password && errors.password)}
              />
            </div>

            <div className="login-left-center-actions">
              <button
                id="login-left-center-actions-primary"
                type="submit"
                disabled={isSubmitting}
                className={`login-left-center-actions-primary ${clsx({
                  'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light':
                    props.data,
                })}`}
                style={{paddingRight: '2.5rem',}}
              >
                <FormattedMessage id="AUTH.LOGIN.BUTTON" />
              </button>
              <Link
                to="/auth/forgot-password"
                className="login-left-center-actions-secondary"
              >
                <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
              </Link>
              <span className="kt-login__signup-label">
                <FormattedMessage id="AUTH.GENERAL.NO_ACCOUNT" />
              </span>
              &nbsp;&nbsp;
              <Link
                to="/auth/registration"
                className="kt-link kt-login__signup-link"
              >
                <FormattedMessage id="AUTH.GENERAL.SIGNUP_BUTTON" />!
              </Link>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}

const mapStateToProps = (state, { history }) => ({
  data: state.auth.isPending,
  errorMessage: state.auth.error,
  history,
});

const mapDispatchToProps = {
  login: authActions.login,
  deleteError: authActions.deleteError,
};

const withRouterLogin = withRouter(Login);

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(withRouterLogin)
);
