import React, { useEffect, useState } from 'react';
import {
  Col,
  Container,
  Image,
  OverlayTrigger,
  Popover,
  PopoverContent,
  PopoverTitle,
  Row,
  Table,
} from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { BiInfoCircle } from 'react-icons/bi';
import { API_URL } from '../../../../common/constants/api/apiConstants';
import { getToleranceTableValue } from '../../../../../_metronic/utils/utils';
import CustomToolTip from '../../../../components/CustomTooltip';
import { TableHead } from '@material-ui/core';
import { areAllFieldsValid } from '../../../../../_metronic/utils/utils';

export const Overview = ({
  projectId,
  products,
  vat,
  price,
  discount,
  shipping_costs,
  startup_costs,
  shipping_type,
  shippingAddress,
  onChangeFoil,
  totalProductPrice,
  onChangeQuantity,
}) => {
  const intl = useIntl();
  const [isCalculating, setIsCalculating] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);
  const excludedFields = ['company_name', 'tax_number', 'tax_number_valid'];

  useEffect(() => {
    if (products) {
      setIsCalculating(false);
    }
  }, [products]);

  useEffect(() => {
    if (areAllFieldsValid(shippingAddress, excludedFields)) {
      setIsDisabled(false);
    }
  }, [shippingAddress]);

  const getColorName = (item) => {
    let color_name = item.color;
    if (intl.locale == 'en' && item.color_en != '') {
      color_name = item.color_en;
    }

    if (intl.locale == 'de' && item.color_de != '') {
      color_name = item.color_de;
    }

    return color_name;
  };

  const changeValue = (item, actionType) => {
    let quantity = item.quantity;
    if (quantity != 1 && actionType == 'subtract') {
      quantity--;
    }
    if (actionType == 'add') {
      quantity++;
    }

    onChangeQuantity(item.id, quantity);
  };

  const onKeyDownQuantity = (event) => {
    if (
      !(
        (event.key >= '0' && event.key <= '9' && event.key !== ' ') ||
        (event.key >= 'NumPad0' && event.key <= 'NumPad9') ||
        event.key === 'Backspace'
      )
    ) {
      return false;
    }
  };

  const getProductOverlay = (item) => {
    return (
      item && (
        <Popover
          id={`popover-positioned-${item.id}`}
          className={'product-overlay'}
          style={{ maxWidth: '500px' }}
        >
          <PopoverTitle as={'h3'}>{item.name}</PopoverTitle>
          <PopoverContent>
            <p className={'text-muted'}>
              <FormattedMessage id={'ORDER.MEASUREMENTS'} />:{' '}
              {item.width.toFixed(1)} mm x {item.height.toFixed(1)} mm
            </p>
            <p className={'text-muted'}>
              {item.material?.name
                ? intl.formatMessage({ id: item.material?.name })
                : ''}{' '}
              / {item.material?.name ? getColorName(item.material) : ''} /{' '}
              {item.material?.thickness} mm / {item.weight.toFixed(1)} kg
            </p>
            {item?.width >= item?.height && (
              <p className={'text-muted'}>
                <FormattedMessage id={'CONFIGURATOR.TOLERANCE'} />
                +/-&nbsp;{getToleranceTableValue(parseFloat(item?.width))} mm
              </p>
            )}

            {item?.height > item?.width && (
              <p className={'text-muted'}>
                <FormattedMessage id={'CONFIGURATOR.TOLERANCE'} />
                +/-&nbsp;{getToleranceTableValue(parseFloat(item?.height))} mm
              </p>
            )}
            {item?.bendings && item?.bendings[0]?.direction === 'vertical' && (
              <p className={'text-muted'}>Vertical Bending</p>
            )}
            {item?.bendings &&
              item?.bendings[0]?.direction === 'horizontal' && (
                <p className={'text-muted'}>Horizontal Bending</p>
              )}
          </PopoverContent>
        </Popover>
      )
    );
  };

  return isCalculating ? (
    intl.formatMessage({ id: 'CHECKOUT.CALCULATING' })
  ) : (
    <overview-partial>
      <Row>
        <Col as={'h6'} className={'font-weight-bold text-left'}>
          <FormattedMessage id={'CHECKOUT.OVERVIEW'} />
        </Col>
        <Col as={'h6'} className={'text-right text-underline'}>
          <Link to={`/configurator/${projectId}`}>
            <FormattedMessage id={'CHECKOUT.EDIT_ORDER'} />
          </Link>
        </Col>
      </Row>
      <Container>
        <div>
          <table>
            <TableHead>
              <tr>
                <th className={'col-7'}></th>
                <th className={'col-2 text-center'}>
                  <FormattedMessage id={'CHECKOUT.QTY'} />
                </th>
                <th className={'col-1 text-right'}>
                  <FormattedMessage id={'CHECKOUT.PRICE'} />
                </th>
                <th className={'col-2 text-right'}>
                  <FormattedMessage id={'CHECKOUT.TOTAL'} />
                </th>
              </tr>
            </TableHead>
            {products &&
              products.map((item, index) => {
                return (
                  item && (
                    <tbody className={'product-row'} key={`product-${index}`}>
                      <tr
                        style={{
                          borderTop: '1px solid #eaedf2',
                          height: '135px',
                        }}
                        key={`product-${item.id}`}
                      >
                        <td
                          style={{ display: 'flex', padding: '30px 0.5vw 0' }}
                        >
                          <Col className={'checkout-col col-5'}>
                            <div className={'checkout-image-container'}>
                              <Image
                                src={`${API_URL}/projects${item.svg}`}
                                className={'checkout-image'}
                              />
                            </div>
                          </Col>
                          <Col className={'checkout-col col-7'}>
                            <OverlayTrigger
                              trigger={'hover'}
                              key={`popover-positioned-${item.id}`}
                              placement={'top'}
                              overlay={getProductOverlay(item)}
                            >
                              <overlay-area>
                                <h6
                                  className={
                                    'checkout-product-title text-truncate'
                                  }
                                  style={{ maxWidth: '250px' }}
                                >
                                  {item.name}
                                </h6>

                                <small className={'text-muted'}>
                                  {item.width.toFixed(1)} mm x{' '}
                                  {item.height.toFixed(1)} mm
                                </small>
                                <br />
                                <small className={'text-muted'}>
                                  {item.material?.name
                                    ? intl.formatMessage({
                                        id: item.material?.name,
                                      })
                                    : ''}{' '}
                                  /{' '}
                                  {item.material?.name
                                    ? getColorName(item.material)
                                    : ''}{' '}
                                  / {item.material?.thickness} mm /{' '}
                                  {item.weight.toFixed(1)} kg /{' '}
                                </small>
                                {item?.bendings &&
                                  item?.bendings[0]?.direction ===
                                    'vertical' && (
                                    <small style={{ fontWeight: 600 }}>
                                      <FormattedMessage
                                        id={'BENDING.VERTICAL'}
                                      />{' '}
                                      /{' '}
                                    </small>
                                  )}

                                {item?.bendings &&
                                  item?.bendings[0]?.direction ===
                                    'horizontal' && (
                                    <small style={{ fontWeight: 600 }}>
                                      <FormattedMessage
                                        id={'BENDING.HORIZONTAL'}
                                      />{' '}
                                      /{' '}
                                    </small>
                                  )}

                                {item?.width >= item?.height && (
                                  <small className={'text-muted'}>
                                    <FormattedMessage
                                      id={'CONFIGURATOR.TOLERANCE'}
                                    />
                                    +/-&nbsp;
                                    {getToleranceTableValue(
                                      parseFloat(item?.width)
                                    )}{' '}
                                    mm
                                  </small>
                                )}

                                {item?.height > item?.width && (
                                  <small className={'text-muted'}>
                                    <FormattedMessage
                                      id={'CONFIGURATOR.TOLERANCE'}
                                    />
                                    +/-&nbsp;
                                    {getToleranceTableValue(
                                      parseFloat(item?.height)
                                    )}{' '}
                                    mm
                                  </small>
                                )}
                              </overlay-area>
                            </OverlayTrigger>
                            <br />
                            {item.material && item.material.foil_removal && (
                              <label className={'checkbox'}>
                                <small
                                  className={'text-muted'}
                                  style={{
                                    marginTop: '6px',
                                    position: 'relative',
                                    display: 'flex',
                                    alignItems: 'flex-start',
                                  }}
                                >
                                  <input
                                    className={'foil-removal-checkbox'}
                                    type={'checkbox'}
                                    name={'foil_removal'}
                                    onChange={(e) => {
                                      onChangeFoil(e, item);
                                    }}
                                  />
                                  <span
                                    style={{
                                      display: 'inline-block',
                                      fontSize: '11px',
                                      lineHeight: '1.2',
                                    }}
                                  >
                                    <FormattedMessage
                                      id={'CHECKOUT.FOIL_REMOVAL'}
                                    />
                                    <CustomToolTip
                                      id="foil-removal-info"
                                      effect="solid"
                                      position="top"
                                      tooltipText={
                                        <FormattedMessage
                                          id={'CHECKOUT.FOIL_REMOVAL_TOOLTIP'}
                                        />
                                      }
                                    >
                                      <button
                                        data-tip
                                        data-for="foil-removal-info"
                                        style={{
                                          border: 'none',
                                          background: 'transparent',
                                          padding: '0 4px',
                                        }}
                                        className={`btn text-center btn-md`}
                                        onClick={() => {}}
                                      >
                                        <BiInfoCircle
                                          size={18}
                                          cursor={'pointer'}
                                          color={'#b6b6b6'}
                                        />
                                      </button>
                                    </CustomToolTip>
                                  </span>
                                </small>
                              </label>
                            )}
                          </Col>
                        </td>
                        <td>
                          <div className={'col-md-3 col-lg-3 col-xl-2 d-flex'}>
                            <button
                              disabled={isDisabled}
                              className={'btn btn-sm btn-quantity'}
                              onClick={() => {
                                changeValue(item, 'subtract');
                              }}
                            >
                              <i className={'fas fa-minus'}></i>
                            </button>

                            <input
                              min="1"
                              name={'quantity'}
                              value={item.quantity}
                              type={'number'}
                              className={'form-control-sm quantity-input'}
                              onKeyDown={(e) => {
                                onKeyDownQuantity(e);
                              }}
                              onChange={(e) => {
                                onChangeQuantity(item.id, e.target.value);
                              }}
                            />

                            <button
                              disabled={isDisabled}
                              className={'btn btn-sm btn-quantity'}
                              onClick={() => {
                                changeValue(item, 'add');
                              }}
                            >
                              <i className={'fas fa-plus'}></i>
                            </button>
                          </div>
                        </td>
                        <td>
                          <div className={'pt-2 text-right text-nowrap'}>
                            <span>
                              € {(item.price / item.quantity).toFixed(2)}
                            </span>
                          </div>
                        </td>
                        <td>
                          <div
                            className={'pt-2 text-right text-nowrap text-price'}
                          >
                            <span>€ {item.price.toFixed(2)}</span>
                          </div>
                        </td>
                      </tr>
                      {item?.material?.has_to_be_milled && (
                        <tr
                          style={{
                            borderBottom: '1px solid #eaedf2',
                            height: '80px',
                          }}
                          key={`product2-${item.id}`}
                        >
                          <td colSpan={4} style={{ border: 0, paddingTop: 0 }}>
                            <div className="checkout-warning  fade show w-100">
                              <small>
                                <FormattedMessage id={'PRODUCT_MILLED'} />
                              </small>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  )
                );
              })}
            <tbody>
              <tr>
                <td colSpan={4}>
                  <Table style={{ width: '75%', marginLeft: 'auto' }}>
                    <tbody className={'checkout-totals'}>
                      <tr>
                        <td className={'text-left font-weight-bold'}>
                          <FormattedMessage id={'CHECKOUT.SUBTOTAL'} />
                        </td>
                        <td className={'text-right'}>
                          € {totalProductPrice.toFixed(2)}
                        </td>
                      </tr>
                      <tr>
                        <td className={'text-left font-weight-bold'}>
                          <FormattedMessage id={'CHECKOUT.STARTUP_COSTS'} />
                          {startup_costs ? (
                            <span style={{ fontSize: '14px' }}>
                              &nbsp;(&lt; € 200)
                            </span>
                          ) : null}
                        </td>
                        <td className={'text-right'}>
                          € {startup_costs ? startup_costs.toFixed(2) : '-'}
                        </td>
                      </tr>

                      {shipping_type === 'ship' && (
                        <tr>
                          <td className={'text-left font-weight-bold'}>
                            <FormattedMessage id={'CHECKOUT.SHIPPING'} />
                          </td>
                          <td className={'text-right'}>
                            €{' '}
                            {shipping_costs && shipping_costs !== 0
                              ? shipping_costs.toFixed(2)
                              : '-'}
                          </td>
                        </tr>
                      )}

                      {shipping_type === 'pickup' && (
                        <tr>
                          <td className={'text-left font-weight-bold'}>
                            <FormattedMessage id={'CHECKOUT.PICKUP'} />
                          </td>
                          <td className={'text-right'}>€ -</td>
                        </tr>
                      )}

                      {discount > 0 && (
                        <tr>
                          <td className={'text-left font-weight-bold'}>
                            <FormattedMessage id={'ORDER.DISCOUNT'} />
                          </td>
                          <td className={'text-right text-success'}>
                            - € {discount.toFixed(2)}
                          </td>
                        </tr>
                      )}
                      <tr className={'price-divider'}>
                        <td className={'text-left font-weight-bold'}>
                          <FormattedMessage id={'CHECKOUT.TOTAL'} /> (EUR){' '}
                          <FormattedMessage id={'CHECKOUT.EXCL'} />{' '}
                          <FormattedMessage id={'CHECKOUT.VAT'} />
                        </td>
                        <td className={'text-right font-weight-bold'}>
                          € {price.toFixed(2)}
                        </td>
                      </tr>
                      <tr>
                        <td className={'text-left font-weight-bold'}>
                          <FormattedMessage id={'CHECKOUT.VAT'} />
                        </td>
                        <td className={'text-right'}>
                          € {vat ? vat.toFixed(2) : '-'}
                        </td>
                      </tr>
                      <tr>
                        <td
                          className={'text-left text-success font-weight-bold'}
                        >
                          <FormattedMessage id={'CHECKOUT.TOTAL'} /> (EUR){' '}
                          <FormattedMessage id={'CHECKOUT.INCL'} />{' '}
                          <FormattedMessage id={'CHECKOUT.VAT'} />
                        </td>
                        <td
                          className={'text-right text-success font-weight-bold'}
                        >
                          € {(price + vat).toFixed(2)}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Container>
    </overview-partial>
  );
};
