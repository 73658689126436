import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment';
import 'moment/locale/nl';
import 'moment/locale/de';
import 'moment/locale/en-gb';

export const Delivery = (props) => {
  const {
    delivery_days,
    deliveryDays,
    country,
    products,
    changeDeliveryDays,
    onChangeShippingType,
    getShippingPrice,
  } = props;

  const intl = useIntl();
  const [isActive, setIsActive] = useState(null);
  const [deliveryList, setDeliveryList] = useState([]);
  const [pickup, setPickup] = useState(false);

  useEffect(() => {
    setIsActive(delivery_days);
  }, [delivery_days]);

  let currentDeliveryItem = deliveryList.find((item) => {
    let extraProductionTime = 0;

    products.forEach((product) => {
      extraProductionTime +=
        (product.length * product.quantity) / product?.material?.cutting_speed;
    });

    extraProductionTime = extraProductionTime / 60 / 60 / 30;

    // let width = Math.ceil(12 / deliveryDays.length);
    let currentProductionTime = Math.ceil(
      item.production_time + extraProductionTime + 2
    );

    let showDays = item.days_with_production_time;

    if (currentProductionTime > item.block_orders_amount) {
      showDays =
        item.block_orders_amount > 150 ? 150 : item.block_orders_amount;
    }
    if (isActive == showDays) {
      return true;
    }
    return false;
  });

  useEffect(() => {
    if (deliveryDays && products) {
      const filteredDeliveryDays = deliveryDays.filter((item) => {
        return item.countries.some((element) => {
          let currentCountry = country;
          if (!currentCountry) {
            currentCountry = { id: 1 };
          }
          if (element?.id === parseInt(currentCountry?.id)) {
            return true;
          }
        });
      });

      setDeliveryList(filteredDeliveryDays);
      setIsActive(delivery_days);

      if (!isActive) {
        setIsActive(14);
      }
    }
  }, [deliveryDays, products]);

  const formatDeliveryDay = (days) => {
    let now = new Date();
    let deliveryDay = new Date();
    if (now.getHours() >= 17) {
      deliveryDay.setDate(deliveryDay.getDate() + 1);
    }
    let countAll = 0;
    for (let count = 0; countAll != days; count++) {
      deliveryDay.setDate(deliveryDay.getDate() + 1);
      if (deliveryDay.getDay() != 0 && deliveryDay.getDay() != 6) {
        countAll++;
      }
    }

    const date = moment(deliveryDay);
    return date.locale(intl.locale).format('dddd D MMM');
  };

  const updateDelivery = (item, productionTime) => {
    setIsActive(item.days);
    changeDeliveryDays(item, productionTime);
  };

  const updateShippingType = (status) => {
    setPickup(!status);
    if (status == false) {
      onChangeShippingType('pickup');
    }
    if (status == true) {
      onChangeShippingType('ship');
    }
  };

  return (
    <delivery-partial>
      <Row>
        <Col as={'h6'} className={'font-weight-bold'}>
          <FormattedMessage id={'CHECKOUT.DELIVERY'} />
        </Col>
      </Row>
      <Container className={'mt-4 mb-3'}>
        <Row className={'delivery-list'}>
          {deliveryList &&
            deliveryList.map((item) => {
              let extraProductionTime = 0;

              products.forEach((product) => {
                extraProductionTime +=
                  (product.length * product.quantity) /
                  product?.material?.cutting_speed;
              });

              extraProductionTime = extraProductionTime / 60 / 60 / 30;

              // let width = Math.ceil(12 / deliveryDays.length);
              let currentProductionTime = Math.ceil(
                item.production_time + extraProductionTime + 2
              );

              let showDays = item.days_with_production_time;

              if (
                currentProductionTime >
                (item.block_orders_amount > 150
                  ? 150
                  : item.block_orders_amount)
              ) {
                showDays =
                  item.block_orders_amount > 150
                    ? 150
                    : item.block_orders_amount;
              }
              let priceDifference =
                item?.price - (currentDeliveryItem?.price ?? 0);
              if (priceDifference < 0) {
                priceDifference = '- € ' + Math.abs(priceDifference).toFixed(2);
              } else {
                priceDifference = '+ € ' + Math.abs(priceDifference).toFixed(2);
              }

              return (
                <Col
                  className={
                    (isActive == showDays
                      ? 'delivery-item text-center p-4 active '
                      : 'delivery-item text-center p-4 ') +
                    ((currentProductionTime > item.block_orders_amount ||
                      item.is_blocked) &&
                      'delivery-item-blocked')
                  }
                  key={showDays}
                  onClick={() => {
                    (!(currentProductionTime > item.block_orders_amount) ||
                      !item.is_blocked) &&
                      updateDelivery(item, currentProductionTime - 2);
                  }}
                >
                  <h6 className={'font-weight-bold'}>
                    {showDays} <FormattedMessage id={'GENERAL.DAYS'} />
                  </h6>
                  {currentProductionTime > item.block_orders_amount ||
                  item.is_blocked ? (
                    <span>
                      <FormattedMessage id={'Unavailable'} />
                    </span>
                  ) : (
                    <>
                      <span>{formatDeliveryDay(showDays)}</span>
                      <span className={' price-difference text-success'}>
                        {priceDifference}
                      </span>
                    </>
                  )}
                </Col>
              );
            })}
        </Row>
        <Row className={'delivery-list mt-3'}>
          <Col
            className={
              pickup == true
                ? 'pickup-item text-center p-4 active'
                : 'pickup-item text-center p-4'
            }
            key={0}
            onClick={() => {
              updateShippingType(pickup);
            }}
          >
            <h6 className={'font-weight-bold'}>
              <FormattedMessage id={'CHECKOUT.FREE_PICKUP'} />
            </h6>
            <span className={'text-success'}>- € {getShippingPrice()}</span>
          </Col>
        </Row>
      </Container>
    </delivery-partial>
  );
};
