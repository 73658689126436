import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import { Divider } from '@material-ui/core';

import { API_URLS } from '../../../common/constants/api/apiConstants';
import initApi from '../../../crud/apiConfig/apiConfig';
import HeaderDropdownToggle from '../../content/CustomDropdowns/HeaderDropdownToggle';
import { metronic } from '../../../../_metronic';
import AdminNotification from './components/AdminNotification';
import Footer from './components/Footer';
import { INotification } from './styles';

const Notifications = ({ lang, notifications, user }) => {

  const [msgData, setMsgData] = useState();
  const [isMsgUp, setIsMsgUp] = useState(false);
  const [bellClicked, setBellClicked] = useState(
    localStorage.getItem('bellClicked') || null
  );

  useEffect(() => {
    setTimeout(() => {
      user && getMessages();
    }, 1000)

  }, [user]);

  useEffect(() => {
    msgData &&
      msgData.map((msg) => {
        if (
          localStorage.getItem('bellClicked') -
            new Date(msg.updated_at * 1000).getTime() <
          4 * 86400000
        ) {
          setIsMsgUp(true);
        } else {
          setIsMsgUp(false);
        }
      });
  }, [msgData]);

  useEffect(() => {
    setBellClicked(localStorage.getItem('bellClicked'));
  }, [bellClicked]);

  const handleClickBell = () => {
    setBellClicked(localStorage.setItem('bellClicked', Date.now()));
    setIsMsgUp(false);
  };

  const getMessages = async () => {
    let { data } = await initApi().get(API_URLS.MESSAGES);

    setMsgData(data && data.messages);
  };

  return (
    <Dropdown
      className="kt-header__topbar-item kt-header__topbar-item--langs"
      drop="down"
      alignRight
    >
      <Dropdown.Toggle as={HeaderDropdownToggle} id="dropdown-toggle-my-cart">
        <INotification
          onClick={handleClickBell}
          className={isMsgUp && 'breathe-animate'}
        >
          <i className="flaticon2-notification font-size-6" />
        </INotification>
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround  notification-dropdown">
        <div className="notification-dropdown-menu">
          {notifications.map((notif) => (
            <div key={notif.updated_at}>
              <AdminNotification
                bellClicked={bellClicked}
                lang={lang}
                {...notif}
              />
              <Divider />
            </div>
          ))}
        </div>
        <Divider />
        <Footer />
      </Dropdown.Menu>
    </Dropdown>
  );
};

const mapStateToProps = ({ i18n, messages: { notifications } ,  auth: { user }}) => ({
  lang: i18n.lang,
  user,
  notifications,
});

export default connect(mapStateToProps, metronic.i18n.actions)(Notifications);
