import axios from 'axios';
import { getStorage } from '../../../_metronic/utils/utils';
import { API_URL } from '../../common/constants/api/apiConstants';

const initApi = (setAccessHeader = true, /* setRefreshToken = false */) => {
  if (setAccessHeader) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${getStorage(
      'token'
    )}`;
  }

  axios.defaults.maxContentLength = Infinity;
  axios.defaults.maxBodyLength = Infinity;

  axios.defaults.baseURL = API_URL;

  return axios;
};

export default initApi;
