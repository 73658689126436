import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import TermsAndConditionsPage from './TermsAndConditionsPage';


export default function Pages () {
  return (
    <Switch>
      {/* Layout */}
      <Redirect
        exact
        from="/pages"
        to="/pages/terms-and-conditions"
      />
      <Route
        path="/pages/terms-and-conditions"
        component={TermsAndConditionsPage}
      />


    </Switch>
  );
}
