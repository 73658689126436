import React, { useRef } from "react";
import PropTypes from "prop-types";

import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { FormLayout } from "../../../../components/FormPageLayout/Layouts";
import Form from "./Form";
import { setMessage } from "../../../../store/messages/actions";
import { ROUTES } from "../../../../common/constants/routes/routes";

const CreateFormPage = ({ isProcessing }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const formRef = useRef({
    validateForm: () => {},
    values: {},
  });

  const handleSubmit = async () => {
    const { values, validateForm } = formRef.current;
    await validateForm();
    if (!formRef.current.isValid || !values) return;
    dispatch(
      setMessage({
        data: values,
        cb: ({ status }) => {
          if (status === "error") return;
          history.push(ROUTES.ADMIN_UPDATES);
        },
      })
    );
  };

  return isProcessing ? (
    "Loading..."
  ) : (
    <FormLayout
      onClose={() => history.push(ROUTES.ADMIN_UPDATES)}
      title="News Add"
      onSubmit={handleSubmit}
    >
      <Form ref={formRef} />
    </FormLayout>
  );
};

CreateFormPage.propTypes = {
  isProcessing: PropTypes.bool.isRequired,
  data: PropTypes.object,
};

const mapStateToProps = ({
  messages: {
    detail: { data, isProcessing },
  },
}) => ({
  data,
  isProcessing,
});

export default connect(mapStateToProps)(CreateFormPage);
