export const API_URL = process.env.REACT_APP_API_URL || 'http://127.0.0.1:8000';

export const API_URLS = {
  REGISTER: '/api/register',
  ADMIN_SIGN_IN_AS_CUSTOMER: (id) => `/api/impersonate-user/${id}`,
  LOGIN: '/api/login_check',
  REFRES_TOKEN: '/api/token/refresh',
  CHEK_AUTH_USER: '/api/current',
  RESET_PASSWORD: '/api/reset-password',
  UPDATE_PASSWORD: (id) => `/api/update-password/${id}`,
  HANDLE_RESET_PASSWORD: '/api/handle-reset-password',
  CONVERT_DXF: '/api/to-svg',
  MATERIAL_DATA: '/api/product/options',
  SAVE_PRODUCTS: '/api/projects/checkout',
  GET_QUOTES_DATA: '/api/quotes',
  PUT_QUOTE_STATUS: (id) => `/api/orders/${id}`,
  GET_ORDERS_DATA: '/api/orders',
  GET_CONCEPTS_DATA: '/api/concepts',
  GET_DASHBOARD: '/api/dashboard',
  DELETE_ORDER: (id) => `/api/orders/${id}`,
  COPY_ORDER_TO_QUOTE: (id) => `/api/orders/${id}/copy-to-quote`,
  GET_COUNTRY: '/api/countries',
  PROCESS_PAYMENT: '/api/projects/process-mollie-payment',
  CHECK_STATUS: '/api/projects/Checkstatus',
  GET_COUNTRY_NO_TOKEN: '/rest/country',
  COPY_ORDER: (id) => `/api/orders/${id}/copy-to-quote`,
  GET_SHIPPING_TAX: (shipId) => `/api/vats/${shipId}`,
  GET_SAVE_AS_QUOTE: (projectId) => `/api/projects/${projectId}/save-as-quote`,
  GET_PROCEED_TO_PAYMENT: (projectId) =>
    `/api/projects/${projectId}/proceed-to-payment`,
  GET_ORDER_DETAILED: (orderId) => `/api/orders/${orderId}`,
  GET_CHECKOUT: (projectId) => `/api/projects/${projectId}`,
  PUT_CHECKOUT: (projectId) => `/api/projects/${projectId}/checkout`,
  GET_EDIT_PROJECT: (productsId) => `/api/projects/${productsId}`,
  GET_PRODUCTS_SVG: (productsId) => `/api/products/${productsId}/svg`,
  GET_SAVE_CHANGE_PRODUCT: (productsId) => `/api/products/${productsId}`,
  GET_ORDER_TYPES: '/api/orders/types/all',
  GET_DELIVERY_DAYS: '/api/delivery-days',
  CHANGE_ORDER_TYPE: (orderId) => `/api/orders/${orderId}/change-type`,
  GET_MATERIALS_DATA: '/api/materials',
  POST_MATERIALS_DATA: `/api/materials`,
  PUT_MATERIALS_DATA: (id) => `/api/materials/${id}`,
  GET_MATERIAL_DETAILED: (id) => `/api/materials/${id}`,
  PUT_MATERIAL_FILE: (id) => `/api/materials/${id}/file `,
  UPLOAD_MATERIAL_IMAGE: (id) => `/api/materials/${id}/upload`,
  GET_MATERIAL_SEARCH_DATA: `/api/material/search`,
  GET_MATERIAL_FILTER_DATA: `/api/material/filter`,
  GET_MATERIAL_DETAILS: `/api/material/details`,
  GET_DATA_WITH_CRITERIA: `/api/material/criteria`,
  GET_MATERIAL: (id) => `/api/materials/${id}`,
  //UPLOAD_MATERIAL_IMAGE: '/material-upload.php',
  GET_THREADS_DATA: '/api/threads',
  GET_THREAD_DETAILED: (id) => `/api/threads/${id}`,
  GET_PRICE_RULES_DATA: '/api/price-rules',
  GET_PRICE_RULE_DETAILED: (id) => `/api/price-rules/${id}`,
  GET_VATS_DATA: '/api/vats',
  GET_VAT_DETAILED: (id) => `/api/vats/${id}`,
  GET_PAYMENT_TYPES_DATA: '/api/payment-types',
  GET_PAYMENT_TYPE_DETAILED: (id) => `/api/payment-types/${id}`,
  GET_HOURLY_RATES_DATA: '/api/hourly-rates',
  GET_HOURLY_RATE_DETAILED: (id) => `/api/hourly-rates/${id}`,
  GET_SHIPPING_COSTS_DATA: '/api/shipping-costs',
  GET_SHIPPING_COST_DETAILED: (id) => `/api/shipping-costs/${id}`,
  GET_USERS: '/api/users',
  GET_USER: (id) => `/api/users/${id}`,
  GET_COUNTRIES: '/api/countries',
  GET_DELIVERY_DAYS_DATA: (orderId) => '/api/delivery-days/' + orderId,
  GET_COUNTRY: (id) => `/api/countries/${id}`,
  GET_APP_SETTINGS: `/api/app-settings`,
  IMPORT_CSV_FILE: (option) => `/api/import/${option}/csv`,
  EXPORT_CSV_FILE: (option) => `/api/export/${option}/csv`,
  DOWNLOAD_CSV_FILE: (id) => `/api/export/${id}/download`,
  IMPORT_EXPORT_HISTORY: '/api/import-export/history',
  NEWEST_EXPORT_DATA: '/api/export/newest',
  PRICE_SETTINGS_DATA_DISCOUNT: '/api/export-price-settings',
  MESSAGES: 'api/messages',
  EXACT: '/api/exact',
  EXACT_EXPIRE_DATE: '/api/exact/expire-date',
  GET_USER_CAD_JSON: (id) => `api/user_cad_json/${id}`,
  PATCH_USER: (id) => `/api/users/${id}`,
  /* part library */
  GET_FOLDERS: '/api/folder',
  GET_FOLDER: (id)  => `/api/folder/${id}`,
  GET_FILES: (id)  => `/api/file/${id}`,
  GET_FILE_SVG: (id)  => `/api/file/${id}/svg`,
  GET_FILE_DXF: (id)  => `/api/file/${id}/download/dxf`,
  ADD_FOLDER: '/api/folder',
  UPDATE_FOLDER: '/api/folder',
  GET_FILE: '/api/file',
  ADD_FILE: '/api/file',
  EDIT_FILE: (id) => `/api/file/${id}`,
  EDIT_FOLDER: (id) => `/api/folder/${id}`,
  DELETE_FILE: (id) => `/api/file/${id}`,
  DELETE_FOLDER: (id) => `/api/folder/${id}`,
  GET_ALL_FILES: '/api/file',




};
