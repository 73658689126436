import { API_URLS } from '../common/constants/api/apiConstants';
import initApi from './apiConfig/apiConfig';

const defaultParams = {
  include: 'company,addresses,address_type,country',
};

export function login(data) {
  return initApi(false).post(API_URLS.LOGIN, data);
}

export function register(data) {
  return initApi(false).post(API_URLS.REGISTER, data);
}

export function checkAuth() {
  return initApi().get(API_URLS.CHEK_AUTH_USER, { params: defaultParams });
}

export function requestPassword(email) {
  return initApi().post(API_URLS.RESET_PASSWORD, { email });
}

export function updatePassword({ id, currentpassword, newpassword }) {
  return initApi().post(API_URLS.UPDATE_PASSWORD(id), {
    currentpassword,
    newpassword,
  });
}

export function handleResetPassword({ email, token, password }) {
  return initApi().post(API_URLS.HANDLE_RESET_PASSWORD, {
    email,
    password,
    token,
  });
}

export function refreshToken(refresh_token) {
  return initApi().post(API_URLS.REFRES_TOKEN, { refresh_token });
}

export function getCountry() {
  return initApi(false).get(API_URLS.GET_COUNTRY_NO_TOKEN);
}

export function handleAdminSignInAsCustomer(id) {
  return initApi().get(API_URLS.ADMIN_SIGN_IN_AS_CUSTOMER(id));
}

export function requestCADjson(id) {
  return initApi().get(API_URLS.GET_USER_CAD_JSON(id));
}

export function reloadCADjson(id, data) {
  return initApi()
    .patch(API_URLS.PATCH_USER(id), data);
}