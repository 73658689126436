import { handleActions } from "redux-actions";
import {
  getShippingCosts,
  getShippingCostsSuccess,
  getShippingCostsError,
  getShippingCost,
  getShippingCostSuccess,
  getShippingCostError,
  updateShippingCost,
  updateShippingCostSuccess,
  updateShippingCostError,
  deleteShippingCost,
  deleteShippingCostSuccess,
  deleteShippingCostError,
} from "./actions";

const initialState = {
  isProcessing: false,
  data: [],
  error: "",
  pagination: {
    per_page: 10,
    current_page: 1,
    count: 0
  },
  detailedShippingCost: {
    isProcessing: false,
    data: null,
    status: {
      isProcessing: false
    }
  },
};

const handlers = {
  [getShippingCosts]: (state) => ({
    ...state,
    isProcessing: true,
  }),
  [getShippingCostsSuccess]: (state, action) => ({
    ...state,
    isProcessing: false,
    data: action.payload,
    // data: payload.quotes,
    pagination: {
      ...state.pagination,
      count: (action.payload && action.payload.length) || 0
    }
  }),
  [getShippingCostsError]: (state, action) => ({
    ...state,
    isProcessing: false,
    error: action.payload
  }),
  [getShippingCost]: (state) => ({
    ...state,
    detailedShippingCost: {
      ...state.detailedShippingCost,
      isProcessing: true
    }
  }),
  [getShippingCostSuccess]: (state, action) => ({
    ...state,
    detailedShippingCost: {
      ...state.detailedShippingCost,
      isProcessing: false,
      data: action.payload
    }
  }),
  [getShippingCostError]: (state, action) => ({
    ...state,
    detailedShippingCost: {
      ...state.detailedShippingCost,
      isProcessing: false,
      error: action.payload
    }
  }),
  [updateShippingCost]: (state) => ({
    ...state,
    detailedShippingCost: {
      ...state.detailedShippingCost,
      isProcessing: true,
    }
  }),
  [updateShippingCostSuccess]: (state) => ({
    ...state,
    detailedShippingCost: {
      ...initialState.detailedShippingCost,
      isProcessing: false,
    }
  }),
  [updateShippingCostError]: (state, action) => ({
    ...state,
    detailedShippingCost: {
      ...state.detailedShippingCost,
      isProcessing: false,
      status: {
        ...state.detailedShippingCost.status,
        error: action.payload
      }
    }
  }),
  [deleteShippingCost]: (state) => ({
    ...state,
  }),
  [deleteShippingCostSuccess]: (state) => ({
    ...state,
  }),
  [deleteShippingCostError]: (state) => ({
    ...state,
  }),
};

export default handleActions(handlers, initialState);
