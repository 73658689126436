import { toAbsoluteUrl } from '../../../../_metronic/utils/utils';
import { ROUTES } from '../../../common/constants/routes/routes';

export const materialsInitialItems = [
  {
    id: 'pmma-gs',
    category: 'plastic',
    title: 'PMMA GS',
    subtitle: 'Plexiglas / Perspex / Altuglas / Oroglas / Acryl / Acrylglas',
    href: ROUTES.PMMA_GS_MATERIAL_LP,
    imgSrc: toAbsoluteUrl('/clients-logos/PMMA-zwart-lasersnijden-BMTEC-495x400.jpg'),
    thickness: '1 - 25',
    plateMeasurement: '3050 x 2050',
    dataSheets: ["Tolerance sheet thickness PMMA GS - CutWise.pdf", "Datasheet PMMA CutWise.pdf"],
  },
  {
    id: 'hdpe',
    category: 'plastic',
    title: 'HDPE',
    subtitle: 'Hoge Dichtheid Polyetheen',
    href: ROUTES.HDPE_MATERIALS_LP,
    imgSrc: toAbsoluteUrl('/materials/HDPE.png'),
    thickness: '1 - 40',
    plateMeasurement: '2000 x 1000',
    dataSheets: ["Tolerances_HDPE-PE100_CutWise.pdf", "Datasheet_HDPE-PE100_CutWise.pdf"],
  },
  {
    id: 'pom',
    category: 'plastic',
    title: 'POM',
    subtitle: 'Delrin / Celcon / Hostaform / Ultraform / Kocetal / Duracon',
    href: ROUTES.POM_MATERIAL_LP,
    imgSrc: toAbsoluteUrl('/materials/POM-1.png'),
    thickness: '0.5 - 40',
    plateMeasurement: '2000 x 1000',
    dataSheets: ["Tolerance sheet thickness POM-C - CutWise.pdf", "CutWise Datasheet POM-C.pdf"],
  },
  {
    id: 'pet-g',
    category: 'plastic',
    title: 'PET-G',
    subtitle: 'Polyethyleentereftalaat Glycol',
    href: ROUTES.PETG_MATERIAL_LP,
    imgSrc: toAbsoluteUrl('/materials/PETG.png'),
    thickness: '0.6 - 15',
    plateMeasurement: '3050 x 2050',
    dataSheets: ["Tolerance sheet thickness PET-G - CutWise.pdf", "Datasheet PET-G CutWise.pdf"],
  },
  {
    id: 'ptfe',
    category: 'plastic',
    title: 'PTFE',
    subtitle: 'Teflon / Fluon / Dyneon / Algoflon / Polyflon',
    href: ROUTES.PTFE_MATERIAL_LP,
    imgSrc: toAbsoluteUrl('/materials/PTFE.png'),
    thickness: '1 - 40',
    plateMeasurement: '1200 x 1200',
    dataSheets: ["Tolerance sheet thickness PTFE - CutWise.pdf", "Datasheet PTFE CutWise.pdf"],
  },
  {
    id: 'petp',
    category: 'plastic',
    title: 'PET-P',
    subtitle: 'Polyethyleentereftalaat',
    href: ROUTES.PETP_MATERIAL_LP,
    imgSrc: toAbsoluteUrl('/materials/PETP.png'),
    thickness: '3 - 40',
    plateMeasurement: '3050 x 2050',
    dataSheets: ["Tolerance sheet thickness PETP - CutWise.pdf", "Datasheet PETP CutWise.pdf"],
  },
  {
    id: 'hmpe-500',
    category: 'plastic',
    title: 'HMPE-500',
    subtitle: 'Hoogmoleculaire Polyethyleen',
    href: ROUTES.HMPE_500_MATERIAL_LP,
    imgSrc: toAbsoluteUrl('/clients-logos/HMPE-snijden-845x684.jpg'),
    thickness: '1 - 40',
    plateMeasurement: '2000 x 1000',
    dataSheets: ["Tolerance sheet thickness HMPE-500 - CutWise.pdf", "Datasheet HMPE-500 CutWise.pdf"],
  },
  {
    id: 'pmma-xt',
    category: 'plastic',
    title: 'PMMA XT',
    subtitle: 'Plexiglas / Perspex / Altuglas / Oroglas / Acryl / Acrylglas',
    href: ROUTES.PMMA_XT_MATERIAL_LP,
    imgSrc: toAbsoluteUrl('/materials/pmma.png'),
    thickness: '1 - 20',
    plateMeasurement: '3050 x 2050',
    dataSheets: ["Tolerance sheet thickness PMMA XT - CutWise.pdf", "Datasheet PMMA CutWise.pdf"],
  },
  {
    id: 'ps',
    category: 'plastic',
    title: 'PS',
    subtitle: ' Thermoplastisch Polymeer',
    href: ROUTES.PS_MATERIAL_LP,
    imgSrc: toAbsoluteUrl('/materials/PS.png'),
    thickness: '0.5 - 5',
    plateMeasurement: '2000 x 1000',
    dataSheets: ["", ""],
  },
  {
    id: 'pc',
    category: 'plastic',
    title: 'Polycarbonaat',
    subtitle: 'Lexan / Makrolon / Makroclear / Palsun / AkyVer',
    href: ROUTES.PC_MATERIAL_LP,
    imgSrc: toAbsoluteUrl('/materials/PC.png'),
    thickness: '0.8 - 15',
    plateMeasurement: '3050 x 2050',
    dataSheets: ["Tolerance sheet thickness PS (Polystyrene) - CutWise.pdf", "Datasheet PS (Polystyrene) CutWise.pdf"],
  },
  {
    id: 'pi',
    category: 'plastic',
    title: 'PI',
    subtitle: 'Kapton / Vespel / Upilex / Durimide / Aurum',
    href: ROUTES.PI_MATERIAL_LP,
    imgSrc: toAbsoluteUrl('/materials/PI-klein.png'),
    thickness: '0.125 - 0.5',
    plateMeasurement: 'On roll width up to 2500',
    dataSheets: ["Kapton-Polyimidefilms - CutWise.pdf", "Datasheet PI (Polyimide) CutWise.pdf"],
  },
  {
    id: 'pa',
    category: 'plastic',
    title: 'PA6 (Nylon)',
    subtitle: 'Nylon / Ultramid / Durethan / Technyl / Akulon',
    href: ROUTES.PA_MATERIAL_LP,
    imgSrc: toAbsoluteUrl('/materials/PA.png'),
    thickness: '0.5 - 40',
    plateMeasurement: '2000 x 1000',
    dataSheets: ["", ""],
  },
  {
    id: 'pp',
    category: 'plastic',
    title: 'PP',
    subtitle: 'Polypropyleen',
    href: ROUTES.PP_MATERIAL_LP,
    imgSrc: toAbsoluteUrl('/materials/PP.png'),
    thickness: '1 - 40',
    plateMeasurement: '2000 x 1000',
    dataSheets: ["Tolerance sheet thickness PP (Polypropylene) - CutWise.pdf", "Datasheet PP (Polypropylene) CutWise.pdf"],
  },
  {
    id: 'hpl',
    category: 'other',
    title: 'HPL / Trespa',
    subtitle: 'Formica / Wilsonart / Abet Laminati / Arpa Industriale / Nevamar / Trespa',
    href: ROUTES.HPL_MATERIAL_LP,
    imgSrc: toAbsoluteUrl('/materials/HPL.jpg'),
    thickness: '3 - 20',
    plateMeasurement: '3050x1300',
    dataSheets: ["Tolerance sheet thickness HPL - CutWise.pdf", "Datasheet HPL CutWise.pdf"],
  },
  {
    id: 'peek',
    category: 'plastic',
    title: 'PEEK',
    subtitle: 'Victrex / KetaSpire',
    href: ROUTES.PEEK_MATERIAL_LP,
    imgSrc: toAbsoluteUrl('/materials/PEEK.png'),
    thickness: '6 - 40',
    plateMeasurement: '1000 x 620',
    dataSheets: ["Tolerance sheet thickness PEEK - CutWise.pdf", "Datasheet PEEK CutWise.pdf"],
  },
  {
    id: 'hmpe-1000',
    category: 'plastic',
    title: 'HMPE-1000',
    subtitle: 'Hoogmoleculaire Polyethyleen',
    href: ROUTES.HMPE_1000_MATERIAL_LP,
    imgSrc: toAbsoluteUrl('/materials/HMPE.png'),
    thickness: '1 - 40',
    plateMeasurement: '2000 x 1000',
    dataSheets: ["Tolerance sheet thickness HMPE-1000 - CutWise.pdf", "Datasheet HMPE-1000 CutWise.pdf"],
  },
  {
    id: 'pur',
    category: 'rubber',
    title: 'PUR',
    subtitle: 'Polyurethaan',
    href: ROUTES.PUR_MATERIAL_LP,
    imgSrc: toAbsoluteUrl('/materials/PUR.png'),
    thickness: '2 - 5',
    plateMeasurement: '3050 x 2050',
    dataSheets: ["Tolerance sheet thickness PUR - CutWise.pdf", "Datasheet PUR CutWise.pdf"],
  },
  {
    id: 'abs',
    category: 'plastic',
    title: 'ABS',
    subtitle: 'Acrylonitril-Butadieen-Styreen',
    href: ROUTES.ABS_MATERIAL_LP,
    imgSrc: toAbsoluteUrl('/materials/ABS.png'),
    thickness: '3 - 4',
    plateMeasurement: '2000 x 1200',
    dataSheets: ["Tolerance sheet thickness ABS - CutWise.pdf", "Datasheet ABS CutWise.pdf"],
  },
  {
    id: 'dibond-alupanel',
    category: 'other',
    title: 'Alupanel / Dibond',
    subtitle: 'Dibond / Alucobond / Alucore / Alupanel / Alpolic / Vitrabond',
    href: ROUTES.DIBOND_ALUPANEL_MATERIAL_LP,
    imgSrc: toAbsoluteUrl('/materials/Alupanel-en-Dibond-Frezen-300x200.jpg'),
    thickness: '2 - 6',
    plateMeasurement: '3050x1500',
    dataSheets: ["Tolerance sheet thickness Alupanel - CutWise.pdf", "CutWise Datasheet Alupanel.pdf"],
  },
  {
    id: 'epdm',
    category: 'rubber',
    title: 'EPDM',
    subtitle: 'Ethyleen / Propyleen / Dieen / Monomeer',
    href: ROUTES.EPDM_MATERIAL_LP,
    imgSrc: toAbsoluteUrl('/materials/epdm-rubber-snijden.jpg'),
    thickness: '1 - 10',
    plateMeasurement: '3000x1400',
    dataSheets: ["Tolerance sheet thickness EPDM-Rubber - CutWise.pdf", "Datasheet EPDM-Rubber CutWise.pdf"],
  },
  {
    id: 'nbr',
    category: 'rubber',
    title: 'NBR',
    subtitle: 'Nitrilbutadieenrubber',
    href: ROUTES.NBR_MATERIAL_LP,
    imgSrc: toAbsoluteUrl('/materials/nbr-rubber-705x470.jpg'),
    thickness: '0.5 - 10',
    plateMeasurement: '3000x1400',
    dataSheets: ["Tolerance sheet thickness NBR - CutWise.pdf", "Datasheet NBR CutWise.pdf"],
  },
  {
    id: 'sbr',
    category: 'rubber',
    title: 'SBR',
    subtitle: 'Styreen Butadieen Rubber',
    href: ROUTES.SBR_MATERIAL_LP,
    imgSrc: toAbsoluteUrl('/materials/sbr.jpg'),
    thickness: '1 - 10',
    plateMeasurement: '3000x1400',
    dataSheets: ["Tolerance sheet thickness PUR - CutWise.pdf", "Datasheet SBR CutWise.pdf"],
  },
  {
    id: 'siliconen',
    category: 'rubber',
    title: 'Siliconen',
    subtitle: 'Siliconen',
    href: ROUTES.SILICONE_MATERIAL_LP,
    imgSrc: toAbsoluteUrl('/materials/siliconen.jpg'),
    thickness: '1 - 10',
    plateMeasurement: '3000x1400',
    dataSheets: ["Tolerance sheet thickness Silicone Rubber - CutWise.pdf", "Datasheet Silicone Rubber CutWise.pdf"],
  },
];
