import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Image } from 'react-bootstrap';

import { toAbsoluteUrl, metronic } from '../../../_metronic';
import '../../../_metronic/_assets/sass/pages/login/login-1.scss';
import Login from './Login';
import Registration from './Registration';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import MaterialsLP from '../home/materialsLP';

import { ROUTES } from '../../common/constants/routes/routes';
import Logos from '../../../_metronic/_assets/media/Group 11.svg';
import LanguageSelector from '../../../app/partials/layout/LanguageSelector';

function AuthPage(props) {
  const {
    setLanguage,
    user,
    match: { params },
  } = props;

  if (params.lang) {
    setLanguage(params.lang);
  }
  if (user) {
    return <Redirect to="/configurator" />;
  }

  return (
    <>
      <div className={'login h-100 login-bg'}>
        <div className={'container-xl h-100'}>
          <div className={'row h-100'}>
            <div className={'login-left col-lg-6 col-sm-12 h-100'}>
              <div className={'login-left-top'}>
                <div className={'login-left-top-lng'}>
                  <LanguageSelector iconType="" />
                </div>
                <Image
                  src={toAbsoluteUrl('/media/logos/cutwise_logo.png')}
                  className={'login-left-top-logo'}
                />
              </div>
              <div className={'login-left-center'}>
                <div className={'login-left-center-inner'}>
                  <Router>
                    <Switch>
                      <Route exact path={ROUTES.AUTH_LOGIN} component={Login} />
                      <Route
                        exact
                        path={ROUTES.AUTH_LOGIN_LANG}
                        component={Login}
                      />
                      <Route
                        path={ROUTES.AUTH_REGISTRATION}
                        component={Registration}
                      />
                      <Route
                        exact
                        path={ROUTES.AUTH_REGISTRATION_LANG}
                        component={Registration}
                      />
                      <Route
                        path={ROUTES.AUTH_FORGOT_PASS}
                        component={ForgotPassword}
                      />
                      <Route
                        exact
                        path={ROUTES.AUTH_RESET_PASS}
                        component={ResetPassword}
                      />
                      <Route
                        exact
                        path={ROUTES.AUTH_RESET_PASS_WITH_PARAMS}
                        component={ResetPassword}
                      />
                      <Route
                        exact
                        path={ROUTES.MATERIALS_LP}
                        component={MaterialsLP}
                      />
                    </Switch>
                  </Router>

                  <Image className={'login-left-center-image'} src={Logos} />
                  <div className={'clearfix'}></div>
                </div>
              </div>
            </div>

            <div className={'login-right col-lg-6 col-sm-12 h-100'}>
              <div className={'login-right-center'}>
                <div className={'login-right-center-text'}>
                  <FormattedMessage id="HOME.UL_TITLE" />
                </div>
                <div className={'login-right-center-bullets'}>
                  <ul>
                    <li>
                      <FormattedMessage id="HOME.LI_1" />
                    </li>
                    <li>
                      <FormattedMessage id="HOME.LI_2" />
                    </li>
                    <li>
                      <FormattedMessage id="HOME.LI_3" />
                    </li>
                  </ul>
                </div>
                <Image
                  className={'login-right-center-image'}
                  src={toAbsoluteUrl('/media/video/cutwise_how_it_works.gif')}
                />
              </div>
            </div>
            <div className={'clearfix'}></div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = ({ i18n, auth: { user } }) => ({
  lang: i18n.lang,
  user,
});

export default injectIntl(
  connect(mapStateToProps, metronic.i18n.actions)(AuthPage)
);
