import moment from 'moment';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';

const AdminNotification = ({
  lang,
  title,
  text,
  title_en,
  text_en,
  title_de,
  text_de,
  updated_at: updatedAt,
  bellClicked,
  type,
}) => {
  const content = {
    en: {
      title: title_en,
      text: text_en,
    },
    de: {
      title: title_de,
      text: text_de,
    },
    nl: {
      title: title,
      text: text,
    },
  };

  moment.locale(lang);
  const utcMoment = moment.unix(updatedAt);

  const relativeTime = utcMoment.fromNow();

  return (
    <div
      className={clsx('notification-message', {
        'admin-notification-message': type == 'ADMIN_UPDATE',
      })}
    >
      <Grid container spacing={1}>
        <Grid container item xs={12} alignItems="flex-start">
          <Grid item xs={6}>
            <h6>{content[lang].title}</h6>
          </Grid>
          <Grid container item xs={5} justifyContent="flex-end">
            <span style={{ fontSize: '10px', padding: '0 4px' }}>
              {relativeTime}
            </span>
          </Grid>
          {bellClicked - new Date(updatedAt * 1000).getTime() < 86400000 && (
            <Grid container item xs={1} justifyContent="flex-end">
              <svg
                data-testid="unread-indicator"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                width="16px"
                fill="none"
                height="16px"
              >
                <circle
                  cx="50%"
                  cy="50%"
                  r="4"
                  fill="var(--ds-icon-brand, #0052CC)"
                ></circle>
              </svg>
            </Grid>
          )}
        </Grid>

        <Grid item xs={12}>
          <span>{content[lang].text}</span>
        </Grid>
      </Grid>
    </div>
  );
};

export default AdminNotification;
