import { API_URLS } from '../../common/constants/api/apiConstants';
import initApi from '../../crud/apiConfig/apiConfig';

const defaultParams = {
  include:
    'company,addresses,addresses.country,addresses.type,price_setting.price_setting_hourly_rates,price_setting.price_setting_materials',
};

export function getUsersQuery() {
  return initApi().get(API_URLS.GET_USERS);
}

export const getUserQuery = (id) => {
  return initApi().get(API_URLS.GET_USER(id), { params: defaultParams });
};

export const deleteUserQuery = (id) => {
  return initApi().delete(API_URLS.GET_USER(id));
};

export const updateUserQuery = ({ id, data }) => {
  if (id) {
    return initApi().put(API_URLS.GET_USER(id), data);
  } else {
    return initApi().post(API_URLS.GET_USERS, data);
  }
};
