import React from 'react';
import { connect } from 'react-redux';
import objectPath from 'object-path';
import Header from './header/Header';
import SubHeader from './sub-header/SubHeader';
import HeaderMobile from './header/HeaderMobile';
import AsideLeft from './aside/AsideLeft';
import ScrollTop from '../../app/partials/layout/ScrollTop';
import HTMLClassService from './HTMLClassService';
import LayoutConfig from './LayoutConfig';
import MenuConfig from './MenuConfig';
import LayoutInitializer from './LayoutInitializer';
import QuickPanel from '../../app/partials/layout/QuickPanel';
import KtContent from './KtContent';
import Snackbar from '@material-ui/core/Snackbar';
import './assets/Base.scss';
import Cookies from 'universal-cookie';
import axios from 'axios';
import { injectIntl } from 'react-intl';
// import useWindowSize from 'react-use/lib/useWindowSize';

const cookies = new Cookies();

const htmlClassService = new HTMLClassService();

class Layout extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };

    this.elementRef = React.createRef();
  }

  componentDidMount = async () => {

    this.elementRef.current != true;

    const contentCssClasses = htmlClassService.classes?.content.join(' ');

    htmlClassService.setConfig(this.props.layoutConfig);

    this.contentCssClasses = contentCssClasses;

    async function fetchData() {
      const result = await axios(
        'https://www.bmtec.nl/wp-json/wp/v2/pages/23756'
      );
  
      if (result.data) {
        let modifiedDate = result.data?.modified;
  
        let userLastNotified = cookies.get('modifiedDate');
  
        if (modifiedDate != userLastNotified) {
          cookies.set('modifiedDate', modifiedDate);
          return true;
        }
        
        return false;
      }
    }
    
    if(this.elementRef.current) {
      const open = await fetchData();
      this.setState(prevState => ({ ...prevState, open }));
    }

  };

  componentWillUnmount() {
    this.elementRef.current != null;
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState(prevState => ({ ...prevState, open: true }));
  };

  render() {

    const {
      children,
      asideDisplay,
      subheaderDisplay,
      selfLayout,
      intl
    } = this.props;

  return selfLayout !== 'blank' ? (
    <LayoutInitializer
      menuConfig={MenuConfig}
      layoutConfig={LayoutConfig}
      htmlClassService={htmlClassService}
    >
      <HeaderMobile />
      <div className="kt-grid kt-grid--hor kt-grid--root">
        <Header />
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
          {asideDisplay && <AsideLeft />}
          <div
            className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper"
            id="kt_wrapper"
          >
            <div
              id="kt_content"
              className={`kt-content ${this.contentCssClasses} kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor`}
            >
              {subheaderDisplay && <SubHeader />}

              <KtContent>{children}</KtContent>

              {this.state.open && (
                <Snackbar
                  open={true}
                  autoHideDuration={10000}
                  onClose={this.handleClose}
                  message={intl.formatMessage({ id: 'CHANGELOG_MSG' })}
                  onClick={() =>
                    window.open(intl.formatMessage({ id: 'CHANGELOG_LINK' }))
                  }
                  className={'hasOnClick'}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <QuickPanel />
      <ScrollTop />
    </LayoutInitializer>
  ) : (
    // BLANK LAYOUT
    <div className="kt-grid kt-grid--ver kt-grid--root">
      <KtContent>{children}</KtContent>
    </div>
  );
} }

const mapStateToProps = ({ builder: { layoutConfig } }) => ({
  layoutConfig,
  selfLayout: objectPath.get(layoutConfig, 'self.layout'),
  asideDisplay: objectPath.get(layoutConfig, 'aside.self.display'),
  subheaderDisplay: objectPath.get(layoutConfig, 'subheader.display'),
  desktopHeaderDisplay: objectPath.get(
    layoutConfig,
    'header.self.fixed.desktop'
  ),
  contentContainerClasses: '',
  // contentContainerClasses: builder.selectors.getClasses(store, {
  //   path: "content_container",
  //   toString: true
  // })
});

export default injectIntl(connect(mapStateToProps)(Layout));
