import React from 'react';
import Button from "@mui/material/Button";
import { LuImport } from "react-icons/lu";
import { FiFolderPlus } from "react-icons/fi";
import { useParams } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import LinearProgress from '@mui/material/LinearProgress';
import {MdOutlineChevronLeft} from "react-icons/md";

function Loader({ message, progress }) {
    return (
        <Backdrop open={true} >
            <div style={{ textAlign: 'center', width: '50%' }}>
                <p>{message}</p>
                <LinearProgress variant="determinate" value={progress} />
                <p>{`${progress}%`}</p>
            </div>
        </Backdrop>
    );
}

export default function ToolBarComponent({uploadRef, progress, loading, loadingMessage, createProj,  currentFolder, createFolder, handleBack}) {

    const { folderId } = useParams();

    return (
        <div className={'drive_window'}>
            {loading && <Loader message={loadingMessage} progress={progress} />}

            <div className="drive_window-toolbar">

                    <Button
                    variant="text"
                    className={'drive_window-back'}
                    onClick={handleBack}
                >
                    {folderId !== "main" && (
                        <MdOutlineChevronLeft style={{fontSize: '20px'}}/>
                    )}
                    { (folderId === "main") ? "MAIN" : 'back'}
                </Button>

                <LuImport
                    className="actionIcons drive_window"
                    style={{ transform: 'rotate(180deg)' }}
                    onClick={() => uploadRef.current.click()}
                />

                <input
                    ref={uploadRef}
                    type="file"
                    accept=".dxf"
                    style={{ display: 'none' }}
                    multiple={true}
                    onChange={(e) => createProj(e, uploadRef)}
                />

                <FiFolderPlus
                    className="actionIcons"
                    onClick={() => createFolder()}
                />
            </div>
        </div>
    )
}
