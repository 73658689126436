// import React from 'react';

export default {
  header: {
    self: {},
    items: [
      {
        title: 'Dashboards',
        root: true,
        alignment: 'left',
        page: 'dashboard',
        translate: 'MENU.DASHBOARD',
      },
      // {
      //   title: "Components",
      //   root: true,
      //   alignment: "left",
      //   toggle: "click",
      //   submenu: [
      //     {
      //       title: "React Bootstrap",
      //       bullet: "dot",
      //       icon: "flaticon-web",
      //       submenu: [
      //
      //
      //       ]
      //     }
      //   ]
      // },
    ],
  },
  aside: {
    self: {},
    items: [
      {
        section: 'Ordering',
        translate: 'MENU.ORDERING',
      },
      {
        title: 'Upload parts',
        root: true,
        icon: 'flaticon2-shopping-cart',
        page: 'configurator',
        translate: 'MENU.UPLOADPARTS',
        bullet: 'dot',
      },
      {
        title: 'Quotes',
        root: true,
        icon: 'flaticon2-list',
        page: 'quotes',
        translate: 'MENU.QUOTES',
        bullet: 'dot',
        scope: ['admin', 'user'],
      },
      {
        title: 'Orders',
        root: true,
        icon: 'flaticon2-delivery-package',
        page: 'orders',
        translate: 'MENU.ORDERS',
        bullet: 'dot',
        scope: ['admin', 'user'],
      },
      {
        title: 'Material 360°',
        root: true,
        icon: 'flaticon2-cube',
        page: 'materials',
        translate: 'MENU.MATERIALS_360',
        bullet: 'dot',
      },
      {
        title: 'CUTWISE CAD',
        root: true,
        icon: 'flaticon2-edit',
        badge: "beta",
        page: 'cutwisecad',
        translate: 'MENU.SKETCHER',
        bullet: 'dot',
        scope: ['admin', 'user'],
      },
      // {
      //   title: 'My Part Library',
      //   root: true,
      //   icon: 'flaticon2-shopping-cart-1',
      //   page: 'mypartlibrary',
      //   translate: 'MENU.SKETCHER',
      //   bullet: 'dot',
      //   scope: ['admin', 'user'],
      // },
      {
        title: 'My Part Library',
        root: true,
        icon: 'flaticon2-shopping-cart-1',
        badge: "beta",
        page: 'part-library/main',
        translate: 'MENU.PART_LIBRARY',
        bullet: 'dot',
        scope: ['admin', 'user'],
      },
      {
        section: 'Configuration',
        scope: ['admin'],
      },
      {
        title: 'Dashboard',
        root: true,
        icon: 'flaticon2-rocket',
        page: 'dashboard',
        translate: 'MENU.DASHBOARD',
        bullet: 'dot',
        scope: ['admin'],
      },
      {
        title: 'Parts In Lib',
        root: true,
        icon: 'flaticon2-checking',
        page: 'parts-in-lib',
        translate: 'MENU.PARTSINLIB',
        bullet: 'dot',
        scope: ['admin'],
      },
      {
        title: 'Users',
        root: true,
        icon: 'flaticon2-user',
        page: 'users',
        translate: 'MENU.USERS',
        bullet: 'dot',
        scope: ['admin'],
      },
      {
        title: 'Admin updates',
        root: true,
        icon: 'flaticon2-user',
        page: 'admin-updates',
        translate: 'MENU.ADMIN_UPDATES',
        bullet: 'dot',
        scope: ['admin'],
      },
      {
        title: 'Exact',
        root: true,
        icon: 'icon-exact',
        page: 'exact',
        translate: 'MENU.EXACT',
        bullet: 'dot',
        scope: ['admin'],
      },
      {
        title: 'Uploads',
        root: true,
        icon: 'flaticon2-delivery-package',
        page: 'uploads',
        translate: 'MENU.UPLOADS',
        bullet: 'dot',
        scope: ['admin'],
      },
      {
        title: 'Price rules',
        root: true,
        icon: 'flaticon2-console',
        page: 'price-rules',
        translate: 'MENU.PRICE_RULES',
        bullet: 'dot',
        scope: ['admin'],
      },
      {
        title: 'Materials',
        root: true,
        icon: 'flaticon2-layers-2',
        page: 'materials-info',
        translate: 'MENU.MATERIALS',
        bullet: 'dot',
        scope: ['admin'],
      },
      {
        title: 'Materials Bending',
        root: true,
        icon: 'flaticon2-cube',
        page: 'bending',
        translate: 'MENU.MATERIALS_BENDING',
        bullet: 'dot',
        scope: ['admin'],
      },
      {
        title: 'Hourly rates',
        root: true,
        icon: 'flaticon2-time',
        page: 'hourly-rates',
        translate: 'MENU.HOURLY_RATES',
        bullet: 'dot',
        scope: ['admin'],
      },
      {
        title: 'Threads',
        root: true,
        icon: 'flaticon2-add',
        page: 'threads',
        translate: 'MENU.THREADS',
        bullet: 'dot',
        scope: ['admin'],
      },
      {
        title: 'VAT',
        root: true,
        icon: 'flaticon2-percentage',
        page: 'vats',
        translate: 'MENU.VAT',
        bullet: 'dot',
        scope: ['admin'],
      },
      {
        title: 'Shipping',
        root: true,
        icon: 'flaticon2-lorry',
        page: 'shipping',
        translate: 'MENU.SHIPPING',
        bullet: 'dot',
        scope: ['admin'],
      },

      {
        title: 'Countries',
        root: true,
        icon: 'flaticon2-pin',
        page: 'countries',
        translate: 'MENU.COUNTRIES',
        bullet: 'dot',
        scope: ['admin'],
      },
      {
        title: 'Settings',
        root: true,
        icon: 'flaticon2-settings',
        page: 'app-settings',
        translate: 'MENU.APP_SETTINGS',
        bullet: 'dot',
        scope: ['admin'],
      },
      {
        title: 'Import/Export',
        root: true,
        icon: 'flaticon2-image-file',
        page: 'import-export',
        translate: 'MENU.IMPORT_EXPORT',
        bullet: 'dot',
        scope: ['admin'],
      },
      // {
      //   section: 'Information',
      //   external: true,
      // },
      // {
      //   title: 'About',
      //   root: true,
      //   external: true,
      //   page: "FOOTER.ABOUT_URL",
      //   translate: 'FOOTER.ABOUT',
      //   bullet: 'dot'
      // },
      // {
      //   title: 'Terms',
      //   root: true,
      //   external: true,
      //   page: 'FOOTER.URL_TERMS',
      //   translate: 'FOOTER.TERMS_CONDITIONS',
      //   bullet: 'dot'
      // },
      // {
      //   title: 'Contact',
      //   root: true,
      //   external: true,
      //   page: 'FOOTER.CONTACT_URL',
      //   translate: 'FOOTER.CONTACT',
      //   bullet: 'dot'
      // },
      //

      // {
      //   title: "Checkout",
      //   root: true,
      //   icon: "flaticon2-order",
      //   page: "checkout",
      //   translate: "MENU.CHECKOUT",
      //   bullet: "dot"
      // },
    ],
  },
};
