import React from 'react';
import { Route, Switch } from 'react-router-dom';
import CheckoutPage from './CheckoutPage';


export default function Checkout () {
  return (
    <Switch>
      {/* Layout */}
      <Route
        path="/checkout/:id"
        component={CheckoutPage}
      />
      {
          /* <Route
        path="/checkout/new"
        component={CheckoutPage}
      /> */
      }


    </Switch>
  );
}
