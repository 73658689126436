import React, { useState, useEffect } from 'react';
import {
  Grid,
  AppBar,
  Box,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  Drawer,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { Image } from 'react-bootstrap';
import MenuIcon from '@material-ui/icons/Menu';
import CheckIcon from '@material-ui/icons/Check';
import { FormattedMessage, injectIntl } from 'react-intl';

import { toAbsoluteUrl } from '../../../../_metronic/utils/utils';
import LanguageSelector from '../../../../app/partials/layout/LanguageSelector';

const Header = ({ intl }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const handleScroll = () => {
    setScrollPosition(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const navItems = [
    {
      text: 'Diensten',
      href: {
        nl: 'https://www.bmtec.nl/diensten/',
        en: 'https://bmtec-lasercutting.com/services/',
        de: 'https://bmtec-laserschneiden.de/kunststoffe-lasern/',
      },
    },
    {
      text: 'Materialen',
      href: window.location.origin + '/materials',
    },
    {
      text: 'Machinepark',
      href: {
        nl: 'https://www.bmtec.nl/machinepark/',
        en: 'https://bmtec-lasercutting.com/machine-park/',
        de: 'https://bmtec-laserschneiden.de/maschinenpark/',
      },
    },
    {
      text: 'Over ons',
      href: {
        nl: 'https://www.bmtec.nl/over-ons-bmtec/',
        en: 'https://bmtec-lasercutting.com/about-us/',
        de: 'https://bmtec-laserschneiden.de/ueber-uns/',
      },
    },
    {
      text: 'Contact',
      href: {
        nl: 'https://www.bmtec.nl/contact/',
        en: 'https://bmtec-lasercutting.com/contact-us/',
        de: 'https://bmtec-laserschneiden.de/kontakt/',
      },
    },
    { text: 'MIJN CUTWISE', href: window.location.origin + '/auth/login' },
  ];

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <List>
        {navItems.map((item, idx) => (
          <ListItem key={idx}>
            <Link href={item.href[intl?.locale || 'nl'] || item.href}>
              <ListItemText>
                <FormattedMessage id={item.text} />
              </ListItemText>
            </Link>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <>
      <Grid
        item
        container
        xs={12}
        justifyContent="space-evenly"
        className="units-section"
      >
        <Grid item>
          <CheckIcon htmlColor="#5dad29" />
          <Typography variant="body1">
            <FormattedMessage id="UNITS.SECTION.UNIT_1" />
          </Typography>
        </Grid>

        <Grid item>
          <CheckIcon htmlColor="#5dad29" />
          <Typography variant="body1">
            <FormattedMessage id="UNITS.SECTION.UNIT_2" />
          </Typography>
        </Grid>

        <Grid item>
          <CheckIcon htmlColor="#5dad29" />
          <Typography variant="body1">
            <FormattedMessage id="UNITS.SECTION.UNIT_3" />
          </Typography>
        </Grid>

        <Grid item>
          <CheckIcon htmlColor="#5dad29" />
          <Typography variant="body1">
            <FormattedMessage id="UNITS.SECTION.UNIT_4" />
          </Typography>
        </Grid>
      </Grid>
      <AppBar
        position="sticky"
        className={
          'cutwise-header ' +
          (scrollPosition > 80 ? 'bp-header-sticky' : 'bp-header')
        }
        style={{
          background: scrollPosition > 80 ? 'rgba(249,249,249, 0.8)' : '#fff',
        }}
      >
        <Grid
          item
          md={4}
          sm={5}
          xs={6}
          container
          alignItems="center"
          justifyContent="space-evenly"
        >
          <Image
            src={toAbsoluteUrl('/media/logos/cutwise_logo.png')}
            className={'login-left-top-logo'}
          />
          <LanguageSelector iconType="" isBasicPage={true} />
        </Grid>

        <Grid item md={8} sm={7} xs={6} container>
          <Toolbar style={{ width: '100%', justifyContent: 'flex-end' }}>
            <IconButton
              onClick={handleDrawerToggle}
              sx={{ display: { md: 'none' }, color: '#939393' }}
            >
              <MenuIcon style={{ fontSize: '36px' }} />
            </IconButton>

            <Box
              className="nav-items"
              sx={{
                width: '100%',
                display: { xs: 'none', sm: 'none', md: 'flex' },
                justifyContent: 'space-between',
              }}
            >
              {navItems.map((item, idx) => (
                <Link
                  key={idx}
                  href={item.href[intl?.locale || 'nl'] || item.href}
                >
                  <FormattedMessage id={item.text} />
                </Link>
              ))}
            </Box>
          </Toolbar>
        </Grid>
      </AppBar>
      <Grid item xs={6}>
        <nav>
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: { xs: 'block', sm: 'block', md: 'none' },
              '& .MuiDrawer-paper': {
                boxSizing: 'border-box',
              },
            }}
          >
            {drawer}
          </Drawer>
        </nav>
      </Grid>
    </>
  );
};

export default injectIntl(Header);
