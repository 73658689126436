import sass from 'sass';
import css from 'css';

export function css_into_js(scssStylesheet) {
  const parsedCSS = sass.compileString(scssStylesheet).css;
  const cssAST = css.parse(parsedCSS);
  const styleObject = {};

  cssAST.stylesheet.rules.forEach((rule) => {
    if (rule.type === 'rule') {
      const selector = rule.selectors.join(', ');
      styleObject[selector] = {};

      rule.declarations.forEach((declaration) => {
        if (declaration.type === 'declaration') {
          // Convert property to camelCase
          const camelCaseProperty = declaration.property.replace(
            /-([a-z])/g,
            (match, p1) => p1.toUpperCase()
          );
          styleObject[selector][camelCaseProperty] = declaration.value;
        }
      });
    }
  });

  return styleObject;
}