import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { removeStorage } from '../../../_metronic/utils/utils';
import * as routerHelpers from '../../router/RouterHelpers';

export const authActionTypes = {
  Login: '[Login] Action',
  LoginSuccess: '[Login Success] Action',
  CheckAuth: '[Check Auth] Action',
  CheckAuthSucces: '[Check Auth Success] Action',
  CheckAuthError: '[Check Auth Error] Action',
  GetCountry: '[Country] Action',
  GetCountrySuccess: '[Country Success] Action',
  Logout: '[Logout] Action',
  Register: '[Register] Action',
  RegisterSuccess: '[Register Success] Action',
  UserLoaded: '[Load User] Auth API',
  Error: '[Error] Action',
  DeleteError: '[Delete Error] Action',
  ForgotPassword: '[Forgot Password] Action',
  UpdatePassword: '[Update Password] Action',
  ResetPassword: '[Reset Password] Action',
  SignInAsUser: '[Sign In Admin as Customer] Action',
  GetCADjson: '[Load JSON] Action',
  ReloadCADjson: '[Patch JSON] Action',
  RefreshCADjson: '[Refresh JSON] Action',
};

const initialAuthState = {
  isPending: false,
  user: undefined,
  error: null,
  country: {},
};

export const reducer = persistReducer(
  { storage, key: 'userAuth1', whitelist: [] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case authActionTypes.Login: {
        return { ...state, isPending: !state.isPending };
      }

      case authActionTypes.LoginSuccess: {
        return { ...state, user: action.payload, isPending: !state.isPending };
      }

      case authActionTypes.CheckAuth:
        return {
          ...state,
          isPending: true,
        };

      case authActionTypes.CheckAuthSucces:
        return {
          ...state,
          isPending: false,
          user: action.payload,
        };

      case authActionTypes.CheckAuthError:
        return {
          ...state,
          user: action.payload,
          isPending: !state.isPending,
        };

      case authActionTypes.GetCountrySuccess:
        return {
          ...state,
          country: action.payload.data,
        };

      case authActionTypes.Register: {
        return { ...state, isPending: !state.isPending };
      }

      case authActionTypes.RegisterSuccess: {
        return { ...state, user: action.payload, isPending: false };
      }

      case authActionTypes.ResetPassword: {
        return { ...state, isPending: !state.isPending };
      }

      case authActionTypes.Logout: {
        routerHelpers.forgotLastLocation();
        removeStorage('token');
        removeStorage('refreshToken');

        return initialAuthState;
      }

      case authActionTypes.Error: {
        return { ...state, error: action.payload, isPending: false };
      }

      case authActionTypes.DeleteError: {
        return { ...state, error: null };
      }

      case authActionTypes.SignInAsUser: {
        return { ...state, isPending: !state.isPending };
      }

      case authActionTypes.SignInAsUser: {
        return { ...state, isPending: !state.isPending };
      }
      
      // case authActionTypes.GetCADjson: {
      //   return { ...state, isPending: true };
      // }
      
      case authActionTypes.RefreshCADjson: {
        return {
          ...state,
          isPending: false,
          user: {
            ...state.user,
            cadJSON: action.payload,
          } 
        };
      }

      default:
        return state;
    }
  }
);

export const authActions = {
  login: (username, password, setSubmitting, history) => ({
    type: authActionTypes.Login,
    payload: {
      username,
      password,
      setSubmitting,
      history,
    },
  }),
  loginSuccess: (payload) => ({ type: authActionTypes.LoginSuccess, payload }),
  registerUser: (newUser, history) => ({
    type: authActionTypes.Register,
    payload: { newUser, history },
  }),
  registerSuccess: (payload) => ({
    type: authActionTypes.RegisterSuccess,
    payload,
  }),
  logout: () => ({ type: authActionTypes.Logout }),
  checkAuth: (history) => ({
    type: authActionTypes.CheckAuth,
    payload: { history },
  }),
  checkAuthSuccess: (payload) => ({
    type: authActionTypes.CheckAuthSucces,
    payload,
  }),
  checkAuthError: (payload) => ({
    type: authActionTypes.CheckAuthError,
    payload,
  }),
  getCountry: () => ({ type: authActionTypes.GetCountry }),
  getCountrySuccess: (data) => ({
    type: authActionTypes.GetCountrySuccess,
    payload: data,
  }),
  pendingStatus: () => ({ type: authActionTypes.Pending }),
  requestUser: (payload) => ({ type: authActionTypes.UserRequested, payload }),
  error: (payload) => ({ type: authActionTypes.Error, payload }),
  deleteError: () => ({ type: authActionTypes.DeleteError }),
  forgotPassword: (email, history) => ({
    type: authActionTypes.ForgotPassword,
    payload: { email, history },
  }),
  updatePassword: (payload) => ({
    type: authActionTypes.UpdatePassword,
    payload,
  }),
  resetPassword: (email, token, password, history) => ({
    type: authActionTypes.ResetPassword,
    payload: { email, token, password, history },
  }),
  signInAsUser: (payload) => ({
    type: authActionTypes.SignInAsUser,
    payload,
  }),
  getCADjson: (id) => ({
    type: authActionTypes.GetCADjson,
    payload: { id },
  }),
  refreshCADjson: (payload) => ({
    type: authActionTypes.RefreshCADjson,
    payload,
  }),
  reloadCADjson: (id, structure, message) => ({
    type: authActionTypes.ReloadCADjson,
    payload: { id, structure, message },
  }),
};
