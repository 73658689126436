import React, { useState, useRef, useEffect } from 'react';
import { Menu, MenuItem, IconButton, ListItem, ListItemText, TextField, Grid, Paper, Collapse, Typography } from '@mui/material';
import { MoreVert as MoreVertIcon } from '@mui/icons-material';
import { FaRegFolder, FaRegFile } from "react-icons/fa";
import { useHistory, useParams } from "react-router-dom";
import { isNull } from 'lodash';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import { deleteFolder } from '../../../crud/partLibrary.crud'
import './index.scss';

const FileComponent = ({ file, openFolder, folderId}) => (

    <Grid item xs={6} sm={4} md={3} lg={12} key={file.id}>
        <Paper className='folder-wrapperx child-folder' style={{ margin: '10px', background: '#fff'}}>
            <ListItem button>
                <FaRegFile size={20} />
                <ListItemText className='folder-title' onClick={() => openFolder(folderId)}>
                    {file.name}
                </ListItemText>
            </ListItem>
        </Paper>
    </Grid>
);

export default function FolderComponent({ folders, openFolder, onUpdateFolder,  getFolder, createFolder, fetchFolders, fetchSingleFolder }) {
    const [menuState, setMenuState] = useState({ anchorEl: null, selectedFolder: { id: null, isEditing: false, name: '' } });
    const [openFolders, setOpenFolders] = useState({});
    const [childFolders, setChildFolders] = useState({});
    const [childFiles, setChildFiles] = useState({});
    const [loadingFolders, setLoadingFolders] = useState({});
    const [loadingFiles, setLoadingFiles] = useState({});
    const [selectedFolder, setSelectedFolder] = useState();
    const inputEditRef = useRef(null);
    const {folderId} = useParams();

    const onDeleteFolder = async (id) => {

         await deleteFolder(id);
        if (folderId === 'main') {
            await fetchFolders();
        }
        else
        {
            await fetchSingleFolder(folderId);

        }
    }

    const handleMenuOpen = (event, folderID, folderName) => {
        event.stopPropagation();
        setMenuState({
            anchorEl: event.currentTarget,
            selectedFolder: { id: folderID, isEditing: false, name: folderName }
        });
    };

    const handleMenuClose = () => {
        setMenuState(prevState => ({
            ...prevState,
            anchorEl: null,
            selectedFolder: { id: null, isEditing: false, name: '' }
        }));
    };

    const handleDelete = () => {
        onDeleteFolder(menuState.selectedFolder.id);
        handleMenuClose();
    };

    const handleRename = (name, isStart = false) => {

        setMenuState(prevState => ({
            ...prevState,
            anchorEl: isStart ? null : prevState.anchorEl,
            selectedFolder: {
                ...prevState.selectedFolder,
                isEditing: isStart || prevState.selectedFolder.isEditing,
                name: isStart ? prevState.selectedFolder.name : event?.target.value
            }
        }));
    };

    const handleKeyDown = (event, folderID) => {
        if (event.key === 'Enter') {
            handleMenuClose();
            onUpdateFolder(folderID,  menuState.selectedFolder.name);
        }
    };

    const fetchFolder = async (id) => {
        try {
            setLoadingFolders(prevState => ({ ...prevState, [id]: true }));
            
            const res = await getFolder(id);
            
            setChildFolders(prevState => ({
                ...prevState,
                [id]: res.data.childFolders || [],
            }));
            
            setChildFiles(prevState => ({
                ...prevState,
                [id]: res.data.folder[0].files || [],
            }));
        } catch (error) {
            console.error('Error fetching folder:', error);
        } finally {
            setLoadingFolders(prevState => ({ ...prevState, [id]: false }));
        }
    };

    const handleFolderClick = async (folderID) => {
        setSelectedFolder(folderID)
        setOpenFolders(prevState => ({
            ...prevState,
            [folderID]: !prevState[folderID]
        }));
        if (!openFolders[folderID] && !childFolders[folderID]) {
            await fetchFolder(folderID);
        }
    };

    useEffect(() => {
        if (menuState.selectedFolder.isEditing) {
            inputEditRef.current?.focus();
        }
    }, [menuState.selectedFolder.isEditing]);

    const renderChildFolders = (parentFolderID) => {
        const folders = childFolders[parentFolderID] || [];

        const files = childFiles[parentFolderID] || [];
        return (
            <>
                {folders.map((folder) => (
                    <Grid item xs={6} sm={4} md={3} lg={12} key={folder.id}>
                        <Paper className='folder-wrapperx child-folder' style={{ margin: '10px' }}>
                            <ListItem button onClick={() => handleFolderClick(folder.id)}>
                                <FaRegFolder size={20} />
                                <ListItemText className='folder-title'>
                                    {menuState.selectedFolder.id === folder.id && menuState.selectedFolder.isEditing ? (
                                        <TextField
                                            inputRef={inputEditRef}
                                            placeholder='Folder name'
                                            value={menuState.selectedFolder.name}
                                            onClick={event => event.stopPropagation()}
                                            onChange={handleRename}
                                            onBlur={() => {
                                                handleMenuClose();
                                                onUpdateFolder(folder.id, menuState.selectedFolder.name);
                                            }}
                                            onKeyDown={event => handleKeyDown(event, folder.id)}
                                            onFocusCapture={() => {alert("ddd")}}
                                            variant="standard"
                                            size="small"
                                            fullWidth
                                        />
                                    ) : (
                                        folder.name
                                    )}
                                </ListItemText>
                                <div className='actions'>
                                    <IconButton className='action-icon'>
                                        <DriveFileMoveIcon />
                                    </IconButton>
                                    <IconButton onClick={event => handleMenuOpen(event, folder.id, folder.name)} className='action-icon'>
                                        <MoreVertIcon />
                                    </IconButton>
                                </div>
                            </ListItem>
                            <Menu
                                id="folder-menu"
                                anchorEl={menuState.anchorEl}
                                open={!isNull(menuState.anchorEl)}
                                onClose={handleMenuClose}
                                MenuListProps={{ 'aria-labelledby': 'folder-menu-button' }}
                            >
                                <MenuItem onClick={() => handleRename(null, true)}>Rename</MenuItem>
                                <MenuItem onClick={handleDelete}>Delete</MenuItem>
                            </Menu>
                            <Collapse in={openFolders[folder.id]} timeout="auto" unmountOnExit>
                                {renderChildFolders(folder.id)}
                            </Collapse>
                        </Paper>
                    </Grid>
                ))}
                {files.map((file) => (
                    <FileComponent key={file.id} file={file} openFolder={openFolder}  folderId={selectedFolder}/>
                ))}
                {folders.length === 0 && files.length === 0 && (
                    <Typography variant="body2" className="empty-message">
                        No folders or files found.
                    </Typography>
                )}
            </>
        );
    };

    return (
        <Grid container spacing={0} className='folder-section'>
            {folders.map((folder) => (
                <Grid item  key={folder.id} style={{
                    width: '250px'
                }}>
                    <Paper className='folder-wrapperx'>
                    

<ListItem 
    button
    onClick={() => openFolder(folder.id)}
>
    <FaRegFolder size={20} />
    <ListItemText className='folder-title'>
        {menuState.selectedFolder.id === folder.id && menuState.selectedFolder.isEditing ? (
            <TextField
                inputRef={inputEditRef}
                placeholder='Folder name'
                value={menuState.selectedFolder.name}
                onClick={event => event.stopPropagation()}
                onChange={(e) => handleRename(e.target.value)}
                onBlur={() => {
                    handleMenuClose();
                    onUpdateFolder(folder.id,  menuState.selectedFolder.name);
                }}
                onKeyDown={event => handleKeyDown(event, folder.id)}
                variant="standard"
                size="small"
                fullWidth
            />
        ) : (
            folder.name
        )}
    </ListItemText>
    <div className='actions'>
        <IconButton 
            className='action-icon' 
            onClick={(e) => {
                e.stopPropagation();
                handleFolderClick(folder.id);
            }}
        >
            <DriveFileMoveIcon />
        </IconButton>
        <IconButton 
            onClick={(e) => {
                e.stopPropagation();
                handleMenuOpen(e, folder.id, folder.name);
            }} 
            className='action-icon'
        >
            <MoreVertIcon />
        </IconButton>
    </div>
</ListItem>
                        <Collapse in={openFolders[folder.id]} timeout="auto" unmountOnExit>
                            {renderChildFolders(folder.id)}
                        </Collapse>
                    </Paper>
                </Grid>
            ))}
            <Menu
                id="folder-menu"
                anchorEl={menuState.anchorEl}
                open={!isNull(menuState.anchorEl)}
                onClose={handleMenuClose}
                MenuListProps={{ 'aria-labelledby': 'folder-menu-button' }}
            >
                <MenuItem onClick={() => handleRename(null, true)}>Rename</MenuItem>
                <MenuItem onClick={handleDelete}>Delete</MenuItem>
            </Menu>
        </Grid>
    );
}
