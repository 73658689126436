import { createAction } from 'redux-actions';

export const getShippingCosts = createAction('GET_SHIPPING_COSTS_REQUEST');
export const getShippingCostsSuccess = createAction('GET_SHIPPING_COSTS_SUCCESS');
export const getShippingCostsError = createAction('GET_SHIPPING_COSTS_ERROR');
export const getShippingCost = createAction('GET_SHIPPING_COST_REQUEST');
export const getShippingCostSuccess = createAction('GET_SHIPPING_COST_SUCCESS');
export const getShippingCostError = createAction('GET_SHIPPING_COST_ERROR');
export const deleteShippingCost = createAction('DELETE_SHIPPING_COST_REQUEST');
export const deleteShippingCostSuccess = createAction('DELETE_SHIPPING_COST_SUCCESS');
export const deleteShippingCostError = createAction('DELETE_SHIPPING_COST_ERROR');
export const updateShippingCost = createAction('UPDATE_SHIPPING_COST_REQUEST');
export const updateShippingCostSuccess = createAction('UPDATE_SHIPPING_COST_SUCCESS');
export const updateShippingCostError = createAction('UPDATE_SHIPPING_COST_ERROR');
