/**
 * Entry application component used to compose providers and render Routes.
 * */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { LastLocationProvider } from 'react-router-last-location';
import { ToastContainer } from 'react-toastify';
import { Routes } from './app/router/Routes';
import { I18nProvider, LayoutSplashScreen, ThemeProvider } from './_metronic';
import 'react-toastify/dist/ReactToastify.css';

import { passiveSupport } from 'passive-events-support/src/utils';
export default function App({ store, persistor, basename }) {
  const [country, setCountry] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://ipapi.co/json/');
        const { country } = response.data;

        setCountry(country);
      } catch (error) {
        console.error('Error fetching IP information:', error);
      }
    };

    fetchData();

    passiveSupport({
      events: ['touchstart', 'touchmove', 'wheel'],
      element: 'window',
    });
  }, []);

  return country === 'IN' ? (
    (window.location.href = 'https://www.aajtak.in/')
  ) : (
    /* Provide Redux store */
    <Provider store={store}>
      {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
      <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
        {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
        <React.Suspense fallback={<LayoutSplashScreen />}>
          {/* Override `basename` (e.g: `homepage` in `package.json`) */}
          <BrowserRouter basename={basename}>
            {/*This library only returns the location that has been active before 
            the recent location change in the current window lifetime.*/}
            <LastLocationProvider>
              {/* Provide Metronic theme overrides. */}
              <ThemeProvider>
                <ToastContainer />
                {/* Provide `react-intl` context synchronized with Redux state.  */}
                <I18nProvider>
                  {/* Render routes with provided `Layout`. */}
                  <Routes />
                </I18nProvider>
              </ThemeProvider>
            </LastLocationProvider>
          </BrowserRouter>
        </React.Suspense>
      </PersistGate>
    </Provider>
  );
}

App.propTypes = {
  store: PropTypes.object,
  persistor: PropTypes.object,
  basename: PropTypes.string,
};
