import React, { forwardRef, useState, useImperativeHandle } from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal } from 'react-bootstrap';

const ToleranceTableModal = forwardRef(({}, ref) => {
  const [modalVisibility, setModalVisibility] = useState(false);

  useImperativeHandle(ref, () => ({
    open: () => {
      setModalVisibility(true);
    },
  }));

  return (
    <Modal
      show={modalVisibility}
      onHide={() => {
        setModalVisibility(false);
      }}
      size="lg"
      centered
    >
      <Modal.Header closeButton> </Modal.Header>
      <Modal.Body
        style={{
          height: '62vh',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          fontSize: 14,
          overflow: 'scroll',
        }}
      >
        <FormattedMessage id="TOLERANCE.MODAL.DESC_1" />
        <br />
        <br />
        <FormattedMessage id="TOLERANCE.MODAL.DESC_2" />
        <br />
        <br />
        <FormattedMessage id="TOLERANCE.MODAL.DESC_3" />
        <br />
        <br />
        <FormattedMessage id="TOLERANCE.MODAL.DESC_4" />
        <br />

        <table className="table table-striped w-75">
          <thead>
            <tr>
              <th scope="col" className={'w-75'}>
                <FormattedMessage id="TOLERANCE.MODAL.TABLE.HEAD" />
              </th>
              <th scope="col" className={'w-25'}>
                +/-
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <FormattedMessage id="TOLERANCE.MODAL.TABLE.0.5_UP_TO_3" />
              </td>
              <td>0.3</td>
            </tr>
            <tr>
              <td>
                <FormattedMessage id="TOLERANCE.MODAL.TABLE.3_UP_TO_6" />
              </td>
              <td>0.3</td>
            </tr>
            <tr>
              <td>
                <FormattedMessage id="TOLERANCE.MODAL.TABLE.6_UP_TO_30" />
              </td>
              <td>0.3</td>
            </tr>
            <tr>
              <td>
                <FormattedMessage id="TOLERANCE.MODAL.TABLE.30_UP_TO_120" />
              </td>
              <td>0.4</td>
            </tr>
            <tr>
              <td>
                <FormattedMessage id="TOLERANCE.MODAL.TABLE.120_UP_TO_400" />
              </td>
              <td>0.5</td>
            </tr>
            <tr>
              <td>
                <FormattedMessage id="TOLERANCE.MODAL.TABLE.400_UP_TO_1000" />
              </td>
              <td>0.8</td>
            </tr>
            <tr>
              <td>
                <FormattedMessage id="TOLERANCE.MODAL.TABLE.1000_UP_TO_2000" />
              </td>
              <td>1</td>
            </tr>
            <tr>
              <td>
                <FormattedMessage id="TOLERANCE.MODAL.TABLE.2000_UP_TO_4000" />
              </td>
              <td>1.2</td>
            </tr>
          </tbody>
        </table>
      </Modal.Body>
    </Modal>
  );
});

export default ToleranceTableModal;
