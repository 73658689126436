const initialState = {
         folder: {
             id: 1,
             name: "folder 1",
             parentId: 1,
         }
}

export const PART_LIBRARY_ACTIONS = {
    GET_FOLDERS_REQUEST: 'GET_FOLDERS_REQUEST',
    FOLDER_DATA_SUCCESS: 'FOLDER_DATA_SUCCESS',
}


export const folderReducer = (state = initialState, action) => {
switch (action.type) {
    case PART_LIBRARY_ACTIONS.GET_FOLDERS_REQUEST:
        console.log({...state}, 8888888)
        return {
            ...state,
        };
    case PART_LIBRARY_ACTIONS.FOLDER_DATA_SUCCESS:
        console.log({...state}, 1111111111111)
        return  {
            ...state,
            isProcessing: false,
            data: action.payload.folders
        }

    default:
        return  state
  }
}

export const partLibraryActions = {
    getFoldersRequest: () => ({
        type: PART_LIBRARY_ACTIONS.GET_FOLDERS_REQUEST}),

    foldersDataSuccess: (payload) =>  ({
        type: PART_LIBRARY_ACTIONS.FOLDER_DATA_SUCCESS,
        payload
    }),
}
