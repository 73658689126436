// import React from "react";
import utils from "svg-path-reverse"
import {pointMatchingDistance} from "./Config";

// var reverse = utils.reverse, normalize = utils.normalize;


class Chainer {
    chains = [];

    constructor(heap) {
        while (heap.length) {
            const {id, path, startPoint, endPoint} = heap.shift();
            const ordered = {
                    paths: [{d: path, startPoint, endPoint, id}],
                closed: this.matchPoints(startPoint, endPoint),
                endPoint
            };

            this.findNext(ordered, heap, startPoint, endPoint);
            this.chains.push(ordered);
        }

    }

    matchPoints(p1, p2) {
        const y = p2.x - p1.x;
        const x = p2.y - p1.y;

        return Math.hypot(x, y) <= pointMatchingDistance;
    }

    reverse(path) {
        return utils.reverse(path);
    }

    getNext(heap, idx, reverse) {
        const add = heap.splice(idx, 1)[0];

        if (reverse) {
            return {
                ...add,
                path: this.reverse(add.path),
                startPoint: add.endPoint,
                endPoint: add.startPoint
            };
        } else {
            return {...add};
        }
    }

    addNext(ordered, nextWay) {
        const {id, path, startPoint, endPoint} = nextWay;

        ordered.paths.push({d: path, startPoint, endPoint, id});
        ordered.endPoint = endPoint;

        return endPoint;
    }

    addPrev(ordered, prevWay) {
        const {id, path, startPoint, endPoint} = prevWay;

        ordered.paths.unshift({d: path, startPoint, endPoint, id});

        return startPoint;
    }

    findNext(ordered, heap, startPoint, endPoint) {
        let next, prev, reverseNext, reversePrev;

        for (const [idx, way] of heap.entries()) {
            if (this.matchPoints(way.startPoint, endPoint)) {
                next = idx;
                break;
            }
            if (this.matchPoints(way.endPoint, startPoint)) prev = idx;
            if (this.matchPoints(way.endPoint, endPoint)) reverseNext = idx;
            if (this.matchPoints(way.startPoint, startPoint)) reversePrev = idx;
        }

        if (next !== undefined) {
            endPoint = this.addNext(ordered, this.getNext(heap, next, false));

        } else if (prev !== undefined) {
            startPoint = this.addPrev(ordered, this.getNext(heap, prev, false));

        } else if (reverseNext !== undefined) {
            endPoint = this.addNext(ordered, this.getNext(heap, reverseNext, true));

        } else if (reversePrev !== undefined) {
            startPoint = this.addPrev(ordered, this.getNext(heap, reversePrev, true));

        } else return;

        if (this.matchPoints(startPoint, endPoint)) {
            ordered.closed = true;
            return;
        }

        this.findNext(ordered, heap, startPoint, endPoint);
    }

    writePaths() {

        return this.chains.map(ordered => {
            let mergedPath;

            ordered.paths.forEach(({d, id}) => {

                if (mergedPath && mergedPath.d) {
                    mergedPath.d += d.replace("M", "L");
                } else {
                    mergedPath = {d, id};
                }
            });

            if (ordered.closed) mergedPath.d += "Z";

            return mergedPath;
        });
    }
}

export default Chainer;
