import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { deleteMessage, getMessages } from "../../../../store/messages/actions";

const DeleteModal = forwardRef(({}, ref) => {
  const dispatch = useDispatch();
  const [item, setItem] = useState(null);

  useImperativeHandle(ref, () => ({
    open: (item) => {
      setItem(item);
    },
  }));

  const handleCloseDialog = () => setItem(null);

  const handleDelete = () => {
    dispatch(
      deleteMessage({
        id: item.id,
        cb: ({ status }) => {
          if (status === "error") return;
          dispatch(getMessages());
          handleCloseDialog();
        },
      })
    );
  };

  return (
    <Modal show={!!item} onHide={handleCloseDialog} size="md" centered>
      <Modal.Header closeButton>
        <Modal.Title>Delete item</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Confirm that you would to delete the selected item.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseDialog}>
          Cancel
        </Button>
        <Button onClick={handleDelete}>Delete</Button>
      </Modal.Footer>
    </Modal>
  );
});

export default DeleteModal;
