import React, { useState } from 'react';
import MaterialTable from '@material-table/core';
import initApi from '../../../crud/apiConfig/apiConfig';
import { API_URLS } from '../../../common/constants/api/apiConstants';
import { DEFAULT_DATE_FORMAT } from '../../../common/constants/constants';

import moment from 'moment/moment';
import { injectIntl } from 'react-intl';

const requestOptions = {
  params: {
    include:
      'project.products,shipping_address.country,billing_address.country,payment_status,user',
  },
};

function MasterPage(props) {
  const { intl, history } = props;

  const [pageSize, setPageSize] = useState(10);
  // const [count, setCount] = useState(0);
  // const [total, setTotal] = useState(0);
  // const [perPage, setPerPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const columns = [
    {
      title: intl.formatMessage({ id: 'CONCEPTS.CONCEPT' }),
      field: 'project.id',
      render: (rowData) => 'Concept ' + rowData.id,
    },
    {
      title: intl.formatMessage({ id: 'QUOTE.DATE' }),
      field: 'created_at',
      render: (rowData) =>
        moment.unix(rowData.created_at).format(DEFAULT_DATE_FORMAT),
    },
    {
      title: intl.formatMessage({ id: 'QUOTE.CUSTOMER' }),
      field: 'shipping_address',
      render: (rowData) => {
        let customer = '';
        if (rowData.shipping_address) {
          if (rowData.shipping_address.company_name) {
            customer = rowData.shipping_address.company_name;
          } else if (rowData.shipping_address.first_name) {
            customer =
              rowData.shipping_address.first_name +
              ' ' +
              rowData.shipping_address.last_name;
          }
        }
        return customer;
      },
    },

    {
      title: intl.formatMessage({ id: 'User email' }),
      field: 'user.email',
    },
    {
      title: intl.formatMessage({ id: 'QUOTE.PARTS' }),
      field: 'project.products.length',
      render: (rowData) => {
        return rowData.project.products.length;
      },
    },
    {
      title: intl.formatMessage({ id: 'Total' }),
      field: 'project.products',
      render: (rowData) => '€ ' + rowData.project.price.toFixed(2),
    },
  ];

  const getConcepts = async (query) => {
    let params = {};
    if (query) {
      params = { search: query.search, page: query.page + 1 };
    }

    let options = {
      params: {
        ...requestOptions.params,
        ...params,
      },
    };

    let { data } = await initApi().get(API_URLS.GET_CONCEPTS_DATA, options);

    let { /* count, total, per_page, */ current_page } = data.meta.pagination;

    // setCount(count);
    // setTotal(total);
    // setPerPage(per_page);
    setCurrentPage(current_page);

    let tableData = {
      data: data.quotes,
      totalCount: data.meta.pagination.total,
      page: data.meta.pagination.current_page - 1,
    };

    return new Promise((resolve) => {
      resolve(tableData);
    });
  };

  function navigateTo({ id }) {
    history.push(`/quotes/details/${id}`);
  }

  const changePage = (page) => {
    setCurrentPage(page);
  };

  const changePerPage = (per_page) => {
    setPageSize(per_page);
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="kt-section">
            <div className="kt-section__content">
              <MaterialTable
                localization={{
                  toolbar: {
                    searchPlaceholder: intl.formatMessage({ id: 'search' }),
                  },
                  header: {
                    actions: intl.formatMessage({ id: 'QUOTES.ACTIONS' }),
                  },
                  body: {
                    emptyDataSourceMessage: intl.formatMessage({
                      id: 'no_records_to_display',
                    }),
                  },
                }}
                title="Concepts"
                onRowClick={(evt, selectedRow) => navigateTo(selectedRow)}
                columns={columns}
                data={getConcepts}
                page={currentPage}
                totalCount={0 /* total */}
                onPageChange={changePage}
                onChangeRowsPerPage={changePerPage}
                options={{
                  pageSize: pageSize,
                  actionsColumnIndex: -1,
                  search: true,
                  // padding: 'dense',
                  headerStyle: {
                    color: '#D80000',
                    fontSize: 13,
                  },
                  body: {
                    fontSize: 13,
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default injectIntl(MasterPage);
