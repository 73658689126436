import { call, put, takeEvery } from 'redux-saga/effects';

import {
  getUserQuery,
  updateUserQuery,
  getUsersQuery,
  deleteUserQuery,
} from './crud';
import {
  getUsers,
  getUsersError,
  getUsersSuccess,
  updateUser,
  updateUserSuccess,
  updateUserError,
  deleteUser,
  deleteUserSuccess,
  deleteUserError,
  getUser,
  getUserError,
  getUserSuccess,
} from './actions';

function* fetchUsersSaga({ payload }) {
  try {
    const res = yield call(getUsersQuery, payload);

    yield put(getUsersSuccess(res.data));
  } catch (err) {
    yield put(getUsersError(err));
  }
}

function* fetchUserSaga({ payload }) {
  try {
    const { successCallBack = () => {} } = payload;

    const res = yield call(getUserQuery, payload);

    yield put(
      getUserSuccess({
        ...res.data,
        created_at: res.data.created_at,
      })
    );

    yield successCallBack(res.data.type);
  } catch (err) {
    yield put(getUserError(err.response));
  }
}

function* updateUserSaga({ payload }) {
  try {
    const { successCallBack = () => {}, id, data } = payload;

    const res = yield call(updateUserQuery, { id, data });

    yield put(
      updateUserSuccess({
        ...res.data,
        created_at: res.data.created_at,
      })
    );

    yield successCallBack(res.data.type);
  } catch (err) {
    yield put(updateUserError(err.response));
  }
}

function* deleteUserSaga({ payload }) {
  try {
    const { successCallBack = () => {}, id } = payload;

    const res = yield call(deleteUserQuery, id);

    yield put(
      deleteUserSuccess({
        ...res.data,
        created_at: res.data.created_at,
      })
    );

    yield successCallBack(res.data.type);
  } catch (err) {
    yield put(deleteUserError(err.response));
  }
}

function* threadsSagas() {
  yield takeEvery(getUsers, fetchUsersSaga);
  yield takeEvery(getUser, fetchUserSaga);
  yield takeEvery(updateUser, updateUserSaga);
  yield takeEvery(deleteUser, deleteUserSaga);
}

export default threadsSagas;
