import React, { useState, useEffect } from 'react';
import moment from 'moment';
import initApi from '../../../../crud/apiConfig/apiConfig';
// import { useIntl } from 'react-intl';
import { PortletBody } from '../../../../partials/content/Portlet';
import { Table } from 'react-bootstrap';
import {
  API_URL,
  API_URLS,
} from '../../../../common/constants/api/apiConstants';

export const History = () => {
  // const intl = useIntl();
  const [historyData, setHistoryData] = useState(undefined);

  useEffect(() => {
    getHistory();
  }, []);

  const getHistory = async () => {
    let historyUrl = API_URL + API_URLS.IMPORT_EXPORT_HISTORY;
    let historyResult = await initApi().get(historyUrl);

    historyResult.data.finishedAt = historyResult.data.finishedAt
      ? new Date(historyResult.data.finishedAt)
      : undefined;
    historyResult.data.startedAt = historyResult.data.startedAt
      ? new Date(historyResult.data.startedAt)
      : undefined;
    setHistoryData(historyResult.data);
  };

  return (
    <PortletBody>
      {historyData && (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Naam</th>
              <th>CSV</th>
              <th>Optie</th>
              <th>Type</th>
              <th>Verwerkt</th>
              <th>Starttijd</th>
              <th>Eindtijd</th>
              <th>Afgerond</th>
            </tr>
          </thead>
          <tbody>
            {historyData.map((item, index) => {
              return (
                <tr key={{ index }}>
                  <td>{item.name}</td>
                  <td>{item.csvFile}</td>
                  <td>{item.importOption}</td>
                  <td>{item.importType}</td>
                  <td>{item.processed}</td>
                  <td>
                    {item.startedAt
                      ? moment(item.startedAt).format('DD-MM-YYYY HH:mm')
                      : ''}
                  </td>
                  <td>
                    {item.finishedAt
                      ? moment(item.finishedAt).format('DD-MM-YYYY HH:mm')
                      : ''}
                  </td>
                  <td>
                    {item.finished ? (
                      <i className="fa fa-check" aria-hidden="true"></i>
                    ) : (
                      <i className="fa fa-times" aria-hidden="true"></i>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
    </PortletBody>
  );
};
