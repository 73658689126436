import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import GetAppIcon from '@mui/icons-material/GetApp';

import { toAbsoluteUrl } from '../../../../_metronic/utils/utils';

const PDFDownloader = ({ pdfUrl, pdfFileName, title, isItemBlock = false }) => {
  const downloadLinkContent = isItemBlock ? (
    <>
      <strong>
        <FormattedMessage id={title} />
      </strong>
      <button
        type="button"
        className="btn btn-bold btn-outline-brand"
      >
        <img src={toAbsoluteUrl('/media/icons/pdf-icon.svg')} alt="pdf-icon" />
        <img src={toAbsoluteUrl('/media/icons/download.svg')} alt="download-icon" />
      </button>
    </>
  ) : (
    <>
      <img
        src={toAbsoluteUrl('/clients-logos/pdf-icon.svg')}
        alt="pdf-icon"
        width="45px"
        height="45px"
      />
      {title}
      <GetAppIcon htmlColor="#ff0000" style={{ fontSize: '38px' }} />
    </>
  );

  return (
    <div className={isItemBlock ? "item-block__download-pdf" : "download-pdf-block gray-bg"}>
      <a
        href={pdfUrl}
        target="_blank"
        rel="noopener noreferrer"
        download={pdfFileName}
      >
        {downloadLinkContent}
      </a>
    </div>
  );
};

export default injectIntl(PDFDownloader);
