import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Form, Col, Button } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import { useHistory, useParams } from 'react-router-dom';
import { Paper } from "@material-ui/core";
import { updateThread, getThread } from "../../../store/threads/actions";
import { ROUTES } from "../../../common/constants/routes/routes";

const DetailsPage = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { data, isProcessing, } = props;
  const [form, setForm] = useState({});
  const [validated, setValidated] = useState(false);
  const fields = [
      { title: 'Hole', name: 'hole', type: 'float', required: true },
      { title: 'Units', name: 'units', type: 'text', required: true },
      { title: 'mm', name: 'mm', type: 'integer', required: true },
  ]

  useEffect(() => {
    if (id) {
      dispatch(getThread(id))
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (data && id) {
      setForm(data);
    } else {
      setForm({})
    }
  }, [data]); // eslint-disable-line

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);

    const payload = {};
    fields.forEach(field => {
      switch (field.type) {
        case 'integer':
          payload[field.name] = parseInt(form[field.name]);
          break;
        case 'float':
          payload[field.name] = parseFloat(form[field.name]);
          break;
        default:
          payload[field.name] = form[field.name];
      }
    })

    dispatch(updateThread({id: id, data: payload, successCallBack: () => {
      history.push(ROUTES.THREADS);
    }}));
  }

  const handleCancel = () => {
    history.push(ROUTES.THREADS);
  }

  const onChange = field => event => {
    setForm({...form, [field]: event.target.value});
  }

  return isProcessing ? 'Loading...' : (
    <div className="row">
      <div className="col-md-12">
        <div className="kt-section">
          <div className="kt-section__content">
            <Paper className="p-5">
              <h1 className="kt-invoice__title mb-5">Thread {id ? 'Edit' : 'Add'}</h1>

              <Form
                noValidate
                validated={validated}
                onSubmit={handleSubmit}
              >
                  {fields.map(field => (
                    <Form.Group key={field.name} as={Row} controlId="">
                      <Form.Label column sm={2}>{field.title}</Form.Label>
                      <Col sm={10}>
                        <Form.Control
                          type="text"
                          value={form[field.name] || ''}
                          onChange={onChange(field.name)}
                          required={field.required}
                        />
                      </Col>
                    </Form.Group>
                  ))}
                <Row>
                  <Col sm={12}>
                    <Button className="pull-right" type="submit" >Save </Button>
                    <Button className="pull-right mr-2" variant="light" onClick={handleCancel}>Cancel</Button>
                  </Col>
                </Row>
              </Form>
            </Paper>
          </div>
        </div>
      </div>
    </div>
  );
}

DetailsPage.propTypes = {
  isProcessing: PropTypes.bool.isRequired,
  data: PropTypes.object,
};

const mapStateToProps = ({ threads: { detailedThread: { data, isProcessing } } }) => ({
  data,
  isProcessing,
});

export default connect(mapStateToProps)(DetailsPage);
