import React, { useState, useEffect } from 'react';
import CheckBox from "../../checkBox";
import SearchBar from "./searchBar";
import { FormattedMessage } from "react-intl";
import { SlBasketLoaded } from "react-icons/sl";
import { FaRegTrashAlt } from "react-icons/fa";
import { deleteFile } from "../../../crud/partLibrary.crud";
import { useParams } from 'react-router-dom';
import { Button as BootstrapButton } from "react-bootstrap";
import Tooltip from '@mui/material/Tooltip';
import { Modal, Button } from 'react-bootstrap';
import MaterialSelector from '../../../pages/home/mypartlibrary/fileComponent/materialSelector';
import FilterModal from './filterModal';
export default function ActionBar({ actionBarState, fileContents, setActionBarState, fetchFolder, fetchFolders, openBasketModal, fileList, currentFolder, dxfUpload, addBasket, searchTerm, setSearchTerm, choiceData }) {
    const { folderId } = useParams();
    
    const [specifyModalVisibility, setSpecifyModalVisibility] = useState(false);
    const [selectedChoice, setSelectedChoice] = useState(null);

    const handleCheckboxChange = (e) => {
        const fileIds = fileList.map(file => (file.id));
        let count = 0;
        fileList.forEach(file => {
            if (file.material) {
                count += 1;
            }
        });
        setActionBarState((prevState) => ({
            ...prevState,
            checkbox: { checked: !prevState.checkbox.checked },
            selectedFiles: !prevState.checkbox.checked ? fileIds : [],
            specified: !prevState.checkbox.checked ? 0 : count,
        }));
    };

    const fileSpecifyModal = (event, index) => {
        event.stopPropagation();

        updateSpecifyModalVisibility(true);

    };

    const updateSpecifyModalVisibility = (show) => {
        setSpecifyModalVisibility(show);
    };


    const handleChoiceSelection = (choice) => {
        setSelectedChoice(choice);
    };

    const toConfigurator = function () {
        const filteredFiles = fileList.filter(file => actionBarState.selectedFiles.includes(file.id));
        dxfUpload(filteredFiles);
    };

    const dltFile_s = async (ids) => {
        if (!ids || ids.length === 0) {
            return;
        }
        try {
            const deletePromises = ids.map(async (id) => {
                await deleteFile(id);
                if (folderId === 'main') {
                    await fetchFolders();
                } else {
                    await fetchFolder(folderId);
                }
            });
            await Promise.all(deletePromises);
            setActionBarState(prevState => ({
                ...prevState,
                checkbox: { checked: false },
                selectedFiles: [],
                basket: prevState.basket.filter((id) => !ids.includes(id))
            }));
            setCheck(false);
        } catch (error) {
            console.error('Error deleting files:', error);
        }
    };


    const filesObject = {};
    fileList.forEach((f) => (filesObject[f.id] = f));

    const selectedFiles = Array.isArray(actionBarState.selectedFiles) ? actionBarState.selectedFiles : [];
    const basket = Array.isArray(actionBarState.basket) ? actionBarState.basket : [];

    const filteredBasket = [
        ...selectedFiles,
        ...basket,
    ].filter((id, index, array) => {
        return filesObject[id]?.material && array.indexOf(id) === index;
    });

    const filteredBasketFiles = fileContents.filter((file) => filteredBasket.includes(file.id));


    return (
        <>
            <h2 className="mypartlibrary-title">
                <FormattedMessage id="CAD.LIBRARY.TITLE" /> | {" "}
                { (folderId === "main") ? "MAIN" : currentFolder?.name}
            </h2>
            <div className="mypartlibrary-actionRow">
                <div className="mypartlibrary-actionRow-forSelected">
                    <CheckBox
                        checked={actionBarState.checkbox.checked}
                        indeterminate={actionBarState.checkbox.indeterminate}
                        onClick={(e) => handleCheckboxChange(e)}
                    />
                    <span>
                        {actionBarState.selectedFiles.length}{' '}
                        <FormattedMessage id="Geselecteerd" />{' '}
                    </span>
                    {
                        actionBarState.selectedFiles.length ?
                            <>
                                <BootstrapButton style={{ fontSize: '10px' }} onClick={() => toConfigurator()}>
                                    <FormattedMessage id="CAD.LIBRARY.GO_CHECKOUT" />
                                </BootstrapButton>
                                <Tooltip title={(actionBarState.specified) ? "Only the specified part will be added to the basket" : "Please select the specified parts"}
                                    arrow placement="top" >
                                    <BootstrapButton style={{ fontSize: '10px', marginLeft: '10px' }} onClick={() => addBasket(filteredBasket, filteredBasketFiles)} >
                                        <FormattedMessage id="CAD.LIBRARY.BASKET_ADD" />
                                    </BootstrapButton>
                                </Tooltip>
                            </>
                            : ''
                    }


                    <header id="header-shop">
                        <section id="cart">
                            <div id="bcount">

                                <button className="mypartlibrary-actionRow-basket" onClick={() => openBasketModal()}>
                        <div className="mypartlibrary-actionRow-orderCount">
                            {actionBarState.basket.length}
                        </div>
                                    <SlBasketLoaded className="actionIcons " />

                    </button>
                            </div>
                            <ul />
                        </section>
                    </header>



                    {/* <FaRegTrashAlt className="actionIcons" onClick={() => dltFile_s(actionBarState.selectedFiles)} /> */}
                </div>
                <SearchBar onSearch={setSearchTerm} fileSpecifyModal={fileSpecifyModal} />
            </div>
          
{/*           
            <Modal
                className="cad-modal"
                show={specifyModalVisibility}
                onHide={() => updateSpecifyModalVisibility(false    )}
                size={'md'}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <FormattedMessage id="CAD.LIBRARY.SPECIFY_PART"/>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormattedMessage id="CAD.LIBRARY.SPECIFY_DETAILS" />
                    <MaterialSelector
                        // svgWidth={Number(fileContents[specifyIndex]?.svgWidth.replace('mm', ''))}
                        // svgHeight={Number(fileContents[specifyIndex]?.svgHeight.replace('mm', ''))}
                        onChoiceSelect={handleChoiceSelection}
                        choiceData={choiceData}
                    />
                    <FormattedMessage id="CAD.LIBRARY.CALC_PRICE_TEXT" />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant={'secondary'}
                        onClick={() => updateSpecifyModalVisibility(false)}
                    >
                        <FormattedMessage id="CANCEL" />
                    </Button>
                    <Button
                      
                    >
                        <FormattedMessage id="SAVE" />
                    </Button>
                </Modal.Footer>
            </Modal> */}

            <FilterModal
             specifyModalVisibility={specifyModalVisibility}
             updateSpecifyModalVisibility={updateSpecifyModalVisibility}
             handleChoiceSelection={handleChoiceSelection}
             choiceData={choiceData}
             />
        </>
    );
}
