import { API_URLS } from '../../common/constants/api/apiConstants';
import initApi from '../../crud/apiConfig/apiConfig';

export function getCountriesQuery() {
  return initApi().get(API_URLS.GET_COUNTRIES);
}

export const getCountryQuery = id => {
  return initApi().get(API_URLS.GET_COUNTRY(id));
};

export const deleteCountryQuery = id => {
  return initApi().delete(API_URLS.GET_COUNTRY(id));
};

export const updateCountryQuery = ({ id, data }) => {
  if (id) {
    return initApi().put(API_URLS.GET_COUNTRY(id), data);
  } else {
    return initApi().post(API_URLS.GET_COUNTRIES, data);
  }
};
