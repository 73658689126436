import {API_URLS} from '../../common/constants/api/apiConstants';
import initApi from '../../crud/apiConfig/apiConfig';

export function getMaterialsQuery() {
    return initApi().get(API_URLS.GET_MATERIALS_DATA);
}

export const getMaterialQuery = id => {
    return initApi().get(API_URLS.GET_MATERIAL_DETAILED(id));
};

export const deleteMaterialQuery = id => {
    return initApi().delete(API_URLS.GET_MATERIAL_DETAILED(id));
};

export const updateMaterialQuery = ({id, data}) => {
    if (id) {
        return initApi().put(API_URLS.GET_MATERIAL_DETAILED(id), data);
    } else {
        return initApi().post(API_URLS.GET_MATERIALS_DATA, data);
    }
};
