import React from "react";
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';

class MenuItemText extends React.Component {
  render() {
    const { item, parentItem, user } = this.props;

    return (
      <>
        {item.icon && <i className={`kt-menu__link-icon ${item.icon}`} />}

        {parentItem && parentItem.bullet === "dot" && (
          <i className="kt-menu__link-bullet kt-menu__link-bullet--dot">
            <span />
          </i>
        )}

        {parentItem && parentItem.bullet === "line" && (
          <i className="kt-menu__link-bullet kt-menu__link-bullet--line">
            <span />
          </i>
        )}

        <span className="kt-menu__link-text" style={{ whiteSpace: "nowrap" }}>
          {!item.translate ? (
            item.title
          ) : (
            <FormattedMessage id={item.translate} defaultMessage={item.title} />
          )}
        </span>

        {item.badge && (
          <span className="kt-menu__link-badge">
            <span className={`kt-badge ${item.badge.type}`}>
              {item.badge.value}
            </span>

            {user && <span style={{ color: "#a1a8c3", fontSize: "11px", fontWeight: '500', letterSpacing: '0.3px' }}>(BETA)</span>}

            {/* 
              <div className='ribbon-wrapper'>
                <div className='ribbon'>BETA</div>
              </div> 
            */}
          </span>
        )}

        {item.submenu && <i className="kt-menu__ver-arrow la la-angle-right" />}
      </>
    );
  }
}

const mapStateToProps = ({ auth: { user } }) => ({ user });

export default injectIntl(connect(mapStateToProps)(MenuItemText));
