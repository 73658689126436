import React from 'react';
import { Route, Switch } from 'react-router-dom';
import MasterPage from './MasterPage';
import FormPage from './FormPage';
import { ROUTES } from '../../../common/constants/routes/routes';

export default function Bending() {
  return (
    <Switch>
      <Route exact path={ROUTES.BENDING} component={MasterPage} />
      <Route exact path={`${ROUTES.BENDING}/create`} component={FormPage} />
      <Route exact path={`${ROUTES.BENDING}/edit/:id`} component={FormPage} />
    </Switch>
  );
}
