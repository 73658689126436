import React from 'react';
import { useSelector } from 'react-redux';
import { IntlProvider } from 'react-intl';
import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/dist/locale-data/en';
import '@formatjs/intl-relativetimeformat/dist/locale-data/de';
import '@formatjs/intl-relativetimeformat/dist/locale-data/es';
import '@formatjs/intl-relativetimeformat/dist/locale-data/fr';
import '@formatjs/intl-relativetimeformat/dist/locale-data/ja';
import '@formatjs/intl-relativetimeformat/dist/locale-data/zh';

import nlMessages from './messages/nl.json';
import deMessages from './messages/de.json';
import enMessages from './messages/en.json';

const allMessages = {
  de: deMessages,
  nl: nlMessages,
  en: enMessages,
};

export default function I18nProvider({ children }) {
  const locale = useSelector(({ i18n }) => i18n.lang);
  const messages = allMessages[locale];
  const onError = (e) => console.log(e);

  return (
    <IntlProvider locale={locale} messages={messages} onError={onError}>
      {children}
    </IntlProvider>
  );
}
