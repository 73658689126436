import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import objectPath from "object-path";
import * as builder from "../../ducks/builder";
import KTToggle from "../../_assets/js/toggle";
import { toAbsoluteUrl } from "../../utils/utils";
import { Dropdown } from "react-bootstrap";
import { FormattedMessage} from "react-intl";

class HeaderMobile extends React.Component {
  toggleButtonRef = React.createRef();

  componentDidMount() {
    new KTToggle(this.toggleButtonRef.current, this.props.toggleOptions);
  }

  render() {
    const {
      asideDisplay,
      headerMenuSelfDisplay,
      headerMobileCssClasses,
      headerMobileAttributes
    } = this.props;
    return (
      <div
        id="kt_header_mobile"
        className={`kt-header-mobile ${headerMobileCssClasses}`}
        {...headerMobileAttributes}
      >
        <div className="kt-header-mobile__logo">
          <Link to="/">
            <img alt="logo" src={toAbsoluteUrl("/media/logos/cutwise_logo.png")} />
          </Link>
        </div>

        <div className="kt-header-mobile__toolbar">
          {asideDisplay && (
            <button
              className="kt-header-mobile__toggler kt-header-mobile__toggler--left"
              id="kt_aside_mobile_toggler"
            >
              <span />
            </button>
          )}

          {headerMenuSelfDisplay && (
            <button
              className="kt-header-mobile__toggler"
              id="kt_header_mobile_toggler"
            >
              <span />
            </button>
          )}

          <Dropdown className={'mobile-account-dropdown'}>
            <Dropdown.Toggle variant={'outline'}>
              <i className={'flaticon-user'} />
            </Dropdown.Toggle>
            <Dropdown.Menu alignRight={true} className={'dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl'}>
              <div className="kt-notification">
                <a className="kt-notification__item">
                  <div className="kt-notification__item-icon">
                    <i className="flaticon2-calendar-3 kt-font-primary"/>
                  </div>
                  <Link to={'/settings'} className="kt-notification__item-details">
                    <div className="kt-notification__item-title kt-font-bold">
                      <FormattedMessage id="AUTH.ACCOUNT.MY_ACCOUNT"/>
                    </div>
                    <div className="kt-notification__item-time">
                      <FormattedMessage id="AUTH.ACCOUNT.ACCOUNT_SETTING"/>
                    </div>
                  </Link>
                </a>
                <div className="kt-notification__custom">
                  <Link to="/logout" className="btn btn-label-brand btn-sm btn-bold">
                    <FormattedMessage id="AUTH.GENERAL.SIGNOUT_BUTTON"/>
                  </Link>
                </div>
              </div>
            </Dropdown.Menu>
          </Dropdown>

        </div>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  headerLogo: builder.selectors.getStickyLogo(store),
  asideDisplay: objectPath.get(
    store.builder.layoutConfig,
    "aside.self.display"
  ),
  headerMenuSelfDisplay:
    objectPath.get(store.builder.layoutConfig, "header.menu.self.display") ===
    true,
  toggleOptions: {
    target: "body",
    targetState: "kt-header__topbar--mobile-on",
    togglerState: "kt-header-mobile__toolbar-topbar-toggler--active"
  },
  headerMobileCssClasses: builder.selectors.getClasses(store, {
    path: "header_mobile",
    toString: true
  }),
  headerMobileAttributes: builder.selectors.getAttributes(store, {
    path: "aside_menu"
  })
});

export default connect(mapStateToProps)(HeaderMobile);
