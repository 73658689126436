import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { TextField, InputAdornment, IconButton } from '@material-ui/core';
import { FormattedMessage, useIntl, injectIntl } from 'react-intl';
import { IoEyeOffOutline, IoEyeOutline } from 'react-icons/io5';

import { authActions } from '../../store/ducks/auth.duck';
import MetaTags from '../../components/MetaTags';

const ResetPassword = ({
  history,
  resetPassword,
  errorMessage,
}) => {
  const initialValues = {
    token: '',
    email: '',
    password: '',
    confirmPassword: '',
  };

  const initialErrors = {
    password: '',
    confirmPassword: '',
    isRight: true,
  };

  const intl = useIntl();

  const { email, token } = useParams();

  const [state, setState] = useState(initialValues);
  const [errors, setErrors] = useState(initialErrors);
  const [isShownPass, setIsShownPass] = useState(false);
  const [isShownConfirmPass, setIsShownConfirmPass] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(true);

  const [apiErr, setApiErr] = useState(false);

  useEffect(() => {
    setState({
      ...state,
      email: email || '',
      token: token || '',
    });
  }, []);

  useEffect(() => {
    if (!errors.password && !errors.confirmPassword) {
      if (state.password === state.confirmPassword) {
        setBtnDisabled(false);
      }
    }

    if (errors.password || errors.confirmPassword || apiErr) {
      setBtnDisabled(true);
    }
  }, [apiErr, errors, state]);

  useEffect(() => {
    if (apiErr) {
      setTimeout(() => {
        setApiErr(false);
      }, 10000);
    }
  }, [apiErr]);

  const validateValues = () => {
    let hasErrors = initialErrors;
    console.log('validateValues runed');

    if (!state.password) {
      hasErrors.password = intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD',
      });
      hasErrors.isRight = false;
    }

    if (!state.confirmPassword) {
      hasErrors.confirmPassword = intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD',
      });
      hasErrors.isRight = false;
    }

    if (
      state.password &&
      state.confirmPassword &&
      state.password !== state.confirmPassword
    ) {
      hasErrors.confirmPassword = intl.formatMessage({
        id: 'AUTH.VALIDATION.DOES_NOT_MATCH',
      });
      hasErrors.isRight = false;
    }

    setErrors(hasErrors);
    return hasErrors;
  };

  const handleChange = async (field, value) => {
    setState({
      ...state,
      [field]: value,
    });

    setErrors((prevErrors) => {
      const password = prevErrors.password;
      const confirmPassword = prevErrors.confirmPassword;

      const isRight =
        password.length === confirmPassword.length && password.length === 0;

      return {
        ...prevErrors,
        [field]: '',
        isRight,
      };
    });
  };

  const handleResetBtnSubmit = async () => {
    const hasError = validateValues();

    if (hasError.isRight) {
      await resetPassword(state.email, state.token, state.password, history);
      history.push('/auth/login');
    }
  };

  return (
    <>
      <MetaTags
        title={intl.formatMessage({ id: 'AUTH.RESET.META.TITLE' })}
        description={intl.formatMessage({ id: 'AUTH.RESET.META.DESC' })}
        keywords={intl.formatMessage({ id: 'AUTH.RESET.META.KEYWORDS' })}
      />
      <h3 className="login-left-center-title">
        <FormattedMessage id="AUTH.RESET.TITLE" />
      </h3>

      <p className="login-left-center-desc">
        <FormattedMessage id="AUTH.RESET.DESC" />
      </p>

      {errorMessage && (
        <div className="alert alert-danger">
          <div className="alert-text">{errorMessage}</div>
        </div>
      )}
      <div className="kt-form">
        <div className="form-group">
          <TextField
            variant="outlined"
            margin="normal"
            label={intl.formatMessage({ id: 'AUTH.RESET.PASS' })}
            value={state.password}
            type={isShownPass ? 'text' : 'password'}
            name="password"
            className="kt-width-full"
            // onBlur={(e) => validateValues(e.target.value)}
            onChange={(e) => handleChange('password', e.target.value)}
            helperText={errors.password ? errors.password : ''}
            error={Boolean(errors.password)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle-password-visibility"
                    onClick={() => setIsShownPass(!isShownPass)}
                  >
                    {!isShownPass ? (
                      <IoEyeOffOutline size={28} color={'rgb(215, 64, 58)'} />
                    ) : (
                      <IoEyeOutline size={28} color={'rgb(215, 64, 58)'} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>

        <div className="form-group">
          <TextField
            variant="outlined"
            margin="normal"
            label={intl.formatMessage({ id: 'AUTH.RESET.CONFIRM_PASS' })}
            value={state.confirmPassword}
            type={isShownConfirmPass ? 'text' : 'password'}
            name="confirmPassword"
            className="kt-width-full"
            // onBlur={(e) => validateValues(e.target.value)}
            onChange={(e) => handleChange('confirmPassword', e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle-password-visibility"
                    onClick={() => setIsShownConfirmPass(!isShownConfirmPass)}
                  >
                    {!isShownConfirmPass ? (
                      <IoEyeOffOutline size={28} color={'rgb(215, 64, 58)'} />
                    ) : (
                      <IoEyeOutline size={28} color={'rgb(215, 64, 58)'} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            helperText={errors.confirmPassword ? errors.confirmPassword : ''}
            error={Boolean(errors.confirmPassword)}
          />
        </div>

        <div className="login-left-center-actions">
          <button
            type="submit"
            className="login-left-center-actions-primary"
            disabled={btnDisabled}
            onClick={handleResetBtnSubmit}
          >
            <FormattedMessage id="AUTH.GENERAL.RESET_BUTTON" />
          </button>
        </div>
      </div>
    </>
  );
};

ResetPassword.propTypes = {
  isPending: PropTypes.bool,
  history: PropTypes.object,
  resetPassword: PropTypes.func,
};

const mapStateToProps = (state, { history }) => ({
  isPending: state.auth.isPending,
  errorMessage: state.auth.error,
  history,
});

const mapDispatchToProps = {
  resetPassword: authActions.resetPassword,
};

const withRouterResetPassword = withRouter(ResetPassword);

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(withRouterResetPassword)
);
