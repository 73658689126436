const initialState = {
  isProcessing: false,
  data: [], /* response.orders = [{ project: ... }] */
  error: '',
  pagination: {
    per_page: 10,
    current_page: 1,
    count: 0,
  },
  detailedOrder: {
    isProcessing: false,
    data: null,
    status: {
      isProcessing: false,
    },
  },
  orderTypes: {
    isProcessing: false,
    error: null,
    items: [],
  },
};

export const ORDERS_ACTIONS = {
  GET_ORDERS_REQUEST: 'GET_ORDERS_REQUEST',
  GET_ORDERS_SUCCESS: 'GET_ORDERS_SUCCESS',
  GET_ORDERS_ERROR: 'GET_ORDERS_ERROR',
  FULFILL_DETAILED_ORDER: 'FULFILL_DETAILED_ORDER',

  GET_ORDER_DETAILED_REQUEST: 'GET_ORDER_DETAILED_REQUEST',
  GET_ORDER_DETAILED_SUCCESS: 'GET_ORDER_DETAILED_SUCCESS',
  GET_ORDER_DETAILED_ERROR: 'GET_ORDER_DETAILED_ERROR',

  GET_ORDER_TYPES_REQUEST: 'GET_ORDER_TYPES_REQUEST',
  GET_ORDER_TYPES_SUCCESS: 'GET_ORDER_TYPES_SUCCESS',
  GET_ORDER_TYPES_ERROR: 'GET_ORDER_TYPES_ERROR',

  CHANGE_ORDER_TYPE_REQUEST: 'CHANGE_ORDER_TYPE_REQUEST',
  CHANGE_ORDER_TYPE_SUCCESS: 'CHANGE_ORDER_TYPE_SUCCESS',
  CHANGE_ORDER_TYPE_ERROR: 'CHANGE_ORDER_TYPE_ERROR',

  DELETE_ORDER_REQUEST: 'DELETE_ORDER_REQUEST',
  DELETE_ORDER_SUCCESS: 'DELETE_ORDER_SUCCESS',
  DELETE_ORDER_ERROR: 'DELETE_ORDER_ERROR',
};

export const ordersReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ORDERS_ACTIONS.GET_ORDERS_REQUEST:
      return {
        ...state,
        isProcessing: true,
      };

    case ORDERS_ACTIONS.GET_ORDERS_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        data: payload.orders,
        // data: payload.quotes,
        pagination: payload.meta.pagination,
      };

    case ORDERS_ACTIONS.GET_ORDERS_ERROR:
      return {
        ...state,
        isProcessing: false,
        error: payload,
      };

    case ORDERS_ACTIONS.FULFILL_DETAILED_ORDER:
    case ORDERS_ACTIONS.GET_ORDER_DETAILED_SUCCESS:
      return {
        ...state,
        detailedOrder: {
          ...state.detailedOrder,
          isProcessing: false,
          data: payload,
        }
      };

    case ORDERS_ACTIONS.GET_ORDER_DETAILED_REQUEST:
      return {
        ...state,
        detailedOrder: {
          ...state.detailedOrder,
          isProcessing: true,
        }
      };

    case ORDERS_ACTIONS.GET_ORDER_DETAILED_ERROR:
      return {
        ...state,
        detailedOrder: {
          ...state.detailedOrder,
          isProcessing: false,
          error: payload,
        },
      };

    case ORDERS_ACTIONS.CHANGE_ORDER_TYPE_REQUEST:
      return {
        ...state,
        detailedOrder: {
          ...state.detailedOrder,
          status: {
            ...state.detailedOrder.status,
            isProcessing: true,
          }
        }
      };

    case ORDERS_ACTIONS.CHANGE_ORDER_TYPE_SUCCESS:
      return {
        ...state,
        detailedOrder: {
          ...state.detailedOrder,
          status: {
            ...state.detailedOrder.status,
            isProcessing: false,
          }
        }
      };

    case ORDERS_ACTIONS.CHANGE_ORDER_TYPE_ERROR:
      return {
        ...state,
        detailedOrder: {
          ...state.detailedOrder,
          status: {
            ...state.detailedOrder.status,
            isProcessing: false,
            error: payload,
          }
        }
      };


    case ORDERS_ACTIONS.DELETE_ORDER_REQUEST:
      return {
        ...state,
        isProcessing: true
      };

    case ORDERS_ACTIONS.DELETE_ORDER_SUCCESS:
      return {
        ...state,
        isProcessing: false
      };

    case ORDERS_ACTIONS.DELETE_ORDER_ERROR:
      return {
        ...state,
        isProcessing: false,
        error: payload,
      };

    case ORDERS_ACTIONS.GET_ORDER_TYPES_REQUEST:
      return {
        ...state,
        orderTypes: {
          ...state.orderTypes,
          isProcessing: true,
        },
      };

    case ORDERS_ACTIONS.GET_ORDER_TYPES_SUCCESS:
      return {
        ...state,
        orderTypes: {
          ...state.orderTypes,
          items: payload,
          isProcessing: false,
        },
      };

    case ORDERS_ACTIONS.GET_ORDER_TYPES_ERROR:
      return {
        ...state,
        orderTypes: {
          ...state.orderTypes,
          isProcessing: false,
          error: payload,
        },
      };

    default:
      return state;
  }
};

export const ordersActions = {
  getOrdersRequest: (payload) => ({
    type: ORDERS_ACTIONS.GET_ORDERS_REQUEST,
    payload
  }),
  getOrdersSuccess: (payload) => ({
    type: ORDERS_ACTIONS.GET_ORDERS_SUCCESS,
    payload
  }),
  getOrdersError: (payload) => ({
    type: ORDERS_ACTIONS.GET_ORDERS_ERROR,
    payload
  }),


  deleteOrderRequest: (payload) => ({
    type: ORDERS_ACTIONS.DELETE_ORDER_REQUEST,
    payload
  }),
  deleteOrderSuccess: (payload) => ({
    type: ORDERS_ACTIONS.DELETE_ORDER_SUCCESS,
    payload
  }),
  deleteOrderError: (payload) => ({
    type: ORDERS_ACTIONS.DELETE_ORDER_ERROR,
    payload
  }),


  fulfillDetailedOrder: (payload) => ({
    type: ORDERS_ACTIONS.FULFILL_DETAILED_ORDER,
    payload
  }),
  getOrderDetailedRequest: (payload) => ({
    type: ORDERS_ACTIONS.GET_ORDER_DETAILED_REQUEST,
    payload
  }),
  getOrderDetailedSuccess: (payload) => ({
    type: ORDERS_ACTIONS.GET_ORDER_DETAILED_SUCCESS,
    payload
  }),
  getOrderDetailedError: (payload) => ({
    type: ORDERS_ACTIONS.GET_ORDER_DETAILED_ERROR,
    payload
  }),

  getOrderTypeRequest: (payload) => ({
    type: ORDERS_ACTIONS.GET_ORDER_TYPES_REQUEST,
    payload,
  }),
  getOrderTypeSuccess: (payload) => ({
    type: ORDERS_ACTIONS.GET_ORDER_TYPES_SUCCESS,
    payload,
  }),
  getOrderTypeError: (payload) => ({
    type: ORDERS_ACTIONS.GET_ORDER_TYPES_ERROR,
    payload,
  }),

  changeDetailedOrderRequest: (payload) => ({
    type: ORDERS_ACTIONS.CHANGE_ORDER_TYPE_REQUEST,
    payload,
  }),
  changeDetailedOrderSuccess: (payload) => ({
    type: ORDERS_ACTIONS.CHANGE_ORDER_TYPE_SUCCESS,
    payload,
  }),
  changeDetailedOrderError: (payload) => ({
    type: ORDERS_ACTIONS.CHANGE_ORDER_TYPE_ERROR,
    payload,
  }),
};
