import React, { useEffect, useState } from 'react';
import PropTypes /*, { object } */ from 'prop-types';
import { Button, Col, Form, Row, Card } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Paper } from '@material-ui/core';
import { getMaterial } from '../../../store/materials/actions';
import { ROUTES } from '../../../common/constants/routes/routes';
import initApi from '../../../crud/apiConfig/apiConfig';
import { API_URL, API_URLS } from '../../../common/constants/api/apiConstants';
import '../../../partials/content/WysiwygEditor/styling.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Editor } from 'react-draft-wysiwyg';
import Multiselect from 'multiselect-react-dropdown';
import { MATERIAL_CATEGORIES } from '../../../common/constants/constants';
import { convertToRaw, convertFromRaw, EditorState } from 'draft-js';

const DetailsPage = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { data, isProcessing } = props;
  const [form, setForm] = useState({});
  const [validated, setValidated] = useState(false);
  // const [showUpload, setShowUpload] = useState(false);

  const fields = [
    { name: 'name', title: 'Name', type: 'text', required: true },
    { name: 'name_en', title: 'Name English', type: 'text', required: true },
    { name: 'name_de', title: 'Name German', type: 'text', required: true },
    { name: 'text', title: 'Description', type: 'wysiwyg' },
    { name: 'text_en', title: 'Description English', type: 'wysiwyg' },
    { name: 'text_de', title: 'Description German', type: 'wysiwyg' },
    { name: 'color', title: 'Color', type: 'text', required: true },
    { name: 'color_en', title: 'Color English', type: 'text' },
    { name: 'color_de', title: 'Color German', type: 'text' },
    {
      name: 'plate_measurement',
      title: 'Plate Measurement',
      type: 'text',
      required: true,
    },
    { name: 'thickness', title: 'Thickness', type: 'float', required: true },
    {
      name: 'cutting_speed',
      title: 'Cutting Speed',
      type: 'integer',
      required: true,
    },
    {
      name: 'destiny',
      title: 'Material density',
      type: 'float',
      required: true,
    },
    { name: 'price', title: 'Price', type: 'float', required: true },
    {
      name: 'foil_removal',
      title: 'Foil Removal',
      type: 'checkbox',
      required: true,
    },
    {
      name: 'has_to_be_milled',
      title: 'Has to be milled',
      type: 'checkbox',
      required: true,
    },
    {
      name: 'category',
      title: 'Category',
      type: 'select',
      required: false,
      multiple: false,
      options: MATERIAL_CATEGORIES,
    },
    { name: 'cleaned_product_file', title: 'Cleaned product', type: 'file' },
    {
      name: 'uncleaned_product_file',
      title: 'Uncleaned product',
      type: 'file',
    },
    {
      name: 'is_bendable',
      title: 'Bendable true/false',
      type: 'checkbox',
      required: true,
    },
    { name: 'min_bend_offset', title: 'Minimal offset in mm', type: 'text' },
    { name: 'bending_price', title: 'Price per bending', type: 'text' },
  ];

  useEffect(() => {
    if (id) {
      dispatch(getMaterial(id));
      // setShowUpload(true);
    }
  }, []);

  useEffect(() => {
    if (data && id) {
      data.uncleaned_product_file = data.uncleanedProductFile;
      data.cleaned_product_file = data.cleanedProductFile;
      data.plate_measurement = data.plateMeasurement;
      data.cutting_speed = data.cuttingSpeed;
      data.foil_removal = data.foilRemoval;
      data.has_to_be_milled = data.hasToBeMilled;
      data.name_de = data.nameDe;
      data.name_en = data.nameEn;
      data.is_bendable = data.isBendable;
      data.min_bend_offset = data.minBendOffset;
      data.bending_price = data.bendingPrice;

      if (data.category) {
        data.category = MATERIAL_CATEGORIES.find(
          (item) => item.id === data.category
        );
      }

      data.color_de = data.colorDe;
      data.color_en = data.colorEn;

      data.text =
        typeof data.text !== 'object'
          ? EditorState.createWithContent(convertFromRaw(JSON.parse(data.text)))
          : EditorState.createEmpty();
      data.text_de =
        typeof data.textEn !== 'object'
          ? EditorState.createWithContent(
              convertFromRaw(JSON.parse(data.textDe))
            )
          : EditorState.createEmpty();
      data.text_en =
        typeof data.textDe !== 'object'
          ? EditorState.createWithContent(
              convertFromRaw(JSON.parse(data.textEn))
            )
          : EditorState.createEmpty();
      setForm(data);
    } else {
      setForm({
        text: EditorState.createEmpty(),
        text_en: EditorState.createEmpty(),
        text_de: EditorState.createEmpty(),
      });
    }
  }, [data]); // eslint-disable-line

  const handleUpload = async (id, uploadfile) => {
    const data = new FormData();
    data.append(uploadfile.name, uploadfile.file);
    data.append('id', id);
    data.append('submit', true);

    let uploadUrl = API_URL + API_URLS.UPLOAD_MATERIAL_IMAGE(id);
    let uploadResult = await initApi().post(uploadUrl, data);

    if (uploadResult.data) {
      if (!uploadResult.data.success) {
        // setError(uploadResult.data.error);
      } else {
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);

    const payload = {};
    const uploadFiles = [];
    fields.forEach(async (field) => {
      switch (field.type) {
        case 'file':
          uploadFiles.push({ name: field.name, file: form[field.name] });
          break;
        case 'integer':
          payload[field.name] = parseInt(form[field.name]);
          break;
        case 'float':
          payload[field.name] = parseFloat(form[field.name]);
          break;
        case 'textarea':
          payload[field.name] = form[field.name];
          break;
        case 'select':
          payload[field.name] = form[field.name].id;
          break;
        case 'wysiwyg':
          let contentState = convertToRaw(form[field.name].getCurrentContent());
          payload[field.name] = JSON.stringify(contentState);
          break;
        default:
          payload[field.name] = form[field.name];
      }
    });

    try {
      let result = null;
      if (!id) {
        result = await initApi().post(
          API_URL + API_URLS.POST_MATERIALS_DATA,
          payload
        );
      } else {
        result = await initApi().put(
          API_URL + API_URLS.PUT_MATERIALS_DATA(id),
          payload
        );
      }

      let responseData = result.data;
      await uploadFiles.map((uploadFile) => {
        let productId = responseData.id;
        handleUpload(productId, uploadFile);
      });
      history.push(ROUTES.MATERIALS);
    } catch (e) {
      console.error(e);
    }
  };

  const handleCancel = () => {
    history.push(ROUTES.MATERIALS);
  };

  const onChange = (field) => (event) => {
    setForm({ ...form, [field]: event.target.value });
  };

  const onChangeCheckbox = (field, event) => {
    setForm({ ...form, [field]: event.target.checked });
  };

  const onChangeFile = (field, event) => {
    setForm({ ...form, [field]: event.target.files[0] });
  };

  const onChangeEditor = (field, editorState) => {
    setForm({ ...form, [field]: editorState });
  };

  return isProcessing && !form ? (
    'Loading...'
  ) : (
    <div className="row">
      <div className="col-md-12">
        <div className="kt-section">
          <div className="kt-section__content">
            <Paper className="p-5">
              <h1 className="kt-invoice__title mb-5">
                Material {id ? 'Edit' : 'Add'}
              </h1>

              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                {fields.map((field) => (
                  <Form.Group key={field.name} as={Row} controlId="">
                    <Form.Label column sm={2}>
                      {field.title}
                    </Form.Label>

                    <Col sm={10}>
                      {field.type == 'text' && (
                        <Form.Control
                          type="text"
                          value={form[field.name] || ''}
                          onChange={onChange(field.name)}
                          required={field.required}
                        />
                      )}

                      {field.type == 'file' &&
                        typeof form[field.name] === 'string' && (
                          <Card
                            style={{ maxWidth: '30%', marginBottom: '5px' }}
                          >
                            <Card.Img
                              variant="top"
                              src={`https://cutwise.sirv.com/Materials/Uploads/${
                                field.name.toUpperCase().split('_')[0]
                              }/${id}/${id}.spin?image=72&gif.lossy=5&w=650&h=488`}
                              thumbnail
                            />
                            <Card.Body>
                              This is an example of the uploaded file
                            </Card.Body>
                          </Card>
                        )}

                      {field.type == 'file' && (
                        <Form.Control
                          type="file"
                          accept="video/mp4"
                          onChange={(event) => onChangeFile(field.name, event)}
                          required={field.required}
                        />
                      )}

                      {field.type == 'textarea' && (
                        <Form.Control
                          as={'textarea'}
                          rows={5}
                          value={form[field.name]}
                          onChange={onChange(field.name)}
                        />
                      )}

                      {field.type == 'wysiwyg' && form[field.name] && (
                        <div className="WysiwygEditor">
                          <Editor
                            handlePastedText={() => false}
                            editorState={form[field.name]}
                            onEditorStateChange={(editorState) =>
                              onChangeEditor(field.name, editorState)
                            }
                            wrapperClassName="wrapper-class"
                            editorClassName="editor-class"
                            toolbarClassName="toolbar-class"
                          />
                        </div>
                      )}
                      {(field.type == 'integer' || field.type == 'float') && (
                        <Form.Control
                          type="number"
                          value={form[field.name] || ''}
                          onChange={onChange(field.name)}
                          required={field.required}
                        />
                      )}

                      {field.type === 'select' && (
                        <Multiselect
                          options={field.options}
                          selectedValues={[
                            {
                              id: form[field.name] ? form[field.name].id : '',
                              title: form[field.name]
                                ? form[field.name].title
                                : '',
                            },
                          ]}
                          singleSelect={true}
                          onSelect={(selectedList) => {
                            form[field.name] = selectedList[0];
                          }}
                          avoidHighlightFirstOption={true}
                          displayValue="title"
                        />
                      )}

                      {field.type === 'checkbox' && (
                        <Form.Check
                          type="checkbox"
                          checked={form[field.name]}
                          onChange={(evt) => onChangeCheckbox(field.name, evt)}
                          required={field.required}
                        />
                      )}
                    </Col>
                  </Form.Group>
                ))}
                <Row>
                  <Col sm={12}>
                    <Button className="pull-right" type="submit">
                      Save{' '}
                    </Button>
                    <Button
                      className="pull-right mr-2"
                      variant="light"
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Paper>
          </div>
        </div>
      </div>
    </div>
  );
};

DetailsPage.propTypes = {
  isProcessing: PropTypes.bool.isRequired,
  data: PropTypes.object,
};

const mapStateToProps = ({
  materials: {
    detailedMaterial: { data, isProcessing },
  },
}) => ({
  data,
  isProcessing,
});

export default connect(mapStateToProps)(DetailsPage);
