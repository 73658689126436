import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Paper } from '@material-ui/core';
import { getMaterial } from '../../../store/materials/actions';
import { ROUTES } from '../../../common/constants/routes/routes';
import initApi from '../../../crud/apiConfig/apiConfig';
import { API_URL, API_URLS } from '../../../common/constants/api/apiConstants';
import '../../../partials/content/WysiwygEditor/styling.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState } from 'draft-js';

const DetailsPage = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { data, isProcessing } = props;
  const [form, setForm] = useState({});
  const [validated, setValidated] = useState(false);
  // const [showUpload, setShowUpload] = useState(false);

  const fields = [
    { name: 'name', title: 'Name', type: 'text' },
    { name: 'color', title: 'Color', type: 'text' },
    { name: 'thickness', title: 'Thickness', type: 'float', required: true },
    {
      name: 'cutting_speed',
      title: 'Cutting Speed',
      type: 'integer',
    },
    {
      name: 'is_bendable',
      title: 'Bendable true/false',
      type: 'checkbox',
      required: true,
    },
    { name: 'min_bend_offset', title: 'Minimal offset in mm', type: 'integer' },
    { name: 'bending_price', title: 'Price per bending', type: 'text' },
  ];
  useEffect(() => {
    if (id) {
      dispatch(getMaterial(id));
      // setShowUpload(true);
    }
  }, []);
  useEffect(() => {
    if (data && id) {
      data.cutting_speed = data.cuttingSpeed;
      data.is_bendable = data.isBendable;
      data.min_bend_offset = data.minBendOffset;
      data.bending_price = data.bendingPrice;
      setForm(data);
    } else {
      setForm({
        name: EditorState.createEmpty(),
      });
    }
  }, [data]); // eslint-disable-line
  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);
    const payload = {};
    fields.forEach(async (field) => {
      switch (field.type) {
        case 'integer':
          payload[field.name] = parseInt(form[field.name]);
          break;
        case 'float':
          payload[field.name] = parseFloat(form[field.name]);
          break;
        default:
          payload[field.name] = form[field.name];
      }
    });
    try {
      let result = null;
      if (!id) {
        result = await initApi().post(
          API_URL + API_URLS.POST_MATERIALS_DATA,
          payload
        );
      } else {
        result = await initApi().put(
          API_URL + API_URLS.PUT_MATERIALS_DATA(id),
          payload
        );
      }
      history.push(ROUTES.BENDING);

      return result;
    } catch (e) {
      console.error(e);
    }
  };
  const handleCancel = () => {
    history.push(ROUTES.BENDING);
  };
  const onChange = (field) => (event) => {
    setForm({ ...form, [field]: event.target.value });
  };
  const onChangeCheckbox = (field, event) => {
    setForm({ ...form, [field]: event.target.checked });
  };
  return isProcessing && !form ? (
    'Loading...'
  ) : (
    <div className="row">
      <div className="col-md-12">
        <div className="kt-section">
          <div className="kt-section__content">
            <Paper className="p-5">
              <h1 className="kt-invoice__title mb-5">
                Material Bending {id ? 'Edit' : 'Add'}
              </h1>
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                {fields.map((field) => (
                  <Form.Group key={field.name} as={Row} controlId="">
                    <Form.Label column sm={2}>
                      {field.title}
                    </Form.Label>
                    <Col sm={10}>
                      {field.type == 'text' && (
                        <Form.Control
                          type="text"
                          value={form[field.name] || ''}
                          onChange={onChange(field.name)}
                          required={field.required}
                        />
                      )}
                      {(field.type == 'integer' || field.type == 'float') && (
                        <Form.Control
                          type="number"
                          value={form[field.name] || ''}
                          onChange={onChange(field.name)}
                          required={field.required}
                        />
                      )}
                      {field.type === 'checkbox' && (
                        <Form.Check
                          type="checkbox"
                          checked={form[field.name]}
                          onChange={(evt) => onChangeCheckbox(field.name, evt)}
                          required={field.required}
                        />
                      )}
                    </Col>
                  </Form.Group>
                ))}
                <Row>
                  <Col sm={12}>
                    <Button className="pull-right" type="submit">
                      Save{' '}
                    </Button>
                    <Button
                      className="pull-right mr-2"
                      variant="light"
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Paper>
          </div>
        </div>
      </div>
    </div>
  );
};
DetailsPage.propTypes = {
  isProcessing: PropTypes.bool.isRequired,
  data: PropTypes.object,
};
const mapStateToProps = ({
  materials: {
    detailedMaterial: { data, isProcessing },
  },
}) => ({
  data,
  isProcessing,
});
export default connect(mapStateToProps)(DetailsPage);
