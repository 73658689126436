import { takeEvery, put, call } from 'redux-saga/effects';

import {
  login,
  register,
  checkAuth,
  refreshToken,
  requestPassword,
  updatePassword,
  handleResetPassword,
  getCountry,
  handleAdminSignInAsCustomer,
  requestCADjson,
  reloadCADjson,
} from '../../crud/auth.crud';

import { toast } from 'react-toastify';
import { isNull, isArray } from 'lodash';

import { FOLDER } from '../../common/constants/cadJSON';

import { setStorage, getStorage } from '../../../_metronic/utils/utils';
import { authActions, authActionTypes } from '../../store/ducks/auth.duck';
import { ROUTES } from '../../common/constants/routes/routes';

function* fetchLogin({ payload }) {
  const { username, password, /* setSubmitting */ history } = payload;

  try {
    const result = yield call(login, { username, password });

    // setSubmitting(false);
    history.push(ROUTES.CONFIGURATION);
    yield put(authActions.loginSuccess(result.data.data));
    yield setStorage('id', result.data.data.id);
    yield setStorage('token', result.data.credentials.token);
    yield setStorage('refreshToken', result.data.credentials.refresh_token);
  } catch (err) {
    const error = err.response ? err.response.data.message : err.stack;
    yield put(authActions.error(error));
    // setSubmitting(false);
  }
}

function* fetchRegister({ payload }) {
  try {
    const { newUser, history } = payload;
    const dataNewUser = yield call(register, newUser);

    yield put(authActions.registerSuccess(dataNewUser.data.data));
    yield setStorage('token', dataNewUser.data.credentials.token);
    yield setStorage(
      'refreshToken',
      dataNewUser.data.credentials.refresh_token
    );
    yield history.push(ROUTES.CONFIGURATION);
  } catch (err) {
    console.log(err);
    yield put(authActions.error(err.response.data.errors[0].message));
  }
}

function* fetchCountry() {
  const data = yield call(getCountry);
  yield put(authActions.getCountrySuccess(data));
}

function* fetchRefreshToken(history) {
  try {
    const token = yield getStorage('refreshToken');
    const result = yield call(refreshToken, token);

    yield put(authActions.checkAuthSuccess(result.data.data));
    yield setStorage('token', result.data.credentials.token);
    yield setStorage('refreshToken', result.data.credentials.refresh_token);

    // yield history.push(ROUTES.DASHBOARD);
  } catch (e) {
    yield put(authActions.checkAuthError(e.response.data.message));
    yield history.push(ROUTES.AUTH_LOGIN);
  }
}

function* fetchForgotPassword({ payload }) {
  const { email, history } = payload;
  try {
    yield call(requestPassword, email);
    yield put(authActions.error('Password has been reset.'));
    yield history.push(ROUTES.AUTH_LOGIN);
  } catch (error) {
    yield put(authActions.error(error.response.data.errors[0].message));
    yield history.push(ROUTES.AUTH_LOGIN);
  }
}

function* updatePasswordSaga({ payload }) {
  try {
    yield call(updatePassword, payload);
    yield put(authActions.error('Password updated.'));
  } catch (error) {
    yield put(authActions.error(error.response));
  }
}

function* resetPasswordSaga({ payload }) {
  const { email, token, password } = payload;

  try {
    const dataNewUser = yield call(handleResetPassword, {
      email,
      token,
      password,
    });

    yield put(authActions.registerSuccess(dataNewUser.data.data));
    yield put(authActions.error('Password has been updated. Use it to login'));
  } catch (error) {
    yield put(authActions.error(error.response));
  }
}

function* fetchCheckAuth({ payload }) {
  const { history } = payload;

  try {
    const result = yield call(checkAuth);
    yield put(authActions.checkAuthSuccess(result.data.data));
  } catch (e) {
    if (e.response.status === 401) {
      yield fetchRefreshToken(history);
      return;
    }

    yield history.push(ROUTES.AUTH_LOGIN);
  }
}

function* signInAsUser({ payload }) {
  try {
    const { id, history } = payload;
    const dataUser = yield call(handleAdminSignInAsCustomer, id);

    yield put(authActions.registerSuccess(dataUser.data.data));
    yield setStorage('token', dataUser.data.credentials.token);
    yield setStorage('refreshToken', dataUser.data.credentials.refresh_token);
    yield history.push(ROUTES.CONFIGURATION);
  } catch (err) {
    console.log(err);
    yield put(authActions.error(err.response.data.errors[0].message));
  }
}

function* fetchCADjson({ payload }) {
  try {
    const { id } = payload;

    const data = {
      id: 1,
      name: 'Main',
      files: [],
      parent_id: null,
      children_folders: [
        {
          id: 2,
          name: 'small parts',
          files: [],
          parent_id: 1,
          children_folders: [
            {
              id: 4,
              name: 'final',
              files: [],
              parent_id: 2,
              children_folders: [
                {
                  id: 5,
                  name: 'I lied not final',
                  files: [],
                  parent_id: 4,
                  children_folders: [],
                },
              ],
            },
          ],
        },
        {
          id: 3,
          name: 'big parts',
          type: 'FOLDER',
          files: [],
          parent_id: 1,
          children_folders: [],
        },
      ],
    };

    const result = yield call(requestCADjson, id, data);
    const { cad_json } = result.data;
    const adoptedJSON = isNull(cad_json) || isArray(cad_json) ? FOLDER({}) : cad_json;
    yield put(authActions.refreshCADjson(adoptedJSON));
  } catch (err) {
    console.log(err);
    yield put(authActions.error(err.response.data.errors[0].message));
  }
}

function* patchCADjson({ payload }) {
  try {
    const { id, structure, message } = payload;
    const data = JSON.stringify(structure);
    yield call(reloadCADjson, id, data);

    message && toast.success(message, {
      position: 'bottom-right',
      autoClose: 2500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
  });
  } catch (err) {
    yield put(authActions.error(err.response.data.errors[0].message));
    console.error(err);
    toast.error(
      err?.response?.data?.error ||
        'Error while saving your lst changes',
      {
        position: 'bottom-right',
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      }
    );
  }
}

function* authSagas() {
  yield takeEvery(authActionTypes.Login, fetchLogin);
  yield takeEvery(authActionTypes.Register, fetchRegister);
  yield takeEvery(authActionTypes.CheckAuth, fetchCheckAuth);
  yield takeEvery(authActionTypes.ForgotPassword, fetchForgotPassword);
  yield takeEvery(authActionTypes.UpdatePassword, updatePasswordSaga);
  yield takeEvery(authActionTypes.ResetPassword, resetPasswordSaga);
  yield takeEvery(authActionTypes.GetCountry, fetchCountry);
  yield takeEvery(authActionTypes.GetCADjson, fetchCADjson);
  yield takeEvery(authActionTypes.ReloadCADjson, patchCADjson);
  yield takeEvery(authActionTypes.SignInAsUser, signInAsUser);
}

export default authSagas;
