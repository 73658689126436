import React from 'react'
import { FormattedMessage, injectIntl } from "react-intl";

class TermsAndConditionsPage extends React.Component {
    render() {
        return (
            <>
            <div className="kt-portlet">
                <div className="kt-portlet__head kt-portlet__head--noborder kt-portlet__head--break-sm">
                    <div className="kt-portlet__head-label">
                        <h1 className="kt-portlet__head-title">
                        <FormattedMessage id="PAGES.TERMS_AND_CONDITIONS" />
                        </h1>
                    </div>
                </div>
                <div className="kt-portlet__body">
                    <div className="kt-section">
                        <strong>1. General</strong>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum vitae massa tellus. Vivamus ullamcorper eu tellus et pellentesque. Morbi rhoncus eros elit, sit amet consectetur magna convallis vitae. Mauris id faucibus ipsum. Nam ultrices, nulla sit amet tincidunt placerat, lectus nibh lacinia nisl, in faucibus est orci euismod massa. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Interdum et malesuada fames ac ante ipsum primis in faucibus. Morbi vulputate augue rhoncus elit placerat vestibulum. Integer id ligula lacus. Aliquam eleifend justo molestie turpis porttitor rhoncus. Phasellus ut rutrum leo.</p>

                        <p>Mauris ac eros diam. Cras mattis convallis ligula, in mollis quam. Phasellus non dolor elit. Donec massa erat, tempor eget condimentum et, sagittis in dui. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Pellentesque eget eros laoreet, ornare massa nec, vestibulum arcu. Quisque vestibulum purus viverra, malesuada eros non, suscipit urna. Morbi eu tristique ante. Mauris ullamcorper, augue non dignissim lacinia, tortor nisl fermentum leo, sed tristique ipsum elit a nunc. Fusce maximus urna in ex efficitur, a rutrum leo lobortis. Phasellus risus sem, suscipit ac pellentesque maximus, ultrices vel nisi. Vivamus sed ultricies justo, id congue felis. Curabitur est purus, lobortis vitae tempor sollicitudin, commodo at libero. Proin varius ex non erat tempus eleifend. Sed eget neque ex. Aenean accumsan rhoncus vulputate.</p>
                        <strong>2. Less general</strong>
                        <p>Vestibulum fermentum, lacus tempus tincidunt vestibulum, orci diam feugiat felis, vitae luctus metus eros ornare est. Aenean commodo volutpat dui, ac pretium elit fermentum eu. Vestibulum augue ante, porttitor vitae scelerisque sit amet, elementum id arcu. In sed nulla vel nunc elementum vulputate. Donec nec velit tincidunt orci elementum porttitor. Nullam a turpis sed dui ornare feugiat. Proin sit amet tellus sit amet neque aliquam placerat. Donec consectetur feugiat venenatis. Suspendisse congue varius facilisis. Pellentesque feugiat, lectus nec gravida vehicula, felis quam dapibus mi, nec ullamcorper massa enim in augue. Suspendisse potenti.</p>

                        <p>Mauris venenatis mi non posuere rutrum. Ut vulputate libero nisl, ut finibus enim bibendum id. Duis ultrices sit amet diam tincidunt malesuada. Mauris eu convallis turpis. Suspendisse molestie elit id interdum euismod. Duis vehicula urna quis consequat porta. Phasellus fringilla eleifend metus, a consectetur erat porta eu.</p>
                        <strong>3. The fine print</strong>
                        <p>In maximus malesuada nulla ac rutrum. Proin dictum volutpat lacus, sit amet lacinia est scelerisque viverra. Nullam tortor mauris, scelerisque mattis porttitor vitae, imperdiet in augue. In fermentum eros ut augue aliquam, in mattis ante rutrum. Duis nec neque sapien. Praesent ligula erat, dictum et porttitor ac, ullamcorper non lectus. Vivamus tempor bibendum magna sit amet vulputate. Cras ut suscipit ante, ut ullamcorper ipsum. Fusce et auctor lorem.</p>
                    </div>
                </div>
            </div>
            </>
        )
    }
}

export default injectIntl(TermsAndConditionsPage);

