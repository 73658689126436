import React, { useState, useEffect, useRef } from 'react';
import qs from 'query-string';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  PortletBody,
  PortletFooter,
} from '../../../../../partials/content/Portlet';
import {
  API_URL,
  API_URLS,
} from '../../../../../common/constants/api/apiConstants';
import { solid, regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import initApi from '../../../../../crud/apiConfig/apiConfig';
import {
  Table,
  Image,
  Badge,
  ListGroup,
  OverlayTrigger,
  Tooltip,
  Container,
  Modal,
  Button,
} from 'react-bootstrap';
import { Chip } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment';
import 'moment/locale/nl';
import 'moment/locale/de';
import 'moment/locale/en-gb';

export const Active = ({ admin, search }) => {
  const intl = useIntl();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [modalVisibility, setModalVisibility] = useState(false);
  const [deleteId, setDeleteId] = useState(undefined);
  const [page, setPage] = useState(1);
  const isMounted = useRef(false);

  const query = {
    page: page,
    search: search,
  };

  let fetchQuotesUrl =
    API_URL +
    API_URLS.GET_QUOTES_DATA +
    `?include=project.products,shipping_address.country,billing_address.country,payment_status,user&${qs.stringify(
      query
    )}`;

  useEffect(() => {
    isMounted.current = true;

    setLoading(true);

    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      getActiveQuotes();
    }
  }, [page, search, isMounted]);

  useEffect(() => {
    setPage(1);
  }, [search]);

  // useEffect(() => {
  //   if (data.length) {
  //     setLoading(false);
  //   }
  // }, [data]);

  const formatMoney = (value) => {
    if (value) {
      value = Number(value).toFixed(2);
    }
    return value
      ? '€ ' +
          value
            .toString()
            .replace('.', ',')
            .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      : '€ 0';
  };

  const navigateToCustomer = (event, userId) => {
    event.stopPropagation();
    history.push(`/users/details/${userId}`);
  };

  const navigateToConfigurator = (event, projectId) => {
    event.stopPropagation();
    history.push(`/configurator/${projectId}`);
  };

  const navigateToCheckOut = async (event, item) => {
    event.stopPropagation();
    const response = await initApi().get(
      API_URL + API_URLS.COPY_ORDER_TO_QUOTE(item?.project?.id)
    );

    if (response?.data?.success) {
      if (item.is_quote_expired) {
        history.push(`/checkout/${response.data.project_id}?recalculate=true`);
      } else {
        history.push(
          `/checkout/${response.data.project_id}?old_project_id=${item?.project?.id}`
        );
      }
    }
  };

  const navigateToQuote = (quoteId) => {
    history.push(`/quotes/details/${quoteId}`);
  };

  const quoteStatusChip = (item) => {
    if (!item) {
      return null;
    }
    if (item.is_quote_expired) {
      let label = intl.formatMessage({
        id: 'QUOTE.EXPIRED',
        defaultMessage: 'Expired',
      });
      return (
        <Chip
          className={'order-chip-on-account'}
          label={label}
          variant={'outlined'}
          size={'medium'}
        />
      );
    }
    let label = (
      <span>
        {intl.formatMessage({ id: 'QUOTE.STILL', defaultMessage: 'Still' })}
        &nbsp;
        {item.quote_valid_days === 0 ? 1 : item.quote_valid_days}&nbsp;
        {item.quote_valid_days === 0
          ? intl.formatMessage({
              id: 'QUOTE.DAY_VALID',
              defaultMessage: 'Day left',
            })
          : intl.formatMessage({
              id: 'QUOTE.DAYS_VALID',
              defaultMessage: 'Days left',
            })}
      </span>
    );
    return (
      <Chip
        className={'order-chip-info'}
        label={label}
        variant={'outlined'}
        size={'medium'}
      />
    );
  };

  const getCustomer = (item) => {
    let customer =
      item.billing_address &&
      item.billing_address.company_name &&
      item.billing_address.company_name.length > 0
        ? item.billing_address.company_name
        : item.billing_address &&
          item.billing_address.first_name &&
          item.billing_address.last_name
        ? item.billing_address.first_name + ' ' + item.billing_address.last_name
        : item.shipping_address &&
          item.shipping_address.company_name &&
          item.shipping_address.company_name.length > 0
        ? item.shipping_address.company_name
        : item.shipping_address &&
          item.shipping_address.first_name &&
          item.shipping_address.last_name
        ? item.shipping_address.first_name +
          ' ' +
          item.shipping_address.last_name
        : '';

    return (
      <Link
        className={'customer-click'}
        to={`/users/details/${item.user.id}`}
        onClick={(event) => {
          navigateToCustomer(event, item.user.id);
        }}
      >
        {customer.replaceAll('null', '')}
      </Link>
    );
  };

  const getActiveQuotes = async () => {
    let { data } = await initApi().get(fetchQuotesUrl);

    if (isMounted.current) {
      setTotalItems(data.meta.pagination.total);
      setLoading(false);

      if (page == 1) {
        setData(data.quotes);
      } else {
        setData((current) => [...current, ...data.quotes]);
      }
    }
  };

  const onLoadMoreClick = () => {
    setLoading(true);
    setPage(page + 1);
  };

  const updateModalVisibility = (show) => {
    setModalVisibility(show);
  };

  const orderDeleteModal = (event, orderId) => {
    event.stopPropagation();
    setDeleteId(orderId);
    updateModalVisibility(true);
  };

  const deleteOrder = async () => {
    if (deleteId) {
      await initApi().delete(API_URL + API_URLS.DELETE_ORDER(deleteId));
      setDeleteId(undefined);
    }
    updateModalVisibility(false);
    history.go(0);
  };

  if (!loading && data?.length === 0) {
    return (
      <div
        style={{
          borderTop: '1px solid #ebedf2',
          marginTop: '10px',
          marginLeft: '10px',
          padding: '10px',
        }}
      >
        <FormattedMessage id="no_records_to_display" />
      </div>
    );
  } else
    return (
      <PortletBody>
        {data && (
          <Table className={'quotes-table'} responsive>
            <tbody>
              {data.map((item, index) => {
                return (
                  <tr
                    className={'row-height'}
                    onClick={() => {
                      navigateToQuote(item.id);
                    }}
                    key={index}
                  >
                    <td className={'product-image-column hide-mobile'}>
                      <div className={'product-images-container'}>
                        <Badge className={'product-badge'}>
                          {item.project.products.length}
                        </Badge>
                        <Image
                          src={
                            API_URL +
                            '/projects/' +
                            item.project.products[0]?.svg
                          }
                          className={'product-images image1'}
                          height={'100px'}
                        />
                        {item.project.products[1] && (
                          <Image
                            src={
                              API_URL +
                              '/projects/' +
                              item.project.products[1]?.svg
                            }
                            className={'product-images image2'}
                            height={'100px'}
                          />
                        )}

                        {item.project.products[2] && (
                          <Image
                            src={
                              API_URL +
                              '/projects/' +
                              item.project.products[2]?.svg
                            }
                            className={'product-images image3'}
                            height={'100px'}
                          />
                        )}
                      </div>
                    </td>

                    <td>
                      <ListGroup as={'ol'} variant="flush">
                        <ListGroup.Item as={'li'} style={{ border: 'none' }}>
                          <div className="ms-2 me-auto">
                            <div className={'font-weight-bold'}>
                              {intl.formatMessage({ id: 'QUOTE.QUOTE' })} #{' '}
                              {item.id}
                            </div>
                            {item.reference && (
                              <OverlayTrigger
                                placement={'top'}
                                overlay={
                                  <Tooltip id={`tooltip-${item.id}`}>
                                    {item.reference}
                                  </Tooltip>
                                }
                              >
                                <div
                                  className={'d-inline-block text-truncate'}
                                  style={{ maxWidth: '150px' }}
                                >
                                  <FormattedMessage id="GENERAL.REFERENCE" />:{' '}
                                  {item.reference}
                                </div>
                              </OverlayTrigger>
                            )}
                            {admin && (
                              <div className={'font-weight-bold'}>
                                {getCustomer(item)}
                              </div>
                            )}
                          </div>
                        </ListGroup.Item>
                      </ListGroup>
                    </td>

                    <td>
                      {moment
                        .unix(item.created_at)
                        .locale(intl.locale)
                        .format('dddd DD MMMM YYYY')}
                    </td>

                    <td className={'text-success font-weight-bold'}>
                      {formatMoney(item.project.price)}
                    </td>

                    <td align="center" className={'hide-mobile'}>
                      {quoteStatusChip(item)}
                    </td>

                    <td className={'hide-mobile'}>
                      <button
                        type={'button'}
                        className={'btn btn-bold btn-outline-brand'}
                        onClick={(e) => {
                          navigateToConfigurator(e, item.project.id);
                        }}
                      >
                        <FormattedMessage id="QUOTE.EDIT_IN_CONFIGURATOR" />
                      </button>
                    </td>

                    <td className={'hide-mobile'}>
                      <button
                        type={'button'}
                        className={'btn btn-brand btn-bold'}
                        onClick={(e) => {
                          navigateToCheckOut(e, item);
                        }}
                      >
                        {item.is_quote_expired ? (
                          <FormattedMessage id="QUOTE.RECALCULATE" />
                        ) : (
                          <FormattedMessage id="QUOTE.PLACE_ORDER" />
                        )}
                      </button>
                    </td>

                    {admin && (
                      <td className={'order-remove hide-mobile'}>
                        <FontAwesomeIcon
                          icon={regular('trash-can')}
                          size={'1x'}
                          color={'#B7B7B7'}
                          cursor={'pointer'}
                          onClick={(e) => {
                            orderDeleteModal(e, item.id);
                          }}
                        />
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        )}

        {data.length == totalItems && (
          <PortletFooter
            className={'quotes-footer'}
            style={{ textAlign: 'center' }}
          >
            <Container>
              <span className={'quotes-pagination'}>
                {data.length} {intl.formatMessage({ id: 'GENERAL.OF' })}{' '}
                {totalItems}
              </span>
            </Container>
            <br />
            <Container>
              <button
                type="button"
                className="btn btn-label-brand btn-bold"
                disabled
              >
                <FormattedMessage id="GENERAL.NO_MORE_ITEMS" />
              </button>
            </Container>
          </PortletFooter>
        )}

        {data.length < totalItems && (
          <PortletFooter
            className={'quotes-footer'}
            style={{ textAlign: 'center' }}
          >
            <Container>
              <span className={'quotes-pagination'}>
                {data.length} {intl.formatMessage({ id: 'GENERAL.OF' })}{' '}
                {totalItems}
              </span>
            </Container>
            <br />
            <Container>
              {loading && (
                <button
                  type="button"
                  className="btn btn-label-brand btn-bold"
                  onClick={(event) => onLoadMoreClick(event)}
                >
                  <FontAwesomeIcon icon={solid('spinner')} size={'1x'} spin />{' '}
                  <FormattedMessage id="GENERAL.LOADING" />
                </button>
              )}
              {!loading && (
                <button
                  type="button"
                  className="btn btn-label-brand btn-bold"
                  onClick={(event) => onLoadMoreClick(event)}
                >
                  <FontAwesomeIcon icon={solid('chevron-down')} size={'1x'} />{' '}
                  <FormattedMessage id="GENERAL.LOAD_MORE" />
                </button>
              )}
            </Container>
          </PortletFooter>
        )}

        <Modal
          show={modalVisibility}
          onHide={() => {
            updateModalVisibility(false);
          }}
          size={'md'}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <FormattedMessage id="QUOTE.DELETE_QUOTE" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormattedMessage id="QUOTE.CONFIRM_DELETE" />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant={'secondary'}
              onClick={() => {
                updateModalVisibility(false);
              }}
            >
              <FormattedMessage id="CANCEL" />
            </Button>
            <Button
              onClick={(e) => {
                deleteOrder(e);
              }}
            >
              <FormattedMessage id="DELETE" />
            </Button>
          </Modal.Footer>
        </Modal>
      </PortletBody>
    );
};
