import React, { useState, useEffect, useMemo } from 'react';

import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { FaRegTrashAlt } from 'react-icons/fa';
// import TextField from '@material-ui/core/TextField';

import { priceCalculator } from '../../../../partials/content/priceCalculator';

import './index.scss';
import scssStylesheet from '!!raw-loader!./index.scss';
import { css_into_js } from '../../../../../_metronic/utils/scss';
import initApi from '../../../../crud/apiConfig/apiConfig';
import { API_URL, API_URLS } from '../../../../common/constants/api/apiConstants';

function OverviewPart({ intl, fileContents, setActionBar }) {
  const styles = useMemo(() => css_into_js(scssStylesheet), []);
  const [rate, setRate] = useState()
 
  const [adopted, setAdopted] = useState({
    KEYS: [
      '',
      intl.formatMessage({ id: 'CAD.LIBRARY.NAME' }),
      intl.formatMessage({ id: 'CAD.LIBRARY.MATERIAL' }),
      intl.formatMessage({ id: 'CAD.LIBRARY.COLOR' }),
      intl.formatMessage({ id: 'CAD.LIBRARY.SIZE' }),
      intl.formatMessage({ id: 'CAD.LIBRARY.QUANTITY' }),
      intl.formatMessage({ id: 'CAD.LIBRARY.PRICE' }),
      '',
      intl.formatMessage({ id: 'CAD.LIBRARY.TOTAL' }),
      intl.formatMessage({ id: 'MENU.ACTIONS' }),
    ],
  });

  const removeBasketID = (prevState, targetID) => {
    const newBasketIDs = prevState.basket.filter(
      (fileID) => fileID !== targetID
    );
    const newFileContents = {};

    Object.values(prevState.basket_fileContest).forEach((file) => {
      if (file.id !== targetID) newFileContents[file.id] = file;
    });

    setAdopted((prevState) => ({
      ...prevState,
      asObject: newFileContents,
    }));

    return {
      ...prevState,
      basket: newBasketIDs,
      basket_fileContest: newFileContents,
    };
  };


  const getloweRate = async (days) => {
    const data = await initApi().get(
         API_URL + API_URLS.GET_HOURLY_RATES_DATA
       );

       return  data.data.find( rate => rate.deliveryDays === days );
 }

 useEffect(()=> {
  getloweRate(14).then(res => {
    setRate(res)
   })
 }, [])
  useEffect(() => {
    
    

    if (fileContents && fileContents.length > 0) {
      const asObject = {};

      fileContents.forEach((file) => {
        asObject[file.id] = {
          ...file,
          quantity: 1,
          deleteButton: (
            <FaRegTrashAlt
              className="trashIcon"
              onClick={() =>
                setActionBar((prevState) => removeBasketID(prevState, file.id))
              }
            />
          ),
        };
      });

      setActionBar((actionPrevState) => ({
        ...actionPrevState,
        basket_fileContest: asObject,
      }));

      setAdopted((prevState) => ({ ...prevState, asObject }));
    }
  }, [JSON.stringify(fileContents)]);

  const changeValue = (file, actionType) => {
    let quantity = file.quantity;
    if (quantity != 1 && actionType == 'subtract') {
      quantity--;
    }
    if (actionType == 'add') {
      quantity++;
    }

    setAdopted((prevState) => {
      return {
        ...prevState,
        asObject: {
          ...prevState.asObject,
          [file.id]: { ...file, quantity },
        },
      };
    });

    setActionBar((actionPrevState) => ({
      ...actionPrevState,
      basket_fileContest: {
        ...actionPrevState.basket_fileContest,
        [file.id]: { ...file, quantity },
      },
    }));
  };

  if (adopted.asObject)
    return (
      <TableContainer className="overview" >
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              {adopted.KEYS.map((key, index) => (
                <TableCell
                  key={key + index}
                  style={styles['.overview-row-cell']}
                >
                  {key}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.values(adopted.asObject).map((file) => {
              const row_total = priceCalculator({
                area: file.totalArea,
                length: file.totalLength,
                quantity: file.quantity,
                materialPrice: file.material.price,
                cuttingSpeed: file.material.cuttingSpeed,
                rate: Number(rate?.rate),
                minimalRate: Number(rate?.minimumPrice),
                startingFrom: false
              });

              const file_price = (row_total / file.quantity).toFixed(2);

              return (<TableRow key={file.id}>
                <TableCell style={styles['.overview-row-cell']}>
                  <span
                    dangerouslySetInnerHTML={{ __html: file.svgContent }}
                    style={{
                      padding: '10px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '150px',
                      height: '100px',
                      overflow: 'visible', // Ensures SVG content isn't cut off
                    }}
                  ></span>
                </TableCell>

                <TableCell style={styles['.overview-row-cell']}>
                <p
                style={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  maxWidth: '200px',
                  whiteSpace: 'nowrap',
                  marginTop: '10px'
                }}
                > 
                  {file.name}
                  </p>  
                </TableCell>

                <TableCell style={styles['.overview-row-cell']}>
                  <span style={{ fontFamily: 'monospace' }}>
                    {file.material.name}
                  </span>
                </TableCell>

                <TableCell style={styles['.overview-row-cell']}>
                {parseFloat(Number(file.material.thickness)) + ' ' + 'mm'}  {intl.formatMessage({ id: `${file.material.color}` })}
                </TableCell>

                <TableCell style={styles['.overview-row-cell']}>
                  <span
                    style={{
                      fontFamily: 'monospace',
                      color: 'rgb(150, 150, 150)',
                      fontSize: '10px',
                      width: '200px',
                      display: 'flex',
                      margin: 'auto',
                      flexDirection: 'column'
                    }}
                  >
                    {/*{Number(file.svgWidth).toFixed(2)} x{' '}*/}
                    {/*{Number(file.svgWidth).toFixed(2)}*/}
                    {/* {Number(file.svgWidth.replace('mm', '')).toFixed(2) - 10} x{' '}
                    {Number(file.svgHeight.replace('mm', '')).toFixed(2) - 10}mm */}
                                  {Number(file.svgWidth.replace('mm', '')).toFixed(1) -10} x{' '}
                                  {Number(file.svgHeight.replace('mm', '')).toFixed(1) -10}mm

                  </span>
                </TableCell>

                <TableCell
                  className="overview-row-cell_quantity"
                  style={styles['.overview-row-cell']}
                >
                  <button
                    className={'btn btn-sm btn-quantity'}
                    onClick={() => {
                      changeValue(file, 'subtract');
                    }}
                  >
                    <i className={'fas fa-minus'}></i>
                  </button>

                  <input
                    style={{
                      padding: '5px',
                      width: '40px',
                      border: '1px solid rgb(150, 150, 150)',
                      outline: 'none',
                      borderRadius: '4px',
                      textAlign: 'center',
                    }}
                    type="number"
                    min="1"
                    onChange={(e) => {
                      e.persist();
                      setAdopted((prevState) => {
                        setActionBar((actionPrevState) => ({
                          ...actionPrevState,
                          basket_fileContest: {
                            ...actionPrevState.basket_fileContest,
                            [file.id]: {
                              ...file,
                              quantity: Number(e.target.value),
                            },
                          },
                        }));

                        return {
                          ...prevState,
                          asObject: {
                            ...prevState.asObject,
                            [file.id]: {
                              ...file,
                              quantity: Number(e.target.value),
                            },
                          },
                        };
                      });
                    }}
                    value={file.quantity}
                  />

                  <button
                    className={'btn btn-sm btn-quantity'}
                    onClick={() => {
                      changeValue(file, 'add');
                    }}
                  >
                    <i className={'fas fa-plus'}></i>
                  </button>
                </TableCell>

                <TableCell style={styles['.overview-row-cell']}>
                  <span style={{ fontFamily: 'monospace', color: '#42a061' }}>
                    {/* € {file.price.toFixed(2)} */}
                    € {isNaN(file_price) ? 0 : file_price}
                  </span>
                </TableCell>

                <TableCell className="separator-cell"></TableCell>

                <TableCell style={styles['.overview-row-cell']}>
                  <span
                    style={{
                      fontFamily: 'monospace',
                      color: '#42a061',
                      marginRight: '15px',
                    }}
                  >
                    € {row_total} 
                    {/* € {(file.price * file.quantity).toFixed(2)} */}
                  </span>
                </TableCell>

                <TableCell
                  style={{
                    ...styles['.overview-row-cell'],
                    width: '50px',
                    border: 'none',
                    verticalAlign: 'middle',
                  }}
                >
                  {file.deleteButton}
                </TableCell>
              </TableRow>
            )})}
          </TableBody>
        </Table>
      </TableContainer>
    );

  return <span> </span>;
}

const mapStateToProps = ({ auth: { user } }) => ({
  user,
});

export default injectIntl(connect(mapStateToProps)(OverviewPart));