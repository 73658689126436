import MaterialTable from '@material-table/core';
import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
// import { useHistory } from "react-router-dom";
// import { useLastLocation } from "react-router-last-location";

const TableLayout = ({
  columns = [],
  data,
  onChange = async () => null,
  // onRowClick = (selectedRow) => {},
  actions = [],
  title = "",
  ...props
}) => {
  const { intl } = props;
  // const history = useHistory();
  // const lastLocation = useLastLocation();

  // const pagePath = history.location.pathname;

  const [searchQuery, setSearchQuery] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        onChange({
          pageSize,
          page: currentPage + 1,
          searchQuery,
        }),
      500
    );
    return () => clearTimeout(timeOutId);
  }, [searchQuery]);

  useEffect(() => {
    if (!data) return;

    onChange({
      pageSize,
      page: currentPage + 1,
      searchQuery,
    });
  }, [pageSize, currentPage]);

  return (
    <MaterialTable
      localization={{
        toolbar: {
          searchPlaceholder: intl.formatMessage({ id: "search" }),
        },
        header: {
          actions: intl.formatMessage({ id: "QUOTES.ACTIONS" }),
        },
        body: {
          emptyDataSourceMessage: intl.formatMessage({
            id: "no_records_to_display",
          }),
        },
      }}
      title={title}
      // onRowClick={(evt, selectedRow) => onRowClick(selectedRow)}
      columns={columns}
      data={data}
      page={currentPage}
      actions={actions}
      onPageChange={setCurrentPage}
      onChangeRowsPerPage={setPageSize}
      // onFilterChange={console.log}
      onSearchChange={setSearchQuery}
      options={{
        pageSize: pageSize,
        actionsColumnIndex: -1,
        search: true,
        // padding: 'dense',
        headerStyle: {
          color: "#D80000",
          fontSize: 13,
        },
        body: {
          fontSize: 13,
        },
      }}
      {...props}
    />
  );
};

export default injectIntl(TableLayout);
