import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Slider, IconButton, Checkbox, FormControlLabel, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import FilterMaterialSelector from '../../../pages/home/mypartlibrary/fileComponent/filterMaterialSelector';
import { FormattedMessage } from 'react-intl';
import Select from 'react-select';


const FilterModal = ({ specifyModalVisibility, updateSpecifyModalVisibility, handleChoiceSelection, choiceData }) => {
    const [priceRange, setPriceRange] = useState([0, 100]);
    const [materialSelectors, setMaterialSelectors] = useState([{ specified: false }]);
    const [width, setWidth] = useState('');
    const [height, setHeight] = useState('');

    const optionStyle = {
        menuPortal: (base) => ({
          ...base,
          zIndex: 9999,
        }),
        control: (base) => ({
          ...base,
          border: '1px solid #e2e5ec',
          borderRadius: '10px',
        }),
      };

    const handlePriceChange = (event, newValue) => {
        setPriceRange(newValue);
    };

    const addMaterialSelector = () => {
        setMaterialSelectors([...materialSelectors, { specified: false }]);
    };

    const removeMaterialSelector = (index) => {
        const updatedSelectors = materialSelectors.filter((_, i) => i !== index);
        setMaterialSelectors(updatedSelectors);
    };

    const handleSpecifiedChange = (index, event) => {
        const updatedSelectors = materialSelectors.map((selector, i) => 
            i === index ? { ...selector, specified: event.target.checked } : selector
        );
        setMaterialSelectors(updatedSelectors);
    };

    const handleWidthChange = (event) => {
        setWidth(event.target.value);
    };

    const handleHeightChange = (event) => {
        setHeight(event.target.value);
    };

    return (
        <Dialog
            open={specifyModalVisibility}
            onClose={() => updateSpecifyModalVisibility(false)}
            maxWidth="md"
            fullWidth
            aria-labelledby="specify-part-title"
        >
            <DialogTitle id="specify-part-title">
                <FormattedMessage id="Filter" />
            </DialogTitle>

            
            <DialogContent dividers>


                <Typography gutterBottom>
                    <FormattedMessage id="Size" />
                </Typography>
                <div id="material-bar" style={{ display: 'flex', paddingBottom: '10px' }}>
      <div style={{ zIndex: 4, color: '#000', padding: 0, width: '200px', marginBottom: '10px'}}>
                   
       <Select
            options={[]}

          styles={optionStyle}
          value= '200.00'

        />
        </div>
            
        <div style={{ zIndex: 4, color: '#000', padding: 0, width: '200px' , marginLeft: '12px'}}>

    <Select
          options={[]}
          styles={optionStyle}
          value= '100.00'
        />
        </div>
                </div>


                <Typography gutterBottom>
                    <FormattedMessage id="Material" />
                </Typography>

                {materialSelectors.map((selector, index) => (
                    <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
                        <FilterMaterialSelector
                            onChoiceSelect={handleChoiceSelection}
                            choiceData={choiceData}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={selector.specified}
                                    onChange={(event) => handleSpecifiedChange(index, event)}
                                />
                            }
                            label={<FormattedMessage id="Specified" />}
                        />
                            <IconButton onClick={() => removeMaterialSelector(index)}>
                                <RemoveIcon />
                            </IconButton>
                    </div>
                ))}

                <button
                    variant="outlined"
                    onClick={addMaterialSelector}
                    style={{ marginBottom: 16 }}
                    className={'btn btn-bold btn-outline-brand'}
                >
                    <FormattedMessage id="ADD MATERIAL" />
                </button>

                <Typography gutterBottom>
                    <FormattedMessage id="Price Range" />
                    
                </Typography>
                <Typography gutterBottom>
                {priceRange[0]} - {priceRange[1]} €
                </Typography>


                <Slider
                    value={priceRange}
                    onChange={handlePriceChange}
                    valueLabelDisplay="auto"
                    min={0}
                    max={1000}
                    step={0.1}
                    style={{ width: '65%', margin: '0 auto' }}
                />
             
            </DialogContent>
            <DialogActions>
                <button
                    variant="contained"
                    color="secondary"
                    className={'btn btn-bold btn-outline-brand'}
                    onClick={() => updateSpecifyModalVisibility(false)}
                >
                    <FormattedMessage id="CANCEL" />
                </button>
                <button
                    variant="contained"
                    color="primary"
                    className={'btn btn-bold btn-outline-brand'}
                >
                    <FormattedMessage id="Filter" />
                </button>
            </DialogActions>
        </Dialog>
    );
};

export default FilterModal;
