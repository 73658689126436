/* eslint-disable no-script-url*/
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';

import HeaderDropdownToggle from '../content/CustomDropdowns/HeaderDropdownToggle';
import { ROUTES as routes } from '../../common/constants/routes/routes';

const UserProfile = ({ user, showHi, showAvatar, showBadge, intl }) => {
  const history = useHistory();

  const linkSettings = () => {
    history.push(routes.SETTINGS);
  };

  const websiteSrcLink = {
    en: 'https://www.bmtec.nl/?lang=en',
    nl: 'https://www.bmtec.nl/',
    de: 'https://bmtec-laserschneiden.de/',
  };

  return (
    <>
      {!user && (
        <div className="kt-header__login_guest">
          <div className="kt-header__login_guest-title">
            <FormattedMessage id="AUTH.GENERAL.PART_OF_BMTEC" />
            <a
              href={websiteSrcLink[intl?.locale] || websiteSrcLink['nl']}
              target="_blank" rel="noopener noreferrer"
            >
              BMTEC
            </a>
          </div>

          <div className="kt-header__login_guest-link">
            <Link to="/auth/login">
              <FormattedMessage id="AUTH.GENERAL.ALREADY_HAVE_ACCOUNT" />
            </Link>
          </div>
        </div>
      )}

      <Dropdown
        className="kt-header__topbar-item kt-header__topbar-item--user"
        drop="down"
        alignRight
      >
        <Dropdown.Toggle
          as={HeaderDropdownToggle}
          id="dropdown-toggle-user-profile"
        >
          {' '}
          {user && (
            <div className="kt-header__topbar-user">
              <>
                {showHi && (
                  <span className="kt-header__topbar-welcome kt-hidden-mobile">
                    <FormattedMessage id="GENERAL.HELLO" />
                  </span>
                )}

                {showHi && (
                  <span className="kt-header__topbar-username kt-hidden-mobile">
                    {user?.first_name != ''
                      ? user?.first_name + ' ' + user?.last_name
                      : user?.email}
                  </span>
                )}

                {showAvatar && <img alt="Pic" src={user?.pic} />}

                {showBadge && (
                  <span className="kt-badge kt-badge--username kt-badge--unified-success kt-badge--lg kt-badge--rounded kt-badge--bold">
                    {/* TODO: Should get from currentUser */}
                    John Doe
                  </span>
                )}
              </>
            </div>
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
          {/** ClassName should be 'dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}

          <div className="kt-notification">
            <a className="kt-notification__item">
              <div className="kt-notification__item-icon">
                <i className="flaticon2-calendar-3 kt-font-primary " />
              </div>
              <div
                className="kt-notification__item-details"
                onClick={linkSettings}
              >
                <div className="kt-notification__item-title kt-font-bold">
                  <FormattedMessage id="AUTH.ACCOUNT.MY_ACCOUNT" />
                </div>
                <div className="kt-notification__item-time">
                  <FormattedMessage id="AUTH.ACCOUNT.ACCOUNT_SETTING" />
                </div>
              </div>
            </a>
            <div className="kt-notification__custom">
              <Link
                to="/logout"
                className="btn btn-label-brand btn-sm btn-bold"
              >
                <FormattedMessage id="AUTH.GENERAL.SIGNOUT_BUTTON" />
              </Link>
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

const mapStateToProps = ({ auth: { user } }) => ({
  user,
});

export default injectIntl(connect(mapStateToProps)(UserProfile));
