import { API_URLS } from '../common/constants/api/apiConstants';
import initApi from './apiConfig/apiConfig';

const defaultOptions = {
    params: {
        include:
            'project.products,shipping_address.country,billing_address.country,payment_status,user',
    },
};


export function partLibraryDataRequest(params = {}) {
    const options = {
        params: {
            ...defaultOptions.params,
            ...params,
        },
    }
    return initApi().get(API_URLS.GET_QUOTES_DATA, options);
}


export const getFolders = () => {
    return initApi().get(API_URLS.GET_FOLDERS, {});
};

export const getFolder = (id) => {
   return initApi().get(API_URLS.GET_FOLDER(id));
}

export const getFiles = (id) => {
    return initApi().get(API_URLS.GET_FILES(id));
}

export const createFile = (payload) => {
    return initApi().post(API_URLS.ADD_FILE, {...payload})
}


export const updateFile = (id, payload) => {
    return initApi().patch(API_URLS.EDIT_FILE(id), {...payload})
}

export const updateFolder = (id, payload) => {
    return initApi().patch(API_URLS.EDIT_FOLDER(id), {...payload})
}

export const createFolder = (payload) => {
    return initApi().post(API_URLS.ADD_FOLDER, {...payload})
}

export const getFileSvg = (id) => {
    return initApi().get(API_URLS.GET_FILE_SVG(id), {})
}

export const getFileDxf = (id) => {
    return initApi().get(API_URLS.GET_FILE_DXF(id), {})
}

export const getMaterial = (id) => {
    return  initApi().get(API_URLS.GET_MATERIAL(id), {})
}

export const deleteFile = (id) => {
    return initApi().delete(API_URLS.DELETE_FILE(id), {})
}

export const deleteFolder = (id) => {
    return initApi().delete(API_URLS.DELETE_FOLDER(id), {})
}
