import initApi from './apiConfig/apiConfig';
import { API_URLS } from '../common/constants/api/apiConstants';

const defaultParams = {
  include:
    'products,products.material,products.bendings,order,order.shipping_address.country,order.billing_address.country',
};

export function saveAsQuote(data, projectId, params = {}) {
  return initApi().post(API_URLS.GET_SAVE_AS_QUOTE(projectId), data, {
    params,
  });
}
export function proceedToPayment(data, projectId, params = {}) {
  return initApi().post(API_URLS.GET_PROCEED_TO_PAYMENT(projectId), data, {
    params,
  });
}

export const getCheckout = (id) => {
  return initApi().get(API_URLS.GET_CHECKOUT(id), { params: defaultParams });
};

export async function putCheckout({
  data = {},
  id,
  successCallBack = () => {},
}) {
  const queryString = window.location.search;
  const params = new URLSearchParams(queryString);
  const orderAgainValue = params.get('order_again');
  const reCalcValue = params.get('recalculate');
  const oldProjectID = params.get('old_project_id');

  if (orderAgainValue === 'true') {
    data.order_again = true;
  } else {
    data.order_again = false;
  }
  if (reCalcValue === 'true') {
    data.recalculate = true;
  } else {
    data.recalculate = false;
  }

  if (oldProjectID) {
    data.old_project_id = oldProjectID;
  }

  return initApi()
    .put(API_URLS.PUT_CHECKOUT(id), data, {
      params: defaultParams,
    })
    .then((response) => {
      successCallBack(response);
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

export const getDeliveryDays = () => {
  return initApi().get(API_URLS.GET_DELIVERY_DAYS);
};
export function getCountry() {
  return initApi().get(API_URLS.GET_COUNTRY);
}

export const getShippingTax = (id) => {
  return initApi().get(API_URLS.GET_SHIPPING_TAX(id));
};

export function processPayment(data) {
  var formdata = new FormData();
  formdata.append('payment_mehod', data.payment_method);
  formdata.append('price', data.price);
  return initApi().post(API_URLS.PROCESS_PAYMENT, formdata);
}

export function checkStatus(data) {
  var formdata = new FormData();
  formdata.append('paymentId', data.paymentId);
  return initApi().post(API_URLS.CHECK_STATUS, formdata);
}
