import React from "react";
import MasterPage from "./components/MasterPage";
import CreateFormPage from "./components/CreateFormPage";
import UpdateFormPage from "./components/UpdateFormPage";
import { Route, Switch } from "react-router-dom";
import { ROUTES } from "../../../common/constants/routes/routes";

const News = () => (
  <Switch>
    <Route exact path={ROUTES.ADMIN_UPDATES} component={MasterPage} />
    <Route
      exact
      path={`${ROUTES.ADMIN_UPDATES}/create`}
      component={CreateFormPage}
    />
    <Route
      exact
      path={`${ROUTES.ADMIN_UPDATES}/edit/:id`}
      component={UpdateFormPage}
    />
  </Switch>
);

export default News;
