import { handleActions } from "redux-actions";
import * as actions from "./actions";

const initialState = {
  isProcessing: false,
  data: [],
  notifications: [],
  error: "",
  pagination: {
    per_page: 10,
    current_page: 1,
    count: 0,
    total: 0,
  },
  detail: {
    isProcessing: false,
    data: null,
    status: {
      isProcessing: false,
    },
  },
};

const handlers = {
  [actions.getMessages]: (state) => ({
    ...state,
    isProcessing: true,
  }),
  [actions.getMessagesSuccess]: (state, action) => {
    const { messages, meta } = action.payload;

    return {
      ...state,
      isProcessing: false,
      data: messages,
      pagination: meta.pagination,
    };
  },
  [actions.getMessagesError]: (state, action) => ({
    ...state,
    isProcessing: false,
    error: action.payload,
  }),
  [actions.getNotifications]: (state) => ({
    ...state,
    isProcessing: true,
  }),
  [actions.getNotificationsSuccess]: (state, action) => {
    const { messages, meta } = action.payload;

    return {
      ...state,
      isProcessing: false,
      notifications: messages,
      pagination: meta.pagination,
    };
  },
  [actions.getNotificationsError]: (state, action) => ({
    ...state,
    isProcessing: false,
    error: action.payload,
  }),
  [actions.getMessage]: (state) => ({
    ...state,
    isProcessing: true,
    detail: {
      ...state.detail,
      isProcessing: false,
    },
  }),
  [actions.getMessageSuccess]: (state, action) => ({
    ...state,
    detail: {
      ...state.detail,
      isProcessing: false,
      data: action.payload,
    },
  }),
  [actions.getMessageError]: (state, action) => ({
    ...state,
    detail: {
      ...state.detail,
      isProcessing: false,
      error: action.payload,
    },
  }),
  [actions.setMessage]: (state) => ({
    ...state,
    isProcessing: true,
    detail: {
      ...state.detail,
      isProcessing: false,
    },
  }),
  [actions.setMessageSuccess]: (state) => ({
    ...state,
    isProcessing: false,
    detail: {
      ...state.detail,
      isProcessing: false,
    },
  }),
  [actions.setMessageError]: (state, action) => ({
    ...state,
    isProcessing: false,
    error: action.payload,
    detail: {
      ...state.detail,
      isProcessing: false,
    },
  }),
  [actions.updateMessage]: (state) => ({
    ...state,
    isProcessing: true,
    detail: {
      ...state.detail,
      isProcessing: false,
    },
  }),
  [actions.updateMessageSuccess]: (state) => ({
    ...state,
    isProcessing: false,
    detail: {
      ...state.detail,
      isProcessing: false,
    },
  }),
  [actions.updateMessageError]: (state, action) => ({
    ...state,
    isProcessing: false,
    error: action.payload,
    detail: {
      ...state.detail,
      isProcessing: false,
    },
  }),
  [actions.deleteMessage]: (state) => ({
    ...state,
  }),
  [actions.deleteMessageSuccess]: (state) => ({
    ...state,
  }),
  [actions.deleteMessageError]: (state) => ({
    ...state,
  }),
};

export default handleActions(handlers, initialState);
