import React, { useState, useEffect, useRef } from 'react';
import {
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tabs,
  Tab,
  Box,
} from '@mui/material';
import { FormattedMessage, injectIntl } from 'react-intl';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

import { API_URL, API_URLS } from '../../../common/constants/api/apiConstants';
import {
  toAbsoluteUrl,
  mergeObjInArray,
} from '../../../../_metronic/utils/utils';
import initApi from '../../../crud/apiConfig/apiConfig';
import ConfiguratorPage from '../configurator/ConfiguratorPage';

import PDFDownloader from './PDFDownloader';
import CommonSection from './CommonSection';
import HowItWorks from './HowItWorks';

const SBRMaterialLandingPage = (props) => {
  const { intl } = props;
  const isMounted = useRef(false);

  const [index, setIndex] = useState(null);
  const [topPanel2, setTopPanel2] = useState(0);

  const [materialData, setMaterialData] = useState([]);

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    isMounted.current = true;
    const updateWindowSize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    const scrollToTop = () => {
      window.scrollTo(0, 0);
    };
    scrollToTop();
    window.addEventListener('beforeunload', scrollToTop);

    window.addEventListener('resize', updateWindowSize);
    setTopPanel2(
      document.getElementById('panel2a-header').getBoundingClientRect().top -
        150
    );

    return () => {
      isMounted.current = false;
      window.removeEventListener('resize', updateWindowSize);
      window.removeEventListener('beforeunload', scrollToTop);
    };
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      getMaterialData('SBR');
    }
  }, [isMounted]);

  useEffect(() => {
    setTopPanel2(
      document.getElementById('panel2a-header').getBoundingClientRect().top -
        150
    );
  }, [materialData, windowSize]);

  const [tabValue, setTabValue] = useState(0);

  const TabPanel = (props) => {
    const { children, value, index } = props;

    return (
      <div
        role="tabPanel"
        hidden={value !== index}
        id={`tabPanel-${index}`}
        style={{ width: '100%' }}
      >
        {value === index && (
          <Box p={3}>
            <Typography component="div">{children}</Typography>
          </Box>
        )}
      </div>
    );
  };

  const handleTabChange = (event, value) => {
    event.preventDefault();

    setTabValue(value);
  };

  const getMaterialData = async (material_name) => {
    let { data } = await initApi().get(API_URL + API_URLS.GET_MATERIALS_DATA);

    const filteredMaterialData = data.filter(
      (item) => item.name === material_name
    );

    setMaterialData(mergeObjInArray(filteredMaterialData));
  };

  return (
    <Grid container justifyContent="center" alignItems="center">
      {/* <Grid item container xs={12} className="hb-section"></Grid> */}

      <Grid item xs={12} container className="bp-main-section">
        <Grid item xs={12} container justifyContent="center">
          <Typography variant="h1">
            <FormattedMessage id="SBR.TITLE" />
          </Typography>

          <Typography variant="p">
            <FormattedMessage id="SBR.DESC" />
          </Typography>
        </Grid>
        <Grid item xs={12} container justifyContent="center">
          <ConfiguratorPage isMainPage={true} />
        </Grid>
      </Grid>

      <HowItWorks materialType="SBR" />

      <Grid item xs={12} container justifyContent="center" className="tab-list">
        <Grid item sm={10} xs={12} sx={{ border: 1, borderColor: 'divider' }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab
              label={intl.formatMessage({ id: 'Materiaal informatie' })}
              index={0}
            />
            <Tab
              label={intl.formatMessage({ id: 'Technische informatie' })}
              index={1}
            />
            <Tab label={intl.formatMessage({ id: 'Datasheets' })} index={2} />
          </Tabs>
        </Grid>

        <TabPanel value={tabValue} index={0}>
          <table
            className="material-info-table table table-striped w-100"
            style={{ marginBottom: 0, overflowX: 'auto' }}
          >
            <thead>
              <tr>
                <th colSpan="3" style={{ fontSize: '18px' }}>
                  <FormattedMessage id="Productinformatie" />
                </th>
                <th colSpan="4" style={{ fontSize: '18px' }}>
                  <FormattedMessage id="Bewerkingen" />
                </th>
              </tr>

              <tr>
                <th scope="col" className={'w-15'}>
                  <FormattedMessage id="Plaat diktes" />
                </th>
                <th scope="col" className={'w-15'}>
                  <FormattedMessage id="Plaat afmetingen" />
                </th>
                <th scope="col" className={'w-15'}>
                  <FormattedMessage id="Kleuren" />
                </th>
                <th scope="col" className={'w-15 col-with-icon'}>
                  <span>
                    <FormattedMessage id="Te laseren diktes" />
                    <i className="cw-materials-icon icon-laser"></i>
                  </span>
                </th>
                <th scope="col" className={'w-15 col-with-icon'}>
                  <span>
                    <FormattedMessage id="Te frezen diktes" />
                    <i className="cw-materials-icon icon-milling"></i>
                  </span>
                </th>
                <th scope="col" className={'w-15 col-with-icon'}>
                  <span>
                    <FormattedMessage id="Zettingen mogelijk" />
                    <i className="cw-materials-icon icon-bending"></i>
                  </span>
                </th>
                <th scope="col" className={'w-15'}>
                  <FormattedMessage id="Verlijmbaar" />
                </th>
              </tr>
            </thead>
            <tbody>
              {materialData.map(
                ({
                  thickness,
                  plate_measurement,
                  color,
                  color_de,
                  color_en,
                  has_to_be_milled,
                  is_bendable,
                }) => {
                  return (
                    <tr key={thickness + color}>
                      <td>{thickness} mm</td>
                      <td>{plate_measurement} mm</td>
                      <td className="td-flex">
                        {intl?.locale === 'nl' &&
                          color.map((item, idx) => (
                            <span key={idx + item}>{item}</span>
                          ))}

                        {intl?.locale === 'de' &&
                          color_de.map((item, idx) => (
                            <span key={idx + item}>{item}</span>
                          ))}

                        {intl?.locale === 'en' &&
                          color_en.map((item, idx) => (
                            <span key={idx + item}>{item}</span>
                          ))}
                      </td>
                      <td className="td-flex">
                        {has_to_be_milled?.map((item, idx) =>
                          item == false ? (
                            <span key={idx + color}>
                              <CheckIcon htmlColor="#7ed321" />
                            </span>
                          ) : (
                            <span key={idx + color}>
                              <CloseIcon htmlColor="#ff0000" />
                            </span>
                          )
                        )}
                      </td>
                      <td className="td-flex">
                        {has_to_be_milled?.map((item, idx) =>
                          item == false ? (
                            <span key={idx + color}>
                              <CloseIcon htmlColor="#ff0000" />
                            </span>
                          ) : (
                            <span key={idx + color}>
                              <CheckIcon htmlColor="#7ed321" />
                            </span>
                          )
                        )}
                      </td>
                      <td className="td-flex" align="center">
                        {color.map((idx, item) =>
                          is_bendable == false ? (
                            <span key={idx + item}>
                              <CloseIcon htmlColor="#ff0000" />
                            </span>
                          ) : (
                            <span key={idx + item}>
                              <CheckIcon htmlColor="#7ed321" />
                            </span>
                          )
                        )}
                      </td>
                      <td className="td-flex" align="center">
                        {color.map((idx, item) => (
                          <span key={idx + item}>
                            <CheckIcon htmlColor="#7ed321" />
                          </span>
                        ))}
                      </td>
                    </tr>
                  );
                }
              )}
            </tbody>
          </table>

          <table
            className="material-info-table table table-striped w-100"
            style={{ marginBottom: 0, overflowX: 'auto' }}
          >
            <thead>
              <tr>
                <th scope="col" className={'w-50'} style={{ fontSize: '18px' }}>
                  <FormattedMessage id="Toepassingen" />
                </th>
                <th scope="col" className={'w-50'} style={{ fontSize: '18px' }}>
                  <FormattedMessage id="Verdere eigenschappen" />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <ul>
                    <li>
                      <CheckIcon
                        htmlColor="#7ed321"
                        style={{ marginRight: '8px' }}
                      />
                      <FormattedMessage id="SBR.CHECK.LIST_1.ITEM_1" />
                    </li>
                    <li>
                      <CheckIcon
                        htmlColor="#7ed321"
                        style={{ marginRight: '8px' }}
                      />
                      <FormattedMessage id="SBR.CHECK.LIST_1.ITEM_2" />
                    </li>
                    <li>
                      <CheckIcon
                        htmlColor="#7ed321"
                        style={{ marginRight: '8px' }}
                      />
                      <FormattedMessage id="SBR.CHECK.LIST_1.ITEM_3" />
                    </li>
                    <li>
                      <CheckIcon
                        htmlColor="#7ed321"
                        style={{ marginRight: '8px' }}
                      />
                      <FormattedMessage id="SBR.CHECK.LIST_1.ITEM_4" />
                    </li>
                    <li>
                      <CheckIcon
                        htmlColor="#7ed321"
                        style={{ marginRight: '8px' }}
                      />
                      <FormattedMessage id="SBR.CHECK.LIST_1.ITEM_5" />
                    </li>
                    <li>
                      <CheckIcon
                        htmlColor="#7ed321"
                        style={{ marginRight: '8px' }}
                      />
                      <FormattedMessage id="SBR.CHECK.LIST_1.ITEM_6" />
                    </li>
                    <li>
                      <CheckIcon
                        htmlColor="#7ed321"
                        style={{ marginRight: '8px' }}
                      />
                      <FormattedMessage id="SBR.CHECK.LIST_1.ITEM_7" />
                    </li>
                  </ul>
                </td>

                <td>
                  <ul>
                    <li>
                      <CheckIcon
                        htmlColor="#7ed321"
                        style={{ marginRight: '8px' }}
                      />
                      <FormattedMessage id="SBR.CHECK.LIST_2.ITEM_1" />
                    </li>
                    <li>
                      <CheckIcon
                        htmlColor="#7ed321"
                        style={{ marginRight: '8px' }}
                      />
                      <FormattedMessage id="SBR.CHECK.LIST_2.ITEM_2" />
                    </li>
                    <li>
                      <CheckIcon
                        htmlColor="#7ed321"
                        style={{ marginRight: '8px' }}
                      />
                      <FormattedMessage id="SBR.CHECK.LIST_2.ITEM_3" />
                    </li>
                    <li>
                      <CheckIcon
                        htmlColor="#7ed321"
                        style={{ marginRight: '8px' }}
                      />
                      <FormattedMessage id="SBR.CHECK.LIST_2.ITEM_4" />
                    </li>
                    <li>
                      <CheckIcon
                        htmlColor="#7ed321"
                        style={{ marginRight: '8px' }}
                      />
                      <FormattedMessage id="SBR.CHECK.LIST_2.ITEM_5" />
                    </li>
                    <li>
                      <CheckIcon
                        htmlColor="#7ed321"
                        style={{ marginRight: '8px' }}
                      />
                      <FormattedMessage id="SBR.CHECK.LIST_2.ITEM_6" />
                    </li>
                    <li>
                      <CheckIcon
                        htmlColor="#7ed321"
                        style={{ marginRight: '8px' }}
                      />
                      <FormattedMessage id="SBR.CHECK.LIST_2.ITEM_7" />
                    </li>
                    <li>
                      <CheckIcon
                        htmlColor="#7ed321"
                        style={{ marginRight: '8px' }}
                      />
                      <FormattedMessage id="SBR.CHECK.LIST_2.ITEM_8" />
                    </li>
                    <li>
                      <CheckIcon
                        htmlColor="#7ed321"
                        style={{ marginRight: '8px' }}
                      />
                      <FormattedMessage id="SBR.CHECK.LIST_2.ITEM_9" />
                    </li>
                    <li>
                      <CheckIcon
                        htmlColor="#7ed321"
                        style={{ marginRight: '8px' }}
                      />
                      <FormattedMessage id="SBR.CHECK.LIST_2.ITEM_10" />
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <table
            className="material-info-table table table-striped w-100"
            style={{
              border: '1px solid rgba(0, 0, 0, 0.12)',
              overflowX: 'auto',
            }}
          >
            <thead>
              <tr>
                <th
                  colSpan={4}
                  scope="col"
                  className={'w-100'}
                  style={{ fontSize: '18px' }}
                >
                  <FormattedMessage id="Algemeen" />
                </th>
              </tr>
              <tr scope="col" className={'w-100'}>
                <th scope="col" className={'w-30'}>
                  <FormattedMessage id="Materiële eigenschappen" />
                </th>
                <th scope="col" className={'w-30'}>
                  <FormattedMessage id="Gegevens en waarden" />
                </th>
                <th scope="col" className={'w-30'}>
                  <FormattedMessage id="Standaard" />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <FormattedMessage id="Materiaal beschrijving" />
                </td>
                <td>
                  <FormattedMessage id="SBR (Styreen Butadieen Rubber)" />
                </td>
                <td></td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="Materiaaldikte en toleranties" />
                </td>
                <td>1 - 20 mm</td>
                <td>
                  <FormattedMessage id="ISO 3302 Deel 1 Klasse ST2" />
                </td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="Vulcanisatiemethode" />
                </td>
                <td>
                  <FormattedMessage id="Zwavel" />
                </td>
                <td></td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="Temperatuurbereik" />
                </td>
                <td>-30 °C / +80 °C</td>
                <td></td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="Soortelijk gewicht" />
                </td>
                <td>1.50 g/cm³</td>
                <td>ISO 845-88 / ASTM D 3575</td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="Hardheid Shore A" />
                </td>
                <td>65 (+/- 5)</td>
                <td>ASTM D 2240</td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="Rek bij breuk" />
                </td>
                <td>Min. 200%</td>
                <td>ISO 37</td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="Treksterkte" />
                </td>
                <td>3.0 MPa (3 N/mm²)</td>
                <td>ISO 37</td>
              </tr>
            </tbody>
          </table>
          <table
            className="material-info-table table table-striped w-100"
            style={{
              border: '1px solid rgba(0, 0, 0, 0.12)',
              overflowX: 'auto',
            }}
          >
            <thead>
              <tr>
                <th
                  colSpan={4}
                  scope="col"
                  className={'w-100'}
                  style={{ fontSize: '18px' }}
                >
                  <FormattedMessage id="Resistentie" />
                </th>
              </tr>
              <tr scope="col" className={'w-100'}>
                <th scope="col" className={'w-50'}>
                  <FormattedMessage id="Type" />
                </th>
                <th scope="col" className={'w-50'}>
                  <FormattedMessage id="Mate van weerstand" />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <FormattedMessage id="Verdunde zuren en alkalien" />
                </td>
                <td>
                  <FormattedMessage id="Matig" />
                </td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="Geconcentreerde zuren en basen" />
                </td>
                <td>
                  <FormattedMessage id="Niet geschikt" />
                </td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="Ozon en UV (weerbestendigheid)" />
                </td>
                <td>
                  <FormattedMessage id="Slecht tot Matig" />
                </td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="Olie, vetten, brandstoffen en smeermiddelen" />
                </td>
                <td>
                  <FormattedMessage id="Niet geschikt" />
                </td>
              </tr>
            </tbody>
          </table>
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <PDFDownloader
            title="Tolerance sheet thickness SBR - CutWise"
            pdfUrl="https://api.prod.cutwise.nl/assets/images/Tolerance sheet thickness PUR - CutWise.pdf"
            pdfFileName="toleranties"
          />

          <PDFDownloader
            title="Datasheet SBR CutWise"
            pdfUrl="https://api.prod.cutwise.nl/assets/images/Datasheet SBR CutWise.pdf"
            pdfFileName="technisch_datablad"
          />
        </TabPanel>
      </Grid>

      <Grid
        item
        container
        xs={12}
        sm={12}
        md={12}
        alignItems="center"
        justifyContent="center"
        className="materials-info-section"
      >
        <Grid item container xs={12} sm={10} alignItems="flex-start">
          <Grid item sm={6} xs={12}>
            <Grid item xs={12}>
              <Typography variant="h2">
                <FormattedMessage id="SBR.SNIJDEN" />
              </Typography>
              <Typography variant="body1">
                <FormattedMessage id="SBR.SNIJDEN.BODY" />
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h2">
                <FormattedMessage id="SBR.DESC.TITLE" />
              </Typography>
              <Typography variant="body1">
                <FormattedMessage id="SBR.DESC.BODY" />
              </Typography>
            </Grid>
          </Grid>

          <Grid item sm={6} xs={12} container justifyContent="center">
            <img
              src={toAbsoluteUrl('/clients-logos/materials-hb.svg')}
              width="100%"
              height="auto"
              style={{ padding: '20px', maxWidth: '474px' }}
            />
          </Grid>

          <Grid item xs={12} className="accordion-list">
            <Accordion
              TransitionProps={{ unmountOnExit: true }}
              expanded={index === 0}
              onChange={(event, expanded) => {
                event.preventDefault();

                setIndex(expanded ? 0 : null);
              }}
            >
              <AccordionSummary
                expandIcon={
                  index === 0 ? (
                    <RemoveCircleOutlineIcon fontSize="large" />
                  ) : (
                    <AddCircleOutlineIcon fontSize="large" />
                  )
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="body1">
                  <FormattedMessage id="SBR.ACCORDION_1.TITLE" />
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="body1"
                  style={{
                    marginBottom: '0',
                    fontSize: '20px',
                    fontWeight: 600,
                  }}
                >
                  <FormattedMessage id="SBR.ACCORDION_1.LIST.TITLE" />
                </Typography>

                <ul>
                  <li>
                    <strong>
                      <FormattedMessage id="SBR.ACCORDION_1.LIST.LIST_1" />
                    </strong>
                    &nbsp;
                    <FormattedMessage id="SBR.ACCORDION_1.LIST.LIST_1_DESC" />
                  </li>
                  <li>
                    <strong>
                      <FormattedMessage id="SBR.ACCORDION_1.LIST.LIST_2" />
                    </strong>
                    &nbsp;
                    <FormattedMessage id="SBR.ACCORDION_1.LIST.LIST_2_DESC" />
                  </li>
                  <li>
                    <strong>
                      <FormattedMessage id="SBR.ACCORDION_1.LIST.LIST_3" />
                    </strong>
                    &nbsp;
                    <FormattedMessage id="SBR.ACCORDION_1.LIST.LIST_3_DESC" />
                  </li>
                  <li>
                    <strong>
                      <FormattedMessage id="SBR.ACCORDION_1.LIST.LIST_4" />
                    </strong>
                    &nbsp;
                    <FormattedMessage id="SBR.ACCORDION_1.LIST.LIST_4_DESC" />
                  </li>
                  <li>
                    <strong>
                      <FormattedMessage id="SBR.ACCORDION_1.LIST.LIST_5" />
                    </strong>
                    &nbsp;
                    <FormattedMessage id="SBR.ACCORDION_1.LIST.LIST_5_DESC" />
                  </li>
                  <li>
                    <strong>
                      <FormattedMessage id="SBR.ACCORDION_1.LIST.LIST_6" />
                    </strong>
                    &nbsp;
                    <FormattedMessage id="SBR.ACCORDION_1.LIST.LIST_6_DESC" />
                  </li>
                  <li>
                    <strong>
                      <FormattedMessage id="SBR.ACCORDION_1.LIST.LIST_7" />
                    </strong>
                    &nbsp;
                    <FormattedMessage id="SBR.ACCORDION_1.LIST.LIST_7_DESC" />
                  </li>
                </ul>
              </AccordionDetails>
            </Accordion>
            <Accordion
              TransitionProps={{ unmountOnExit: true }}
              expanded={index === 1}
              onChange={(event, expanded) => {
                event.preventDefault();

                setIndex(expanded ? 1 : null);
                window.scrollTo(0, topPanel2);
              }}
            >
              <AccordionSummary
                expandIcon={
                  index === 1 ? (
                    <RemoveCircleOutlineIcon fontSize="large" />
                  ) : (
                    <AddCircleOutlineIcon fontSize="large" />
                  )
                }
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography variant="body1">
                  <FormattedMessage id="SBR.ACCORDION_2.TITLE" />
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="body1"
                  style={{
                    marginBottom: '0',
                    fontSize: '20px',
                    fontWeight: 600,
                  }}
                >
                  <FormattedMessage id="SBR.ACCORDION_2.LIST.TITLE" />
                </Typography>

                <ul>
                  <li>
                    <FormattedMessage id="SBR.ACCORDION_2.LIST.LIST_1" />
                  </li>
                  <li>
                    <FormattedMessage id="SBR.ACCORDION_2.LIST.LIST_2" />
                  </li>
                  <li>
                    <FormattedMessage id="SBR.ACCORDION_2.LIST.LIST_3" />
                  </li>
                  <li>
                    <FormattedMessage id="SBR.ACCORDION_2.LIST.LIST_4" />
                  </li>
                  <li>
                    <FormattedMessage id="SBR.ACCORDION_2.LIST.LIST_5" />
                  </li>
                  <li>
                    <FormattedMessage id="SBR.ACCORDION_2.LIST.LIST_6" />
                  </li>
                  <li>
                    <FormattedMessage id="SBR.ACCORDION_2.LIST.LIST_7" />
                  </li>
                </ul>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </Grid>

      <CommonSection materialType="SBR" />
    </Grid>
  );
};

export default injectIntl(SBRMaterialLandingPage);
