import React, { useState, useEffect } from 'react';
import qs from 'query-string';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  PortletBody,
  PortletFooter,
} from '../../../../../partials/content/Portlet';
import { solid, regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Link, useHistory } from 'react-router-dom';
import {
  API_URL,
  API_URLS,
} from '../../../../../common/constants/api/apiConstants';
import initApi from '../../../../../crud/apiConfig/apiConfig';

import idealImage from '../../../../../../assets/images/icons_mollie/payments/ideal@2x.png';
import paypalImage from '../../../../../../assets/images/icons_mollie/payments/paypal@2x.png';
import bancontactImage from '../../../../../../assets/images/icons_mollie/payments/bancontact@2x.png';
import sepaImage from '../../../../../../assets/images/cutwise-overschrijving-icoon@2x.png';

import {
  OverlayTrigger,
  Tooltip,
  Table,
  Image,
  Badge,
  ListGroup,
  Container,
  Modal,
  Button,
} from 'react-bootstrap';
import { Chip } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment';
import 'moment/locale/nl';
import 'moment/locale/de';
import 'moment/locale/en-gb';

export const All = ({ admin, search, filter, filterActive, isMounted }) => {
  const intl = useIntl();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [modalVisibility, setModalVisibility] = useState(false);
  const [deleteId, setDeleteId] = useState(undefined);
  const [data, setData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [page, setPage] = useState(1);

  const popperConfig = {
    modifiers: { preventOverflow: { boundariesElement: 'viewport' } },
  };

  const getOrdersData = async () => {
    const query = {
      page: page,
      search: search,
      filter: filterActive ? JSON.stringify(filter) : '',
    };

    let fetchOrdersUrl =
      API_URL +
      API_URLS.GET_ORDERS_DATA +
      `?include=project.products,shipping_address.country,billing_address.country,payment_status,user&${qs.stringify(
        query
      )}`;
    let { data } = await initApi().get(fetchOrdersUrl);
    // console.log(data);

    if (isMounted.current) {
      setTotalItems(data.meta.pagination.total);

      if (page == 1) {
        setData(data.orders);
      } else {
        setData((current) => [...current, ...data.orders]);
      }
    }
  };

  useEffect(() => {
    if (isMounted.current) {
      getOrdersData();
    }
  }, [page, filterActive, filter, search, isMounted]);

  useEffect(() => {
    setPage(1);
  }, [search, filterActive, filter]);

  useEffect(() => {
    if (data.length) {
      setLoading(false);
    }
  }, [data]);

  const formatMoney = (value) => {
    if (value) {
      value = Number(value).toFixed(2);
    }
    return value
      ? '€ ' +
          value
            .toString()
            .replace('.', ',')
            .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      : '€ 0';
  };

  const navigateToCustomer = (event, userId) => {
    event.stopPropagation();
    history.push(`/users/details/${userId}`);
  };

  const navigateToOrder = (orderId) => {
    history.push(`/orders/details/${orderId}`);
  };

  const copyOrder = async (event, projectId) => {
    event.stopPropagation();
    const response = await initApi().get(
      API_URL + API_URLS.COPY_ORDER_TO_QUOTE(projectId)
    );
    if (response?.data?.success) {
      history.push(`/checkout/${response.data.project_id}?order_again=true`);
    }
  };

  const getCustomer = (item) => {
    let customer =
      item.billing_address &&
      item.billing_address.company_name &&
      item.billing_address.company_name.length > 0
        ? item.billing_address.company_name
        : item.billing_address &&
          item.billing_address.first_name &&
          item.billing_address.last_name
        ? item.billing_address.first_name + ' ' + item.billing_address.last_name
        : item.shipping_address &&
          item.shipping_address.company_name &&
          item.shipping_address.company_name.length > 0
        ? item.shipping_address.company_name
        : item.shipping_address &&
          item.shipping_address.first_name &&
          item.shipping_address.last_name
        ? item.shipping_address.first_name +
          ' ' +
          item.shipping_address.last_name
        : '';

    return (
      <Link
        to={`/users/details/${item.user.id}`}
        className={'customer-click'}
        onClick={(event) => {
          navigateToCustomer(event, item.user.id);
        }}
      >
        {customer}
      </Link>
    );
  };

  const onLoadMoreClick = () => {
    setLoading(true);
    setPage(page + 1);
  };

  const updateModalVisibility = (show) => {
    setModalVisibility(show);
  };

  const orderDeleteModal = (event, orderId) => {
    event.stopPropagation();
    setDeleteId(orderId);
    updateModalVisibility(true);
  };

  const deleteOrder = async () => {
    if (deleteId) {
      await initApi().delete(API_URL + API_URLS.DELETE_ORDER(deleteId));
      setDeleteId(undefined);
    }
    updateModalVisibility(false);
    history.go(0);
  };

  const getOrderStatusIcon = (status) => {
    switch (status) {
      case 'accepted':
        return (
          <Button className="fa-layers fa-fw order-status-icon">
            <FontAwesomeIcon
              icon={solid('circle')}
              color={'#FF0000'}
              opacity={'10%'}
            />
            <FontAwesomeIcon
              icon={solid('check-double')}
              color={'#FF0000'}
              transform="shrink-8"
            />
          </Button>
        );
      case 'concept':
        return (
          <Button className="fa-layers fa-fw order-status-icon">
            <FontAwesomeIcon
              icon={solid('circle')}
              color={'#CE9D19'}
              opacity={'10%'}
            />
            <FontAwesomeIcon
              icon={regular('clock')}
              color={'#CE9D19'}
              transform="shrink-8"
            />
          </Button>
        );
      case 'deleted':
        return (
          <Button className="fa-layers fa-fw order-status-icon">
            <FontAwesomeIcon
              icon={solid('circle')}
              color={'#CE9D19'}
              opacity={'10%'}
            />
            <FontAwesomeIcon
              icon={regular('clock')}
              color={'#CE9D19'}
              transform="shrink-8"
            />
          </Button>
        );
      case 'production_finished':
        return (
          <Button className="fa-layers fa-fw order-status-icon">
            <FontAwesomeIcon
              icon={solid('circle')}
              color={'#CE9D19'}
              opacity={'10%'}
            />
            <FontAwesomeIcon
              icon={regular('clock')}
              color={'#CE9D19'}
              transform="shrink-8"
            />
          </Button>
        );
      case 'quoted':
        return (
          <Button className="fa-layers fa-fw order-status-icon">
            <FontAwesomeIcon
              icon={solid('circle')}
              color={'#CE9D19'}
              opacity={'10%'}
            />
            <FontAwesomeIcon
              icon={regular('clock')}
              color={'#CE9D19'}
              transform="shrink-8"
            />
          </Button>
        );
      case 'send_to_customer':
        return (
          <Button className="fa-layers fa-fw order-status-icon">
            <FontAwesomeIcon
              icon={solid('circle')}
              color={'#41A061'}
              opacity={'10%'}
            />
            <FontAwesomeIcon
              icon={solid('truck-fast')}
              color={'#41A061'}
              transform="shrink-8"
            />
          </Button>
        );
      default:
        return (
          <Button className="fa-layers fa-fw order-status-icon">
            <FontAwesomeIcon
              icon={solid('circle')}
              color={'#CE9D19'}
              opacity={'10%'}
            />
            <FontAwesomeIcon
              icon={regular('clock')}
              color={'#CE9D19'}
              transform="shrink-8"
            />
          </Button>
        );
    }
  };

  const getPaymentChip = (item) => {
    if (item.payment_type == 'on_account') {
      return (
        <Chip
          className={'order-chip-on-account'}
          label={intl.formatMessage({ id: 'CHECKOUT.ON_ACCOUNT' })}
          variant={'outlined'}
          size={'medium'}
        />
      );
    } else {
      return item.payment_status.id == 6 ? (
        <Chip
          className={'order-chip-success'}
          label={intl.formatMessage({ id: item.payment_status.title })}
          variant={'outlined'}
          size={'medium'}
        />
      ) : (
        <Chip
          className={'order-chip-info'}
          label={intl.formatMessage({ id: item.payment_status.title })}
          variant={'outlined'}
          size={'medium'}
        />
      );
    }
  };

  if (loading && data?.length === 0) {
    return (
      <div
        style={{
          borderTop: '1px solid #ebedf2',
          marginTop: '10px',
          marginLeft: '10px',
          padding: '10px',
        }}
      >
        <FormattedMessage id="no_records_to_display" />
      </div>
    );
  } else
    return (
      <PortletBody>
        {data && (
          <Table className={'orders-table'} responsive>
            <tbody>
              {data.map((item) => {
                return (
                  <tr
                    className={'row-height'}
                    onClick={() => {
                      navigateToOrder(item.id);
                    }}
                    key={item.id}
                  >
                    <td className={'fa-4x'}>
                      <OverlayTrigger
                        popperConfig={popperConfig}
                        delay={{ show: 100, hide: 300 }}
                        container={document.querySelector(
                          `[aria-describedby="tooltip-status-${item.id}"]`
                        )}
                        placement={'top'}
                        trigger={['hover', 'click']}
                        overlay={
                          <Tooltip id={`tooltip-status-${item.id}`}>
                            {intl.formatMessage({ id: item.type })}
                          </Tooltip>
                        }
                      >
                        {getOrderStatusIcon(item.type)}
                      </OverlayTrigger>
                    </td>

                    <td className={'product-image-column hide-mobile'}>
                      <div className={'product-images-container'}>
                        <Badge className={'product-badge'}>
                          {item.project.products.length}
                        </Badge>
                        <div>
                        <Image
                          src={
                            API_URL +
                            '/projects/' +
                            item.project.products[0]?.svg
                          }
                          className={'product-images image1'}
                          height={'100px'}
                          style={{
                            padding: '15px'
                          }}
                        />
                        </div>
                        {item.project.products[1] && (
                          <Image
                            src={
                              API_URL +
                              '/projects/' +
                              item.project.products[1]?.svg
                            }
                            className={'product-images image2'}
                            height={'100px'}
                          />
                        )}

                        {item.project.products[2] && (
                          <Image
                            src={
                              API_URL +
                              '/projects/' +
                              item.project.products[2]?.svg
                            }
                            className={'product-images image3'}
                            height={'100px'}
                          />
                        )}
                      </div>
                    </td>

                    <td>
                      <ListGroup as={'ol'} variant="flush">
                        <ListGroup.Item as={'li'} style={{ border: 'none' }}>
                          <div className="ms-2 me-auto">
                            <div className={'font-weight-bold'}>
                              {intl.formatMessage({ id: 'ORDER.ORDER' })} #{' '}
                              {item.id}
                            </div>
                            {item.reference && (
                              <OverlayTrigger
                                popperConfig={popperConfig}
                                delay={{ show: 100, hide: 300 }}
                                placement={'top'}
                                trigger={['hover', 'click']}
                                overlay={
                                  <Tooltip id={`tooltip-${item.id}`}>
                                    {item.reference}
                                  </Tooltip>
                                }
                              >
                                <div
                                  className={'d-inline-block text-truncate'}
                                  style={{ maxWidth: '150px' }}
                                >
                                  <FormattedMessage id="ORDER.REFERENCE" />:{' '}
                                  {item.reference}
                                </div>
                              </OverlayTrigger>
                            )}
                            {admin && (
                              <div className={'font-weight-bold'}>
                                {getCustomer(item)}
                              </div>
                            )}
                          </div>
                        </ListGroup.Item>
                      </ListGroup>
                    </td>

                    <td>
                      {moment
                        .unix(item.created_at)
                        .locale(intl.locale)
                        .format('dddd DD MMMM YYYY')}
                    </td>

                    <td className={'hide-mobile'}>{getPaymentChip(item)}</td>

                    <td className={'hide-mobile'}>
                      {item.payment_type == 'ideal' && (
                        <Image src={idealImage} height={'40px'} />
                      )}
                      {item.payment_type == 'paypal' && (
                        <Image src={paypalImage} height={'40px'} />
                      )}
                      {item.payment_type == 'bancontact' && (
                        <Image src={bancontactImage} height={'40px'} />
                      )}
                      {item.payment_type == 'on_account' && (
                        <Image src={sepaImage} height={'40px'} />
                      )}
                    </td>

                    <td className={'text-success font-weight-bold'}>
                      {formatMoney(item.project.price)}
                    </td>

                    <td className={'hide-mobile'}>
                      <button
                        type="button"
                        className="btn btn-brand btn-bold"
                        onClick={(e) => {
                          copyOrder(e, item.project.id);
                        }}
                      >
                        <FormattedMessage id="ORDER.REPEAT_ORDER" />
                      </button>
                    </td>

                    {admin && (
                      <td className={'order-remove hide-mobile'}>
                        <FontAwesomeIcon
                          icon={regular('trash-can')}
                          size={'1x'}
                          color={'#B7B7B7'}
                          cursor={'pointer'}
                          onClick={(e) => {
                            orderDeleteModal(e, item.id);
                          }}
                        />
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        )}
        {data.length == totalItems && (
          <PortletFooter
            className={'orders-footer'}
            style={{ textAlign: 'center' }}
          >
            <Container>
              <span className={'orders-pagination'}>
                {data.length} {intl.formatMessage({ id: 'ORDER.OF' })}{' '}
                {totalItems}
              </span>
            </Container>
            <br />
            <Container>
              <button
                type="button"
                className="btn btn-label-brand btn-bold"
                disabled
              >
                <FormattedMessage id="ORDER.NO_MORE_ITEMS" />
              </button>
            </Container>
          </PortletFooter>
        )}
        {data.length < totalItems && (
          <PortletFooter
            className={'orders-footer'}
            style={{ textAlign: 'center' }}
          >
            <Container>
              <span className={'orders-pagination'}>
                {data.length} {intl.formatMessage({ id: 'ORDER.OF' })}{' '}
                {totalItems}
              </span>
            </Container>
            <br />
            <Container>
              {loading && (
                <button
                  type="button"
                  className="btn btn-label-brand btn-bold"
                  onClick={(event) => onLoadMoreClick(event)}
                >
                  <FontAwesomeIcon icon={solid('spinner')} size={'1x'} spin />{' '}
                  <FormattedMessage id="ORDER.LOADING" />
                </button>
              )}
              {!loading && (
                <button
                  type="button"
                  className="btn btn-label-brand btn-bold"
                  onClick={(event) => onLoadMoreClick(event)}
                >
                  <FontAwesomeIcon icon={solid('chevron-down')} size={'1x'} />{' '}
                  <FormattedMessage id="ORDER.LOAD_MORE" />
                </button>
              )}
            </Container>
          </PortletFooter>
        )}
        <Modal
          show={modalVisibility}
          onHide={() => {
            updateModalVisibility(false);
          }}
          size={'md'}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <FormattedMessage id="ORDER.DELETE_ORDER" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormattedMessage id="ORDER.CONFIRM_DELETE" />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant={'secondary'}
              onClick={() => {
                updateModalVisibility(false);
              }}
            >
              <FormattedMessage id="CANCEL" />
            </Button>
            <Button
              onClick={(e) => {
                deleteOrder(e);
              }}
            >
              <FormattedMessage id="DELETE" />
            </Button>
          </Modal.Footer>
        </Modal>
      </PortletBody>
    );
};
