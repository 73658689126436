import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Form, Col, Button } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Paper } from '@material-ui/core';
import { updateCountry, getCountry } from '../../../store/countries/actions';
import { ROUTES } from '../../../common/constants/routes/routes';

const DetailsPage = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { data, isProcessing } = props;
  const [form, setForm] = useState({});
  const [validated, setValidated] = useState(false);
  const fields = [
    { title: 'Name', name: 'name', type: 'text', required: true },
  ];

  useEffect(() => {
    if (id) {
      dispatch(getCountry(id));
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (data && id) {
      setForm(data);
    } else {
      setForm({});
    }
  }, [data]); // eslint-disable-line

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);

    const payload = {};
    fields.forEach((field) => {
      switch (field.type) {
        case 'integer':
          payload[field.name] = parseInt(form[field.name]);
          break;
        case 'float':
          payload[field.name] = parseFloat(form[field.name]);
        case 'select':
          if (field.multiple) {
            let payloadSelect = [];
            field.options.forEach((option) => {
              if (form[field.name].includes(option.id)) {
                payloadSelect.push({ id: option.id });
              }
            });
            payload[field.name] = payloadSelect;
          } else {
            let payloadSelect = null;

            field.options.forEach((option) => {
              if (option.id == form[field.name]) {
                payloadSelect = option;
              }
            });
            payload[field.name] = payloadSelect;
          }

          break;
        default:
          payload[field.name] = form[field.name];
      }
    });

    dispatch(
      updateCountry({
        id: id,
        data: payload,
        successCallBack: () => {
          history.push(ROUTES.COUNTRIES);
        },
      })
    );
  };

  const handleCancel = () => {
    history.push(ROUTES.COUNTRIES);
  };

  const onChange = (field) => (event) => {
    setForm({ ...form, [field]: event.target.value });
  };

  return isProcessing ? (
    'Loading...'
  ) : (
    <div className="row">
      <div className="col-md-12">
        <div className="kt-section">
          <div className="kt-section__content">
            <Paper className="p-5">
              <h1 className="kt-invoice__title mb-5">
                Country {id ? 'Edit' : 'Add'}
              </h1>

              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                {fields.map((field) => (
                  <Form.Group key={field.name} as={Row} controlId="">
                    <Form.Label column sm={2}>
                      {field.title}
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Control
                        type="text"
                        value={form[field.name] || ''}
                        onChange={onChange(field.name)}
                        required={field.required}
                      />
                    </Col>
                  </Form.Group>
                ))}
                <Row>
                  <Col sm={12}>
                    <Button className="pull-right" type="submit">
                      Save{' '}
                    </Button>
                    <Button
                      className="pull-right mr-2"
                      variant="light"
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Paper>
          </div>
        </div>
      </div>
    </div>
  );
};

DetailsPage.propTypes = {
  isProcessing: PropTypes.bool.isRequired,
  data: PropTypes.object,
};

const mapStateToProps = ({
  countries: {
    detailedCountry: { data, isProcessing },
  },
}) => ({
  data,
  isProcessing,
});

export default connect(mapStateToProps)(DetailsPage);
