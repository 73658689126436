const initialState = {
  form: {
    user: null,
    price: 0,
    order: {},
    products: [],
    countries: [],
    check_status: [],
    payment_info: [],
    shippingAddress: {
      company_name: '',
      first_name: '',
      last_name: '',
      address: '',
      house_number: '',
      ext: '',
      postal_code: '',
      phone_number: '',
      tax_number: '',
      city: '',
      country: null,
    },
    billingAddress: {
      company_name: '',
      first_name: '',
      last_name: '',
      address: '',
      house_number: '',
      ext: '',
      postal_code: '',
      phone_number: '',
      tax_number: '',
      city: '',
      country: null,
    },
    refernce: '',
    payment_type: '',
    shipping_type: 'ship',
    delivery_day: 14,
  },
  isProcessing: false,
  error: null,
};

export const CHECKOUT_ACTIONS = {
  FULFILL_FORM: 'FULFILL_CHECKOUT_FORM',
  SET_VALUE: 'SET_CHECKOUT_FORM_VALUE',
  SET_PAYMENT_TYPE: 'SET_PAYMENT_TYPE',
  SET_SHIPPING_ADDRESS_VALUE: 'SET_CHECKOUT_FORM_VALUE [SHIPPING_ADDRESS]',
  SET_BILLING_ADDRESS_VALUE: 'SET_CHECKOUT_FORM_VALUE [BILLING_ADDRESS]',

  PROCEED_TO_PAYMENT_REQUEST: 'PROCEED_TO_PAYMENT_REQUEST',
  SAVE_ORDER: 'SAVE_ORDER',
  PROCEED_TO_PAYMENT_SUCCESS: 'PROCEED_TO_PAYMENT_SUCCESS',
  PROCEED_TO_PAYMENT_ERROR: 'PROCEED_TO_PAYMENT_ERROR',

  GET_DELIVERY_DAYS_REQUEST: 'GET_DELIVERY_DAYS_REQUEST',
  GET_DELIVERY_DAYS_REQUEST_SUCCESS: 'GET_DELIVERY_DAYS_REQUEST_SUCCESS',
  GET_DELIVERY_DAYS_REQUEST_ERROR: 'GET_DELIVERY_DAYS_REQUEST_ERROR',

  PUT_CHECKOUT_REQUEST: 'PUT_CHECKOUT_REQUEST',
  PUT_CHECKOUT_REQUEST_SUCCESS: 'PUT_CHECKOUT_REQUEST_SUCCESS',
  PUT_CHECKOUT_REQUEST_ERROR: 'PUT_CHECKOUT_REQUEST_ERROR',

  SAVE_AS_QUOTE_REQUEST: 'SAVE_AS_QUOTE_REQUEST',
  SAVE_AS_QUOTE_SUCCESS: 'SAVE_AS_QUOTE_SUCCESS',
  SAVE_AS_QUOTE_ERROR: 'SAVE_AS_QUOTE_ERROR',

  GET_COUNTRIES_REQUEST: 'GET_COUNTRIES_REQUEST',
  GET_COUNTRIES_SUCCESS: 'GET_COUNTRIES_SUCCESS',
  GET_COUNTRIES_ERROR: 'GET_COUNTRIES_ERROR',

  GET_COUNTRY_REQUEST: 'GET_COUNTRY_REQUEST',
  GET_COUNTRY_SUCCESS: 'GET_COUNTRY_SUCCESS',
  GET_COUNTRY_ERROR: 'GET_COUNTRY_ERROR',

  GET_SHIPPING_TAX_REQUEST: 'GET_SHIPPING_TAX_REQUEST',
  GET_SHIPPING_TAX_SUCCESS: 'GET_SHIPPING_TAX_SUCCESS',
  GET_SHIPPING_TAX_ERROR: 'GET_SHIPPING_TAX_ERROR',

  PROCESS_PAYMENT_REQUEST: 'PROCESS_PAYMENT_REQUEST',
  PROCESS_PAYMENT_SUCCESS: 'PROCESS_PAYMENT_SUCCESS',
  PROCESS_PAYMENT_ERROR: 'PROCESS_PAYMENT_ERROR',

  CHECK_PAYMENT_STATUS_REQUEST: 'CHECK_PAYMENT_STATUS_REQUEST',
  CHECK_PAYMENT_STATUS_SUCCESS: 'CHECK_PAYMENT_STATUS_SUCCESS',
  CHECK_PAYMENT_STATUS_ERROR: 'CHECK_PAYMENT_STATUS_ERROR',

  GET_CHECKOUT_REQUEST: 'GET_CHECKOUT_REQUEST',
  GET_CHECKOUT_SUCCESS: 'GET_CHECKOUT_SUCCESS',
  GET_CHECKOUT_ERROR: 'GET_CHECKOUT_ERROR',
};

export const checkoutReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case CHECKOUT_ACTIONS.FULFILL_FORM:
      return {
        ...state,
        form: {
          ...state.form,
          ...payload,
        },
      };

    case CHECKOUT_ACTIONS.SET_VALUE:
      return {
        ...state,
        form: {
          ...state.form,
          [payload.name]: payload.value,
        },
      };

    case CHECKOUT_ACTIONS.SET_PAYMENT_TYPE:
      return {
        ...state,
        form: {
          ...state.form,
          order: {
            ...state.form.order,
            [payload.name]: payload.value,
          },
        },
      };

    case CHECKOUT_ACTIONS.SET_SHIPPING_ADDRESS_VALUE:
      let newState = {
        ...state,
        form: {
          ...state.form,
          order: {
            ...state.form.order,
            shipping_address: {
              ...state.form.order.shipping_address,
              [payload.name]: payload.value,
            },
          },
        },
      };
      // console.log('NEW', newState);

      return newState;

    case CHECKOUT_ACTIONS.SET_BILLING_ADDRESS_VALUE:
      let newStateB = {
        ...state,
        form: {
          ...state.form,
          order: {
            ...state.form.order,
            billing_address: {
              ...state.form.order.billing_address,
              [payload.name]: payload.value,
            },
          },
        },
      };

      return newStateB;

    case CHECKOUT_ACTIONS.SAVE_AS_QUOTE_REQUEST:
      return {
        ...state,
        isProcessing: true,
      };

    case CHECKOUT_ACTIONS.SAVE_AS_QUOTE_SUCCESS:
      return {
        ...state,
        isProcessing: false,
      };

    case CHECKOUT_ACTIONS.PROCEED_TO_PAYMENT_ERROR:
      return {
        ...state,
        isProcessing: false,
        error: payload,
      };

    case CHECKOUT_ACTIONS.PROCEED_TO_PAYMENT_REQUEST:
      return {
        ...state,
        isProcessing: true,
      };

    case CHECKOUT_ACTIONS.SAVE_ORDER:
      return {
        ...state,
      };

    case CHECKOUT_ACTIONS.PROCEED_TO_PAYMENT_SUCCESS:
      return {
        ...state,
        isProcessing: true,
      };

    case CHECKOUT_ACTIONS.SAVE_AS_QUOTE_ERROR:
      return {
        ...state,
        isProcessing: false,
        error: payload,
      };

    case CHECKOUT_ACTIONS.GET_CHECKOUT_REQUEST:
      return {
        ...state,
        isProcessing: true,
      };

    case CHECKOUT_ACTIONS.GET_CHECKOUT_SUCCESS:
      let checkoutState = {
        ...state,
        form: {
          ...state.form,
          ...payload,
          isProcessing: false,
        },
        isProcessing: false,
      };
      return checkoutState;

    case CHECKOUT_ACTIONS.GET_CHECKOUT_ERROR:
      return {
        ...state,
        isProcessing: false,
        error: payload,
      };

    case CHECKOUT_ACTIONS.GET_DELIVERY_DAYS_REQUEST:
      return {
        ...state,
        isProcessing: false,
      };

    case CHECKOUT_ACTIONS.GET_DELIVERY_DAYS_REQUEST_ERROR:
      return {
        ...state,
        isProcessing: false,
        error: payload,
      };

    case CHECKOUT_ACTIONS.GET_DELIVERY_DAYS_REQUEST_SUCCESS:
      return {
        ...state,
        delivery_days: payload,
        form: {
          ...state.form,
        },
        isProcessing: false,
      };

    case CHECKOUT_ACTIONS.PUT_CHECKOUT_REQUEST:
      return {
        ...state,
      };

    case CHECKOUT_ACTIONS.PUT_CHECKOUT_REQUEST_ERROR:
      return {
        ...state,
      };

    case CHECKOUT_ACTIONS.PUT_CHECKOUT_REQUEST_SUCCESS:
      return {
        ...state,
        form: {
          ...state.form,
          ...payload,
        },
      };

    case CHECKOUT_ACTIONS.GET_SHIPPING_TAX_REQUEST:
      return {
        ...state,
        isProcessing: true,
      };

    case CHECKOUT_ACTIONS.GET_SHIPPING_TAX_SUCCESS:
      return {
        ...state,
        form: {
          ...state.form,
          shipping: payload,
        },
        isProcessing: false,
      };

    case CHECKOUT_ACTIONS.GET_SHIPPING_TAX_ERROR:
      return {
        ...state,
        isProcessing: false,
        error: payload,
      };

    case CHECKOUT_ACTIONS.GET_COUNTRY_REQUEST:
      return {
        ...state,
        isProcessing: true,
      };

    case CHECKOUT_ACTIONS.GET_COUNTRY_SUCCESS:
      return {
        ...state,
        form: {
          ...state.form,
          countries: payload,
        },
      };

    case CHECKOUT_ACTIONS.GET_COUNTRY_ERROR:
      return {
        ...state,
        isProcessing: false,
        error: payload,
      };

    case CHECKOUT_ACTIONS.GET_COUNTRIES_REQUEST:
      return {
        ...state,
        isProcessing: true,
      };

    case CHECKOUT_ACTIONS.GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        form: {
          ...state.form,
          countries: payload,
        },
      };

    case CHECKOUT_ACTIONS.GET_COUNTRIES_ERROR:
      return {
        ...state,
        isProcessing: false,
        error: payload,
      };

    case CHECKOUT_ACTIONS.PROCESS_PAYMENT_REQUEST:
      return {
        ...state,
        isProcessing: true,
      };

    case CHECKOUT_ACTIONS.PROCESS_PAYMENT_SUCCESS:
      return {
        ...state,
        form: {
          ...state.form,
          payment_info: payload,
        },
      };

    case CHECKOUT_ACTIONS.PROCESS_PAYMENT_ERROR:
      return {
        ...state,
        isProcessing: false,
        error: payload,
      };

    case CHECKOUT_ACTIONS.CHECK_PAYMENT_STATUS_REQUEST:
      return {
        ...state,
        isProcessing: true,
      };

    case CHECKOUT_ACTIONS.CHECK_PAYMENT_STATUS_SUCCESS:
      return {
        ...state,
        form: {
          ...state.form,
          check_status: payload,
        },
        isProcessing: false,
      };

    case CHECKOUT_ACTIONS.CHECK_PAYMENT_STATUS_ERROR:
      return {
        ...state,
        isProcessing: false,
        error: payload,
      };

    default:
      return state;
  }
};

export const checkoutActions = {
  setValue: ({ name, value }) => ({
    type: CHECKOUT_ACTIONS.SET_VALUE,
    payload: {
      name,
      value,
    },
  }),
  setPaymentType: ({ name, value }) => ({
    type: CHECKOUT_ACTIONS.SET_PAYMENT_TYPE,
    payload: {
      name,
      value,
    },
  }),
  setShippingAddressValue: ({ name, value }) => ({
    type: CHECKOUT_ACTIONS.SET_SHIPPING_ADDRESS_VALUE,
    payload: {
      name,
      value,
    },
  }),
  setBillingAddressValue: ({ name, value }) => ({
    type: CHECKOUT_ACTIONS.SET_BILLING_ADDRESS_VALUE,
    payload: {
      name,
      value,
    },
  }),
  fulfillForm: (payload) => ({
    type: CHECKOUT_ACTIONS.FULFILL_FORM,
    payload,
  }),
  saveAsQuoteRequest: (payload) => ({
    type: CHECKOUT_ACTIONS.SAVE_AS_QUOTE_REQUEST,
    payload,
  }),
  saveAsQuoteSuccess: (payload) => ({
    type: CHECKOUT_ACTIONS.SAVE_AS_QUOTE_SUCCESS,
    payload,
  }),
  saveAsQuoteError: (payload) => ({
    type: CHECKOUT_ACTIONS.SAVE_AS_QUOTE_ERROR,
    payload,
  }),
  getCountryRequest: () => ({ type: CHECKOUT_ACTIONS.GET_COUNTRY_REQUEST }),
  getCountrySuccess: (payload) => ({
    type: CHECKOUT_ACTIONS.GET_COUNTRY_SUCCESS,
    payload,
  }),
  getCountryError: (payload) => ({
    type: CHECKOUT_ACTIONS.GET_COUNTRY_ERROR,
    payload,
  }),

  getCountriesRequest: () => ({
    type: CHECKOUT_ACTIONS.GET_COUNTRIES_REQUEST,
  }),

  getCountriesSuccess: (payload) => ({
    type: CHECKOUT_ACTIONS.GET_COUNTRIES_SUCCESS,
    payload,
  }),
  getCountriesError: (payload) => ({
    type: CHECKOUT_ACTIONS.GET_COUNTRIES_ERROR,
    payload,
  }),

  processPaymentRequest: (payload) => ({
    type: CHECKOUT_ACTIONS.PROCESS_PAYMENT_REQUEST,
    payload,
  }),
  processPaymentSuccess: (payload) => ({
    type: CHECKOUT_ACTIONS.PROCESS_PAYMENT_SUCCESS,
    payload,
  }),
  processPaymentError: (payload) => ({
    type: CHECKOUT_ACTIONS.PROCESS_PAYMENT_ERROR,
    payload,
  }),

  checkStatusRequest: (payload) => ({
    type: CHECKOUT_ACTIONS.CHECK_PAYMENT_STATUS_REQUEST,
    payload,
  }),
  checkStatusSuccess: (payload) => ({
    type: CHECKOUT_ACTIONS.CHECK_PAYMENT_STATUS_SUCCESS,
    payload,
  }),
  checkStatusError: (payload) => ({
    type: CHECKOUT_ACTIONS.CHECK_PAYMENT_STATUS_ERROR,
    payload,
  }),

  getShippingTaxRequest: (payload) => ({
    type: CHECKOUT_ACTIONS.GET_SHIPPING_TAX_REQUEST,
    payload,
  }),
  getShippingTaxSuccess: (payload) => ({
    type: CHECKOUT_ACTIONS.GET_SHIPPING_TAX_SUCCESS,
    payload,
  }),
  getShippingTaxError: (payload) => ({
    type: CHECKOUT_ACTIONS.GET_SHIPPING_TAX_ERROR,
    payload,
  }),

  getCheckoutRequest: (payload) => ({
    type: CHECKOUT_ACTIONS.GET_CHECKOUT_REQUEST,
    payload,
  }),
  getCheckoutSuccess: (payload) => ({
    type: CHECKOUT_ACTIONS.GET_CHECKOUT_SUCCESS,
    payload,
  }),
  getCheckoutError: (payload) => ({
    type: CHECKOUT_ACTIONS.GET_CHECKOUT_ERROR,
    payload,
  }),

  saveOrder: (payload) => ({
    type: CHECKOUT_ACTIONS.SAVE_ORDER,
    payload,
  }),
  proceedToPaymentRequest: (payload) => ({
    type: CHECKOUT_ACTIONS.PROCEED_TO_PAYMENT_REQUEST,
    payload,
  }),
  proceedToPaymentSuccess: (payload) => ({
    type: CHECKOUT_ACTIONS.PROCEED_TO_PAYMENT_SUCCESS,
    payload,
  }),
  proceedToPaymentError: (payload) => ({
    type: CHECKOUT_ACTIONS.PROCEED_TO_PAYMENT_ERROR,
    payload,
  }),

  getDeliveryDaysRequest: (payload) => ({
    type: CHECKOUT_ACTIONS.GET_DELIVERY_DAYS_REQUEST,
    payload,
  }),
  getDeliveryDaysRequestSuccess: (payload) => ({
    type: CHECKOUT_ACTIONS.GET_DELIVERY_DAYS_REQUEST_SUCCESS,
    payload,
  }),
  getDeliveryDaysRequestError: (payload) => ({
    type: CHECKOUT_ACTIONS.GET_DELIVERY_DAYS_REQUEST_ERROR,
    payload,
  }),

  putCheckoutRequest: (payload) => ({
    type: CHECKOUT_ACTIONS.PUT_CHECKOUT_REQUEST,
    payload,
  }),
  putCheckoutRequestSuccess: (payload) => ({
    type: CHECKOUT_ACTIONS.PUT_CHECKOUT_REQUEST_SUCCESS,
    payload,
  }),
  putCheckoutRequestError: (payload) => ({
    type: CHECKOUT_ACTIONS.PUT_CHECKOUT_REQUEST_ERROR,
    payload,
  }),
};
