import React, { useEffect, useState, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';

import Button from '@mui/material/Button';
import { Modal, Button as BootstrapButton } from 'react-bootstrap';
import SearchIcon from '@mui/icons-material/Search';
import { CircularProgress, Skeleton } from '@mui/material';
import { FaRegTrashAlt } from 'react-icons/fa';
import { SlBasketLoaded } from 'react-icons/sl';
import { FiFolderPlus } from 'react-icons/fi';
import { CiFilter } from 'react-icons/ci';
import { MdOutlineChevronLeft } from 'react-icons/md';
import { LuImport } from 'react-icons/lu';

import { isArray } from 'lodash';
import { compress } from 'lz-string';

import { authActions } from '../../../store/ducks/auth.duck';
import { drawActions } from '../../../store/ducks/draw.duck';

import CheckBox from '../../../components/checkBox';

import {
  materialById,
  fetchSVGs,
  findFolder,
  createFolder,
  updateFolder,
  deleteFolder,
  reSaveFile,
  updateFile,
  createFile,
  deleteFile,
  deleteFileGroup,
} from './utils';
import FileComponent from './fileComponent';
import FolderComponent from './folderComponent';

import OverviewPart from './basketTable';

import initApi from '../../../crud/apiConfig/apiConfig';
import {
  ORDER_TYPE_LIB,
  ORDER_TYPE_SIMPLE,
  RESAVE,
} from '../../../common/constants/cadJSON';
import { API_URL, API_URLS } from '../../../common/constants/api/apiConstants';

import './index.scss';
import scssStylesheet from '!!raw-loader!./index.scss';
import { css_into_js } from '../../../../_metronic/utils/scss';

function MyPartLibrary({ user, ...props }) {
  const { id, cadJSON } = user;
  console.log(cadJSON)

  const [cartTotal, setCartTotal] = useState(0);
  const [buttonClass, setButtonClass] = useState('');
  const [cartClass, setCartClass] = useState('');

  const handleAddToCart = () => {
    setButtonClass('sendtocart');
    setTimeout(() => {
      setButtonClass('');
      setCartTotal(cartTotal + 1);
      setCartClass('shake');
      setTimeout(() => {
        setCartClass('');
      }, 500);
    }, 1000);
  };




  const uploadRef = useRef(null);
  const styles = useMemo(() => css_into_js(scssStylesheet), []);
  const intl = useIntl();
  const history = useHistory();

  const [actionBarState, setActionBar] = useState({
    checkbox: { checked: false, indeterminate: false },
    basket: [],
    basket_fileContest: {},
    selectedFiles: [],
  });

  const [fileContents, setFileContents] = useState([]);

  const [driveState, setDriveState] = useState({
    currentFolderID: 1,
    paginationHistory: null,

    crtFolders: [],
    dltFolders: [],

    crtFiles: [],
  });

  const [modalState, setModal] = useState({
    show: false,
    body: <span>Table</span>,
    headerTitle: <span>Modal</span>,
    onCancel: () => {},
    submitSection: <span>Loading...</span>,
    defaultMaterial: null,
    choiceData: [],
  });

  const crtFolder = function(targetFolderID = driveState.currentFolderID) {
    const { reloadCADjson } = props;
    const newFolder = createFolder(cadJSON, targetFolderID);
    const currentFolder = findFolder(cadJSON, targetFolderID);

    currentFolder.children_folders.push(newFolder);
    reloadCADjson(id, cadJSON, 'Folder was successfully created');

    setDriveState((prevState) => ({
      ...prevState,
      crtFolders: [...prevState.crtFolders, newFolder.id],
    }));
  };

  const uptFold = function(currentFolderID, key, value) {
    const { reloadCADjson } = props;
    updateFolder(cadJSON, currentFolderID, key, value);
    reloadCADjson(id, cadJSON, 'Folder was successfully updated');
  };

  const dltFolder = function(deleteID) {
    const { reloadCADjson } = props;
    deleteFolder(cadJSON, deleteID);

    setDriveState((prevState) => ({
      ...prevState,
      crtFolders: [...prevState.dltFolders, deleteID],
    }));
    reloadCADjson(id, cadJSON, 'Folder was successfully deleted');
  };

  const crtFile = function(
    fileRes,
    targetFolderID = driveState.currentFolderID
  ) {
    const { reloadCADjson } = props;
    const newFile = createFile(cadJSON, targetFolderID, fileRes);
    const currentFolder = findFolder(cadJSON, targetFolderID);

    currentFolder.files.push(newFile);

    setDriveState((prevState) => ({
      ...prevState,
      crtFiles: [...prevState.crtFiles, newFile.id],
    }));

    reloadCADjson(id, cadJSON);
  };

  const uptFile = function(targetFileID, key, value) {
    const { reloadCADjson } = props;
    updateFile(cadJSON, targetFileID, key, value);
    reloadCADjson(id, cadJSON, 'File was successfully updated');
  };

  const dltFile_s = function(deleteID_s) {
    const { reloadCADjson } = props;
    if (deleteID_s) {
      isArray(deleteID_s)
        ? deleteFileGroup(cadJSON, deleteID_s)
        : deleteFile(cadJSON, deleteID_s);

      setDriveState((prevState) => ({
        ...prevState,
        dltFolders: [...prevState.dltFolders, deleteID_s],
      }));

      setActionBar({
        checkbox: { checked: false, indeterminate: false },
        basket: [],
        basket_fileContest: {},
        selectedFiles: [],
      });

      reloadCADjson(id, cadJSON, 'File was successfully deleted');
    }
  };

  const createProj = async function(e, uploadRef) {
    const { createProject } = props;

    const file = uploadRef.current.files[0];
    const DXF_TEXT = await file.text();
    const ARRAY_DXF = [{ DXF_TEXT, name: file.name }];

    createProject(ARRAY_DXF, ORDER_TYPE_LIB);
  };

  const turnOffClick = function(e) {
    e.stopPropagation();
    e.preventDefault();
  };

  const downloadDXF = function(productID, fileName) {
    try {
      const link = document.createElement('a');
      link.href = `${API_URL}/api/product/${productID}/download/dxf-original`;
      link.download = fileName;
      link.click();
    } catch (err) {
      toast.error(err.message, {
        position: 'bottom-right',
        autoClose: 2500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    } finally {
      toast.success('File downloaded', {
        position: 'bottom-right',
        autoClose: 2500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    }
  };

  const handelSelect = function() {
    const currentFolder = findFolder(cadJSON, driveState.currentFolderID);

    setActionBar((prevState) => ({
      ...prevState,
      checkbox: {
        checked: !prevState.checkbox.checked,
        indeterminate: false,
      },
      selectedFiles: !prevState.checkbox.checked
        ? currentFolder.files.map((fileID) => fileID.id)
        : [],
    }));
  };

  const addBasket = function(someFile) {
    setActionBar((prevState) =>
      prevState.selectedFiles.length > 0 || someFile
        ? {
            ...prevState,
            basket: [
              ...prevState.basket,
              ...(isArray(someFile) ? someFile : [someFile]),
            ].filter((value, index, array) => array.indexOf(value) === index),
          }
        : prevState
    );
  };

  const closeModal = function() {
    setModal((prevState) => ({
      ...prevState,
      show: false,
    }));
  };

  const openBasketModal = function() {
    const disableBasket = function(bodyTxt) {
      setModal((prevState) => ({
        ...prevState,
        show: true,
        headerTitle: <FormattedMessage id="CAD.LIBRARY.BASKET" />,
        body: <span>{bodyTxt}</span>,
        submitSection: (
          <>
            <Skeleton
              variant="rectangular"
              width={79}
              height={39}
              sx={{ borderRadius: '5px' }}
            />
            <Skeleton
              variant="rectangular"
              width={197}
              height={39}
              sx={{ borderRadius: '5px' }}
            />
            <Skeleton
              variant="rectangular"
              width={140}
              height={39}
              sx={{ borderRadius: '5px' }}
            />
          </>
        ),
      }));
    };

    const toConfigurator = function() {
      setActionBar((prevState) => {
        dxfUpload(Object.values(prevState.basket_fileContest));
        return { ...prevState, basket: [] };
      });
      disableBasket('Please await you will be redirected to configurator');
    };

    const toCheckout = function() {
      setActionBar((prevState) => {
        createCheckout(Object.values(prevState.basket_fileContest));
        return { ...prevState, basket: [] };
      });
      setActionBar((prevState) => ({ ...prevState, basket: [] }));
      disableBasket('Please await you will be redirected to checkout');
    };

    setModal((prevState) => ({
      ...prevState,
      show: true,
      headerTitle: <FormattedMessage id="CAD.LIBRARY.BASKET" />,
      body:
        fileContents && fileContents.length > 0 ? (
          <OverviewPart
            fileContents={fileContents?.filter((file) =>
              actionBarState.basket.includes(file.id)
            )}
            setActionBar={setActionBar}
          />
        ) : (
          'empty'
        ),
      submitSection: (
        <>
          <BootstrapButton onClick={closeModal} variant={'secondary'}>
            <FormattedMessage id="CANCEL" />
          </BootstrapButton>
          <BootstrapButton onClick={toConfigurator}>
            <FormattedMessage id="CAD.LIBRARY.GO_CHECKOUT" />
          </BootstrapButton>
          <BootstrapButton onClick={toCheckout}>
            <FormattedMessage id="CAD.LIBRARY.GO_CONFIGURATOR" />
          </BootstrapButton>
        </>
      ),
    }));
  };

  const adoptMaterial = function(choiceData, materialData) {
    const adoptedGlobData = {};
    materialData.forEach(
      (item) =>
        (adoptedGlobData[item.id] = {
          price: Number(item.price),
          cutting_speed: item.cutting_speed,
        })
    );

    const adopted = {};

    choiceData &&
      choiceData.materials.forEach(
        (item) =>
          (adopted[item.name] = {
            ...item,
            choices: item.choices.map((choice) => ({
              ...choice,
              key: choice.id,
              price: adoptedGlobData[choice.id].price,
              cutting_speed: adoptedGlobData[choice.id].cutting_speed,
              value: `${choice.thickness}  ${choice.id}`,
              label: (
                <div style={{ display: 'flex' }}>
                  <span
                    dangerouslySetInnerHTML={{ __html: choice.icon }}
                    style={{ marginLeft: '5px' }}
                  ></span>
                  <span>{choice.thickness + ` mm ${choice.color}`}</span>
                </div>
              ),
            })),
          })
      );

    return adopted;
  };

  const fetchMaterials = async () => {
    try {
      const { data: globDataResponse } = await initApi().get(
        API_URL + API_URLS.GET_MATERIALS_DATA
      );
      const { data: choicesData } = await initApi().get(
        API_URL + API_URLS.MATERIAL_DATA
      );

      const choiceData = adoptMaterial(choicesData, globDataResponse);
      setModal((prevState) => ({
        ...prevState,
        defaultMaterial: 1,
        choiceData,
      }));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  let currentFolder;
  if (cadJSON && cadJSON.length !== 0) {
    currentFolder = findFolder(cadJSON, driveState.currentFolderID);
  }

  const dxfUpload = function(files) {
    const { convertDxf, drawLoading } = props;

    files.forEach(async (file, index, array) => {
      const lastOne = index + 1 === array.length;
      const materialObject = file.material;
      const quantity = file.quantity;

      const DXF_TEXT = await fetch(
        `${API_URL}/api/product/${file.product_id}/download/dxf-original`
      ).then((imp) => imp.text());
      await convertDxf(
        compress(DXF_TEXT),
        file.name,
        drawLoading,
        true,
        lastOne,
        materialObject,
        quantity
      );

      history.push(`/configurator`);
    });
  };

  const createCheckout = async function(files) {
    const { createProject } = props;

    const ARRAY_DXF = await Promise.all(
      files.map(async (file) => {
        const { product_id, name, material: materialObject, quantity } = file;
        const DXF_TEXT = await fetch(
          `${API_URL}/api/product/${product_id}/download/dxf-original`
        ).then((imp) => imp.text());

        return { DXF_TEXT, name, materialObject, quantity };
      })
    );

    createProject(ARRAY_DXF, ORDER_TYPE_SIMPLE);
  };

  useEffect(() => {
    const { getCADjson, materialDataRequest } = props;

    getCADjson(id);
    materialDataRequest();
    fetchMaterials();
  }, []);

  useEffect(() => {
    const { reloadCADjson, updateNewFile, updateDrawSVGarray, draw } = props;
    const { newFile } = draw;

    if (Object.keys(newFile).length > 0 && newFile.type === ORDER_TYPE_LIB) {
      crtFile(newFile);
      updateNewFile({});
    }

    if (Object.keys(newFile).length > 0 && newFile.type === ORDER_TYPE_SIMPLE) {
      history.push(`/checkout/${newFile.project_id}`);

      updateNewFile({});
      updateDrawSVGarray([]);
    }

    if (Object.keys(newFile).length > 0 && newFile.type === RESAVE) {
      reSaveFile(cadJSON, newFile);
    
      reloadCADjson(id, cadJSON, 'File was updated');
      updateNewFile({});
    }
  }, [JSON.stringify(props.draw.newFile)]);

  useEffect(() => {
    const fetchFiles = async () => {
        if (currentFolder?.files && Object.keys(modalState.choiceData).length > 0) {
            try {
                const currentFiles = await fetchSVGs(currentFolder.files, modalState.choiceData);

                setFileContents(currentFiles);
            } catch (error) {
                console.error('Error fetching SVGs:', error);
            }
        }
    };

    fetchFiles();
}, [JSON.stringify(currentFolder), JSON.stringify(modalState.choiceData)]);

  if (cadJSON && cadJSON.length !== 0) {
    const filesObject = {};
    currentFolder.files.forEach((f) => (filesObject[f.id] = f));

    const filteredBasket = [
      ...actionBarState.selectedFiles,
      ...actionBarState.basket,
    ].filter((id, index, array) => {
      return filesObject[id]?.material && array.indexOf(id) === index;
    });

    const isForBasket =
      filteredBasket.length > actionBarState.basket.length &&
      JSON.stringify(actionBarState.selectedFiles) !==
        JSON.stringify(actionBarState.basket);

    const BASKET_ADD = intl.formatMessage({ id: 'CAD.LIBRARY.BASKET_ADD' });

    return (
      <>
        <div
          onClick={turnOffClick}
          className={
            props.draw.projectCreation || props.drawLoading.status
              ? 'loadingPrevent'
              : 'lodingOff'
          }
        >
          <CircularProgress />
        </div>
        <div className="mypartlibrary">
          <h2 className="mypartlibrary-title">
            <FormattedMessage id="CAD.LIBRARY.TITLE" />
          </h2>
          <div className="mypartlibrary-actionRow">
            <div className="mypartlibrary-actionRow-forSelected">
              <CheckBox
                checked={actionBarState.checkbox.checked}
                indeterminate={actionBarState.checkbox.indeterminate}
                onClick={handelSelect}
              />
              <span>
                {actionBarState.selectedFiles.length}{' '}
                <FormattedMessage id="Geselecteerd" />{' '}
              </span>

              <button
                  id="cart" className={`cart ${cartClass} mypartlibrary-actionRow-basket`}
                  // data-totalitems={cartTotal}
                style={{
                  ...(isForBasket
                    ? {
                        padding: '0 10px',
                        width:
                          (BASKET_ADD.length +
                            1 +
                            filteredBasket.length.toString().length) *
                            11 +
                          10 +
                          'px',
                      }
                    : { width: '40px', paddingRight: '3px' }),
                  overflow: 'hidden',
                }}
                onClick={() => addBasket(filteredBasket)}
              >
                {isForBasket && (
                  <span
                    width={{
                      width:
                        (BASKET_ADD.length +
                          1 +
                          filteredBasket.length.toString().length) *
                          11 +
                        'px',
                    }}
                  >
                    {BASKET_ADD + ' ' + filteredBasket.length}
                  </span>
                )}

                <div className="mypartlibrary-actionRow-orderCount">
                  {actionBarState.basket.length}
                </div>

                <SlBasketLoaded
                  className="actionIcons"
                  onClick={() => openBasketModal()}
                />
              </button>

              <FaRegTrashAlt
                className="actionIcons"
                onClick={() => dltFile_s(actionBarState.selectedFiles)}
              />
            </div>
            <div className="mypartlibrary-actionRow-forAll">
              <CiFilter
                className="actionIcons"
                style={{ opacity: 0.5, pointerEvents: 'none' }}
              />
              <div className="search">
                <div className="sIconDiv">
                  <SearchIcon className="search-icon" />
                </div>
                <input placeholder={'Search for document'} />
              </div>
            </div>
          </div>

          <div key={currentFolder.id} className="drive_window">
            <div className="drive_window-toolbar">
              <Button
                variant="text"
                style={{
                  ...styles['.drive_window-back'],
                  ...(currentFolder.id !== 1 ? { paddingRight: '15px' } : {}),
                }}
                onClick={() =>
                  setDriveState((prevState) => ({
                    ...prevState,
                    currentFolderID:
                      currentFolder.parent_id || currentFolder.id,
                  }))
                }
              >
                {currentFolder.id !== 1 && (
                  <MdOutlineChevronLeft style={{ fontSize: '20px' }} />
                )}
                {currentFolder.name}
              </Button>

              <LuImport
                className="actionIcons"
                style={{ transform: 'rotate(180deg)' }}
                onClick={() => uploadRef.current.click()}
              />

              <input
                ref={uploadRef}
                type="file"
                accept=".dxf"
                style={{ display: 'none' }}
                multiple={false}
                onChange={(e) => createProj(e, uploadRef)}
              />

              <FiFolderPlus
                className="actionIcons"
                onClick={() => crtFolder()}
              />
            </div>

            <FolderComponent
              folderList={currentFolder.children_folders}
              uptFold={uptFold}
              dltFolder={dltFolder}
              onClick={(childFolderID) =>
                setDriveState((prevState) => ({
                  ...prevState,
                  currentFolderID: childFolderID,
                }))
              }
            />

            <FileComponent
              fileList={currentFolder.files}
              uptFile={uptFile}
              dltFile_s={dltFile_s}
              onDownload={downloadDXF}
              addBasket={addBasket}
              fileContents={fileContents}
              setFileContents={setFileContents}
              checkBox={actionBarState.checkbox}
              setActionBar={setActionBar}
              choiceData={modalState.choiceData}
              materialById={() => materialById(modalState.choiceData)}
              dxfUpload={dxfUpload}
              createCheckout={createCheckout}
              cartTotal={cartTotal}
              buttonClass={buttonClass}
              cartClass={cartClass}
              handleAddToCart={handleAddToCart}
            />
          </div>
        </div>

        {/*<Modal*/}
        {/*  show={modalState.show}*/}
        {/*  onHide={closeModal}*/}
        {/*  className="oneModal"*/}
        {/*  centered*/}
        {/*>*/}
        {/*  <Modal.Header closeButton>*/}
        {/*    {' '}*/}
        {/*    <Modal.Title> {modalState.headerTitle} </Modal.Title>{' '}*/}
        {/*  </Modal.Header>*/}

        {/*  <Modal.Body> {modalState.body} </Modal.Body>*/}

        {/*  <Modal.Footer>{modalState.submitSection}</Modal.Footer>*/}
        {/*</Modal>*/}
      </>
    );
  }
  return <span>Loading...</span>;
}

MyPartLibrary.propTypes = {
  user: PropTypes.object,
};

const mapStateToProps = ({ auth: { user }, draw }) => ({
  draw,
  user,
  uploadedSvgs: draw.uploadedSvgs,
  drawLoading: draw.loading,
});

const mapDispatchToProps = {
  getCADjson: (id) => authActions.getCADjson(id),
  reloadCADjson: (id, structure, message) =>
    authActions.reloadCADjson(id, structure, message),
  createProject: (ARRAY_DXF, type, fileType) =>
    drawActions.createProject(ARRAY_DXF, type, fileType),
  updateNewFile: (urls) => drawActions.updateNewFile(urls),
  updateDrawSVGarray: (uploadedSvgs) =>
    drawActions.updateDrawSVGarray({ SVG: uploadedSvgs }),
  materialDataRequest: () => drawActions.materialDataRequest(),
  convertDxf: (
    data,
    name,
    loading,
    withAbort,
    lastOne,
    materialObject,
    quantity
  ) =>
    drawActions.convertDxfRequest(
      data,
      name,
      loading,
      withAbort,
      lastOne,
      materialObject,
      quantity
    ),
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(MyPartLibrary))
);
