import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  Card,
  CardContent,
  CardHeader,
  Paper,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { getUser } from '../../../store/users/actions';
// import { ROUTES } from '../../../common/constants/routes/routes';
import {
  INITIAL_ADDRESS,
  DEFAULT_DATE_FORMAT,
} from '../../../common/constants/constants';
import { getCountries } from '../../../store/countries/actions';

import MaterialTable from '@material-table/core';
import moment from 'moment';
import { injectIntl } from 'react-intl';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const DetailsPage = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  let { user, intl } = props;

  const { id } = useParams();
  const { isProcessing } = props;
  const [company, setCompany] = useState({
    id: null,
    name: '',
  });
  const [addresses, setAddresses] = useState({
    billing: { ...INITIAL_ADDRESS },
    shipping: { ...INITIAL_ADDRESS },
  });

  const [value, setValue] = useState(0);

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  useEffect(() => {
    if (id) {
      dispatch(getUser(id));
    }
    dispatch(getCountries());
  }, []); // eslint-disable-line

  useEffect(() => {
    let data = user;

    if (data) {
      setCompany(data.company);
      const shippingAddress = (data.addresses &&
        data.addresses.find((item) => item?.type?.title === 'Shipping')) || {
        ...INITIAL_ADDRESS,
      };
      const billingAddress = (data.addresses &&
        data.addresses.find((item) => item?.type?.title === 'Billing')) || {
        ...INITIAL_ADDRESS,
      };

      setAddresses({
        billing: {
          ...billingAddress,
          type: 2,
        },
        shipping: {
          ...shippingAddress,
          type: 1,
        },
      });
    }
  }, [user]);

  function navigateToQuote({ id }) {
    history.push(`/quotes/details/${id}`);
  }
  function navigateToOrder({ id }) {
    history.push(`/orders/details/${id}`);
  }
  const columnsOrders = [
    {
      title: '#',
      field: 'project.id',
      render: (rowData) => rowData.id,
    },
    {
      title: intl.formatMessage({ id: 'QUOTE.DATE' }),
      field: 'created_at',
      render: (rowData) =>
        moment.utc(rowData.createdAt.date).format(DEFAULT_DATE_FORMAT),
    },
    {
      title: intl.formatMessage({ id: 'CHECKOUT.REFERENCE' }),
      field: 'reference',
      render: (rowData) => rowData.reference ?? '',
    },
    {
      title: intl.formatMessage({ id: 'QUOTE.TOTAL' }),
      field: 'project.price',
      type: 'currency',
      currencySetting: {
        currencyCode: 'EUR',
        locale: 'NL',
      },
    },
  ];

  // const handleCancel = () => {
  //   history.push(ROUTES.USERS);
  // };

  const handleOnChange = (evt, new_value) => {
    setValue(new_value);
  };

  return isProcessing || !user ? (
    'Loading...'
  ) : (
    <div className="row">
      <div className="col-md-12">
        <Col className="kt-section">
          <Row className="kt-section__content">
            <Row>
              <Col md={4}>
                <Card className={'mb-4 p-2'}>
                  <CardHeader
                    title={company ? company.name : 'Particulier'}
                    subheader={user && user?.first_name + ' ' + user?.last_name}
                  ></CardHeader>

                  <CardContent>
                    <Row>
                      <Col md={6}>
                        <strong>Verzendadres</strong>
                        <br />
                        {addresses.shipping &&
                          addresses.shipping.address +
                            ' ' +
                            addresses.shipping.house_number +
                            ' ' +
                            addresses.shipping.ext}
                        <br />
                        {addresses.shipping &&
                          addresses.shipping.postal_code +
                            ' ' +
                            addresses.shipping.city}
                        <br />
                        <br />
                      </Col>

                      <Col md={6}>
                        <strong>Factuuradres</strong>
                        <br />
                        {addresses?.billing.length > 0 ? (
                          <>
                            {addresses.billing.address +
                              ' ' +
                              addresses.billing.house_number +
                              ' ' +
                              addresses.billing.ext}
                            <br />
                            {addresses.billing.postal_code +
                              ' ' +
                              addresses.billing.city}
                          </>
                        ) : (
                          <i>Niet ingesteld</i>
                        )}
                      </Col>
                    </Row>
                  </CardContent>
                </Card>
              </Col>

              <Col md={8}>
                <Paper square>
                  <Tabs
                    value={value}
                    onChange={handleOnChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                  >
                    <Tab
                      label={'Orders (' + user?.orders.length + ')'}
                      value={0}
                    />
                    <Tab
                      label={'Offertes (' + user?.quotes.length + ')'}
                      value={1}
                    />
                    <Tab
                      label={'Uploads (' + user?.uploads.length + ')'}
                      value={2}
                    />
                  </Tabs>
                </Paper>
                <Paper className={'mt-4'}>
                  <TabPanel value={value} index={0}>
                    {user.orders && (
                      <MaterialTable
                        title={intl.formatMessage({ id: 'ORDER.ORDERS' })}
                        onRowClick={(evt, selectedRow) =>
                          navigateToOrder(selectedRow)
                        }
                        columns={columnsOrders}
                        data={user.orders}
                        totalCount={user.orders.length}
                        options={{
                          pageSize: 10,
                        }}
                      ></MaterialTable>
                    )}
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    {user.quotes && (
                      <MaterialTable
                        title={intl.formatMessage({ id: 'QUOTE.QUOTES' })}
                        onRowClick={(evt, selectedRow) =>
                          navigateToQuote(selectedRow)
                        }
                        columns={columnsOrders}
                        data={user.quotes}
                        totalCount={user.quotes.length}
                        options={{
                          pageSize: 10,
                        }}
                      ></MaterialTable>
                    )}
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                    {user.uploads && (
                      <MaterialTable
                        title={'Uploads'}
                        onRowClick={(evt, selectedRow) =>
                          navigateToQuote(selectedRow)
                        }
                        columns={columnsOrders}
                        data={user.uploads}
                        totalCount={user.uploads.length}
                        options={{
                          pageSize: 10,
                        }}
                      ></MaterialTable>
                    )}
                  </TabPanel>
                </Paper>
              </Col>
            </Row>
          </Row>
        </Col>
      </div>
    </div>
  );
};

DetailsPage.propTypes = {
  isProcessing: PropTypes.bool.isRequired,
  data: PropTypes.object,
  countriesData: PropTypes.array,
};

const mapStateToProps = ({ users: { detailedUser }, countries }) => ({
  user: detailedUser.data,
  isProcessing: detailedUser.isProcessing,
  countriesData: countries.data,
});

export default injectIntl(connect(mapStateToProps)(DetailsPage));
