import React, { forwardRef } from 'react';
import { TextField, FormLabel, Grid } from '@material-ui/core';
import { Formik, Field } from 'formik';
import { formFileds, NewsScheme } from '../data';

const Form = ({ data }, ref) => {
  return (
    <Formik
      key={Date.now()}
      innerRef={ref}
      initialValues={{
        title: data?.title || '',
        title_en: data?.titleEn || '',
        title_de: data?.titleDe || '',
        text: data?.text || '',
        text_en: data?.textEn || '',
        text_de: data?.textDe || '',
        type: 'ADMIN_UPDATE',
      }}
      validationSchema={NewsScheme}
      validateOnChange={false}
    >
      {({ values, handleSubmit }) => (
        <form onSubmit={handleSubmit} className="kt-form">
          {formFileds.map((field, index) => (
            <Grid alignItems="center" container spacing={1} key={{index}}>
              <Grid item xs={2} spacing={3}>
                <FormLabel>{field.title}</FormLabel>
              </Grid>
              <Grid item xs={10} spacing={3}>
                <Field name={field.key} variant="outlined" id={field.key}>
                  {({ field: fieldProps, form }) => {
                    const { errors } = form;
                    return (
                      <TextField
                        id={field.key}
                        name={field.key}
                        variant="outlined"
                        margin="normal"
                        fullWidth={true}
                        helperText={errors[field.key]}
                        error={Boolean(errors[field.key])}
                        invalid={Boolean(errors[field.key])}
                        {...fieldProps}
                        defaultValue={values[field.key]}
                      />
                    );
                  }}
                </Field>
              </Grid>
            </Grid>
          ))}
        </form>
      )}
    </Formik>
  );
};

export default forwardRef(Form);
