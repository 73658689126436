import { handleActions } from "redux-actions";
import {
    getPaymentTypes,
    getPaymentTypesSuccess,
    getPaymentTypesError,
    getPaymentType,
    getPaymentTypeSuccess,
    getPaymentTypeError
} from "./actions";

const initialState = {
    isProcessing: false,
    data: [],
    error: "",
    pagination: {
        per_page: 10,
        current_page: 1,
        count: 0
    },
    detailedPaymentType: {
        isProcessing: false,
        data: null,
        status: {
            isProcessing: false
        }
    },
};

const handlers = {
    [getPaymentTypes]: (state) => ({
        ...state,
        isProcessing: true,
    }),
    [getPaymentTypesSuccess]: (state, action) => ({
        ...state,
        isProcessing: false,
        data: action.payload,
        // data: payload.quotes,
        pagination: {
            ...state.pagination,
            count: (action.payload && action.payload.length) || 0
        }
    }),
    [getPaymentTypesError]: (state, action) => ({
        ...state,
        isProcessing: false,
        error: action.payload
    }),
    [getPaymentType]: (state) => ({
        ...state,
        detailedPaymentType: {
            ...state.detailedPaymentType,
            isProcessing: true
        }
    }),
    [getPaymentTypeSuccess]: (state, action) => ({
        ...state,
        detailedPaymentType: {
            ...state.detailedPaymentType,
            isProcessing: false,
            data: action.payload
        }
    }),
    [getPaymentTypeError]: (state, action) => ({
        ...state,
        detailedPaymentType: {
            ...state.detailedPaymentType,
            isProcessing: false,
            error: action.payload
        }
    }),
};

export default handleActions(handlers, initialState);