import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Grid } from '@material-ui/core';
import { Row, Form } from 'react-bootstrap';

import { API_URL, API_URLS } from '../../../common/constants/api/apiConstants';
import initApi from '../../../crud/apiConfig/apiConfig';

const HourlyRateDiscountCmpnt = ({ data, onChange }) => {
  const [hourlyRatesData, setHourlyRatesData] = useState();
  const [hourlyRatePayload, setHourlyRatePayload] = useState();

  const [discount, setDiscount] = useState();
  const isMounted = useRef(false);

  const calculateDiscount = (price, discount) => {
    const parsedOriginalValue = parseFloat(price);
    const parsedPercentage = parseFloat(discount);

    const calculatedValue =
      parsedOriginalValue - (parsedOriginalValue * parsedPercentage) / 100;

    return calculatedValue;
  };

  const getData = async () => {
    let { data } = await initApi().get(
      API_URL + API_URLS.GET_HOURLY_RATES_DATA
    );

    if (isMounted.current) {
      setHourlyRatesData(data);
    }
  };

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      getData();
    }
  }, [isMounted]);

  useEffect(() => {
    let payload = [];

    if (isMounted.current) {
      if (hourlyRatesData && hourlyRatesData.length) {
        payload = hourlyRatesData.map((item) => {
          return {
            id: item.id,
            hourly_rate_id: item.id,
            discount: '',
            price: '',
            min_price: '',
            start_cost_discount: '',
            start_cost: '',
          };
        });

        setHourlyRatePayload(payload);
      }

      if (data && data.length > 1) {
        setHourlyRatePayload(data);
      }
    }
  }, [hourlyRatesData, isMounted]);

  useEffect(() => {
    onChange(hourlyRatePayload);
  }, [discount]);

  return (
    <>
      <Grid item container alignItems="center" xs={12}>
        <Form.Group className="m-0" key="hourly_rate" as={Row} controlId="">
          <Form.Label column md={12}>
            Hourly rate
          </Form.Label>
        </Form.Group>
      </Grid>
      {hourlyRatesData &&
        hourlyRatesData.map((item) => {
          let hourlyRateItem = hourlyRatePayload?.find(
            (el) => el.hourly_rate_id === item.id
          );
          item.hourly_rate_id = hourlyRateItem?.hourly_rate_id;

          return (
            <Grid container alignItems="center" key={item.id}>
              <Grid item container alignItems="center" xs={12} className="mb-3">
                <div className="col-md-3 mb-3">
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={item?.deliveryDays}
                    disabled
                  />
                </div>
                <div className="col-md-3 mb-3">
                  <label htmlFor="rateValue">
                    <FormattedMessage id={'Rate'} />
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="rateValue"
                    key="rateValue"
                    defaultValue={item?.rate}
                    disabled
                  />

                  <label htmlFor="minPrice">
                    <FormattedMessage id={'Minimum price'} />
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="minPrice"
                    key="minPrice"
                    defaultValue={item?.minimumPrice}
                    disabled
                  />
                </div>

                <div className="col-md-3 mb-3">
                  <label htmlFor="percentage">
                    <FormattedMessage id={'Discount (%)'} />
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="percentage"
                    key="percentage"
                    value={
                      hourlyRatePayload?.find(
                        (obj) => obj.hourly_rate_id === item.id
                      )?.discount || ''
                    }
                    onChange={(e) => {
                      setDiscount(e.target.value);
                      hourlyRateItem.discount = e.target.value;

                      hourlyRateItem.price = calculateDiscount(
                        hourlyRatesData?.find((obj) => obj.id === item.id)
                          ?.rate,
                        hourlyRateItem.discount
                      );

                      hourlyRateItem.min_price = calculateDiscount(
                        hourlyRatesData?.find((obj) => obj.id === item.id)
                          ?.minimumPrice,
                        hourlyRateItem.discount
                      );
                    }}
                  />
                </div>

                <div className="col-md-3 mb-3">
                  <label htmlFor="rate-new-value">
                    <FormattedMessage id={'Rate New Price'} />{' '}
                  </label>
                  <input
                    style={{ background: '#fff' }}
                    disabled
                    type="text"
                    className="form-control"
                    id="rate-new-value"
                    key="rate-new-value"
                    value={
                      hourlyRatePayload?.find(
                        (el) => el.hourly_rate_id === item.id
                      )?.price || ''
                    }
                  />

                  <label htmlFor="new-min-value">
                    <FormattedMessage id={'New Minimum Price'} />{' '}
                  </label>
                  <input
                    style={{ background: '#fff' }}
                    disabled
                    type="text"
                    className="form-control"
                    id="new-min-value"
                    key="new-min-value"
                    value={
                      hourlyRatePayload?.find(
                        (el) => el.hourly_rate_id === item.id
                      )?.min_price || ''
                    }
                  />
                </div>
              </Grid>

              <Grid item container alignItems="center" xs={12} className="mb-3">
                <div className="col-md-4 mb-3">
                  <label htmlFor="originalPrice">
                    <FormattedMessage id={'Startup Cost / Original Price'} />
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="originalPrice"
                    key="originalPrice"
                    value={item?.startupCosts}
                    disabled
                  />
                </div>

                <div className="col-md-4 mb-3">
                  <label htmlFor="startup_cost_discount">
                    <FormattedMessage id={'Discount (%)'} />
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="startup_cost_discount"
                    key="startup_cost_discount"
                    value={
                      hourlyRatePayload?.find(
                        (el) => el.hourly_rate_id === item.id
                      )?.start_cost_discount || ''
                    }
                    onChange={(e) => {
                      setDiscount(e.target.value);
                      hourlyRateItem.start_cost_discount = e.target.value;

                      hourlyRateItem.start_cost = calculateDiscount(
                        hourlyRatesData?.find((obj) => obj.id === item.id)
                          ?.startupCosts,
                        hourlyRateItem.start_cost_discount
                      );
                    }}
                  />
                </div>

                <div className="col-md-4 mb-3">
                  <label htmlFor="new-value">
                    <FormattedMessage id={'New Price'} />{' '}
                  </label>
                  <input
                    style={{ background: '#fff' }}
                    disabled
                    type="text"
                    className="form-control"
                    id="new-value"
                    key="new-value"
                    value={
                      hourlyRatePayload?.find(
                        (el) => el.hourly_rate_id === item.id
                      )?.start_cost || ''
                    }
                  />
                </div>
              </Grid>
            </Grid>
          );
        })}
    </>
  );
};

const mapStateToProps = ({ users: { detailedUser } }) => ({
  data: detailedUser.data?.price_setting?.price_setting_hourly_rates,
});

export default connect(mapStateToProps)(HourlyRateDiscountCmpnt);
