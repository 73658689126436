import {call, put, takeEvery} from 'redux-saga/effects';

import {
    changeOrderType,
    deleteOrder,
    getDetailedOrder,
    getDetailedQuote,
    getOrderTypes,
    ordersDataRequest,
    quotesDataRequest
} from '../../crud/orders.crud';
import {ORDERS_ACTIONS, ordersActions} from '../../store/ducks/ordersReducer';
import {QUOTES_ACTIONS, quotesActions} from '../../store/ducks/quotesReducer';


function* fetchQuotesDataRequest({payload}) {
    try {
        const quotesData = yield call(quotesDataRequest, payload);
        yield put(quotesActions.getQuotesSuccess(quotesData.data));
    } catch (err) {
        yield put(quotesActions.getQuotesError(err.response.data));
    }
}

function* fetchOrdersDataRequest({payload}) {
    try {
        const ordersData = yield call(ordersDataRequest, payload);
        yield put(ordersActions.getOrdersSuccess(ordersData.data));
    } catch (err) {
        yield put(ordersActions.getOrdersError(err.response.data));
    }
}

function* fetchOrderDetailed({payload}) {
    try {
        const {
            successCallBack = () => {
            }, data
        } = payload;

        const res = yield call(getDetailedOrder, data);

        yield put(ordersActions.getOrderDetailedSuccess({
            ...res.data, created_at: res.data.created_at
        }));

        yield successCallBack(res.data.type);
    } catch (err) {

        yield put(ordersActions.getOrderDetailedError(err.response ? err.response.data : err));
    }
}

function* fetchQuoteDetailed({payload}) {
    try {
        const res = yield call(getDetailedQuote, payload);
        yield put(quotesActions.getQuoteDetailedSuccess({
            ...res.data,
            created_at: res.data.created_at
        }));
    } catch (err) {

        yield put(quotesActions.getQuoteDetailedError(err.response.data));
    }
}

function* fetchGetOrderTypes({payload}) {
    try {
        const res = yield call(getOrderTypes);
        const {
            successCallBack = () => {
            }
        } = payload;

        const formattedResData = Object.keys(res.data).map(key => {
            const value = res.data[key];

            return {label: value, value: key};
        });

        yield put(ordersActions.getOrderTypeSuccess(formattedResData));
        yield successCallBack(formattedResData[0]);
    } catch (error) {
        yield put(ordersActions.getOrderTypeError(error));
    }
}

function* fetchChangeOrderType({payload}) {
    try {
        const {id, type} = payload;

        const res = yield call(changeOrderType, id, type);

        yield put(ordersActions.changeDetailedOrderSuccess(res.data));

        yield alert('Order status saved!');

    } catch (error) {

        yield put(ordersActions.changeDetailedOrderError(error));

    }
}

function* fetchDeleteOrderRequest({payload}) {
    try {
        const {id} = payload;

        const res = yield call(deleteOrder, id);

        yield put(ordersActions.deleteOrderSuccess(res.data));

    } catch (err) {

        yield put(ordersActions.deleteOrderError(err.response.data));

    }
}

function* ordersSagas() {
    yield takeEvery(QUOTES_ACTIONS.GET_QUOTES, fetchQuotesDataRequest);
    yield takeEvery(QUOTES_ACTIONS.GET_QUOTES_DETAILED_REQUEST, fetchQuoteDetailed);
    yield takeEvery(ORDERS_ACTIONS.GET_ORDERS_REQUEST, fetchOrdersDataRequest);
    yield takeEvery(ORDERS_ACTIONS.GET_ORDER_DETAILED_REQUEST, fetchOrderDetailed);
    yield takeEvery(ORDERS_ACTIONS.GET_ORDER_TYPES_REQUEST, fetchGetOrderTypes);
    yield takeEvery(ORDERS_ACTIONS.CHANGE_ORDER_TYPE_REQUEST, fetchChangeOrderType);
    yield takeEvery(ORDERS_ACTIONS.DELETE_ORDER_REQUEST, fetchDeleteOrderRequest);
}

export default ordersSagas;
