import React, { useState, useEffect, useRef } from 'react';
import qs from 'query-string';
import {
  Tab,
  Tabs,
  TextField,
  InputAdornment,
  FormControl,
} from '@material-ui/core';
import { Active } from './Tabs';
import { CiSearch } from 'react-icons/ci';
import {
  Portlet,
  PortletHeader,
  PortletHeaderToolbar,
} from '../../../../partials/content/Portlet';
import {
  API_URL,
  API_URLS,
} from '../../../../common/constants/api/apiConstants';
import initApi from '../../../../crud/apiConfig/apiConfig';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { useHistory, withRouter } from 'react-router-dom';
import './styles.css';
import { connect } from 'react-redux';
import { withLastLocation } from 'react-router-last-location';
import PropTypes from 'prop-types';

const MasterPage = ({ admin }) => {
  const intl = useIntl();
  const history = useHistory();
  const pagePath = history.location.pathname;
  const activeTab = JSON.parse(localStorage.getItem(`${pagePath}`));
  const [tab, setTab] = useState(activeTab ? activeTab.nextTab : 0);
  const [totalActiveQuotes, setTotalActiveQuotes] = useState(0);
  const [search, setSearch] = useState('');
  const isMounted = useRef(false);

  const activeQuery = {
    page: 1,
    search: search,
  };

  let fetchQuotesUrl =
    API_URL +
    API_URLS.GET_QUOTES_DATA +
    `?include=project.products,shipping_address.country,billing_address.country,payment_status,user&${qs.stringify(
      activeQuery
    )}`;

  const getQuotesData = async () => {
    let { data } = await initApi().get(fetchQuotesUrl);
    if (isMounted.current) {
      setTotalActiveQuotes(data.meta.pagination.total);
    }
  };

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      getQuotesData();
    }
  }, [isMounted]);

  const searchInput = (event) => {
    const searchQuery = event.target.value;
    if (searchQuery.length > 3) {
      setSearch(searchQuery);
    } else {
      setSearch('');
    }
  };

  return (
    <>
      <div id={'kt_subheader'} className={'subheader py-2 py-lg-4'}>
        <div
          className={
            'container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap'
          }
        >
          <div className={'d-flex align-items-center flex-wrap mr-1'}>
            <div className={'d-flex align-items-baseline mr-5'}>
              <h5 className={'text-dark font-weight-bold my-2 mr-5'}>
                <FormattedMessage id={'MENU.QUOTES'} />
              </h5>
            </div>
          </div>
        </div>
      </div>

      <div className={'kt-form kt-form--label-right'}>
        <Portlet className={'quotes-portlet'}>
          <PortletHeader
            toolbar={
              <>
                <PortletHeaderToolbar className={'quotes-toolbar'}>
                  <Tabs
                    component={'div'}
                    className={'quote-tabs'}
                    TabIndicatorProps={{ style: { background: 'none' } }}
                    value={tab}
                    onChange={(_, nextTab) => {
                      setTab(nextTab);
                      localStorage.setItem(
                        `${pagePath}`,
                        JSON.stringify({ nextTab })
                      );
                    }}
                  >
                    <Tab
                      label={
                        intl.formatMessage({ id: 'QUOTE.ACTIVE' }) +
                        ` (${totalActiveQuotes})`
                      }
                    />
                    {/*<Tab label={ intl.formatMessage({ id: 'QUOTES.EXPIRED' }) + ` (1)` }/>*/}
                  </Tabs>
                </PortletHeaderToolbar>

                <PortletHeaderToolbar>
                  <FormControl>
                    <TextField
                      InputProps={{
                        shrink: null,
                        startAdornment: (
                          <InputAdornment position={'start'}>
                            <CiSearch size={20} />
                          </InputAdornment>
                        ),
                      }}
                      placeholder={
                        'Zoeken naar offerte, referentie, datum, etc.'
                      }
                      className={'quotes-search-form'}
                      variant={'outlined'}
                      onChange={(e) => {
                        searchInput(e);
                      }}
                      // size={'100'}
                    />
                  </FormControl>
                </PortletHeaderToolbar>
              </>
            }
          />

          {tab === 0 && <Active admin={admin} search={search} />}

          {/*{tab === 1 && (*/}
          {/*  <Expired admin={admin} search={search} />*/}
          {/*)}*/}
        </Portlet>
      </div>
    </>
  );
};

MasterPage.propTypes = {
  history: PropTypes.object.isRequired,
  // isProcessing: PropTypes.bool,
};

const mapStateToProps = ({
  auth: {
    user: { admin },
  },
}) => ({
  admin,
});

export default injectIntl(
  connect(mapStateToProps)(withLastLocation(withRouter(MasterPage)))
);
