import styled from 'styled-components';

export const SFooter = styled.div`
  padding: 1rem;
  text-align: center;
  .disabled {
    cursor: not-allowed;
  }
`;

export const INotification = styled.span`
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  height: 44px;
  width: 44px;
  cursor: pointer;
  transition: all 0.3s;
  border-radius: 4px;

  &.breathe-animate:before {
    content: '';
    position: absolute;
    top: 7px;
    left: 7px;
    z-index: 2;
    width: 30px;
    height: 30px;
    background: #ff150f;
    opacity: 0.3;
    border-radius: 100%;
    animation: breathe2 2s infinite;
  }
  &.breathe-animate:after {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 3;
    width: 45px;
    height: 45px;
    background: #ff150f;
    opacity: 0.2;
    border-radius: 100%;
    animation: breathe 2s infinite;
  }
  > i {
    color: #ff150f !important;
    font-size: 16px;
    z-index: 10;
  }
  &.breathe-animate > i {
    color: #fff !important;
  }
`;
