import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import makerjs from 'makerjs';
import paperjs from 'paper';
import { isUndefined } from 'lodash';

import { drawActions } from '../../../../../store/ducks/draw.duck';
import { ELEMENTS } from '../../../../../common/constants/elements/elements';
import Toolbar from '../Toolbar/Toolbar';
import { SELECTED_ELEM } from '../../../../../common/constants/propTypes/propTypes';
import { Button, Modal } from 'react-bootstrap';
import parseSVG from './SvgDrawer';
import { convertDxfSimple } from '../../../../../crud/draw.crud';
import { updateFile } from '../../../../../crud/partLibrary.crud';
class Draw extends React.Component {
  constructor(props) {
    super(props);
    this.div = React.createRef();
    this.svgRef = React.createRef();
    this.selectionRectRef = React.createRef();

    this.state = {
      newExportSvg: '',
      area: 0,
      rotate: true,
      loading: {},
      chainList: {},
      selectedPaths: [],
      startX: 0,
      startY: 0,
      endX: 0,
      endY: 0,

      isMouseDown: false,
    };
  }

  componentDidMount() {
    const { materialDataRequest } = this.props;
    materialDataRequest();
  }

  componentDidUpdate = async (prevProps, prevState) => {
    const { updateDrawSVG, id, updateSelectedSVG, svg } = this.props;

    if (
      prevProps.id !== this.props.id &&
      isUndefined(this.state.loading[id]) &&
      id &&
      `${id}`?.length > 0
    ) {
      this.setState((prevState) => {
        return {
          ...prevState,
          loading: { ...prevState.loading, [id]: true },
        };
      });

        parseSVG.call(this, updateDrawSVG, id, updateSelectedSVG, svg);
    }    

    if (
      prevProps.deleteElement !== this.props.deleteElement ||
      prevProps.insertedGears !== this.props.insertedGears ||
      prevProps.insertedGalvanize !== this.props.insertedGalvanize ||
      prevProps.cutElement !== this.props.cutElement ||
      prevProps.engravingElement !== this.props.engravingElement ||
      prevProps.bendElements !== this.props.bendElements ||
      prevProps.materialData !== this.props.materialData ||
      prevProps.selectedMaterial !== this.props.selectedMaterial ||
      prevProps.selectedTypeMaterial !== this.props.selectedTypeMaterial ||
      prevProps?.bending?.direction !== this.props.bending?.direction ||
      prevProps?.bending?.side !== this.props.bending?.side ||
      prevProps?.bending?.degrees !== this.props.bending?.degrees ||
      prevProps?.bending !== this.props.bending ||
      prevState.selectedPaths.length !== this.state.selectedPaths.length
    ) {
      this.updatePreview();
    }

    if (
      prevProps?.bending?.offset !== this.props.bending?.offset ||
      prevProps?.bending?.side !== this.props.bending?.side ||
      prevProps?.bending?.degrees !== this.props.bending?.degrees ||
      prevProps?.bending !== this.props.bending
    ) {
      this.renderLine();
    }
  };

  renderLine = () => {
    const { bending, viewBox = {} } = this.props;
    return (
      <>
        {bending &&
          ((bending?.direction &&
            localStorage.getItem('posOfBending') === 'horizontal') ||
            bending[0]?.direction === 'horizontal') && (
            <svg
              id="horizontalLine"
              xmlns="http://www.w3.org/2000/svg"
              width={10}
              height={10}
              style={{
                width: '100%',
                height: '5px',
                maxHeight: '600px',
                position: 'absolute',
                left: '-3px',
                bottom:
                  ((Number(bending?.offset) ||
                    Number(bending && bending[0]?.offset)) /
                    viewBox.height.toFixed(1)) *
                    100 +
                  '%',
              }}
            >
              <line
                x1="-5"
                y1="50%"
                x2="100%"
                y2="50%"
                strokeDasharray="5 5"
                stroke="black"
              />
            </svg>
          )}
        {bending &&
          (bending?.direction === 'vertical' ||
            bending[0]?.direction === 'vertical') && (
            <svg
              style={{
                width: '12px',
                position: 'absolute',
                left:
                  ((Number(bending?.offset) ||
                    Number(bending && bending[0]?.offset)) /
                    viewBox.width.toFixed(1)) *
                    100 -
                  1 +
                  '%',
                top: 0,
              }}
              fill="#000000"
              height="18px"
              width="12px"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 330 330"
            >
              <path
                d="M250.606,154.389l-150-149.996c-5.857-5.858-15.355-5.858-21.213,0.001
              c-5.857,5.858-5.857,15.355,0.001,21.213l139.393,139.39L79.393,304.394c-5.857,5.858-5.857,15.355,0.001,21.213
              C82.322,328.536,86.161,330,90,330s7.678-1.464,10.607-4.394l149.999-150.004c2.814-2.813,4.394-6.628,4.394-10.606
              C255,161.018,253.42,157.202,250.606,154.389z"
              />
            </svg>
          )}
        {bending &&
          ((bending?.direction &&
            bending?.offset &&
            localStorage.getItem('posOfBending') === 'vertical') ||
            (bending[0]?.direction &&
              bending[0]?.offset &&
              bending[0]?.direction === 'vertical')) && (
            <svg
              id="verticalLine"
              xmlns="http://www.w3.org/2000/svg"
              width={10}
              height={10}
              style={{
                width: '5px',
                height: '100%',
                maxHeight: '600px',
                position: 'absolute',
                left:
                  ((Number(bending?.offset) ||
                    Number(bending && bending[0]?.offset)) /
                    viewBox.width.toFixed(1)) *
                    100 +
                  '%',
                top: '0',
              }}
            >
              <line
                x1="50%"
                y1="-5"
                x2="50%"
                y2="100%"
                strokeDasharray="5 5"
                stroke="black"
              />
            </svg>
          )}
      </>
    );
  };

  toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  onClick = (tag, index, id) => {
    const { selectElem, selectedElem } = this.props;
    if (selectedElem.id !== id && 'path-0' !== id) {
      selectElem(tag, index, id);
      this.prevStatePathsUpdate(tag, index, id);
    }
  };

  updatePreview = () => {
    const {
      chains,
      loosePaths,
      deleteElement,
      updateSelectedSVG,
      updateDrawSVG,
      id,
      insertedGears,
      materialData,
      engravingElement,
      // selectedTypeMaterial
    } = this.props;

    let newModel = [];
    let paths = [];
    // var selectedColor = selectedTypeMaterial && selectedTypeMaterial.color_code;
    // var fillColor = selectedColor || '#F3B3B3';
    let engravingArea = 0;
    // let cuttingArea = 0;

    if (chains || loosePaths) {
      Object.keys(chains).forEach((index) => {
        chains[index].layer = 'cuttings';
        let chainModel =
          chains[index] &&
          chains[index].links &&
          chains[index].links[0] &&
          makerjs.chain.toNewModel(chains[index]);

        if (chainModel && !chainModel.layer) {
          chainModel.layer = 'cuttings';
        }

        newModel['path-' + index] = chainModel;
        // All models inside of models
        if (chains[index].contains) {
          chains[index].contains.forEach((path, i) => {
            let svgPath;
            try {
              svgPath = makerjs.exporter.chainToSVGPathData(path);
            } catch (e) {
              console.error(e);
            }

            let chainModel = makerjs.chain.toNewModel(path);
            chainModel.layer = 'cuttings';

            const gearEl = insertedGears[`path-child-${i}`];
            // const galvanizedEl = insertedGalvanize[`path-child-${i}`];

            const threads = materialData && materialData.threads;
            const thread =
              gearEl && threads
                ? threads.find((item) => item.id === gearEl.text)
                : null;
            //const pathType = isCircle ? ELEMENTS.CIRCLE : ELEMENTS.PATH;

            // let fillElement = '#F2F3F8';
            if (thread) {
              // chainModel = makerjs.model.mirror(chainModel, false, true);
              const simpleModel = makerjs.model.simplify(chainModel);
              const simplePath =
                simpleModel.paths[Object.keys(simpleModel.paths)[0]];

              let newCircle = {
                type: 'circle',
                origin: simplePath.origin,
                radius: thread.hole / 2,
                layer: 'threads',
              };

              // fillElement = '#F2F3F8';

              paths['path-child-' + i] = newCircle;
            }

            if (engravingElement['path-child-' + i]) {
              // fillElement = fillColor;

              let svgPath;
              try {
                path.layer = 'engravings';
                svgPath = makerjs.exporter.chainToSVGPathData(path);

                paperjs.setup('paper-canvas');
                let paperPath = new paperjs.Path(svgPath);
                engravingArea += Math.abs(paperPath.area);
              } catch (e) {
                console.error(e);
              }
            }

            if (!deleteElement['path-child-' + i] && svgPath && !thread) {
              path.layer = path.layer ? path.layer : 'cuttings';
              let newPathModel = makerjs.chain.toNewModel(path);

              newPathModel.layer = path.layer ? path.layer : 'cuttings';
              newModel['path-child-' + index + i] = newPathModel;

              try {
                // let svgPath = makerjs.exporter.chainToSVGPathData(path);

                paperjs.setup('paper-canvas');
                // let paperPath = new paperjs.Path(svgPath);
                // cuttingArea += Math.abs(paperPath.area);
              } catch (e) {
                console.error(e);
              }
            }
          });
        }
      });

      let updatedModel = {
        paths: paths,
        models: newModel,
      };

      const preview = this.getPreview();

      const dxf = makerjs.exporter.toDXF(updatedModel, {
        usePOLYLINE: true,
        layerOptions: {
          engravings: {
            stroke: makerjs.exporter.colors.black,
            color: makerjs.exporter.colors.black,
          },
          threads: {
            stroke: makerjs.exporter.colors.blue,
            color: makerjs.exporter.colors.blue,
          },
          cuttings: {
            stroke: makerjs.exporter.colors.red,
            color: makerjs.exporter.colors.red,
          },
        },
        pointMatchingDistance: 0.01,
      });

      updateSelectedSVG({
        preview,
        dxf,
        engravingArea,
      });

      updateDrawSVG({ id });
    }
  };

  getPreview = (props = this.props) => {
    const {
      viewBox = {},
      chains,
      selectedElem,
      insertedGears,
      deleteElement,
      cutElement,
      engravingElement,
      selectedTypeMaterial,
      materialData,
      loadingStatus,
    } = props;

    let result = loadingStatus
      ? `
    <svg width="24" height="24" stroke="#000" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <style>
        .spinner_V8m1{transform-origin:center;animation:spinner_zKoa 2s linear infinite}
        .spinner_V8m1 circle{stroke-linecap:round;animation:spinner_YpZS 1.5s ease-in-out infinite}
        @keyframes spinner_zKoa{100%{transform:rotate(360deg)}}
        @keyframes spinner_YpZS{0%{stroke-dasharray:0 150;stroke-dashoffset:0}47.5%{stroke-dasharray:42 150;stroke-dashoffset:-16}95%,100%{stroke-dasharray:42 150;stroke-dashoffset:-59}}
        </style>
      <g class="spinner_V8m1">
        <circle cx="12" cy="12" r="9.5" fill="none" stroke-width="3"></circle>
      </g>
    </svg>`
      : `
        <svg xmlns="http://www.w3.org/2000/svg" 
          width="${viewBox.width || 0}"
          height="${viewBox.height || 0}"
          viewBox="-1 -1 ${(viewBox.width || 0) + 2} ${(viewBox.height || 0) +
          2}"
        >
        <g id="svgGroup" strokeLinecap="round" fillRule="evenodd" fontSize="9pt" stroke="#000" strokeWidth="0.25mm" fill="#F2F3F8" style="stroke: #000; stroke-width: 0.25mm; fill: #F2F3F8">`;

    chains &&
      chains.forEach((chain, index) => {
        let svgPath;

        try {
          svgPath =
            chain &&
            chain.links &&
            chain.links[0] &&
            makerjs.exporter.chainToSVGPathData(chain);
        } catch (e) {
          console.error(e);
        }


        var selectedColor =
          selectedTypeMaterial && selectedTypeMaterial.color_code;

        var fillColor = selectedColor || '#F3B3B3';
        let fillElementMain = fillColor;

        // Root path
        result += svgPath
          ? `<path
              d="${svgPath}"
              fill="${fillElementMain}"
              class="${selectedElem.id === 'path-' + index ? 'active' : ''}"
            />`
          : '';

        chain.contains &&
          chain.contains.forEach((path, i) => {
            let svgPath;
            try {
              svgPath = makerjs.exporter.chainToSVGPathData(path);
            } catch (e) {
              console.error(e);
            }

            // let threadPath = path.paths[Object.keys(path.paths)[0]];

            const gearEl = insertedGears[`path-child-${i}`];
            const threads = materialData && materialData.threads;
            const thread =
              gearEl && threads
                ? threads.find((item) => item.id === gearEl.text)
                : null;

            let fillElement = '#F2F3F8';
            if (thread) {
              fillElement = '#3858ff';
            }

            if (engravingElement['path-child-' + i]) {
              fillElement = fillColor;
            }

            if (!deleteElement['path-child-' + i] && svgPath) {
              if (thread) {
                let chainModel = makerjs.chain.toNewModel(path);

                chainModel = makerjs.model.mirror(chainModel, false, true);
                const simpleModel = makerjs.model.simplify(chainModel);
                const simplePath =
                  simpleModel.paths[Object.keys(simpleModel.paths)[0]];

                result += `
                <circle
                  r="${thread ? thread.hole / 2 : simplePath.radius}"
                  cx="${simplePath.origin[0]}"
                  cy="${Math.abs(simplePath.origin[1])}"
                  stroke-width="${
                    thread ? (thread.mm - parseFloat(thread.hole)) / 2 : 1
                  }"
                  fill="${fillElement}"
                  class="${selectedElem.id === 'path-child-' + i &&
                    'active'}  ${cutElement['path-child-' + i] &&
                  'cut-element'}"
                />
            `;
              }

              if (!thread) {
                result += `
                <path
                  d="${svgPath}"
                  fill="${fillElement}"
                  class="${selectedElem.id === 'path-child-' + i &&
                    'active'} ${cutElement['path-child-' + i] && 'cut-element'}"
                />
              `;
              }
            }
          });
      });
    result += `</g></svg>`;
    return result;
  };

  getSvgPoint = (clientX, clientY) => {
    const svg = document.getElementById('draw-svg-container');
    const point = svg.createSVGPoint();
    point.x = clientX;
    point.y = clientY;

    return point.matrixTransform(svg.getScreenCTM().inverse());
  };

  handleMouseDown = (event) => {
    event.preventDefault();
    const svgPoint = this.getSvgPoint(event.clientX, event.clientY);

    const x = svgPoint.x;
    const y = svgPoint.y;

    this.setState({
      ...this.state,
      isMouseDown: true,
      startX: x,
      startY: y,
      endX: x,
      endY: y,
    });
    this.handleMouseMove(event);
  };

  handleMouseMove = (event) => {
    const { isMouseDown } = this.state;

    if (!isMouseDown) return;

    const svgPoint = this.getSvgPoint(event.clientX, event.clientY);
    const currentX = svgPoint.x;
    const currentY = svgPoint.y;

    this.setState({
      ...this.state,
      endX: currentX,
      endY: currentY,

      currentPosition: svgPoint,
    });
  };

  handleMouseUp = () => {
    this.setState({ isMouseDown: false });

    this.findPathsInSelectedArea();
  };

  prevStatePathsUpdate = (tag, index, id) => {
    const { currentSvg } = this.props;

    this.setState((prevState) => {
      if (prevState.selectedPaths.some((path) => path.id === id)) {
        return {
          selectedPaths: [
            ...prevState.selectedPaths.filter((item) => item.id !== id),
          ],
        };
      }

      return {
        selectedPaths: [
          ...prevState.selectedPaths,
          { tag, index, id, svgId: currentSvg.id, className: 'active' },
        ],
      };
    });
  };

  toggleSelection = (ev, tag, index, id) => {
    if (ev.shiftKey) {
      this.onClick(ev, tag, index, id);
    } else {
      this.onClick(ev, tag, index, id);
    }
  };

  findPathsInSelectedArea = () => {
    const svg = this.svgRef.current;
    const selectionRect = this.selectionRectRef.current;

    const pathsInSelectedArea = [];
    const circlesInSelectedArea = [];

    for (let path of svg.getElementsByTagName('path')) {
      const pathBoundingBox = path.getBBox();

      if (
        pathBoundingBox?.x <
          selectionRect.x.baseVal.value + selectionRect.width.baseVal.value &&
        pathBoundingBox?.x + pathBoundingBox?.width >
          selectionRect.x.baseVal.value &&
        pathBoundingBox?.y <
          selectionRect.y.baseVal.value + selectionRect.height.baseVal.value &&
        pathBoundingBox?.y + pathBoundingBox?.height >
          selectionRect.y.baseVal.value
      ) {
        pathsInSelectedArea.push(path.id);
      }
    }

    pathsInSelectedArea.slice(1).forEach((element) => {
      const idx = element.match(/\d+/g);

      this.onClick(ELEMENTS.PATH, Number(idx[0]), element);
    });

    for (let circle of svg.getElementsByTagName('circle')) {
      const circleBoundingBox = circle.getBBox();

      if (
        circleBoundingBox?.x <
          selectionRect.x.baseVal.value + selectionRect.width.baseVal.value &&
        circleBoundingBox?.x + circleBoundingBox?.width >
          selectionRect.x.baseVal.value &&
        circleBoundingBox?.y <
          selectionRect.y.baseVal.value + selectionRect.height.baseVal.value &&
        circleBoundingBox?.y + circleBoundingBox?.height >
          selectionRect.y.baseVal.value
      ) {
        circlesInSelectedArea.push(circle.id);
      }
    }

    circlesInSelectedArea.forEach((element) => {
      const idx = element.match(/\d+/g);

      this.onClick(ELEMENTS.CIRCLE, Number(idx[0]), element);
    });
  };

  renderSvg = () => {
    const {
      viewBox = {},
      chains,
      selectedElem,
      insertedGears,
      deleteElement,
      cutElement,
      engravingElement,
      selectedTypeMaterial,
      materialData,
      openModal,
      bending,
    } = this.props;

    const {
      selectedPaths,
      startX,
      startY,
      endX,
      endY,
      isMouseDown,
    } = this.state;

    const svgRectJson = localStorage.getItem('svgRect');
    const svgRect =
      (document.querySelector('.svgPathEl') &&
        document.querySelector('.svgPathEl').getBoundingClientRect()) ||
      JSON.parse(svgRectJson);
    localStorage.setItem('svgRect', JSON.stringify(svgRect));
    const modalContentJson = localStorage.getItem('modalContentRect');
    const modalContentRect =
      (document.querySelector('.modal-content') &&
        document.querySelector('.modal-content').getBoundingClientRect()) ||
      JSON.parse(modalContentJson);
    localStorage.setItem('modalContentRect', JSON.stringify(modalContentRect));
    const horizontalLine = document.getElementById('horizontalLine');
    const xAxisLine = document.getElementById('xAxisLine');
    const verticalLine = document.getElementById('verticalLine');
    const yAxisLine = document.getElementById('yAxisLine');
    // const horizontalLineJson = localStorage.getItem('horizontalLineRect');
    const horizontalLineRect =
      (horizontalLine && horizontalLine.getBoundingClientRect()) ||
      localStorage.setItem(
        'horizontalLineRect',
        JSON.stringify(horizontalLineRect)
      );
    const xAxisLineRectJson = localStorage.getItem('xAxisLineRect');
    const xAxisLineRect =
      (xAxisLine && xAxisLine.getBoundingClientRect()) ||
      JSON.parse(xAxisLineRectJson);
    localStorage.setItem('xAxisLineRect', JSON.stringify(xAxisLineRect));
    const verticalLineJson = localStorage.getItem('verticalLineRect');
    const verticalLineRect =
      (verticalLine && verticalLine.getBoundingClientRect()) ||
      JSON.parse(verticalLineJson);
    localStorage.setItem('verticalLineRect', JSON.stringify(verticalLineRect));
    const yAxisLineRectJson = localStorage.getItem('yAxisLineRect');
    const yAxisLineRect =
      (yAxisLine && yAxisLine.getBoundingClientRect()) ||
      JSON.parse(yAxisLineRectJson);
    localStorage.setItem('yAxisLineRect', JSON.stringify(yAxisLineRect));

    return (
      <div>
        {openModal && (
          <>
            <div className="measurements">
              {viewBox.width.toFixed(1)} x {viewBox.height.toFixed(1)} mm
            </div>

            {((bending?.offset && bending?.side) ||
              (bending && bending[0]?.offset && bending[0]?.side)) && (
              <p
                className="measurements"
                style={{
                  width: '190px',
                  top: bending?.direction === 'horizontal' ? '85%' : '-50px',
                  left: bending?.direction === 'vertical' ? '13%' : '',
                }}
              >
                {' '}
                <FormattedMessage id="BENDING.OFFSET" />
                &nbsp;{bending?.offset || (bending && bending[0]?.offset)}
                &nbsp;mm -
                <FormattedMessage id="BENDING.DEGREES" />
                &nbsp;
                {bending?.degrees || (bending && bending[0]?.degrees)}
                &nbsp;&#176; -{' '}
                {bending?.side == 'up' ||
                (bending && bending[0]?.side == 'up') ? (
                  <FormattedMessage id="BENDING.UP" />
                ) : bending?.side == 'down' ||
                  (bending && bending[0]?.side == 'down') ? (
                  <FormattedMessage id="BENDING.DOWN" />
                ) : (
                  ''
                )}
                {bending?.side == 'up' ||
                (bending && bending[0]?.side == 'up') ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    shapeRendering="geometricPrecision"
                    textRendering="geometricPrecision"
                    imageRendering="optimizeQuality"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    viewBox="0 0 244 512.89"
                    style={{ width: '15px', height: '20px' }}
                  >
                    <path
                      fill="#fff"
                      fillRule="nonzero"
                      d="M0 138.67 120.95 0 244 140.72l-23.95 20.94-83.14-95.08.41 446.31h-31.8l-.41-446.32-81.16 93.05z"
                    />
                  </svg>
                ) : bending?.side == 'down' ||
                  (bending && bending[0]?.side == 'down') ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    shapeRendering="geometricPrecision"
                    textRendering="geometricPrecision"
                    imageRendering="optimizeQuality"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    viewBox="0 0 244 512.89"
                    style={{ width: '15px', height: '20px' }}
                  >
                    <path
                      fill="#fff"
                      fillRule="nonzero"
                      d="m23.95 353.26 81.16 93.05L105.52 0h31.8l-.41 446.3 83.14-95.08L244 372.17 120.95 512.89 0 374.21z"
                    />
                  </svg>
                ) : (
                  ''
                )}
              </p>
            )}

            <div className="canvas">
              {localStorage.getItem('isBendable') === 'true' &&
                this.renderLine()}
              {bending &&
                (bending?.direction === 'horizontal' ||
                  bending[0]?.direction === 'horizontal') && (
                  <div
                    style={{
                      position: 'absolute',
                      left: 0,
                      bottom:
                        Math.abs(horizontalLineRect?.top - xAxisLineRect?.top) +
                        6 +
                        'px',
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="#000000"
                      height="18px"
                      width="12px"
                      version="1.1"
                      id="Layer_1"
                      viewBox="0 0 330 330"
                    >
                      <path
                        id="XMLID_224_"
                        d="M325.606,229.393l-150.004-150C172.79,76.58,168.974,75,164.996,75c-3.979,0-7.794,1.581-10.607,4.394  l-149.996,150c-5.858,5.858-5.858,15.355,0,21.213c5.857,5.857,15.355,5.858,21.213,0l139.39-139.393l139.397,139.393  C307.322,253.536,311.161,255,315,255c3.839,0,7.678-1.464,10.607-4.394C331.464,244.748,331.464,235.251,325.606,229.393z"
                      />
                    </svg>
                  </div>
                )}
              {bending &&
                (bending?.direction === 'vertical' ||
                  bending[0]?.direction === 'vertical') && (
                  <svg
                    id="yAxisLine"
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="10"
                    style={{
                      width: '5px',
                      height: '100%',
                      maxHeight: '600px',
                      position: 'absolute',
                      left: svgRect?.left - modalContentRect?.left - 16 + 'px',
                      top: '0',
                    }}
                  >
                    <line
                      x1="50%"
                      y1="-5"
                      x2="50%"
                      y2="100%"
                      stroke="black"
                    ></line>
                  </svg>
                )}
              {bending &&
                (bending?.direction === 'vertical' ||
                  bending[0]?.direction === 'vertical') && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="10"
                    style={{
                      width:
                        Math.abs(
                          verticalLineRect?.left -
                            (yAxisLineRect?.left + yAxisLineRect?.width)
                        ) +
                        2 +
                        'px',
                      position: 'absolute',
                      left: svgRect?.left - modalContentRect?.left - 12 + 'px',
                      top: '4px',
                    }}
                  >
                    <line
                      x1="-5"
                      y1="50%"
                      x2="100%"
                      y2="50%"
                      stroke="black"
                    ></line>
                  </svg>
                )}
              {bending &&
                (bending?.direction === 'vertical' ||
                  bending[0]?.direction === 'vertical') && (
                  <svg
                    style={{
                      width: '12px',
                      position: 'absolute',
                      left: svgRect?.left - modalContentRect?.left - 16 + 'px',
                      top: 0,
                    }}
                    fill="#000000"
                    height="18px"
                    width="12px"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 330.002 330.002"
                  >
                    <path
                      d="M233.25,306.001L127.5,165.005L233.25,24.001c4.971-6.628,3.627-16.03-3-21c-6.627-4.971-16.03-3.626-21,3
	L96.75,156.005c-4,5.333-4,12.667,0,18l112.5,149.996c2.947,3.93,7.451,6.001,12.012,6.001c3.131,0,6.29-0.978,8.988-3.001
	C236.878,322.03,238.221,312.628,233.25,306.001z"
                    />
                  </svg>
                )}
              <svg
                id="draw-svg-container"
                ref={this.svgRef}
                style={{ height: '80%' }}
                xmlns="http://www.w3.org/2000/svg"
                width={viewBox.width || 0}
                height={viewBox.height || 0}
                viewBox={`-1 -1 ${(viewBox.width || 0) + 2} ${(viewBox.height ||
                  0) + 2}`}
                onMouseDown={this.handleMouseDown}
                onMouseMove={this.handleMouseMove}
                onMouseUp={this.handleMouseUp}
              >
                <g
                  id="svgGroup"
                  className="svgPathEl"
                  strokeLinecap="round"
                  fillRule="evenodd"
                  fontSize="9pt"
                  stroke="#000"
                  strokeWidth="0.25mm"
                  fill="#F2F3F8"
                  style={{
                    stroke: '#000',
                    strokeWidth: '0.25mm',
                    fill: '#F2F3F8',
                  }}
                >
                  {chains &&
                    chains.map((chain, index) => {
                      let svgPath;
                      try {
                        svgPath =
                          chain &&
                          chain.links &&
                          chain.links[0] &&
                          makerjs.exporter.chainToSVGPathData(chain);
                      } catch (e) {
                        console.error(e);
                      }

                      var selectedColor =
                        selectedTypeMaterial && selectedTypeMaterial.color_code;
                      var fillColor = selectedColor || '#F3B3B3';
                      let fillElementMain = fillColor;

                      return (
                        <React.Fragment key={`path-${index}`}>
                          {svgPath && (
                            <path
                              d={svgPath}
                              fill={fillElementMain}
                              id={`path-${index}`}
                              className={`${
                                selectedElem.id === 'path-' + index
                                  ? 'active'
                                  : ''
                              }`}
                              onClick={(event) =>
                                this.toggleSelection(
                                  event,
                                  ELEMENTS.PATH,
                                  index,
                                  `path-${index}`
                                )
                              }
                            />
                          )}

                          {chain.contains &&
                            chain.contains.map((path, i) => {
                              let svgPath;
                              try {
                                svgPath = makerjs.exporter.chainToSVGPathData(
                                  path
                                );
                              } catch (e) {
                                console.error(e);
                              }

                              const gearEl = insertedGears[`path-child-${i}`];
                              const threads =
                                materialData && materialData.threads;
                              const thread =
                                gearEl && threads
                                  ? threads.find(
                                      (item) => item.id === gearEl.text
                                    )
                                  : null;

                              let fillElement = '#F2F3F8';
                              if (thread) {
                                fillElement = '#3858ff';
                              }

                              if (engravingElement['path-child-' + i]) {
                                fillElement = fillColor;
                              }

                              if (
                                !deleteElement['path-child-' + i] &&
                                svgPath
                              ) {
                                if (thread) {
                                  let chainModel = makerjs.chain.toNewModel(
                                    path
                                  );
                                  chainModel = makerjs.model.mirror(
                                    chainModel,
                                    false,
                                    true
                                  );
                                  const simpleModel = makerjs.model.simplify(
                                    chainModel
                                  );
                                  const simplePath =
                                    simpleModel.paths[
                                      Object.keys(simpleModel.paths)[0]
                                    ];
                                  return (
                                    <circle
                                      id={`path-child-${i}`}
                                      key={`path-child-${i}`}
                                      r={
                                        thread
                                          ? thread.hole / 2
                                          : simplePath.radius
                                      }
                                      cx={simplePath.origin[0]}
                                      cy={simplePath.origin[1]}
                                      strokeWidth={
                                        thread
                                          ? (thread.mm -
                                              parseFloat(thread.hole)) /
                                            2
                                          : 1
                                      }
                                      fill={`${fillElement}`}
                                      className={`${
                                        selectedPaths.find((item) => {
                                          return item.id === `path-child-${i}`;
                                        })?.className
                                          ? 'active'
                                          : ''
                                      } ${
                                        cutElement['path-child-' + i]
                                          ? 'cut-element'
                                          : ''
                                      }`}
                                      onClick={(event) => {
                                        this.toggleSelection(
                                          event,
                                          ELEMENTS.CIRCLE,
                                          i,
                                          `path-child-${i}`
                                        );
                                      }}
                                    />
                                  );
                                }
                                if (!thread) {
                                  return (
                                    <path
                                      key={`path-child-${i}`}
                                      d={svgPath}
                                      fill={`${fillElement}`}
                                      className={`${
                                        selectedPaths.find((item) => {
                                          return item.id === `path-child-${i}`;
                                        })?.className
                                          ? 'active'
                                          : ''
                                      } ${
                                        cutElement['path-child-' + i]
                                          ? 'cut-element'
                                          : ''
                                      }`}
                                      id={`path-child-${i}`}
                                      onClick={(event) => {
                                        this.toggleSelection(
                                          event,
                                          ELEMENTS.PATH,
                                          i,
                                          `path-child-${i}`
                                        );
                                      }}
                                    />
                                  );
                                }
                              }
                            })}
                        </React.Fragment>
                      );
                    })}
                </g>
                {isMouseDown && (
                  <rect
                    ref={this.selectionRectRef}
                    x={Math.min(startX, endX)}
                    y={Math.min(startY, endY)}
                    width={Math.abs(endX - startX)}
                    height={Math.abs(endY - startY)}
                    fill="#A9A9A9"
                    opacity="0.35"
                  />
                )}
              </svg>

              {bending &&
                (bending?.direction === 'horizontal' ||
                  bending[0]?.direction === 'horizontal') && (
                  <div
                    style={{
                      position: 'absolute',
                      left: 0,
                      bottom: '16px',
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={10}
                      height={10}
                      style={{
                        height:
                          Math.abs(
                            horizontalLineRect?.top -
                              (xAxisLineRect?.top + xAxisLineRect?.height)
                          ) -
                          4 +
                          'px',
                        position: 'absolute',
                        left: 0,
                        bottom: '2px',
                      }}
                    >
                      <line
                        x1="50%"
                        y1="-5"
                        x2="50%"
                        y2="100%"
                        stroke="black"
                      />
                    </svg>
                    <svg
                      style={{ marginBottom: '-5px' }}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="#000000"
                      height="18px"
                      width="12px"
                      version="1.1"
                      id="Layer_1"
                      viewBox="0 0 330 330"
                    >
                      <path
                        xmlns="http://www.w3.org/2000/svg"
                        id="XMLID_225_"
                        d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393  c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393  s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z"
                      />
                    </svg>
                  </div>
                )}
              {bending &&
                (bending?.direction === 'horizontal' ||
                  bending[0]?.direction === 'horizontal') && (
                  <svg
                    id="xAxisLine"
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="10"
                    style={{
                      width: '100%',
                      height: '5px',
                      maxHeight: '600px',
                      position: 'absolute',
                      left: '-3px',
                      bottom: '16px',
                    }}
                  >
                    <line
                      x1="-5"
                      y1="50%"
                      x2="100%"
                      y2="50%"
                      stroke="black"
                    ></line>
                  </svg>
                )}
            </div>
          </>
        )}
      </div>
    );
  };

  updateSelectedPaths = async () => {
    this.setState({ selectedPaths: [] });

  };

  updatePartLibFile = async () => {

    if (this.props.fileId) {
      const convertedDxf = await convertDxfSimple(this.props.currentSvg.dxf, 'dxf', true);

      await updateFile(this.props.fileId, {
        svg_path: convertedDxf.headers['x-svg-path'],
        dxf_path: convertedDxf.headers['x-dxf-path'],
      })

      await this.props.resetState()
    }
  }

  render() {
    const { svg, openModal } = this.props;
    const { selectedPaths } = this.state;

    return (
      <>
        {svg && openModal && (
          <Modal
            show={openModal}
            onHide={async () => {
              this.props.setOpenModal(!this.props.openModal)
              this.updatePartLibFile()
            }


            }
            size="lg"
            centered
          >
            <Modal.Header closeButton>
              <Toolbar
                selectedPaths={selectedPaths}
                updateSelectedPaths={this.updateSelectedPaths}
              />
            </Modal.Header>
            <Modal.Body style={{ margin: '1em' }}>
              <div className="draw draw__content" id="drawBox">
                {this.renderSvg()}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => {

                  this.props.setOpenModal(!this.props.openModal)
                  this.updatePartLibFile()

                }

                }
              >
                {this.props.intl.formatMessage({ id: 'Save and close' })}
              </Button>
            </Modal.Footer>
          </Modal>
        )}
        <canvas id="maker-canvas" data-paper-resize />
        <canvas id="paper-canvas" data-paper-resize />
      </>
    );
  }
}

Draw.propTypes = {
  // svg: PropTypes.string,
  selectedElem: SELECTED_ELEM,
  // insertedGears: INSERTED_GEARS,
  parsedSVG: PropTypes.object,
  viewBoxWidth: PropTypes.string,
  viewBoxHeight: PropTypes.string,
  cutElement: PropTypes.object,
  engravingElement: PropTypes.object,
  deleteElement: PropTypes.object,
  bendElements: PropTypes.object,
  bending: PropTypes.array || PropTypes.object,
  materialDataRequest: PropTypes.func.isRequired,
  selectElem: PropTypes.func.isRequired,
  updateSelectedSVG: PropTypes.func.isRequired,
  updateDrawSVG: PropTypes.func.isRequired,
  materialData: PropTypes.object,
};

const mapStateToProps = ({
  draw: {
    currentSvg,
    selectedMaterial,
    uploadedSvgs,
    materialData,
    projectId,
    loading,
  },
}) => ({
  ...currentSvg,
  selectedMaterial,
  materialData,
  uploadedSvgs,
  projectId,
  currentSvg,
  loading,
});

const mapDispatchToProps = {
  updateDrawSVG: (data) => drawActions.updateDrawSVG(data),
  updateDrawSVGarray: (data) => drawActions.updateDrawSVGarray(data),
  updateSelectedSVG: (data) => drawActions.updateSelectedSVG(data),
  resetState: () => drawActions.resetState(),
  selectElem: (nodeName, index, id) =>
    drawActions.selectElement({
      nodeName,
      index,
      id,
    }),
  materialDataRequest: () => drawActions.materialDataRequest(),
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Draw));
