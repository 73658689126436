import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FormattedMessage, useIntl } from 'react-intl';
import { usePreviewMaterial } from './hook';
import { connect, useDispatch } from 'react-redux';
import { getMaterial } from '../../../store/materials/actions';

const PreviewModal = forwardRef(({ data }, ref) => {
  const dispatch = useDispatch();
  const { detail, handleMaterialDetails } = usePreviewMaterial();
  const [readMoreClass, setReadMoreClass] = useState('closed');
  const [modalVisibility, setModalVisibility] = useState(false);
  const intl = useIntl();

  useEffect(() => {
    if (!data) return;
    handleMaterialDetails(data);
  }, [data]);

  useImperativeHandle(ref, () => ({
    open: ({ materialId }) => {
      if (!materialId) {
        return;
      }

      dispatch(getMaterial(materialId));
      setModalVisibility(true);
    },
  }));

  return (
    <Modal
      show={modalVisibility}
      onHide={() => {
        setModalVisibility(false);
      }}
      size="lg"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage id="CONFIGURATOR.PREVIEW_MATERIAL" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          height: 500,
          overflow: 'scroll',
        }}
      >
        <Row style={{ textAlign: 'center' }}>
          <Col className={'three-sixty-spinner h-100 d-inline-block'}>
            <Container className="w-75">
              {/*https://sirv.com/help/viewer/?config=config-2021-04-09__12_34_04_891.json*/}
              {detail?.src && (
                <div
                  className={'Sirv'}
                  data-src={detail.src + '?t=' + Date.now()}
                  data-options={`
                      spin.column.start:75; 
                      spin.hint.onInactive.effect:none; 
                      spin.hint.message.text:${intl.formatMessage({
                        id: 'SPINNER.DRAG',
                      })}; 
                      hdQuality:100; 
                      quality:100; 
                      spin.column.reverse:false;
                    `}
                />
              )}
            </Container>
          </Col>
        </Row>
        <Row>
          <Col>
            {detail && (
              <Container>
                <Row className={'three-sixty-description'}>
                  <Col
                    className={`three-sixty-read-more ${readMoreClass}`}
                    dangerouslySetInnerHTML={{
                      __html: detail.description,
                    }}
                  ></Col>
                </Row>
                <Col className={'three-sixty-read-more-button text-center'}>
                  {detail.description.length > 1000 && (
                    <button
                      type={'button'}
                      className={'btn btn-label-brand btn-bold'}
                      onClick={() => {
                        setReadMoreClass(
                          readMoreClass == 'closed' ? 'open' : 'closed'
                        );
                      }}
                    >
                      {readMoreClass == 'closed' ? (
                        <>
                          <FontAwesomeIcon
                            icon={solid('chevron-down')}
                            size={'1x'}
                          />{' '}
                          <FormattedMessage id={'360.READ_MORE'} />
                        </>
                      ) : (
                        <>
                          <FontAwesomeIcon
                            icon={solid('chevron-up')}
                            size={'1x'}
                          />{' '}
                          <FormattedMessage id={'360.READ_LESS'} />
                        </>
                      )}
                    </button>
                  )}
                </Col>
              </Container>
            )}
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant={'secondary'}
          onClick={() => {
            setModalVisibility(false);
          }}
        >
          <FormattedMessage id="CANCEL" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
});

const mapStateToProps = ({
  materials: {
    detailedMaterial: { data, isProcessing },
  },
}) => ({
  data,
  isProcessing,
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(
  PreviewModal
);
