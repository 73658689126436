import { handleActions } from "redux-actions";
import {
  getThreads,
  getThreadsSuccess,
  getThreadsError,
  getThread,
  getThreadSuccess,
  getThreadError,
  updateThread,
  updateThreadSuccess,
  updateThreadError,
  deleteThread,
  deleteThreadSuccess,
  deleteThreadError,
} from "./actions";

const initialState = {
  isProcessing: false,
  data: [],
  error: "",
  pagination: {
    per_page: 10,
    current_page: 1,
    count: 0
  },
  detailedThread: {
    isProcessing: false,
    data: null,
    status: {
      isProcessing: false
    }
  },
};

const handlers = {
  [getThreads]: (state) => ({
    ...state,
    isProcessing: true,
  }),
  [getThreadsSuccess]: (state, action) => ({
    ...state,
    isProcessing: false,
    data: action.payload,
    // data: payload.quotes,
    pagination: {
      ...state.pagination,
      count: (action.payload && action.payload.length) || 0
    }
  }),
  [getThreadsError]: (state, action) => ({
    ...state,
    isProcessing: false,
    error: action.payload
  }),
  [getThread]: (state) => ({
    ...state,
    detailedThread: {
      ...state.detailedThread,
      isProcessing: true
    }
  }),
  [getThreadSuccess]: (state, action) => ({
    ...state,
    detailedThread: {
      ...state.detailedThread,
      isProcessing: false,
      data: action.payload
    }
  }),
  [getThreadError]: (state, action) => ({
    ...state,
    detailedThread: {
      ...state.detailedThread,
      isProcessing: false,
      error: action.payload
    }
  }),
  [updateThread]: (state) => ({
    ...state,
    detailedThread: {
      ...state.detailedThread,
      isProcessing: true,
    }
  }),
  [updateThreadSuccess]: (state) => ({
    ...state,
    detailedThread: {
      ...initialState.detailedThread,
      isProcessing: false,
    }
  }),
  [updateThreadError]: (state, action) => ({
    ...state,
    detailedThread: {
      ...state.detailedThread,
      isProcessing: false,
      status: {
        ...state.detailedThread.status,
        error: action.payload
      }
    }
  }),
  [deleteThread]: (state) => ({
    ...state,
  }),
  [deleteThreadSuccess]: (state) => ({
    ...state,
  }),
  [deleteThreadError]: (state) => ({
    ...state,
  }),
};

export default handleActions(handlers, initialState);
