import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../common/constants/routes/routes";
import { getMessages } from "../../../store/messages/actions";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

export const useGetTableData = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getMessages());
  }, []);

  const refetchData = ({ page, searchQuery, pageSize }) => {
    // todo in best case every time when we use filter there should be backend call, but for now it ignored
    dispatch(
      getMessages({ params: { page, search: searchQuery, perPage: pageSize } })
    );
  };

  return { refetchData };
};

export const useActions = ({ onDelete = () => {} }) => {
  const history = useHistory();

  return [
    {
      icon: "add",
      tooltip: "Add",
      isFreeAction: true,
      onClick: () => history.push(`${ROUTES.ADMIN_UPDATES}/create`),
    },
    {
      title: "AD",
      icon: EditIcon,
      tooltip: "Edit",
      onClick: (event, rowData) =>
        history.push(`${ROUTES.ADMIN_UPDATES}/edit/${rowData.id}`),
    },
    {
      title: "AD",
      icon: DeleteIcon,
      tooltip: "Delete",
      onClick: (event, rowData) => onDelete(rowData),
    },
  ];
};
