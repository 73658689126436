import { call, put, takeEvery } from "redux-saga/effects";

import {
  getDeliveryDaysQuery
} from "./crud";

import {
  getDeliveryDays,
  getDeliveryDaysError,
  getDeliveryDaysSuccess
} from "./actions";

function* fetchDeliveryDaysSaga({ payload }) {
  try {
    const res = yield call(getDeliveryDaysQuery, payload);
    yield put(getDeliveryDaysSuccess(res.data));
  } catch (err) {
    yield put(getDeliveryDaysError(err));
  }
}


function* deliveryDaysSagas() {
  yield takeEvery(getDeliveryDays, fetchDeliveryDaysSaga);
}

export default deliveryDaysSagas;
