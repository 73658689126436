import React, { useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { TableLayout } from "../../../../components/MasterPageLayout/Layouts";
import { columns } from "../data";
import { useActions, useGetTableData } from "../hook";
import DeleteModal from "./DeleteModal";

const MasterPage = ({ dataAdminUpdate, pagination }) => {
  const { total } = pagination;
  const modalRef = useRef({
    open: () => {},
  });
  const { refetchData } = useGetTableData();
  const actions = useActions({
    onDelete: modalRef.current.open,
  });

  return (
    <>
      <TableLayout
        title="Admin Updates"
        actions={actions}
        onChange={refetchData}
        data={dataAdminUpdate}
        columns={columns}
        totalCount={total}
      />
      <DeleteModal ref={modalRef} />
    </>
  );
};

MasterPage.propTypes = {
  dataAdminUpdate: PropTypes.array,
  isProcessing: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ messages: { data, pagination, isProcessing } }) => ({
  dataAdminUpdate: data,
  pagination,
  isProcessing,
});

export default connect(mapStateToProps)(MasterPage);
