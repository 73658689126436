import React from 'react';
import { Grid } from '@material-ui/core';

import Header from './Header';
import Footer from './Footer';
import PTFEMaterialLandingPage from './PTFEMaterialLandingPage';

const PTFEMaterialPage = () => {
  return (
    <Grid
      item
      xs={12}
      container
      style={{ fontFamily: '"IBM Plex Sans", sans-serif' }}
      className="basic-page-cutwise"
    >
      <Header />

      <PTFEMaterialLandingPage />

      <Footer />
    </Grid>
  );
};

export default PTFEMaterialPage;
