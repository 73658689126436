import React from 'react';
// import { Dropdown } from 'react-bootstrap';
// import HeaderDropdownToggle from '../content/CustomDropdowns/HeaderDropdownToggle';
// import { useHistory } from 'react-router-dom';
// import { ROUTES as routes } from '../../common/constants/routes/routes';


const UserProfileMobile = () => {

  // const history = useHistory();

  // const linkSettings = () => {
  //   history.push(routes.SETTINGS);
  // };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-2"></div>
        <div className="col-md-10">
          <div className="dropdown">
            <button type="button" className="btn btn-primary dropdown-toggle" data-toggle="dropdown">Dropdown</button>
            <div className="dropdown-menu">
              <a className="dropdown-item" href="#">Link 1</a>
              <a className="dropdown-item" href="#">Link 2</a>
              <a className="dropdown-item" href="#">Link 3</a>
            </div>
          </div>
        </div>
        <div className="col-2"></div>
      </div>
    </div>
  )
}

export default UserProfileMobile;
