import { createAction } from 'redux-actions';

export const getUsers = createAction('GET_USERS_REQUEST');
export const getUsersSuccess = createAction('GET_USERS_SUCCESS');
export const getUsersError = createAction('GET_USERS_ERROR');
export const getUser = createAction('GET_USER_REQUEST');
export const getUserSuccess = createAction('GET_USER_SUCCESS');
export const getUserError = createAction('GET_USER_ERROR');
export const deleteUser = createAction('DELETE_USER_REQUEST');
export const deleteUserSuccess = createAction('DELETE_USER_SUCCESS');
export const deleteUserError = createAction('DELETE_USER_ERROR');
export const updateUser = createAction('UPDATE_USER_REQUEST');
export const updateUserSuccess = createAction('UPDATE_USER_SUCCESS');
export const updateUserError = createAction('UPDATE_USER_ERROR');
