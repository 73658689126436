import uniqueid from 'uniqid';
import { makeLoadingObj } from './utils';

const selectedElemInitial = {
  nodeName: null,
  index: null,
  id: null,
};

const bendingInitialObj = {
  direction: null,
  offset: null,
  side: null,
  degrees: null,
  cost: null,
  id: null,
};

const initialState = {
  error: '',
  uploadedSvgs: [],
  materialData: {},
  selectedMaterial: {},
  loading: { status: false, uploadingSvgs: [] },
  loadingProgress: {},
  currentSvg: {
    svg: null,
    dxf: null,
    dxf_original: null,
    preview: null,
    insertedGears: {},
    insertedGalvanize: {},
    deleteElement: {},
    engravingElement: {},
    bendElements: {},
    cutElement: {},
    id: '',
    model: null,
    chains: null,
    area: null,
    engravingArea: null,
    looseChains: null,
    viewBox: {
      width: 0,
      height: 0,
    },
    selectedElem: { ...selectedElemInitial },
    bending: [bendingInitialObj],
    threadings_quantity: 0,
  },
  isProcessing: false,
  editProductId: null,
  projectCreation: false, //
  newFile: {}, //
  fileForEditInCAD: {}, //
};

export const DRAW_ACTIONS = {
  MATERIAL_DATA_REQUEST: 'MATERIAL_DATA_REQUEST',
  MATERIAL_DATA_REQUEST_SUCCESS: 'MATERIAL_DATA_REQUEST_SUCCESS',
  MATERIAL_DATA_REQUEST_ERROR: 'MATERIAL_DATA_REQUEST_ERROR',
  SELECTED_MATERIAL: 'SELECTED_MATERIAL',
  SELECT_MATERIAL_TYPE: 'SELECT_MATERIAL_TYPE',
  SELECT_CURRENT_SVG: 'SELECT_CURRENT_SVG',
  SELECT_BATCH_MATERIAL: 'SELECT_BATCH_MATERIAL',
  CONVERT_DXF_REQUEST: 'CONVERT_DXF_REQUEST',
  CONVERT_DXF_REQUEST_SUCCESS: 'CONVERT_DXF_REQUEST_SUCCESS',
  CONVERT_DXF_REQUEST_ERROR: 'CONVERT_DXF_REQUEST_ERROR',
  CREATE_PROJECT: 'CREATE_PROJECT',
  RESET_STATE: 'RESET_STATE',
  SET_DRAW_SVG: 'SET_DRAW_SVG',
  SELECT_ELEM: 'SELECT_ELEM',
  ENGRAVING_ELEM: 'ENGRAVING_ELEM',
  BEND_ELEM: 'BEND_ELEM',
  SELECT_BEND_ELEM: 'SELECT_BEND_ELEM',
  INSERT_GEAR: 'INSERT_GEAR',
  INSERT_GALVANIZE: 'INSERT_GALVANIZE',
  DELETE_ELEMENT: 'DELETE_ELEMENT',
  CUT_ELEMENT: 'CUT_ELEMENT',
  DELETE_SVG: 'DELETE_SVG',
  ADD_QUANTITY: 'ADD_QUANTITY',
  SAVE_PRODUCTS_REQUEST: 'SAVE_PRODUCTS_REQUEST',
  SAVE_PRODUCTS_SUCCESS: 'SAVE_PRODUCTS_SUCCESS',
  SAVE_PRODUCTS_ERROR: 'SAVE_PRODUCTS_ERROR',
  UPDATE_DRAW_SVG: 'UPDATE_DRAW_SVG',
  UPDATE_DRAW_SVG_ARRAY: 'UPDATE_DRAW_SVG_ARRAY',
  RESET_DRAW: 'RESET_DRAW',
  FULFILL_DRAW_WITH_PRODUCT: 'FULFILL_DRAW_WITH_PRODUCT',
  GET_EDIT_PRODUCT: 'GET_EDIT_PRODUCT',
  GET_EDIT_PROJECT: 'GET_EDIT_PROJECT',
  GET_EDIT_PROJECT_SUCCESS: 'GET_EDIT_PROJECT_SUCCESS',
  GET_EDIT_PROJECT_ERROR: 'GET_EDIT_PROJECT_ERROR',
  SAVE_CHANGE_PRODUCT_REQUEST: 'SAVE_CHANGE_PRODUCT_REQUEST',
  SAVE_CHANGE_PRODUCT_SUCCESS: 'SAVE_CHANGE_PRODUCT_SUCCESS',
  SAVE_CHANGE_PRODUCT_ERROR: 'SAVE_CHANGE_PRODUCT_ERROR',
  UPDATE_SELECTED_SVG: 'UPDATE_SELECTED_SVG',
  UPDATE_NEW_FILE: 'UPDATE_NEW_FILE',
  UPDATE_CAD_FILE: 'UPDATE_CAD_FILE',
  LOADING_UPDATE: 'LOADING_UPDATE',
  LOADING_PROGRESS_UPDATE: 'LOADING_PROGRESS_UPDATE',
  LOADING_PROJECT_CREATION: 'LOADING_PROJECT_CREATION,',
};

export const drawReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case DRAW_ACTIONS.CONVERT_DXF_REQUEST_SUCCESS:
      const currentSvg = {
        dxf_original: payload.dxf_original,
        svg: payload.svg,
        name: payload.name,
        id: uniqueid(),
        insertedGalvanize: {},
        insertedGears: {},
        deleteElement: {},
        cutElement: {},
        engravingElement: {},
        bendElements: {},
        selectedElem: {
          nodeName: '',
          index: null,
          id: null,
        },
        selectedTypeMaterial: payload.materialObject,
        quantity: payload.quantity,
        bending: [],
        model: null,
        chains: null,
        looseChains: null,
        viewBox: {
          width: 0,
          height: 0,
        },
        preview: null,
        threadings_quantity: 0,
      };

      return {
        ...state,
        currentSvg,
        uploadedSvgs: [...state.uploadedSvgs, currentSvg],
        error: '',
      };

    case DRAW_ACTIONS.RESET_STATE:
      return initialState;

    case DRAW_ACTIONS.SELECT_ELEM:
      return {
        ...state,
        currentSvg: {
          ...state.currentSvg,
          selectedElem: payload,
        },
      };
    case DRAW_ACTIONS.ENGRAVING_ELEM:
      return {
        ...state,
        currentSvg: {
          ...state.currentSvg,
          engravingElement: {
            ...state.currentSvg.engravingElement,
            [payload.selectedId]:
              state.currentSvg.bendElements.hasOwnProperty(
                payload.selectedId
              ) && state.currentSvg.bendElements[payload.selectedId]
                ? null
                : payload.id,
          },
          cutElement: {
            ...state.currentSvg.cutElement,
            [payload.selectedId]: null,
          },
        },
      };
    case DRAW_ACTIONS.BEND_ELEM:
      return {
        ...state,
        currentSvg: {
          ...state.currentSvg,
          bendElements: {
            ...state.currentSvg.bendElements,
            [payload.selectedId]:
              state.currentSvg.bendElements.hasOwnProperty(
                payload.selectedId
              ) && state.currentSvg.bendElements[payload.selectedId]
                ? null
                : payload.id,
          },
        },
      };
      break;
    case DRAW_ACTIONS.SELECT_BEND_ELEM:
      return {
        ...state,
        currentSvg: {
          ...state.currentSvg,
          bending: payload,
        },
      };
      break;
    case DRAW_ACTIONS.INSERT_GEAR:
      return {
        ...state,
        currentSvg: {
          ...state.currentSvg,
          insertedGears: {
            ...state.currentSvg.insertedGears,
            [payload.selectedId]: payload.item,
          },
        },
      };
    case DRAW_ACTIONS.INSERT_GEAR:
      return {
        ...state,
        currentSvg: {
          ...state.currentSvg,
          insertedGalvanize: {
            ...state.currentSvg.insertedGalvanize,
            [payload.selectedId]: payload.item,
          },
        },
      };
    case DRAW_ACTIONS.DELETE_ELEMENT:
      return {
        ...state,
        currentSvg: {
          ...state.currentSvg,
          selectedElem: { ...selectedElemInitial },
          deleteElement: {
            ...state.currentSvg.deleteElement,
            [payload.selectedId]: payload.selectedId,
          },
        },
      };
    case DRAW_ACTIONS.CUT_ELEMENT:
      return {
        ...state,
        currentSvg: {
          ...state.currentSvg,
          cutElement: {
            ...state.currentSvg.cutElement,
            [payload.selectedId]:
              state.currentSvg.bendElements.hasOwnProperty(
                payload.selectedId
              ) && state.currentSvg.bendElements[payload.selectedId]
                ? null
                : payload.id,
          },
          engravingElement: {
            ...state.currentSvg.engravingElement,
            [payload.selectedId]: null,
          },
        },
      };
    case DRAW_ACTIONS.SELECT_BATCH_MATERIAL:
      return {
        ...state,
        batchMaterial: payload.material,
      };
    case DRAW_ACTIONS.SELECT_CURRENT_SVG:
      return {
        ...state,
        currentSvg: {
          ...state.uploadedSvgs.find((svg) => svg.id === payload.id),
        },
      };
    case DRAW_ACTIONS.DELETE_SVG:
      return {
        ...state,
        uploadedSvgs: [...state.uploadedSvgs].filter(
          (svg) => svg.id !== payload
        ),
        currentSvg:
          state.currentSvg.id === payload
            ? { ...initialState.currentSvg }
            : state.currentSvg,
      };
    case DRAW_ACTIONS.CONVERT_DXF_ERROR:
      return {
        ...state,
        error: payload.data,
      };
    case DRAW_ACTIONS.CONVERT_DXF_REQUEST_ERROR:
      return {
        ...state,
        error: payload.data,
      };
    case DRAW_ACTIONS.MATERIAL_DATA_REQUEST_SUCCESS:
      return {
        ...state,
        materialData: payload,
      };
    case DRAW_ACTIONS.SELECTED_MATERIAL:
      return {
        ...state,
        selectedMaterial: {
          ...state.selectedMaterial,
          [payload.id]: payload.data,
        },
      };
    case DRAW_ACTIONS.SELECT_MATERIAL_TYPE:
      let smState = {
        ...state,
        uploadedSvgs: state.uploadedSvgs.map((item) => {
          if (item.id === payload.id) {
            item.selectedTypeMaterial = payload.data;
          }
          return item;
        }),
      };
      return smState;
    case DRAW_ACTIONS.ADD_QUANTITY:
      return {
        ...state,
        currentSvg: {
          ...state.currentSvg,
          quantity: payload.data,
        },
      };
    case DRAW_ACTIONS.SAVE_PRODUCTS_REQUEST:
      return {
        ...state,
        isProcessing: true,
      };
    case DRAW_ACTIONS.SAVE_PRODUCTS_SUCCESS:
      return {
        ...state,
        isProcessing: false,
      };
    case DRAW_ACTIONS.SAVE_PRODUCTS_ERROR:
      return {
        ...state,
        error: payload,
        isProcessing: false,
      };
    case DRAW_ACTIONS.UPDATE_DRAW_SVG:
      return {
        ...state,
        uploadedSvgs: state.uploadedSvgs.map((item) =>
          item.id === payload.id
            ? {
                ...state.currentSvg,
                selectedTypeMaterial: item.selectedTypeMaterial,
              }
            : item
        ),
      };

    case DRAW_ACTIONS.UPDATE_DRAW_SVG_ARRAY:
      return {
        ...state,
        uploadedSvgs: payload.SVG,
      };

    case DRAW_ACTIONS.RESET_DRAW:
      return initialState;
    case DRAW_ACTIONS.FULFILL_DRAW_WITH_PRODUCT:
      // eslint-disable-next-line no-case-declarations

      const parsedSvg = {
        ...initialState.currentSvg,
        svg: payload.svg,
        dxf: payload.data.dxf,
        dxf_original: payload.data.dxf_original,
        id: payload.data.id,
        quantity: payload.data.quantity,
        name: payload.data.name,
        selectedTypeMaterial: payload.data.material,
        bending: payload.data.bendings,
        threadings_quantity: payload.data.threadings_quantity,
      };

      return {
        ...state,
        isProcessing: false,
        uploadedSvgs: [...state.uploadedSvgs, parsedSvg],
        // selectedMaterial : {
        //   ...state.selectedMaterial,
        //   [payload.data.id]: {
        //     name: payload.data.material.name,
        //     color: payload.data.material.color,
        //     color_code: payload.data.material.color_code,
        //     cutting_speeds: payload.data.material.cutting_speed,
        //     id: payload.data.material.id,
        //     measurement: payload.data.material.plate_measurement,
        //     thickness: payload.data.material.thickness,
        //   }
        // },
        currentSvg: parsedSvg,
      };
    case DRAW_ACTIONS.SAVE_CHANGE_PRODUCT_REQUEST:
      return {
        ...state,
        isProcessing: true,
      };
    case DRAW_ACTIONS.SAVE_CHANGE_PRODUCT_SUCCESS:
      return {
        ...state,
        isProcessing: false,
      };
    case DRAW_ACTIONS.SAVE_CHANGE_PRODUCT_ERROR:
      return {
        ...state,
        isProcessing: false,
        error: payload,
      };
    case DRAW_ACTIONS.GET_EDIT_PROJECT:
      return {
        ...state,
        isProcessing: true,
      };
    case DRAW_ACTIONS.GET_EDIT_PROJECT_SUCCESS:
      return {
        ...state,
        isProcessing: false,
      };
    case DRAW_ACTIONS.GET_EDIT_PROJECT_ERROR:
      return {
        ...state,
        isProcessing: false,
        error: payload,
      };
    case DRAW_ACTIONS.UPDATE_SELECTED_SVG:
      return {
        ...state,
        currentSvg: {
          ...state.currentSvg,
          ...payload,
        },
      };
    case DRAW_ACTIONS.UPDATE_NEW_FILE:
      return {
        ...state,
        projectCreation: false,
        newFile: {
          ...payload,
        },
      };
    case DRAW_ACTIONS.UPDATE_CAD_FILE:
      return {
        ...state,
        projectCreation: false,
        fileForEditInCAD: {
          ...payload,
        },
      };
    case DRAW_ACTIONS.LOADING_UPDATE:
      return {
        ...state,
        ...makeLoadingObj(
          payload.status,
          payload.svgNames,
          payload.loadedName,
          state.loading
        ),
      };
    case DRAW_ACTIONS.LOADING_PROGRESS_UPDATE:
      return {
        ...state,
        loadingProgress: {
          ...state.loadingProgress,
          [payload.name]: payload.loadingObject,
        },
      };
    case DRAW_ACTIONS.LOADING_PROJECT_CREATION:
      return {
        ...state,
        projectCreation: payload.status,
      };
    default:
      return state;
  }
};

export const drawActions = {
  engravingElement: ({ nodeName, selectedId, id }) => ({
    type: DRAW_ACTIONS.ENGRAVING_ELEM,
    payload: {
      nodeName,
      selectedId,
      id,
    },
  }),
  selectBendingElement: ({ direction, offset, side, degrees, cost, id }) => ({
    type: DRAW_ACTIONS.SELECT_BEND_ELEM,
    payload: {
      direction,
      offset,
      side,
      degrees,
      cost,
      id,
    },
  }),
  toggleBendElement: ({ selectedId, id }) => ({
    type: DRAW_ACTIONS.BEND_ELEM,
    payload: {
      selectedId,
      id,
    },
  }),
  selectElement: ({ nodeName, index, id }) => ({
    type: DRAW_ACTIONS.SELECT_ELEM,
    payload: {
      nodeName,
      index,
      id,
    },
  }),
  insertGear: ({ item, selectedId, id }) => ({
    type: DRAW_ACTIONS.INSERT_GEAR,
    payload: {
      item,
      selectedId,
      id,
    },
  }),
  insertGalvanize: ({ item, selectedId, id }) => ({
    type: DRAW_ACTIONS.INSERT_GEAR,
    payload: {
      item,
      selectedId,
      id,
    },
  }),
  deleteElement: (selectedId, id) => ({
    type: DRAW_ACTIONS.DELETE_ELEMENT,
    payload: {
      selectedId,
      id,
    },
  }),
  cutElement: (selectedId, id) => ({
    type: DRAW_ACTIONS.CUT_ELEMENT,
    payload: {
      selectedId,
      id,
    },
  }),
  selectCurrentSvg: (id, extraState) => ({
    type: DRAW_ACTIONS.SELECT_CURRENT_SVG,
    payload: {
      id,
      extraState,
    },
  }),
  deleteSvg: (payload) => ({
    type: DRAW_ACTIONS.DELETE_SVG,
    payload,
  }),
  createProject: (ARRAY_DXF, type, fileType, quantity) => ({
    type: DRAW_ACTIONS.CREATE_PROJECT,
    payload: {
      ARRAY_DXF,
      type,
      fileType,
      quantity
    },
  }),
  convertDxfRequest: (
    data,
    name,
    loading,
    withAbort,
    lastOne,
    materialObject = null,
    quantity = 1
  ) => ({
    type: DRAW_ACTIONS.CONVERT_DXF_REQUEST,
    payload: {
      data,
      name,
      loading,
      withAbort,
      lastOne,
      materialObject,
      quantity,
    },
  }),
  convertDxfSuccess: ({
    svg,
    name,
    dxf_original,
    materialObject,
    quantity,
  }) => ({
    type: DRAW_ACTIONS.CONVERT_DXF_REQUEST_SUCCESS,
    payload: {
      svg,
      name,
      dxf_original,
      materialObject,
      quantity,
    },
  }),
  convertDxfError: (data) => ({
    type: DRAW_ACTIONS.CONVERT_DXF_REQUEST_ERROR,
    payload: { data },
  }),

  resetState: () => ({
    type: DRAW_ACTIONS.RESET_STATE
  }),

  materialDataRequest: () => ({ type: DRAW_ACTIONS.MATERIAL_DATA_REQUEST }),
  materialDataSuccess: (data) => ({
    type: DRAW_ACTIONS.MATERIAL_DATA_REQUEST_SUCCESS,
    payload: data,
  }),
  selectMaterial: (data, id) => ({
    type: DRAW_ACTIONS.SELECTED_MATERIAL,
    payload: {
      data,
      id,
    },
  }),
  selectMaterialType: (data, id) => ({
    type: DRAW_ACTIONS.SELECT_MATERIAL_TYPE,
    payload: {
      data,
      id,
    },
  }),
  selectBatchMaterial: (material) => ({
    type: DRAW_ACTIONS.SELECT_BATCH_MATERIAL,
    payload: {
      material,
    },
  }),
  addQuantity: (data, id) => ({
    type: DRAW_ACTIONS.ADD_QUANTITY,
    payload: {
      data,
      id,
    },
  }),
  saveProductsRequest: (payload) => ({
    type: DRAW_ACTIONS.SAVE_PRODUCTS_REQUEST,
    payload,
  }),
  saveProductsSuccess: (payload) => ({
    type: DRAW_ACTIONS.SAVE_PRODUCTS_SUCCESS,
    payload,
  }),
  saveProductsError: (payload) => ({
    type: DRAW_ACTIONS.SAVE_PRODUCTS_ERROR,
    payload,
  }),
  updateDrawSVG: (payload) => ({
    type: DRAW_ACTIONS.UPDATE_DRAW_SVG,
    payload,
  }),
  updateDrawSVGarray: (payload) => ({
    type: DRAW_ACTIONS.UPDATE_DRAW_SVG_ARRAY,
    payload,
  }),

  fulfillDrawWithProduct: (payload) => ({
    type: DRAW_ACTIONS.FULFILL_DRAW_WITH_PRODUCT,
    payload,
  }),
  resetDraw: () => ({ type: DRAW_ACTIONS.RESET_DRAW }),

  getEditProduct: (payload) => ({
    type: DRAW_ACTIONS.GET_EDIT_PRODUCT,
    payload,
  }),
  getEditProject: (payload) => ({
    type: DRAW_ACTIONS.GET_EDIT_PROJECT,
    payload,
  }),
  getEditProjectSuccess: (payload) => ({
    type: DRAW_ACTIONS.GET_EDIT_PROJECT_SUCCESS,
    payload,
  }),
  getEditProjectError: (payload) => ({
    type: DRAW_ACTIONS.GET_EDIT_PROJECT_ERROR,
    payload,
  }),
  saveChangeProductRequest: (payload) => ({
    type: DRAW_ACTIONS.SAVE_CHANGE_PRODUCT_REQUEST,
    payload,
  }),
  saveChangeProductError: (payload) => ({
    type: DRAW_ACTIONS.SAVE_CHANGE_PRODUCT_ERROR,
    payload,
  }),
  saveChangeProductSuccess: () => ({
    type: DRAW_ACTIONS.SAVE_CHANGE_PRODUCT_SUCCESS,
  }),
  updateSelectedSVG: (payload) => ({
    type: DRAW_ACTIONS.UPDATE_SELECTED_SVG,
    payload,
  }),
  updateNewFile: (payload) => ({
    type: DRAW_ACTIONS.UPDATE_NEW_FILE,
    payload,
  }),
  updateCADfile: (payload) => ({
    type: DRAW_ACTIONS.UPDATE_CAD_FILE,
    payload,
  }),
  loadingUpdate: (status, svgNames, loadedName) => ({
    type: DRAW_ACTIONS.LOADING_UPDATE,
    payload: {
      status,
      svgNames,
      loadedName,
    },
  }),
  loadingProgressUpdate: (name, loadingObject) => {
    return {
      type: DRAW_ACTIONS.LOADING_PROGRESS_UPDATE,
      payload: {
        name,
        loadingObject,
      },
    };
  },
  projectCreationUpdte: (status) => {
    return {
      type: DRAW_ACTIONS.LOADING_PROJECT_CREATION,
      payload: {
        status,
      },
    };
  },
};
