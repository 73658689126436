import {call, put, takeEvery} from 'redux-saga/effects';
import { getFolders } from "../../crud/partLibrary.crud";
import {PART_LIBRARY_ACTIONS, partLibraryActions} from "../../store/ducks/folderReducer";

function* fetchFolderData() {
    try {
        const folderData = yield call(getFolders);
        yield put(partLibraryActions.foldersDataSuccess(folderData.data))
    } catch (err)
    {
        console.error(err);
    }
}


function* partLibrarySagas() {
     yield takeEvery(PART_LIBRARY_ACTIONS.GET_FOLDERS_REQUEST, fetchFolderData);
}

export default partLibrarySagas;