import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Dropzone from 'react-dropzone';
import {withRouter} from 'react-router-dom';
import Draw from './Draw/Draw';
import {drawActions} from '../../../store/ducks/draw.duck';
import {COMMON_PROP_TYPES} from '../../../common/constants/propTypes/propTypes';
import {getTotalLengths} from '../../../common/svg/sum';
import {INVALID_DXF_ELEMENTS} from '../../../common/constants/elements/elements';
import {FormattedMessage, injectIntl} from 'react-intl';

class ConfiguratorPage extends React.Component {
    state = {
        error: '',
        loading: false,
        importing: false,
        pcSelected: false
    };

    componentDidMount() {
        const {
            match,
        } = this.props;

        const projectId = match.params.projectId;

        if (projectId) {
            this.loadProject(projectId);
        }
    }

    componentDidUpdate() {

        let {
            uploadedSvgs,
            selectedMaterial,
            materialData,
            addMaterial,
        } = this.props;
        for (let uSvg of uploadedSvgs) {
            if (!selectedMaterial[uSvg.id]) {
                materialData.materials.forEach(item => {
                    if (this.props.intl.formatMessage({id: item.name}) === uSvg.selectedTypeMaterial?.name) {
                        addMaterial({
                            ...item,
                            id: uSvg.id
                        }, uSvg.id);
                    }
                });
            }
        }
    }

    addSelectMaterial = (event, overwriteSvg = undefined) => {
        const {
            materialData,
            addMaterial,
            currentSvg
        } = this.props;

        materialData.materials.forEach(item => {
            if (this.props.intl.formatMessage({id: item.name}) === event.target.value) {

                addMaterial({
                    ...item,
                    id: overwriteSvg ? overwriteSvg.id : currentSvg.id
                }, overwriteSvg ? overwriteSvg.id : currentSvg.id);
            }
        });
    };

    addTypeMaterial = (event) => {
        const {
            selectedMaterial,
            selectMaterialType,
            currentSvg
        } = this.props;

        const idSelectedType = event.target.options[event.target.selectedIndex].id;
        const material = event.target.id;

        selectedMaterial[material].choices.forEach(item => {
            if (item.id === Number(idSelectedType)) {
                selectMaterialType(item, currentSvg.id);
            }
        });
    };

    async loadProject(projectId) {
        const {
            uploadedSvgs,
            getProject,
            deleteSvg
        } = this.props;

        for await (let uploadedSvg of uploadedSvgs) {
            deleteSvg(uploadedSvg.id);
        }
        getProject(projectId);
    }

    getDataForSaving = () => {

        const {
            uploadedSvgs,
            currentSvg,
            isEditing
        } = this.props;

        const dxfsLengths = getTotalLengths(currentSvg);

        const products = uploadedSvgs.map(value => {

            const {
                id,
                selectedTypeMaterial,
                quantity,
                area,
                engravingArea,
                insertedGears
            } = value;

            const lengthItem = dxfsLengths.map((item) => (item.id === String(id) ? item.sum : 0));

            let length = 0;

            lengthItem.forEach(item => {
                length += item;
            });

            const material = (selectedTypeMaterial && selectedTypeMaterial.id) || null;

            return {
                name: value.name,
                dxf: isEditing ? value.dxf : value.dxf.replace('HEADER\n0', 'HEADER\n9\n$INSUNITS\n70\n4\n0'),
                dxf_original: isEditing ? value.dxf : value.dxf_original,
                svg: value.preview,
                length: length + engravingArea / 1.2,
                width: value.viewBox.width,
                height: value.viewBox.height,
                threadings_quantity: insertedGears ? Object.keys(insertedGears).length : 0,
                area,
                material,
                quantity: Number(quantity),
            };
        });

        const data = {products};

        return data;
    };

    toStr = file => new Promise((resolve, reject) => {

        const reader = new FileReader();
        reader.readAsText(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);

    });

    validateDxfString = (dxfString) => {

        const invalidElemsFromString = dxfString.replace(/[\n\r\s]+/gm, ' ')
            .split(' ')
            .map(elem => INVALID_DXF_ELEMENTS.find(invalidItem => elem === invalidItem))
            .filter(elem => elem)
            .filter((value, index, self) => self.indexOf(value) === index);

        if (invalidElemsFromString.length) {

            this.setState(() => ({
                ...this.state,
                error: `${invalidElemsFromString.length > 1 ? invalidElemsFromString.join(', ') : invalidElemsFromString.join(' ')} not supported!`
            }));

        } else {

            this.setState(() => ({
                ...this.state,
                error: '',
            }));

        }

        return !invalidElemsFromString.length;
    };

    importDxfFiles = async (acceptedFiles) => {
        this.setState({importing: true});

        // let dxfString = null;
        // let countImported = 0;

        await acceptedFiles.map((acceptedFile) => {
            this.importDxf(acceptedFile);
        });
        setTimeout(() => {
            this.setState({importing: false});
        }, 2000);
    };

    importDxf = async (dxf) => {
        const {convertDxf} = this.props;

        const dxfString = await this.toStr(dxf);

        this.setState({
            uploadedFile: dxf
        });

        await convertDxf(dxfString, dxf.name);
    };

    selectSvg = (id) => () => {
        const {selectCurrentSvg} = this.props;

        selectCurrentSvg(id);
    };

    deleteSvgElem = (id) => () => {
        const {
            deleteSvg,
            selectCurrentSvg,
            uploadedSvgs
        } = this.props;

        deleteSvg(id);
        if (uploadedSvgs.length > 0) {
            setTimeout(function () {
                selectCurrentSvg(uploadedSvgs[0]?.id);
            }, 200);
        }
    };

    downloadSvgElem = (value) => () => {
        const svg = value && value.preview;

        if (svg) {
            const element = document.createElement('a');
            const file = new Blob([svg], {type: 'text/plain'});
            element.href = URL.createObjectURL(file);
            element.download = value.name.replace('dxf', 'svg') || 'example.svg';
            document.body.appendChild(element);
            element.click();
        }
    };

    downloadDxfElem = (value) => () => {
        const dxf = value && value.dxf;

        if (dxf) {
            const element = document.createElement('a');
            const file = new Blob([dxf], {type: 'text/plain'});

            element.href = URL.createObjectURL(file);
            element.download = value.name || 'example.dxf';
            document.body.appendChild(element);
            element.click();
        }
    };

    submit = (e) => {
        const {
            history,
            saveProducts
        } = this.props;
        e.preventDefault();

        const data = this.getDataForSaving();

        saveProducts(data, history, {include: 'products,products.material,order,order.shipping_address,order.billing_address'});
    };

    submitSaveChange = (e) => {
        e.preventDefault();

        const {
            history,
            saveChangeProduct,
            editProductId
        } = this.props;
        const data = this.getDataForSaving();

        saveChangeProduct(editProductId, data, history);
    };

    addQuantity = (event) => {
        const {
            selectQuantity,
            currentSvg
        } = this.props;

        selectQuantity(event.target.value, currentSvg.id);
    };

    checkIfSubmitDisabled = () => {
        const {uploadedSvgs} = this.props;

        const disabled = !!uploadedSvgs.find(svg => {
            if (!svg.selectedTypeMaterial) {
                return true;
            }

            if (svg.selectedTypeMaterial.id === null || svg.selectedTypeMaterial === undefined) {
                return true;
            }

            if (!svg.quantity) {
                return true;
            }

            return false;
        });

        return disabled;
    };

    render() {
        const {
            uploadedSvgs,
            currentSvg,
            materialData,
            selectedMaterial,
            isProcessing,
            isEditing
        } = this.props;

        const {
            importing,
        } = this.state;

        const error = this.state.error || this.props.error;
        const submitDisabled = this.checkIfSubmitDisabled();

        return (
            <div className="row">
                <div className="col-md-12">
                    <div className="kt-section">
                        <div className="kt-section__content">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="kt-portlet">
                                        <div
                                            className="kt-portlet__head kt-portlet__head--noborder kt-portlet__head--break-sm">
                                            <div className="kt-portlet__head-label">
                                                <h3 className="kt-portlet__head-title">
                                                    <FormattedMessage id="CONFIGURATOR.UPLOADED_PARTS"/>
                                                </h3>
                                            </div>
                                        </div>
                                        <div className="kt-portlet__body">
                                            <div className="kt-section">
                                                {!isEditing && (
                                                    <>
                                                        <Dropzone
                                                            accept=".dxf, .step, .stp"
                                                            onDrop={this.importDxfFiles}
                                                            minSize={0}
                                                            maxSize={2000000}
                                                        >
                                                            {({
                                                                  getRootProps,
                                                                  getInputProps,
                                                                  rejectedFiles
                                                              }) => {
                                                                const isFileTooLarge = rejectedFiles.length > 0 && rejectedFiles[0].size > 1448576;

                                                                return (
                                                                    <section className="dropzone">
                                                                        <div {...getRootProps()}>
                                                                            <input {...getInputProps()} />
                                                                            <p className={importing ? 'loading-text' : ''}>
                                                                                {importing ? 'Loading files, please wait untill finished...' :
                                                                                    <FormattedMessage
                                                                                        id="CONFIGURATOR.DRAG_AND_DROP_DXF_FILES"/>}
                                                                            </p>

                                                                            {isFileTooLarge && (
                                                                                <div className="text-danger mt-2">
                                                                                    <FormattedMessage
                                                                                        id="CONFIGURATOR.FILE_IS_TOO_LARGE"/>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </section>
                                                                );
                                                            }}
                                                        </Dropzone>
                                                    </>
                                                )}
                                                <div className="svgs-list">
                                                    {uploadedSvgs.length > 0 &&
                                                        <>
                                                            {/*<div className={'svg-batch-edit-check mb-1'}>*/}
                                                            {/*  <input type={'checkbox'}*/}
                                                            {/*         onChange={(evt) => this.setState({ 'batchOptions': evt.target.checked })}*/}
                                                            {/*         id={'svg-batch-check'}/> <label*/}
                                                            {/*  for={'svg-batch-check'}><FormattedMessage*/}
                                                            {/*  id="Edit all materials in batch?"/></label>*/}
                                                            {/*</div>*/}
                                                            {this.state.batchOptions &&
                                                                <>
                                                                    <div className={'svg-batch-edit'}>
                                                                        <select
                                                                            className={'svg-batch-material form-control mb-1'}
                                                                            onChange={(evt) => {
                                                                                this.setState({batchMaterialId: evt.target.value});
                                                                            }}>
                                                                            {materialData.materials && materialData.materials.map(item => {
                                                                                return (
                                                                                    <option
                                                                                        key={item.name}
                                                                                    > {item.name && this.props.intl.formatMessage({id: item.name})}</option>
                                                                                );
                                                                            })}
                                                                        </select>

                                                                        <select className={'svg-batch-thickness form-control'}
                                                                                onChange={this.batchThickness}>
                                                                            <option>{this.props.intl.formatMessage({id: 'CONFIGURATOR.SELECT_THICKNESS_COLOR'})}</option>
                                                                            {this.state.batchMaterialId && materialData.materials.find((mat) => mat.name == this.state.batchMaterialId)
                                                                                .choices
                                                                                .map(item => {
                                                                                        // let measurements = item.measurement.split('x');
                                                                                        // let width = measurements[0];
                                                                                        // let height = measurements[1];

                                                                                        return (
                                                                                            <option
                                                                                                key={item.id}
                                                                                                id={item.id}
                                                                                            >
                                                                                                {item.thickness.toFixed(3)
                                                                                                    .replace('.000', '')
                                                                                                    .replace('00', '')
                                                                                                    .replace('.', ',')} mm &quot;{this.props.intl.formatMessage({id: item.color})}&quot;
                                                                                            </option>
                                                                                        );
                                                                                    }
                                                                                )}
                                                                        </select>
                                                                    </div>
                                                                </>
                                                            }
                                                        </>
                                                    }

                                                    {uploadedSvgs.map(value => {

                                                        const {
                                                            id,
                                                            name,
                                                            quantity,
                                                            preview
                                                        } = value;

                                                        const labelId = `checkbox-list-secondary-label-${value}`;
                                                        const selected = id === currentSvg.id;
                                                        const className = 'col-md-12 ' + (selected ? 'active' : '');

                                                        return (
                                                            <div className="row focus-row" key={id}
                                                                 onClick={this.selectSvg(id, currentSvg.id)}>
                                                                <div className={className} key={`svg_${id}`}>
                                                                    <div className="row">
                                                                        <div className="col-md-12 ">
                                                                            <h5>{name}</h5>
                                                                        </div>
                                                                    </div>

                                                                    <div className="row">
                                                                        <div className="col-md-4">
                                                                            <div className="row">

                                                                                <div className="svg-wrapper col-md-12"
                                                                                     id={`configurator-draw-box:${id}`}
                                                                                     dangerouslySetInnerHTML={{
                                                                                         __html: preview
                                                                                     }}
                                                                                ></div>

                                                                                <div className="col-md-12">
                                                                                    <button
                                                                                        className="btn btn-secondary btn-sm mb-2"
                                                                                        onClick={this.deleteSvgElem(id)}>
                                                                                        <FormattedMessage
                                                                                            id="CONFIGURATOR.DELETE"/>
                                                                                    </button>

                                                                                </div>

                                                                            </div>
                                                                        </div>

                                                                        <div id={labelId} className="col-md-8">
                                                                            {value.svg && (

                                                                                <div className="svgs-list-item__details"
                                                                                     id={id}>

                                                                                    <div className="row">
                                                                                        <div className="col-md-12">
                                                                                            <select
                                                                                                className="form-control"
                                                                                                onChange={this.addSelectMaterial}
                                                                                                onClick={this.selectSvg(id, currentSvg.id)}
                                                                                            >
                                                                                                <option>{this.props.intl.formatMessage({id: 'CONFIGURATOR.SELECT_MATERIAL'})}</option>
                                                                                                {materialData.materials && materialData.materials.map(item => {
                                                                                                    let foundApplicable = false;

                                                                                                    for (let choice of item.choices) {
                                                                                                        let measurements = choice.measurement.split('x');
                                                                                                        let width = measurements[0];
                                                                                                        let height = measurements[1];
                                                                                                        if ((currentSvg?.viewBox?.width <= width && currentSvg?.viewBox?.height <= height) || (currentSvg?.viewBox?.width <= height && currentSvg?.viewBox?.height <= width)) {
                                                                                                            foundApplicable = true;
                                                                                                        }
                                                                                                    }

                                                                                                    return (
                                                                                                        <option
                                                                                                            key={item.name}
                                                                                                            selected={value.selectedTypeMaterial?.name == item.name}
                                                                                                            disabled={!foundApplicable}
                                                                                                        > {item.name && this.props.intl.formatMessage({id: item.name})}</option>
                                                                                                    );
                                                                                                })}
                                                                                            </select>

                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="row">
                                                                                        <div className="col-md-12">

                                                                                            {selectedMaterial[value.id] ? (
                                                                                                <select
                                                                                                    className="form-control"
                                                                                                    onClick={this.selectSvg(id, currentSvg.id)}
                                                                                                    onChange={this.addTypeMaterial}
                                                                                                    id={id}
                                                                                                >
                                                                                                    <option>{this.props.intl.formatMessage({id: 'CONFIGURATOR.SELECT_THICKNESS_COLOR'})}</option>
                                                                                                    {selectedMaterial[id].choices.map(item => {
                                                                                                            let measurements = item.measurement.split('x');
                                                                                                            let width = measurements[0];
                                                                                                            let height = measurements[1];

                                                                                                            return (
                                                                                                                <option
                                                                                                                    key={item.id}
                                                                                                                    id={item.id}
                                                                                                                    selected={item.id == value.selectedTypeMaterial?.id}
                                                                                                                    disabled={!((currentSvg?.viewBox?.width <= width && currentSvg?.viewBox?.height <= height) || (currentSvg?.viewBox?.width <= height && currentSvg?.viewBox?.height <= width))}
                                                                                                                >
                                                                                                                    {item.thickness.toFixed(3)
                                                                                                                        .replace('.000', '')
                                                                                                                        .replace('00', '')
                                                                                                                        .replace('.', ',')} mm &quot;{this.props.intl.formatMessage({id: item.color})}&quot;
                                                                                                                </option>
                                                                                                            );
                                                                                                        }
                                                                                                    )}
                                                                                                </select>
                                                                                            ) : (
                                                                                                <select
                                                                                                    className="form-control"
                                                                                                    disabled={true}
                                                                                                    onClick={this.selectSvg(id, currentSvg.id)}
                                                                                                >
                                                                                                    <option>{this.props.intl.formatMessage({id: 'CONFIGURATOR.SELECT_THICKNESS_COLOR'})}</option>
                                                                                                </select>
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="row">

                                                                                        <div className="col-md-12">
                                                                                            <input
                                                                                                className="form-control"
                                                                                                placeholder={this.props.intl.formatMessage({id: 'CONFIGURATOR.QUANTITY'})}
                                                                                                type="number"
                                                                                                value={quantity || ''}
                                                                                                onClick={this.selectSvg(id, currentSvg.id)}
                                                                                                onChange={this.addQuantity}
                                                                                            />
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </div>

                                                                    </div>
                                                                    {(error) && (
                                                                        <span className="badge badge-pill badge-info">{error}</span>
                                                                    )}
                                                                    <hr className="m-t-2"/>

                                                                    {/*{pcSelected && <p className="font-bold"><strong><small>* {this.props.intl.formatMessage({ id: "PC_MESSAGE"})}</small></strong></p>}*/}
                                                                </div>

                                                            </div>

                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {isEditing ? (
                                        <div className="proceed-button-container">
                                            <button
                                                disabled={submitDisabled}
                                                type="submit"
                                                onClick={this.submitSaveChange}
                                                className="proceed-button-container__item"
                                                title={submitDisabled ? 'Please select one detail and choose material and quantity in configurator' : ''}
                                            >
                                                {isProcessing ? 'Loading..' :
                                                    <FormattedMessage id="CONFIGURATOR.SAVE_CHANGE_QUOTE"/>}
                                            </button>
                                        </div>
                                    ) : !!uploadedSvgs.length && (
                                        <div className="proceed-button-container">
                                            <button
                                                disabled={submitDisabled}
                                                type="submit"
                                                onClick={this.submit}
                                                className="proceed-button-container__item"
                                                title={submitDisabled ? 'Please choose material and quantity for every detail in configurator' : ''}
                                            >
                                                {isProcessing ? 'Loading..' :
                                                    <FormattedMessage id="CONFIGURATOR.PROCEED_TO_CHECKOUT"/>}
                                            </button>
                                        </div>
                                    )}
                                </div>

                                <div className="col-md-8">
                                    <div className="kt-portlet kt-background-transparent">

                                        <div className="kt-portlet__body">
                                            <div className="kt-section">
                                                <Draw importDxfFiles={this.importDxfFiles} importing={importing}/>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

ConfiguratorPage.propTypes = {
    uploadedSvgs: PropTypes.arrayOf(COMMON_PROP_TYPES.CURRENT_SVG),
    currentSvg: COMMON_PROP_TYPES.CURRENT_SVG,
    isProcessing: PropTypes.bool.isRequired,
    materialData: PropTypes.object.isRequired,
    error: PropTypes.string,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    selectedMaterial: PropTypes.object.isRequired,
    selectMaterialType: PropTypes.func.isRequired,
    selectQuantity: PropTypes.func.isRequired,
    addMaterial: PropTypes.func.isRequired,
    setDraw: PropTypes.func.isRequired,
    selectCurrentSvg: PropTypes.func.isRequired,
    deleteSvg: PropTypes.func.isRequired,
    convertDxf: PropTypes.func.isRequired,
    saveProducts: PropTypes.func.isRequired,
    getProduct: PropTypes.func.isRequired,
    dataProducts: PropTypes.object,
    isEditing: PropTypes.bool,
    saveChangeProduct: PropTypes.func.isRequired,
    editProductId: PropTypes.string,
};

const mapStateToProps = (
    {
        draw: {
            uploadedSvgs,
            currentSvg,
            error,
            editProductId,
            materialData,
            selectedMaterial,
            isProcessing,
        },
        quotes: {
            selectedQuoteDetails: {data}
        }
    }
) => (
    {
        uploadedSvgs,
        currentSvg,
        error,
        materialData,
        selectedMaterial,
        isProcessing,
        dataProducts: data,
        editProductId,
    }
);

const mapDispatchToProps = {
    setDraw: (svg, name) => drawActions.setDraw({
        svg,
        name
    }),
    getProject: (id) => drawActions.getEditProject(id),
    getProduct: (id) => drawActions.getEditProduct(id),
    selectCurrentSvg: drawActions.selectCurrentSvg,
    deleteSvg: drawActions.deleteSvg,
    convertDxf: (data, name) => drawActions.convertDxfRequest(data, name),
    addMaterial: (data, id) => drawActions.selectMaterial(data, id),
    selectMaterialType: (data, id) => drawActions.selectMaterialType(data, id),
    selectQuantity: (data, id) => drawActions.addQuantity(data, id),
    saveProducts: (data, history, params) => drawActions.saveProductsRequest({
        data,
        history,
        params
    }),
    saveChangeProduct: (id, data, history) => drawActions.saveChangeProductRequest({
        id,
        data,
        history
    }),
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withRouter(ConfiguratorPage)));
