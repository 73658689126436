import { call, put, takeEvery } from 'redux-saga/effects';
import { ROUTES } from '../../common/constants/routes/routes';
import {
  checkoutActions,
  CHECKOUT_ACTIONS,
} from '../../store/ducks/checkout.duck';
import { toast } from 'react-toastify';

import {
  saveAsQuote,
  proceedToPayment,
  getCheckout,
  getDeliveryDays,
  putCheckout,
  getCountry,
  getShippingTax,
  processPayment,
  checkStatus,
} from '../../crud/checkout.crud';
import { checkAuth } from '../../crud/auth.crud';
import { authActions } from '../../store/ducks/auth.duck';
import { setStorage } from '../../../_metronic/utils/utils';

function* proceedToPaymentRequest({ payload }) {
  const { history, projectId, data, params } = payload;

  try {
    let refresh = false;
    const res = yield call(proceedToPayment, data, projectId, params);
    yield put(checkoutActions.proceedToPaymentSuccess(res.data));

    if (res.data?.user && res.data?.user?.jwt == null) {
      if (res.data?.url) {
        yield (window.location.href = res.data.url);
      } else {
        yield history.push({
          pathname:
            `${ROUTES.ORDERS}/details/${res.data.id}` +
            '?' +
            new URLSearchParams({ total: res.data.total }).toString(),
          state: { newOrder: true },
        });
      }
    }

    if (res.data?.user && res.data?.user?.jwt != null) {
      yield setStorage('id', res.data.user.id);
      yield setStorage('token', res.data.user.jwt);

      const result = yield call(checkAuth);
      yield put(authActions.checkAuthSuccess(result.data.data));

      if (res.data?.url) {
        yield (window.location.href = res.data.url);
      } else {
        yield history.push({
          pathname:
            `${ROUTES.ORDERS}/details/${res.data.id}` +
            '?' +
            new URLSearchParams({ total: res.data.total }).toString() +
            (refresh ? '&refresh=true' : ''),
          state: { newOrder: true },
        });
      }
    }
  } catch (error) {
    toast.error(
      error?.response?.data?.error ||
        'Error while saving the order.. Probably you have not fulfilled all fields',
      {
        position: 'bottom-right',
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      }
    );
    yield put(checkoutActions.proceedToPaymentError(error));
  }
}
function* saveOrder({ payload }) {
  const { projectId, data, params } = payload;

  try {
    // const res = yield call(proceedToPayment, data, projectId, params);
    yield call(proceedToPayment, data, projectId, params);
  } catch (error) {
    toast.error(
      error?.response?.data?.error ||
        'Error while saving the order.. Probably you have not fulfilled all fields',
      {
        position: 'bottom-right',
        autoClose: 2500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      }
    );
    yield put(checkoutActions.proceedToPaymentError(error));
  }
}

function* saveAsQuoteRequest({ payload }) {
  const { history, projectId, data, params } = payload;

  try {
    const res = yield call(saveAsQuote, data, projectId, params);
    yield put(checkoutActions.saveAsQuoteSuccess(res.data));

    if (res.data?.user && res.data?.user?.jwt == null) {
      if (res.data?.url) {
        yield (window.location.href = res.data.url);
      } else {
        yield history.push({
          pathname:
            `${ROUTES.QOUTES}/details/${res.data.id}` +
            '?' +
            new URLSearchParams({ total: res.data.total }).toString(),
          state: { newQuote: true },
        });
      }
    }

    if (res.data?.user && res.data?.user?.jwt != null) {
      yield setStorage('id', res.data.user.id);
      yield setStorage('token', res.data.user.jwt);
      const result = yield call(checkAuth);
      yield put(authActions.checkAuthSuccess(result.data.data));
    }

    if (res.data?.old_order_id) {
      yield history.push({
        pathname: `${ROUTES.QOUTES}/details/${res.data.old_order_id}`,
        state: { newQuote: false },
      });
    } else {
      yield history.push({
        pathname: `${ROUTES.QOUTES}/details/${res.data.id}`,
        state: { newQuote: true },
      });
    }
  } catch (error) {
    toast.error(
      error?.response?.data?.error ||
        'Error while saving as quote.. Probably you have not fulfilled all fields',
      {
        position: 'bottom-right',
        autoClose: 2500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      }
    );
    yield put(checkoutActions.saveAsQuoteError(error));
  }
}

function* fetchPutCheckout({ payload }) {
  try {
    const { id, data, params, successCallBack = () => {} } = payload;
    const res = yield call(putCheckout, data, id, params, successCallBack);

    yield put(checkoutActions.putCheckoutRequestSuccess(res.data));
    yield successCallBack(res.data);
  } catch (err) {
    yield put(checkoutActions.putCheckoutRequestError(err));
  }
}

function* fetchGetCheckout({ payload }) {
  if (payload) {
    try {
      // console.log(payload);
      const res = yield call(getCheckout, payload);
      yield put(checkoutActions.getCheckoutSuccess(res.data));
    } catch (err) {
      yield put(checkoutActions.getCheckoutError(err));
    }
  }
}

function* fetchGetDeliveryDays({ payload }) {
  try {
    const res = yield call(getDeliveryDays, payload);

    yield put(checkoutActions.getDeliveryDaysRequestSuccess(res.data));
  } catch (err) {
    yield put(checkoutActions.getDeliveryDaysRequestError(err));
  }
}

function* fetchCountry() {
  try {
    const res = yield call(getCountry);
    yield put(checkoutActions.getCountrySuccess(res.data));
  } catch (err) {
    yield put(checkoutActions.getCountryError(err));
  }
}

function* fetchPaymentProcess({ payload }) {
  const { history, data } = payload;

  try {
    const res = yield call(processPayment, data);
    localStorage.setItem('checkId', res.data.id);
    localStorage.setItem('checkoutPath', history.location.pathname);

    window.location.replace(res.data.url);
    yield put(checkoutActions.processPaymentSuccess(res.data));
  } catch (err) {
    yield put(checkoutActions.processPaymentError(err));
  }
}

function* checkPaymentStatus({ payload }) {
  const { data } = payload;
  try {
    const res = yield call(checkStatus, data);
    yield put(checkoutActions.checkStatusSuccess(res.data));
  } catch (err) {
    yield put(checkoutActions.checkStatusSuccess(err));
  }
}

function* fetchShippingTax({ payload }) {
  try {
    const res = yield call(getShippingTax, payload);
    yield put(checkoutActions.getShippingTaxSuccess(res.data));
  } catch (err) {
    yield put(checkoutActions.getShippingTaxError(err));
  }
}

export default function* checkoutSagas() {
  yield takeEvery(CHECKOUT_ACTIONS.SAVE_ORDER, saveOrder);
  yield takeEvery(
    CHECKOUT_ACTIONS.PROCEED_TO_PAYMENT_REQUEST,
    proceedToPaymentRequest
  );
  yield takeEvery(CHECKOUT_ACTIONS.SAVE_AS_QUOTE_REQUEST, saveAsQuoteRequest);
  yield takeEvery(CHECKOUT_ACTIONS.GET_CHECKOUT_REQUEST, fetchGetCheckout);
  yield takeEvery(
    CHECKOUT_ACTIONS.GET_DELIVERY_DAYS_REQUEST,
    fetchGetDeliveryDays
  );
  yield takeEvery(CHECKOUT_ACTIONS.PUT_CHECKOUT_REQUEST, fetchPutCheckout);
  yield takeEvery(CHECKOUT_ACTIONS.GET_COUNTRY_REQUEST, fetchCountry);
  yield takeEvery(CHECKOUT_ACTIONS.GET_SHIPPING_TAX_REQUEST, fetchShippingTax);
  yield takeEvery(
    CHECKOUT_ACTIONS.PROCESS_PAYMENT_REQUEST,
    fetchPaymentProcess
  );
  yield takeEvery(
    CHECKOUT_ACTIONS.CHECK_PAYMENT_STATUS_REQUEST,
    checkPaymentStatus
  );
}
