import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Form, Col, Button } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import { useHistory, useParams } from 'react-router-dom';
import { Paper } from "@material-ui/core";
import { updateVat, getVat } from "../../../store/vats/actions";
import { ROUTES } from "../../../common/constants/routes/routes";
import Multiselect from 'multiselect-react-dropdown';
import { getCountries } from "../../../store/countries/actions";

const DetailsPage = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { data, isProcessing, countriesData } = props;
  const [form, setForm] = useState({});
  const [validated, setValidated] = useState(false);
  const [countries, setCountries] = useState([]);
  const fields = [
      { title: 'Country', name: 'country', type: 'select', required: true, options: countries },
      // { title: 'Description', name: 'description', type: 'text', required: false },
      { title: 'Percentage', name: 'percentage', type: 'float', required: true },
      { title: 'Percentage International', name: 'percentage_intl', type: 'float', required: true },
  ]

  useEffect(() => {
    if (id) {
      dispatch(getVat(id))
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (data && id) {
      setForm(data);
    } else {
      setForm({})
    }
  }, [data]); // eslint-disable-line

  useEffect(() => {
    dispatch(getCountries());
  }, []);

  useEffect(() => {
    let formatCountryData = countriesData.map((country) => {
      return {
        id: country?.id,
        name: country?.name
      }
    })
    setCountries(formatCountryData);
  }, [countriesData]);

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);

    const payload = {};
    fields.forEach(field => {
      switch (field.type) {
        case 'integer':
          payload[field.name] = parseInt(form[field.name]);
          break;
        case 'float':
          payload[field.name] = parseFloat(form[field.name]);
          break;
        default:
          payload[field.name] = form[field.name];
      }
    })

    dispatch(updateVat({id: id, data: payload, successCallBack: () => {
      history.push(ROUTES.VATS);
    }}));
  }

  const handleCancel = () => {
    history.push(ROUTES.VATS);
  }

  const onChange = field => event => {
    setForm({...form, [field]: event.target.value});
  }

  return isProcessing ? 'Loading...' : (
    <div className="row">
      <div className="col-md-12">
        <div className="kt-section">
          <div className="kt-section__content">
            <Paper className="p-5">
              <h1 className="kt-invoice__title mb-5">Vat {id ? 'Edit' : 'Add'}</h1>

              <Form
                noValidate
                validated={validated}
                onSubmit={handleSubmit}
              >
                {fields.map(field => (

                    <Form.Group key={field.name} as={Row} controlId="">
                      <Form.Label column sm={2}>{field.title}</Form.Label>
                      <Col sm={10}>
                        {field.type === "text" && <Form.Control
                            type="text"
                            defaultValue={form[field.name] || ''}
                            onChange={onChange(field.name)}
                            required={field.required}
                        />}
                        {field.type === "float" && <Form.Control
                            type="text"
                            defaultValue={form[field.name] || ''}
                            onChange={onChange(field.name)}
                            required={field.required}
                        />}
                        {field.type === "integer" && <Form.Control
                            type="text"
                            defaultValue={form[field.name] || ''}
                            onChange={onChange(field.name)}
                            required={field.required}
                        />}
                        {field.type === "select" && <Multiselect
                            options={field.options} // Options to display in the dropdown
                            selectedValues={[
                              {
                                id: (form[field.name]) ? form[field.name].id : '',
                                name: (form[field.name]) ? form[field.name].name : ''
                              }
                            ]} // Preselected value to persist in dropdown
                            singleSelect={true}
                            onSelect={(selectedList) => {
                              form[field.name] = selectedList[0];
                            }}
                            avoidHighlightFirstOption={true}
                            displayValue="name"
                        />
                        }
                      </Col>
                    </Form.Group>
                ))}
                <Row>
                  <Col sm={12}>
                    <Button className="pull-right" type="submit" >Save </Button>
                    <Button className="pull-right mr-2" variant="light" onClick={handleCancel}>Cancel</Button>
                  </Col>
                </Row>
              </Form>
            </Paper>
          </div>
        </div>
      </div>
    </div>
  );
}

DetailsPage.propTypes = {
  isProcessing: PropTypes.bool.isRequired,
  data: PropTypes.object,
};

const mapStateToProps = ({ vats: { detailedVat: { data, isProcessing } }, countries }) => ({
  data,
  isProcessing,
  countriesData: countries.data,
});

export default connect(mapStateToProps)(DetailsPage);
