import React from 'react';
import { Route, Switch } from 'react-router-dom';
import MasterPage from './MasterPage';
import FormPage from './FormPage';
import { ROUTES } from "../../../common/constants/routes/routes";

export default function Threads () {
  return (
    <Switch>
      <Route exact path={ROUTES.THREADS} component={MasterPage} />
      <Route exact path={`${ROUTES.THREADS}/create`} component={FormPage} />
      <Route exact path={`${ROUTES.THREADS}/edit/:id`} component={FormPage} />
    </Switch>
  );
}
