import React from 'react';
import { Route, Switch } from 'react-router-dom';
import MasterPage from './MasterPage';
import { ROUTES } from "../../../common/constants/routes/routes";

export default function PartsInLib() {
    return (
        <Switch>
            <Route exact path={ROUTES.PARTSINLIB} component={MasterPage} />
        </Switch>
    )
}
