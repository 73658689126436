import { handleActions } from "redux-actions";
import {
  getMaterials,
  getMaterialsSuccess,
  getMaterialsError,
  getMaterial,
  getMaterialSuccess,
  getMaterialError,
  updateMaterial,
  updateMaterialSuccess,
  updateMaterialError,
  deleteMaterial,
  deleteMaterialSuccess,
  deleteMaterialError,
} from "./actions";

const initialState = {
  isProcessing: false,
  data: [],
  error: "",
  pagination: {
    per_page: 10,
    current_page: 1,
    count: 0
  },
  detailedMaterial: {
    isProcessing: false,
    data: null,
    status: {
      isProcessing: false
    }
  },
};

const handlers = {
  [getMaterials]: (state) => ({
    ...state,
    isProcessing: true,
  }),
  [getMaterialsSuccess]: (state, action) => ({
    ...state,
    isProcessing: false,
    data: action.payload,
    // data: payload.quotes,
    pagination: {
      ...state.pagination,
      count: (action.payload && action.payload.length) || 0
    }
  }),
  [getMaterialsError]: (state, action) => ({
    ...state,
    isProcessing: false,
    error: action.payload
  }),
  [getMaterial]: (state) => ({
    ...state,
    detailedMaterial: {
      ...state.detailedMaterial,
      isProcessing: true
    }
  }),
  [getMaterialSuccess]: (state, action) => ({
    ...state,
    detailedMaterial: {
      ...state.detailedMaterial,
      isProcessing: false,
      data: action.payload
    }
  }),
  [getMaterialError]: (state, action) => ({
    ...state,
    detailedMaterial: {
      ...state.detailedMaterial,
      isProcessing: false,
      error: action.payload
    }
  }),
  [updateMaterial]: (state) => ({
    ...state,
    detailedMaterial: {
      ...state.detailedMaterial,
      isProcessing: true,
    }
  }),
  [updateMaterialSuccess]: (state) => ({
    ...state,
    detailedMaterial: {
      ...initialState.detailedMaterial,
      isProcessing: false,
    }
  }),
  [updateMaterialError]: (state, action) => ({
    ...state,
    detailedMaterial: {
      ...state.detailedMaterial,
      isProcessing: false,
      status: {
        ...state.detailedMaterial.status,
        error: action.payload
      }
    }
  }),
  [deleteMaterial]: (state) => ({
    ...state,
  }),
  [deleteMaterialSuccess]: (state) => ({
    ...state,
  }),
  [deleteMaterialError]: (state) => ({
    ...state,
  }),
};

export default handleActions(handlers, initialState);
