import makerjs from 'makerjs';

import { FOLDER, FILE, FOLDER_KEYS, FILE_KEYS } from '../../../common/constants/cadJSON';
import { getQuoteSvg } from '../../../crud/draw.crud'
import { priceCalculator } from '../../../partials/content/priceCalculator';;

function materialById(choiceData) {
    const byOBJ = {};
    Object.values(choiceData).forEach((M) =>
        M.choices.forEach(
        (choice) =>
            (byOBJ[choice.id] = {
            ...choice,
            name: M.name,
            color_nl: choice.color,
            })
        )
    );
    return byOBJ;
};

async function fetchSVGs(fileList, choiceData) {
    const svgPromises = fileList.map(async (item) => {
      const fillColor =
        item?.material !== null ? '#c0c0c0' : 'rgb(243, 179, 179)';

      if (item.product_id) {
        const { data } = await getQuoteSvg(item.product_id);

        const parser = new DOMParser();
        const svgDoc = parser.parseFromString(data, 'image/svg+xml');
        const svgElement = svgDoc.querySelector('svg');
        const width = svgElement?.getAttribute('width');
        const height = svgElement?.getAttribute('height');
        const viewBox = svgElement?.getAttribute('viewBox').split(' ');

        const paths = svgDoc.querySelectorAll('path');
        const polyLines = svgDoc.querySelectorAll('polyline');
        const circles = svgDoc.querySelectorAll('circle');
        const rectangles = svgDoc.querySelectorAll('rect');
        const modelsMaker = [];
        const pathsMaker = [];

        let totalLength = 0;
        let totalArea = viewBox && viewBox.length > 3 && (viewBox[2] - 10) * (viewBox[3] - 10);

        paths.forEach((path) => {
          totalLength += path.getTotalLength();
        });

        // Extract all paths and add them to models["path"]
        paths.forEach((elem, index) => {
          let pathData = elem.getAttribute('d');

          try {
            var model = makerjs.importer.fromSVGPathData(pathData);
            model = makerjs.model.mirror(model, false, true);

            if (model && model.paths) {
              Object.keys(model.paths).forEach((key) => {
                if (model.paths[key] && fillColor) {
                  model.paths[key].fill = model.paths[key].fill || fillColor;
                }
              });
            }

            if (model && model.models) {
              Object.keys(model.models).forEach((key) => {
                let shape = model.models && model.models[key];

                if (shape && shape.paths && fillColor) {
                  shape.paths.fill = shape.paths.fill || fillColor;
                }
              });
            }

            modelsMaker['path' + index] = model;
          } catch (e) {
            console.error(e);
          }
        });

        polyLines.forEach((polyline) => {
          totalLength += polyline.getTotalLength();
        });

        circles.forEach((circle) => {
          const radius = parseFloat(circle.getAttribute('r'));

          totalLength += 2 * Math.PI * radius;
          // totalArea += Math.PI * radius * radius;
        });

        // Extract all circles
        circles.forEach((elem, index) => {
          let cx = parseFloat(elem.getAttribute('cx'));
          let cy = parseFloat(elem.getAttribute('cy'));
          let r = parseFloat(elem.getAttribute('r'));

          try {
            let circle = new makerjs.paths.Circle([cx, cy], r);
            circle.fill = '#F2F3F8';

            pathsMaker['path' + index] = circle;
          } catch (e) {
            console.error(e);
          }
        });

        let modelWrapper = {
          models: modelsMaker,
          paths: pathsMaker,
        };

        const svgString = makerjs.exporter.toSVG(modelWrapper, {
          useSvgPathOnly: true,
          layerOptions: { fill: true },
        });


        const doc = parser.parseFromString(svgString, 'image/svg+xml');
        const docSvgEl = doc.documentElement; 

        if (docSvgEl.hasAttribute('viewBox')) {
          const viewBoxValues = docSvgEl.getAttribute('viewBox').split(' ');
          const newViewBox = `0 -3 ${parseFloat(viewBoxValues[2]) + 4} ${parseFloat(viewBoxValues[3]) + 4}`;
          docSvgEl.setAttribute('viewBox', newViewBox);
        } else {
          console.error('The SVG element does not have a viewBox attribute.');
        }


        const pathsToFill = doc.querySelectorAll('path');

        pathsToFill.forEach((path) => {
          const d = path.getAttribute('d');
          if (d && (d.startsWith('M') || d.startsWith('m'))) {
            path.setAttribute('fill', fillColor);
          } else {
            console.error('Invalid path data:', d);
          }
        });

        const serializer = new XMLSerializer();
        const updatedSvg = serializer.serializeToString(doc);

        rectangles.forEach((rect) => {
          const rectWidth = parseFloat(rect.getAttribute('width'));
          const rectHeight = parseFloat(rect.getAttribute('height'));

          totalLength = 2 * (rectWidth + rectHeight);
          //   totalArea += rectWidth * rectHeight;
        });

        return { updatedSvg, width, height, totalLength, totalArea };
      }
    });

    const svgResults = await Promise.all(svgPromises);
    const materialObject = materialById(choiceData);

    const price_i = (index, material_id) => {
      if (svgResults[index] && materialObject[material_id]) {
        return priceCalculator({
          area: svgResults[index].totalArea,
          length: svgResults[index].totalLength,
          materialPrice: materialObject[material_id].price,
          cuttingSpeed: materialObject[material_id].cutting_speed,
        });
      }

      return 0;
    };

    const updatedFileList = fileList.map((item, index) => ({
      ...item,
      material: materialObject[item.material],
      svgContent: svgResults[index]?.updatedSvg,
      svgWidth: svgResults[index]?.width,
      svgHeight: svgResults[index]?.height,
      totalLength: svgResults[index]?.totalLength,
      totalArea: svgResults[index]?.totalArea,
      price: price_i(index, item.material),
    }));

    return updatedFileList;
};



function GET_KEYS(structure, key = 'id', type = [ 'FOLDER', 'FILE' ]) {
    
    let extendetARR = [];
    
    if ( FOLDER_KEYS.includes(key) && type.includes('FOLDER') ) {
        extendetARR = [ structure[key] ];
    }

    if ( FILE_KEYS.includes(key) && type.includes('FILE') ) {
        extendetARR.push( ...structure.files.map( file => file[key] ));
    }

    if(structure.children_folders.length > 0) {
        for (const structureItem of structure.children_folders) {
            extendetARR.push(...GET_KEYS(structureItem, key, type))
        }
    }

    return extendetARR;
}

function findFolder(structure, targetID) {

    if(structure.id === targetID) return structure;
    let returnVal;
    for (const structureItem of structure.children_folders) {
        returnVal = findFolder(structureItem, targetID) || returnVal;
    }

    return returnVal;
};

function createFolder(allStructure, targetID) {

    const nextID = Math.max( ...GET_KEYS(allStructure) ) + 1;
    const allNames = GET_KEYS(allStructure, 'name', ['FOLDER'] );
    const allUntitles = allNames
    .filter( name => name.includes('untitle') );

    const indexes = allUntitles
    .map( item => Number(item.slice(-1)) )
    .filter( i => !isNaN(i) );

    let nextUntitle;
    
         if( allUntitles.length !== 0 && indexes.length === 0 ) nextUntitle = '-1';
    else if ( allUntitles.length === 0 )                        nextUntitle = '';
    else                                                        nextUntitle = `-${Math.max(...indexes) + 1}`;

    const newFolder = FOLDER({ id: nextID, parent_id: targetID, nextUntitle  });
    return newFolder;
}

function updateFolder(structure, targetID, key, value) {
    // ALERT this function mutates structure parametr

    const currentFolder = findFolder(structure, targetID);
    currentFolder[key] = value;
}

function deleteFolder(structure, targetID) {
    // ALERT this function mutates structure parametr

    const parentID = findFolder(structure, targetID).parent_id;
    const parent = findFolder(structure, parentID);
    parent.children_folders = parent.children_folders.filter( child => child.id !== targetID );
};

function findFile(structure, targetID) {
    const foundFile = structure.files.find( file => file.id === targetID );
    if( foundFile ) return foundFile;
    let returnVal;
    for (const structureItem of structure.children_folders) {
        returnVal = findFile(structureItem, targetID) || returnVal;
    }

    return returnVal;
}

function findFileGroup(structure, targetIDs, returnVal = []) {
    const foundFile = structure.files.filter( file => targetIDs.includes(file.id) );
    if (foundFile && foundFile.length > 0) returnVal.push(...foundFile);

    for (const structureItem of structure.children_folders) {
        findFileGroup(structureItem, targetIDs, returnVal);
    }
    return returnVal;
}

function createFile(allStructure, targetID, projectRes) {
    const { svg: SVG, dxf: DXF, dxf_original: DXF_ORIGINAL, file_name: name, order_id, product_id, material } = projectRes;

    const nextID = Math.max( ...GET_KEYS(allStructure) ) + 1;
    const newFile = FILE({ id: nextID, parent_id: targetID, product_id, order_id, name, SVG, DXF, DXF_ORIGINAL, material  });

    return newFile;
}

function updateFile(structure, targetID, key, value) {
    // ALERT this function mutates structure parameter 

    const currentFile = findFile(structure, targetID);
    currentFile[key] = value;
}

function reSaveFile(structure, projectRes) {
    // ALERT this function mutates structure parameter 

    const { svg: SVG, dxf: DXF, dxf_original: DXF_ORIGINAL, order_id, product_id, material, id: oldFileID } = projectRes;

    const oldFile  = findFile(structure, oldFileID);
    const parentID = oldFile.parent_id;
    const parent   = findFolder(structure, parentID);
    const newFile  = FILE({ id: oldFileID, parent_id: parentID, product_id, order_id, name: oldFile.name, SVG, DXF, DXF_ORIGINAL, material });

    parent.files = parent.files.filter( child => child.id !== oldFileID );
    parent.files.push(newFile);
}

function deleteFile(structure, removeID) {
    // ALERT this function mutates structure parameter

    const parentID = findFile(structure, removeID).parent_id;
    const parent = findFolder(structure, parentID);
    parent.files = parent.files.filter( child => child.id !== removeID );
};

function deleteFileGroup(structure, removeIDs) {
    // ALERT this function mutates structure parameter

    const files = findFileGroup(structure, removeIDs);
    const parentFolder = files.map( file => findFolder(structure, file.parent_id) )
    parentFolder.forEach( parent => parent.files = parent.files.filter( file => !removeIDs.includes(file.id) ));
};


export  {
    materialById,
    fetchSVGs,
    findFolder,
    createFolder,
    updateFolder,
    deleteFolder,
    GET_KEYS,
    findFile,
    reSaveFile,
    findFileGroup,
    createFile,
    updateFile,
    deleteFile,
    deleteFileGroup
};