import React from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ROUTES } from "../../../common/constants/routes/routes";
import { checkoutActions } from '../../../store/ducks/checkout.duck';
// import { checkStatus } from '../../../crud/checkout.crud';

class CheckStatusPage extends React.Component {
    componentDidMount (){
        const { history,checkstatus } = this.props;

        const data = {
            paymentId: localStorage.getItem("checkId")
        }
        checkstatus(data,history)
    }
    render() {
        const { check_status, isProcessing, history } = this.props;

        if((check_status!=null && check_status.status!=null && check_status.status!=undefined) && (check_status.status == 'paid' || check_status.status == 'pending')){
            history.push({
                pathname: ROUTES.DASHBOARD + "/master",
                status:check_status.status
            })
        }else if((check_status!=null && check_status.status!=null && check_status.status!=undefined) && (check_status.status != 'paid' || check_status.status != 'pending')){
            history.push({
                pathname: localStorage.getItem("checkoutPath"),
                status:check_status.status
            })
        }
        return isProcessing ? (
            <>Loading...</>
          ) : (
            <div>
            </div>
          )

    }
}

CheckStatusPage.propTypes = {
    checkstatus: PropTypes.func.isRequired,
    isProcessing: PropTypes.bool.isRequired,
    check_status: PropTypes.object.isRequired,
};

const mapStateToProps = ({ checkout: { form,isProcessing, form: { check_status } } }) => ({
    ...form,
    check_status,
    isProcessing
    });

const mapDispatchToProps = {
    checkstatus: (data, history) => (checkoutActions.checkStatusRequest({ data, history })),
    };

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CheckStatusPage));

