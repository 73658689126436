import { API_URLS } from '../../common/constants/api/apiConstants';
import initApi from '../../crud/apiConfig/apiConfig';

export function getVatsQuery() {
  return initApi().get(API_URLS.GET_VATS_DATA);
}

export const getVatQuery = id => {
  return initApi().get(API_URLS.GET_VAT_DETAILED(id));
};

export const deleteVatQuery = id => {
  return initApi().delete(API_URLS.GET_VAT_DETAILED(id));
};

export const updateVatQuery = ({ id, data }) => {
  if (id) {
    return initApi().put(API_URLS.GET_VAT_DETAILED(id), data);
  } else {
    return initApi().post(API_URLS.GET_VATS_DATA, data);
  }
};
