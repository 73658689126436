import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";

import { Redirect, withRouter } from "react-router-dom";
import { LayoutSplashScreen } from "../../../_metronic";
import { authActions } from "../../store/ducks/auth.duck";
import { ROUTES } from "../../common/constants/routes/routes";

class Logout extends Component {
  componentDidMount() {
    const { logout, history } = this.props;
    logout();

    history.push(ROUTES.AUTH_LOGIN);
  }

  render() {
    const { hasAuthToken } = this.props;

    return hasAuthToken ? <LayoutSplashScreen /> : <Redirect to="/auth" />;
  }
}

Logout.propTypes = {
  history: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
};

const withRouterLogout = withRouter(Logout);

export default connect(
  ({ auth }) => ({ hasAuthToken: !!auth.authToken }),
  authActions
)(withRouterLogout);
