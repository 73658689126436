import { API_URLS } from '../../common/constants/api/apiConstants';
import initApi from '../../crud/apiConfig/apiConfig';

export function getHourlyRatesQuery() {
  return initApi().get(API_URLS.GET_HOURLY_RATES_DATA);
}

export const getHourlyRateQuery = id => {
  return initApi().get(API_URLS.GET_HOURLY_RATE_DETAILED(id));
};

export const deleteHourlyRateQuery = id => {
  return initApi().delete(API_URLS.GET_HOURLY_RATE_DETAILED(id));
};

export const updateHourlyRateQuery = ({ id, data }) => {
  if (id) {
    return initApi().put(API_URLS.GET_HOURLY_RATE_DETAILED(id), data);
  } else {
    return initApi().post(API_URLS.GET_HOURLY_RATES_DATA, data);
  }
};
