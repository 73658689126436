import React, { useEffect, useState, useRef } from 'react';
// import { useSelector } from 'react-redux';
// import { metronic } from '../../../_metronic';
import { API_URLS } from '../../common/constants/api/apiConstants';
import initApi from '../../crud/apiConfig/apiConfig';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { AreaGraph } from '../../partials/content/Graphs/AreaGraph';
import { VerticalBarGraph } from '../../partials/content/Graphs/VerticalBarGraph';
import { StatsDisplay } from '../../partials/content/Stats/StatsDisplay';
import { TopListDisplay } from '../../partials/content/Stats/TopListDisplay';
import {
  COUNTRY_LIST,
  MATERIAL_CATEGORIES,
} from '../../common/constants/constants';

export default function Dashboard() {
  const [users, setUsers] = useState(0);
  const [uniqueUsers, setUniqueUsers] = useState(0);
  const [quotes, setQuotes] = useState(0);
  const [orders, setOrders] = useState(0);
  const [uploads, setUploads] = useState(0);
  const [productionData, setProductionData] = useState([]);
  const [ordersTotal, setOrdersTotal] = useState(0);
  const [quotesTotal, setQuotesTotal] = useState(0);
  const [uploadsTotal, setUploadsTotal] = useState(0);
  const [uploadsTotalData, setUploadsTotalData] = useState([]);
  const [usersActive, setUsersActive] = useState(0);
  const [ordersTotalMadeActiveUsers, setOrdersTotalMadeActiveUsers] = useState(
    0
  );
  const [orderAmountData, setOrderAmountData] = useState([]);
  const [orderAverageAmountData, setOrderAverageAmountData] = useState([]);
  const [orderCountData, setOrderCountData] = useState([]);
  const [userCountryList, setUserCountryList] = useState([]);
  const [userRegCountryList, setUserRegCountryList] = useState([]);
  const [ordersTotalByCountryList, setOrdersTotalByCountryList] = useState([]);
  const [orderMaterialList, setOrderMaterialList] = useState([]);
  const [orderPriceByCategory, setOrderPriceByCategory] = useState([]);
  const [orderPriceByMilled, setOrderPriceByMilled] = useState([]);
  const [totalProductPrice, setTotalProductPrice] = useState(0);
  const [totalProjectPrice, setTotalProjectPrice] = useState([]);
  const [signupValue, setSignupValue] = useState(0);
  const [purchaseFrequencyData, setPurchaseFrequencyData] = useState([]);
  // const [quoteToOrder, setQuoteToOrder] = useState(0);
  const [lifespan, setLifespan] = useState(0);
  const [orderAverageAmount, setOrderAverageAmount] = useState(0);
  const [customerValue, setCustomerValue] = useState(0);
  const [purchaseFrequency, setPurchaseFrequency] = useState(0);
  const [totalLifespan, setTotalLifespan] = useState(0);
  const [totalDataPrice, setTotalDataPrice] = useState([]);
  const isMounted = useRef(false);

  let dateFrom = new Date();
  dateFrom.setFullYear(dateFrom.getFullYear() - 1);
  const [dateValues, setDateValues] = useState([null, null]);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      getData();
      getProductionData();
    }
  }, [isMounted]);

  const formatMoney = (value) => {
    if (value) {
      value = Number(value).toFixed(2);
    }
    return value
      ? '€ ' +
          value
            .toString()
            .replace('.', ',')
            .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      : '€ 0';
  };

  const formatPercentage = (value) => {
    if (value) {
      value = Number(value).toFixed(2);
    }
    return value
      ? value
          .toString()
          .replace('.', ',')
          .replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '%'
      : '0%';
  };

  const categoryMap = MATERIAL_CATEGORIES.reduce((acc, { id, title }) => {
    acc[id] = title;
    return acc;
  }, {});

  const countryMap = COUNTRY_LIST.reduce((acc, { id, title }) => {
    acc[id] = title;
    return acc;
  }, {});

  const changeKeys = (obj, keyMapping) =>
    Object.entries(obj).reduce((acc, [key, value]) => {
      acc[keyMapping[key] || key] = value;
      return acc;
    }, {});

  const keyMapping = {
    material_total_price: 'label',
    category: 'strong',
  };

  const keyMappingRegUsers = {
    count_of_users: 'label',
    count_of_users_by_order: 'enumerate',
    country: 'strong',
    count_of_orders: 'count',
    orders_total_price: 'price',
  };

  const keyMappingByMilling = {
    projects: 'label',
    product_type: 'strong',
  };

  const keyMappingProjects = {
    orders: 'Orders',
    discounts: 'Discounts',
    shipping_costs: 'Shipping Costs',
    startup_costs: 'Startup Costs',
  };

  const updatedOrderPriceByCategory =
    orderPriceByCategory &&
    orderPriceByCategory.map((order) => {
      const updatedOrder = changeKeys(order, keyMapping);
      updatedOrder.strong = categoryMap[order.category] || order.category;
      updatedOrder.label = formatMoney(updatedOrder.label);

      return updatedOrder;
    });

  let updatedOrderPriceByMilled =
    orderPriceByMilled &&
    orderPriceByMilled.map((order) => {
      const updatedOrder = changeKeys(order, keyMappingByMilling);
      updatedOrder.strong =
        categoryMap[order.product_type] || order.product_type;

      updatedOrder.strong =
        updatedOrder.strong === 'laser cutting' ? ' Lasersnijden' : 'Frezen';
      updatedOrder.label = formatMoney(updatedOrder.label);

      return updatedOrder;
    });

  updatedOrderPriceByMilled?.push({
    strong: 'Total',
    label: formatMoney(totalProductPrice),
  });

  const countryData = {
    Netherlands: {
      total: 0,
      count: 0,
      enumerate: 0,
      price: 0,
      revenue: 0,
      aliases: ['Netherlands', 'The Netherlands', '1'],
      countryCode: '1',
    },
    Germany: {
      total: 0,
      count: 0,
      enumerate: 0,
      price: 0,
      revenue: 0,
      aliases: ['Germany', '2'],
      countryCode: '2',
    },
    Belgium: {
      total: 0,
      count: 0,
      enumerate: 0,
      price: 0,
      revenue: 0,
      aliases: ['Belgium', '3'],
      countryCode: '3',
    },
    // Add more countries as needed
  };

  let combinedData = {};

  userRegCountryList?.forEach((item) => {
    const country = item.country;

    if (!combinedData[country]) {
      combinedData[country] = {
        country,
        count_of_users: 0,
        count_of_users_by_order: 0,
        count_of_orders: 0,
        orders_total_price: 0,
        orders_total_price_by_country: 0,
      };
    }
    combinedData[country].count_of_users = 0;
    combinedData[country].count_of_users_by_order +=
      item.count_of_users_by_order;
    combinedData[country].count_of_orders += item.count_of_orders;
    combinedData[country].orders_total_price += item.orders_total_price
      ? Number(item.orders_total_price)
      : 0;
  });

  userCountryList.forEach((item) => {
    const country = item.country;

    if (!combinedData[country]) {
      combinedData[country] = {
        country,
        count_of_users: 0,
        count_of_users_by_order: 0,
        count_of_orders: 0,
        orders_total_price: 0,
        orders_total_price_by_country: 0,
      };
    }
    combinedData[country].count_of_users += item.users_count;
  });

  ordersTotalByCountryList?.forEach((item) => {
    const country = item.country;

    if (!combinedData[country]) {
      combinedData[country] = {
        country,
        count_of_users: 0,
        count_of_users_by_order: 0,
        count_of_orders: 0,
        orders_total_price: 0,
        orders_total_price_by_country: 0,
      };
    }
    combinedData[country].orders_total_price_by_country += item.orders_total_price;
  });

  let combinedList = [];

  /* eslint-disable no-unused-vars */
  Object.entries(combinedData).forEach(([key, value]) => {
    for (const country in countryData) {
      if (countryData[country].aliases.includes(value.country)) {
        countryData[country].total += value.count_of_users;
        countryData[country].enumerate += value.count_of_users_by_order;
        countryData[country].count += value.count_of_orders;
        countryData[country].price +=
          value.orders_total_price === null
            ? 0
            : Number(value.orders_total_price);
        countryData[country].revenue +=
          value.orders_total_price_by_country === null
            ? 0
            : Number(value.orders_total_price_by_country);
        return;
      }
    }

    combinedList.push(value);
  });

  for (const country in countryData) {
    const { total, enumerate, count, price, revenue, countryCode } = countryData[
      country
    ];

    combinedList.push({
      country: countryCode,
      count_of_users: total || 0,
      count_of_users_by_order: enumerate || 0,
      count_of_orders: count || 0,
      orders_total_price: price || 0,
      orders_total_price_by_country: revenue || 0,
    });
  }

  let updatedUserRegCountryList = combinedList
    ?.map((user) => {
      const updatedUser = changeKeys(user, keyMappingRegUsers);
      updatedUser.strong = countryMap[user.country] || user.country;

      updatedUser.price =
        countryMap[user.orders_total_price] !== null ||
        countryMap[user.orders_total_price] !== undefined
          ? formatMoney(user.orders_total_price)
          : 0;

      updatedUser.revenue =  
        countryMap[user.orders_total_price_by_country] !== null ||
        countryMap[user.orders_total_price_by_country] !== undefined
          ? formatMoney(user.orders_total_price_by_country)
          : 0;

      return updatedUser;
    })
    .sort((a, b) => b.label - a.label);

  updatedUserRegCountryList.unshift({
    strong: '   ',
    label: 'Reg Users Total',
    enumerate: 'New User Order Count',
    count: 'Total Order Count',
    price: 'New Total Revenue',
    revenue: 'Total Revenue',
  });

  const updatedTotalProjectPrice = (data, keyMapping) => {
    return (
      data &&
      Object.entries(data).map(([key, value]) => {
        const strong = keyMapping[key] || key;
        const formattedValue = formatMoney(parseFloat(value));
        return { strong, label: formattedValue };
      })
    );
  };

  const transformTotalProjectData = updatedTotalProjectPrice(
    totalProjectPrice,
    keyMappingProjects
  );

  const getData = async (dateFrom = null, dateTo = null) => {
    let response;
    if (dateFrom && dateTo) {
      response = await initApi().get(API_URLS.GET_DASHBOARD, {
        params: {
          date_from: dateFrom,
          date_to: dateTo,
        },
      });
    } else {
      response = await initApi().get(API_URLS.GET_DASHBOARD);
    }

    if (isMounted.current) {
      setUsers(response.data.users);
      setUniqueUsers(response.data.unique_customers);
      setUserCountryList(response.data.registered_user_countries);
      setUserRegCountryList(response.data.registered_user_countries_and_orders);
      setOrdersTotalByCountryList(response.data?.get_countries_and_orders_total);
      setOrderMaterialList(response.data.order_material_list);
      setOrderPriceByCategory(
        response.data?.total_price_materials?.data_by_category
      );
      setOrderPriceByMilled(
        response.data?.material_price_total_to_be_milled?.projectPriceByMaterial
      );
      setTotalProductPrice(
        response.data?.material_price_total_to_be_milled?.totalProductPrice[0]
          ?.products
      );
      setTotalProjectPrice(
        response.data?.material_price_total_to_be_milled?.totalProjectPrice[0]
      );
      setLifespan(response.data.total_lifecycle);
      setTotalLifespan(response.data.total_lifespan);
      setTotalDataPrice(response.data?.total_price_materials?.total_data);

      let signValue = response.data.orders_total / response.data.users;
      setSignupValue(signValue ? parseFloat(signValue).toFixed(2) : 0);

      setOrders(response.data.orders);
      setQuotes(response.data.quotes);
      setOrdersTotal(response.data.orders_total);
      setQuotesTotal(response.data.quotes_total);
      setUploadsTotal(response.data.uploads_total);
      setUploads(response.data.uploads);
      setUploadsTotalData(response.data.uploads_data_graph);
      setUsersActive(response.data.live_users);
      setOrdersTotalMadeActiveUsers(
        response.data.current_users_projects_price_amount
      );

      // let quoteToOrder = (response.data.orders / response.data.quotes) * 100;
      // setQuoteToOrder((quoteToOrder) ? parseFloat(quoteToOrder).toFixed(2) : 0);

      setOrderAverageAmountData(response.data.order_average_amount_graph);
      setOrderAverageAmount(response.data.order_average_amount);
      setCustomerValue(response.data.customer_value);
      setPurchaseFrequencyData(response.data.purchase_frequency_graph);
      setPurchaseFrequency(response.data.purchase_frequency);
      setOrderAmountData(response.data.order_amount_graph);
      setOrderCountData(response.data.order_count_graph);
    }
  };

  // const {brandColor, dangerColor, successColor, primaryColor} = useSelector(
  //     state => ({
  //         brandColor: metronic.builder.selectors.getConfig(
  //             state,
  //             'colors.state.brand'
  //         ),
  //         dangerColor: metronic.builder.selectors.getConfig(
  //             state,
  //             'colors.state.danger'
  //         ),
  //         successColor: metronic.builder.selectors.getConfig(
  //             state,
  //             'colors.state.success'
  //         ),
  //         primaryColor: metronic.builder.selectors.getConfig(
  //             state,
  //             'colors.state.primary'
  //         )
  //     })
  // );

  const getProductionData = async () => {
    let { data } = await initApi().get(API_URLS.GET_DELIVERY_DAYS);
    let formatRepsonse = data.map((item) => {
      return {
        key: item.id,
        strong: `${item.days} dagen`,
        label: `Huidig ${Number(item.production_time + 1).toFixed(2)} dagen`,
      };
    });
    setProductionData(formatRepsonse);
  };

  const handleChangeDateValues = async (values) => {
    if (values && values[0] && values[1]) {
      const dateFrom = new Date(values[0]);
      const dateTo = new Date(values[1]);

      await setDateValues([dateFrom, new Date(values[1].toISOString())]);

      const formattedDateFrom = formatDateWithTimezoneOffset(dateFrom);
      const formattedDateTo = formatDateWithTimezoneOffset(dateTo);

      getData(formattedDateFrom, formattedDateTo);
    } else {
      await setDateValues([null, null]);
      getData();
    }
  };

  // Function to format date with timezone offset
  const formatDateWithTimezoneOffset = (date) => {
    const timezoneOffset = date.getTimezoneOffset();
    const timezoneOffsetHours = Math.abs(Math.floor(timezoneOffset / 60));
    const timezoneOffsetMinutes = Math.abs(timezoneOffset % 60);
    const timezoneSign = timezoneOffset >= 0 ? '-' : '+';

    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const seconds = ('0' + date.getSeconds()).slice(-2);

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}${timezoneSign}${padNumber(
      timezoneOffsetHours
    )}:${padNumber(timezoneOffsetMinutes)}`;
  };

  // Helper function to pad numbers with leading zeros
  const padNumber = (num) => {
    return num < 10 ? '0' + num : num;
  };

  return (
    <>
      <div className="row">
        <div className="col-12 text-right mb-4">
          <DateRangePicker
            value={dateValues}
            onChange={handleChangeDateValues}
          />
        </div>
      </div>
      <div className="row cutwise-stats">
        <div className="col-12">
          <div className="row">
            <StatsDisplay
              colWidth={3}
              title="Gebruikers"
              className="kt-widget26"
            >
              <div
                className={'flex-grow-1'}
                // eslint-disable-next-line react/no-unknown-property
                align="center"
              >
                <h1>{users}</h1>
                <span className={'text-muted font-weight-bold d-block'}>
                  {usersActive} gebruikers actief
                </span>
                <h1>{formatMoney(ordersTotalMadeActiveUsers)}</h1>
                <span className={'text-muted font-weight-bold d-block'}>
                  Totale bestellingen
                </span>
              </div>
            </StatsDisplay>

            <StatsDisplay
              colWidth={3}
              title="Aanmelding waarde"
              className="kt-widget26"
            >
              <div
                className={'flex-grow-1'}
                // eslint-disable-next-line react/no-unknown-property
                align="center"
              >
                <h1>{formatMoney(signupValue)}</h1>
              </div>
            </StatsDisplay>

            <StatsDisplay
              colWidth={3}
              title={` ${orders} Orders`}
              className="kt-widget26"
            >
              <div
                className={'flex-grow-1'}
                // eslint-disable-next-line react/no-unknown-property
                align="center"
              >
                <h1>{formatMoney(ordersTotal)}</h1>

                <span className={'text-muted font-weight-bold d-block'}>
                  Gem. aankoopwr: €{parseFloat(orderAverageAmount).toFixed(2)}
                </span>
                <span className={'text-muted font-weight-bold d-block'}>
                  Gem. aankoopfr: {purchaseFrequency}
                </span>
                <span className={'text-muted font-weight-bold d-block'}>
                  Gem. klantwr: €{parseFloat(customerValue).toFixed(2)}
                </span>
              </div>
            </StatsDisplay>

            <StatsDisplay
              colWidth={3}
              title={`  ${orders} Totaal Aantal Bestellingen`}
              className="kt-widget26"
            >
              <div
                className={'flex-grow-1'}
                // eslint-disable-next-line react/no-unknown-property
                align="center"
              >
                <h1>{formatMoney(totalDataPrice?.shipping_costs)}</h1>
                <span className={'text-muted font-weight-bold d-block'}>
                  Totale verzendkosten
                </span>
                <br />
                <h1>{formatMoney(totalDataPrice?.startup_costs)}</h1>
                <span className={'text-muted font-weight-bold d-block'}>
                  Totale opstartkosten
                </span>
              </div>
            </StatsDisplay>

            <StatsDisplay
              colWidth={3}
              title="Uploads naar orders"
              className="kt-widget26"
            >
              <div
                className={'flex-grow-1'}
                // eslint-disable-next-line react/no-unknown-property
                align="center"
              >
                <h1>{formatPercentage((orders / uploads) * 100)}</h1>
              </div>
            </StatsDisplay>

            <StatsDisplay
              colWidth={3}
              title={` ${uploads} Uploads`}
              className="kt-widget26"
            >
              <div
                className={'flex-grow-1'}
                // eslint-disable-next-line react/no-unknown-property
                align="center"
              >
                <h1>{formatMoney(uploadsTotal)}</h1>
                <span className={'text-muted font-weight-bold d-block'}>
                  excl. offerte & orders
                </span>
              </div>
            </StatsDisplay>

            <StatsDisplay
              colWidth={3}
              title={` ${quotes} Offertes`}
              className="kt-widget26"
            >
              <div
                className={'flex-grow-1'}
                // eslint-disable-next-line react/no-unknown-property
                align="center"
              >
                <h1>{formatMoney(quotesTotal)}</h1>
                <span className={'text-muted font-weight-bold d-block'}>
                  {' '}
                  aantal offertes
                </span>
              </div>
            </StatsDisplay>

            <StatsDisplay
              colWidth={3}
              title="Customer Lifetime Value"
              className="kt-widget26"
            >
              <div
                className={'flex-grow-1'}
                // eslint-disable-next-line react/no-unknown-property
                align="center"
              >
                <h1>{formatMoney(lifespan)}</h1>
                <span className={'text-muted font-weight-bold d-block'}>
                  Total customer lifetime : {totalLifespan?.toFixed(4)}
                </span>
              </div>
            </StatsDisplay>
            <StatsDisplay
              colWidth={3}
              title="Unieke klanten met bestelling"
              className="kt-widget26"
            >
              <div
                className={'flex-grow-1'}
                // eslint-disable-next-line react/no-unknown-property
                align="center"
              >
                <h1>{uniqueUsers}</h1>
                <span className={'text-muted font-weight-bold d-block'}>
                  {((uniqueUsers / users) * 100).toFixed(2)}% gebruiker naar
                  klant
                </span>
              </div>
            </StatsDisplay>

            <AreaGraph
              colWidth={12}
              dataSet={orderCountData}
              styles={{ area: { fill: '#D80000', stroke: '#D80000' } }}
              xAxis={{ show: true, title: 'Maand' }}
              yAxis={{ show: true, title: 'Orders' }}
              title={`Aantal Orders : ${formatMoney(ordersTotal)}`}
              className="kt-widget26"
              opacity={0.5}
              height={200}
            />

            <VerticalBarGraph
              colWidth={12}
              dataSet={orderAverageAmountData}
              title="Gem. Orderwaarde"
              className="kt-widget26"
              xAxis={{ show: true, title: 'Maand' }}
              yAxis={{ show: true, title: 'Gem.', type: 'money' }}
              opacity={0.5}
              height={200}
            />

            <VerticalBarGraph
              colWidth={12}
              dataSet={orderAmountData}
              title="Totale Orderwaarde"
              className="kt-widget26"
              xAxis={{ show: true, title: 'Maand' }}
              yAxis={{ show: true, title: 'Gem.', type: 'money' }}
              opacity={0.5}
              height={200}
            />

            <AreaGraph
              colWidth={12}
              dataSet={purchaseFrequencyData}
              styles={{ area: { fill: '#D80000', stroke: '#D80000' } }}
              title={`Gem. aankoopfrequentie`}
              className="kt-widget26"
              xAxis={{ show: true, title: 'Maand' }}
              yAxis={{ show: true, title: 'Percentage.' }}
              opacity={0.5}
              height={200}
            />

            <VerticalBarGraph
              colWidth={12}
              dataSet={uploadsTotalData}
              title="Totaal aantal uploads exclusief offertes & orders"
              className="kt-widget26"
              xAxis={{ show: true, title: 'Maand' }}
              yAxis={{ show: true, title: 'Aantal' }}
              opacity={0.5}
              height={200}
            />

            <TopListDisplay
              title="Omzet per Materiaalcategorie"
              listData={updatedOrderPriceByCategory}
            />

            <TopListDisplay
              title="Omzet per bewerkingscategorie"
              listData={updatedOrderPriceByMilled}
            />

            <TopListDisplay
              title="Totale bestelling, inclusief kortingen, verzendkosten en opstartkosten"
              listData={transformTotalProjectData}
            />

            <TopListDisplay title="Productie tijd" listData={productionData} />

            <TopListDisplay
              title="Meest gekochte materialen"
              listData={orderMaterialList}
            />

            <TopListDisplay
              colWidth={6}
              title="landen van geregistreerde gebruikers"
              listData={updatedUserRegCountryList}
            />
          </div>
        </div>
      </div>
    </>
  );
}
