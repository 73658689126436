import React from 'react';
import { Route, Switch } from 'react-router-dom';
import MasterPage from './MasterPage';
import { ROUTES } from '../../../common/constants/routes/routes';

export default function ImportExport() {
  return (
    <Switch>
      <Route exact path={ROUTES.IMPORT_EXPORT} component={MasterPage} />
    </Switch>
  );
}
