import React from 'react';
import { Route, Switch } from 'react-router-dom';
import HDPEMaterialLandingPage from './HDPEMaterialLandingPage';

import { ROUTES } from '../../../common/constants/routes/routes';

export default function MaterialsLP() {
  return (
    <Switch>
      <Route
        exact
        path={`${ROUTES.MATERIALS_360}/material-lp`}
        component={HDPEMaterialLandingPage}
      />
    </Switch>
  );
}
