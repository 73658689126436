import React from 'react';
import { Grid } from '@material-ui/core';

import Header from './Header';
import Footer from './Footer';
import HMPE500MaterialLandingPage from './HMPE500MaterialLandingPage';

const HMPE500MaterialPage = () => {
  return (
    <Grid
      item
      xs={12}
      container
      style={{ fontFamily: '"IBM Plex Sans", sans-serif' }}
      className="basic-page-cutwise"
    >
      <Header />

      <HMPE500MaterialLandingPage />

      <Footer />
    </Grid>
  );
};

export default HMPE500MaterialPage;
