import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import {
  TextField,
  Box,
  Tab,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from '@material-ui/core';
import { TabContext, TabPanel, TabList } from '@material-ui/lab';
import ReactTooltip from 'react-tooltip';
import { FormattedMessage, injectIntl } from 'react-intl';

import {
  API_URL,
  API_URLS,
} from '../../../../../common/constants/api/apiConstants';
import initApi from '../../../../../crud/apiConfig/apiConfig';
import { drawActions } from '../../../../../store/ducks/draw.duck';

import { ReactComponent as OffsetSvg } from '../../../../../../app/styles/components/icons/offset.svg';
import { ReactComponent as AngleSvg } from '../../../../../../app/styles/components/icons/angle.svg';
import { ReactComponent as DownSvg } from '../../../../../../app/styles/components/icons/down_bending.svg';
import { ReactComponent as HorizontalSvg } from '../../../../../../app/styles/components/icons/horizontal_bending.svg';
import { ReactComponent as UpSvg } from '../../../../../../app/styles/components/icons/up_bending.svg';
import { ReactComponent as VerticalSvg } from '../../../../../../app/styles/components/icons/vertical_bending.svg';

class Toolbar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: '',
      engraveType: null,
      gearType: null,
      minBendOffset: null,
      bendOffset: null,
      countOfBending: '1',
      posOfBending: null,
      bendingSite: null,
      bendDegrees: null,
      costOfBending: null,
      isBendable: false,
      errors: {
        bendDegrees: false,
        bendOffset: false,
      },
    };
  }

  componentDidMount() {
    const { selectedTypeMaterial, bending } = this.props;

    this.setState({
      ...this.state,
      posOfBending: bending && (bending.direction || bending[0]?.direction),
      bendDegrees: bending && (bending.degrees || bending[0]?.degrees),
      bendOffset: bending && (bending.offset || bending[0]?.offset),
      bendingSite: bending && (bending.side || bending[0]?.side),
      costOfBending: bending && (bending.cost || bending[0]?.cost),
    });

    this.getIsBendable(selectedTypeMaterial);
  }

  closeDialog = () => {
    this.setState({
      isOpen: '',
      errors: {
        bendDegrees: false,
        bendOffset: false,
      },
      engraveType: null,
      minBendOffset: null,
      countOfBending: '1',
      isBendable: false,
    });
  };

  onChange = (field) => (event) => {
    const value = event.target.value;
    const { errors } = this.state;

    if (field == 'bendDegrees') {
      errors.bendDegrees =
        value !== '' &&
        (isNaN(value) || Number(value) < 10 || Number(value) > 180);
    }

    if (field == 'bendOffset') {
      errors.bendOffset = value < this.state.minBendOffset;
    }

    if (field == 'posOfBending') {
      localStorage.setItem('posOfBending', value);
    }
    this.setState({ [field]: value, errors: errors });
    this.selectBending();
  };

  onBlur = (field) => (event) => {
    const value = event.target.value;
    this.setState({ [field]: value });
  };
  handleTabChange = (event, newValue) => {
    this.setState({ countOfBending: newValue });
  };

  drawGear = () => {
    const { gearType } = this.state;
    const {
      selectElem,
      updateSelectedPaths,
      selectedPaths,
      insertGear,
    } = this.props;

    selectedPaths.map((item) => {
      insertGear({ text: parseInt(gearType) }, item?.id, item?.svgId);
    });

    updateSelectedPaths();
    selectElem(null, null, null);
    this.closeDialog();
  };

  drawGalvanize = () => {
    const { gearType } = this.state;
    const { insertGalvanize, selectedId, id } = this.props;
    insertGalvanize({ text: parseInt(gearType) }, selectedId, id);
    this.closeDialog();
  };

  toggleBend = (event) => {
    const {
      toggleBendElement,
      selectedId,
      selectedTypeMaterial,
      id,
    } = this.props;
    const element = { [event.target.id]: true };

    toggleBendElement(selectedId, id);
    this.getMinOffset(selectedTypeMaterial);
    this.getCostOfBending(selectedTypeMaterial);
    this.setState({
      isOpen: element,
    });
    // this.closeDialog();
  };

  selectBending = () => {
    const { selectBendingElement, id } = this.props;
    const {
      bendOffset,
      posOfBending,
      bendingSite,
      bendDegrees,
      costOfBending,
    } = this.state;
    selectBendingElement(
      posOfBending,
      bendOffset,
      bendingSite,
      bendDegrees,
      costOfBending,
      id
    );
  };

  openToggle = (event) => {
    const { insertedGears, selectedId } = this.props;
    const element = { [event.target.id]: true };

    this.setState({
      isOpen: element,
      gearType: insertedGears[selectedId] && insertedGears[selectedId]['text'],
    });
  };

  deleteElement = () => {
    const {
      selectElem,
      updateSelectedPaths,
      selectedPaths,
      deleteElement,
    } = this.props;

    selectedPaths.map((item) => {
      deleteElement(item?.id, item?.svgId);
    });

    updateSelectedPaths();
    selectElem(null, null, null);
  };

  cutElement = () => {
    const {
      selectElem,
      cutElement,
      updateSelectedPaths,
      selectedPaths,
    } = this.props;

    selectedPaths.map((item) => {
      cutElement(item?.id, item?.svgId);
    });

    updateSelectedPaths();
    selectElem(null, null, null);
    this.selectBending();
    this.setState({ isOpen: '' });
  };

  drawEngravingElement = () => {
    const {
      selectElem,
      insertEngravingElement,
      updateSelectedPaths,
      selectedPaths,
    } = this.props;

    selectedPaths.map((item) => {
      insertEngravingElement(item?.tag, item?.id, item?.svgId);
    });

    updateSelectedPaths();
    selectElem(null, null, null);

    this.setState({ isOpen: '' });
  };

  checkElementState = () => {
    const {
      selectedElem,
      insertedGears,
      insertedGalvanize,
      engravingElement,
      cutElement,
    } = this.props;

    if (Object.keys(cutElement).includes(selectedElem.id)) {
      return 'cut';
    }

    if (Object.keys(insertedGears).includes(selectedElem.id)) {
      return 'threaded';
    }

    if (Object.keys(engravingElement).includes(selectedElem.id)) {
      return 'engraved';
    }

    if (Object.keys(insertedGalvanize).includes(selectedElem.id)) {
      return 'galvanized';
    }
    return 'cut';
  };

  getMinOffset = async (selectedTypeMaterial) => {
    let { data } = await initApi().get(API_URL + API_URLS.GET_MATERIALS_DATA);

    const materialItem = data.find(
      (item) => item.id === selectedTypeMaterial?.id
    );

    this.setState({
      minBendOffset: materialItem && materialItem.min_bend_offset,
    });
  };

  getCostOfBending = async (selectedTypeMaterial) => {
    let { data } = await initApi().get(API_URL + API_URLS.GET_MATERIALS_DATA);

    const materialItem = data.find(
      (item) => item.id === selectedTypeMaterial?.id
    );

    this.setState({
      costOfBending: materialItem && materialItem.bending_price,
    });
  };

  getIsBendable = async (selectedTypeMaterial) => {
    let { data } = await initApi().get(API_URL + API_URLS.GET_MATERIALS_DATA);

    const materialItem = data.find(
      (item) => item.id === selectedTypeMaterial?.id
    );

    this.setState({
      isBendable: materialItem && materialItem.is_bendable,
    });
    localStorage.setItem(
      'isBendable',
      materialItem && materialItem.is_bendable
    );
  };

  modalContentForBending = () => {
    const {
      errors,
      minBendOffset,
      bendOffset,
      posOfBending,
      bendingSite,
      bendDegrees,
    } = this.state;

    const { bending } = this.props;

    return (
      <>
        <FormControl>
          <FormLabel>
            <FormattedMessage id={'Kies de richting van de buiging.'} />
          </FormLabel>
          <RadioGroup
            onChange={this.onChange('posOfBending')}
            value={posOfBending || (bending && bending[0]?.direction)}
          >
            <FormControlLabel
              value="horizontal"
              control={<Radio />}
              label={
                <>
                  <HorizontalSvg />
                  <FormattedMessage id={'Horizontal'} />
                </>
              }
            />
            <FormControlLabel
              value="vertical"
              control={<Radio />}
              label={
                <>
                  <VerticalSvg />
                  <FormattedMessage id={'Vertical'} />
                </>
              }
            />
          </RadioGroup>
        </FormControl>

        <p style={{ display: 'flex', alignItems: 'center', fontSize: 14 }}>
          <span style={{ position: 'relative' }}>
            <OffsetSvg />
            <small
              style={{
                position: 'absolute',
                bottom: '6px',
                left: '18px',
                color: '#ff150f',
              }}
            >
              0
            </small>
          </span>
          <FormattedMessage id={'MATERIAL.BENDING.OFFSET.LABEL'} />
          {minBendOffset} mm:
        </p>
        <TextField
          className="custom-textfield"
          id="min-bend-offset"
          variant="outlined"
          type="number"
          onChange={this.onChange('bendOffset')}
          onBlur={this.onBlur('bendOffset')}
          errors={errors && errors.bendOffset}
          helperText={
            errors && errors.bendOffset
              ? `The minimum bending offset is ${minBendOffset}`
              : ''
          }
          FormHelperTextProps={{ className: 'errorText' }}
          value={bendOffset || (bending && bending[0]?.offset)}
          required
          fullWidth
        />

        <FormControl>
          <FormLabel>
            <FormattedMessage id={'Selecteer richting van de buiging.'} />
          </FormLabel>
          <RadioGroup
            onChange={this.onChange('bendingSite')}
            value={bendingSite || (bending && bending[0]?.side)}
          >
            <FormControlLabel
              value="up"
              control={<Radio />}
              label={
                <>
                  <UpSvg />
                  <FormattedMessage id={'Up'} />
                </>
              }
            />
            <FormControlLabel
              value="down"
              control={<Radio />}
              label={
                <>
                  <DownSvg />
                  <FormattedMessage id={'Down'} />
                </>
              }
            />
          </RadioGroup>
        </FormControl>

        <p
          className="bending-degrees"
          style={{ display: 'flex', alignItems: 'center', fontSize: 14 }}
        >
          <AngleSvg />
          <FormattedMessage id={'Voer graden van de buiging in.'} />
        </p>
        <TextField
          className="custom-textfield"
          id="bend-degrees"
          variant="outlined"
          type="number"
          errors={errors && errors.bendDegrees}
          helperText={
            errors && errors.bendDegrees ? 'Outside of the range' : ''
          }
          FormHelperTextProps={{ className: 'errorText' }}
          onChange={this.onChange('bendDegrees')}
          inputProps={{ min: 10, max: 180 }}
          value={bendDegrees || (bending && bending[0]?.degrees)}
          required
          fullWidth
        />
      </>
    );
  };

  render() {
    const { selectedId, threads, intl } = this.props;

    const { isOpen, gearType, countOfBending, isBendable, errors } = this.state;

    return (
      <>
        <ReactTooltip />

        <div
          className="toolbar"
          data-tip={
            selectedId === null
              ? intl.formatMessage({ id: 'Please select an element first' })
              : ''
          }
        >
          <button
            type="button"
            id="cut"
            data-tip={intl.formatMessage({ id: 'Cutting' })}
            disabled={selectedId === null || this.checkElementState() === 'cut'}
            className={
              this.checkElementState() === 'cut'
                ? 'selected toolbar__button cut'
                : 'toolbar__button cut'
            }
            onClick={this.openToggle}
          >
            <FormattedMessage id={'Undo'} />
          </button>

          <button
            id="bending"
            type="button"
            data-tip={intl.formatMessage({ id: 'Bending' })}
            data-text={intl.formatMessage({ id: 'Bending' })}
            disabled={!isBendable}
            className={
              this.checkElementState() === 'bend'
                ? 'selected toolbar__button bend'
                : 'toolbar__button bend'
            }
            onClick={this.toggleBend}
          >
            <FormattedMessage id={'Add Bending'} />
          </button>

          <button
            type="button"
            data-tip={intl.formatMessage({ id: 'Threading' })}
            id="gear"
            disabled={
              selectedId === null || this.checkElementState() == 'threaded'
            }
            className={
              this.checkElementState() === 'threaded'
                ? 'selected toolbar__button gear'
                : 'toolbar__button gear'
            }
            onClick={this.openToggle}
          >
            <FormattedMessage id={'Add Threading'} />
          </button>

          {/*<button type="button" data-tip={intl.formatMessage({ id: 'Galvanize' })} id="galvanize"*/}
          {/*        disabled={selectedId === null || this.checkElementState() == 'galvanized'}*/}
          {/*        className={ this.checkElementState() == 'galvanized' ? 'selected toolbar__button galvanize' : 'toolbar__button galvanize' }*/}
          {/*        onClick={this.openToggle}></button>*/}

          <button
            type="button"
            data-tip={intl.formatMessage({ id: 'Engraving' })}
            id="edit"
            disabled={
              selectedId === null || this.checkElementState() === 'engraved'
            }
            className={
              this.checkElementState() == 'engraved'
                ? 'selected toolbar__button edit'
                : 'toolbar__button edit'
            }
            onClick={this.openToggle}
          >
            <FormattedMessage id={'Add Engraving'} />
          </button>

          <button
            type="button"
            data-tip={intl.formatMessage({ id: 'Delete element' })}
            disabled={selectedId === null}
            className="toolbar__button delete"
            onClick={this.deleteElement}
          >
            <FormattedMessage id={'Delete Selection'} />
          </button>

          <Modal
            show={isOpen.galvanized}
            onHide={this.closeDialog}
            size="md"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <FormattedMessage id={'Galvanizing'} />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                <FormattedMessage id={'Please select an option'} />
              </p>
              <select onChange={this.onChange('gearType')} value={gearType}>
                <option>{intl.formatMessage({ id: 'Select' })}</option>
                <option key="45" value="45">
                  {' '}
                  45
                </option>
                <option key="90" value="90">
                  {' '}
                  90
                </option>
              </select>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.closeDialog}>
                <FormattedMessage id={'Cancel'} />
              </Button>
              <Button onClick={this.drawGalvanize}>
                <FormattedMessage id={'Select'} />
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal
            show={isOpen.gear}
            onHide={this.closeDialog}
            size="md"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <FormattedMessage id={'Threading'} />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                <FormattedMessage id={'Please select an option'} />
              </p>
              <select onChange={this.onChange('gearType')} value={gearType}>
                <option>{intl.formatMessage({ id: 'Select' })}</option>
                {threads &&
                  threads.map((item) => (
                    <option key={item.id} value={item.id}>
                      {' '}
                      M{item.mm}{' '}
                    </option>
                  ))}
              </select>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.closeDialog}>
                <FormattedMessage id={'Cancel'} />
              </Button>
              <Button onClick={this.drawGear}>
                <FormattedMessage id={'Select'} />
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal
            show={isOpen.edit}
            onHide={this.closeDialog}
            size="md"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <FormattedMessage id={'Engraving'} />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                <FormattedMessage id={'Please select an option'} />
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.closeDialog}>
                <FormattedMessage id={'Cancel'} />
              </Button>
              <Button onClick={this.drawEngravingElement}>
                <FormattedMessage id={'Select'} />
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={isOpen.cut} onHide={this.closeDialog} size="md" centered>
            <Modal.Header closeButton>
              <Modal.Title>
                <FormattedMessage id={'Cutting'} />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                <FormattedMessage
                  id={'Confirm that you would to engrave the cut element'}
                />
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.closeDialog}>
                <FormattedMessage id={'Cancel'} />
              </Button>
              <Button onClick={this.cutElement}>
                <FormattedMessage id={'Select'} />
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal
            show={isOpen.bending}
            onHide={this.closeDialog}
            size="lg"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <FormattedMessage id={'Material Bending'} />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                <FormattedMessage
                  id={'Je kunt maximaal 4 buigingen per onderdeel invoeren.'}
                />
              </p>

              <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={countOfBending}>
                  <Box sx={{ borderBottom: 1 }}>
                    <TabList onChange={this.handleTabChange} aria-label="tabs">
                      <Tab
                        label={
                          <>
                            <FormattedMessage id={'MATERIAL.BENDING'} /> 1:{' '}
                          </>
                        }
                        value="1"
                      />
                      <Tab
                        label={
                          <>
                            <FormattedMessage id={'MATERIAL.BENDING'} /> 2:{' '}
                          </>
                        }
                        value="2"
                        disabled
                      />
                      <Tab
                        label={
                          <>
                            <FormattedMessage id={'MATERIAL.BENDING'} /> 3:{' '}
                          </>
                        }
                        value="3"
                        disabled
                      />
                      <Tab
                        label={
                          <>
                            <FormattedMessage id={'MATERIAL.BENDING'} /> 4:{' '}
                          </>
                        }
                        value="4"
                        disabled
                      />
                    </TabList>
                  </Box>

                  <TabPanel value="1">{this.modalContentForBending()}</TabPanel>
                  <TabPanel value="2">{this.modalContentForBending()}</TabPanel>
                  <TabPanel value="3">{this.modalContentForBending()}</TabPanel>
                  <TabPanel value="4">{this.modalContentForBending()}</TabPanel>
                </TabContext>
              </Box>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.closeDialog}>
                <FormattedMessage id={'Cancel'} />
              </Button>
              <Button
                onClick={this.cutElement}
                disabled={errors?.bendOffset || errors?.bendDegrees}
              >
                <FormattedMessage id={'Select'} />
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </>
    );
  }
}

Toolbar.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  threads: PropTypes.array,
  insertedGears: PropTypes.object,
  insertedGalvanize: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  selectedType: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  selectedId: PropTypes.any,
  insertEngravingElement: PropTypes.func.isRequired,
  deleteElement: PropTypes.func.isRequired,
  toggleBendElement: PropTypes.func.isRequired,
  cutElement: PropTypes.func.isRequired,
  insertGear: PropTypes.func.isRequired,
  insertGalvanize: PropTypes.func.isRequired,
  selectBendingElement: PropTypes.func.isRequired,
  selectedPaths: PropTypes.array,
  updateSelectedPaths: PropTypes.func,
  selectElem: PropTypes.func.isRequired,
};

const mapStateToProps = ({
  draw: {
    currentSvg: {
      selectedMaterial,
      selectedTypeMaterial,
      selectedElem,
      insertedGears,
      insertedGalvanize,
      engravingElement,
      cutElement,
      bending,
      id,
    },
    materialData: { threads },
  },
}) => ({
  selectedMaterial,
  selectedTypeMaterial,
  selectedElem: selectedElem,
  selectedId: selectedElem.id,
  selectedType: selectedElem.nodeName,
  id,
  insertedGears,
  insertedGalvanize,
  engravingElement,
  cutElement,
  threads,
  bending,
});

const mapDispatchToprops = {
  toggleBendElement: (selectedId, id) =>
    drawActions.toggleBendElement({
      selectedId,
      id,
    }),
  insertGear: (item, selectedId, id) =>
    drawActions.insertGear({
      item,
      selectedId,
      id,
    }),
  insertGalvanize: (item, selectedId, id) =>
    drawActions.insertGalvanize({
      item,
      selectedId,
      id,
    }),
  deleteElement: (selectedId, id) => drawActions.deleteElement(selectedId, id),
  cutElement: (selectedId, id) => drawActions.cutElement(selectedId, id),
  insertEngravingElement: (nodeName, selectedId, id) =>
    drawActions.engravingElement({
      nodeName,
      selectedId,
      id,
    }),
  selectBendingElement: (direction, offset, side, degrees, cost, id) =>
    drawActions.selectBendingElement({
      direction,
      offset,
      side,
      degrees,
      cost,
      id,
    }),
  selectElem: (nodeName, index, id) =>
    drawActions.selectElement({
      nodeName,
      index,
      id,
    }),
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToprops)(Toolbar)
);
