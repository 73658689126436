import React from 'react';
import { Route, Switch } from 'react-router-dom';
import MasterPage from './MasterPage';
import FormPage from './FormPage';
import DetailsPage from './DetailsPage';
import { ROUTES } from "../../../common/constants/routes/routes";

export default function Users () {
  return (
    <Switch>
      <Route exact path={ROUTES.USERS} component={MasterPage} />
      <Route exact path={`${ROUTES.USERS}/create`} component={FormPage} />
      <Route exact path={`${ROUTES.USERS}/edit/:id`} component={FormPage} />
      <Route exact path={`${ROUTES.USERS}/details/:id`} component={DetailsPage} />
    </Switch>
  );
}
