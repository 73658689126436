import { call, put, takeEvery } from "redux-saga/effects";

import {
  getMessagesQuery,
  getMessageQuery,
  createMessage,
  updateMessage,
  deleteMessage,
} from "./crud";
import * as actions from "./actions";

function* fetchMessagesSaga({ payload }) {
  try {
    const res = yield call(getMessagesQuery, payload);

    yield put(actions.getMessagesSuccess(res.data));
  } catch (err) {
    yield put(actions.getMessagesError(err));
  }
}

function* fetchNotificationsSaga({ payload }) {
  try {
    console.log(payload, "< payload");
    const res = yield call(getMessagesQuery, payload);

    yield put(actions.getNotificationsSuccess(res.data));
  } catch (err) {
    yield put(actions.getNotificationsError(err));
  }
}

function* fetchMessageSaga({ payload }) {
  try {
    const res = yield call(getMessageQuery, payload);

    yield put(actions.getMessageSuccess(res.data));
  } catch (err) {
    yield put(actions.getMessageError(err));
  }
}

function* createMessageSaga({ payload }) {
  const { cb = () => {} } = payload;
  try {
    const res = yield call(createMessage, payload);

    yield put(actions.setMessageSuccess(res.data));
    yield cb({ status: "success" });
  } catch (err) {
    yield put(actions.setMessageError(err));
    yield cb({ status: "error" });
  }
}

function* updateMessageSaga({ payload }) {
  const { cb = () => {} } = payload;
  try {
    const res = yield call(updateMessage, payload);

    yield put(actions.updateMessageSuccess(res.data));
    yield cb({ status: "success" });
  } catch (err) {
    yield put(actions.updateMessageError(err));
    yield cb({ status: "error" });
  }
}

function* deleteMessageSaga({ payload }) {
  const { cb = () => {}, id } = payload;
  try {
    const res = yield call(deleteMessage, id);

    yield put(actions.deleteMessageSuccess(res.data));
    yield cb({ status: "success" });
  } catch (err) {
    yield put(actions.deleteMessageError(err));
    yield cb({ status: "error" });
  }
}

function* messagesSagas() {
  yield takeEvery(actions.getMessages, fetchMessagesSaga);
  // used for notification icon
  yield takeEvery(actions.getNotifications, fetchNotificationsSaga);
  yield takeEvery(actions.getMessage, fetchMessageSaga);
  yield takeEvery(actions.setMessage, createMessageSaga);
  yield takeEvery(actions.updateMessage, updateMessageSaga);
  yield takeEvery(actions.deleteMessage, deleteMessageSaga);
}

export default messagesSagas;
