import React from 'react';
import { PopupWidget, useCalendlyEventListener } from 'react-calendly';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

const CalendlyPopupComponent = () => {
  useCalendlyEventListener({
    // when the user schedule an event do something.
    onEventScheduled: (e) => console.log(e.data.payload),
  });

  return (
    <div
      className="popup-widget"
      style={{ position: 'absolute', top: '-2px', right: 0 }}
    >
      <PopupWidget
        url="https://calendly.com/cutwise"
        rootElement={document.getElementById('root')}
        text={<CalendarMonthIcon fill="#ff150f" />}
        color="transparent"
        padding="10px"
      />
    </div>
  );
};

export default CalendlyPopupComponent;
