import React from 'react';
import { Route, Switch } from 'react-router-dom';
import MasterPage from './MasterPage';

import FormPage from './FormPage';
import { ROUTES } from "../../../common/constants/routes/routes";

export default function Materials () {
  return (
    <Switch>
      <Route exact path={ROUTES.MATERIALS} component={MasterPage} />
      <Route exact path={`${ROUTES.MATERIALS}/create`} component={FormPage} />
      <Route exact path={`${ROUTES.MATERIALS}/edit/:id`} component={FormPage} />
    </Switch>
  );
}
