import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { getNotifications } from "../../../../store/messages/actions";
import { Button, Spinner } from "react-bootstrap";
import { SFooter } from "../styles";
import clsx from "clsx";

const Footer = ({ isProcessing, pagination }) => {
  const dispatch = useDispatch();
  const [perPage, setPerPage] = useState(5);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isProcessing) {
      setLoading(isProcessing);
    }
    const timeOutId = setTimeout(() => setLoading(false), 1000);

    return () => clearTimeout(timeOutId);
  }, [isProcessing]);

  useEffect(() => {
    dispatch(getNotifications({ params: { perPage } }));
  }, [perPage]);

  return (
    <SFooter>
      <Button
        className={clsx({ disabled: loading || pagination.total <= perPage })}
        onClick={() =>
          !loading && pagination.total >= perPage && setPerPage(perPage + 5)
        }
      >
        Load more{" "}
        {loading && (
          <Spinner
            as="span"
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        )}
      </Button>
    </SFooter>
  );
};

const mapStateToProps = ({ messages: { pagination, isProcessing } }) => ({
  isProcessing,
  pagination,
});

export default connect(mapStateToProps)(Footer);
