import { API_URLS } from '../../common/constants/api/apiConstants';
import initApi from '../../crud/apiConfig/apiConfig';

export function getPriceRulesQuery() {
  return initApi().get(API_URLS.GET_PRICE_RULES_DATA);
}

export const getPriceRuleQuery = id => {
  return initApi().get(API_URLS.GET_PRICE_RULE_DETAILED(id));
};

export const deletePriceRuleQuery = id => {
  return initApi().delete(API_URLS.GET_PRICE_RULE_DETAILED(id));
};

export const updatePriceRuleQuery = ({ id, data }) => {
  if (id) {
    return initApi().put(API_URLS.GET_PRICE_RULE_DETAILED(id), data);
  } else {
    return initApi().post(API_URLS.GET_PRICE_RULES_DATA, data);
  }
};
