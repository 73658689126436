import { call, put, takeEvery } from "redux-saga/effects";

import {
  getHourlyRateQuery,
  updateHourlyRateQuery,
  getHourlyRatesQuery,
  deleteHourlyRateQuery
} from "./crud";
import {
  getHourlyRates,
  getHourlyRatesError,
  getHourlyRatesSuccess,
  updateHourlyRate,
  updateHourlyRateSuccess,
  updateHourlyRateError,
  deleteHourlyRate,
  deleteHourlyRateSuccess,
  deleteHourlyRateError,
  getHourlyRate,
  getHourlyRateError,
  getHourlyRateSuccess
} from "./actions";

function* fetchHourlyRatesSaga({ payload }) {
  try {
    const res = yield call(getHourlyRatesQuery, payload);

    yield put(getHourlyRatesSuccess(res.data));
  } catch (err) {
    yield put(getHourlyRatesError(err));
  }
}

function* fetchHourlyRateSaga({ payload }) {
  try {
    const { successCallBack = () => {} } = payload;

    const res = yield call(getHourlyRateQuery, payload);

    yield put(
      getHourlyRateSuccess({
        ...res.data,
        created_at: res.data.created_at
      })
    );

    yield successCallBack(res.data.type);
  } catch (err) {
    yield put(getHourlyRateError(err.response));
  }
}

function* updateHourlyRateSaga({ payload }) {
  try {
    const { successCallBack = () => {}, id, data } = payload;

    const res = yield call(updateHourlyRateQuery, { id, data });

    yield put(
      updateHourlyRateSuccess({
        ...res.data,
        created_at: res.data.created_at
      })
    );

    yield successCallBack(res.data.type);
  } catch (err) {
    yield put(updateHourlyRateError(err.response));
  }
}

function* deleteHourlyRateSaga({ payload }) {
  try {
    const { successCallBack = () => {}, id } = payload;

    const res = yield call(deleteHourlyRateQuery, id);

    yield put(
      deleteHourlyRateSuccess({
        ...res.data,
        created_at: res.data.created_at
      })
    );

    yield successCallBack(res.data.type);
  } catch (err) {
    yield put(deleteHourlyRateError(err.response));
  }
}

function* hourlyRatesSagas() {
  yield takeEvery(getHourlyRates, fetchHourlyRatesSaga);
  yield takeEvery(getHourlyRate, fetchHourlyRateSaga);
  yield takeEvery(updateHourlyRate, updateHourlyRateSaga);
  yield takeEvery(deleteHourlyRate, deleteHourlyRateSaga);
}

export default hourlyRatesSagas;
