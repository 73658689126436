export const ROUTES = {
  AUTH: '/auth',
  AUTH_LOGIN_LANG: '/auth/login/:lang',
  AUTH_LOGIN: '/auth/login',
  AUTH_REGISTRATION_LANG: '/auth/registration/:lang',
  AUTH_REGISTRATION: '/auth/registration',
  AUTH_FORGOT_PASS: '/auth/forgot-password',
  AUTH_RESET_PASS_WITH_PARAMS: '/handle_reset_password/:email/:token',
  AUTH_RESET_PASS: '/handle_reset_password',
  MATERIALS: '/materials-info',
  MATERIALS_360: '/materials',
  MATERIALS_LP: '/materials-lp',
  LP_ADVERTISING: '/lasersnijden',
  LP_ADVERTISING_NL: '/materialen/lasercutting',
  LP_ADVERTISING_DE: '/laserschneiden',
  LP_ADVERTISING_DK: '/laserskaering',
  CONFIGURATION: '/configurator',
  HDPE_MATERIALS_LP: {
    EN: '/hdpe-lasercutting-milling',
    NL: '/materialen/hdpe',
    DE: '/materialien/hdpe',
  },
  POM_MATERIAL_LP: {
    EN: '/pom-lasercutting-milling',
    NL: '/materialen/pom',
    DE: '/materialien/pom',
  },
  PMMA_XT_MATERIAL_LP: {
    EN: '/pmma-xt-lasercutting-milling',
    NL: '/materialen/pmma-xt',
    DE: '/materialien/pmma-xt',
  },
  PMMA_GS_MATERIAL_LP: {
    EN: '/pmma-gs-lasercutting-milling',
    NL: '/materialen/pmma-gs',
    DE: '/materialien/pmma-gs',
  },
  HMPE_1000_MATERIAL_LP: {
    EN: '/hmpe-1000-lasercutting-milling',
    NL: '/materialen/hmpe-1000',
    DE: '/materialien/hmpe-1000',
  },
  HMPE_500_MATERIAL_LP: {
    EN: '/hmpe-500-lasercutting-milling',
    NL: '/materialen/hmpe-500',
    DE: '/materialien/hmpe-500',
  },
  PC_MATERIAL_LP: {
    EN: '/pc-lasercutting-milling',
    NL: '/materialen/pc',
    DE: '/materialien/pc',
  },
  PETG_MATERIAL_LP: {
    EN: '/petg-lasercutting-milling',
    NL: '/materialen/petg',
    DE: '/materialien/petg',
  },
  PTFE_MATERIAL_LP: {
    EN: '/ptfe-lasercutting-milling',
    NL: '/materialen/ptfe',
    DE: '/materialien/ptfe',
  },
  PETP_MATERIAL_LP: {
    EN: '/petp-lasercutting-milling',
    NL: '/materialen/petp',
    DE: '/materialien/petp',
  },
  PS_MATERIAL_LP: {
    EN: '/ps-lasercutting-milling',
    NL: '/materialen/ps',
    DE: '/materialien/ps',
  },
  PA_MATERIAL_LP: {
    EN: '/pa-lasercutting-milling',
    NL: '/materialen/pa',
    DE: '/materialien/pa',
  },
  PP_MATERIAL_LP: {
    EN: '/pp-lasercutting-milling',
    NL: '/materialen/pp',
    DE: '/materialien/pp',
  },
  PI_MATERIAL_LP: {
    EN: '/pi-lasercutting-milling',
    NL: '/materialen/pi',
    DE: '/materialien/pi',
  },
  PEEK_MATERIAL_LP: {
    EN: '/peek-lasercutting-milling',
    NL: '/materialen/peek',
    DE: '/materialien/peek',
  },
  ABS_MATERIAL_LP: {
    EN: '/abs-lasercutting-milling',
    NL: '/materialen/abs',
    DE: '/materialien/abs',
  },
  HPL_MATERIAL_LP: {
    EN: '/hpl-milling',
    NL: '/materialen/hpl',
    DE: '/materialien/hpl',
  },
  DIBOND_ALUPANEL_MATERIAL_LP: {
    EN: '/dibond-alupanel-milling',
    NL: '/materialen/dibond-alupanel',
    DE: '/materialien/dibond-alupanel',
  },
  EPDM_MATERIAL_LP: {
    EN: '/epdm-cutting',
    NL: '/materialen/epdm-snijden',
    DE: '/materialien/epdm-schneiden',
  },
  NBR_MATERIAL_LP: {
    EN: '/nbr-cutting',
    NL: '/materialen/nbr-snijden',
    DE: '/materialien/nbr-schneiden',
  },
  PUR_MATERIAL_LP: {
    EN: '/pur-cutting',
    NL: '/materialen/pur-snijden',
    DE: '/materialien/pur-schneiden',
  },
  SBR_MATERIAL_LP: {
    EN: '/sbr-cutting',
    NL: '/materialen/sbr-snijden',
    DE: '/materialien/sbr-schneiden',
  },
  SILICONE_MATERIAL_LP: {
    EN: '/sbr-cutting',
    NL: '/materialen/siliconen-snijden',
    DE: '/materialien/siliconen-schneiden',
  },

  ERROR: '/error',
  LOGOUT: '/logout',
  BUILDER: '/builder',
  CONFIGURATIONNEW: '/configurator-new',
  ORDERS: '/orders',
  QOUTES: '/quotes',
  DASHBOARD: '/dashboard',
  VATS: '/vats',
  UPLOADS: '/uploads',
  HOURLY_RATES: '/hourly-rates',
  APP_SETTINGS: '/app-settings',
  IMPORT_EXPORT: '/import-export',
  PRICE_RULES: '/price-rules',
  BENDING: '/bending',
  THREADS: '/threads',
  SHIPPING: '/shipping',
  COUNTRIES: '/countries',
  GOOGLE_MATERIAL: '/google-material',
  REACT_BOOTSTRAP: '/react-bootstrap',
  DOCS: '/docs',
  ERROR_V1: '/error/error-v1',
  CHECKOUT: '/checkout',
  CHECK_STATUS: '/check-status',
  PAGES: '/pages',
  SETTINGS: '/settings',
  USERS: '/users',
  ADMIN_UPDATES: '/admin-updates',
  EXACT: '/exact',
  SKETCHER: '/cutwisecad',
  MYPARTLIBRARY: '/mypartlibrary',
  PARTLIBRARY: '/part-library/:folderId',
  PARTSINLIB: '/parts-in-lib',
};
