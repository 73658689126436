import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import {connect, useDispatch} from "react-redux";
import ThreadTable from '@material-table/core';
import {Button, Modal} from 'react-bootstrap';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import {deleteThread, getThreads} from "../../../store/threads/actions";
import {ROUTES} from "../../../common/constants/routes/routes";
import {useLastLocation} from "react-router-last-location";

function MasterPage(props) {
    const {
        dataThreads,
        pagination,
        isProcessing,
    } = props;
    const lastLocation = useLastLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const pagePath = history.location.pathname;
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [data, setData] = useState([]);
    const [open, setOpen] = useState(false);
    const [deleteId, setDeleteId] = useState();
    const columns = [
        {title: 'Hole', field: 'hole',},
        {title: 'Units', field: 'units'},
        {title: 'mm', field: 'mm'},
    ];

    useEffect(() => {
        dispatch(getThreads());
        let lastVisitedPage = JSON.parse(localStorage.getItem(`${pagePath}`))
        if (lastVisitedPage && (lastLocation == null || lastLocation?.pathname.includes(pagePath))) {
            setCurrentPage(lastVisitedPage)
        }
    }, []);

    useEffect(() => {
        updateData();
    }, [dataThreads]);

    useEffect(() => {
        updateData();
    }, [currentPage, pageSize]);

    const {count} = pagination;

    // const navigateTo = ({id}) => {
    //     history.push(`${ROUTES.THREADS}/details/${id}`);
    // }

    const handleCreate = () => {
        history.push(`${ROUTES.THREADS}/create`);
    }

    const updateData = () => {
        if (dataThreads) {
            setData(dataThreads.slice(currentPage * pageSize, (currentPage + 1) * pageSize));
        }
    };

    const changePage = async (page) => {
        setCurrentPage(page);
        await localStorage.setItem(`${pagePath}`, page);
    };

    const changePerPage = (per_page) => {
        setPageSize(per_page)
    };

    const confirmDeleteItem = (id) => {
        setDeleteId(id);
        setOpen(true);
    };

    const deleteItem = () => {
        dispatch(deleteThread({
            id: deleteId, successCallBack: () => {
                dispatch(getThreads());
            }
        }));
        closeDialog();
    };

    const closeDialog = () => {
        setOpen(false);
        setDeleteId();
    }

    const confirmModal = () => {
        return (<Modal show={open} onHide={closeDialog} size="md" centered>
            <Modal.Header closeButton>
                <Modal.Title>Delete item</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Confirm that you would to delete the selected item.</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={closeDialog}>Cancel</Button>
                <Button onClick={deleteItem}>Delete</Button>
            </Modal.Footer>
        </Modal>)
    }

    return isProcessing ? 'Loading...' : (
        <>
            <div className="row">
                <div className="col-md-12">
                    <div className="kt-section">
                        <div className="kt-section__content">
                            <ThreadTable
                                title="Threads"
                                // onRowClick={((evt, selectedRow) => navigateTo(selectedRow))}
                                columns={columns}
                                data={data}
                                page={currentPage}
                                totalCount={count}
                                onPageChange={changePage}
                                onChangeRowsPerPage={changePerPage}
                                actions={
                                    [
                                        {
                                            title: 'AD',
                                            icon: EditIcon,
                                            tooltip: 'Edit',
                                            onClick: (event, rowData) => history.push(`${ROUTES.THREADS}/edit/${rowData.id}`)
                                        },
                                        {
                                            title: 'AD',
                                            icon: DeleteIcon,
                                            tooltip: 'Delete',
                                            onClick: (event, rowData) => confirmDeleteItem(rowData.id)
                                        },
                                        {
                                            icon: 'add',
                                            tooltip: 'Add',
                                            isFreeAction: true,
                                            onClick: () => handleCreate()
                                        }
                                    ]}
                                options={{
                                    pageSize: pageSize,
                                    actionsColumnIndex: -1,
                                    search: true,
                                    // padding: 'dense',
                                    headerStyle: {
                                        color: '#D80000',
                                        fontSize: 13
                                    },
                                    body: {
                                        fontSize: 13
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {confirmModal()}
        </>
    );
}

MasterPage.propTypes = {
    dataThreads: PropTypes.array,
    isProcessing: PropTypes.bool.isRequired,
};

const mapStateToProps = ({threads: {data, pagination, isProcessing}}) => ({
    dataThreads: data,
    pagination,
    isProcessing,
});

export default connect(mapStateToProps)(MasterPage);
