import { API_URLS } from '../common/constants/api/apiConstants';
import initApi from './apiConfig/apiConfig';

const defaultOptions = {
  params: {
    include:
      'project.products,shipping_address.country,billing_address.country,payment_status,user',
  },
};
const quoteFullDataOptions = {
  params: {
    include:
      'project.products.material,project.products.bendings,shipping_address.country,billing_address.country,payment_status,user,location',
  },
};

export function ordersDataRequest(params = {}) {
  const options = {
    params: {
      ...defaultOptions.params,
      ...params,
    },
  };

  return initApi().get(API_URLS.GET_ORDERS_DATA, options);
  // return initApi().get(API_URLS.GET_QUOTES_DATA, options);
}

export function quotesDataRequest(params = {}) {
  const options = {
    params: {
      ...defaultOptions.params,
      ...params,
    },
  };

  return initApi().get(API_URLS.GET_QUOTES_DATA, options);
}

export const getDetailedOrder = (id) => {
  return initApi().get(API_URLS.GET_ORDER_DETAILED(id), {
    params: {
      include:
        'project.products.material,project.products.bendings,shipping_address.country,billing_address.country,user,location',
    },
  });
};

export const getDetailedQuote = (id) => {
  return initApi().get(API_URLS.GET_ORDER_DETAILED(id), quoteFullDataOptions);
};

export const deleteOrder = (id) => {
  return initApi().delete(API_URLS.DELETE_ORDER(id));
};
export const getOrderTypes = () => initApi().get(API_URLS.GET_ORDER_TYPES);

export const changeOrderType = (id, type) =>
  initApi().post(API_URLS.CHANGE_ORDER_TYPE(id), { type });
