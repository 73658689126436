import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Select from 'react-select';
import { BiInfoCircle } from 'react-icons/bi';
import { FormattedMessage, injectIntl } from 'react-intl';

// import { drawActions } from '../../../../store/ducks/draw.duck';

import CustomToolTip from '../../../../components/CustomTooltip';

// import initApi from '../../../../crud/apiConfig/apiConfig';
// import {
//   API_URL,
//   API_URLS,
// } from '../../../../common/constants/api/apiConstants';
import { MATERIAL_CATEGORIES } from '../../../../common/constants/constants';

const optionStyle = {
  menuPortal: (base) => ({
    ...base,
    zIndex: 9999,
  }),
  control: (base) => ({
    ...base,
    border: '1px solid #e2e5ec',
    borderRadius: '10px',
  }),
};

const MaterialSelector = ({
  intl,
  svgWidth,
  svgHeight,
  materialData,
  // materialDataRequest,
  choiceData,
  onChoiceSelect,
}) => {

  const [materialOptions, setMaterialOptions] = useState([]);
  const [materialOption, setMaterialOption] = useState(null);
  const [choiceOption, setChoiceOption] = useState(null);
  // const [materialChoicesData, setMaterialChoicesData] = useState([]);

  // useEffect(() => {
  //   // fetchData();
  //   materialDataRequest();
  // }, []);

  // useEffect(() => {
  //   fetchData();
  // }, [materialOption]);

  useEffect(() => {
    onChoiceSelect(choiceOption);
  }, [choiceOption]);

  useEffect(() => {
    const materialOptions = [];
    let foundApplicable = false;

    MATERIAL_CATEGORIES.map((optGroup) => {
      let optList = {
        label: intl.formatMessage({ id: optGroup.title }),
        options: [],
      };

      if (materialData && materialData.materials) {
        materialData.materials.forEach((item) => {
          if (item.category == optGroup.id) {
            for (let choice of item.choices) {
              let measurements = choice.measurement.split('x');
              let width = measurements[0];
              let height = measurements[1];
              if (
                (svgWidth <= width && svgHeight <= height) ||
                (svgWidth <= height && svgHeight <= width)
              ) {
                foundApplicable = true;
              }
            }

            optList.options.push({
              key: item.name,
              label: (
                <div
                  style={{
                    textDecoration: !foundApplicable ? 'line-through' : 'unset',
                  }}
                >
                  {!foundApplicable && (
                    <CustomToolTip
                      id="applicable"
                      effect="solid"
                      position="top"
                      tooltipText={
                        <FormattedMessage
                          id={'CONFIGURATOR.IS_NOT_APPLICABLE'}
                        />
                      }
                    >
                      <button
                        data-tip
                        data-for="applicable"
                        style={{ border: 'none', background: 'transparent' }}
                      >
                        <BiInfoCircle
                          size={18}
                          cursor={'pointer'}
                          color={'#b6b6b6'}
                        />
                      </button>
                    </CustomToolTip>
                  )}

                  {getMaterialName(item)}
                </div>
              ),
              value: item.name,
              isDisabled: !foundApplicable ? true : false,
            });
          }
        });
      }

      materialOptions.push(optList);
    });

    setMaterialOptions(materialOptions);
  }, [choiceData, materialData, intl, svgWidth, svgHeight]);

  // const fetchData = async () => {
  //   try {
  //     const { data: globDataResponse } = await initApi().get(
  //       API_URL + API_URLS.GET_MATERIALS_DATA
  //     );
  //     const { data: choicesData } = await initApi().get(
  //       API_URL + API_URLS.MATERIAL_DATA
  //     );

  //     setMaterialChoicesData(adoptMaterial(choicesData, globDataResponse));
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   }
  // };

  const getMaterialName = (item) => {
    let material_name = item.name;
    if (intl.locale === 'en') {
      material_name = item.name_en ? item.name_en : material_name;
    }
    if (intl.locale === 'de') {
      material_name = item.name_de ? item.name_de : material_name;
    }
    return material_name;
  };

  // const adoptMaterial = function(materialData, globData) {
  //   const adoptedGlobData = {};
  //   globData.forEach(
  //     (item) =>
  //       (adoptedGlobData[item.id] = {
  //         price: Number(item.price),
  //         cutting_speed: item.cutting_speed,
  //       })
  //   );

  //   const adopted = {};

  //   materialData &&
  //     materialData.materials.forEach(
  //       (item) =>
  //         (adopted[item.name] = {
  //           ...item,
  //           choices: item.choices.map((choice) => ({
  //             ...choice,
  //             key: choice.id,
  //             price: adoptedGlobData[choice.id].price,
  //             cutting_speed: adoptedGlobData[choice.id].cutting_speed,
  //             value: `${choice.thickness}  ${choice.id}`,
  //             label: (
  //               <div style={{ display: 'flex' }}>
  //                 <span
  //                   dangerouslySetInnerHTML={{ __html: choice.icon }}
  //                   style={{ marginLeft: '5px' }}
  //                 ></span>
  //                 <span>{choice.thickness + ` mm ${choice.color}`}</span>
  //               </div>
  //             ),
  //           })),
  //         })
  //     );

  //   return adopted;
  // };

  return (
    <div id="material-bar" style={{ display: 'flex', paddingBottom: '10px' }}>
      <div style={{ zIndex: 4, color: '#000', padding: 0, width: '200px' }}>
        <Select
          placeholder={intl.formatMessage({ id: 'MATERIAL' })}
          onChange={(event) => setMaterialOption(event)}
          options={materialOptions}
          isOptionDisabled={(option) => option.isDisabled}
          styles={optionStyle}
          value={materialOption}
        />
      </div>

      <div
        style={{ zIndex: 4, color: '#000', width: '200px', margin: '0 10px' }}
      >
        <Select
          placeholder={intl.formatMessage({ id: 'THICKNESS_AND_COLOR' })}
          onChange={(event) => setChoiceOption(event)}
          options={
            (choiceData &&
              choiceData[materialOption?.value] &&
              choiceData[materialOption?.value].choices) ||
            []
          }
          isOptionDisabled={(option) => option.isDisabled}
          styles={optionStyle}
          value={choiceOption}
        />
      </div>
    </div>
  );
};

MaterialSelector.propTypes = {
  user: PropTypes.object,
  materialData: PropTypes.object.isRequired,
  // materialDataRequest: PropTypes.func.isRequired,
};

const mapStateToProps = ({ i18n, auth: { user }, draw: { materialData } }) => ({
  materialData,
  lang: i18n.lang,
  user,
});

const mapDispatchToProps = {
  // materialDataRequest: () => drawActions.materialDataRequest(),
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(MaterialSelector))
);
