const initialState = {
  isProcessing: false,
  data: [], /* response.orders = [{ project: ... }] */
  error: '',
  selectedQuoteDetails: {
    isProccessing: false,
    data: null,
    error: '',
  },
  pagination: {
    per_page: 10,
    current_page: 1,
    count: 0,
  },
};

export const QUOTES_ACTIONS = {
  GET_QUOTES: 'GET_QUOTES_REQUEST',
  GET_QUOTES_SUCCESS: 'GET_QUOTES_SUCCESS',
  GET_QUOTES_ERROR: 'GET_QUOTES_ERROR',
  GET_QUOTES_DETAILED_REQUEST: 'GET_QUOTES_DETAILED_REQUEST',
  GET_QUOTES_DETAILED_SUCCESS: 'GET_QUOTES_DETAILED_SUCCESS',
  GET_QUOTES_DETAILED_ERROR: 'GET_QUOTES_DETAILED_ERROR',
};

export const qoutesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case QUOTES_ACTIONS.GET_QUOTES:
      return {
        ...state,
        isProcessing: true,
      };

    case QUOTES_ACTIONS.GET_QUOTES_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        data: payload.quotes,
        pagination: payload.meta.pagination,

      };

    case QUOTES_ACTIONS.GET_QUOTES_ERROR:
      return {
        ...state,
        isProcessing: false,
        error: payload,
      };

    case QUOTES_ACTIONS.GET_QUOTES_DETAILED_REQUEST:
      return {
        ...state,
        selectedQuoteDetails: {
          isProcessing: true,
        }
      };

    case QUOTES_ACTIONS.GET_QUOTES_DETAILED_SUCCESS:
      return {
        ...state,
        selectedQuoteDetails: {
          isProcessing: false,
          data: payload,
        },
      };

    case QUOTES_ACTIONS.GET_QUOTES_DETAILED_ERROR:
      return {
        ...state,
        selectedQuoteDetails: {
          isProcessing: false,
          error: payload,
        },
      };

    default:
      return state;
  }
};


export const quotesActions = {
  getQuotesRequest: (payload) => ({ type: QUOTES_ACTIONS.GET_QUOTES, payload }),
  getQuotesSuccess: (data) => ({ type: QUOTES_ACTIONS.GET_QUOTES_SUCCESS, payload: data }),
  getQuotesError: (data) => ({ type: QUOTES_ACTIONS.GET_QUOTES_ERROR, payload: data }),
  getQuoteDetailedRequest: (data) => (
    { type: QUOTES_ACTIONS.GET_QUOTES_DETAILED_REQUEST, payload: data }
  ),
  getQuoteDetailedSuccess: (payload) => (
    { type: QUOTES_ACTIONS.GET_QUOTES_DETAILED_SUCCESS, payload }
  ),
  getQuoteDetailedError: (data) => (
    { type: QUOTES_ACTIONS.GET_QUOTES_DETAILED_ERROR, payload: data }
  ),
};
