import { call, put, takeEvery } from "redux-saga/effects";

import {
  getPriceRuleQuery,
  updatePriceRuleQuery,
  getPriceRulesQuery,
  deletePriceRuleQuery
} from "./crud";
import {
  getPriceRules,
  getPriceRulesError,
  getPriceRulesSuccess,
  updatePriceRule,
  updatePriceRuleSuccess,
  updatePriceRuleError,
  deletePriceRule,
  deletePriceRuleSuccess,
  deletePriceRuleError,
  getPriceRule,
  getPriceRuleError,
  getPriceRuleSuccess
} from "./actions";

function* fetchPriceRulesSaga({ payload }) {
  try {
    const res = yield call(getPriceRulesQuery, payload);

    yield put(getPriceRulesSuccess(res.data));
  } catch (err) {
    yield put(getPriceRulesError(err));
  }
}

function* fetchPriceRuleSaga({ payload }) {
  try {
    const { successCallBack = () => {} } = payload;

    const res = yield call(getPriceRuleQuery, payload);

    yield put(
      getPriceRuleSuccess({
        ...res.data,
        created_at: res.data.created_at
      })
    );

    yield successCallBack(res.data.type);
  } catch (err) {
    yield put(getPriceRuleError(err.response));
  }
}

function* updatePriceRuleSaga({ payload }) {
  try {
    const { successCallBack = () => {}, id, data } = payload;

    const res = yield call(updatePriceRuleQuery, { id, data });

    yield put(
      updatePriceRuleSuccess({
        ...res.data,
        created_at: res.data.created_at
      })
    );

    yield successCallBack(res.data.type);
  } catch (err) {
    yield put(updatePriceRuleError(err.response));
  }
}

function* deletePriceRuleSaga({ payload }) {
  try {
    const { successCallBack = () => {}, id } = payload;

    const res = yield call(deletePriceRuleQuery, id);

    yield put(
      deletePriceRuleSuccess({
        ...res.data,
        created_at: res.data.created_at
      })
    );

    yield successCallBack(res.data.type);
  } catch (err) {
    yield put(deletePriceRuleError(err.response));
  }
}

function* priceRulesSagas() {
  yield takeEvery(getPriceRules, fetchPriceRulesSaga);
  yield takeEvery(getPriceRule, fetchPriceRuleSaga);
  yield takeEvery(updatePriceRule, updatePriceRuleSaga);
  yield takeEvery(deletePriceRule, deletePriceRuleSaga);
}

export default priceRulesSagas;
