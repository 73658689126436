import React, { useState } from 'react';
import { Tab, Tabs } from '@material-ui/core';
import {
  Portlet,
  PortletHeader,
  PortletHeaderToolbar,
} from '../../../partials/content/Portlet';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Import, Export, History } from './Tabs';
import './styles.css';

function MasterPage() {
  const intl = useIntl();
  const history = useHistory();
  const pagePath = history.location.pathname;
  const activeTab = JSON.parse(localStorage.getItem(`${pagePath}`));
  const [tab, setTab] = useState(activeTab ? +activeTab.nextTab : 0);

  return (
    <>
      <div className="kt-form kt-form--label-right">
        <Portlet>
          <PortletHeader
            toolbar={
              <PortletHeaderToolbar>
                <Tabs
                  component="div"
                  className="builder-tabs"
                  value={tab}
                  onChange={(_, nextTab) => {
                    setTab(nextTab);
                    localStorage.setItem(
                      `${pagePath}`,
                      JSON.stringify({ nextTab })
                    );
                  }}
                >
                  <Tab label={intl.formatMessage({ id: 'IMPORT' })} />
                  <Tab label={intl.formatMessage({ id: 'EXPORT' })} />
                  <Tab label={intl.formatMessage({ id: 'HISTORY' })} />
                </Tabs>
              </PortletHeaderToolbar>
            }
          />

          {tab === 0 && <Import />}

          {tab === 1 && <Export />}

          {tab === 2 && <History />}
        </Portlet>
      </div>
    </>
  );
}

export default MasterPage;
