import React from 'react';
import LanguageSelector from '../../../app/partials/layout/LanguageSelector';
import UserProfile from '../../../app/partials/layout/UserProfile';
import UserProfileMobile from '../../../app/partials/layout/UserProfileMobile';

import CalendlyPopupComponent from '../../../app/partials/layout/Calendly';
import Notifications from '../../../app/partials/layout/Notifications/Notifications';

export default class Topbar extends React.Component {
  render() {
    return (
      <div className="kt-header__topbar" style={{ paddingRight: '45px' }}>
        <div className={'hide-mobile'}>
          <Notifications />
          <LanguageSelector iconType="" />
          <UserProfile showAvatar={false} showHi={true} showBadge={false} />
          <CalendlyPopupComponent />
        </div>
        <div className={'hide-desktop'}>
          <UserProfileMobile />
        </div>
      </div>
    );
  }
}
