export const FOLDER = ({ id = 1, parent_id = null, nextUntitle: nextIndex }) => ({
    id,
    name: id === 1 ? 'Main' : `untitle${nextIndex}`,
    type: 'FOLDER',
    files: [],
    parent_id,
    children_folders: [],
});

export const FOLDER_KEYS = [
    'id',
    'name',
    'type',
    'files',
    'parent_id',
    'children_folders' 
];

export const FILE = ({ id, parent_id = 1, product_id, order_id, name, SVG, DXF, DXF_ORIGINAL, STEP = null, material = null }) => ({
    id,
    parent_id,
    product_id,
    order_id,
    name,
    type: 'FILE',
    SVG,
    DXF,
    DXF_ORIGINAL,
    material,
    STEP,
});

export const FILE_KEYS = [
    'id',
    'parent_id',
    'order_id',
    'name',
    'type',
    'material',
    'SVG',
    'DXF',
    'DXF_ORIGINAL',
    'STEP',
];

export const RESAVE = 'cad_resave';
export const ORDER_TYPE_LIB = 'part_lib';
export const ORDER_TYPE_SIMPLE = 'part_simple';