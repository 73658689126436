import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import paperjs from 'paper';
import { drawActions } from './../../../../store/ducks/draw.duck';
import Toolbar from '../Toolbar/Toolbar';
import { SELECTED_ELEM } from './../../../../common/constants/propTypes/propTypes';
import { ELEMENTS } from './../../../../common/constants/elements/elements';
import Chainer from '../Utils/Chainer';
import Leveler from '../Utils/Leveler';
import Converter from '../Utils/Converter';
import axios from 'axios';

class Draw extends React.Component {
  state = {
    loading: '',
    svgContent: null,
    selectedFile: undefined,
    strokeWidth: 0.5,
    transform: null,
    paths: [],
    viewBox: null,
  };

  constructor(props) {
    super(props);
    this.div = React.createRef();
  }

  componentDidMount() {
    const { materialDataRequest } = this.props;

    materialDataRequest();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.svg !== this.props.svg) {
      this.parseSvg();
    }

    // const {parsedSVG} = this.props;

    if (
      prevProps.deleteElement !== this.props.deleteElement ||
      prevProps.insertedGears !== this.props.insertedGears ||
      prevProps.insertedGalvanize !== this.props.insertedGalvanize ||
      prevProps.cutElement !== this.props.cutElement ||
      prevProps.engravingElement !== this.props.engravingElement ||
      prevProps.bendElements !== this.props.bendElements ||
      prevProps.materialData !== this.props.materialData ||
      prevProps.selectedMaterial !== this.props.selectedMaterial ||
      prevProps.selectedTypeMaterial !== this.props.selectedTypeMaterial
    ) {
      // this.updatePreview();
    }
  }

  onFileChange = async (event) => {
    this.setState({ loading: 'Uploaden...' });
    await this.setState({ selectedFile: event.target.files[0] });

    const fileReader = await new FileReader();

    let type = 'dxf';
    type = this.state.selectedFile.name.toLowerCase().includes('.stp')
      ? 'step'
      : type;
    type = this.state.selectedFile.name.toLowerCase().includes('.svg')
      ? 'svg'
      : type;

    fileReader.readAsText(this.state.selectedFile);

    await this.setState({ loading: 'Conversie ' + type + ' naar svg...' });

    fileReader.onload = async (e) => {
      const fileContents = e.target.result;

      try {
        let svgFromConverter = fileContents;

        if (type != 'svg') {
          let result = await axios.post(
            'https://cutwise-api.liveserver.nl/api/to-svg?type=' + type,
            fileContents
          );
          await this.setState({ loading: 'SVG configuratie...' });
          svgFromConverter = result.data;
        }

        this.convertSvg(svgFromConverter);
      } catch (e) {
        alert('Error' + e);
      }
    };
  };

  convertSvg = async (svgContent) => {
    //@ts-ignore
    paperjs.setup([640, 480]);

    const converter = new Converter();
    const { paths, circles, viewBox, transform } = converter.getSvgElements(
      svgContent
    );
    console.log(paths, circles);
    const models = await converter.convertPathsToModels(paths, circles);
    const chainer = new Chainer(models);
    const newPaths = chainer.writePaths();

    let newCircles = [];
    let circleID = 0;
    //@ts-ignore
    for (let circle of circles) {
      if (!newCircles.includes(circle)) {
        let cx = circle.getAttribute('cx');
        let cy = circle.getAttribute('cy');
        let r = circle.getAttribute('r');

        let pathFromCircle = this.circleToPath(cx, cy, r);

        newPaths.push({
          d: pathFromCircle,
          id: circleID + 'circle',
          radius: r,
        });
        circleID++;
      }
    }
    // newPaths.sort((a, b) => {
    //     let paperPath1 = new paperjs.Path(a.d);
    //     let paperPath2 = new paperjs.Path(b.d);
    //     return Math.abs(paperPath1.area) <= Math.abs(paperPath2.area) ? 1 : -1;
    // });
    console.log('NP', newPaths);
    this.setState({
      paths: newPaths,
      viewBox: viewBox,
      transform: transform,
    });
    // this.writeSvg(newPaths, newCircles, width, height, viewBox, transform);
  };

  circleToPath = (cx, cy, r) => {
    cx = parseFloat(cx);
    cy = parseFloat(cy);
    r = parseFloat(r);

    return (
      'M' +
      (cx - r) +
      ' ' +
      cy +
      'A' +
      r +
      ' ' +
      r +
      ' 0 1 0 ' +
      (cx + r) +
      ' ' +
      cy +
      'A' +
      r +
      ' ' +
      r +
      ' 0 1 0 ' +
      (cx - r) +
      ' ' +
      cy +
      'Z'
    );
  };

  writeSvg = (paths, circles, width, height, viewBox, transform) => {
    const { updateSelectedSVG } = this.props;

    let preview =
      '<?xml version="1.0" encoding="UTF-8"?><svg  viewBox="' +
      viewBox +
      '"  version="1.1" xmlns="http://www.w3.org/2000/svg">';
    preview +=
      '<g id="importedPart" ' +
      (transform && 'transform="' + transform + '"') +
      ' >';

    // let leveler = new Leveler();
    // let insidePaths = leveler.levelPaths(paths);

    // let mergedPaths = "";
    for (let path of paths) {
      // mergedPaths += path.d;
      // let isInside = insidePaths.find(insidePath => path.id == insidePath.id && insidePath.isInside );
      // console.log(isInside)
      preview +=
        '<path class="imported-path" clip-rule="evenodd" d=\'' +
        path.d +
        "' style='" +
        +"'  ></path>";
    }

    preview += '</g>';
    preview += '</svg>';

    this.setState({ paths: paths, viewBox: viewBox, transform: transform });
    let dxf = '';
    let engravingArea = 0;
    updateSelectedSVG({ preview, dxf, engravingArea });
  };

  onClick = (tag, index, id) => {
    const { selectElem, selectedElem } = this.props;
    if (selectedElem.id !== id) {
      selectElem(tag, index, id);
    }
  };

  parseSvg = () => {
    const { svg } = this.props;

    if (!svg) return;

    this.convertSvg(svg);
  };

  renderSvg = () => {
    const {
      selectedElem,
      insertedGears,
      cutElement,
      engravingElement,
      materialData,
    } = this.props;

    const { paths, viewBox, transform } = this.state;

    let leveler = new Leveler();
    let insidePaths = leveler.levelPaths(paths);
    console.log('paths', paths);
    console.log('inside paths', insidePaths);

    return (
      <>
        <div className="measurements">
          {viewBox?.width?.toFixed(1)} x {viewBox?.height?.toFixed(1)} mm
        </div>
        <div className={'svg-canvas'}>
          <svg
            viewBox={viewBox}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            className={'imported-svg'}
          >
            <g
              transform={transform}
              id="importedPart"
              className={'imported-group'}
            >
              {paths &&
                paths.map((path, i) => {
                  const gearEl = insertedGears[`path-child-${i}`];
                  // const galvanizeEl = insertedGalvanize[`path-child-${i}`];
                  const threads = materialData && materialData.threads;
                  const thread =
                    gearEl && threads
                      ? threads.find((item) => item.id === gearEl.text)
                      : null;

                  let fillColor = '#F2F3F8';
                  let fillElement = '#ffe0e0';

                  if (thread) {
                    fillElement = '#3858ff';
                  }

                  if (engravingElement['path-child-' + i]) {
                    fillElement = '#ffe0e0';
                  }

                  // let isInside = false;
                  for (let insidePath of insidePaths) {
                    if (
                      insidePath.id == path.id &&
                      insidePath.isInside == true
                    ) {
                      fillElement = fillColor;
                    }
                  }

                  return (
                    <path
                      d={path.d}
                      key={`path-child-${i}`}
                      fill={fillElement}
                      className={`imported-path ${
                        selectedElem.id === 'path-child-' + i ? 'active' : ''
                      } ${cutElement['path-child-' + i] ? 'cut-element' : ''}`}
                      id={`path-child-${i}`}
                      onClick={() =>
                        this.onClick(ELEMENTS.PATH, i, `path-child-${i}`)
                      }
                    ></path>
                  );
                })}
            </g>
          </svg>
          <div dangerouslySetInnerHTML={{ __html: this.state.svgContent }} />
        </div>
        <canvas id="paperCanvas"></canvas>
      </>
    );
  };

  render() {
    const { svg, id } = this.props;

    return (
      <div className="draw">
        {svg && <div className="draw draw__toolbar">{id && <Toolbar />}</div>}

        <div className="draw draw__content" id="drawBox">
          {svg && this.renderSvg()}
        </div>

        <canvas id="paper-canvas" data-paper-resize />
      </div>
    );
  }
}

Draw.propTypes = {
  // svg: PropTypes.string,
  selectedElem: SELECTED_ELEM,
  // insertedGears: INSERTED_GEARS,
  parsedSVG: PropTypes.object,
  viewBoxWidth: PropTypes.string,
  viewBoxHeight: PropTypes.string,
  cutElement: PropTypes.object,
  engravingElement: PropTypes.object,
  deleteElement: PropTypes.object,
  bendElements: PropTypes.object,
  materialDataRequest: PropTypes.func.isRequired,
  selectElem: PropTypes.func.isRequired,
  updateSelectedSVG: PropTypes.func.isRequired,
  updateDrawSVG: PropTypes.func.isRequired,
  materialData: PropTypes.object,
};

const mapStateToProps = ({
  draw: { currentSvg, selectedMaterial, uploadedSvgs, materialData },
}) => ({
  ...currentSvg,
  selectedMaterial,
  materialData,
  uploadedSvgs,
});

const mapDispatchToProps = {
  updateDrawSVG: (data) => drawActions.updateDrawSVG(data),
  updateSelectedSVG: (data) => drawActions.updateSelectedSVG(data),
  selectElem: (nodeName, index, id) =>
    drawActions.selectElement({
      nodeName,
      index,
      id,
    }),
  materialDataRequest: () => drawActions.materialDataRequest(),
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Draw));
