import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import MaterialTable from '@material-table/core';
import { Button, Modal } from 'react-bootstrap';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { deleteMaterial, getMaterials } from '../../../store/materials/actions';
import { ROUTES } from '../../../common/constants/routes/routes';

function MasterPage(props) {
  const { dataMaterials, pagination, isProcessing } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  // const pagePath = history.location.pathname;
  // const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState();

  const columns = [
    { title: 'Name', field: 'name' },
    { title: 'Color', field: 'color' },
    { title: 'Cutting Speed', field: 'cutting_speed' },
    { title: 'Thickness', field: 'thickness' },
    { title: 'Bendable true/false', field: 'is_bendable' },
    { title: 'Minimal offset in mm', field: 'min_bend_offset' },
    { title: 'Price per bending', field: 'bending_price' },
  ];
  useEffect(() => {
    dispatch(getMaterials());
  }, []);
  useEffect(() => {
    updateData();
  }, [dataMaterials]);
  useEffect(() => {
    updateData();
  }, [pageSize]);
  const { count } = pagination;
  // const navigateTo = ({ id }) => {
  //   history.push(`${ROUTES.BENDING}/details/${id}`);
  // };
  const handleCreate = () => {
    history.push(`${ROUTES.BENDING}/create`);
  };
  const updateData = () => {
    setData(dataMaterials);
  };
  // const changePage = async (page) => {
  //   setCurrentPage(page);
  //   await localStorage.setItem(`${pagePath}`, page);
  // };
  const changePerPage = (per_page) => {
    setPageSize(per_page);
  };
  const confirmDeleteItem = (id) => {
    setDeleteId(id);
    setOpen(true);
  };
  const deleteItem = () => {
    dispatch(
      deleteMaterial({
        id: deleteId,
        successCallBack: () => {
          dispatch(getMaterials());
        },
      })
    );
    closeDialog();
  };
  const closeDialog = () => {
    setOpen(false);
    setDeleteId();
  };
  const confirmModal = () => {
    return (
      <Modal show={open} onHide={closeDialog} size="md" centered>
        <Modal.Header closeButton>
          <Modal.Title>Delete item</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Confirm that you would to delete the selected item.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeDialog}>
            Cancel
          </Button>
          <Button onClick={deleteItem}>Delete</Button>
        </Modal.Footer>
      </Modal>
    );
  };
  return isProcessing ? (
    'Loading...'
  ) : (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="kt-section">
            <div className="kt-section__content">
              <MaterialTable
                title="Materials"
                columns={columns}
                data={data}
                totalCount={count}
                onChangeRowsPerPage={changePerPage}
                actions={[
                  {
                    title: 'AD',
                    icon: EditIcon,
                    tooltip: 'Edit',
                    onClick: (event, rowData) => {
                      history.push(`${ROUTES.BENDING}/edit/${rowData.id}`);
                    },
                  },
                  {
                    title: 'AD',
                    icon: DeleteIcon,
                    tooltip: 'Delete',
                    onClick: (event, rowData) => confirmDeleteItem(rowData.id),
                  },
                  {
                    icon: 'add',
                    tooltip: 'Add',
                    isFreeAction: true,
                    onClick: () => handleCreate(),
                  },
                ]}
                options={{
                  pageSize: pageSize,
                  actionsColumnIndex: -1,
                  search: true,
                  headerStyle: {
                    color: '#D80000',
                    fontSize: 13,
                  },
                  body: {
                    fontSize: 13,
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {confirmModal()}
    </>
  );
}
MasterPage.propTypes = {
  dataMaterials: PropTypes.array,
  isProcessing: PropTypes.bool.isRequired,
};
const mapStateToProps = ({
  materials: { data, pagination, isProcessing },
}) => ({
  dataMaterials: data,
  pagination,
  isProcessing,
});
export default connect(mapStateToProps)(MasterPage);
