import React, { useEffect, useState, useReducer } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Modal, Button } from 'react-bootstrap';
import { Button as MUIbutton } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { FaDownload } from 'react-icons/fa6';
import { SlBasketLoaded } from 'react-icons/sl';

import { useHistory } from 'react-router-dom';

// import { metronic } from '../../../../../_metronic';
import { drawActions } from '../../../../store/ducks/draw.duck';
import CheckBox from '../../../../components/checkBox';
import MaterialSelector from './materialSelector';
import { Unstable_Popup as BasePopup } from '@mui/base/Unstable_Popup';

import { isNull } from 'lodash';

// import initApi from '../../../../crud/apiConfig/apiConfig'
// import { getQuoteSvg } from '../../../../crud/draw.crud';
import { API_URL } from '../../../../common/constants/api/apiConstants';
import { ROUTES } from '../../../../common/constants/routes/routes';
import { priceCalculator } from '../../../../partials/content/priceCalculator';

import './index.scss';

function FileComponent({
  fileList,

  fileContents,
  setFileContents,

  onClick,
  onDownload,
  uptFile,
  dltFile_s,
  materialById,
  addBasket,

  ...props
}) {
  const [checkedState, setCheckedState] = useState(
    fileList.reduce((accum, file) => ({ ...accum, [file.id]: false }), {})
  );

  const history = useHistory();

  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const [deleteModalVisibility, setDeleteModalVisibility] = useState(false);
  const [deleteId, setDeleteId] = useState(undefined);

  const [ternary, setTernary] = useState({ anchor: null, file: {} });

  const [specifyModalVisibility, setSpecifyModalVisibility] = useState(false);
  const [specifyId, setSpecifyId] = useState(undefined);
  const [selectedChoice, setSelectedChoice] = useState(null);

  const [materialObject, setMaterialObject] = useState({});

  const intl = useIntl();

  const handleCheckboxChange = (fileID, checked) => {
    const { setActionBar } = props;

    const newState = { ...checkedState };
    newState[fileID] = checked;

    const values = Object.values(newState);
    const indeterminate = values.includes(true);
    const checkAll = !values.includes(false);

    setActionBar((prevState) => ({
      ...prevState,
      checkbox: {
        checked: checkAll,
        indeterminate: !checkAll && indeterminate,
      },
      selectedFiles: Object.keys(newState)
        .filter((fileID) => newState[fileID])
        .map((fileID) => Number(fileID)),
    }));
    setCheckedState(newState);
  };

  const updateDeleteModalVisibility = (show) => {
    setDeleteModalVisibility(show);
  };

  const updateSpecifyModalVisibility = (show) => {
    setSpecifyModalVisibility(show);
  };

  const fileDeleteModal = (event, fileId) => {
    event.stopPropagation();
    setDeleteId(fileId);
    updateDeleteModalVisibility(true);
  };

  const fileSpecifyModal = (event, fileId) => {
    event.stopPropagation();
    setSpecifyId(fileId);

    updateSpecifyModalVisibility(true);
  };

  const deleteFile = () => {
    if (deleteId) {
      const files = fileContents.filter((item) => item.id !== deleteId);

      dltFile_s(deleteId);
      setFileContents(files);
      setDeleteId(undefined);
    }

    updateDeleteModalVisibility(false);
  };

  const handleChoiceSelection = (choice) => {
    setSelectedChoice(choice);
  };

  const specifyFile = async (length, area, choice) => {
    uptFile(specifyId, 'material', choice.id);

    if (specifyId) {
      const calculatedPrice = priceCalculator({
        area,
        length,
        materialPrice: choice.price,
        cuttingSpeed: choice.cutting_speed,
      });

      const newContents = fileContents.map((file) =>
        file.id === specifyId
          ? {
              ...file,
              material: materialObject[choice.id],
              price: calculatedPrice,
            }
          : file
      );

      setFileContents(newContents);
      setSpecifyId(undefined);
    }

    updateSpecifyModalVisibility(false);
  };

  const relationBTWchecked = function() {
    const { setActionBar } = props;
    const newIDs = fileList.map((f) => f.id);
    const oldIDs = Object.keys(checkedState).map((i) => Number(i));
    const diffIDs = {};

    if (newIDs !== oldIDs) {
      if (newIDs.length > oldIDs.length) {
        newIDs.forEach(
          (f_id) => !oldIDs.includes(f_id) && (diffIDs[f_id] = false)
        );

        setCheckedState((prevState) => {
          const newState = { ...prevState, ...diffIDs };

          const values = Object.values(newState);
          const newIndet = values.includes(true);
          const checkAll = !values.includes(false);

          setActionBar((prevState) => {
            const { checked, indeterminate: oldIndet } = prevState.checkbox;
            if (checked !== checkAll || oldIndet !== newIndet)
              return {
                ...prevState,
                checkbox: {
                  checked: checkAll,
                  indeterminate: !checkAll && newIndet,
                },
                selectedFiles: Object.keys(newState)
                  .filter((fileID) => newState[fileID])
                  .map((fileID) => Number(fileID)),
              };
            return prevState;
          });
          return newState;
        });
      } else {
        const newState = newIDs
          .filter((f_id) => !Object.keys(diffIDs).includes(f_id))
          .reduce((acc, key) => ((acc[key] = checkedState[key]), acc), {});

        const values = Object.values(newState);
        const newIndet = values.includes(true);
        const checkAll = !values.includes(false);

        setActionBar((prevState) => {
          const { checked, indeterminate: oldIndet } = prevState.checkbox;
          if (checked !== checkAll || oldIndet !== newIndet)
            return {
              ...prevState,
              checkbox: {
                checked: checkAll && values.length > 0,
                indeterminate: !checkAll && newIndet && values.length > 0,
              },
              selectedFiles: Object.keys(newState)
                .filter((fileID) => newState[fileID])
                .map((fileID) => Number(fileID)),
            };
          return prevState;
        });
        setCheckedState(newState);
      }
    }
  };

  const redirect_CAD = async function(product_id, file_id, name, material) {
    const DXF_TEXT = await fetch(
      `${API_URL}/api/product/${product_id}/download/dxf-original`
    ).then((imp) => imp.text());
    window.cutwise = { DXF_PROJECT: { DXF_TEXT, file_id, name, material } };
    history.push(`${ROUTES.SKETCHER}`);
  };

  useEffect(() => {
    if (props.choiceData) {
      const object = materialById(props.choiceData);
      setMaterialObject(object);
    }
  }, [props.choiceData]);

  useEffect(() => {
    // fetchSVGs();
    relationBTWchecked();
  }, [JSON.stringify(fileList)]);

  const {
    checkBox: { checked: allCheck, indeterminate },
  } = props; //setActionBar

  useEffect(() => {
    if (!(indeterminate && !allCheck)) {
      const newState = fileList.reduce(
        (accum, file) => ({ ...accum, [file.id]: allCheck }),
        {}
      );
      setCheckedState(newState);
    }
  }, [allCheck]);

  useEffect(() => {
    forceUpdate();
  }, [JSON.stringify(fileContents)]);

  return fileContents.length > 0 && fileContents.map((file, index) => {
    return (
      <div
        key={index + file.name}
        onClick={onClick}
        className={`file-component ${checkedState[file.id] ? 'checked' : ''}`}
      >
        <div className="column file-component_info">
          <div className="checkbox">
            <CheckBox
              checked={checkedState[file.id]}
              onClick={(e) => handleCheckboxChange(file.id, e.target.checked)}
            />
          </div>

          <div className="file-component_img">
            {file.svgContent ? (
              <div
                dangerouslySetInnerHTML={{ __html: file.svgContent }}
                style={{
                  padding: '10px',
                  width: '150px',
                  height: '100px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              />
            ) : (
              <p>Error loading SVG</p>
            )}
          </div>

          <div className="file-component_description">
            <div className="item-row">
              {file.name && (
                <strong style={{ fontSize: '14px' }}>{file.name}</strong>
              )}
            </div>

            <div className="item-row">
              <strong>
                <FormattedMessage id="CAD.LIBRARY.MATERIAL" />:
              </strong>

              {file.material ? (
                file.material.name + ', ' + file.material.thickness + 'mm'
              ) : (
                <FormattedMessage id="CAD.LIBRARY.NOT_SPECIFIED" />
              )}
            </div>

            <div className="item-row">
              <strong>
                <FormattedMessage id="CAD.LIBRARY.COLOR" />:
              </strong>
              {file.material ? (
                file.material[`color_${intl.locale}`]
              ) : (
                <FormattedMessage id="CAD.LIBRARY.NOT_SPECIFIED" />
              )}
            </div>

            {file.svgWidth && file.svgHeight && (
              <div className="item-row">
                <strong>
                  <FormattedMessage id="CAD.LIBRARY.SIZE" />:
                </strong>
                {Number(file.svgWidth.replace('mm', '')).toFixed() - 10} x{' '}
                {Number(file.svgHeight.replace('mm', '')).toFixed() - 10}mm
              </div>
            )}
          </div>

          <div className="file-component_price">
            {file.price ? (
              '€ ' + file.price
            ) : (
              <span
                style={{
                  display: 'inline-block',
                  color: '#D80000',
                  fontSize: '12px',
                  lineHeight: 1.2,
                }}
              >
                <FormattedMessage id="CAD.LIBRARY.NOT_SPECIFIED_PRICE_UNAVAILABLE" />
              </span>
            )}
          </div>
        </div>

        <div className="column file-component_actions">
          <div className="hide-mobile text-center">
            <button
              type={'button'}
              className={'btn btn-bold btn-outline-brand'}
              onClick={() =>
                redirect_CAD(file.product_id, file.id, file.name, file.material)
              }
            >
              <FormattedMessage id="CAD.LIBRARY.EDIT_IN_CUTWISE_CAD" />
            </button>
          </div>

          <div className="hide-mobile text-center">
            <button
              type={'button'}
              className={'btn btn-bold btn-outline-brand'}
              onClick={(e) => fileSpecifyModal(e, file.id)}
            >
              {file.material ? (
                <FormattedMessage id="CAD.LIBRARY.RE_SPECIFY" />
              ) : (
                <FormattedMessage id="CAD.LIBRARY.SPECIFY" />
              )}
            </button>
          </div>

          <BasePopup
            open={!isNull(ternary.anchor)}
            anchor={ternary.anchor}
            onMouseLeave={() =>
              setTernary((prevState) => ({
                ...prevState,
                anchor: null,
                file: {},
              }))
            }
          >
            <div className="ternary">
              <MUIbutton
                variant="contained"
                onClick={() => {
                  props.dxfUpload([ternary.file]);
                  setTernary((prevState) => ({ ...prevState, anchor: null }));
                }}
                style={{ boxShadow: 'unset', marginRight: '15px' }}
              >
                <FormattedMessage id="CAD.LIBRARY.GO_CHECKOUT" />
              </MUIbutton>
              <MUIbutton
                variant="contained"
                onClick={() => {
                  props.createCheckout([ternary.file]);
                  setTernary((prevState) => ({ ...prevState, anchor: null }));
                }}
                style={{
                  opacity: ternary.file.material ? 1 : 0.3,
                  boxShadow: 'unset',
                }}
                disabled={!ternary.file.material}
              >
                <FormattedMessage id="CAD.LIBRARY.GO_CONFIGURATOR" />
              </MUIbutton>
            </div>
          </BasePopup>

          <div className="hide-mobile">
            <button
              type={'button'}
              className={'btn btn-brand btn-bold'}
              disabled={ternary.disable}
              onClick={(e) =>
                setTernary({
                  anchor: ternary.anchor ? null : e.currentTarget,
                  file: file,
                })
              }
            >
              {ternary.disable ? (
                <CircularProgress />
              ) : (
                <FormattedMessage id="CAD.LIBRARY.ORDER" />
              )}
            </button>
          </div>

          <div className="file-component_basket" >
            <SlBasketLoaded
              style={{
                fontSize: '1.5em',
                // cursor: 'pointer',
                cursor: file.material ? 'pointer' : 'not-allowed',
              }}
              color={'#404040'}
              onClick={() => file.material && addBasket(file.id)}
            />
          </div>

          <div className="file-component_download">
            <FaDownload
              style={{ fontSize: '1.5em', cursor: 'pointer' }}
              color={'#404040'}
              onClick={() => onDownload(file.product_id, file.name)}
            />
          </div>

          <div className="file-component_remove">
            <FontAwesomeIcon
              icon={regular('trash-can')}
              style={{ fontSize: '1.5em' }}
              color={'#404040'}
              cursor={'pointer'}
              onClick={(e) => fileDeleteModal(e, file.id)}
            />
          </div>
        </div>

        {/* Modal for Specifying file details */}
        <Modal
          className="cad-modal"
          show={specifyModalVisibility}
          onHide={() => updateSpecifyModalVisibility(false)}
          size={'md'}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <FormattedMessage id="CAD.LIBRARY.SPECIFY_PART" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormattedMessage id="CAD.LIBRARY.SPECIFY_DETAILS" />
            <MaterialSelector
              svgWidth={Number(file.svgWidth.replace('mm', ''))}
              svgHeight={Number(file.svgHeight.replace('mm', ''))}
              onChoiceSelect={handleChoiceSelection}
              choiceData={props.choiceData}
            />
            <FormattedMessage id="CAD.LIBRARY.CALC_PRICE_TEXT" />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant={'secondary'}
              onClick={() => updateSpecifyModalVisibility(false)}
            >
              <FormattedMessage id="CANCEL" />
            </Button>
            <Button
              onClick={() =>
                specifyFile(file.totalLength, file.totalArea, selectedChoice)
              }
            >
              <FormattedMessage id="SAVE" />
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Modal Deletion for CAD Drawing part */}
        <Modal
          className="cad-modal"
          show={deleteModalVisibility}
          onHide={() => updateDeleteModalVisibility(false)}
          size={'md'}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <FormattedMessage id="CAD.LIBRARY.DELETE_PART" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormattedMessage id="CAD.LIBRARY.CONFIRM_DELETE" />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant={'secondary'}
              onClick={() => updateDeleteModalVisibility(false)}
            >
              <FormattedMessage id="CANCEL" />
            </Button>
            <Button onClick={(e) => deleteFile(e)}>
              <FormattedMessage id="DELETE" />
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  });
}

const mapStateToProps = ({ i18n, auth: { user }, draw: { uploadedSvgs } }) => ({
  lang: i18n.lang,
  user,
  uploadedSvgs,
});

const mapDispatchToProps = {
  updateDrawSVGarray: (uploadedSvgs) =>
    drawActions.updateDrawSVGarray({ SVG: uploadedSvgs }),
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(FileComponent)
);
