import React from 'react';
import { Route, Switch } from 'react-router-dom';
import MasterPage from './MasterPage';
import FormPage from './FormPage';
import { ROUTES } from "../../../common/constants/routes/routes";

export default function HourlyRates () {
  return (
    <Switch>
      <Route exact path={ROUTES.HOURLY_RATES} component={MasterPage} />
      <Route exact path={`${ROUTES.HOURLY_RATES}/create`} component={FormPage} />
      <Route exact path={`${ROUTES.HOURLY_RATES}/edit/:id`} component={FormPage} />
    </Switch>
  );
}
