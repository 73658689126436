import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useIntl } from 'react-intl';
import MasterPage from './master/MasterPage';
import DetailsPage from './details/DetailsPage';

import MetaTags from '../../../components/MetaTags';

export default function Quotes() {
  const intl = useIntl();

  return (
    <>
      <MetaTags
        title={intl.formatMessage({ id: 'QUOTE.META.TITLE' })}
        description={intl.formatMessage({ id: 'QUOTE.META.DESC' })}
        keywords={intl.formatMessage({ id: 'QUOTE.META.KEYWORDS' })}
      />

      <Switch>
        <Redirect exact from="/quotes" to="/quotes/master" />
        <Route path="/quotes/master" component={MasterPage} />
        <Route path="/quotes/details/:id" component={DetailsPage} />
      </Switch>
    </>
  );
}
