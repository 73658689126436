import React, { useEffect, useState, useRef } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { 
  Grid, 
  Box, 
  Typography, 
  TextField, 
  InputAdornment, 
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stepper,
  Step,
  StepLabel,
} from '@material-ui/core';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import Multiselect from 'multiselect-react-dropdown';
import SearchIcon from '@mui/icons-material/Search';
import CheckIcon from '@mui/icons-material/Check';

import MetaTags from '../../../components/MetaTags';
import { toAbsoluteUrl, filterObjectsByStringKeys  } from '../../../../_metronic';

import { materialsInitialItems } from './materialInitialItems';
import PDFDownloader from '../materialsLP/PDFDownloader';

import { API_URL, API_URLS } from '../../../common/constants/api/apiConstants';
import { MATERIAL_CATEGORIES } from '../../../common/constants/constants';
import { ROUTES } from '../../../common/constants/routes/routes';
import initApi from '../../../crud/apiConfig/apiConfig';

const Materials360Page = ({ user, intl }) => {
  const history = useHistory();
  const isMounted = useRef(false);
  const [loading, setLoading] = useState(true);
  const [filteredItems, setFilteredItems] = useState([]);
  const [filteredCategory, setFilteredCategory] = useState('');
  const [searchData, setSearchData] = useState([]);
  const [search, setSearch] = useState('');

  const [colors, setColors] = useState([]);
  const [thicknesses, setThicknesses] = useState([]);

  const [color, setColor] = useState('');
  const [colorSelectedList, setColoSelectedList] = useState([]);
  const [thickness, setThickness] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const [index, setIndex] = useState(null);

  const dataSheetTitles = [
    intl.formatMessage({ id: 'Dikte tolerantie' }),
    intl.formatMessage({ id: 'Datasheet' }),
  ];

  const steps = [
    {
      label: intl.formatMessage({ id: '360.HOW_IT_WORKS.LIST.LI_1' }),
    },
    {
      label: intl.formatMessage({ id: '360.HOW_IT_WORKS.LIST.LI_2' }),
    },
    {
      label: intl.formatMessage({ id: '360.HOW_IT_WORKS.LIST.LI_3' }),
    },
  ];

  const handleCategoryClick = async (category) => {
    // const { data } = await initApi().get(
    //  API_URL + API_URLS.GET_MATERIAL_FILTER_DATA + `?category=${category}`
    // );

    const selectedCategory = MATERIAL_CATEGORIES.find((item) => item.id == category)?.value || 'all';
    const filteredItemsArrayByCategory = materialsInitialItems.filter((item) => item.category === selectedCategory);

    setFilteredItems(filteredItemsArrayByCategory);

    setColor('');
    setThickness('');
    setSearch('');
    setSearchData([]);

    setFilteredCategory(category);
  };

  const handleColorChange = (selectedList) => {
    const label = colorSelectedList.filter((item) => {
      return intl?.locale !== 'nl' && item !== selectedList[0]
        ? selectedList[0] === intl.formatMessage({ id: item })
        : intl.formatMessage({ id: selectedList[0] }) ===
            intl.formatMessage({ id: item });
    });

    setColor(label[0]);
  };

  const handleThicknessChange = (selectedList) => {
    const option = selectedList;

    setThickness(option);
  };

  const getMaterialDataByColor = async () => {
    if (color) {
      // const { data } = await initApi().get(
      //   API_URL + API_URLS.GET_MATERIAL_FILTER_DATA + `?color=${color}`
      // );
      // const filteredItemsArrayByColor = Array.from(
      //   new Set(data.map((item) => item.name))
      // );
      // setFilteredItems(
      //   filterObjectsByStringKeys(
      //     materialsInitialItems,
      //     filteredItemsArrayByColor
      //   )
      // );
      // setFilteredCategory('all');
      // setThickness('');
    }
  };

  const getMaterialDataByThickness = async () => {
    if (thickness) {
      // const { data } = await initApi().get(
      //   API_URL + API_URLS.GET_MATERIAL_FILTER_DATA + `?thickness=${thickness}`
      // );
      // const filteredItemsArrayByThickness = Array.from(
      //   new Set(data.map((item) => item.name))
      // );
      // setFilteredItems(
      //   filterObjectsByStringKeys(
      //     materialsInitialItems,
      //     filteredItemsArrayByThickness
      //   )
      // );
      // setFilteredCategory('all');
      // setColor('');
    }
  };

  const getMaterialSearchData = async (query) => {
    const { data } = await initApi().get(
      API_URL + API_URLS.GET_MATERIAL_SEARCH_DATA + `?query=${query}`
    );

    if (isMounted.current) {
      if (!data.length) {
        setLoading(true);
        setFilteredItems([]);
      }
      setSearchData(data);
    }
  };

  const getMaterialDetailsData = async () => {
    const { data } = await initApi().get(
      API_URL + API_URLS.GET_MATERIAL_DETAILS
    );

    if (isMounted.current) {
      setColoSelectedList(data?.colors);
      setColors(
        data?.colors.map((item) => {
          return intl.formatMessage({ id: item });
        })
      );
      setThicknesses(
        data?.thicknesses.map((item) => {
          return item.replace(/\.?0+$/, '') + ` mm`;
        })
      );
    }
  };

  const getDataWithCriteria = async (item) => {
    const { data } = await initApi().get(
      API_URL + API_URLS.GET_DATA_WITH_CRITERIA + `?color=${item}`
    );

    setThicknesses(
      data?.thicknesses.map((item) => {
        return item.replace(/\.?0+$/, '') + ` mm`;
      })
    );
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const categoryFromParams = params.get('category') || 'all';
    const category = MATERIAL_CATEGORIES.find((item) => item.value == categoryFromParams)?.id || 'all';

    if (category === 'all') {
      setFilteredItems(materialsInitialItems);
    } else {
      handleCategoryClick(category);
    }

    setLoading(false);
    setFilteredCategory(category);

    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      getMaterialDetailsData();
    }
  }, [isMounted, intl?.locale]);

  useEffect(() => {
    if (color) {
      colorLabel(color);
    }
  }, [color, intl?.locale]);

  useEffect(() => {
    if (search) {
      getMaterialSearchData(search);
    }
  }, [search]);

  useEffect(() => {
    if (filteredCategory === 'all') {
      setFilteredItems(materialsInitialItems);
    }

    setSearch('');
    setSearchData([]);
  }, [isMounted, filteredCategory]);

  useEffect(() => {
    getMaterialDataByColor();

    if (color) {
      getDataWithCriteria(color);
      setThickness('');
    }
  }, [color]);

  useEffect(() => {
    getMaterialDataByThickness();
  }, [thickness]);

  useEffect(() => {
    handleSearchQuery();

    if (searchInput.length > 2) {
      handleSearchQuery();
    }
  }, [color, thickness, searchInput]);

  useEffect(() => {
    if (!searchInput) {
      setLoading(false);
      setFilteredItems(materialsInitialItems);
    }
  }, [searchInput]);

  useEffect(() => {
    if (searchData.length) {
      const searchedItemsArray = Array.from(
        new Set(searchData.map((item) => item.name))
      );

      if (!searchedItemsArray.length) {
        setLoading(true);
        setFilteredItems([]);
      } else {
        setLoading(false);
        setFilteredItems(
          filterObjectsByStringKeys(materialsInitialItems, searchedItemsArray)
        );
      }
    }
  }, [searchData, search]);

  useEffect(() => {
    const params = new URLSearchParams();
    if(filteredCategory) {
      const category = MATERIAL_CATEGORIES.find((item) => item.id == filteredCategory)?.value || 'all';
      params.set('category', category);

      history.push({
        pathname: location.pathname,
        search: params.toString(),
      });
    }
  }, [filteredCategory]);

  const onKeyPress = (event) => {
    setSearchInput(event.target.value);

    if (event.keyCode == 13) {
      handleSearchQuery();
    }
  };

  const handleSearchQuery = () => {
    const searchQuery =
      searchInput +
      (color && (searchInput && '&') + color) +
      (thickness &&
        ((searchInput || color) && '&') + thickness[0].replace(/ mm/, ''));

    if (searchQuery.length > 1) {
      setSearch(searchQuery);
      setFilteredCategory('all');
    } else {
      setSearch('');
    }
  };

  const colorLabel = (color) => {
    return intl.formatMessage({ id: color });
  };

  const handleMaterialItemClick = (e, href, id) => {
    if (e.target.tagName === 'A' || e.target.closest('a')) {
      return;
    }

    if (!user) {
      return history.push(`${href}`);
    } else {
      return history.push(`${ROUTES.MATERIALS_360}/${id}`);
    }
  };

  return (
    <>
      <MetaTags
        title={intl.formatMessage({ id: '360.META.TITLE' })}
        description={intl.formatMessage({ id: '360.META.DESC' })}
        keywords={intl.formatMessage({ id: '360.META.KEYWORDS' })}
      />

      <div id="kt_subheader" className="subheader">
        <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
          <div className="d-flex align-items-center flex-wrap mr-1">
            <div className="d-flex align-items-baseline mr-5">
              <h2 className="materials-title my-2 mr-5">
                <FormattedMessage id={'MENU.MATERIALS_360'} />
              </h2>
            </div>
          </div>
        </div>
      </div>

      <Grid container item xs={12} className="top-bar-module">
        <Grid item className='ai-choice-assistance-btn'>
          <h5>
            <img src={toAbsoluteUrl('/media/icons/stars.svg')} alt="Stars" />
            <FormattedMessage id={'360.AI_KEUZE_HULP'} /> (<FormattedMessage id={'360.COMING_SOON'} />)
          </h5>
        </Grid>


        <Grid item className="filtered-btns-block">
          <button
            value="all"
            onClick={(e) => {
              handleCategoryClick(e.target.value);
              setSearch('');
              setSearchInput('');
              setColor('');
              setThickness('');
            }}
            className={filteredCategory === 'all' ? 'active' : ''}
            data-text={intl.formatMessage({ id: 'All' })}
          />

          {MATERIAL_CATEGORIES.map((item) => {
            return (
              <button
                key={item.id}
                value={item.id}
                onClick={(e) => {
                  handleCategoryClick(e.target.value);
                  setSearch('');
                  setSearchInput('');
                  setColor('');
                  setThickness('');
                }}
                className={filteredCategory == item.id ? 'active' : ''}
                data-text={intl.formatMessage({ id: `${item.title}` })}
              />
            );
          })}
        </Grid>

        <Grid item className="filtered-options-block">
          <Multiselect
            isObject={false}
            singleSelect={true}
            avoidHighlightFirstOption={true}
            options={colors}
            selectedValues={
              !color.length
                ? intl.formatMessage({
                    id: '360.SELECT_COLOR',
                  })
                : colorLabel(color)
            }
            onSelect={handleColorChange}
            className="multi-color-select"
          />

          <Multiselect
            isObject={false}
            singleSelect={true}
            avoidHighlightFirstOption={true}
            options={thicknesses}
            selectedValues={
              !thickness.length
                ? intl.formatMessage({
                    id: '360.SELECT_THICKNESS',
                  })
                : thickness
            }
            value={thickness}
            onSelect={handleThicknessChange}
          />
        </Grid>

        <Grid item className="global-search-block">
          <TextField
            className="search-input"
            placeholder={intl.formatMessage({
              id: '360.SEARCH_PLACEHOLDER',
            })}
            variant={'outlined'}
            onInput={(e) => {
              setColor('');
              setThickness('');
              setFilteredCategory('all');
              setSearchInput(e.target.value);
            }}
            onChange={(e) => {
              setColor('');
              setThickness('');
              setFilteredCategory('all');
              setSearchInput(e.target.value);
            }}
            onKeyDown={onKeyPress}
            value={searchInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

      </Grid>

      <Grid
        container
        item
        xs={12}
        justifyContent="start"
        className="materials-items-box"
      >
        <Grid item className='material-description-wrapper'>
          <Typography component='div'>
            <Box fontWeight="fontWeightBold"><FormattedMessage id={'360.MATERIAL.WRAPPER.DESC_1'} /> </Box>
            <Box><FormattedMessage id={'360.MATERIAL.WRAPPER.DESC_2'} /> </Box>
          </Typography>

          <Typography variant='h5'><FormattedMessage  id="AUTH.GENERAL.HOW_DOES_CUTWISE_WORK" /></Typography>

          <Stepper activeStep={-1} orientation="vertical" className='how-it-works-list'>
            {steps.map((step, index) => (
              <Step key={index + step.label}>
                <StepLabel>{step.label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          <Typography variant='subtitle1'>
            <FormattedMessage  id="360.HOW_IT_WORKS.LIST.FOOTER" values={{ boldText: <strong><FormattedMessage  id="360.HOW_IT_WORKS.LIST.FOOTER.BOLD_TEXT" /></strong>}} />
          </Typography>

          <Typography variant='h5'><FormattedMessage  id="360.ADVANTAGES_CUTWISE.TITLE" /></Typography>

          <ul>
            <li>
              <CheckIcon
                htmlColor="#7ed321"
                style={{ marginRight: '8px' }}
              />
              <FormattedMessage id="360.ADVANTAGES_CUTWISE.LIST.ITEM_1" />
            </li>
            <li>
              <CheckIcon
                htmlColor="#7ed321"
                style={{ marginRight: '8px' }}
              />
              <FormattedMessage id="360.ADVANTAGES_CUTWISE.LIST.ITEM_2" />
            </li>
            <li>
              <CheckIcon
                htmlColor="#7ed321"
                style={{ marginRight: '8px' }}
              />
              <FormattedMessage id="360.ADVANTAGES_CUTWISE.LIST.ITEM_3" />
            </li>
          </ul>

          <Typography variant='subtitle1' component="div">
            <FormattedMessage  
              id="360.ADVANTAGES_CUTWISE.LIST.FOOTER.TEXT" 
              values={{
                link1: <Link to="/auth/registration" target="_blank"><FormattedMessage id="360.ADVANTAGES_CUTWISE.LIST.FOOTER.TEXT.REG.LINK" /></Link>,
                link2: <Link to='/auth/login' target="_blank"><FormattedMessage id="360.ADVANTAGES_CUTWISE.LIST.FOOTER.TEXT.LOGIN.LINK" /></Link>
              }}
            />
          </Typography>

          <Grid item xs={12} className="faq-accordion-list">
            <Typography variant='h5'><FormattedMessage id="360.FAQ" /></Typography>

            <Accordion
              TransitionProps={{ unmountOnExit: true }}
              expanded={index === 0}
              onChange={(event, expanded) => {
                event.preventDefault();

                setIndex(expanded ? 0 : null);
              }}
            >
              <AccordionSummary
                expandIcon={
                  index === 0 ? (
                    <RemoveCircleOutlineIcon fontSize="large" />
                  ) : (
                    <AddCircleOutlineIcon fontSize="large" />
                  )
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="body1">
                  <FormattedMessage id="360.FAQ.ACCORDION_1.TITLE" />
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body2">
                  <FormattedMessage 
                    id="360.FAQ.ACCORDION_1.BODY" 
                    values={{ 
                      boldDXF: <strong><FormattedMessage id="360.FAQ.BOLD_DXF" /></strong>,
                      boldSTP: <strong><FormattedMessage id="360.FAQ.BOLD_STP" /></strong>,
                    }}
                  />
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              TransitionProps={{ unmountOnExit: true }}
              expanded={index === 1}
              onChange={(event, expanded) => {
                event.preventDefault();

                setIndex(expanded ? 1 : null);
              }}
            >
              <AccordionSummary
                expandIcon={
                  index === 1 ? (
                    <RemoveCircleOutlineIcon fontSize="large" />
                  ) : (
                    <AddCircleOutlineIcon fontSize="large" />
                  )
                }
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography variant="body1">
                  <FormattedMessage id="360.FAQ.ACCORDION_2.TITLE" />
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body2">
                  <FormattedMessage id="360.FAQ.ACCORDION_2.BODY" />
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              TransitionProps={{ unmountOnExit: true }}
              expanded={index === 2}
              onChange={(event, expanded) => {
                event.preventDefault();

                setIndex(expanded ? 2 : null);
              }}
            >
              <AccordionSummary
                expandIcon={
                  index === 2 ? (
                    <RemoveCircleOutlineIcon fontSize="large" />
                  ) : (
                    <AddCircleOutlineIcon fontSize="large" />
                  )
                }
                aria-controls="panel3a-content"
                id="panel3a-header"
              >
                <Typography variant="body1">
                  <FormattedMessage id="360.FAQ.ACCORDION_3.TITLE" />
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography component="div" variant='body2'>
                  <Box fontWeight="fontWeightBold"><FormattedMessage id="360.FAQ.ACCORDION_3.BODY.PLASTICS" /></Box>
                  <Box fontWeight="fontWeightBold">
                    <FormattedMessage 
                      id="360.FAQ.ACCORDION_3.BODY.PLASTICS.DESC" 
                      values={{ 
                        regText: <span><FormattedMessage id="360.FAQ.ACCORDION_3.BODY.PLASTICS.REG_TEXT" /></span>,
                        nylon: <span><FormattedMessage id="360.PLASTICS.NYLON" /></span>,
                        plexiglass: <span><FormattedMessage id="360.PLASTICS.PLEXIGLASS" /></span>,
                        polycarbonate: <span><FormattedMessage id="360.PLASTICS.POLYCARBONATE" /></span>,
                        expanded: <span><FormattedMessage id="360.PLASTICS.EXPANDED" /></span>,
                      }} 
                    />
                  </Box>
                </Typography>

                <Typography component="div" variant='body2'>
                  <Box fontWeight="fontWeightBold"><FormattedMessage id="360.FAQ.ACCORDION_3.BODY.RUBBER" /></Box>
                  <Box fontWeight="fontWeightBold">
                    <FormattedMessage id="360.FAQ.ACCORDION_3.BODY.RUBBER.DESC" values={{ regText: <span><FormattedMessage id="360.FAQ.ACCORDION_3.BODY.RUBBER.REG_TEXT" /></span> }} />
                  </Box>
                </Typography>

                <Typography component="div" variant='body2'>
                  <Box fontWeight="fontWeightBold"><FormattedMessage id="360.FAQ.ACCORDION_3.BODY.OTHERS" /></Box>
                  <Box fontWeight="fontWeightBold">
                    <FormattedMessage id="360.FAQ.ACCORDION_3.BODY.OTHERS.DESC" values={{ regText: <span><FormattedMessage id="360.FAQ.ACCORDION_3.BODY.OTHERS.REG_TEXT" /></span> }} />
                  </Box>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              TransitionProps={{ unmountOnExit: true }}
              expanded={index === 3}
              onChange={(event, expanded) => {
                event.preventDefault();

                setIndex(expanded ? 3 : null);
              }}
            >
              <AccordionSummary
                expandIcon={
                  index === 3 ? (
                    <RemoveCircleOutlineIcon fontSize="large" />
                  ) : (
                    <AddCircleOutlineIcon fontSize="large" />
                  )
                }
                aria-controls="panel4a-content"
                id="panel4a-header"
              >
                <Typography variant="body1">
                  <FormattedMessage id="360.FAQ.ACCORDION_4.TITLE" />
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography component="div" variant='body2'>
                  <Box fontWeight="fontWeightBold"><FormattedMessage id="360.FAQ.ACCORDION_4.BODY.PLASTICS" /></Box>
                  <FormattedMessage 
                    id="360.FAQ.ACCORDION_4.BODY.PLASTICS.DESC" 
                    values={{ 
                      start: <strong><FormattedMessage id="360.FAQ.ACCORDION_4.BODY.PLASTICS.START" /></strong>,
                      end: <strong><FormattedMessage id="360.FAQ.ACCORDION_4.BODY.PLASTICS.END" /></strong>,
                    }}
                  />
                </Typography>

                <Typography component="div" variant='body2'>
                  <Box fontWeight="fontWeightBold"><FormattedMessage id="360.FAQ.ACCORDION_4.BODY.RUBBER" /></Box>
                  <FormattedMessage 
                    id="360.FAQ.ACCORDION_4.BODY.RUBBER.DESC" 
                    values={{ 
                      start: <strong><FormattedMessage id="360.FAQ.ACCORDION_4.BODY.RUBBER.START" /></strong>,
                      end: <strong><FormattedMessage id="360.FAQ.ACCORDION_4.BODY.RUBBER.END" /></strong>,
                    }}
                  />
                </Typography>

                <Typography component="div" variant='body2'>
                  <Box fontWeight="fontWeightBold"><FormattedMessage id="360.FAQ.ACCORDION_4.BODY.HPL" /></Box>
                  <FormattedMessage 
                    id="360.FAQ.ACCORDION_4.BODY.HPL.DESC" 
                    values={{ 
                      start: <strong><FormattedMessage id="360.FAQ.ACCORDION_4.BODY.HPL.START" /></strong>,
                      end: <strong><FormattedMessage id="360.FAQ.ACCORDION_4.BODY.HPL.END" /></strong>,
                    }}
                  />
                </Typography>

                <Typography component="div" variant='body2'>
                  <Box fontWeight="fontWeightBold"><FormattedMessage id="360.FAQ.ACCORDION_4.BODY.ALUPANEL" /></Box>
                  <FormattedMessage 
                    id="360.FAQ.ACCORDION_4.BODY.ALUPANEL.DESC" 
                    values={{ 
                      start: <strong><FormattedMessage id="360.FAQ.ACCORDION_4.BODY.ALUPANEL.START" /></strong>,
                      end: <strong><FormattedMessage id="360.FAQ.ACCORDION_4.BODY.ALUPANEL.END" /></strong>,
                    }}
                  />
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              TransitionProps={{ unmountOnExit: true }}
              expanded={index === 4}
              onChange={(event, expanded) => {
                event.preventDefault();

                setIndex(expanded ? 4 : null);
              }}
            >
              <AccordionSummary
                expandIcon={
                  index === 4 ? (
                    <RemoveCircleOutlineIcon fontSize="large" />
                  ) : (
                    <AddCircleOutlineIcon fontSize="large" />
                  )
                }
                aria-controls="panel5a-content"
                id="panel5a-header"
              >
                <Typography variant="body1">
                  <FormattedMessage id="360.FAQ.ACCORDION_5.TITLE" />
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body2">
                  <FormattedMessage 
                    id="360.FAQ.ACCORDION_5.BODY" 
                    values={{ 
                      boldDXF: <strong><FormattedMessage id="360.FAQ.BOLD_DXF" /></strong>,
                      boldSTP: <strong><FormattedMessage id="360.FAQ.BOLD_STP" /></strong>,
                    }}
                  />
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>

        <Grid item className='material-items-wrapper'>
          {loading && filteredItems.length == 0 ? (
            <div
              style={{
                borderTop: '1px solid #ebedf2',
                marginTop: '10px',
                marginLeft: '10px',
                padding: '10px',
              }}
            >
              <FormattedMessage id="no_records_to_display" />
            </div>
          ) : (
            filteredItems?.map((material) => (
              <Grid
                container
                item
                key={material.id}
                className="material-item-block"
                flexDirection="column"
                onClick={(event) => {
                  handleMaterialItemClick(
                    event,
                    material.href[intl?.locale.toUpperCase() || 'NL'] ||
                    material.href,
                    material?.id
                  );
                }}
              >
                <img src={material.imgSrc} alt={material.title} />
                <h5><FormattedMessage id={`${material.title}`} /></h5>
                <Typography variant='body1'>{material.subtitle}</Typography>
                <Typography component="div" textAlign="right">
                  <Box fontWeight="fontWeightBold" textAlign="left" style={{ width: "auto" }}><FormattedMessage id={'ORDER.THICKNESS'} /></Box>
                  {material.thickness} mm
                </Typography>
                <Typography component="div" textAlign="right">
                  <Box fontWeight="fontWeightBold" textAlign="left" style={{ width: "auto" }}><FormattedMessage id={'360.FORMAT'} /></Box>
                  {material.plateMeasurement} mm
                </Typography>
                
                <Grid item container xs={12} flexDirection="column-reverse">
                  {dataSheetTitles.map((title, index) => (
                    <Typography component="div" textAlign="right" key={`${index}-${material.title}`}>
                      <PDFDownloader
                        title={title}
                        pdfUrl={`https://api.prod.cutwise.nl/assets/images/${material.dataSheets[index]}`}
                        pdfFileName={title}
                        isItemBlock={true}
                      />
                    </Typography>
                  ))}
                </Grid>
                
                <button className="btn btn-bold btn-outline-brand">
                  <FormattedMessage id={'360.SEE_MORE'} />
                </button>
              </Grid>
            ))
          )}
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = ({ auth: { user } }) => ({
  user,
});

export default injectIntl(connect(mapStateToProps)(Materials360Page));
