import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

const CustomToolTip = ({ id, tooltipText, children, position, effect }) => {
  return (
    <>
      {children}

      <ReactTooltip
        id={id}
        place={position}
        effect={effect}
        style={{ maxWidth: 100 }}
      >
        <span className={'tooltip-text'} style={{ color: '#fff' }}>
          {tooltipText}
        </span>
      </ReactTooltip>
    </>
  );
};

export default CustomToolTip;

CustomToolTip.defaultProps = {
  id: 'custom-tooltip',
  tooltipText: '',
  children: React.createElement('div'),
  position: 'top',
  effect: 'solid',
};

CustomToolTip.propTypes = {
  id: PropTypes.string,
  tooltipText: PropTypes.element || PropTypes.string,
  children: PropTypes.element,
  position: PropTypes.string,
  effect: PropTypes.string,
};
