import { createAction } from "redux-actions";

export const getMessages = createAction("GET_MESSAGES_REQUEST");
export const getMessagesSuccess = createAction("GET_MESSAGES_SUCCESS");
export const getMessagesError = createAction("GET_MESSAGES_ERROR");

export const getNotifications = createAction("GET_NOTIFICATIONS_REQUEST");
export const getNotificationsSuccess = createAction(
  "GET_NOTIFICATIONS_SUCCESS"
);
export const getNotificationsError = createAction("GET_NOTIFICATIONS_ERROR");

export const getMessage = createAction("GET_MESSAGE_REQUEST");
export const getMessageSuccess = createAction("GET_MESSAGE_SUCCESS");
export const getMessageError = createAction("GET_MESSAGES_ERROR");

export const setMessage = createAction("SET_MESSAGE_REQUEST");
export const setMessageSuccess = createAction("SET_MESSAGE_SUCCESS");
export const setMessageError = createAction("SET_MESSAGE_ERROR");

export const updateMessage = createAction("UPDATE_MESSAGE_REQUEST");
export const updateMessageSuccess = createAction("UPDATE_MESSAGE_SUCCESS");
export const updateMessageError = createAction("UPDATE_MESSAGE_ERROR");

export const deleteMessage = createAction("DELETE_MESSAGE_REQUEST");
export const deleteMessageSuccess = createAction("DELETE_MESSAGE_SUCCESS");
export const deleteMessageError = createAction("DELETE_MESSAGE_ERROR");
