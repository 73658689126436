import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { connect, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { FormLayout } from '../../../../components/FormPageLayout/Layouts';
import Form from './Form';
import { updateMessage, getMessage } from '../../../../store/messages/actions';
import { ROUTES } from '../../../../common/constants/routes/routes';

const UpdateFormPage = ({ data, isProcessing }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const formRef = useRef({
    validateForm: () => {},
    values: {},
  });

  useEffect(() => {
    if (!id) return;
    dispatch(getMessage({ id }));
  }, []);

  const handleSubmit = async () => {
    const { values, validateForm } = formRef.current;
    await validateForm();
    if (!formRef.current.isValid || !values) return;
    dispatch(
      updateMessage({
        id,
        data: values,
        cb: ({ status }) => {
          if (status === 'error') return;
          history.push(ROUTES.ADMIN_UPDATES);
        },
      })
    );
  };

  return isProcessing ? (
    'Loading...'
  ) : (
    <FormLayout
      onClose={() => history.push(ROUTES.ADMIN_UPDATES)}
      title="News Edit"
      onSubmit={handleSubmit}
    >
      {!!data && <Form data={data} ref={formRef} />}
    </FormLayout>
  );
};

UpdateFormPage.propTypes = {
  isProcessing: PropTypes.bool.isRequired,
  data: PropTypes.object,
};

const mapStateToProps = ({
  messages: {
    detail: { data, isProcessing },
  },
}) => ({
  data,
  isProcessing,
});

export default connect(mapStateToProps)(UpdateFormPage);
