import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import { BiInfoCircle } from 'react-icons/bi';
import { FormattedMessage, injectIntl } from 'react-intl';
import { toast } from 'react-toastify';
import LinearProgress from '@mui/material/LinearProgress';

import CustomToolTip from '../../../components/CustomTooltip';
import { COMMON_PROP_TYPES } from '../../../common/constants/propTypes/propTypes';
import { drawActions } from '../../../store/ducks/draw.duck';

import { compress } from 'lz-string';
import { renderSketcher, deleteSketcher } from 'jsketcher';
import { ROUTES } from '../../../common/constants/routes/routes';
import { Modal, TextField, Button, List, ListItem, ListItemIcon, ListItemText, Collapse, Paper, Box, Divider, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText } from '@mui/material';
import { Folder, FolderOpen, InsertDriveFile } from '@mui/icons-material';
import { getFolders, getFolder, createFolder } from '../../../crud/partLibrary.crud';

import {
  ORDER_TYPE_LIB,
  ORDER_TYPE_SIMPLE,
  RESAVE,
} from '../../../common/constants/cadJSON';

import { MATERIAL_CATEGORIES } from '../../../common/constants/constants';

import initApi from '../../../crud/apiConfig/apiConfig';
import { API_URL, API_URLS } from '../../../common/constants/api/apiConstants';
import { priceCalculator } from '../../../partials/content/priceCalculator';
import  {convertDxfSimple} from '../../../crud/draw.crud'
import  { createFile, updateFile} from '../../../crud/partLibrary.crud'

const optionStyle = {
  menuPortal: (base) => ({
    ...base,
    zIndex: 9999,
  }),
  control: (base) => ({
    ...base,
    border: '1px solid #e2e5ec',
    borderRadius: '10px',
  }),
  // container: (base) => ({
  //   ...base,
  //   borderRadius: '10px'
  // })
};

class Sketcher extends React.Component {
  constructor() {
    super();
    this.material_list = [];
    this.state = {
      material: {},
      quantity: 1,
      total: 0,
      price: 0,
      materialOption: null,
      choiceOption: null,
      prevState: null,
      open: false,
      newFolderModalOpen: false,
      fileReplaceModalOpen: false,
      name: '',
      newFolderName: '',
      folders: [],
      selectedFolders: {},
      expandedFolders: [],
      currentFolderFiles: [],
      selectedFolderId: null,
      selectedFile: null,
      dxf_txt: null,
      draftFolderId: null,
      draftFileId: null,
      error: false,
      errorMessage: '',
    };

    this.to_configurator = this.to_configurator.bind(this);
  }


  async componentDidMount() {
    this.fetchFolders();
    // setInterval(() => {this.createOrUpdateDrafts()}, 3000)

    this.globVar = Object.keys(window);

    if (window.location.pathname === ROUTES.SKETCHER) {
      await renderSketcher();
    }
    const { materialDataRequest } = this.props; // match must be distructed from props

    const { data: globData } = await initApi().get(
      API_URL + API_URLS.GET_MATERIALS_DATA
    );
    const { data: choicesData } = await initApi().get(
      API_URL + API_URLS.MATERIAL_DATA
    );

    const { data: hourlyRates } = await initApi().get(
      API_URL + API_URLS.GET_HOURLY_RATES_DATA
    );
    
    this.setState( prevState => ({ ...prevState, hourlyRates }) );
    materialDataRequest();

    window.cutwise = {
      ...(window.cutwise ? window.cutwise : {}),
      to_checkout: (DXF_TEXT) => this.to_checkout(DXF_TEXT),
      save: (DXF_TEXT) => this.save(DXF_TEXT),
      save_as: (DXF_TEXT) => this.save_as(DXF_TEXT),
      to_configurator: (DXF_TEXT) => this.to_configurator(DXF_TEXT),
      calculate_price: (length, area) => this.calculate_price(length, area),

      isChanged: false,
      isSpecified: false,
    };

    this.viewer = window.cutwise.viewer;

    if ( this.viewer ) {
      this.viewer.fit();
      this.viewer.refresh();
    }


    // this.material_list = this.adoptMaterial(materialdata);

    this.choices_list = this.adoptMaterial(choicesData, globData);
    this.globVarAftImp = Object.keys(window);

    if (
      window.cutwise?.DXF_PROJECT?.material.name &&
      !this.state.choiceOption &&
      !this.state.materialOption &&
      this.material_list
    ) {

      const { material: materialObject } = window.cutwise.DXF_PROJECT;
      const choiceOption = this.choices_list[materialObject.name].choices.find( choice => choice.id === materialObject.id );


      const materialOption = {
        key: materialObject.name,
        label: <span>{materialObject.name}</span>,
        value: materialObject.name,
        isDisabled: false,
      };

      this.setState((prevState) => ({
        ...prevState,
        materialOption,
        choiceOption,
      }));

      window.cutwise.viewer.refresh();
    }

    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  componentDidUpdate() {
    const {
      materialData,
      currentSvg,
      updateDrawSVGarray,
      updateNewFile,
      history,
      newFile,
    } = this.props;

    const materialOptions = [];
    let foundApplicable = false;

    // console.log(this.state)

    if (
      materialData.materials &&
      materialData.materials.length &&
      this.material_list.length === 0
    ) {
      MATERIAL_CATEGORIES.map((optGroup) => {
        let optList = {
          label: this.props.intl.formatMessage({ id: optGroup.title }),
          options: [],
        };
        materialData.materials.map((item) => {
          if (item.category == optGroup.id) {
            for (let choice of item.choices) {
              let measurements = choice.measurement.split('x');
              let width = measurements[0];
              let height = measurements[1];
              if (
                (currentSvg?.viewBox?.width <= width &&
                  currentSvg?.viewBox?.height <= height) ||
                (currentSvg?.viewBox?.width <= height &&
                  currentSvg?.viewBox?.height <= width)
              ) {
                foundApplicable = true;
              }
            }

            optList.options.push({
              key: item.name,
              label: (
                <div
                  style={{
                    textDecoration: !foundApplicable ? 'line-through' : 'unset',
                  }}
                >
                  {!foundApplicable && (
                    <CustomToolTip
                      id="applicable"
                      effect="solid"
                      position="top"
                      tooltipText={
                        <FormattedMessage
                          id={'CONFIGURATOR.IS_NOT_APPLICABLE'}
                        />
                      }
                    >
                      <button
                        data-tip
                        data-for="applicable"
                        style={{
                          border: 'none',
                          background: 'transparent',
                        }}
                      >
                        <BiInfoCircle
                          size={18}
                          cursor={'pointer'}
                          color={'#b6b6b6'}
                        />
                      </button>
                    </CustomToolTip>
                  )}

                  {this.getMaterialName(item)}
                </div>
              ),
              value: item.name,
              isDisabled: !foundApplicable ? true : false,
            });
          }
        });

        materialOptions.push(optList);
        // currentSvg.options = materialOptions;
      });
      this.material_list = materialOptions;
      this.forceUpdate();
    }

    if (Object.keys(newFile).length > 0 && newFile.type === ORDER_TYPE_SIMPLE) {
      history.push(`/checkout/${newFile.project_id}`);

      updateNewFile({});
      updateDrawSVGarray([]);
    }
  }

  getMaterialName = (item) => {
    const { intl } = this.props;
    let material_name = item.name;
    if (intl.locale == 'en') {
      material_name = item.name_en ? item.name_en : material_name;
    }
    if (intl.locale == 'de') {
      material_name = item.name_de ? item.name_de : material_name;
    }
    return material_name;
  };

  componentWillUnmount() {
    queueMicrotask(() => deleteSketcher());

    window.cutwise.DXF_PROJECT = null;

    this.globVarAftImp?.forEach((gVar) => {
      if (!this.globVar.includes(gVar)) {
        delete window[gVar];
      }
    });
  }

  async loadProject(projectId) {
    const { uploadedSvgs, getProject, deleteSvg } = this.props;

    for (let uploadedSvg of uploadedSvgs) {
      deleteSvg(uploadedSvg.id);
    }
    getProject(projectId);
  }

  adoptMaterial(materialData, globData) {

    const adoptedGlobData = {};
    globData.forEach(
      (item) =>
        (adoptedGlobData[item.id] = {
          price: Number(item.price),
          cuttingSpeed: item.cutting_speed,
        })
    );

    const adopted = {};

    materialData.materials.forEach(
      (item) =>
        (adopted[item.name] = {
          ...item,
          choices: item.choices.map((choice) => ({
            ...choice,
            key: choice.id,
            price: adoptedGlobData[choice.id].price,
            cuttingSpeed: adoptedGlobData[choice.id].cuttingSpeed,
            value: `${choice.thickness}  ${choice.id}`,
            name: item.name,
            label: (
              <div style={{ display: 'flex' }}>
                <span
                  dangerouslySetInnerHTML={{ __html: choice.icon }}
                  style={{ marginLeft: '5px' }}
                ></span>
                <span>{choice.thickness + ` mm ${choice.color}`}</span>
              </div>
            ),
          })),
        })
    );

    return adopted;
  }

  to_checkout = (DXF_TEXT) => {
    const { createProject } = this.props;
    const { choiceOption, quantity } = this.state;
    const name = 'FROM CUTWISE CAD.dxf';
    const ARRAY_DXF = [{ DXF_TEXT, name, quantity: Number(quantity), materialObject: choiceOption }];
    const err_message = 'Please fill material and quantity';

    if (choiceOption && quantity) {
      const jsketcherDIV = document.getElementById('jsketcher-iframe');
      if ( jsketcherDIV.style.position === 'absolute' ) {
        this.viewer.applicationContext.actions.fullscreen.invoke(this.viewer.applicationContext);
      }

      createProject(ARRAY_DXF, ORDER_TYPE_SIMPLE);
    }
    else
      toast.error(err_message, {
        position: 'bottom-right',
        autoClose: 2500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
  };
  save = async (DXF_TEXT) => {

    const { history } = this.props;
    const { choiceOption: materialObject } = this.state;

    const file_id = window.cutwise.DXF_PROJECT?.file_id;
    const folderId = window.cutwise.DXF_PROJECT?.folderId ;
    const name = window.cutwise.DXF_PROJECT?.name;


    const convertedDxf = await convertDxfSimple(DXF_TEXT, 'dxf', true);

    try {
      await updateFile(file_id, {
        material: materialObject && materialObject.id,
        name: name,
        svg_path: convertedDxf.headers['x-svg-path'],
        dxf_path: convertedDxf.headers['x-dxf-path'],
      })
      
      const jsketcherDIV = document.getElementById('jsketcher-iframe');
      if ( jsketcherDIV.style.position === 'absolute' ) {
        this.viewer.applicationContext.actions.fullscreen.invoke(this.viewer.applicationContext);
      } 
    } catch (error) {
      console.error('Error updating file:', error);
    }

    history.push(`/part-library/${folderId}`);
  };

  save_as = async (DXF_TEXT) => {
    this.setState({dxf_txt: DXF_TEXT})

    this.setState({ open: true });

  };


   replace_file(){
     this.save(this.state.dxf_txt, this.state.selectedFile.name, this.state.selectedFile.id, this.state.selectedFolderId )
   }


  save_as_action  = async (isDraft = false) => {

    const { history } = this.props;


    const name = isDraft ? 'draft' : this.state.name;
    const { choiceOption: materialObject } = this.state;

    const folderId =  this.state.selectedFolderId;

    const convertedDxf = await convertDxfSimple(this.state.dxf_txt, 'dxf', true);

    const file = await createFile( {
      material: materialObject && materialObject.id,
      name: `${name}.dxf`,
      svg_path: convertedDxf.headers['x-svg-path'],
      dxf_path: convertedDxf.headers['x-dxf-path'],
      folder: (folderId !== "main") ? folderId : null,
    })

    this.setState({draftFileId: file.data.id})
    const jsketcherDIV = document.getElementById('jsketcher-iframe');
    if ( jsketcherDIV.style.position === 'absolute' ) {
      this.viewer.applicationContext.actions.fullscreen.invoke(this.viewer.applicationContext);
    }
    isDraft ? '' : history.push(`/part-library/${folderId ? folderId : 'main'}`);
  }

  to_configurator = async (DXF_TEXT) => {
    const { convertDxf, drawLoading, history, uploadedSvgs } = this.props;
    const lastOne = true;
    let name = 'FROM CUTWISE CAD.dxf';
    const quantity_from_CAD = uploadedSvgs.filter((SVG) =>
      SVG.name.includes(name)
    ).length;
    name = quantity_from_CAD >= 1 ? `${name}-${quantity_from_CAD + 1}` : name;

    const { choiceOption: materialObject } = this.state;
    await convertDxf(
      compress(DXF_TEXT),
      name,
      drawLoading,
      true,
      lastOne,
      materialObject
    );
    history.push(`/configurator`);
  };

  calculate_price = (length, area) => {
    const { quantity, choiceOption, hourlyRates } = this.state;

    if ((quantity, choiceOption)) {
      const {
        price: materialPrice,
        cuttingSpeed: cuttingSpeed,
      } = choiceOption;

      const loweRate = hourlyRates.find( rate => rate.deliveryDays === 14 );

      // console.log({
      //   area,
      //   length,
      //   materialPrice,
      //   cuttingSpeed,
      //   quantity,
      //   rate: Number(loweRate.rate),
      //   minimalRate: Number(loweRate.minimumPrice),
      // });

      const total = priceCalculator({
        area,
        length,
        materialPrice,
        cuttingSpeed,
        quantity,
        rate: Number(loweRate.rate),
        minimalRate: Number(loweRate.minimumPrice),
      });


      this.setState((prevState) => ({
        ...prevState,
        price: (total / quantity).toFixed(2),
        total,
        length,
        area
      }));
    }
  };
  fetchFolders = async () => {
    const folders = await getFolders();
    this.setState({ folders: folders.data.child_folders });
  };

  fetchFolderContent = async (folderId) => {
    const folder = await getFolder(folderId);
    return {
      folders: folder.data.child_folders.map(f => ({ ...f })),
      files: folder.data.folder[0].files || [],
    }
  };

  handleQuantitty = (event) => {
    event.persist();
    const {choiceOption, hourlyRates, area, length} = this.state;
    const loweRate = hourlyRates.find( rate => rate.deliveryDays === 14 );
    if(choiceOption) {
      const { price: materialPrice, cuttingSpeed: cuttingSpeed } = choiceOption;

      const total = priceCalculator({
        area,
        length,
        materialPrice,
        cuttingSpeed,
        quantity: event.target.value,
        rate: Number(loweRate.rate),
        minimalRate: Number(loweRate.minimumPrice),
      });

      let price = (total / event.target.value).toFixed(2);
      price = isNaN(price) ? 0 : price  ;

      this.setState((prevState) => ({
        ...prevState,
        quantity: event.target.value,
        price,
        total,
      }));
    }

    else {
      this.setState((prevState) => ({
        ...prevState,
        quantity: event.target.value,
      }));
    }
  }

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleOnSave = () => {
    this.save_as_action()
    this.setState({ open: false });
  };

  handleNameChange = (event) => {

    const name = event.target.value;
    const forbiddenCharacters = /[\/\\]/; // Regular expression to match "/" and "\"

    if (forbiddenCharacters.test(name)) {
      this.setState({
        error: true,
        errorMessage: 'Filename cannot contain "/" or "\\" characters.',
      });
    } else {
      this.setState({
        name: name,
        error: false,
        errorMessage: '',
      });
    }

    // this.setState({ name: event.target.value });
  };

  handleNewFolderNameChange = (event) => {
    this.setState({ newFolderName: event.target.value });
  };

  handleToggleFolder = async (folderId) => {
    const { expandedFolders, selectedFolders } = this.state;
    const isExpanded = expandedFolders.includes(folderId);

    if (isExpanded) {
      // Collapse folder
      this.setState({
        expandedFolders: expandedFolders.filter(id => id !== folderId),
        currentFolderFiles: [],
        selectedFolderId: null,
      });
    } else {
      // Expand folder and fetch its content if not already fetched
      const content = await this.fetchFolderContent(folderId);
      this.setState({
        selectedFolders: { ...selectedFolders, [folderId]: content.folders },
        currentFolderFiles: content.files || [],
        selectedFolderId: folderId,
      });

      this.setState({ expandedFolders: [...expandedFolders, folderId] });
    }
  };

  createDraftFolder = async () => {

  const folder =   await createFolder({ parent_id: null, name: 'DRAFT' });
    this.setState({ draftFolderId: folder.data.id});
  }

  createOrUpdateDrafts = async () => {
    console.log('stime')
    console.log(window.cutwise, 'window.cutwise.DXF_TEXT')

    if(!window.cutwise.DXF_TEXT)
    {
      return
    }

     if(!this.state.draftFolderId)
       {
         this.createDraftFolder()
       }

    if(this.state.draftFolderId) {
      if(this.state.draftFileId) {
        this.save(window.cutwise.DXF_TEXT, 'draft', draftFileId, draftFolderId)
      }
      if(!this.state.draftFileId) {
        this.save_as_action(true)


      }
    }
  }


  handleAddFolder = async () => {
    const { expandedFolders, newFolderName } = this.state;
    const parentId = expandedFolders[expandedFolders.length - 1];

    if (newFolderName) {
      await createFolder({ parent_id: parentId, name: newFolderName });
      this.fetchFolders();
    }

    this.setState({ newFolderModalOpen: false, newFolderName: '' });
  };

  openNewFolderModal = () => {
    this.setState({ newFolderModalOpen: true });
  };

  closeNewFolderModal = () => {
    this.setState({ newFolderModalOpen: false, newFolderName: '' });
  };

  openFileReplaceModal = (file) => {
    this.setState({ fileReplaceModalOpen: true, selectedFile: file });
  };

  closeFileReplaceModal = () => {
    this.setState({ fileReplaceModalOpen: false, selectedFile: null });
  };

  handleReplaceFile = () => {
    // Handle file replacement logic
    this.replace_file()
    this.setState({ fileReplaceModalOpen: false, selectedFile: null });
  };

  renderFolder = (folder, level = 0) => {
    const { expandedFolders, selectedFolders, selectedFolderId } = this.state;
    const isExpanded = expandedFolders.includes(folder.id);
    const isSelected = selectedFolderId === folder.id;
    const childFolders = selectedFolders[folder.id] || [];
    const folderFiles = folder.files || [];

    return (
        <div key={folder.id}>
          <ListItem
              button
              onClick={() => this.handleToggleFolder(folder.id)}
              style={{
                paddingLeft: level * 20,
                backgroundColor: isSelected ? '#e3f2fd' : 'transparent',
              }}
          >
            <ListItemIcon>
              {isExpanded ? <FolderOpen /> : <Folder />}
            </ListItemIcon>

            <ListItemText primary={folder.name} />
          </ListItem>
          <Divider style={{ marginLeft: level * 20 }} />
          <Collapse in={isExpanded} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {childFolders.length === 0 && folderFiles.length === 0 ? (
                  <ListItem>
                    <ListItemText primary="No child folders available." style={{ paddingLeft: (level + 1) * 20 }} />

                  </ListItem>
              ) : (
                  <>
                    {childFolders.map(childFolder => this.renderFolder(childFolder, level + 1))}
                  </>
              )}
            </List>
          </Collapse>
        </div>
    );
  };

  render() {
    const { open, name, newFolderName, folders, newFolderModalOpen, currentFolderFiles, fileReplaceModalOpen, selectedFile } = this.state;

    if (this.props.projectCreation)
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddingTop: '70px',
            fontFamily: 'Poppins',
            fontWeight: 600,
            fontSize: '40px',
            color: '#404040d4',
          }}
        >
          <p> You will be redirected to checkout page please wait </p>
          <span style={{ width: '100%', marginTop: '40px' }}>
            <LinearProgress />
          </span>
        </div>
      );

    return (
        <>
          <div>
            {/*<Button variant="contained" color="primary" onClick={this.handleOpen} style={{backgroundColor: '#D80000'}}>*/}
            {/*  Open Modal*/}
            {/*</Button>*/}
            <Modal open={open} onClose={this.handleClose}>
              <Paper style={{
                padding: 25,
                background: '#fff',
                margin: '150px auto',
                maxWidth: 1200,
                display: 'flex',
                flexDirection: 'column',
                gap: 20
              }}>
                <h2>My Part Library</h2>

                <Button variant="contained" onClick={this.openNewFolderModal}
                        style={{width: '26%', backgroundColor: '#D80000'}}>
                  Add Folder +
                </Button>

                <Box display="flex" gap={2}>
                  <List component="nav"
                        style={{minWidth: 300, borderRight: '1px solid #ccc', overflowY: 'auto', maxHeight: 400}}>
                    {folders.map(folder => this.renderFolder(folder))}

                  </List>
                  <Box flex={1}>
                    {currentFolderFiles.length > 0 && (
                        <Box display="flex" flexWrap="wrap" gap={2} style={{maxHeight: 200, overflowX: 'auto'}}>
                          {currentFolderFiles.map(file => (
                              <ListItem
                                  key={file.id}
                                  button
                                  onClick={() => this.openFileReplaceModal(file)}
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    maxWidth: '200px',
                                  }}
                              >

                                <ListItemIcon>
                                  <InsertDriveFile style={{fontSize: 40, color: '#D80000'}}/>
                                </ListItemIcon>
                                <ListItemText
                                    primary={file.name}
                                    style={{
                                      marginLeft: 8,
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      whiteSpace: 'nowrap'
                                    }}
                                />

                              </ListItem>
                          ))}
                        </Box>
                    )}


                  </Box>
                </Box>

                <TextField
                label="Save as"
                error={this.state.error}
                helperText={this.state.errorMessage}
                value={this.state.name}
                onChange={this.handleNameChange}
                margin="normal"
                variant="outlined"
                size="small"
                style={{ width: '26%' }}
                className="save-as-text-field"
              />
                <Button variant="contained" color="primary" onClick={this.handleOnSave}
                disabled={this.state.name.length ? false : true}
                        style={{backgroundColor: '#D80000', width: '26%'}}>
                  Save as
                </Button>

              </Paper>
            </Modal>

            <Dialog open={newFolderModalOpen} onClose={this.closeNewFolderModal}>
              <DialogTitle>Add New Folder</DialogTitle>
              <DialogContent>
                <TextField
                    className="save-as-text-field"
                    label="New Folder Name"
                    value={newFolderName}
                    onChange={this.handleNewFolderNameChange}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={this.closeNewFolderModal} style={{color: '#D80000'}}>
                  Cancel
                </Button>
                <Button onClick={this.handleAddFolder} color="primary">
                  Add
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog open={fileReplaceModalOpen} onClose={this.closeFileReplaceModal}>
              <DialogTitle>Replace File</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Are you sure you want to replace the file "{selectedFile?.name}"?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.closeFileReplaceModal} style={{color: '#D80000'}}>
                  Cancel
                </Button>
                <Button onClick={this.handleReplaceFile} color="primary">
                  Replace
                </Button>
              </DialogActions>
            </Dialog>
          </div>

          <div
              id="material-bar"
              style={{
                display: 'flex',
                paddingBottom: '10px',
                alignItems: 'center',
              }}
          >
            <div style={{zIndex: 4, color: '#000', padding: 0, width: '200px'}}>
              <Select
                  placeholder={this.props.intl.formatMessage({
                    id: 'MATERIAL',
                  })}
                  onChange={(event) =>
                      this.setState((prevState) => ({
                        ...prevState,
                        materialOption: event,
                        choiceOption: null,
                      }))
                  }
                  options={this.material_list}
                  isOptionDisabled={(option) => option.isDisabled}
                  styles={optionStyle}
                  value={this.state.materialOption}
                  // onClick={}
                  // menuPortalTarget={document.body}
              />
            </div>

            <div
                style={{
                  zIndex: 4,
                  color: '#000',
                  width: '200px',
                  margin: '0 10px',
                }}
            >
              <Select
                  placeholder={this.props.intl.formatMessage({
                    id: 'MATERIAL',
                  })}
                  onChange={(event) => {
                    this.setState((prevState) => ({
                      ...prevState,
                      choiceOption: event,
                    }));
                    this.viewer.refresh.apply(this.viewer);
                  }}
                  options={
                    this.state.materialOption && this.choices_list
                        ? this.choices_list[this.state.materialOption.key]?.choices
                        : []
                  }
                  isOptionDisabled={(option) => option.isDisabled}
                  styles={optionStyle}
                  value={this.state.choiceOption}
                  // onClick={}
                  // menuPortalTarget={document.body}
              />
            </div>

            <input
                className="form-control"
                style={{width: '200px', height: '38px'}}
                type="number"
                placeholder={this.props.intl.formatMessage({
                  id: 'CONFIGURATOR.QUANTITY',
                })}
                onChange={this.handleQuantitty}
                value={this.state.quantity}
            />

            <span
                style={{
                  width: 'fit-content',
                  color: 'rgb(66, 160, 97)',
                  fontFamily: 'Poppins',
                  fontSize: '20px',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  margin: '0px 10px 0 20px',
                }}
            >
            <span
                style={{
                  width: 'fit-content',
                  display: 'inline-block',
                  fontSize: '15px',
                  color: '#404040',
                  fontWeight: 900,
                  textShadow:
                      'rgba(0, 0, 0, 0.19) 0px 0px 8px, rgba(0, 0, 0, 0.23) 0px 6px 6px',
                  marginRight: '15px',
                }}
            >
              Price
            </span>
              {`€ ${this.state.price}`}
           </span>

            <span
                style={{
                  width: 'fit-content',
                  color: 'rgb(66, 160, 97)',
                  fontFamily: 'Poppins',
                  fontSize: '20px',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  margin: '0px 20px 0 10px',
                }}
            >
            <span
                style={{
                  width: 'fit-content',
                  display: 'inline-block',
                  fontSize: '15px',
                  color: '#404040',
                  fontWeight: 900,
                  textShadow:
                      'rgba(0, 0, 0, 0.19) 0px 0px 8px, rgba(0, 0, 0, 0.23) 0px 6px 6px',
                  marginRight: '15px',
                }}
            >
              Total
            </span>
              {`€ ${this.state.total}`}
            </span>

            <Button 
              variant="contained"
              style={{
                fontFamily: 'Poppins',
                fontWeight: 900,
                backgroundColor: '#FF0000',
                boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                fontSize: '12px',
                borderRadius: '8px',
                marginLeft: 'auto'
                }}
              onClick={() => this.viewer?.applicationContext.actions['To checkout'].invoke(this.viewer.applicationContext) }
              >
                To checkout
              </Button>
          </div>

          <div
              id="jsketcher-iframe"
              style={{
                width: '100%',
                height: '75vh',
                padding: '0px',
                minHeight: '700px',
                zIndex: 5,
              }}
          >
            Loading
          </div>
        </>
    );
  }
}

Sketcher.propTypes = {
  user: PropTypes.object,
  uploadedSvgs: PropTypes.arrayOf(COMMON_PROP_TYPES.CURRENT_SVG),
  currentSvg: COMMON_PROP_TYPES.CURRENT_SVG,
  isProcessing: PropTypes.bool.isRequired,
  materialData: PropTypes.object.isRequired,
  error: PropTypes.string,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  selectedMaterial: PropTypes.object.isRequired,
  selectMaterialType: PropTypes.func.isRequired,
  selectQuantity: PropTypes.func.isRequired,
  addMaterial: PropTypes.func.isRequired,
  setDraw: PropTypes.func.isRequired,
  selectCurrentSvg: PropTypes.func.isRequired,
  selectBatchMaterial: PropTypes.func,
  deleteSvg: PropTypes.func.isRequired,
  convertDxf: PropTypes.func.isRequired,
  saveProducts: PropTypes.func.isRequired,
  getProduct: PropTypes.func.isRequired,
  loadingUpdate: PropTypes.func.isRequired,
  loadingProgressUpdate: PropTypes.func.isRequired,
  dataProducts: PropTypes.object,
  isEditing: PropTypes.bool,
  saveChangeProduct: PropTypes.func.isRequired,
  editProductId: PropTypes.string,
  isMainPage: PropTypes.bool,
  materialDataRequest: PropTypes.func.isRequired,
};

const mapStateToProps = ({
                           auth: {user},
                           draw: {
                             newFile,
                             projectCreation,
                             uploadedSvgs,
                             currentSvg,
                             error,
                             editProductId,
                             materialData,
                             selectedMaterial,
                             isProcessing,
                             dxf,
                             dxf_original,
                             loading,
                             loadingProgress,
                           },
                           quotes: {
                             selectedQuoteDetails: {data},
                           },
                         }) => ({
  newFile,
  projectCreation,
  user,
  uploadedSvgs,
  currentSvg,
  error,
  materialData,
  selectedMaterial,
  isProcessing,
  dataProducts: data,
  editProductId,
  dxf,
  dxf_original,
  drawLoading: loading,
  loadingProgress,
});

const mapDispatchToProps = {
  setDraw: (svg, name) =>
      drawActions.setDraw({
        svg,
        name,
      }),
  getProject: (id) => drawActions.getEditProject(id),
  getProduct: (id) => drawActions.getEditProduct(id),
  selectCurrentSvg: (id, extraState) =>
      drawActions.selectCurrentSvg(id, extraState),
  deleteSvg: drawActions.deleteSvg,
  convertDxf: (
      data,
      name,
      loading,
      withAbort,
      lastOne,
      materialObject,
      quantity
  ) =>
      drawActions.convertDxfRequest(
          data,
          name,
          loading,
          withAbort,
          lastOne,
          materialObject,
          quantity
      ),
  addMaterial: (data, id, svgId) => drawActions.selectMaterial(data, id, svgId),
  selectMaterialType: (data, id, svgId) =>
      drawActions.selectMaterialType(data, id, svgId),
  selectBatchMaterial: (material) => drawActions.selectBatchMaterial(material),
  selectQuantity: (data, id) => drawActions.addQuantity(data, id),
  saveProducts: (data, history, params) =>
      drawActions.saveProductsRequest({
        data,
        history,
        params,
      }),
  saveChangeProduct: (id, data, history) =>
      drawActions.saveChangeProductRequest({
        id,
        data,
        history,
      }),
  loadingUpdate: (status, svgNames) =>
      drawActions.loadingUpdate(status, svgNames),
  loadingProgressUpdate: (name, loadingObject) =>
      drawActions.loadingProgressUpdate(name, loadingObject),
  createProject: (ARRAY_DXF, type, fileType) =>
      drawActions.createProject(ARRAY_DXF, type, fileType),
  materialDataRequest: () => drawActions.materialDataRequest(),
  updateNewFile: (urls) => drawActions.updateNewFile(urls),
  updateDrawSVGarray: (uploadedSvgs) =>
      drawActions.updateDrawSVGarray({SVG: uploadedSvgs}),
};

export default injectIntl(
    connect(mapStateToProps, mapDispatchToProps)(withRouter(Sketcher))
);
