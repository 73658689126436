import { call, put, takeEvery } from "redux-saga/effects";

import {
  getMaterialQuery,
  updateMaterialQuery,
  getMaterialsQuery,
  deleteMaterialQuery
} from "./crud";
import {
  getMaterials,
  getMaterialsError,
  getMaterialsSuccess,
  updateMaterial,
  updateMaterialSuccess,
  updateMaterialError,
  deleteMaterial,
  deleteMaterialSuccess,
  deleteMaterialError,
  getMaterial,
  getMaterialError,
  getMaterialSuccess
} from "./actions";

function* fetchMaterialsSaga({ payload }) {
  try {
    const res = yield call(getMaterialsQuery, payload);

    yield put(getMaterialsSuccess(res.data));
  } catch (err) {
    yield put(getMaterialsError(err));
  }
}

function* fetchMaterialSaga({ payload }) {
  try {
    const { successCallBack = () => {} } = payload;

    const res = yield call(getMaterialQuery, payload);

    yield put(
      getMaterialSuccess({
        ...res.data,
        created_at: res.data.created_at
      })
    );

    yield successCallBack(res.data.type);
  } catch (err) {
    yield put(getMaterialError(err.response));
  }
}

function* updateMaterialSaga({ payload }) {
  try {
    const { successCallBack = () => {}, id, data } = payload;

    const res = yield call(updateMaterialQuery, { id, data });

    yield put(
      updateMaterialSuccess({
        ...res.data,
        created_at: res.data.created_at
      })
    );

    yield successCallBack(res.data.type);
  } catch (err) {
    yield put(updateMaterialError(err.response));
  }
}

function* deleteMaterialSaga({ payload }) {
  try {
    const { successCallBack = () => {}, id } = payload;

    const res = yield call(deleteMaterialQuery, id);

    yield put(
      deleteMaterialSuccess({
        ...res.data,
        created_at: res.data.created_at
      })
    );

    yield successCallBack(res.data.type);
  } catch (err) {
    yield put(deleteMaterialError(err.response));
  }
}

function* materialsSagas() {
  yield takeEvery(getMaterials, fetchMaterialsSaga);
  yield takeEvery(getMaterial, fetchMaterialSaga);
  yield takeEvery(updateMaterial, updateMaterialSaga);
  yield takeEvery(deleteMaterial, deleteMaterialSaga);
}

export default materialsSagas;
