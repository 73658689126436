import { createAction } from 'redux-actions';

export const getThreads = createAction('GET_THREADS_REQUEST');
export const getThreadsSuccess = createAction('GET_THREADS_SUCCESS');
export const getThreadsError = createAction('GET_THREADS_ERROR');
export const getThread = createAction('GET_THREAD_REQUEST');
export const getThreadSuccess = createAction('GET_THREAD_SUCCESS');
export const getThreadError = createAction('GET_THREAD_ERROR');
export const deleteThread = createAction('DELETE_THREAD_REQUEST');
export const deleteThreadSuccess = createAction('DELETE_THREAD_SUCCESS');
export const deleteThreadError = createAction('DELETE_THREAD_ERROR');
export const updateThread = createAction('UPDATE_THREAD_REQUEST');
export const updateThreadSuccess = createAction('UPDATE_THREAD_SUCCESS');
export const updateThreadError = createAction('UPDATE_THREAD_ERROR');
