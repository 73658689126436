import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { DOMAIN_LOCALES } from '../../app/common/constants/constants';

const actionTypes = {
  SetLanguage: 'i18n/SET_LANGUAGE',
};

const countries = DOMAIN_LOCALES.find(
  (item) => item.domain === window.location.hostname
);

const initialState = {
  lang: countries?.defaultLocale ? countries.defaultLocale : 'en',
};

const selectedLang = localStorage.getItem('lang')

let i18nStorageVal = JSON.parse(localStorage.getItem('persist:i18n')) || {};

if (!selectedLang) {
  i18nStorageVal.lang = initialState.lang;
}

if (i18nStorageVal?.lang === 'nl') {
  i18nStorageVal.lang =
    location.pathname.indexOf('lasercutting') > -1
      ? '"en"'
      : location.pathname.indexOf('laserschneiden') > -1
      ? '"de"'
      : '"nl"';
}

localStorage.setItem('persist:i18n', JSON.stringify(i18nStorageVal));

export const reducer = persistReducer(
  { storage, key: 'i18n' },
  (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.SetLanguage:
        return { ...state, lang: action.payload.lang };
      default:
        return state;
    }
  }
);

export const actions = {
  setLanguage: (lang) => ({ type: actionTypes.SetLanguage, payload: { lang } }),
};
