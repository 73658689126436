import React from 'react';
// import PropTypes from 'prop-types';
import { Spinner } from 'react-bootstrap';

function LoadingTag(props) {
  const value =
    props.children.length > 75
      ? `${props.children.slice(75)}...`
      : props.children;

  const { loadingUpdate, loading, loadingProgress, promiseAbort } = props;

  function abortUpdate() {
    promiseAbort();

    const filteredSvgs = loading.uploadingSvgs.filter(
      (nameWpromis) => nameWpromis.name !== props.children
    );
    const allowedLoad = filteredSvgs.length > 0;
    loadingUpdate(allowedLoad, filteredSvgs, null);
  }

  const percent = loadingProgress
    ? (loadingProgress?.loaded * 100) / loadingProgress?.total
    : 0;

  return (
    <div
      className="loading-tag"
      style={{
        background: `linear-gradient(to right, #ff7b74 0%, #ff7b74 ${percent}%, #ededed ${
          percent === 0 ? '100%' : percent
        }%, #ededed 100%)`,
      }}
    >
      <div style={{ color: '#34495e' }}>
        <Spinner animation="border" size="sm" role="status">
          <span className="sr-only ">Loading...</span>
        </Spinner>
        <p>{value}</p>
      </div>

      <div style={{ color: '#34495e' }}>
        <p>
          {loadingProgress?.download
            ? percent === 100
              ? 'SVG generation'
              : 'Downloading'
            : percent === 100
            ? 'waiting for server response'
            : 'Uploading'}
        </p>

        <p style={{ margin: '0 50px 0 0', width: '50px' }}>
          {isNaN(percent.toFixed(2)) ? '' : percent.toFixed(2)}%
        </p>

        <i onClick={abortUpdate} className="flaticon2-delete font-size-4" />
      </div>
    </div>
  );
}

export default LoadingTag;
