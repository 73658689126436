import { API_URLS } from '../../common/constants/api/apiConstants';
import initApi from '../../crud/apiConfig/apiConfig';

export function getThreadsQuery() {
  return initApi().get(API_URLS.GET_THREADS_DATA);
}

export const getThreadQuery = id => {
  return initApi().get(API_URLS.GET_THREAD_DETAILED(id));
};

export const deleteThreadQuery = id => {
  return initApi().delete(API_URLS.GET_THREAD_DETAILED(id));
};

export const updateThreadQuery = ({ id, data }) => {
  if (id) {
    return initApi().put(API_URLS.GET_THREAD_DETAILED(id), data);
  } else {
    return initApi().post(API_URLS.GET_THREADS_DATA, data);
  }
};
