import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { Paper } from "@material-ui/core";

const FormLayout = ({
  title = "",
  children,
  submitTitle = "Save",
  onSubmit = () => {},
  hideClose,
  closeTitle = "Cancel",
  onClose = () => {},
}) => {
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="kt-section">
          <div className="kt-section__content">
            <Paper className="p-5">
              {!!title && <h1 className="kt-invoice__title mb-5">{title}</h1>}

              {children}

              <Row>
                <Col sm={12}>
                  <Button
                    className="pull-right"
                    type="submit"
                    onClick={onSubmit}
                  >
                    {submitTitle}
                  </Button>
                  {!hideClose && (
                    <Button
                      className="pull-right mr-2"
                      variant="light"
                      onClick={onClose}
                    >
                      {closeTitle}
                    </Button>
                  )}
                </Col>
              </Row>
            </Paper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormLayout;
