import { handleActions } from "redux-actions";
import {
  getCountries,
  getCountriesSuccess,
  getCountriesError,
  getCountry,
  getCountrySuccess,
  getCountryError,
  updateCountry,
  updateCountrySuccess,
  updateCountryError,
  deleteCountry,
  deleteCountrySuccess,
  deleteCountryError,
} from "./actions";

const initialState = {
  isProcessing: false,
  data: [],
  error: "",
  pagination: {
    per_page: 10,
    current_page: 1,
    count: 0
  },
  detailedCountry: {
    isProcessing: false,
    data: null,
    status: {
      isProcessing: false
    }
  },
};

const handlers = {
  [getCountries]: (state) => ({
    ...state,
    isProcessing: true,
  }),
  [getCountriesSuccess]: (state, action) => ({
    ...state,
    isProcessing: false,
    data: action.payload,
    pagination: {
      ...state.pagination,
      count: (action.payload && action.payload.length) || 0
    }
  }),
  [getCountriesError]: (state, action) => ({
    ...state,
    isProcessing: false,
    error: action.payload
  }),
  [getCountry]: (state) => ({
    ...state,
    detailedCountry: {
      ...state.detailedCountry,
      isProcessing: true
    }
  }),
  [getCountrySuccess]: (state, action) => ({
    ...state,
    detailedCountry: {
      ...state.detailedCountry,
      isProcessing: false,
      data: action.payload
    }
  }),
  [getCountryError]: (state, action) => ({
    ...state,
    detailedCountry: {
      ...state.detailedCountry,
      isProcessing: false,
      error: action.payload
    }
  }),
  [updateCountry]: (state) => ({
    ...state,
    detailedCountry: {
      ...state.detailedCountry,
      isProcessing: true,
    }
  }),
  [updateCountrySuccess]: (state) => ({
    ...state,
    detailedCountry: {
      ...initialState.detailedCountry,
      isProcessing: false,
    }
  }),
  [updateCountryError]: (state, action) => ({
    ...state,
    detailedCountry: {
      ...state.detailedCountry,
      isProcessing: false,
      status: {
        ...state.detailedCountry.status,
        error: action.payload
      }
    }
  }),
  [deleteCountry]: (state) => ({
    ...state,
  }),
  [deleteCountrySuccess]: (state) => ({
    ...state,
  }),
  [deleteCountryError]: (state) => ({
    ...state,
  }),
};

export default handleActions(handlers, initialState);
