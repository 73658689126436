// import React from "react";
// import utils from "svg-path-reverse"
import {digits, pointMatchingDistance} from "./Config";

// var reverse = utils.reverse, normalize = utils.normalize;

class Converter {

    convertPathsToModels = async (paths, circles) => {
        let singlePath = this.pathsToSingePath(paths, circles);
        let modelsBundled = await this.convertToModelWrappers(singlePath);
        let cleanedUpModels = await this.removeRedundantMoveToElements(modelsBundled);
        let newModels = await this.removeDuplicates(cleanedUpModels);
        let models = await this.writeModels(newModels);

        return models;
    }

    circleToPath = (cx, cy, r) => {
        cx = parseFloat(cx)
        cy = parseFloat(cy)
        r = parseFloat(r)

        return 'M' + (cx - (r)) + ' ' + cy + 'A' + r + ' ' + r + ' 0 1 0 ' + (cx + (r)) + ' '+cy+'A' + r + ' ' + r + ' 0 1 0 ' + (cx - (r)) + ' ' +cy+ 'Z';
    }

    getSvgElements = (svgContent) => {

        const parser = new DOMParser();
        const doc = parser.parseFromString(svgContent, 'image/svg+xml');
        const paths = doc.getElementsByTagName("path");
        const circles = doc.getElementsByTagName("circle");
        const svg = doc.getElementsByTagName("svg")[0];

        //TBD : More groups
        const g = doc.getElementsByTagName("g")[0];
        const width = svg.getAttribute('width');
        const height = svg.getAttribute('height');
        const viewBox = svg.getAttribute('viewBox');
        const transform = g.getAttribute('transform');

        return {paths, circles, width, height, viewBox, transform};
    }
    // Not needed, TBD delete
    pathsToSingePath = (paths, circles) => {
        let singlePath = "";
        for (let path of paths) {

            if (!singlePath.includes(path.getAttribute('d'))) {
                singlePath += path.getAttribute('d');
            }
        }

        //@ts-ignore
        for (let circle of circles) {
            let cx = circle.getAttribute('cx');
            let cy = circle.getAttribute('cy')
            let r = circle.getAttribute('r');

            singlePath += this.circleToPath(cx, cy, r);
        }

        return singlePath;
    }

    walkThroughArrayAndRemoveDuplicatesReversed = async (models) => {
        let newModelsAfterReverse = [];
        let modelFrom = undefined;
        // eslint-disable-next-line no-unused-vars
        for (let [modelKey, model] of await Object(models).entries()) {

            newModelsAfterReverse.map((currentModel, index) => {

                let currentModelFrom = newModelsAfterReverse[index - 1];
                let currentModelNext = newModelsAfterReverse[index + 1];

                if (model?.type == "L" && currentModel.type == "L" ) {

                    if (
                        currentModelFrom.x == model.x &&
                        currentModelFrom.y == model.y &&
                        modelFrom.x == currentModel.x &&
                        modelFrom.y == currentModel.y
                    ) {
                        model.duplicateReverse = true;
                    }

                }

                if (model?.type == "A" && currentModel.type == "A") {

                    if (
                        model.rx == currentModel.rx &&
                        model.ry == currentModel.ry &&
                        model.x_axis_rotation == currentModel.x_axis_rotation &&
                        model.large_arc_flag == currentModel.large_arc_flag
                    ) {

                        if (
                            currentModelFrom.x == model.x &&
                            currentModelFrom.y == model.y &&
                            modelFrom.x == currentModel.x &&
                            modelFrom.y == currentModel.y
                        ) {

                            if (
                                (currentModel.sweep_flag == "0" && model.sweep_flag == "1") ||
                                (currentModel.sweep_flag == "1" && model.sweep_flag == "0")
                            ) {
                                model.duplicateArcReverse = true;
                            }
                        }
                    }
                }
                if (model?.type == "C" && currentModel.type == "C") {

                    if (
                        model.x1 == currentModel.x1 &&
                        model.y1 == currentModel.y1 &&
                        model.x2 == currentModel.x2 &&
                        model.y2 == currentModel.y2
                    ) {

                        if (
                            currentModelNext.x == model.x &&
                            currentModelNext.y == model.y &&
                            modelFrom.x == currentModel.x &&
                            modelFrom.y == currentModel.y
                        ) {
                            model.duplicateReverse = true;
                        }
                    }
                }
            })


            newModelsAfterReverse.push(model)
            modelFrom = model;
        }

        return models.filter((newModelsAfterReverse) => !newModelsAfterReverse.duplicateReverse && !newModelsAfterReverse.duplicateArcReverse);
    }

    walkThroughArrayAndRemoveDuplicates = async (models) => {
        // Check the natural direction, no mirror
        let newModels = []
        for (let [modelKey, model] of await Object(models).entries()) {
            let modelFrom = models[modelKey - 1];
            // let nextModel = models[modelKey + 1];


            if (model) {
                newModels.map((currentModel, index) => {

                    let currentModelFrom = newModels[index - 1];
                    // let currentModelNext = newModels[index + 1];

                    if (model?.x == currentModel.x && model?.y == currentModel.y && model?.type == "L") {

                        if (currentModelFrom) {
                            if (
                                currentModelFrom?.x == modelFrom.x &&
                                currentModelFrom.y == modelFrom.y
                            ) {
                                // WORKS
                                model.duplicate = true;
                            }
                        }
                    }

                    if (model?.type == "A" && currentModel?.type == "A") {
                        if (
                            model.rx == currentModel.rx &&
                            model.ry == currentModel.ry &&
                            model.x_axis_rotation == currentModel.x_axis_rotation &&
                            model.large_arc_flag == currentModel.large_arc_flag
                        ) {
                            // console.log("Same arc specs", "FROM", modelFrom, model)
                            if (
                                currentModelFrom?.x == modelFrom.x &&
                                currentModelFrom?.y == modelFrom.y &&
                                model.x == currentModel.x &&
                                model.y == currentModel.y
                            ) {
                                model.duplicateArc = true;
                            }

                        }
                    }

                    if (model?.type == "C" && currentModel?.type == "C") {
                        if (
                            model.x1 == currentModel.x1 &&
                            model.y1 == currentModel.y1 &&
                            model.x2 == currentModel.x2 &&
                            model.y2 == currentModel.y2

                        ) {
                            // console.log("Same arc specs", "FROM", modelFrom, model)
                            if (
                                currentModelFrom?.x == modelFrom.x &&
                                currentModelFrom?.y == modelFrom.y &&
                                model.x == currentModel.x &&
                                model.y == currentModel.y
                            ) {
                                model.duplicateArc = true;
                            }

                        }
                    }
                })
            }

            if (model) {
                newModels.push(model)
            }
        }

        return newModels.filter((newModel) => !newModel.duplicate && !newModel.duplicateArc);
    }

    removeLast = async (models) => {
        let newModelsAfterReverse = [...models];

        for (let [modelKey, model] of Object.entries(newModelsAfterReverse)) {
            if (newModelsAfterReverse[parseInt(modelKey) - 1]) {
                if (newModelsAfterReverse[parseInt(modelKey) - 1].type == "M" && model?.type == "M") {
                    newModelsAfterReverse[parseInt(modelKey) - 1].deleted = true
                }

                if (newModelsAfterReverse[parseInt(modelKey) - 1]?.x == model?.x && newModelsAfterReverse[parseInt(modelKey) - 1]?.y == model?.y) {
                    // console.log("Delete key", modelKey, model)
                    newModelsAfterReverse[parseInt(modelKey)].deleted = true;
                    if (newModelsAfterReverse[parseInt(modelKey) - 1].type == "M" && (newModelsAfterReverse[parseInt(modelKey) + 1]?.type == "M") || !newModelsAfterReverse[parseInt(modelKey) + 1]) {
                        newModelsAfterReverse[parseInt(modelKey) - 1].deleted = true;
                    }
                }

            }
        }

        return newModelsAfterReverse.filter((newModel) => !newModel.deleted);
    }

    removeDuplicates = async (models) => {

        let allDuplicatesRemoved = false;

        while (!allDuplicatesRemoved) {
            let modelsWithoutDuplicates = await this.walkThroughArrayAndRemoveDuplicates([...models]);
            let newModels = await this.walkThroughArrayAndRemoveDuplicatesReversed(modelsWithoutDuplicates);
            let newModelsWithDeleted = await this.removeLast(newModels);

            if (newModelsWithDeleted.length == models.length) {
                allDuplicatesRemoved = true;
            } else {
                models = [...newModelsWithDeleted] ;
            }
        }

        return models;
    }

    convertToModelWrappers = async (singlePath) => {

        let pathSegments = singlePath.split(/(?=[M])/g);
        let modelsBundled = [];

        for (let segment of pathSegments) {

            let elements = segment.split(/(?=[CLAQZ])/g);
            let firstModel = undefined;
            // let prevModel = undefined;
            for (let element of elements) {
                let model = undefined;


                if (element.includes("L")) {
                    let elementParts = element.includes(",") ? element.replace("L ", "").replace("L", "").split(",") : element.replace("L ", "").replace("L", "").split(" ");

                    let x = parseFloat(elementParts[0]).toFixed(digits);
                    let y = parseFloat(elementParts[1]).toFixed(digits);

                    model = {
                        x: x,
                        y: y,
                        type: "L"
                    };
                }

                if (element.includes("M")) {
                    let elementParts = element.includes(",") ? element.replace("M ", "").replace("M", "").split(",") : element.replace("M ", "").replace("M", "").split(" ");
                    let x = parseFloat(elementParts[0]).toFixed(digits);
                    let y = parseFloat(elementParts[1]).toFixed(digits);

                    model = {
                        x: x,
                        y: y,
                        type: "M"
                    };
                }

                if (element.includes("A")) {

                    let checkElement = element.replaceAll("A ", "").replaceAll("A", "");
                    checkElement = checkElement.replaceAll(",", " ");

                    let elementParts = checkElement.split(" ");

                    let radius_x = parseFloat(elementParts[0]).toFixed(digits);
                    let radius_y = parseFloat(elementParts[1]).toFixed(digits);
                    let x_axis_rotation = elementParts[2];
                    let large_arc_flag = elementParts[3];
                    let sweep_flag = elementParts[4];
                    let x = parseFloat(elementParts[5]).toFixed(digits);
                    let y = parseFloat(elementParts[6]).toFixed(digits);

                    model = {
                        rx: radius_x,
                        ry: radius_y,
                        x_axis_rotation: x_axis_rotation,
                        large_arc_flag: large_arc_flag,
                        sweep_flag: sweep_flag,
                        x: x,
                        y: y,
                        type: "A"
                    };
                }

                if (element.includes("Q")) {

                    let checkElement = element.replace("Q ", "").replace("Q", "");
                    let elementParts = checkElement.includes(",") ? checkElement.split(",") : checkElement.split(" ");
                    let x1 = parseFloat(elementParts[0]).toFixed(digits);
                    let y1 = parseFloat(elementParts[1]).toFixed(digits);
                    let x = parseFloat(elementParts[2]).toFixed(digits);
                    let y = parseFloat(elementParts[3]).toFixed(digits);

                    model = {
                        x1: x1,
                        y1: y1,
                        x: x,
                        y: y,
                        type: "Q"
                    };
                }

                if (element.includes("C")) {
                    let checkElement = element.replace("C ", "").replace("C", "");
                    checkElement = checkElement.replaceAll(",", " ")
                    let elementParts = checkElement.split(" ");

                    let x1 = parseFloat(elementParts[0]).toFixed(digits);
                    let y1 = parseFloat(elementParts[1]).toFixed(digits);
                    let x2 = parseFloat(elementParts[2]).toFixed(digits);
                    let y2 = parseFloat(elementParts[3]).toFixed(digits);
                    let x = parseFloat(elementParts[4]).toFixed(digits);
                    let y = parseFloat(elementParts[5]).toFixed(digits);

                    model = {
                        x1: x1,
                        y1: y1,
                        x2: x2,
                        y2: y2,
                        x: x,
                        y: y,
                        type: "C"
                    };
                }
                if (element.includes("Z")) {
                    if (firstModel) {
                        model = {
                            x: firstModel?.x,
                            y: firstModel?.y,
                            type: "L"
                        };
                    }
                }


                if (model) {
                    modelsBundled.push(model);
                    prevModel = model;

                    if (!firstModel) {
                        firstModel = model;
                    }
                }
            }
        }
        console.log("After first bundle, no distance check", modelsBundled)
        return modelsBundled;
    }

    removeRedundantMoveToElements = async (modelsBundled) => {

        for (let [modelKey, model] of modelsBundled.entries()) {

            // Don't check first element
            if (parseInt(modelKey) !== 0) {

                // In case of moveTo, check if it's the start of a close element to matching distance, so we can connect it
                let previousModel = modelsBundled[parseInt(modelKey) - 1];
                if (modelsBundled[parseInt(modelKey)].type === "M") {


                    let distance = this.getDistance(model.x, previousModel.x, model.y, previousModel.y);
                    if (distance <= pointMatchingDistance) {
                        modelsBundled[parseInt(modelKey)].type = "L";
                    }
                }

                if (model.type == "M" && previousModel.type == "M") {
                    console.log("delete ", modelsBundled[parseInt(modelKey) - 1], "next is ", modelsBundled[parseInt(modelKey)])
                    modelsBundled.splice(parseInt(modelKey) - 1, 1);
                }
            }
        }

        console.log("After redundant removed", modelsBundled)
        return modelsBundled;
    }

    getDistance = (x1, x2, y1, y2) => {
        let y = x2 - x1;
        let x = y2 - y1;

        return Math.sqrt(x * x + y * y);
    }

    writeModels = async (models) => {
        let mergedModels = [];
        let currentPath = undefined;
        let pathId = 1;
        for (let [idx, model] of models.entries()) {

            if (!currentPath) {
                if (model.type !== "M") {

                    currentPath = {
                        id: pathId,
                        path: "M " + model.x + " " + model.y,
                        startPoint: {x: model.x, y: model.y},
                        endPoint: {x: model.x, y: model.y},
                    }
                }
            }
            switch (model.type) {
                case "M":
                    currentPath && mergedModels.push(currentPath);
                    pathId++;
                    currentPath = {
                        id: pathId,
                        path: "M " + model.x + " " + model.y,
                        startPoint: {x: model.x, y: model.y},
                        endPoint: {x: model.x, y: model.y},
                    }

                    break;
                case "L":
                    currentPath.path += "L " + model.x + " " + model.y;
                    currentPath.endPoint = {x: model.x, y: model.y};

                    break;
                case "Q":
                    currentPath.path += "Q " + model.x1 + " " + model.y1 + " " + model.x + " " + model.y;
                    currentPath.endPoint = {x: model.x, y: model.y};
                    break;
                case "C":
                    currentPath.path += "C " + model.x1 + " " + model.y1 + " " + model.x2 + " " + model.y2 + " " + model.x + " " + model.y;
                    currentPath.endPoint = {x: model.x, y: model.y};
                    break;
                case "A":
                    currentPath.path += "A " + model.rx + " " + model.ry + " " + model.x_axis_rotation + " " + model.large_arc_flag + " " + model.sweep_flag + " " + model.x + " " + model.y;
                    currentPath.endPoint = {x: model.x, y: model.y};
                    break;
            }

            // Add the last
            if (idx == models.length - 1 && models[models.length - 1].type !== "M" && currentPath) {
                mergedModels.push(currentPath);
            }
        }


        return mergedModels;
    }

}

export default Converter;
