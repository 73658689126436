import React, { useState, useRef, useEffect } from 'react';
import { Menu, MenuItem } from '@mui/material';
import { FaRegFolder } from "react-icons/fa6";
import { GoKebabHorizontal } from "react-icons/go";
import {isNull} from 'lodash';

import './index.scss';

export default function FolderComponent({ folderList, onClick, uptFold, dltFolder }) {

    const [state, setState] = useState({ anchorEl: null, acting: { folderID: null, open: false, value: '' }, });
    const inputEdit = useRef(null);

    const handleClick = (e, folderID, folderName) => {
      setState(prevState => ({
        ...prevState,
        anchorEl: e.currentTarget,
        acting: { folderID, open: false, value: folderName } 
      }) );
    };
    
    const handleClose = () => {
      setState(prevState => ({
        ...prevState,
        anchorEl: null,
        acting: { folderID: null, open: false, value: null } 
      }) );
    };

    const deleteFolder = () => {
      dltFolder(state.acting.folderID)
      handleClose();
    }

    const renameStart = () => {
      setState(prevState => ({
        ...prevState, 
        anchorEl: null,
        acting: { ...prevState.acting, open: true } 
      }) );
    };

    const handelNameChange = (e) => {
      e.persist();
      setState(prevState => ({ ...prevState,  acting: { ...prevState.acting, value: e.target?.value } }) ) 
    }

    const handelKey = (e, childFolderID) => {
      if(e.keyCode === 13) {
        handleClose(e);
        uptFold(childFolderID, 'name', state.acting.value);
      }
    }

    useEffect( () => {
      inputEdit.current?.focus();
    });
    
    return (
      <div className='folderSekcia'>
        {folderList.map( (child_folder, index) => (
          <div 
            key={child_folder.id + index} 
            onClick={ () => onClick(child_folder.id) }
            className='wrapper'
          >

            <div>
              <FaRegFolder style={{ fontSize: '20px' }} />
              <span className='title'>
                {
                  state.acting.folderID === child_folder.id && state.acting.open ?
                  <input 
                    ref={inputEdit}
                    placeholder={'Folder name'}
                    value={state.acting.value}
                    onClick={ e => e.stopPropagation() }
                    onChange={handelNameChange}
                    onBlur={ () => { 
                      handleClose();
                      uptFold(child_folder.id, 'name', state.acting.value);
                    }}
                    onKeyDown={ e => handelKey(e, child_folder.id) }
                  /> :
                  child_folder.name
                }
              </span>
            </div>

            <div onClick={ e => e.stopPropagation() } >

              <GoKebabHorizontal className='actionI' onClick={ e => handleClick(e, child_folder.id, child_folder.name) } />

              <Menu
                id="basic-menu"
                anchorEl={state.anchorEl}
                open={!isNull(state.anchorEl)}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <div>
                  <MenuItem onClick={renameStart}>Rename</MenuItem>
                  <MenuItem onClick={deleteFolder}>Delete</MenuItem>
                </div> 
              </Menu>
            </div>

          </div>
        ))}
      </div>
    )
}
