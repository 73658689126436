import React from 'react';
import {
  Portlet,
  PortletBody,
  PortletHeader,
} from '../Portlet';

export const StatsDisplay = ({
   className,
   colWidth = 4,
   title = '',
   children
}) => {
  return (
    <div className={'col-'+colWidth}>
      <Portlet className={className}>
        <PortletHeader title={title} />
        <PortletBody fluid={true}>

          { children }

        </PortletBody>
      </Portlet>
    </div>
  )
}
