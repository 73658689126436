import { call, put, takeEvery } from "redux-saga/effects";

import {
  getShippingCostQuery,
  updateShippingCostQuery,
  getShippingCostsQuery,
  deleteShippingCostQuery
} from "./crud";
import {
  getShippingCosts,
  getShippingCostsError,
  getShippingCostsSuccess,
  updateShippingCost,
  updateShippingCostSuccess,
  updateShippingCostError,
  deleteShippingCost,
  deleteShippingCostSuccess,
  deleteShippingCostError,
  getShippingCost,
  getShippingCostError,
  getShippingCostSuccess
} from "./actions";

function* fetchShippingCostsSaga({ payload }) {
  try {
    const res = yield call(getShippingCostsQuery, payload);

    yield put(getShippingCostsSuccess(res.data));
  } catch (err) {
    yield put(getShippingCostsError(err));
  }
}

function* fetchShippingCostSaga({ payload }) {
  try {
    const { successCallBack = () => {} } = payload;

    const res = yield call(getShippingCostQuery, payload);

    yield put(
      getShippingCostSuccess({
        ...res.data,
        created_at: res.data.created_at
      })
    );

    yield successCallBack(res.data.type);
  } catch (err) {
    yield put(getShippingCostError(err.response));
  }
}

function* updateShippingCostSaga({ payload }) {
  try {
    const { successCallBack = () => {}, id, data } = payload;

    const res = yield call(updateShippingCostQuery, { id, data });

    yield put(
      updateShippingCostSuccess({
        ...res.data,
        created_at: res.data.created_at
      })
    );

    yield successCallBack(res.data.type);
  } catch (err) {
    yield put(updateShippingCostError(err.response));
  }
}

function* deleteShippingCostSaga({ payload }) {
  try {
    const { successCallBack = () => {}, id } = payload;

    const res = yield call(deleteShippingCostQuery, id);

    yield put(
      deleteShippingCostSuccess({
        ...res.data,
        created_at: res.data.created_at
      })
    );

    yield successCallBack(res.data.type);
  } catch (err) {
    yield put(deleteShippingCostError(err.response));
  }
}

function* shippingCostsSagas() {
  yield takeEvery(getShippingCosts, fetchShippingCostsSaga);
  yield takeEvery(getShippingCost, fetchShippingCostSaga);
  yield takeEvery(updateShippingCost, updateShippingCostSaga);
  yield takeEvery(deleteShippingCost, deleteShippingCostSaga);
}

export default shippingCostsSagas;
