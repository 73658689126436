import React, { useState } from 'react';
import SearchIcon from "@material-ui/icons/Search";
import {CiFilter} from "react-icons/ci";

export default function SearchBar({onSearch,fileSpecifyModal}) {

    const [searchTerm, setSearchTerm] = useState('');
    const handleChange = (event) => {
        const { value } = event.target;
        setSearchTerm(value);
        onSearch(value);
    };
    return(
            <div className="mypartlibrary-actionRow-forAll">
                <CiFilter
                //  onClick={(e) => fileSpecifyModal(e)}
                style={{
                    opacity: '0.5'
                }}
                    className="actionIcons"
                />
                <div className="search">
                    <div className="sIconDiv" 

                    >
                        <SearchIcon className="search-icon"/>
                    </div>
                    <input
                    type='text'
                     placeholder={'Search for document'}
                     value={searchTerm}
                     onChange={handleChange}
                     />
                </div>
            </div>
    );
}

// import React, { useState } from 'react';

// export default function SearchBar({ onSearch }) {
//     const [searchTerm, setSearchTerm] = useState('');

//     const handleChange = (event) => {
//         const { value } = event.target;
//         setSearchTerm(value);
//         onSearch(value);
//     };

//     return (
//         <input
//             type="text"
//             placeholder="Search..."
//             value={searchTerm}
//             onChange={handleChange}
//         />
//     );
// }
