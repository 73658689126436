import makerjs from 'makerjs';

export function someNeeds(htmlString) {

  const parser = new DOMParser();
  const svgDoc = parser.parseFromString(htmlString, 'image/svg+xml');
  const svgElement = svgDoc.querySelector('svg');
  const width = svgElement?.getAttribute('width');
  const height = svgElement?.getAttribute('height');
  const viewBox = svgElement?.getAttribute('viewBox').split(' ');

  const paths = svgDoc.querySelectorAll('path');
  const polyLines = svgDoc.querySelectorAll('polyline');
  const circles = svgDoc.querySelectorAll('circle');
  const rectangles = svgDoc.querySelectorAll('rect');
  const modelsMaker = [];
  const pathsMaker = [];
  const fillColor = '#c0c0c0';
  

  let totalLength = 0;
  let totalArea = viewBox && viewBox.length > 3 && (viewBox[2] - 10) * (viewBox[3] - 10);

  console.log((viewBox[2] - 10) , (viewBox[3] - 10))

  paths.forEach((path) => {
    totalLength += path.getTotalLength();
  });

  // Extract all paths and add them to models["path"]
  paths.forEach((elem, index) => {
    let pathData = elem.getAttribute('d');

    try {
      var model = makerjs.importer.fromSVGPathData(pathData);
      model = makerjs.model.mirror(model, false, true);

      if (model && model.paths) {
        Object.keys(model.paths).forEach((key) => {
          if (model.paths[key] && fillColor) {
            model.paths[key].fill = model.paths[key].fill || fillColor;
          }
        });
      }

      if (model && model.models) {
        Object.keys(model.models).forEach((key) => {
          let shape = model.models && model.models[key];

          if (shape && shape.paths && fillColor) {
            shape.paths.fill = shape.paths.fill || fillColor;
          }
        });
      }

      modelsMaker['path' + index] = model;
    } catch (e) {
      console.error(e);
    }
  });

  polyLines.forEach((polyline) => {
    totalLength += polyline.getTotalLength();
  });

  circles.forEach((circle) => {
    const radius = parseFloat(circle.getAttribute('r'));

    totalLength += 2 * Math.PI * radius;
    // totalArea += Math.PI * radius * radius;
  });

  // Extract all circles
  circles.forEach((elem, index) => {
    let cx = parseFloat(elem.getAttribute('cx'));
    let cy = parseFloat(elem.getAttribute('cy'));
    let r = parseFloat(elem.getAttribute('r'));

    try {
      let circle = new makerjs.paths.Circle([cx, cy], r);
      circle.fill = '#F2F3F8';

      pathsMaker['path' + index] = circle;
    } catch (e) {
      console.error(e);
    }
  });

  let modelWrapper = {
    models: modelsMaker,
    paths: pathsMaker,
  };

  const svgString = makerjs.exporter.toSVG(modelWrapper, {
    useSvgPathOnly: true,
    layerOptions: { fill: true },
  });


  const doc = parser.parseFromString(svgString, 'image/svg+xml');
  const docSvgEl = doc.documentElement; 

  if (docSvgEl.hasAttribute('viewBox')) {
    const viewBoxValues = docSvgEl.getAttribute('viewBox').split(' ');
    const newViewBox = `0 -3 ${parseFloat(viewBoxValues[2]) + 4} ${parseFloat(viewBoxValues[3]) + 4}`;
    docSvgEl.setAttribute('viewBox', newViewBox);
  } else {
    console.error('The SVG element does not have a viewBox attribute.');
  }


  const pathsToFill = doc.querySelectorAll('path');

  pathsToFill.forEach((path) => {
    const d = path.getAttribute('d');
    if (d && (d.startsWith('M') || d.startsWith('m'))) {
      path.setAttribute('fill', fillColor);
    } else {
      console.error('Invalid path data:', d);
    }
  });

  const serializer = new XMLSerializer();
  const updatedSvg = serializer.serializeToString(doc);

  rectangles.forEach((rect) => {
    const rectWidth = parseFloat(rect.getAttribute('width'));
    const rectHeight = parseFloat(rect.getAttribute('height'));

    totalLength = 2 * (rectWidth + rectHeight);
    //   totalArea += rectWidth * rectHeight;
  });

  return {
    updatedSvg,
    length: totalLength,
    width: width - 10,
    height: height - 10,
    area: totalArea,
  };
  
}

export function adaptProject(response, type, materials_array, id_array) {
  const { order : { id:order_id }, products : [firstProduct], id } = response.data;
  // const [firstProd] = products;

  const {
    id: product_id,
    dxf,
    dxf_original,
    svg,
    name: product_name,
  } = firstProduct; //file urls

  const returnVal = {
    project_id: id,
    order_id,
    product_id,
    file_name: product_name,
    material: materials_array[0] ? materials_array[0].id : null,
    dxf,
    dxf_original,
    svg,
    type,
  };

  if (id_array[0]) returnVal.id = id_array[0];

  return returnVal;
}
