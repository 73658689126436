import {call, put, takeEvery} from 'redux-saga/effects';

import { getShippingCosts, getShippingCostsSuccess, getShippingCostsError } from "./actions";
import { getShippingCostsQuery } from "./crud";


function* getShippingCostsSaga({payload}) {
    try {
        const res = yield call(getShippingCostsQuery, payload);

        yield put(getShippingCostsSuccess(res.data));
    } catch (err) {
        yield put(getShippingCostsError(err));
    }
}

function* sagas() {
    yield takeEvery(getShippingCosts, getShippingCostsSaga);
}

export default sagas;
