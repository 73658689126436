import { call, delay, put, takeEvery, all } from 'redux-saga/effects';
import { decompress } from 'lz-string';

import { getStorage } from '../../../_metronic/utils/utils';
import { someNeeds, adaptProject } from '../../../_metronic/utils/cadUtils';
import { toast } from 'react-toastify';
import {
  convertDxfRequest,
  getEditProject,
  getQuoteSvg,
  materialDataRequest,
  saveChangeProduct,
  saveProducts,
  convertDxfSimple,
} from '../../crud/draw.crud';
import { DRAW_ACTIONS, drawActions } from '../../store/ducks/draw.duck';
import { checkoutActions } from '../../store/ducks/checkout.duck';
import Emitter from '../../common/constants/EventEmitter/emmiter';

let nameWpromise = [];

function* fetchConvertDxf({ payload }) {
  try {
    const {
      data,
      name,
      loading,
      withAbort,
      lastOne,
      materialObject,
      quantity,
    } = payload;

    let fileType = 'dxf';
    if (
      name.indexOf('.stp') !== -1 ||
      name.indexOf('.step') !== -1 ||
      name.indexOf('.STP') !== -1 ||
      name.indexOf('.STEP') !== -1
    ) {
      fileType = 'step';
    }

    let controller = new AbortController();

    function progressEvent(loadingObject) {
      Emitter.emit('LOADING_PROGRESS_UPDATE', { name, loadingObject });
    }

    let request = {
      url: `${process.env.REACT_APP_API_URL}/api/to-svg?type=${fileType}`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${getStorage('token')}`,
      },
      signal: controller.signal,
      data: decompress(data),

      onUploadProgress: progressEvent,

      onDownloadProgress: progressEvent,
    };

    function abort() {
      controller.abort();
    }

    loading.uploadingSvgs.forEach((nameWpromis) => {
      if (nameWpromis.name === name)
        nameWpromise.push({ name, promiseCancel: abort });
    });

    if (lastOne) {
      yield put(drawActions.loadingUpdate(true, nameWpromise, null));
      nameWpromise = [];
    }

    const convertedSvg = yield call(
      convertDxfRequest,
      decompress(data),
      fileType,
      withAbort,
      request
    );

    if (!controller.signal.aborted || !withAbort) {
      yield put(
        drawActions.convertDxfSuccess({
          svg: convertedSvg?.data,
          name,
          dxf_original: decompress(data),
          materialObject,
          quantity,
        })
      );
      yield put(drawActions.loadingUpdate(false, null, name));
    }
  } catch (err) {
    yield put(drawActions.convertDxfError(err.response?.data?.title));
    console.error(err);
    // const convertedSvg = yield call(convertDxf, data);

    // yield put(drawActions.setDraw({ svg: convertedSvg.data, name}));
  }
}

function* uploadFiles(ARRAY_DXF, fileType) {
  const calls = yield all(
    ARRAY_DXF.map(({ DXF_TEXT, name }) => {
      const fileTypeCheck = fileType || name.split('.').reverse()[0];
      return call(convertDxfSimple, DXF_TEXT, fileTypeCheck);
    })
  );

  return calls.map((res) => res.data);
}

function* createProject({ payload }) {
  try {
      
    console.log(payload, 'payload')

    const { ARRAY_DXF, type, fileType, quantity} = payload;
    const materials_array = ARRAY_DXF.map((obj) => obj.materialObject);
    const id_array = ARRAY_DXF.map((obj) => obj.file_id);

    yield put(drawActions.projectCreationUpdte(true));
    const convertedSvgs = yield call(uploadFiles, ARRAY_DXF, fileType);
   
    const PartQuantity = quantity


    const products = convertedSvgs.map((convertedSvg, index) => {

      const htmlString = convertedSvg;
      const { updatedSvg, ...svgPart } = someNeeds(htmlString);

      const { DXF_TEXT, name, quantity = PartQuantity | 1, materialObject } = ARRAY_DXF[index];

      const otherNeeds = {
        name,
        dxf: DXF_TEXT,
        dxf_original: DXF_TEXT,
        svg: updatedSvg,
        ...svgPart,
        threadings_quantity: 0,
        material: materialObject ? materialObject.id : 875,
        quantity,
        bendings: [],
        tolerance: 0.5,
      };

      return otherNeeds;
    });

    const checkoutData = { isAnonymous: null, products };
    const params = {
      include:
        'products,products.material,products.bendings,order,order.shipping_address.country,order.billing_address.country',
    };
    const res = yield call(saveProducts, checkoutData, params);

    yield put(
      drawActions.updateNewFile(
        adaptProject(res, type, materials_array, id_array)
      )
    );

    toast.success('Your document is a cuttable: new order is created', {
      position: 'bottom-right',
      autoClose: 2500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    });
  } catch (err) {
    yield put(drawActions.projectCreationUpdte(false));
    toast.error(
      err?.response?.data?.error ||
        'Error while saving the file.. Probably you have load not cutable form',
      {
        position: 'bottom-right',
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      }
    );
    console.error(err);
    // yield put(drawActions.setDraw({ svg: convertedSvg.data, name}));
  }
}

function* fetchMaterialDataRequest() {
  try {
    const data = yield call(materialDataRequest);
    yield put(drawActions.materialDataSuccess(data.data));
  } catch (err) {
    yield put(drawActions.convertDxfError(err.response.data.title));
  }
}

function* fetchSelectMaterial({ payload }) {
  yield put(drawActions.updateDrawSVG(payload));
}

function* fetchSaveProducts({ payload }) {
  const { history, data, params } = payload;
  try {
    
    // const newData = data.products.map( item => ({ ...item, bendings: [], tolerance: 0, threadings_quantity: 0}) )
    // console.log(newData);
    // const res = yield call(saveProducts, { isAnonymous: null, products: newData} , params);

    const res = yield call(saveProducts, data, params);

    yield put(drawActions.saveProductsSuccess(res.data));
    yield put(checkoutActions.fulfillForm(res.data));

    yield history.push(`/checkout/${res.data.id}`);
    yield put(drawActions.resetDraw());
  } catch (err) {
    yield put(drawActions.saveProductsError(err.response.data.title));
  }
}

function* fetchGetEditProduct({ payload }) {
  const { id } = payload;

  try {
    const quoteSvg = yield call(getQuoteSvg, id);
    yield delay(500);
    yield put(
      drawActions.fulfillDrawWithProduct({ svg: quoteSvg.data, data: payload })
    );
  } catch (err) {
    yield put(drawActions.convertDxfError(err.response.data.title));
  }
}

function* fetchGetEditProject({ payload }) {
  let id = payload;
  try {
    let projectResult = yield call(getEditProject, id);
    let project = projectResult.data;

    for (let product of project.products) {
      let quoteSvg = yield call(getQuoteSvg, product.id);
      yield put(
        drawActions.fulfillDrawWithProduct({
          svg: quoteSvg.data,
          data: product,
        })
      );
    }

    yield put(drawActions.getEditProjectSuccess({ project: project, id: id }));
  } catch (err) {
    yield put(drawActions.getEditProjectError(err.response.data.title));
  }
}

function* fetchSaveChangeProduct({ payload }) {
  const { id, history, data } = payload;
  try {
    yield call(saveChangeProduct, id, ...data.products);
    yield put(drawActions.saveChangeProductSuccess());
    yield history.push('/quotes');
  } catch (err) {
    yield put(drawActions.saveChangeProductError(err.message));
  }
}

function* drawSagas() {
  yield takeEvery(DRAW_ACTIONS.CONVERT_DXF_REQUEST, fetchConvertDxf);
  yield takeEvery(DRAW_ACTIONS.CREATE_PROJECT, createProject);
  yield takeEvery(DRAW_ACTIONS.SELECT_MATERIAL_TYPE, fetchSelectMaterial);
  yield takeEvery(DRAW_ACTIONS.INSERT_GEAR, fetchSelectMaterial);
  yield takeEvery(DRAW_ACTIONS.ADD_QUANTITY, fetchSelectMaterial);
  yield takeEvery(DRAW_ACTIONS.ENGRAVING_ELEM, fetchSelectMaterial);
  yield takeEvery(DRAW_ACTIONS.CUT_ELEMENT, fetchSelectMaterial);
  yield takeEvery(DRAW_ACTIONS.DELETE_ELEMENT, fetchSelectMaterial);
  yield takeEvery(DRAW_ACTIONS.MATERIAL_DATA_REQUEST, fetchMaterialDataRequest);
  // yield takeEvery(DRAW_ACTIONS.CONVERT_DXF_TO_SVG, fetchConverDxf);
  yield takeEvery(DRAW_ACTIONS.SAVE_PRODUCTS_REQUEST, fetchSaveProducts);
  yield takeEvery(DRAW_ACTIONS.GET_EDIT_PRODUCT, fetchGetEditProduct);
  yield takeEvery(DRAW_ACTIONS.GET_EDIT_PROJECT, fetchGetEditProject);
  yield takeEvery(
    DRAW_ACTIONS.SAVE_CHANGE_PRODUCT_REQUEST,
    fetchSaveChangeProduct
  );
}

export default drawSagas;
