import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ConfiguratorPage from './ConfiguratorPage';

export default function Configurator() {
  return (
    <Switch>
      <Route
        path="/configurator/:projectId"
        render={(props) => <ConfiguratorPage {...props} />}
      />
      <Route path="/configurator" component={ConfiguratorPage} />
      <Route path="/lasersnijden" component={ConfiguratorPage} />
      <Route path="/lasercutting" component={ConfiguratorPage} />
      <Route path="/laserschneiden" component={ConfiguratorPage} />
      <Route path="/laserskaering" component={ConfiguratorPage} />
    </Switch>
  );
}
