import { handleActions } from "redux-actions";
import {
  getPriceRules,
  getPriceRulesSuccess,
  getPriceRulesError,
  getPriceRule,
  getPriceRuleSuccess,
  getPriceRuleError,
  updatePriceRule,
  updatePriceRuleSuccess,
  updatePriceRuleError,
  deletePriceRule,
  deletePriceRuleSuccess,
  deletePriceRuleError,
} from "./actions";

const initialState = {
  isProcessing: false,
  data: [],
  error: "",
  pagination: {
    per_page: 10,
    current_page: 1,
    count: 0
  },
  detailedPriceRule: {
    isProcessing: false,
    data: null,
    status: {
      isProcessing: false
    }
  },
};

const handlers = {
  [getPriceRules]: (state) => ({
    ...state,
    isProcessing: true,
  }),
  [getPriceRulesSuccess]: (state, action) => ({
    ...state,
    isProcessing: false,
    data: action.payload,
    // data: payload.quotes,
    pagination: {
      ...state.pagination,
      count: (action.payload && action.payload.length) || 0
    }
  }),
  [getPriceRulesError]: (state, action) => ({
    ...state,
    isProcessing: false,
    error: action.payload
  }),
  [getPriceRule]: (state) => ({
    ...state,
    detailedPriceRule: {
      ...state.detailedPriceRule,
      isProcessing: true
    }
  }),
  [getPriceRuleSuccess]: (state, action) => ({
    ...state,
    detailedPriceRule: {
      ...state.detailedPriceRule,
      isProcessing: false,
      data: action.payload
    }
  }),
  [getPriceRuleError]: (state, action) => ({
    ...state,
    detailedPriceRule: {
      ...state.detailedPriceRule,
      isProcessing: false,
      error: action.payload
    }
  }),
  [updatePriceRule]: (state) => ({
    ...state,
    detailedPriceRule: {
      ...state.detailedPriceRule,
      isProcessing: true,
    }
  }),
  [updatePriceRuleSuccess]: (state) => ({
    ...state,
    detailedPriceRule: {
      ...initialState.detailedPriceRule,
      isProcessing: false,
    }
  }),
  [updatePriceRuleError]: (state, action) => ({
    ...state,
    detailedPriceRule: {
      ...state.detailedPriceRule,
      isProcessing: false,
      status: {
        ...state.detailedPriceRule.status,
        error: action.payload
      }
    }
  }),
  [deletePriceRule]: (state) => ({
    ...state,
  }),
  [deletePriceRuleSuccess]: (state) => ({
    ...state,
  }),
  [deletePriceRuleError]: (state) => ({
    ...state,
  }),
};

export default handleActions(handlers, initialState);
