import React, { Component } from 'react';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { TextField } from '@material-ui/core';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { authActions } from '../../store/ducks/auth.duck';
import MetaTags from '../../components/MetaTags';

class ForgotPassword extends Component {
  state = { isRequested: false };

  render() {
    const { intl, forgotPassword, history } = this.props;
    const { isRequested } = this.state;

    if (isRequested) {
      return <Redirect to="/auth" />;
    }

    return (
      <>
        <MetaTags
          title={intl.formatMessage({ id: 'AUTH.FORGOT.META.TITLE' })}
          description={intl.formatMessage({ id: 'AUTH.FORGOT.META.DESC' })}
          keywords={intl.formatMessage({ id: 'AUTH.FORGOT.META.KEYWORDS' })}
        />
        <h3 className={'login-left-center-title'}>
          <FormattedMessage id="AUTH.FORGOT.TITLE" />
        </h3>

        <Formik
          initialValues={{ email: '' }}
          validate={(values) => {
            const errors = {};

            if (!values.email) {
              errors.email = intl.formatMessage({
                id: 'AUTH.VALIDATION.REQUIRED_FIELD',
              });
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = intl.formatMessage({
                id: 'AUTH.VALIDATION.INVALID_FIELD',
              });
            }

            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            forgotPassword(values.email, history);
          }}
        >
          {({
            values,
            status,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit} className="kt-form">
              {status && (
                <div role="alert" className="alert alert-danger">
                  <div className="alert-text">{status}</div>
                </div>
              )}

              <div className="form-group">
                <TextField
                  variant="outlined"
                  type="email"
                  label="Email"
                  margin="normal"
                  fullWidth={true}
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  helperText={touched.email && errors.email}
                  error={Boolean(touched.email && errors.email)}
                />
              </div>

              <div className="login-left-center-actions">
                <button
                  type="submit"
                  className="login-left-center-actions-primary"
                  disabled={isSubmitting}
                >
                  <FormattedMessage id="AUTH.GENERAL.RESET_BUTTON" />
                </button>

                <Link to="/auth/login">
                  <button
                    type="button"
                    className="login-left-center-actions-secondary"
                  >
                    <FormattedMessage id="AUTH.GENERAL.BACK_BUTTON" />
                  </button>
                </Link>
              </div>
            </form>
          )}
        </Formik>
      </>
    );
  }
}

const mapStateToProps = (state, { history }) => ({
  data: state.auth.isPending,
  errorMessage: state.auth.error,
  history,
});

const mapDispatchToProps = {
  forgotPassword: authActions.forgotPassword,
};

const withRouterForgotPassword = withRouter(ForgotPassword);

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(withRouterForgotPassword)
);
