import { API_URLS } from '../../common/constants/api/apiConstants';
import initApi from '../../crud/apiConfig/apiConfig';

export function getMessagesQuery(params = {}) {
  // console.log(params, "< params");
  // todo params ignored from back
  return initApi().get(API_URLS.MESSAGES, params);
}

export function getMessageQuery({ id }) {
  return initApi().get(`${API_URLS.MESSAGES}/${id}`);
}

export const deleteMessage = (id) => {
  return initApi().delete(`${API_URLS.MESSAGES}/${id}`);
};

export const createMessage = ({ data }) => {
  return initApi().post(API_URLS.MESSAGES, data);
};

export const updateMessage = ({ id, data }) => {
  return initApi().put(`${API_URLS.MESSAGES}/${id}`, data);
};
