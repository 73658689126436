import moment from 'moment/moment';
import { DEFAULT_DATE_FORMAT } from '../../../common/constants/constants';
import * as Yup from 'yup';

export const columns = [
  {
    title: 'Title',
    field: 'title',
  },

  {
    title: 'Description',
    field: 'text',
    cellStyle: { width: '50%' },
  },

  {
    title: 'Date',
    field: 'updated_at',
    defaultSort: 'desc',
    render: (rowData) =>
      moment.unix(rowData.updated_at).format(DEFAULT_DATE_FORMAT),
  },
  {
    name: 'name_en',
    title: 'Date English',
    field: 'updated_at',
    defaultSort: 'desc',
    render: (rowData) =>
      moment.unix(rowData.updated_at).format(DEFAULT_DATE_FORMAT),
  },
  {
    name: 'name_de',
    title: 'Date German',
    field: 'updated_at',
    defaultSort: 'desc',
    render: (rowData) =>
      moment.unix(rowData.updated_at).format(DEFAULT_DATE_FORMAT),
  },
];

export const formFileds = [
  {
    name: 'name',
    title: 'Title',
    key: 'title',
  },
  {
    name: 'name_en',
    title: 'Title English',
    key: 'title_en',
  },
  {
    name: 'name_de',
    title: 'Title German',
    key: 'title_de',
  },
  {
    name: 'text',
    title: 'Description',
    key: 'text',
  },
  {
    name: 'text_en',
    title: 'Description English',
    key: 'text_en',
  },
  {
    name: 'text_de',
    title: 'Description German',
    key: 'text_de',
  },
];

export const NewsScheme = () => {
  return Yup.object().shape({
    title: Yup.string().required('This field is required'),
    text: Yup.string().required('This field is required'),
    title_en: Yup.string().required('This field is required'),
    text_en: Yup.string().required('This field is required'),
    title_de: Yup.string().required('This field is required'),
    text_de: Yup.string().required('This field is required'),
  });
};
