import React from 'react';
import {Route, Switch} from 'react-router-dom';
import ConfiguratorPage from './ConfiguratorPage';


export default function ConfiguratorNew() {
    return (
        <Switch>
            <Route
                path="/configurator-new/:projectId"
                render={(props) => <ConfiguratorPage {...props} />}
            />
            <Route
                path="/configurator-new"
                component={ConfiguratorPage}
            />
        </Switch>
    );
}
