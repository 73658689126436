import { handleActions } from 'redux-actions';
import {
    getShippingCostsSuccess, getShippingCostsError
} from "./actions";

const initialState = {
    isProcessing: false,
    data: [],
    error: '',
    pagination: {
        per_page: 10,
        current_page: 0,
        count: 0,
    },
};

const handlers = {
    [getShippingCostsSuccess]: (state, action) => ({
        ...state,
        isProcessing: false,
        data: action.payload,
        pagination: {...state.pagination, count: (action.payload && action.payload.length) || 0},
    }),
    [getShippingCostsError]: (state, action) => ({
        ...state,
        isProcessing: false,
        error: action.payload,
    }),
};

export default handleActions(handlers, initialState);