import { call, put, takeEvery } from "redux-saga/effects";

import {
  getThreadQuery,
  updateThreadQuery,
  getThreadsQuery,
  deleteThreadQuery
} from "./crud";
import {
  getThreads,
  getThreadsError,
  getThreadsSuccess,
  updateThread,
  updateThreadSuccess,
  updateThreadError,
  deleteThread,
  deleteThreadSuccess,
  deleteThreadError,
  getThread,
  getThreadError,
  getThreadSuccess
} from "./actions";

function* fetchThreadsSaga({ payload }) {
  try {
    const res = yield call(getThreadsQuery, payload);

    yield put(getThreadsSuccess(res.data));
  } catch (err) {
    yield put(getThreadsError(err));
  }
}

function* fetchThreadSaga({ payload }) {
  try {
    const { successCallBack = () => {} } = payload;

    const res = yield call(getThreadQuery, payload);

    yield put(
      getThreadSuccess({
        ...res.data,
        created_at: res.data.created_at
      })
    );

    yield successCallBack(res.data.type);
  } catch (err) {
    yield put(getThreadError(err.response));
  }
}

function* updateThreadSaga({ payload }) {
  try {
    const { successCallBack = () => {}, id, data } = payload;

    const res = yield call(updateThreadQuery, { id, data });

    yield put(
      updateThreadSuccess({
        ...res.data,
        created_at: res.data.created_at
      })
    );

    yield successCallBack(res.data.type);
  } catch (err) {
    yield put(updateThreadError(err.response));
  }
}

function* deleteThreadSaga({ payload }) {
  try {
    const { successCallBack = () => {}, id } = payload;

    const res = yield call(deleteThreadQuery, id);

    yield put(
      deleteThreadSuccess({
        ...res.data,
        created_at: res.data.created_at
      })
    );

    yield successCallBack(res.data.type);
  } catch (err) {
    yield put(deleteThreadError(err.response));
  }
}

function* threadsSagas() {
  yield takeEvery(getThreads, fetchThreadsSaga);
  yield takeEvery(getThread, fetchThreadSaga);
  yield takeEvery(updateThread, updateThreadSaga);
  yield takeEvery(deleteThread, deleteThreadSaga);
}

export default threadsSagas;
