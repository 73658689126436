import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { TextField, InputAdornment, IconButton } from '@material-ui/core';
import { IoEyeOffOutline, IoEyeOutline } from 'react-icons/io5';
import { authActions } from '../../store/ducks/auth.duck';
import MetaTags from '../../components/MetaTags';

function Registration(props) {
  const {
    intl,
    registerUser,
    history,
    errorMessage,
    loadingStatus,
    deleteError,
  } = props;
  // const [inputValue, setInputValue] = React.useState('');
  // const [open, setOpen] = React.useState(false);
  // const [options, setOptions] = React.useState([]);
  const [showPassword, setShowPassword] = React.useState(false);

  // const loading = open && options.length === 0;

  if (errorMessage) {
    setTimeout(() => {
      deleteError();
    }, 5000);
  }

  return (
    <>
      <MetaTags
        title={intl.formatMessage({ id: 'AUTH.REGISTER.META.TITLE' })}
        description={intl.formatMessage({ id: 'AUTH.REGISTER.META.DESC' })}
        keywords={intl.formatMessage({ id: 'AUTH.REGISTER.META.KEYWORDS' })}
      />
      <h3 className={'login-left-center-title'}>
        <FormattedMessage id="AUTH.REGISTER.TITLE" />
      </h3>

      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        validate={(values) => {
          const errors = {};

          if (!values.email) {
            errors.email = intl.formatMessage({
              id: 'AUTH.VALIDATION.REQUIRED_FIELD',
            });
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = intl.formatMessage({
              id: 'AUTH.VALIDATION.INVALID_FIELD',
            });
          }

          if (!values.password) {
            errors.password = intl.formatMessage({
              id: 'AUTH.VALIDATION.REQUIRED_FIELD',
            });
          }

          return errors;
        }}
        onSubmit={(values) => {
          const newUserData = {
            email: values.email,
            password: values.password,
            locale: intl.locale,
          };

          localStorage.setItem('newUser', newUserData);
          registerUser(newUserData, history);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit} noValidate autoComplete="off">
            {errorMessage && (
              <div role="alert" className="alert alert-danger">
                <div className="alert-text">{errorMessage}</div>
              </div>
            )}

            <div className="form-group mb-0">
              <TextField
                variant="outlined"
                label={intl.formatMessage({ id: 'REGISTER.EMAIL' })}
                margin="normal"
                type="email"
                className="kt-width-full"
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                helperText={touched.email && errors.email}
                error={Boolean(touched.email && errors.email)}
              />
            </div>

            <div className="form-group mb-0">
              <TextField
                variant="outlined"
                type={showPassword ? 'text' : 'password'}
                margin="normal"
                label={intl.formatMessage({ id: 'REGISTER.PASSWORD' })}
                className="kt-width-full"
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle-password-visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={() => setShowPassword(!showPassword)}
                      >
                        {!showPassword ? (
                          <IoEyeOffOutline
                            size={28}
                            color={'rgb(215, 64, 58)'}
                          />
                        ) : (
                          <IoEyeOutline size={28} color={'rgb(215, 64, 58)'} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                helperText={touched.password && errors.password}
                error={Boolean(touched.password && errors.password)}
              />
            </div>

            <div className="login-left-center-actions">
              <div className={'login-left-center-actions-terms'}>
                <FormattedMessage id="AUTH.REGISTER.BY_REGISTERING" />
                &nbsp;
                <a
                  href={intl.formatMessage({ id: 'FOOTER.URL_TERMS' })}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage id="AUTH.REGISTER.TERMS" />
                </a>
                &nbsp;
                <FormattedMessage id="AUTH.REGISTER.AND" />
                &nbsp;
                <a
                  href={intl.formatMessage({ id: 'FOOTER.PRIVACY_URL' })}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage id="AUTH.REGISTER.PRIVACYSTATEMENT" />
                </a>
              </div>
              <button
                type="submit"
                disabled={loadingStatus}
                className="login-left-center-actions-primary"
              >
                <FormattedMessage id="AUTH.GENERAL.SUBMIT_BUTTON" />
              </button>

              <div className={'login-left-center-actions-extra'}>
                <span className="kt-login__signup-label">
                  <FormattedMessage id="AUTH.GENERAL.GOT_ACCOUNT" />
                </span>
                &nbsp;&nbsp;
                <Link
                  to="/auth/login"
                  className="kt-link kt-login__signup-link"
                >
                  <FormattedMessage id="AUTH.LOGIN.BUTTON" />
                </Link>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}

Registration.propTypes = {
  errorMessage: PropTypes.object,
  loadingStatus: PropTypes.bool.isRequired,
  intl: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  registerUser: PropTypes.func.isRequired,
  deleteError: PropTypes.func.isRequired,
  //getCountry: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  errorMessage: state.auth.error,
  loadingStatus: state.auth.isPending,
});

export default injectIntl(connect(mapStateToProps, authActions)(Registration));
