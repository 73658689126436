import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Form, Col, Button } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Paper } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import { updateUser, getUser } from '../../../store/users/actions';
import { ROUTES } from '../../../common/constants/routes/routes';
import { INITIAL_ADDRESS } from '../../../common/constants/constants';
import { getCountries } from '../../../store/countries/actions';

import UserAddressForm from './UserAddressForm';
import HourlyRateDiscountCmpnt from './HourlyRateDiscountCmpnt';
import MaterialsDiscountCmpnt from './MaterialsDiscountCmpnt';

const DetailsPage = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { id } = useParams();
  const { data, isProcessing, countriesData } = props;
  const [company, setCompany] = useState({ id: null, name: '' });
  const [countries, setCountries] = useState([]);
  const [form, setForm] = useState({
    first_name: '',
    last_name: '',
    email: '',
  });
  const [validated, setValidated] = useState(false);
  const [addresses, setAddresses] = useState({
    billing: { ...INITIAL_ADDRESS },
    shipping: { ...INITIAL_ADDRESS },
  });
  const [priceRules, setPriceRules] = useState({
    price_setting_materials: [],
    price_setting_hourly_rates: [],
    shipping_price: '',
  });

  useEffect(() => {
    if (id) {
      dispatch(getUser(id));
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    dispatch(getCountries());
  }, []);

  useEffect(() => {
    if (countriesData) {
      setCountries(countriesData);
    }
  }, [countriesData]);

  useEffect(() => {
    if (data) {
      setForm(data);
      setCompany(data.company);
      const shippingAddress = (data.addresses &&
        data.addresses.find((item) => item?.type?.title === 'Shipping')) || {
        ...INITIAL_ADDRESS,
      };
      const billingAddress = (data.addresses &&
        data.addresses.find((item) => item?.type?.title === 'Billing')) || {
        ...INITIAL_ADDRESS,
      };
      const priceSettings = data.price_setting;

      setAddresses({
        billing: { ...billingAddress, type: 2 },
        shipping: { ...shippingAddress, type: 1 },
      });

      setPriceRules({
        ...priceRules,
        price_setting_materials: priceSettings?.price_setting_materials || [],
        price_setting_hourly_rates:
          priceSettings?.price_setting_hourly_rates || [],
        shipping_price_discount: priceSettings?.shipping_price_discount || '',
      });
    } else {
      setForm({});
    }
  }, [data]);

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);

    const payload = {
      company: company,
      first_name: form.first_name,
      last_name: form.last_name,
      email: form.email,
      addresses: [
        {
          ...addresses.billing,
          type: 2,
        },
        {
          ...addresses.shipping,
          type: 1,
        },
      ],
      price_setting: {
        price_setting_materials: priceRules.price_setting_materials,
        price_setting_hourly_rates: priceRules.price_setting_hourly_rates,
        shipping_price_discount: priceRules.shipping_price_discount,
      },
    };

    dispatch(
      updateUser({
        id: id,
        data: payload,
        successCallBack: () => {
          history.push(ROUTES.USERS);
        },
      })
    );
  };

  const handleCancel = () => {
    history.push(ROUTES.USERS);
  };

  const onChange = (field) => (event) => {
    setForm({
      ...form,
      [field]: event.target.value,
    });
  };

  const onChangeCompany = (field) => (event) => {
    setCompany({
      ...company,
      [field]: event.target.value,
    });
  };

  const onChangeBilling = (field) => (event) => {
    setAddresses({
      ...addresses,
      billing: {
        ...addresses.billing,
        [field]: event.target.value,
      },
    });
  };

  const onChangeShipping = (field) => (event) => {
    setAddresses({
      ...addresses,
      shipping: {
        ...addresses.shipping,
        [field]: event.target.value,
      },
    });
  };

  const onChangeHourlyRate = (data) => {
    setPriceRules({
      ...priceRules,
      price_setting_hourly_rates: data,
    });
  };

  const onChangeMaterialPrice = (data) => {
    setPriceRules({
      ...priceRules,
      price_setting_materials: data,
    });
  };

  return isProcessing ? (
    'Loading...'
  ) : (
    <div className="row">
      <div className="col-md-12">
        <Col className="kt-section">
          <Row className="kt-section__content">
            <Paper className="p-5">
              <h1 className="kt-invoice__title mb-5">
                User {id ? 'Edit' : 'Add'}
              </h1>

              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row>
                  <Col md={6}>
                    <Form.Group key="company_name" as={Row} controlId="">
                      <Form.Label column md={12}>
                        Company
                      </Form.Label>
                      <Col md={12}>
                        <Form.Control
                          type="text"
                          value={company ? company.name : ''}
                          onChange={onChangeCompany('name')}
                          required={false}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group key="first_name" as={Row} controlId="">
                      <Form.Label column md={12}>
                        First name
                      </Form.Label>
                      <Col md={12}>
                        <Form.Control
                          type="text"
                          value={form.first_name ? form.first_name : ''}
                          onChange={onChange('first_name')}
                          required={true}
                        />
                      </Col>
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <Form.Group key="email" as={Row} controlId="">
                      <Form.Label column md={12}>
                        E-mail
                      </Form.Label>
                      <Col md={12}>
                        <Form.Control
                          type="text"
                          value={form.email ? form.email : ''}
                          onChange={onChange('email')}
                          required={true}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group key="last_name" as={Row} controlId="">
                      <Form.Label column md={12}>
                        Last name
                      </Form.Label>
                      <Col md={12}>
                        <Form.Control
                          type="text"
                          value={form.last_name ? form.last_name : ''}
                          onChange={onChange('last_name')}
                          required={false}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                <hr className="mt-3 mb-3" />

                <Row>
                  <Col md={6}>
                    <h3 className="kt-section__title kt-section__title-sm">
                      Shipping Address:
                    </h3>
                    <UserAddressForm
                      fields={addresses.shipping}
                      onChange={onChangeShipping}
                      countries={countries}
                      type={'shipping'}
                    />
                  </Col>
                  <Col md={6}>
                    <h3 className="kt-section__title kt-section__title-sm">
                      Billing Address:
                    </h3>
                    <UserAddressForm
                      fields={addresses.billing}
                      onChange={onChangeBilling}
                      countries={countries}
                      type={'billing'}
                    />
                  </Col>
                </Row>

                <hr className="mt-3 mb-3" />

                <Row>
                  <Col md={6}>
                    <h2 className="kt-section__title m-0">
                      New Price Settings:
                    </h2>
                  </Col>

                  <Col md={12}>
                    <hr className="mt-3 mb-3" />
                  </Col>

                  <Col md={12} className="p-0">
                    <Form.Group
                      className="m-0"
                      key="material_price"
                      as={Row}
                      controlId=""
                    >
                      <Form.Label column md={12}>
                        Material price
                      </Form.Label>
                    </Form.Group>
                    <MaterialsDiscountCmpnt
                      data={priceRules.price_setting_materials}
                      onChange={onChangeMaterialPrice}
                    />
                  </Col>

                  <Col md={12} className="p-0">
                    <HourlyRateDiscountCmpnt
                      data={priceRules.price_setting_hourly_rates}
                      onChange={onChangeHourlyRate}
                    />
                  </Col>

                  <Col md={12} className="p-0">
                    <Form.Group
                      className="m-0"
                      key="shipping_price"
                      as={Row}
                      controlId=""
                    >
                      <Form.Label column md={12}>
                        Shipping price
                      </Form.Label>
                    </Form.Group>
                    <Col md={4}>
                      <label htmlFor="shipping-price-percentage">
                        <FormattedMessage id={'Discount (%)'} />
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="shipping-price-percentage"
                        key="shipping-price-percentage"
                        value={priceRules.shipping_price_discount}
                        onChange={(e) => {
                          setPriceRules({
                            ...priceRules,
                            shipping_price_discount: e.target.value,
                          });
                        }}
                      />
                    </Col>
                  </Col>
                </Row>

                <hr className="mt-3 mb-3" />

                <Row>
                  <Col sm={12}>
                    <Button className="pull-right" type="submit">
                      Save{' '}
                    </Button>
                    <Button
                      className="pull-right mr-2"
                      variant="light"
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Paper>
          </Row>
        </Col>
      </div>
    </div>
  );
};

DetailsPage.propTypes = {
  isProcessing: PropTypes.bool.isRequired,
  data: PropTypes.object,
  countriesData: PropTypes.array,
};

const mapStateToProps = ({ users: { detailedUser }, countries }) => ({
  data: detailedUser.data,
  isProcessing: detailedUser.isProcessing,
  countriesData: countries.data,
});

export default connect(mapStateToProps)(DetailsPage);
