import React from 'react';
import { Grid, Typography, Stepper, Step, StepLabel } from '@mui/material';

import { FormattedMessage, injectIntl } from 'react-intl';

const HowItWorks = (props) => {
  const { intl, materialType } = props;

  const howItWorksSteps = [
    'Maak een account aan',
    'Upload jouw tekening',
    'Specificeer het materiaal, de dikte, kleur en bewerkingen',
    'Voer de gewenst aantallen in',
    'Dien je aanvraag in en ontvang binnen 10 seconden je offerte',
  ];

  const iframeSrc = {
    HDPE: {
      en:
        'https://www.youtube.com/embed/NuDSYrq1kwE?feature=oembed&amp;wmode=opaque&amp;rel=0',
      nl:
        'https://www.youtube.com/embed/FP5kwvvKVMU?feature=oembed&amp;wmode=opaque&amp;rel=0',
      de:
        'https://www.youtube.com/embed/ZyAoj7sdBAo?feature=oembed&amp;wmode=opaque&amp;rel=0',
    },

    POM: {
      en:
        'https://www.youtube.com/embed/NuDSYrq1kwE?feature=oembed&amp;wmode=opaque&amp;rel=0',
      nl:
        'https://www.youtube.com/embed/aAQfgY0wbV8?feature=oembed&amp;wmode=opaque&amp;rel=0',
      de:
        'https://www.youtube.com/embed/ZyAoj7sdBAo?feature=oembed&amp;wmode=opaque&amp;rel=0',
    },

    PMMA: {
      en:
        'https://www.youtube.com/embed/NuDSYrq1kwE?feature=oembed&amp;wmode=opaque&amp;rel=0',
      nl:
        'https://www.youtube.com/embed/qOMUcI_KRfs?feature=oembed&amp;wmode=opaque&amp;rel=0',
      de:
        'https://www.youtube.com/embed/ZyAoj7sdBAo?feature=oembed&amp;wmode=opaque&amp;rel=0',
    },

    HMPE: {
      en:
        'https://www.youtube.com/embed/NuDSYrq1kwE?feature=oembed&amp;wmode=opaque&amp;rel=0',
      nl:
        'https://www.youtube.com/embed/no-saboTMu4?feature=oembed&amp;wmode=opaque&amp;rel=0',
      de:
        'https://www.youtube.com/embed/ZyAoj7sdBAo?feature=oembed&amp;wmode=opaque&amp;rel=0',
    },

    PC: {
      en:
        'https://www.youtube.com/embed/NuDSYrq1kwE?feature=oembed&amp;wmode=opaque&amp;rel=0',
      nl:
        'https://www.youtube.com/embed/Oa_trzpqSZM?feature=oembed&amp;wmode=opaque&amp;rel=0',
      de:
        'https://www.youtube.com/embed/ZyAoj7sdBAo?feature=oembed&amp;wmode=opaque&amp;rel=0',
    },
    PETG: {
      en:
        'https://www.youtube.com/embed/NuDSYrq1kwE?feature=oembed&amp;wmode=opaque&amp;rel=0',
      nl:
        'https://www.youtube.com/embed/csQA74UHFSg?feature=oembed&amp;wmode=opaque&amp;rel=0',
      de:
        'https://www.youtube.com/embed/ZyAoj7sdBAo?feature=oembed&amp;wmode=opaque&amp;rel=0',
    },
    PTFE: {
      en:
        'https://www.youtube.com/embed/NuDSYrq1kwE?feature=oembed&amp;wmode=opaque&amp;rel=0',
      nl:
        'https://www.youtube.com/embed/Oa_trzpqSZM?feature=oembed&amp;wmode=opaque&amp;rel=0',
      de:
        'https://www.youtube.com/embed/ZyAoj7sdBAo?feature=oembed&amp;wmode=opaque&amp;rel=0',
    },
    PETP: {
      en:
        'https://www.youtube.com/embed/NuDSYrq1kwE?feature=oembed&amp;wmode=opaque&amp;rel=0',
      nl:
        'https://www.youtube.com/embed/Oa_trzpqSZM?feature=oembed&amp;wmode=opaque&amp;rel=0',
      de:
        'https://www.youtube.com/embed/ZyAoj7sdBAo?feature=oembed&amp;wmode=opaque&amp;rel=0',
    },
    PS: {
      en:
        'https://www.youtube.com/embed/NuDSYrq1kwE?feature=oembed&amp;wmode=opaque&amp;rel=0',
      nl:
        'https://www.youtube.com/embed/Oa_trzpqSZM?feature=oembed&amp;wmode=opaque&amp;rel=0',
      de:
        'https://www.youtube.com/embed/ZyAoj7sdBAo?feature=oembed&amp;wmode=opaque&amp;rel=0',
    },
    PA: {
      en:
        'https://www.youtube.com/embed/NuDSYrq1kwE?feature=oembed&amp;wmode=opaque&amp;rel=0',
      nl:
        'https://www.youtube.com/embed/Oa_trzpqSZM?feature=oembed&amp;wmode=opaque&amp;rel=0',
      de:
        'https://www.youtube.com/embed/ZyAoj7sdBAo?feature=oembed&amp;wmode=opaque&amp;rel=0',
    },
    PP: {
      en:
        'https://www.youtube.com/embed/NuDSYrq1kwE?feature=oembed&amp;wmode=opaque&amp;rel=0',
      nl:
        'https://www.youtube.com/embed/Oa_trzpqSZM?feature=oembed&amp;wmode=opaque&amp;rel=0',
      de:
        'https://www.youtube.com/embed/ZyAoj7sdBAo?feature=oembed&amp;wmode=opaque&amp;rel=0',
    },
    PI: {
      en:
        'https://www.youtube.com/embed/NuDSYrq1kwE?feature=oembed&amp;wmode=opaque&amp;rel=0',
      nl:
        'https://www.youtube.com/embed/Oa_trzpqSZM?feature=oembed&amp;wmode=opaque&amp;rel=0',
      de:
        'https://www.youtube.com/embed/ZyAoj7sdBAo?feature=oembed&amp;wmode=opaque&amp;rel=0',
    },
    PEEK: {
      en:
        'https://www.youtube.com/embed/NuDSYrq1kwE?feature=oembed&amp;wmode=opaque&amp;rel=0',
      nl:
        'https://www.youtube.com/embed/Oa_trzpqSZM?feature=oembed&amp;wmode=opaque&amp;rel=0',
      de:
        'https://www.youtube.com/embed/ZyAoj7sdBAo?feature=oembed&amp;wmode=opaque&amp;rel=0',
    },
    ABS: {
      en:
        'https://www.youtube.com/embed/NuDSYrq1kwE?feature=oembed&amp;wmode=opaque&amp;rel=0',
      nl:
        'https://www.youtube.com/embed/Oa_trzpqSZM?feature=oembed&amp;wmode=opaque&amp;rel=0',
      de:
        'https://www.youtube.com/embed/ZyAoj7sdBAo?feature=oembed&amp;wmode=opaque&amp;rel=0',
    },
    HPL: {
      en:
        'https://www.youtube.com/embed/NuDSYrq1kwE?feature=oembed&amp;wmode=opaque&amp;rel=0',
      nl:
        'https://www.youtube.com/embed/Oa_trzpqSZM?feature=oembed&amp;wmode=opaque&amp;rel=0',
      de:
        'https://www.youtube.com/embed/ZyAoj7sdBAo?feature=oembed&amp;wmode=opaque&amp;rel=0',
    },
    ALUPANEL: {
      en:
        'https://www.youtube.com/embed/NuDSYrq1kwE?feature=oembed&amp;wmode=opaque&amp;rel=0',
      nl:
        'https://www.youtube.com/embed/Oa_trzpqSZM?feature=oembed&amp;wmode=opaque&amp;rel=0',
      de:
        'https://www.youtube.com/embed/ZyAoj7sdBAo?feature=oembed&amp;wmode=opaque&amp;rel=0',
    },
    EPDM: {
      en:
        'https://www.youtube.com/embed/NuDSYrq1kwE?feature=oembed&amp;wmode=opaque&amp;rel=0',
      nl:
        'https://www.youtube.com/embed/Oa_trzpqSZM?feature=oembed&amp;wmode=opaque&amp;rel=0',
      de:
        'https://www.youtube.com/embed/ZyAoj7sdBAo?feature=oembed&amp;wmode=opaque&amp;rel=0',
    },
    NBR: {
      en:
        'https://www.youtube.com/embed/NuDSYrq1kwE?feature=oembed&amp;wmode=opaque&amp;rel=0',
      nl:
        'https://www.youtube.com/embed/Oa_trzpqSZM?feature=oembed&amp;wmode=opaque&amp;rel=0',
      de:
        'https://www.youtube.com/embed/ZyAoj7sdBAo?feature=oembed&amp;wmode=opaque&amp;rel=0',
    },
    PUR: {
      en:
        'https://www.youtube.com/embed/NuDSYrq1kwE?feature=oembed&amp;wmode=opaque&amp;rel=0',
      nl:
        'https://www.youtube.com/embed/Oa_trzpqSZM?feature=oembed&amp;wmode=opaque&amp;rel=0',
      de:
        'https://www.youtube.com/embed/ZyAoj7sdBAo?feature=oembed&amp;wmode=opaque&amp;rel=0',
    },
    SBR: {
      en:
        'https://www.youtube.com/embed/NuDSYrq1kwE?feature=oembed&amp;wmode=opaque&amp;rel=0',
      nl:
        'https://www.youtube.com/embed/Oa_trzpqSZM?feature=oembed&amp;wmode=opaque&amp;rel=0',
      de:
        'https://www.youtube.com/embed/ZyAoj7sdBAo?feature=oembed&amp;wmode=opaque&amp;rel=0',
    },
    SILICONE: {
      en:
        'https://www.youtube.com/embed/NuDSYrq1kwE?feature=oembed&amp;wmode=opaque&amp;rel=0',
      nl:
        'https://www.youtube.com/embed/Oa_trzpqSZM?feature=oembed&amp;wmode=opaque&amp;rel=0',
      de:
        'https://www.youtube.com/embed/ZyAoj7sdBAo?feature=oembed&amp;wmode=opaque&amp;rel=0',
    },
  };

  return (
    <Grid
      item
      container
      xs={12}
      justifyContent="space-between"
      alignItems="flex-start"
      className="how-it-works-section"
    >
      <Grid item xs={12}>
        <Typography variant="h2" align="center">
          <FormattedMessage id="HOWITWORKS.TITLE" />
        </Typography>
      </Grid>

      <Grid
        item
        xs={12}
        container
        justifyContent="center"
        className="how-it-works"
      >
        <Stepper alternativeLabel>
          {howItWorksSteps.map((label) => (
            <Step key={label}>
              <StepLabel>
                <FormattedMessage id={label} />
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Grid>

      <Grid item container xs={12} sm={12} md={12} justifyContent="center">
        <iframe
          title="Wat is HDPE? + Uitleg lasersnijden"
          width="1500"
          height="844"
          src={iframeSrc[materialType][intl?.locale]}
          style={{ border: 0 }}
          loading="lazy"
          allowFullScreen
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </Grid>
    </Grid>
  );
};

export default injectIntl(HowItWorks);
