import React, {useState} from 'react';
import {
  Portlet,
  PortletBody,
  PortletHeader,
} from '../Portlet';

import {
  Hint,
  XAxis,
  YAxis,
  XYPlot,
  makeWidthFlexible,
  VerticalGridLines,
  VerticalBarSeries,
  HorizontalGridLines
} from 'react-vis';

const FlexibleXYPlot = makeWidthFlexible(XYPlot);

export const VerticalBarGraph = ({
  className,
  colWidth = 4,
  title,
  opacity = 1,
  barWidth = 1,
  height,
  verticalGridLines = true,
  horizontalGridLines = true,
  xAxis = { show: true },
  yAxis = { show: true },
  styles,
  dataSet = []
}) => {

  const [hint, setHint] = useState(false);

  const formatMoney = (value) => {
    return (value) ? '€ '+value.toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '€ 0';
  }

  return (
    <div className={'col-'+colWidth}>
      <Portlet className={className}>
        <PortletHeader title={title} />
          <PortletBody fluid={true} onMouseLeave={() => { setHint(false) }}>

            <FlexibleXYPlot
              xType="ordinal"
              colorRange={['#D80000', '#D80000']}
              colorType="literal"
              height={height}
            >
              {horizontalGridLines && <HorizontalGridLines style={styles?.horizontalGridLines} />}
              {verticalGridLines && <VerticalGridLines style={styles?.verticalGridLines} />}

              {xAxis?.show && <XAxis title={xAxis?.title} />}
              {yAxis?.show && <YAxis title={yAxis?.title} />}

              <VerticalBarSeries
                animation={true}
                data={dataSet}
                style={styles?.bars}
                barWidth={barWidth}
                opacity={opacity}
                onNearestX={(value) => setHint(value) }
              />

              {hint && (
                <Hint value={hint}>
                  <div style={styleSheet.Hint}>
                    <div>{yAxis?.title} {(yAxis?.type == 'money') ? formatMoney(hint.y) : hint.y }</div>
                    <div>{xAxis?.title} {hint.x}</div>
                  </div>
                </Hint>
              )}

            </FlexibleXYPlot>

          </PortletBody>
      </Portlet>
    </div>
  )
}

const styleSheet = {
  Hint: {
    backgroundColor: '#ebedf2',
    color: '#000',
    padding: '10px',
    borderRadius: '5px',
    textAlign: 'center',
    border: '1px solid #D80000',
  }
}
