import { call, put, takeEvery } from "redux-saga/effects";

import {
  getVatQuery,
  updateVatQuery,
  getVatsQuery,
  deleteVatQuery
} from "./crud";
import {
  getVats,
  getVatsError,
  getVatsSuccess,
  updateVat,
  updateVatSuccess,
  updateVatError,
  deleteVat,
  deleteVatSuccess,
  deleteVatError,
  getVat,
  getVatError,
  getVatSuccess
} from "./actions";

function* fetchVatsSaga({ payload }) {
  try {
    const res = yield call(getVatsQuery, payload);

    yield put(getVatsSuccess(res.data));
  } catch (err) {
    yield put(getVatsError(err));
  }
}

function* fetchVatSaga({ payload }) {
  try {
    const { successCallBack = () => {} } = payload;

    const res = yield call(getVatQuery, payload);

    yield put(
      getVatSuccess({
        ...res.data,
        created_at: res.data.created_at
      })
    );

    yield successCallBack(res.data.type);
  } catch (err) {
    yield put(getVatError(err.response));
  }
}

function* updateVatSaga({ payload }) {
  try {
    const { successCallBack = () => {}, id, data } = payload;

    const res = yield call(updateVatQuery, { id, data });

    yield put(
      updateVatSuccess({
        ...res.data,
        created_at: res.data.created_at
      })
    );

    yield successCallBack(res.data.type);
  } catch (err) {
    yield put(updateVatError(err.response));
  }
}

function* deleteVatSaga({ payload }) {
  try {
    const { successCallBack = () => {}, id } = payload;

    const res = yield call(deleteVatQuery, id);

    yield put(
      deleteVatSuccess({
        ...res.data,
        created_at: res.data.created_at
      })
    );

    yield successCallBack(res.data.type);
  } catch (err) {
    yield put(deleteVatError(err.response));
  }
}

function* vatsSagas() {
  yield takeEvery(getVats, fetchVatsSaga);
  yield takeEvery(getVat, fetchVatSaga);
  yield takeEvery(updateVat, updateVatSaga);
  yield takeEvery(deleteVat, deleteVatSaga);
}

export default vatsSagas;
