import { createAction } from 'redux-actions';

export const getPriceRules = createAction('GET_PRICE_RULES_REQUEST');
export const getPriceRulesSuccess = createAction('GET_PRICE_RULES_SUCCESS');
export const getPriceRulesError = createAction('GET_PRICE_RULES_ERROR');
export const getPriceRule = createAction('GET_PRICE_RULE_REQUEST');
export const getPriceRuleSuccess = createAction('GET_PRICE_RULE_SUCCESS');
export const getPriceRuleError = createAction('GET_PRICE_RULE_ERROR');
export const deletePriceRule = createAction('DELETE_PRICE_RULE_REQUEST');
export const deletePriceRuleSuccess = createAction('DELETE_PRICE_RULE_SUCCESS');
export const deletePriceRuleError = createAction('DELETE_PRICE_RULE_ERROR');
export const updatePriceRule = createAction('UPDATE_PRICE_RULE_REQUEST');
export const updatePriceRuleSuccess = createAction('UPDATE_PRICE_RULE_SUCCESS');
export const updatePriceRuleError = createAction('UPDATE_PRICE_RULE_ERROR');
