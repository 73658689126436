export const elementsForeachCB = (elem, totalSums, id) => {
  const elemFromArray = totalSums.find(item => item.id === id.replace('configurator-draw-box:', ''));

  if (!elemFromArray) {
    totalSums.push({ id: id.replace('configurator-draw-box:', ''), sum: elem.getTotalLength() });
  }
  else {
    elemFromArray.sum += elem.getTotalLength();
  }
};

export const getTotalLengths = (insertElement) => {
  const { insertedGears } = insertElement;
  const svgsListElem = document.getElementsByClassName('svgs-list')[0];
  const totalSums = [];
  let svgList = svgsListElem.getElementsByClassName('svg-wrapper');
  for( let svgWrapper of svgList ) {

    let circles = svgWrapper.getElementsByTagName('circle');
    for ( let elem of circles) {
      elementsForeachCB(elem, totalSums, svgWrapper.id);
    }
    let paths= svgWrapper.getElementsByTagName('path');
    for (let elem of paths) {
      if (!insertedGears[elem.id]) {
        elementsForeachCB(elem, totalSums, svgWrapper.id);
      }
    }
  }

  return totalSums;
};

export const LENGTH_FACTOR = 0.264583333;
