import React from "react";
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import MenuSection from "./MenuSection";
import MenuItemSeparator from "./MenuItemSeparator";
import MenuItem from "./MenuItem";

class MenuList extends React.Component {
  can = scope => {
    const { role } = this.props;

    return  !scope || (scope && scope.indexOf(role) > -1)
  };

  render() {
    const { currentUrl, menuConfig, layoutConfig, user } = this.props;
    const isScopeAdminUser = (obj) => obj.scope && obj.scope.length === 2 && ['admin', 'user'].every(role => obj.scope.includes(role));

    return menuConfig.aside.items.map((child, index) => {
      return (
          <React.Fragment key={`menuList${index}`}>
            {child.section && this.can(child.scope) && <MenuSection item={child} />}
            {child.separator && this.can(child.scope) && <MenuItemSeparator item={child} />}
            {child.title && this.can(child.scope) && (
                <MenuItem
                    isDisableItem={false}
                    item={child}
                    currentUrl={currentUrl}
                    layoutConfig={layoutConfig}
                />
            )}
            {!user && isScopeAdminUser(child) && (
                <MenuItem
                    isDisableItem={true}
                    item={child}
                    currentUrl={currentUrl}
                    layoutConfig={layoutConfig}
                />
            )}
          </React.Fragment>
      );
    });
  }
}

const mapStateToProps = ({ auth: { user } }) => ({ user });

export default injectIntl(connect(mapStateToProps)(MenuList));
