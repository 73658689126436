import React, { createRef } from 'react';
import { Grid, Typography } from '@mui/material';
import { FormattedMessage, injectIntl } from 'react-intl';

import WestIcon from '@mui/icons-material/West';
import EastIcon from '@mui/icons-material/East';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { toAbsoluteUrl } from '../../../../_metronic/utils/utils';
import ConfiguratorPage from '../configurator/ConfiguratorPage';

const CommonSection = (props) => {
  const { materialType } = props;
  const customSlider = createRef();
  const customersCustomSlider = createRef();

  const carouselSettings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: (
      <div
        className="slick-custom-arrow arrow-next"
        onClick={() => customersCustomSlider.current.slickNext()}
      >
        <EastIcon />
      </div>
    ),
    prevArrow: (
      <div
        className="slick-custom-arrow arrow-next"
        onClick={() => customersCustomSlider.current.slickPrev()}
      >
        <WestIcon />
      </div>
    ),
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const materialsCarouselSettings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: (
      <div
        className="slick-custom-arrow arrow-next"
        onClick={() => customSlider.current.slickNext()}
      >
        <EastIcon />
      </div>
    ),
    prevArrow: (
      <div
        className="slick-custom-arrow arrow-next"
        onClick={() => customSlider.current.slickPrev()}
      >
        <WestIcon />
      </div>
    ),
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const imagesHDPE = [
    toAbsoluteUrl(
      '/clients-logos/hdpe-2-mm-zwart-machine-onderdeel-bmtec-495x400.jpg'
    ),
    toAbsoluteUrl('/clients-logos/hdpe1-495x400.jpg'),
    toAbsoluteUrl('/clients-logos/hdpe-495x400.jpg'),
    toAbsoluteUrl('/clients-logos/hdpe-495x400-1.jpg'),
    toAbsoluteUrl('/clients-logos/hdpe-lasersnijden-495x400.jpg'),
    toAbsoluteUrl(
      '/clients-logos/HDPE-lasersnijden-BMTEC-e1658305472907-495x400.jpg'
    ),
    toAbsoluteUrl('/clients-logos/HDPE-BMTEC-BV-e1658305513150-495x400.jpg'),
    toAbsoluteUrl(
      '/clients-logos/HDPE-8mm-lasersnijden-BMTEC-e1658305539435-495x400.jpg'
    ),
    toAbsoluteUrl(
      '/clients-logos/HDPE-laseren-BMTEC-e1658305480865-495x400.jpg'
    ),
    toAbsoluteUrl(
      '/clients-logos/HDPE-zwart-lasersnijden-BMTEC-e1658305390179-495x400.jpg'
    ),
    toAbsoluteUrl(
      '/clients-logos/HDPE-plaat-lasersnijden-e1658305455887-495x400.jpg'
    ),

    toAbsoluteUrl(
      '/clients-logos/HDPE-1mm-lasersnijden-e1658305564965-495x400.jpg'
    ),
    toAbsoluteUrl('/clients-logos/HDPE-3mm-snijden-e1658305554291-495x400.jpg'),
    toAbsoluteUrl(
      '/clients-logos/HDPE-5mm-lasersnijden-e1658305546302-495x400.jpg'
    ),
    toAbsoluteUrl(
      '/clients-logos/HDPE-10mm-lasersnijden-BMTEC-BV-e1658305521715-495x400.jpg'
    ),
    toAbsoluteUrl(
      '/clients-logos/HDPE-halffabricaten-lasersnijden-BMTEC-e1658305497821-495x400.jpg'
    ),
    toAbsoluteUrl(
      '/clients-logos/HDPE-halffabricaten-snijden-e1658305488672-495x400.jpg'
    ),
    toAbsoluteUrl(
      '/clients-logos/HDPE-pakking-lasersnijden-e1658305464247-495x400.jpg'
    ),
    toAbsoluteUrl(
      '/clients-logos/HDPE-ring-lasersnijden-e1658305443113-495x400.jpg'
    ),
    toAbsoluteUrl(
      '/clients-logos/HDPE-snijden-BMTEC-e1658305432691-495x400.jpg'
    ),
    toAbsoluteUrl(
      '/clients-logos/HDPE-zwart-lasersnijden-e1658305407239-495x400.jpg'
    ),
  ];

  const imagesPOM = [
    toAbsoluteUrl('/clients-logos/Delrin-snijden-1-495x400.jpg'),
    toAbsoluteUrl('/clients-logos/POM-1mm-lasersnijden-495x400.jpg'),
    toAbsoluteUrl('/clients-logos/POM-2mm-wit-laseren-BMTEC-495x400.jpg'),
    toAbsoluteUrl('/clients-logos/POM-2mm-zwart-lasersnijden-495x400.jpg'),
    toAbsoluteUrl('/clients-logos/POM-4mm-lasersnijden-495x400.jpg'),
    toAbsoluteUrl('/clients-logos/POM-6mm-lasersnijden-495x400.jpg'),
    toAbsoluteUrl('/clients-logos/POM-BMTEC-BV-495x400.jpg'),
    toAbsoluteUrl('/clients-logos/POM-BMTEC-lasersnijen-495x400.jpg'),
    toAbsoluteUrl('/clients-logos/POM-delen-lasersnijden-495x400.jpg'),
    toAbsoluteUrl('/clients-logos/POM-halffabricaten-BMTEC-495x400.jpg'),
    toAbsoluteUrl('/clients-logos/POM-laseren-BMTEC-495x400.jpg'),
    toAbsoluteUrl('/clients-logos/POM-lasersnijden-2mm-495x400.jpg'),
    toAbsoluteUrl('/clients-logos/POM-lasersnijden-BMTEC-BV-495x400.jpg'),
    toAbsoluteUrl('/clients-logos/POM-pakking-lasersnijden-495x400.jpg'),
    toAbsoluteUrl('/clients-logos/POM-ring-lasersnijden-495x400.jpg'),
    toAbsoluteUrl('/clients-logos/POM-ringen-lasersnijden-BMTEC-495x400.jpg'),
    toAbsoluteUrl('/clients-logos/POM-ringen-snijden-495x400.jpg'),
    toAbsoluteUrl('/clients-logos/POM-wit-2mm-lasersnijden-BMTEC-495x400.jpg'),
    toAbsoluteUrl('/clients-logos/POM-wit-dik-495x400.jpg'),
    toAbsoluteUrl('/clients-logos/POM-wit-lasersnijden-BMTEC-495x400.jpg'),
    toAbsoluteUrl('/clients-logos/POM-zwart-lasersnijden-BMTEC-495x400.jpg'),
    toAbsoluteUrl('/clients-logos/polyoxymethyleen-lasersnijden-1-495x400.jpg'),
    toAbsoluteUrl('/clients-logos/pom-495x400.jpg'),
    toAbsoluteUrl('/clients-logos/pom-wit-rond-495x400.jpg'),
    toAbsoluteUrl('/clients-logos/pom-wit-tandwiel-495x400.jpg'),
  ];

  const imagesPMMA = [
    toAbsoluteUrl(
      '/clients-logos/PMMA-glashelder-lasersnijden-BMTEC-495x400.jpg'
    ),
    toAbsoluteUrl('/clients-logos/PMMA-laseren-BMTEC-495x400.jpg'),
    toAbsoluteUrl('/clients-logos/PMMA-zwart-lasersnijden-BMTEC-495x400.jpg'),
    toAbsoluteUrl('/clients-logos/PMMA-lasersnijden-495x400.jpg'),
    toAbsoluteUrl('/clients-logos/PMMA-lasersnijden-BMTEC-495x400.jpg'),
    toAbsoluteUrl('/clients-logos/pmma-lasersnijden-495x333.jpg'),
    toAbsoluteUrl('/clients-logos/plexiglas-lasersnijden-1-495x333.jpg'),
  ];

  const imagesHMPE = [
    toAbsoluteUrl('/clients-logos/UHMPE-lasersnijde-BMTEC-BV-845x684.jpg'),
    toAbsoluteUrl('/clients-logos/UHMPE-lasersnijden-BMTEC-845x684.jpg'),
    toAbsoluteUrl('/clients-logos/UHMPE-3mm-lasersnijden-BMTEC-845x684.jpg'),
    toAbsoluteUrl('/clients-logos/HMPE-laseren-845x684.jpg'),
    toAbsoluteUrl('/clients-logos/HMPE-lasersnijden-BMTEC-845x684.jpg'),
    toAbsoluteUrl('/clients-logos/HMPE-lasersnijden-845x684.jpg'),
    toAbsoluteUrl('/clients-logos/HMPE-snijden-845x684.jpg'),
    toAbsoluteUrl('/clients-logos/HMPE-zwart-lasersnijden-845x684.jpg'),
  ];

  const imagesPETG = [
    toAbsoluteUrl('/clients-logos/kunststof-logo-op-maat-snijden-495x400.jpg'),
    toAbsoluteUrl('/clients-logos/PET-G-lasersnijden-1-845x684.jpg'),
    toAbsoluteUrl('/clients-logos/PET-G-lasersnijden-2-845x684.jpg'),
    toAbsoluteUrl('/clients-logos/PET-G-lasersnijden-3-845x684.jpg'),
    toAbsoluteUrl('/clients-logos/PET-G-lasersnijden-4-845x684.jpg'),
    toAbsoluteUrl('/clients-logos/PET-G-lasersnijden-845x684.jpg'),
  ];

  const imagesPTFE = [
    toAbsoluteUrl('/clients-logos/PTFE-lasersnijden-BMTEC-705x470.jpg'),
    toAbsoluteUrl('/clients-logos/ptfe123-min-450x300.jpg'),
  ];

  const imagesPA = [
    toAbsoluteUrl('/clients-logos/PA-ringen-lasersnijden-495x400.jpg'),
    toAbsoluteUrl('/clients-logos/PA-lasersnijden-495x400.jpg'),
    toAbsoluteUrl('/clients-logos/PA-lasersnijden-BMTEC-495x400.jpg'),
    toAbsoluteUrl('/clients-logos/PA-gesneden-495x400.jpg'),
  ];

  const imagesPP = [
    toAbsoluteUrl('/clients-logos/Polypropyleen-495x400.jpg'),
    toAbsoluteUrl(
      '/clients-logos/polypropyleen-lasergesneden-gebogen-495x400.jpg'
    ),
    toAbsoluteUrl('/clients-logos/Polypropyleen-lasersnijden-845x684.jpg'),
    toAbsoluteUrl('/clients-logos/Polypropyleen-PP-845x684.jpg'),
    toAbsoluteUrl('/clients-logos/Polypropyleen-BMTEC-845x684.jpg'),
  ];

  const imagesPI = [
    toAbsoluteUrl('/clients-logos/polyimide-kunststof-495x400.jpg'),
    toAbsoluteUrl('/clients-logos/PI-495x400.png'),
    toAbsoluteUrl('/clients-logos/kapton-kunststof-495x400.jpg'),
  ];

  const imagesABS = [
    toAbsoluteUrl('/clients-logos/ABS-Lasersnijden-BMTEC-min-495x400.jpg'),
    toAbsoluteUrl('/clients-logos/J5A0313-495x400.jpg'),
    toAbsoluteUrl('/clients-logos/J5A0314-495x400.jpg'),
    toAbsoluteUrl('/clients-logos/J5A0321-495x400.jpg'),
    toAbsoluteUrl('/clients-logos/abs-min-495x400.jpg'),
  ];

  const imagesHPL = [
    toAbsoluteUrl('/clients-logos/HPL-495x400.jpg'),
    toAbsoluteUrl('/clients-logos/HPL-Kunststof-Frezen-495x400.jpg'),
    toAbsoluteUrl('/clients-logos/DSC02140-495x400.jpg'),
    toAbsoluteUrl('/clients-logos/DSC02135-495x400.jpg'),
    toAbsoluteUrl('/clients-logos/DSC02136-495x400.jpg'),
  ];

  const imagesEPDM = [
    toAbsoluteUrl('/clients-logos/epdm-snijden.jpg'),
    toAbsoluteUrl('/clients-logos/epdm-flinterdun.jpg'),
    toAbsoluteUrl('/clients-logos/epdm-bewerken-1.jpg'),
    toAbsoluteUrl('/clients-logos/epdm-rubber.jpg'),
    toAbsoluteUrl('/clients-logos/epdm-gefreesd.jpg'),
    toAbsoluteUrl('/clients-logos/epdm-cirkel.jpg'),
    toAbsoluteUrl('/clients-logos/epdm-rubbers-495x333.jpg'),
  ];

  const imagesAlupanel = [toAbsoluteUrl('/clients-logos/Alupanel-Dibond.jpg')];

  const imagesNBR = [toAbsoluteUrl('/materials/nbr-rubber-705x470.jpg')];

  const imagesSBR = [
    toAbsoluteUrl('/clients-logos/sbr-rubber-snijden-495x400.jpg'),
    toAbsoluteUrl('/clients-logos/sbr-rubber-495x400.jpg'),
  ];

  const imagesSILICONE = [toAbsoluteUrl('/materials/siliconen.jpg')];

  const clientsLogos = [
    {
      alt: '300-wavin',
      src: toAbsoluteUrl('/clients-logos/300-wavin.png'),
    },
    {
      alt: 'barco-logo-web-BMTEC',
      src: toAbsoluteUrl('/clients-logos/barco-logo-web-BMTEC.png'),
    },
    {
      alt: 'Festo',
      src: toAbsoluteUrl('/clients-logos/Festo.png'),
    },
    {
      alt: 'FrieslandCampina-BMTEC',
      src: toAbsoluteUrl('/clients-logos/FrieslandCampina-BMTEC.png'),
    },
    {
      alt: 'Honeywell-BMTEC',
      src: toAbsoluteUrl('/clients-logos/Honeywell-BMTEC.png'),
    },
    {
      alt: 'Kuhn-Logo-BMTEC',
      src: toAbsoluteUrl('/clients-logos/Kuhn-Logo-BMTEC.png'),
    },
    {
      alt: 'logo-abb-bmtec',
      src: toAbsoluteUrl('/clients-logos/logo-abb-bmtec.png'),
    },
    {
      alt: 'Logo-Siemens-BMTEC',
      src: toAbsoluteUrl('/clients-logos/Logo-Siemens-BMTEC.png'),
    },
    {
      alt: 'logo-swisslog-bmtec',
      src: toAbsoluteUrl('/clients-logos/logo-swisslog-bmtec.png'),
    },
    {
      alt: 'logo-volvo-bmtec-300x41-1',
      src: toAbsoluteUrl('/clients-logos/logo-volvo-bmtec-300x41-1.png'),
    },
    {
      alt: 'philips',
      src: toAbsoluteUrl('/clients-logos/philips.png'),
    },
    {
      alt: 'resideo-BMTEC',
      src: toAbsoluteUrl('/clients-logos/resideo-BMTEC.png'),
    },
    {
      alt: 'Rolce-royce-1',
      src: toAbsoluteUrl('/clients-logos/Rolce-royce-1.png'),
    },
    {
      alt: 'shell-BMTEC',
      src: toAbsoluteUrl('/clients-logos/shell-BMTEC.png'),
    },
    {
      alt: 'tesla',
      src: toAbsoluteUrl('/clients-logos/tesla.png'),
    },
    {
      alt: 'VDL-Logo.svg',
      src: toAbsoluteUrl('/clients-logos/VDL-Logo.svg.png'),
    },
  ];

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid
        item
        xs={12}
        container
        justifyContent="center"
        className="materials-fancybox"
      >
        <Slider {...materialsCarouselSettings} ref={customSlider}>
          {materialType === 'HDPE' &&
            imagesHDPE.map((imgUrl, idx) => <img key={idx} src={imgUrl} />)}

          {materialType === 'POM' &&
            imagesPOM.map((imgUrl, idx) => <img key={idx} src={imgUrl} />)}

          {materialType === 'PMMA' &&
            imagesPMMA.map((imgUrl, idx) => <img key={idx} src={imgUrl} />)}

          {materialType === 'HMPE' &&
            imagesHMPE.map((imgUrl, idx) => <img key={idx} src={imgUrl} />)}

          {materialType === 'PETG' &&
            imagesPETG.map((imgUrl, idx) => <img key={idx} src={imgUrl} />)}

          {materialType === 'PA' &&
            imagesPA.map((imgUrl, idx) => <img key={idx} src={imgUrl} />)}

          {materialType === 'PP' &&
            imagesPP.map((imgUrl, idx) => <img key={idx} src={imgUrl} />)}

          {materialType === 'ABS' &&
            imagesABS.map((imgUrl, idx) => <img key={idx} src={imgUrl} />)}

          {materialType === 'HPL' &&
            imagesHPL.map((imgUrl, idx) => <img key={idx} src={imgUrl} />)}

          {materialType === 'EPDM' &&
            imagesEPDM.map((imgUrl, idx) => <img key={idx} src={imgUrl} />)}
        </Slider>

        {materialType === 'PTFE' &&
          imagesPTFE.map((imgUrl, idx) => <img key={idx} src={imgUrl} />)}

        {materialType === 'PI' &&
          imagesPI.map((imgUrl, idx) => <img key={idx} src={imgUrl} />)}

        {materialType === 'ALUPANEL' &&
          imagesAlupanel.map((imgUrl, idx) => (
            <img style={{ marginBottom: '30px' }} key={idx} src={imgUrl} />
          ))}

        {materialType === 'NBR' &&
          imagesNBR.map((imgUrl, idx) => <img key={idx} src={imgUrl} />)}

        {materialType === 'SBR' &&
          imagesSBR.map((imgUrl, idx) => <img key={idx} src={imgUrl} />)}

        {materialType === 'SILICONE' &&
          imagesSILICONE.map((imgUrl, idx) => <img key={idx} src={imgUrl} />)}
      </Grid>

      <Grid item xs={12} container className="bp-main-section">
        <Grid item xs={12} container justifyContent="center">
          <Typography variant="h1">
            <FormattedMessage id="Productconfiguratie" />
          </Typography>
        </Grid>

        <Grid item xs={12} container justifyContent="center">
          <ConfiguratorPage isMainPage={true} />
        </Grid>
      </Grid>

      <Grid item xs={12} container justifyContent="center">
        <Typography
          variant="h5"
          style={{ marginTop: '50px', color: 'black', fontWeight: 'bold' }}
        >
          <FormattedMessage id="Vertrouwd door engineers van" />:
        </Typography>
      </Grid>

      <Grid
        item
        xs={12}
        container
        justifyContent="center"
        className="clients-logos-section"
      >
        <Slider {...carouselSettings} ref={customersCustomSlider}>
          {clientsLogos.map((item, i) => (
            <img key={i} src={item.src} alt={item.alt} />
          ))}
        </Slider>
      </Grid>
    </Grid>
  );
};

export default injectIntl(CommonSection);
