import React, { useState, useEffect, useRef } from 'react';
import {
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tabs,
  Tab,
  Box,
} from '@mui/material';
import { FormattedMessage, injectIntl } from 'react-intl';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

import { API_URL, API_URLS } from '../../../common/constants/api/apiConstants';
import {
  toAbsoluteUrl,
  mergeObjInArray,
} from '../../../../_metronic/utils/utils';
import initApi from '../../../crud/apiConfig/apiConfig';
import ConfiguratorPage from '../configurator/ConfiguratorPage';

import PDFDownloader from './PDFDownloader';
import CommonSection from './CommonSection';
import HowItWorks from './HowItWorks';

const HDPEMaterialLandingPage = (props) => {
  const { intl } = props;
  const isMounted = useRef(false);

  const [index, setIndex] = useState(null);
  const [topPanel2, setTopPanel2] = useState(0);

  const [materialData, setMaterialData] = useState([]);

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    isMounted.current = true;
    const updateWindowSize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    const scrollToTop = () => {
      window.scrollTo(0, 0);
    };
    scrollToTop();
    window.addEventListener('beforeunload', scrollToTop);

    window.addEventListener('resize', updateWindowSize);
    setTopPanel2(
      document.getElementById('panel2a-header').getBoundingClientRect().top -
        150
    );

    return () => {
      isMounted.current = false;
      window.removeEventListener('resize', updateWindowSize);
      window.removeEventListener('beforeunload', scrollToTop);
    };
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      getMaterialData('HDPE');
    }
  }, [isMounted]);

  useEffect(() => {
    setTopPanel2(
      document.getElementById('panel2a-header').getBoundingClientRect().top -
        150
    );
  }, [materialData, windowSize]);

  const [tabValue, setTabValue] = useState(0);

  const TabPanel = (props) => {
    const { children, value, index } = props;

    return (
      <div
        role="tabPanel"
        hidden={value !== index}
        id={`tabPanel-${index}`}
        style={{ width: '100%' }}
      >
        {value === index && (
          <Box p={3}>
            <Typography component="div">{children}</Typography>
          </Box>
        )}
      </div>
    );
  };

  const handleTabChange = (event, value) => {
    event.preventDefault();

    setTabValue(value);
  };

  const getMaterialData = async (material_name) => {
    let { data } = await initApi().get(API_URL + API_URLS.GET_MATERIALS_DATA);

    const filteredMaterialData = data.filter(
      (item) => item.name === material_name
    );

    setMaterialData(mergeObjInArray(filteredMaterialData));
  };

  return (
    <Grid container justifyContent="center" alignItems="center">
      {/* <Grid item container xs={12} className="hb-section"></Grid> */}

      <Grid item xs={12} container className="bp-main-section">
        <Grid item xs={12} container justifyContent="center">
          <Typography variant="h1">
            <FormattedMessage id="HDPE.TITLE" />
          </Typography>

          <Typography variant="p">
            <FormattedMessage id="HDPE.DESC" />
          </Typography>
        </Grid>
        <Grid item xs={12} container justifyContent="center">
          <ConfiguratorPage isMainPage={true} />
        </Grid>
      </Grid>

      <HowItWorks materialType="HDPE" />

      <Grid item xs={12} container justifyContent="center" className="tab-list">
        <Grid item sm={10} xs={12} sx={{ border: 1, borderColor: 'divider' }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab
              label={intl.formatMessage({ id: 'Materiaal informatie' })}
              index={0}
            />
            <Tab
              label={intl.formatMessage({ id: 'Technische informatie' })}
              index={1}
            />
            <Tab label={intl.formatMessage({ id: 'Datasheets' })} index={2} />
          </Tabs>
        </Grid>

        <TabPanel value={tabValue} index={0}>
          <table
            className="material-info-table table table-striped w-100"
            style={{ marginBottom: 0, overflowX: 'auto' }}
          >
            <thead>
              <tr>
                <th colSpan="3" style={{ fontSize: '18px' }}>
                  <FormattedMessage id="Productinformatie" />
                </th>
                <th colSpan="4" style={{ fontSize: '18px' }}>
                  <FormattedMessage id="Bewerkingen" />
                </th>
              </tr>

              <tr>
                <th scope="col" className={'w-15'}>
                  <FormattedMessage id="Plaat diktes" />
                </th>
                <th scope="col" className={'w-15'}>
                  <FormattedMessage id="Plaat afmetingen" />
                </th>
                <th scope="col" className={'w-15'}>
                  <FormattedMessage id="Kleuren" />
                </th>
                <th scope="col" className={'w-15 col-with-icon'}>
                  <span>
                    <FormattedMessage id="Te laseren diktes" />
                    <i className="cw-materials-icon icon-laser"></i>
                  </span>
                </th>
                <th scope="col" className={'w-15 col-with-icon'}>
                  <span>
                    <FormattedMessage id="Te frezen diktes" />
                    <i className="cw-materials-icon icon-milling"></i>
                  </span>
                </th>
                <th scope="col" className={'w-15 col-with-icon'}>
                  <span>
                    <FormattedMessage id="Zettingen mogelijk" />
                    <i className="cw-materials-icon icon-bending"></i>
                  </span>
                </th>
                <th scope="col" className={'w-15'}>
                  <FormattedMessage id="Verlijmbaar" />
                </th>
              </tr>
            </thead>
            <tbody>
              {materialData.map(
                ({
                  thickness,
                  plate_measurement,
                  color,
                  color_de,
                  color_en,
                  has_to_be_milled,
                  is_bendable,
                }) => {
                  return (
                    <tr key={thickness + color}>
                      <td>{thickness} mm</td>
                      <td>{plate_measurement} mm</td>
                      <td className="td-flex">
                        {intl?.locale === 'nl' &&
                          color.map((item, idx) => (
                            <span key={idx + item}>{item}</span>
                          ))}

                        {intl?.locale === 'de' &&
                          color_de.map((item, idx) => (
                            <span key={idx + item}>{item}</span>
                          ))}

                        {intl?.locale === 'en' &&
                          color_en.map((item, idx) => (
                            <span key={idx + item}>{item}</span>
                          ))}
                      </td>
                      <td className="td-flex">
                        {has_to_be_milled?.map((item, idx) =>
                          item == false ? (
                            <span key={idx + color}>
                              <CheckIcon htmlColor="#7ed321" />
                            </span>
                          ) : (
                            <span key={idx + color}>
                              <CloseIcon htmlColor="#ff0000" />
                            </span>
                          )
                        )}
                      </td>
                      <td className="td-flex">
                        {has_to_be_milled?.map((item, idx) =>
                          item == false ? (
                            <span key={idx + color}>
                              <CloseIcon htmlColor="#ff0000" />
                            </span>
                          ) : (
                            <span key={idx + color}>
                              <CheckIcon htmlColor="#7ed321" />
                            </span>
                          )
                        )}
                      </td>
                      <td className="td-flex" align="center">
                        {color.map((idx, item) =>
                          is_bendable == false ? (
                            <span key={idx + item}>
                              <CloseIcon htmlColor="#ff0000" />
                            </span>
                          ) : (
                            <span key={idx + item}>
                              <CheckIcon htmlColor="#7ed321" />
                            </span>
                          )
                        )}
                      </td>
                      <td className="td-flex" align="center">
                        {color.map((idx, item) => (
                          <span key={idx + item}>
                            <CloseIcon htmlColor="#ff0000" />
                          </span>
                        ))}
                      </td>
                    </tr>
                  );
                }
              )}
            </tbody>
          </table>

          <table
            className="material-info-table table table-striped w-100"
            style={{ marginBottom: 0, overflowX: 'auto' }}
          >
            <thead>
              <tr>
                <th scope="col" className={'w-50'} style={{ fontSize: '18px' }}>
                  <FormattedMessage id="Toepassingen" />
                </th>
                <th scope="col" className={'w-50'} style={{ fontSize: '18px' }}>
                  <FormattedMessage id="Verdere eigenschappen" />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <ul>
                    <li>
                      <CheckIcon
                        htmlColor="#7ed321"
                        style={{ marginRight: '8px' }}
                      />
                      <FormattedMessage id="CHECK.LIST_1.ITEM_1" />
                    </li>
                    <li>
                      <CheckIcon
                        htmlColor="#7ed321"
                        style={{ marginRight: '8px' }}
                      />
                      <FormattedMessage id="CHECK.LIST_1.ITEM_2" />
                    </li>
                    <li>
                      <CheckIcon
                        htmlColor="#7ed321"
                        style={{ marginRight: '8px' }}
                      />
                      <FormattedMessage id="CHECK.LIST_1.ITEM_3" />
                    </li>
                    <li>
                      <CheckIcon
                        htmlColor="#7ed321"
                        style={{ marginRight: '8px' }}
                      />
                      <FormattedMessage id="CHECK.LIST_1.ITEM_4" />
                    </li>
                    <li>
                      <CheckIcon
                        htmlColor="#7ed321"
                        style={{ marginRight: '8px' }}
                      />
                      <FormattedMessage id="CHECK.LIST_1.ITEM_5" />
                    </li>
                    <li>
                      <CheckIcon
                        htmlColor="#7ed321"
                        style={{ marginRight: '8px' }}
                      />
                      <FormattedMessage id="CHECK.LIST_1.ITEM_6" />
                    </li>
                    <li>
                      <CheckIcon
                        htmlColor="#7ed321"
                        style={{ marginRight: '8px' }}
                      />
                      <FormattedMessage id="CHECK.LIST_1.ITEM_7" />
                    </li>
                  </ul>
                </td>

                <td>
                  <ul>
                    <li>
                      <CheckIcon
                        htmlColor="#7ed321"
                        style={{ marginRight: '8px' }}
                      />
                      <FormattedMessage id="CHECK.LIST_2.ITEM_1" />
                    </li>
                    <li>
                      <CheckIcon
                        htmlColor="#7ed321"
                        style={{ marginRight: '8px' }}
                      />
                      <FormattedMessage id="CHECK.LIST_2.ITEM_2" />
                    </li>
                    <li>
                      <CheckIcon
                        htmlColor="#7ed321"
                        style={{ marginRight: '8px' }}
                      />
                      <FormattedMessage id="CHECK.LIST_2.ITEM_3" />
                    </li>
                    <li>
                      <CheckIcon
                        htmlColor="#7ed321"
                        style={{ marginRight: '8px' }}
                      />
                      <FormattedMessage id="CHECK.LIST_2.ITEM_4" />
                    </li>
                    <li>
                      <CheckIcon
                        htmlColor="#7ed321"
                        style={{ marginRight: '8px' }}
                      />
                      <FormattedMessage id="CHECK.LIST_2.ITEM_5" />
                    </li>
                    <li>
                      <CheckIcon
                        htmlColor="#7ed321"
                        style={{ marginRight: '8px' }}
                      />
                      <FormattedMessage id="CHECK.LIST_2.ITEM_6" />
                    </li>
                    <li>
                      <CheckIcon
                        htmlColor="#7ed321"
                        style={{ marginRight: '8px' }}
                      />
                      <FormattedMessage id="CHECK.LIST_2.ITEM_7" />
                    </li>
                    <li>
                      <CheckIcon
                        htmlColor="#7ed321"
                        style={{ marginRight: '8px' }}
                      />
                      <FormattedMessage id="CHECK.LIST_2.ITEM_8" />
                    </li>
                    <li>
                      <CheckIcon
                        htmlColor="#7ed321"
                        style={{ marginRight: '8px' }}
                      />
                      <FormattedMessage id="CHECK.LIST_2.ITEM_9" />
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <table
            className="material-info-table table table-striped w-100"
            style={{
              border: '1px solid rgba(0, 0, 0, 0.12)',
              overflowX: 'auto',
            }}
          >
            <thead>
              <tr>
                <th
                  colSpan={4}
                  scope="col"
                  className={'w-100'}
                  style={{ fontSize: '18px' }}
                >
                  <FormattedMessage id="Algemeen" />
                </th>
              </tr>
              <tr scope="col" className={'w-100'}>
                <th scope="col" className={'w-30'}>
                  <FormattedMessage id="Eigenschap" />
                </th>
                <th scope="col" className={'w-20'}>
                  <FormattedMessage id="Norm" />
                </th>
                <th scope="col" className={'w-15'}>
                  <FormattedMessage id="Eenheid" />
                </th>
                <th scope="col" className={'w-15'}>
                  <FormattedMessage id="Waarde" />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <FormattedMessage id="Soortelijke massa" />
                </td>
                <td>ISO 1183</td>
                <td>g/cm&#179;</td>
                <td>0,95</td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="Vochtopname bij normaal klimaat" />
                </td>
                <td>-</td>
                <td>%</td>
                <td>0,05</td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="Brandgedrag" />
                </td>
                <td>DIN 4102</td>
                <td>-</td>
                <td>B 2</td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="Specifieke warmtecapaciteit" />
                </td>
                <td>-</td>
                <td>kj/Kg°C</td>
                <td>2,5</td>
              </tr>
            </tbody>
          </table>
          <table
            className="material-info-table table table-striped w-100"
            style={{
              border: '1px solid rgba(0, 0, 0, 0.12)',
              overflowX: 'auto',
            }}
          >
            <thead>
              <tr>
                <th
                  colSpan={4}
                  scope="col"
                  className={'w-100'}
                  style={{ fontSize: '18px' }}
                >
                  <FormattedMessage id="Mechanisch" />
                </th>
              </tr>
              <tr scope="col" className={'w-100'}>
                <th scope="col" className={'w-30'}>
                  <FormattedMessage id="Eigenschap" />
                </th>
                <th scope="col" className={'w-20'}>
                  <FormattedMessage id="Norm" />
                </th>
                <th scope="col" className={'w-15'}>
                  <FormattedMessage id="Eenheid" />
                </th>
                <th scope="col" className={'w-15'}>
                  <FormattedMessage id="Waarde" />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <FormattedMessage id="Treksterkte" />
                </td>
                <td>DIN 53455</td>
                <td>N/mm</td>
                <td>22</td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="Rek tot breuk" />
                </td>
                <td>DIN 53455</td>
                <td>%</td>
                <td>&gt;500</td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="E-Modulus" />
                </td>
                <td>DIN 53457 (23 °C)</td>
                <td>N/mm&#178;</td>
                <td>800</td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="Buigsterkte" />
                </td>
                <td>DIN 53452</td>
                <td>N/mm&#178;</td>
                <td>25</td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="Slagsterkte" />
                </td>
                <td>DIN 53453 (23 °C)</td>
                <td>Charpy</td>
                <td>12</td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="Shore of kogeldruk hardheid" />
                </td>
                <td>-</td>
                <td>Shore °D</td>
                <td>63</td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="Wrijvingscoëfficiënt" />
                </td>
                <td>-</td>
                <td>-</td>
                <td>0,25</td>
              </tr>
            </tbody>
          </table>
          <table
            className="material-info-table table table-striped w-100"
            style={{
              border: '1px solid rgba(0, 0, 0, 0.12)',
              overflowX: 'auto',
            }}
          >
            <thead>
              <tr>
                <th
                  colSpan={4}
                  scope="col"
                  className={'w-100'}
                  style={{ fontSize: '18px' }}
                >
                  <FormattedMessage id="Elektrisch" />
                </th>
              </tr>
              <tr scope="col" className={'w-100'}>
                <th scope="col" className={'w-30'}>
                  <FormattedMessage id="Eigenschap" />
                </th>
                <th scope="col" className={'w-20'}>
                  <FormattedMessage id="Norm" />
                </th>
                <th scope="col" className={'w-15'}>
                  <FormattedMessage id="Eenheid" />
                </th>
                <th scope="col" className={'w-15'}>
                  <FormattedMessage id="Waarde" />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <FormattedMessage id="Relatieve elektrische constante" />
                </td>
                <td>DIN 53485 (100Hz)</td>
                <td>100 Hz</td>
                <td>2,3</td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="Diëlektrische verliesfactor" />
                </td>
                <td>DIN 53483 (100Hz)</td>
                <td>tan (..x10&#x207B;&#179;)</td>
                <td>0,0002</td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="Specifieke weerstand" />
                </td>
                <td>DIN 53482</td>
                <td>16 &Omega; cm</td>
                <td>&gt;10</td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="Oppervlakte weerstand" />
                </td>
                <td>DIN 53482</td>
                <td>13 &Omega;</td>
                <td>&gt;10</td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="Kruipstroomvastheid" />
                </td>
                <td>DIN 53480</td>
                <td>KC</td>
                <td>&gt;600</td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="Doorslagspanning" />
                </td>
                <td>DIN 53481</td>
                <td>kV/mm</td>
                <td>50</td>
              </tr>
            </tbody>
          </table>
          <table
            className="material-info-table table table-striped w-100"
            style={{
              border: '1px solid rgba(0, 0, 0, 0.12)',
              overflowX: 'auto',
            }}
          >
            <thead>
              <tr>
                <th
                  colSpan={4}
                  scope="col"
                  className={'w-100'}
                  style={{ fontSize: '18px' }}
                >
                  <FormattedMessage id="Thermisch" />
                </th>
              </tr>
              <tr scope="col" className={'w-100'}>
                <th scope="col" className={'w-30'}>
                  <FormattedMessage id="Eigenschap" />
                </th>
                <th scope="col" className={'w-20'}>
                  <FormattedMessage id="Norm" />
                </th>
                <th scope="col" className={'w-15'}>
                  <FormattedMessage id="Eenheid" />
                </th>
                <th scope="col" className={'w-15'}>
                  <FormattedMessage id="Waarde" />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <FormattedMessage id="Kristallijn smeltpunt" />
                </td>
                <td>-</td>
                <td>&#176;C</td>
                <td>126-135</td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="Warmte geleidbaarheid" />
                </td>
                <td>DIN 52162</td>
                <td>W/m&#176;K</td>
                <td>0,38</td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="Lineaire uitzettingscoëfficiënt" />
                </td>
                <td>DIN 53762</td>
                <td>mm/m&#176;C</td>
                <td>0,18</td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="Gebruikstemperatuur lange termijn onbelast" />
                </td>
                <td>-</td>
                <td>&#176;C</td>
                <td>-50/80</td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="Maximale gebruikstemperatur korte termijn" />
                </td>
                <td>-</td>
                <td>&#176;C</td>
                <td>100</td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="Hitte vormbestendigheid" />
                </td>
                <td>DIN 53461</td>
                <td>&#176;C</td>
                <td>48</td>
              </tr>
            </tbody>
          </table>
          <table
            className="material-info-table table table-striped w-100"
            style={{
              border: '1px solid rgba(0, 0, 0, 0.12)',
              overflowX: 'auto',
            }}
          >
            <thead>
              <tr>
                <th
                  colSpan={2}
                  scope="col"
                  className={'w-100'}
                  style={{ fontSize: '18px' }}
                >
                  <FormattedMessage id="Temperatuurbereik" />
                </th>
              </tr>
              <tr scope="col" className={'w-100'}>
                <th scope="col" className={'w-50'}>
                  <FormattedMessage id="Eigenschap" />
                </th>
                <th scope="col" className={'w-50'}>
                  <FormattedMessage id="Eenheid" />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <FormattedMessage id="Minimale gebruikstemperatuur" />
                </td>
                <td>-40&#176;C</td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="Maximale gebruikstemperatuur" />{' '}
                </td>
                <td>+80&#176;C</td>
              </tr>
            </tbody>
          </table>
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <PDFDownloader
            title="Tolerance sheet thickness HDPE - PE100-300 CutWise"
            pdfUrl="https://api.prod.cutwise.nl/assets/images/Tolerances_HDPE-PE100_CutWise.pdf"
            pdfFileName="toleranties"
          />

          <PDFDownloader
            title="CutWise Datasheet HDPE – PE100-300"
            pdfUrl="https://api.prod.cutwise.nl/assets/images/Datasheet_HDPE-PE100_CutWise.pdf"
            pdfFileName="technisch_datablad"
          />
        </TabPanel>
      </Grid>

      <Grid
        item
        container
        xs={12}
        sm={12}
        md={12}
        alignItems="center"
        justifyContent="center"
        className="materials-info-section"
      >
        <Grid item container xs={12} sm={10} alignItems="flex-start">
          <Grid item sm={6} xs={12}>
            <Grid item xs={12}>
              <Typography variant="h2">
                <FormattedMessage id="HDPE.LASERSNIJDEN" />
              </Typography>
              <Typography variant="body1">
                <FormattedMessage id="HDPE.LASERSNIJDEN.BODY1" />
              </Typography>

              <Typography variant="body1">
                <FormattedMessage id="HDPE.LASERSNIJDEN.BODY2" />
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h2">
                <FormattedMessage id="HDPE.FREZEN" />
              </Typography>
              <Typography variant="body1">
                <FormattedMessage id="HDPE.FREZEN.BODY" />
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h2">
                <FormattedMessage id="HDPE.DESC.TITLE" />
              </Typography>
              <Typography variant="body1">
                <FormattedMessage id="HDPE.DESC.BODY1" />
              </Typography>

              <Typography variant="body1">
                <FormattedMessage id="HDPE.DESC.BODY2" />
              </Typography>
            </Grid>
          </Grid>

          <Grid item sm={6} xs={12} container justifyContent="center">
            <img
              src={toAbsoluteUrl('/clients-logos/materials-hb.svg')}
              width="100%"
              height="auto"
              style={{ padding: '20px', maxWidth: '474px' }}
            />
          </Grid>

          <Grid item xs={12} className="accordion-list">
            <Accordion
              TransitionProps={{ unmountOnExit: true }}
              expanded={index === 0}
              onChange={(event, expanded) => {
                event.preventDefault();

                setIndex(expanded ? 0 : null);
              }}
            >
              <AccordionSummary
                expandIcon={
                  index === 0 ? (
                    <RemoveCircleOutlineIcon fontSize="large" />
                  ) : (
                    <AddCircleOutlineIcon fontSize="large" />
                  )
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="body1">
                  <FormattedMessage id="HDPE.ACCORDION_1.TITLE" />
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body2">
                  <FormattedMessage id="HDPE.ACCORDION_1.BODY_1" />
                </Typography>
                <Typography variant="body2">
                  <FormattedMessage id="HDPE.ACCORDION_1.BODY_2" />
                </Typography>
                <Typography variant="body2">
                  <FormattedMessage id="HDPE.ACCORDION_1.LIST.TITLE" />
                </Typography>

                <ul>
                  <li>
                    <FormattedMessage id="HDPE.ACCORDION_1.LIST.LIST_1" />
                  </li>
                  <li>
                    <FormattedMessage id="HDPE.ACCORDION_1.LIST.LIST_2" />
                  </li>
                  <li>
                    <FormattedMessage id="HDPE.ACCORDION_1.LIST.LIST_3" />
                  </li>
                  <li>
                    <FormattedMessage id="HDPE.ACCORDION_1.LIST.LIST_4" />
                  </li>
                  <li>
                    <FormattedMessage id="HDPE.ACCORDION_1.LIST.LIST_5" />
                  </li>
                  <li>
                    <FormattedMessage id="HDPE.ACCORDION_1.LIST.LIST_6" />
                  </li>
                  <li>
                    <FormattedMessage id="HDPE.ACCORDION_1.LIST.LIST_7" />
                  </li>
                  <li>
                    <FormattedMessage id="HDPE.ACCORDION_1.LIST.LIST_8" />
                  </li>
                  <li>
                    <FormattedMessage id="HDPE.ACCORDION_1.LIST.LIST_9" />
                  </li>
                </ul>

                {intl?.locale === 'nl' && (
                  <iframe
                    title="BMTEC - HDPE lasersnijden"
                    width="815"
                    height="580"
                    src="https://www.youtube.com/embed/g2Sb4yZVw_w?list=PLH0Fk8QsI11zJJAUr5uKVeSv1ycTKP0IW&amp;wmode=opaque&amp;rel=0"
                    style={{ border: 0 }}
                    loading="lazy"
                    allowFullScreen
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                )}
              </AccordionDetails>
            </Accordion>
            <Accordion
              TransitionProps={{ unmountOnExit: true }}
              expanded={index === 1}
              onChange={(event, expanded) => {
                event.preventDefault();

                setIndex(expanded ? 1 : null);
                window.scrollTo(0, topPanel2);
              }}
            >
              <AccordionSummary
                expandIcon={
                  index === 1 ? (
                    <RemoveCircleOutlineIcon fontSize="large" />
                  ) : (
                    <AddCircleOutlineIcon fontSize="large" />
                  )
                }
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography variant="body1">
                  <FormattedMessage id="HDPE.ACCORDION_2.TITLE" />
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body2">
                  <FormattedMessage id="HDPE.ACCORDION_2.BODY" />
                </Typography>
                <Typography variant="body2">
                  <FormattedMessage id="HDPE.ACCORDION_2.LIST.TITLE" />
                </Typography>
                <ul>
                  <li>
                    <FormattedMessage id="HDPE.ACCORDION_2.LIST.LIST_1" />
                  </li>
                  <li>
                    <FormattedMessage id="HDPE.ACCORDION_2.LIST.LIST_2" />
                  </li>
                  <li>
                    <FormattedMessage id="HDPE.ACCORDION_2.LIST.LIST_3" />
                  </li>
                  <li>
                    <FormattedMessage id="HDPE.ACCORDION_2.LIST.LIST_4" />
                  </li>
                  <li>
                    <FormattedMessage id="HDPE.ACCORDION_2.LIST.LIST_5" />
                  </li>
                  <li>
                    <FormattedMessage id="HDPE.ACCORDION_2.LIST.LIST_6" />
                  </li>
                  <li>
                    <FormattedMessage id="HDPE.ACCORDION_2.LIST.LIST_7" />
                  </li>
                </ul>
              </AccordionDetails>
            </Accordion>
            <Accordion
              TransitionProps={{ unmountOnExit: true }}
              expanded={index === 2}
              onChange={(event, expanded) => {
                event.preventDefault();

                setIndex(expanded ? 2 : null);
                window.scrollTo(0, topPanel2 + 200);
              }}
            >
              <AccordionSummary
                expandIcon={
                  index === 2 ? (
                    <RemoveCircleOutlineIcon fontSize="large" />
                  ) : (
                    <AddCircleOutlineIcon fontSize="large" />
                  )
                }
                aria-controls="panel3a-content"
                id="panel3a-header"
              >
                <Typography variant="body1">
                  <FormattedMessage id="HDPE.ACCORDION_3.TITLE" />
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body2">
                  <FormattedMessage id="HDPE.ACCORDION_3.BODY" />
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </Grid>

      <CommonSection materialType="HDPE" />
    </Grid>
  );
};

export default injectIntl(HDPEMaterialLandingPage);
