import { API_URLS } from '../../common/constants/api/apiConstants';
import initApi from '../../crud/apiConfig/apiConfig';

export function getShippingCostsQuery() {
  return initApi().get(API_URLS.GET_SHIPPING_COSTS_DATA);
}

export const getShippingCostQuery = id => {
  return initApi().get(API_URLS.GET_SHIPPING_COST_DETAILED(id));
};

export const deleteShippingCostQuery = id => {
  return initApi().delete(API_URLS.GET_SHIPPING_COST_DETAILED(id));
};

export const updateShippingCostQuery = ({ id, data }) => {
  if (id) {
    return initApi().put(API_URLS.GET_SHIPPING_COST_DETAILED(id), data);
  } else {
    return initApi().post(API_URLS.GET_SHIPPING_COSTS_DATA, data);
  }
};
