import React, { useState, useEffect } from 'react';
import { Container, Image, Col, Row } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import idealImage from '../../../../../assets/images/icons_mollie/payments/ideal@2x.png';
import paypalImage from '../../../../../assets/images/icons_mollie/payments/paypal@2x.png';
import bancontactImage from '../../../../../assets/images/icons_mollie/payments/bancontact@2x.png';
import sepaImage from '../../../../../assets/images/cutwise-overschrijving-icoon@2x.png';

export const Payment = ({
  payment_type,
  paymentTypes,
  onAccountDisabled,
  changePaymentType,
}) => {
  const intl = useIntl();
  const [isActive, setIsActive] = useState(null);

  useEffect(() => {
    paymentTypes &&
      paymentTypes.map((item) => {
        item.value === payment_type && setIsActive(item.id);
      });
  }, [payment_type]);

  const getPaymentImage = (type) => {
    switch (type) {
      case 'ideal':
        return (
          <Image
            src={idealImage}
            className={'payment-image p-2'}
            height={'60px'}
            title={intl.formatMessage({ id: `CHECKOUT.${type.toUpperCase()}` })}
          />
        );
      case 'paypal':
        return (
          <Image
            src={paypalImage}
            className={'payment-image p-2'}
            height={'60px'}
            title={intl.formatMessage({ id: `CHECKOUT.${type.toUpperCase()}` })}
          />
        );
      case 'bancontact':
        return (
          <Image
            src={bancontactImage}
            className={'payment-image p-2'}
            height={'60px'}
            title={intl.formatMessage({ id: `CHECKOUT.${type.toUpperCase()}` })}
          />
        );
      case 'on_account':
        return (
          <Image
            src={sepaImage}
            className={'payment-image p-2'}
            height={'60px'}
            title={intl.formatMessage({ id: `CHECKOUT.${type.toUpperCase()}` })}
          />
        );
    }
  };

  const updatePayment = (item) => {
    setIsActive(item.id);
    changePaymentType(item);
  };

  return (
    <payment-partial>
      <Row className={'mt-5'}>
        <Col as={'h6'} className={'font-weight-bold'}>
          <FormattedMessage id={'CHECKOUT.PAYMENT'} />
        </Col>
      </Row>
      <Container className={'mt-4 mb-3'}>
        <Row className={'payment-list'}>
          {paymentTypes &&
            paymentTypes.map((item) => {
              return (
                <Col
                  className={
                    item.value == 'on_account' && onAccountDisabled()
                      ? 'payment-item disabled text-center'
                      : isActive == item.id &&
                        item.value == 'on_account' &&
                        !onAccountDisabled()
                      ? 'payment-item active text-center'
                      : isActive == item.id
                      ? 'payment-item text-center active'
                      : 'payment-item text-center'
                  }
                  key={item.id}
                  onClick={() => {
                    ((item.value == 'on_account' && !onAccountDisabled()) ||
                      item.value != 'on_account') &&
                      updatePayment(item);
                  }}
                >
                  {getPaymentImage(item.value)}
                  <h6 className={`font-weight-bold ${item.value}`}>
                    <FormattedMessage
                      id={`CHECKOUT.${item.value.toUpperCase()}`}
                      defaultMessage={item.title}
                    />
                  </h6>
                  <div className={'text-success'}>
                    {item.value != 'on_account' ? '-2%' : ''}
                  </div>
                </Col>
              );
            })}
        </Row>
      </Container>
      <p>
        <small>
          *<FormattedMessage id={'CHECKOUT.ON_ACCOUNT_WARNING'} />
        </small>
      </p>
    </payment-partial>
  );
};
