import { handleActions } from "redux-actions";
import {
  getVats,
  getVatsSuccess,
  getVatsError,
  getVat,
  getVatSuccess,
  getVatError,
  updateVat,
  updateVatSuccess,
  updateVatError,
  deleteVat,
  deleteVatSuccess,
  deleteVatError,
} from "./actions";

const initialState = {
  isProcessing: false,
  data: [],
  error: "",
  pagination: {
    per_page: 10,
    current_page: 1,
    count: 0
  },
  detailedVat: {
    isProcessing: false,
    data: null,
    status: {
      isProcessing: false
    }
  },
};

const handlers = {
  [getVats]: (state) => ({
    ...state,
    isProcessing: true,
  }),
  [getVatsSuccess]: (state, action) => ({
    ...state,
    isProcessing: false,
    data: action.payload,
    // data: payload.quotes,
    pagination: {
      ...state.pagination,
      count: (action.payload && action.payload.length) || 0
    }
  }),
  [getVatsError]: (state, action) => ({
    ...state,
    isProcessing: false,
    error: action.payload
  }),
  [getVat]: (state) => ({
    ...state,
    detailedVat: {
      ...state.detailedVat,
      isProcessing: true
    }
  }),
  [getVatSuccess]: (state, action) => ({
    ...state,
    detailedVat: {
      ...state.detailedVat,
      isProcessing: false,
      data: action.payload
    }
  }),
  [getVatError]: (state, action) => ({
    ...state,
    detailedVat: {
      ...state.detailedVat,
      isProcessing: false,
      error: action.payload
    }
  }),
  [updateVat]: (state) => ({
    ...state,
    detailedVat: {
      ...state.detailedVat,
      isProcessing: true,
    }
  }),
  [updateVatSuccess]: (state) => ({
    ...state,
    detailedVat: {
      ...initialState.detailedVat,
      isProcessing: false,
    }
  }),
  [updateVatError]: (state, action) => ({
    ...state,
    detailedVat: {
      ...state.detailedVat,
      isProcessing: false,
      status: {
        ...state.detailedVat.status,
        error: action.payload
      }
    }
  }),
  [deleteVat]: (state) => ({
    ...state,
  }),
  [deleteVatSuccess]: (state) => ({
    ...state,
  }),
  [deleteVatError]: (state) => ({
    ...state,
  }),
};

export default handleActions(handlers, initialState);
