import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import axios from 'axios';

const AddressForm = ({
  onChangeAddressValue,
  changeAddress,
  countries,
  type,
  order,
  errors,
  setErrors,
}) => {
  const {
    company_name,
    first_name,
    last_name,
    address,
    house_number,
    ext,
    postal_code,
    city,
    country,
    phone_number,
    tax_number,
    tax_number_valid,
  } = changeAddress;

  const intl = useIntl();
  const controller = new AbortController();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://ipapi.co/json/');

        let initialCountry = countries.find((country) => {
          return country?.name == response.data?.country_name;
        }) || { id: 1, name: 'Netherlands', code: 'NL' };

        if (
          (type == 'Shipping' || type == 'shipping') &&
          Object.values(country).every((value) => value === null)
        ) {
          onChangeAddressValue(initialCountry, 'country');
        }
      } catch (error) {
        console.error('Error fetching IP data:', error);
      }
    };

    fetchData();

    return () => {
      if (controller.current) {
        controller.current.abort();
      }
    };
  }, []);

  useEffect(() => {
    if (tax_number?.length > 5) {
      validateTaxField(type.toLowerCase(), tax_number);
    }

    onChangeAddressValue(tax_number_valid, 'tax_number_valid');
  }, [tax_number, country]);

  useEffect(() => {
    if (!company_name) {
      onChangeAddressValue('', 'tax_number');
    }
  }, [company_name]);

  useEffect(() => {
    if (tax_number) {
      if (
        errors?.shipping?.tax_number_validated ||
        (errors?.billing?.tax_number_validated && country?.id !== 5)
      ) {
        onChangeAddressValue(false, 'tax_number_valid');
      } else {
        onChangeAddressValue(true, 'tax_number_valid');
      }
    } else {
      onChangeAddressValue(null, 'tax_number_valid');
    }
  }, [country, tax_number, errors]);

  useEffect(() => {
    getAddress({ ...changeAddress, postal_code });
  }, [postal_code]);

  useEffect(() => {
    getAddress({ ...changeAddress, house_number });
  }, [house_number]);

  useEffect(() => {
    onChangeAddressValue(country, 'country');
  }, [country]);

  const debounceApiCall = (func, delay) => {
    let timeoutId;

    return function(...args) {
      clearTimeout(timeoutId);
      if (controller.current) {
        controller.current.abort();
      }

      return new Promise(async (resolve) => {
        timeoutId = setTimeout(async () => {
          controller.current = new AbortController();
          const result = await func.apply(this, [...args, controller.current]);

          resolve(result);
        }, delay);
      });
    };
  };

  const validateTaxNumber = async (taxNumber) => {
    let apiKey = 'c831f3c1d5986f70577de2c6aa36b7ae';

    try {
      const response = await fetch(
        'https://www.apilayer.net/api/validate?access_key=' +
          apiKey +
          '&format=1&vat_number=' +
          taxNumber,
        { signal: controller.signal }
      );

      if (response.ok) {
        let body = await response.text();
        body = JSON.parse(body);

        return body.database == 'failure' ? true : body.valid;
      } else {
        return 'Invalid';
      }
    } catch (error) {
      if (error.name === 'AbortError') {
        // Request was aborted, ignore
      } else {
        console.error('Error during API call:', error);
        return 'Invalid';
      }
    }
  };

  const debouncedApiValidation = debounceApiCall(validateTaxNumber, 1000);

  const validateTaxField = async (type, taxNumber) => {
    let newErrors = { ...errors };

    let checkNumber = taxNumber;
    let taxNumberValidated =
      country?.id !== 5 ? await debouncedApiValidation(checkNumber) : true;

    if (company_name && company_name.length > 0) {
      if (type == 'shipping') {
        newErrors.shipping.tax_number = !taxNumberValidated;
        newErrors.shipping.tax_number_validated = !taxNumberValidated;
      }

      if (type == 'billing') {
        newErrors.billing.tax_number = !taxNumberValidated;
        newErrors.billing.tax_number_validated = !taxNumberValidated;
      }
    }

    setErrors(newErrors);

    return taxNumberValidated;
  };

  const getAddress = async (checkAddress) => {
    if (
      checkAddress.postal_code &&
      checkAddress.house_number &&
      checkAddress.postal_code.length >= 6
    ) {
      let apiKey = 'd88bf77e-1c04-4f95-84b0-d0f4726eea8e';

      try {
        const response = await axios.get(
          'https://postcode.tech/api/v1/postcode?postcode=' +
            checkAddress.postal_code +
            '&number=' +
            checkAddress.house_number,
          {
            headers: {
              Authorization: 'Bearer ' + apiKey,
            },
          }
        );

        let { data } = await response;

        if (data) {
          onChangeAddressValue(data.city, 'city');
          onChangeAddressValue(data.street, 'address');
        }
      } catch (e) {
        console.error(e);
      }
    }
  };

  const handleInputBlur = (event) => {
    const { value, name } = event.target;
    let newErrors = { ...errors };

    if (type.toLowerCase().includes('shipping')) {
      if (value.trim() === '') {
        newErrors.shipping[name] = true;
      } else {
        newErrors.shipping[name] = false;
      }
    }

    if (type.toLowerCase().includes('billing')) {
      if (value.trim() === '') {
        newErrors.billing[name] = true;
      } else {
        newErrors.billing[name] = false;
      }
    }

    setErrors(newErrors);
  };

  return (
    <div key={order.id + 'formas'}>
      <div className="row">
        <div className="col-md-10">
          <h4 className="mb-3">
            <FormattedMessage id={`CHECKOUT.${type.toUpperCase()}_ADDRESS`} />
          </h4>
        </div>
        <div className="col-md-12 mb-3">
          <label htmlFor="firstName">
            <FormattedMessage id="CHECKOUT.COMPANY_NAME" />
          </label>
          <input
            key={'companyName' + type + changeAddress?.id}
            type="text"
            className="form-control"
            id="companyName"
            required
            value={company_name || ''}
            onChange={(e) => {
              onChangeAddressValue(e.target.value, 'company_name');
              if (e.target.value.length == 0) {
                onChangeAddressValue('', 'payment_type');
                onChangeAddressValue('', 'tax_number');
                onChangeAddressValue('', 'company_name');
              }
            }}
          />
          <div className="invalid-feedback">
            <FormattedMessage id="CHECKOUT.COMPANY_NAME" />{' '}
            <FormattedMessage id="CHECKOUT.REQUIRED" />
          </div>
        </div>
      </div>
      {company_name && (
        <div className="row">
          <div className="col-md-12 mb-3">
            <label htmlFor="firstName">
              <FormattedMessage id="CHECKOUT.TAX_NUMBER" />
            </label>
            <input
              type="text"
              key={'tn' + type + changeAddress?.id}
              className={
                errors &&
                errors[type.toLowerCase()] &&
                errors[type.toLowerCase()].tax_number == true
                  ? 'form-control is-invalid'
                  : 'form-control'
              }
              id="taxNumber"
              name="tax_number"
              required
              value={tax_number || ''}
              onChange={async (e) => {
                let targetValue = e.target.value;
                onChangeAddressValue(targetValue, 'tax_number');
              }}
            />
            <div className="invalid-feedback">
              <FormattedMessage id="CHECKOUT.TAX_NUMBER" />{' '}
              <FormattedMessage id="CHECKOUT.REQUIRED" />
            </div>
          </div>
        </div>
      )}

      <div className="row">
        <div className="col-md-6 mb-3">
          <label htmlFor="firstName">
            <FormattedMessage id="CHECKOUT.FIRST_NAME" />
          </label>
          <input
            type="text"
            key={'fn' + type + changeAddress?.id}
            className={
              errors &&
              errors[type.toLowerCase()] &&
              errors[type.toLowerCase()].first_name == true
                ? 'form-control is-invalid'
                : 'form-control'
            }
            id="first_name"
            name="first_name"
            required
            onBlur={handleInputBlur}
            onChange={(e) => {
              onChangeAddressValue(e.target.value, 'first_name');
            }}
            value={first_name?.replace('...', '') || ''}
          />
          <div className="invalid-feedback">
            <FormattedMessage id="CHECKOUT.FIRST_NAME" />{' '}
            <FormattedMessage id="CHECKOUT.REQUIRED" />.
          </div>
        </div>

        <div className="col-md-6 mb-3">
          <label htmlFor="last_name">
            <FormattedMessage id="CHECKOUT.LAST_NAME" />
          </label>
          <input
            type="text"
            key={'ln' + type + changeAddress?.id}
            className={
              errors &&
              errors[type.toLowerCase()] &&
              errors[type.toLowerCase()].last_name == true
                ? 'form-control is-invalid'
                : 'form-control'
            }
            id="last_name"
            name="last_name"
            required
            value={last_name?.replace('...', '') || ''}
            onBlur={handleInputBlur}
            onChange={(e) => {
              onChangeAddressValue(e.target.value, 'last_name');
            }}
          />
          <div className="invalid-feedback">
            <FormattedMessage id="CHECKOUT.LAST_NAME" />{' '}
            <FormattedMessage id="CHECKOUT.REQUIRED" />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-5 mb-3">
          <label htmlFor="zip">
            <FormattedMessage id="CHECKOUT.POSTAL_CODE" />
          </label>
          <input
            type="text"
            key={'pc' + type + changeAddress?.id}
            className={
              errors &&
              errors[type.toLowerCase()] &&
              errors[type.toLowerCase()].postal_code == true
                ? 'form-control is-invalid'
                : 'form-control'
            }
            id="postalcode"
            name="postal_code"
            required
            value={postal_code || ''}
            onBlur={handleInputBlur}
            onChange={(e) => {
              onChangeAddressValue(e.target.value, 'postal_code');
            }}
          />
          <div className="invalid-feedback">
            <FormattedMessage id="CHECKOUT.POSTAL_CODE" />
            <FormattedMessage id="CHECKOUT.REQUIRED" />
          </div>
        </div>

        <div className="col-md-5 mb-3">
          <label htmlFor="house_number">
            <FormattedMessage id="CHECKOUT.HOUSE_NUMBER" />
          </label>

          <input
            type="text"
            key={'hn' + type + changeAddress?.id}
            className={
              errors &&
              errors[type.toLowerCase()] &&
              errors[type.toLowerCase()].house_number == true
                ? 'form-control is-invalid'
                : 'form-control'
            }
            id="house_number"
            name="house_number"
            required
            value={house_number || ''}
            onBlur={handleInputBlur}
            onChange={(e) => {
              onChangeAddressValue(e.target.value, 'house_number');
            }}
          />
          <div className="invalid-feedback">
            <FormattedMessage id="CHECKOUT.HOUSE_NUMBER" />{' '}
            <FormattedMessage id="CHECKOUT.REQUIRED" />
          </div>
        </div>

        <div className="col-md-2 mb-3">
          <label htmlFor="ext">
            <FormattedMessage id="CHECKOUT.EXT" />
          </label>
          <input
            type="text"
            key={'ext' + type + changeAddress?.id}
            className="form-control"
            id="ext"
            required
            value={ext || ''}
            onChange={(e) => {
              onChangeAddressValue(e.target.value, 'ext');
            }}
          />
          <div className="invalid-feedback">
            <FormattedMessage id="CHECKOUT.EXTENSION" />
            <FormattedMessage id="CHECKOUT.REQUIRED" />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 mb-3">
          <label htmlFor="address">
            <FormattedMessage id="CHECKOUT.ADDRESS" />
          </label>
          <input
            type="text"
            key={'addr' + type + changeAddress?.id}
            className={
              errors &&
              errors[type.toLowerCase()] &&
              errors[type.toLowerCase()].address == true
                ? 'form-control is-invalid'
                : 'form-control'
            }
            id="address"
            name="address"
            required
            value={address || ''}
            onBlur={handleInputBlur}
            onChange={(e) => {
              onChangeAddressValue(e.target.value, 'address');
            }}
          />
          <div className="invalid-feedback">
            <FormattedMessage id="CHECKOUT.ADDRESS" />{' '}
            <FormattedMessage id="CHECKOUT.REQUIRED" />
          </div>
        </div>
        <div className="col-md-6 mb-3">
          <label htmlFor="city">
            <FormattedMessage id="CHECKOUT.CITY" />
          </label>
          <input
            type="text"
            key={'city' + type + changeAddress?.id}
            className={
              errors &&
              errors[type.toLowerCase()] &&
              errors[type.toLowerCase()].city == true
                ? 'form-control is-invalid'
                : 'form-control'
            }
            id="city"
            name="city"
            required
            value={city || ''}
            onBlur={handleInputBlur}
            onChange={(e) => {
              onChangeAddressValue(e.target.value, 'city');
            }}
          />
          <div className="invalid-feedback">
            <FormattedMessage id="CHECKOUT.CITY" />{' '}
            <FormattedMessage id="CHECKOUT.REQUIRED" />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 mb-3">
          <label htmlFor="country">
            <FormattedMessage id="CHECKOUT.COUNTRY" />
          </label>
          <select
            className={
              errors &&
              errors[type.toLowerCase()] &&
              errors[type.toLowerCase()].country == true &&
              country?.id !== 5
                ? 'custom-select d-block w-100 is-invalid'
                : 'custom-select d-block w-100'
            }
            id="country"
            name="country"
            key={'cntry' + type + changeAddress?.id}
            required
            value={country?.id || ''}
            onBlur={handleInputBlur}
            onChange={(e) => {
              let selectedCountry = countries.find((country) => {
                return country.id == e.target.value;
              });

              onChangeAddressValue(selectedCountry ?? undefined, 'country');
            }}
          >
            <option key={'empty-select'} value={''}>
              {intl.formatMessage({ id: 'Select' })}
            </option>
            {countries.map((item) => (
              <option
                key={'shippingOption' + item?.id + type + changeAddress?.id}
                value={item?.id}
              >
                {item?.name}
              </option>
            ))}
          </select>
          <div className="invalid-feedback">
            <FormattedMessage id="CHECKOUT.COUNTRY" />{' '}
            <FormattedMessage id="CHECKOUT.REQUIRED" />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 mb-3">
          <label htmlFor="firstName">
            <FormattedMessage id="CHECKOUT.PHONE_NUMBER" />
          </label>
          <input
            type="text"
            key={'phon' + type + changeAddress?.id}
            className={
              errors &&
              errors[type.toLowerCase()] &&
              errors[type.toLowerCase()].phone_number == true
                ? 'form-control is-invalid'
                : 'form-control'
            }
            id="phoneNumber"
            name="phone_number"
            required
            value={phone_number || ''}
            onBlur={handleInputBlur}
            onChange={(e) =>
              onChangeAddressValue(e.target.value, 'phone_number')
            }
          />
          <div className="invalid-feedback">
            <FormattedMessage id="CHECKOUT.PHONE_NUMBER" />{' '}
            <FormattedMessage id="CHECKOUT.REQUIRED" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddressForm;
