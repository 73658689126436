import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import { drawActions } from './../../../../store/ducks/draw.duck';
import { ELEMENTS } from './../../../../common/constants/elements/elements';
import ReactTooltip from 'react-tooltip';
import { FormattedMessage, injectIntl } from 'react-intl';

class Toolbar extends React.Component {

  constructor (props) {
    super(props);

    this.state = {
      isOpen: '',
      engraveType: null,
      gearType: null
    };
  }

  closeDialog = () => {
    this.setState({
      isOpen: '',
      engraveType: null
    });
  };

  onChange = field => event => {
    this.setState({ [field]: event.target.value });
  };

  drawGear = () => {
    const { gearType } = this.state;
    const { insertGear, selectedId, id } = this.props;
    insertGear({ text: parseInt(gearType) }, selectedId, id);
    this.closeDialog();
  };

  drawGalvanize = () => {
    const { gearType } = this.state;
    const { insertGalvanize, selectedId, id } = this.props;
    insertGalvanize({ text: parseInt(gearType) }, selectedId, id);
    this.closeDialog();
  };

  toggleBend = () => {
    const { toggleBendElement, selectedId, id } = this.props;
    toggleBendElement(selectedId, id);
    this.closeDialog();
  };

  openToggle = (event) => {
    const { insertedGears, selectedId } = this.props;
    const element = { [event.target.id]: true };

    this.setState({
      isOpen: element,
      gearType: (insertedGears[selectedId] && insertedGears[selectedId]['text'])
    });
  };

  deleteElement = () => {
    const { deleteElement, selectedId, id } = this.props;
    deleteElement(selectedId, id);
  };

  cutElement = () => {
    const { cutElement, selectedId, id } = this.props;
    cutElement(selectedId, id);
    this.setState({ isOpen: '' });
  };

  drawEngravingElement = () => {
    const {
      insertEngravingElement, selectedId, selectedType, id
    } = this.props;
    insertEngravingElement(selectedType, selectedId, id);
    this.setState({ isOpen: '' });
  };

  checkElementState = () => {
    const { selectedElem, insertedGears, insertedGalvanize, engravingElement, cutElement } = this.props;

    //console.log(Object.keys(engravingElement).includes(selectedElem.id))

    if (Object.keys(cutElement).includes(selectedElem.id)) {
      return 'cut';
    }

    if (Object.keys(insertedGears)
      .includes(selectedElem.id)) {
      return 'threaded';
    }

    if (Object.keys(engravingElement).includes(selectedElem.id)) {
      return 'engraved';
    }


    if (Object.keys(insertedGalvanize)
      .includes(selectedElem.id)) {
      return 'galvanized';
    }
    return 'cut';
  };


  render () {
    const { selectedId, selectedType, threads, intl } = this.props;
    //console.log(selectedElem, Object.keys(engravingElement));
    const { isOpen, gearType } = this.state;

    return (
      <>
        <ReactTooltip/>

        <div className="toolbar"
             data-tip={selectedId === null ? intl.formatMessage({ id: 'Please select an element first' }) : ''}>

          <button type="button" id="cut"
                  data-tip={intl.formatMessage({ id: 'Cutting' })}
                  disabled={selectedId === null || this.checkElementState() == 'cut' }
                  className={ this.checkElementState() == 'cut' ? 'selected toolbar__button cut' : 'toolbar__button cut' }
                  onClick={this.openToggle}></button>

          <button type="button"
                  data-tip={intl.formatMessage({ id: 'Bending' })}
                  disabled={selectedId === null || selectedType !== ELEMENTS.LINE || this.checkElementState() == 'bend'}
                  className={ this.checkElementState() == 'bend' ? 'selected toolbar__button bend' : 'toolbar__button bend' }
                  onClick={this.toggleBend}></button>

          <button type="button" data-tip={intl.formatMessage({ id: 'Threading' })} id="gear"
                  disabled={selectedId === null || this.checkElementState() == 'threaded'}
                  className={ this.checkElementState() == 'threaded' ? 'selected toolbar__button gear' : 'toolbar__button gear' }
                  onClick={this.openToggle}></button>

          {/*<button type="button" data-tip={intl.formatMessage({ id: 'Galvanize' })} id="galvanize"*/}
          {/*        disabled={selectedId === null || this.checkElementState() == 'galvanized'}*/}
          {/*        className={ this.checkElementState() == 'galvanized' ? 'selected toolbar__button galvanize' : 'toolbar__button galvanize' }*/}
          {/*        onClick={this.openToggle}></button>*/}

          <button type="button" data-tip={intl.formatMessage({ id: 'Engraving' })} id="edit"
                  disabled={selectedId === null || this.checkElementState() == 'engraved'}
                  className={ this.checkElementState() == 'engraved' ? 'selected toolbar__button edit' : 'toolbar__button edit' }
                  onClick={this.openToggle}></button>

          <button type="button" data-tip={intl.formatMessage({ id: 'Delete element' })}
                  disabled={selectedId === null} className="toolbar__button delete"
                  onClick={this.deleteElement}></button>

          <Modal show={isOpen.galvanized} onHide={this.closeDialog} size="md" centered>
            <Modal.Header closeButton>
              <Modal.Title><FormattedMessage id={'Galvanizing'}/></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p><FormattedMessage id={'Please select an option'}/>
              </p>
              <select onChange={this.onChange('gearType')} value={gearType}>
                <option>{intl.formatMessage({ id: 'Select' })}</option>
                <option key="45" value="45"> 45</option>
                <option key="90" value="90"> 90</option>
              </select>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.closeDialog}><FormattedMessage
                id={'Cancel'}/></Button>
              <Button onClick={this.drawGalvanize}><FormattedMessage id={'Select'}/></Button>
            </Modal.Footer>
          </Modal>

          <Modal show={isOpen.gear} onHide={this.closeDialog} size="md" centered>
            <Modal.Header closeButton>
              <Modal.Title><FormattedMessage id={'Threading'}/></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p><FormattedMessage id={'Please select an option'}/></p>
              <select onChange={this.onChange('gearType')} value={gearType}>
                <option>{intl.formatMessage({ id: 'Select' })}</option>
                {threads && threads.map(item => { return(
                  <option key={item.id} value={item.id}> M{item.mm} </option>
                )})}
              </select>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.closeDialog}><FormattedMessage
                id={'Cancel'}/></Button>
              <Button onClick={this.drawGear}><FormattedMessage id={'Select'}/></Button>
            </Modal.Footer>
          </Modal>

          <Modal show={isOpen.edit} onHide={this.closeDialog} size="md" centered>
            <Modal.Header closeButton>
              <Modal.Title><FormattedMessage id={'Engraving'}/></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p><FormattedMessage id={'Please select an option'}/></p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.closeDialog}><FormattedMessage
                id={'Cancel'}/></Button>
              <Button onClick={this.drawEngravingElement}><FormattedMessage id={'Select'}/></Button>
            </Modal.Footer>
          </Modal>

          <Modal show={isOpen.cut} onHide={this.closeDialog} size="md" centered>
            <Modal.Header closeButton>
              <Modal.Title><FormattedMessage id={'Cutting'}/></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p><FormattedMessage id={'Confirm that you would to engrave the cut element'}/></p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.closeDialog}><FormattedMessage
                id={'Cancel'}/></Button>
              <Button onClick={this.cutElement}><FormattedMessage id={'Select'}/></Button>
            </Modal.Footer>
          </Modal>
        </div>

      </>
    );
  }
}

Toolbar.propTypes = {
  id: PropTypes.string,
  threads: PropTypes.array,
  insertedGears: PropTypes.object,
  insertedGalvanize: PropTypes.object,
  selectedType: PropTypes.string,
  selectedId: PropTypes.string,
  insertEngravingElement: PropTypes.func.isRequired,
  deleteElement: PropTypes.func.isRequired,
  toggleBendElement: PropTypes.func.isRequired,
  cutElement: PropTypes.func.isRequired,
  insertGear: PropTypes.func.isRequired,
  insertGalvanize: PropTypes.func.isRequired,
};

const mapStateToProps = ({
  draw: {
    currentSvg: {
      selectedElem, insertedGears, insertedGalvanize, engravingElement, cutElement, id
    }, materialData: { threads }
  }
}) => ({
  selectedElem: selectedElem,
  selectedId: selectedElem.id,
  selectedType: selectedElem.nodeName,
  id,
  insertedGears,
  insertedGalvanize,
  engravingElement,
  cutElement,
  threads,
});

const mapDispatchToprops = {
  toggleBendElement: (selectedId, id,) => drawActions.toggleBendElement({
    selectedId,
    id
  }),
  insertGear: (item, selectedId, id,) => drawActions.insertGear({
    item,
    selectedId,
    id
  }),
  insertGalvanize: (item, selectedId, id,) => drawActions.insertGalvanize({
    item,
    selectedId,
    id
  }),
  deleteElement: (selectedId, id) => drawActions.deleteElement(selectedId, id),
  cutElement: (selectedId, id) => drawActions.cutElement(selectedId, id),
  insertEngravingElement:
    (nodeName, selectedId, id) => drawActions.engravingElement({
      nodeName,
      selectedId,
      id
    })
};

export default injectIntl(connect(mapStateToProps, mapDispatchToprops)(Toolbar));
