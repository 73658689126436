import { handleActions } from "redux-actions";
import {
  getHourlyRates,
  getHourlyRatesSuccess,
  getHourlyRatesError,
  getHourlyRate,
  getHourlyRateSuccess,
  getHourlyRateError,
  updateHourlyRate,
  updateHourlyRateSuccess,
  updateHourlyRateError,
  deleteHourlyRate,
  deleteHourlyRateSuccess,
  deleteHourlyRateError,
} from "./actions";

const initialState = {
  isProcessing: false,
  data: [],
  error: "",
  pagination: {
    per_page: 10,
    current_page: 1,
    count: 0
  },
  detailedHourlyRate: {
    isProcessing: false,
    data: null,
    status: {
      isProcessing: false
    }
  },
};

const handlers = {
  [getHourlyRates]: (state) => ({
    ...state,
    isProcessing: true,
  }),
  [getHourlyRatesSuccess]: (state, action) => ({
    ...state,
    isProcessing: false,
    data: action.payload,
    // data: payload.quotes,
    pagination: {
      ...state.pagination,
      count: (action.payload && action.payload.length) || 0
    }
  }),
  [getHourlyRatesError]: (state, action) => ({
    ...state,
    isProcessing: false,
    error: action.payload
  }),
  [getHourlyRate]: (state) => ({
    ...state,
    detailedHourlyRate: {
      ...state.detailedHourlyRate,
      isProcessing: true
    }
  }),
  [getHourlyRateSuccess]: (state, action) => ({
    ...state,
    detailedHourlyRate: {
      ...state.detailedHourlyRate,
      isProcessing: false,
      data: action.payload
    }
  }),
  [getHourlyRateError]: (state, action) => ({
    ...state,
    detailedHourlyRate: {
      ...state.detailedHourlyRate,
      isProcessing: false,
      error: action.payload
    }
  }),
  [updateHourlyRate]: (state) => ({
    ...state,
    detailedHourlyRate: {
      ...state.detailedHourlyRate,
      isProcessing: true,
    }
  }),
  [updateHourlyRateSuccess]: (state) => ({
    ...state,
    detailedHourlyRate: {
      ...initialState.detailedHourlyRate,
      isProcessing: false,
    }
  }),
  [updateHourlyRateError]: (state, action) => ({
    ...state,
    detailedHourlyRate: {
      ...state.detailedHourlyRate,
      isProcessing: false,
      status: {
        ...state.detailedHourlyRate.status,
        error: action.payload
      }
    }
  }),
  [deleteHourlyRate]: (state) => ({
    ...state,
  }),
  [deleteHourlyRateSuccess]: (state) => ({
    ...state,
  }),
  [deleteHourlyRateError]: (state) => ({
    ...state,
  }),
};

export default handleActions(handlers, initialState);
